import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DSModal, DSAlert, DSAlertType, DSAlertDisplay, DSCheckbox } from "@skillup/ui";

import { Campaign } from "./useCampaignModals";
import styles from "./UpdateStructureModal.module.scss";
import useTranslation from "hooks/useTranslation";

interface IProps {
  readonly campaign: Campaign;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

const UpdateStructureModal = ({ campaign, isOpen, onClose }: IProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [acceptedWarnings, setAcceptedWarnings] = useState(false);

  return (
    <DSModal isOpen={isOpen} className={styles.modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("campaign.updateModal.updateHeader", {
            defaultValue: "Correction d'une campagne en cours",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <div className={styles.contentWrapper}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {t("campaign.updateModal.updateContent_1", {
              defaultValue:
                "Vous vous apprêtez à corriger la trame d’une campagne déjà lancée. Prenez connaissance des conséquences listées ci-dessous avant de poursuivre :",
            })}
          </DSAlert>
          <ul className={styles.warningList}>
            <li>
              {t("campaign.updateModal.updateContent_2", {
                defaultValue:
                  "Les corrections apportées seront appliquées à tous les entretiens (présents et futurs, signés ou non) de la campagne « {{titleForHR}} ».",
                titleForHR: campaign.titleForHR,
              })}
            </li>
            <br />
            <li>
              {t("campaign.updateModal.updateContent_3", {
                defaultValue:
                  "Elles ne seront pas répercutées sur la trame d’entretien initialement utilisée (« {{templateTitle}} »).",
                templateTitle: campaign.templateSource.title,
              })}
            </li>
            <br />
            <li>
              {t("campaign.updateModal.updateContent_4", {
                defaultValue: "L’auteur et la nature des corrections seront loggués.",
              })}
            </li>
          </ul>
          <DSCheckbox
            label={t("campaign.updateModal.updateConscent", {
              defaultValue: "J’ai pris connaissance des avertissements ci-dessus.",
            })}
            checked={acceptedWarnings}
            onChange={(checked) => {
              setAcceptedWarnings(checked);
            }}
          ></DSCheckbox>
        </div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("interview.modals.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          label={t("campaign.updateModal.continueUpdate", {
            defaultValue: "Poursuivre vers la correction",
          })}
          disabled={!acceptedWarnings}
          tooltip={
            !acceptedWarnings
              ? t("campaign.updateModal.acceptedWarning", {
                  defaultValue:
                    "Veuillez cocher la case confirmant que vous avez pris connaissance des avertissements.",
                })
              : undefined
          }
          onClick={() => {
            history.push(`/responsable/campagne/${campaign.uuid}/edit`);
          }}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default UpdateStructureModal;
