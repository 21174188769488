import { useToasts } from "react-toast-notifications";
import {
  useMemo,
  Dispatch,
  useState,
  useContext,
  useCallback,
  createContext,
  SetStateAction,
} from "react";

import { GridSortModel } from "@mui/x-data-grid-pro";

import { FilterProp } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";

import { trpc } from "utils/trpc";
import { Assignee } from "types/skills";
import { DatatableJob } from "types/skills";
import useTranslation from "hooks/useTranslation";

import { useAssignmentsGridFilters } from "./utils/useAssignmentsGridFilters";

type PaginationProperties = {
  page: number;
  pageSize: number;
};

type DirectionUpp = Uppercase<GridSortModel[0]["sort"]>;

type AssignmentsContextType = {
  employees: {
    isLoading: boolean;
    data: Array<Assignee>;
  };
  jobs: {
    isLoading: boolean;
    data: undefined | Array<DatatableJob>;
  };
  sortHook: {
    sortModel: GridSortModel;
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
  };
  filtersHook: {
    conf: ListUtils.FilterConfigurationMap;
    filters: FilterProp<ListUtils.FilterConfigurationMap>;
    setFilterValues: Dispatch<SetStateAction<ListUtils.FilterConfigurationMap>>;
  };
  paginationHook: {
    total: number;
    actualPaginationModel: PaginationProperties;
    paginationModel: ListUtils.PaginationProperties;
    setPaginationModel: Dispatch<
      SetStateAction<{
        page: number;
        pageSize: number;
      }>
    >;
  };
  assignEmployeeToJob: {
    isLoading: boolean;
    refetch: () => void;
    mutator: (
      employees: Array<{ version: number; companyUuid: string; employeeUuid: string }>,
      targetJob: {
        uuid: string;
        name: string;
      }
    ) => Promise<void>;
  };
};

const AssignmentsContext = createContext<AssignmentsContextType>(null);

const useAssignmentsContext = () => {
  const context = useContext(AssignmentsContext);
  if (!context) {
    throw new Error("useAssignmentsContext must be used within an AssignmentsContext");
  }
  return context;
};

interface AssignmentsProviderProps {
  children: React.ReactNode;
}

const AssignmentsProvider = ({ children }: AssignmentsProviderProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [conf, filters, filterValues, setFilterValues] = useAssignmentsGridFilters();
  const [paginationModel, setPaginationModel] = useState<PaginationProperties>({
    page: 0,
    pageSize: 20,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(undefined);

  const { jobName, ...employeesFilters } = filterValues;

  const {
    data: assignmentsData,
    isLoading: employeesLoading,
    refetch: refetchAssignments,
  } = trpc.assignments.get.useQuery(
    {
      filters: {
        employees: {
          ...employeesFilters,
        },
        jobs: { jobName },
      },
      pagination: {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      },
      sortBy:
        sortModel && sortModel.length > 0
          ? {
              direction: sortModel[0].sort.toUpperCase() as DirectionUpp,
              property: sortModel[0].field,
            }
          : undefined,
    },
    {
      keepPreviousData: true,
    }
  );

  const assignments: Array<Assignee> = useMemo(() => {
    return (assignmentsData?.assignments as unknown as Array<Assignee>) || [];
  }, [assignmentsData]);

  const totalRows = useMemo(() => {
    return assignmentsData?.pagination?.total || 0;
  }, [assignmentsData]);

  const payload = useMemo(
    () => ({
      onError: () => {
        addToast(
          t("portal.config.employee.assignEmployeeToJob.error", {
            defaultValue: "La fiche de poste n'a pas pu être assignée",
          }),
          {
            appearance: "error",
          }
        );
      },
      onSuccess: () => {
        refetchAssignments();
        addToast(
          t("portal.config.employee.assignEmployeeToJob.success", {
            defaultValue: "La fiche de poste a bien été assignée",
          }),
          {
            appearance: "success",
          }
        );
      },
    }),
    [addToast, t, refetchAssignments]
  );

  const assignEmployeeToJobMutator = trpc.employees.assignEmployeeToJob.useMutation(payload);

  const { data: jobsData, isLoading: jobsLoading } = trpc.jobs.getAll.useQuery();

  const jobs: Array<DatatableJob> = useMemo(() => {
    return (jobsData?.filter((job) => !job.isArchived) as unknown as Array<DatatableJob>) || [];
  }, [jobsData]);

  const assignEmployeeToJob = useCallback(
    async (
      employees: Array<{ version: number; companyUuid: string; employeeUuid: string }>,
      targetJob: {
        uuid: string;
        name: string;
      }
    ) => {
      assignEmployeeToJobMutator.mutate({
        employees: employees.map((e) => ({ ...e, jobUuid: targetJob.uuid })),
      });
    },
    [assignEmployeeToJobMutator]
  );

  return (
    <AssignmentsContext.Provider
      value={{
        assignEmployeeToJob: {
          isLoading: assignEmployeeToJobMutator.isLoading,
          mutator: assignEmployeeToJob,
          refetch: refetchAssignments,
        },
        employees: {
          data: assignments || [],
          isLoading: employeesLoading,
        },
        filtersHook: {
          conf,
          filters,
          setFilterValues,
        },
        jobs: {
          data: jobs,
          isLoading: jobsLoading,
        },
        paginationHook: {
          actualPaginationModel: paginationModel,
          paginationModel: paginationModel,
          setPaginationModel,
          total: totalRows,
        },
        sortHook: {
          setSortModel,
          sortModel,
        },
      }}
    >
      {children}
    </AssignmentsContext.Provider>
  );
};

export { AssignmentsContext, AssignmentsProvider, useAssignmentsContext };
