import { useCallback, useMemo } from "react";
import { Flex, AssistiveArea } from "@skillup/ui";

import { TableColumn, TableColumnDisallow } from "../types";
import { TableChildColumnPermissionsRadioFormRoles } from "./TableChildColumnPermissionsRadioFormRoles";

import styles from "../TableChild.module.scss";
import { TranslationType } from "hooks/useTranslation";

export const TableChildColumnPermissions = ({
  childId,
  column,
  index,
  onUpdatePermissions,
  t,
}: {
  childId: string;
  column: TableColumn;
  index: number;
  onUpdatePermissions: (disallow: TableColumnDisallow) => void;
  t: TranslationType;
}) => {
  const defaultRoleAllowReply = useMemo(() => {
    if (!column.disallow?.employee?.reply) {
      return "employee";
    }
    if (!column.disallow?.manager?.reply) {
      return "manager";
    }
    return "employee";
  }, [column.disallow]);

  const handleGrantReplyToRole = useCallback(
    (role: "employee" | "manager") => {
      const newDisallow = {
        employee: { ...column.disallow?.employee, reply: role !== "employee" },
        manager: { ...column.disallow?.manager, reply: role !== "manager" },
      };
      onUpdatePermissions(newDisallow);
    },
    [column, onUpdatePermissions]
  );
  return (
    <Flex column className={styles.table__column__permission}>
      <p className={styles.table__column__permission__actionLabel}>
        {t("interviews.templateBuilder.table.permissions.column.label", {
          defaultValue: "Qui peut remplir la colonne {{ count }} ({{ columnTitle }}) ?",
          count: index + 1,
          columnTitle: column.title,
        })}
      </p>
      {column.type === "fixed" && (
        <AssistiveArea
          text={t("interviews.templateBuilder.table.permissions.column.fixed", {
            defaultValue:
              "Le contenu de la colonne étant fixé, aucun participant ne peut le modifier.",
          })}
        />
      )}
      {column.type !== "fixed" && (
        <>
          <AssistiveArea
            text={t("interviews.templateBuilder.table.permissions.column.others", {
              defaultValue: "Seul un des participants peut remplir une cellule d'un tableau.",
            })}
          />
          <TableChildColumnPermissionsRadioFormRoles
            childId={childId}
            columnUuid={column.uuid}
            role={defaultRoleAllowReply}
            onGrantForRole={handleGrantReplyToRole}
            t={t}
          />
        </>
      )}
    </Flex>
  );
};
