import { Flex } from "@skillup/design-system";
import { AssistiveArea, DSCheckbox, DSRadio, DSRadioGroup, DSTextInput, Label } from "@skillup/ui";
import { Dispatch, SetStateAction } from "react";
import { TranslationType } from "hooks/useTranslation";
import styles from "./CreateOrRenameCategoryCommons.module.scss";

export type Scale = "ordinal" | "numeric" | undefined;

export const TargetLabel = ({
  targetCategoriesLabels,
  categoryLabel,
  setCategoryLabel,
  isDuplicate,
  setIsDuplicate,
  t,
}: {
  targetCategoriesLabels: string[];
  categoryLabel?: string;
  setCategoryLabel: (value: string) => void;
  isDuplicate: boolean;
  setIsDuplicate: (value: boolean) => void;
  t: TranslationType;
}) => {
  const onLabelChange = (value: string) => {
    setCategoryLabel(value);
    targetCategoriesLabels.includes(value) ? setIsDuplicate(true) : setIsDuplicate(false);
  };

  return (
    <Flex flexDirection="column">
      <Label
        label={t("supervisor.interviews.configuration.modal.label", {
          defaultValue: "Intitulé de la catégorie",
        })}
        required
      />
      <DSTextInput
        name="category-label"
        value={categoryLabel}
        placeholder={t("supervisor.interviews.configuration.modal.label.placeholder", {
          defaultValue: "Saisissez un intitulé pour la catégorie",
        })}
        onChange={onLabelChange}
        error={isDuplicate || categoryLabel?.length === 0}
        required
      />
      {(isDuplicate || categoryLabel?.length === 0) && (
        <AssistiveArea
          text={
            isDuplicate
              ? t("supervisor.interviews.configuration.modal.label.error", {
                  defaultValue: "Le nom de la catégorie existe déjà.",
                })
              : t("supervisor.interviews.configuration.modal.label.empty", {
                  defaultValue: "Ce champ doit être rempli.",
                })
          }
          mode="error"
        />
      )}
    </Flex>
  );
};

export const TargetScale = ({
  scale,
  setScale,
  disabled,
}: {
  scale: Scale;
  setScale: (type: Scale) => void;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets scale through the categories yet

  return (
    <div>
      <Label label="Type d'objectifs" required className={styles.newSection} />
      <DSRadioGroup name="type-d-objectifs">
        <DSRadio
          label="Quantitatifs"
          checked={scale === "numeric"}
          onChange={() => setScale("numeric")}
        />
        <DSRadio
          label="Qualitatifs"
          checked={scale === "ordinal"}
          onChange={() => setScale("ordinal")}
        />
        <DSRadio
          label="Les deux"
          checked={scale === undefined}
          onChange={() => setScale(undefined)}
        />
      </DSRadioGroup>
    </div>
  );
};

export const TargetGoal = ({
  goal,
  setGoal,
  disabled,
}: {
  goal: boolean;
  setGoal: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets goal through the categories yet

  return (
    <Flex flexDirection="column" className={styles.goal}>
      <DSCheckbox
        label="Résultat attendu"
        checked={goal}
        onChange={() => setGoal((oldValue) => !oldValue)}
      />
      <Label
        label="Par défaut, l'évaluation des objectifs quantitatifs s'exprime en pourcentage."
        className={styles.customHelpText}
      />
      <Label
        label="Cochez cette case si vous souhaitez laisser la possibilité de définir une autre unité pour le résultat à atteindre."
        className={styles.customHelpText}
      />
    </Flex>
  );
};

export const TargetProperties = ({
  period,
  setPeriod,
  weight,
  setWeight,
  disabled,
}: {
  period: boolean;
  setPeriod: Dispatch<SetStateAction<boolean>>;
  weight: boolean;
  setWeight: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets weight and period through the categories yet

  return (
    <div>
      <Label label="Propriétés des objectifs de la catégorie" className={styles.newSection} />
      <Flex flexDirection="column">
        <DSCheckbox
          label="Période"
          checked={period}
          onChange={() => setPeriod((oldValue) => !oldValue)}
        />
        <DSCheckbox
          label="Pondération"
          checked={weight}
          onChange={() => setWeight((oldValue) => !oldValue)}
        />
      </Flex>
    </div>
  );
};
