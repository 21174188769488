import { FormatDate } from "@skillup/shared-utils";
import { DSButton, Label, useMediaQueries } from "@skillup/ui";
import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";
import { Collaborator } from "../../api";

import styles from "./UserInfo.module.scss";

import UserHeader from "../UserHeader";

const GENDER_LABEL = {
  male: "Homme",
  female: "Femme",
};

const FIELD_MAPPING = {
  id: [
    { label: "Prénom", key: "firstName" },
    { label: "Nom", key: "lastName" },
    { label: "E-mail", key: "email" },
    {
      label: "Genre",
      renderFn: (user) => GENDER_LABEL[user.gender],
    },
    { label: "Téléphone", key: "phone" },
    { label: "Matricule", key: "registrationNumber" },
    {
      label: "Date de naissance",
      key: "birthDate",
      renderFn: (user) =>
        user.birthDate
          ? FormatDate.toStringLiteralFormatFromUtc(user.birthDate, "dd/MM/yyyy")
          : null,
    },
    {
      label: "Date d'entrée",
      key: "joinDate",
      renderFn: (user) =>
        user.joinDate ? FormatDate.toStringLiteralFormatFromUtc(user.joinDate, "dd/MM/yyyy") : null,
    },
    {
      label: "Date de fin",
      key: "expectedDepartureDate",
      renderFn: (user) =>
        user.expectedDepartureDate
          ? FormatDate.toStringLiteralFormatFromUtc(user.expectedDepartureDate, "dd/MM/yyyy")
          : null,
    },
    { label: "Âge", key: "age" },
    {
      label: "Ancienneté",
      key: "seniorityDate",
      renderFn: (user) =>
        user.seniorityDate
          ? FormatDate.toStringLiteralFormatFromUtc(user.seniorityDate, "dd/MM/yyyy")
          : null,
    },
  ],
  organisation: [
    { label: "Code rôle", key: "roleCode" },
    { label: "Code département", key: "divisionCode" },
    { label: "Code direction", key: "branchCode" },
    { label: "Code service", key: "serviceCode" },
    { label: "Sous-département", key: "subDivision" },
    { label: "Sous-direction", key: "subBranch" },
    { label: "Sous-service", key: "subService" },
    { label: "Code sous-département", key: "subDivisionCode" },
    { label: "Code sous-direction", key: "subBranchCode" },
    { label: "Code sous-service", key: "subServiceCode" },
    { label: "Périmètres", renderFn: (user) => user.areas?.map((area) => area.name).join(", ") },
    {
      label: "Sous-entreprise",
      renderFn: (user) => user.subCompanies?.map((sub) => sub.name).join(", "),
    },
    {
      label: "Gestionnaire de formation",
      renderFn: (user) => user.bookingManager,
    },
    { label: "Groupes", renderFn: (user) => user.groups?.map((group) => group.name).join(", ") },
    { label: "Société", key: "company" },
    { label: "Département", key: "division" },
    { label: "Direction", key: "branch" },
    { label: "Service", key: "service" },
    { label: "Site", key: "site" },
    { label: "RRH", key: "managerRRH" },
    { label: "HRBP", key: "managerHRBP" },
  ],
  position: [
    { label: "Rôle", key: "role" },
    { label: "Rôle à l'entrée", key: "joinRole" },
    {
      label: "Date d'entrée sur le rôle actuel",
      key: "roleDate",
      renderFn: (user) =>
        user.roleDate ? FormatDate.toStringLiteralFormatFromUtc(user.roleDate, "dd/MM/yyyy") : null,
    },
    { label: "Contrat", key: "contract" },
    { label: "Catégorie", key: "contractCategory" },
    { label: "Code d'imputation budgétaire", key: "invoicingBudgetaryCode" },
  ],
  hierarchy: [
    {
      label: "Manager formation N+1",
      renderFn: (user) =>
        `${user.manager0Lvl0 ?? ""}\n${user.manager0Lvl0Email ?? ""}\n${
          user.manager0Lvl0registrationNumber ?? ""
        }`,
    },
    {
      label: "Manager formation N+2",
      renderFn: (user) =>
        `${user.manager0Lvl1 ?? ""}\n${user.manager0Lvl1Email ?? ""}\n${
          user.manager0Lvl1registrationNumber ?? ""
        }`,
    },
  ],
  other: [
    { label: "Champ personnalisé 1", key: "customField0" },
    { label: "Champ personnalisé 2", key: "customField1" },
    { label: "Champ personnalisé 3", key: "customField2" },
    { label: "Champ personnalisé 4", key: "customField3" },
    { label: "Champ personnalisé 5", key: "customField4" },
    { label: "Champ personnalisé 6", key: "customField5" },
    { label: "Champ personnalisé 7", key: "customField6" },
    { label: "Champ personnalisé 8", key: "customField7" },
    { label: "Champ personnalisé 9", key: "customField8" },
    { label: "Champ personnalisé 10", key: "customField9" },
  ],
};

interface Props {
  readonly user: Collaborator;
  onEditUser: () => void;
}

export default ({ user, onEditUser }: Props) => {
  const { isMobile } = useMediaQueries();
  const { settings, userAccessModules } = useSettings();
  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);
  const isThereAnyCustomField = FIELD_MAPPING.other.some((field) => user[field.key] !== undefined);

  return (
    <div className={styles.UserInfo}>
      {!isMobile && (
        <div className={styles.actions}>
          <DSButton
            onClick={onEditUser}
            emphasis="Mid"
            label="Modifier les informations du collaborateur"
          ></DSButton>
        </div>
      )}
      {isMobile && <UserHeader user={user} />}
      <div className={styles.infos}>
        <section className={styles.infoCard}>
          <h2>Carte d’identité du collaborateur</h2>
          {FIELD_MAPPING.id.map((field) => (
            <div key={field.label}>
              <Label label={field.label} />
              <p>{field.renderFn ? field.renderFn(user) : user[field.key]}</p>
            </div>
          ))}
        </section>
        <section className={styles.infoCard}>
          <h2>Organisation</h2>
          {FIELD_MAPPING.organisation.map((field) => (
            <div key={field.label}>
              <Label label={field.label} />
              <p>{field.renderFn ? field.renderFn(user) : user[field.key]}</p>
            </div>
          ))}
        </section>
        <section className={styles.infoCard}>
          <h2>Poste</h2>
          {FIELD_MAPPING.position.map((field) => (
            <div key={field.label}>
              <Label label={field.label} />
              <p>{field.renderFn ? field.renderFn(user) : user[field.key]}</p>
            </div>
          ))}
        </section>
        <section className={styles.infoCard}>
          <h2>Hiérarchie</h2>
          {FIELD_MAPPING.hierarchy.map((field) => (
            <div key={field.label}>
              <Label label={field.label} />
              <p>{field.renderFn(user)}</p>
            </div>
          ))}
          {UserAccessChecker.Interviews.toInterviews() &&
            user.hierarchy?.observers
              ?.sort(({ level: a }, { level: b }) => a - b)
              ?.map((observer) => (
                <div key={observer.uuid}>
                  <Label label={`Manager entretien N+${observer.level}`} />
                  <p>
                    {observer?.fullName ?? ""}
                    {"\n"}
                    {observer?.email ?? ""}
                    {observer?.registrationNumber ? "\n" + observer?.registrationNumber : ""}
                  </p>
                </div>
              ))}
        </section>
        {isThereAnyCustomField ? (
          <section className={styles.infoCard}>
            <h2>Autres</h2>
            {FIELD_MAPPING.other.map(
              (field) =>
                user[field.key] && (
                  <div key={field.key}>
                    <Label label={user.customFields[field.key]} />
                    <p>{user[field.key]}</p>
                  </div>
                )
            )}
          </section>
        ) : null}
      </div>
    </div>
  );
};
