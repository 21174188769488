import styles from "./PlaceholderCard.module.scss";
import cx from "classnames";

import DashboardChildCard, {
  Props as DashboardChildCardProps,
} from "../DashboardChildCard/DashboardChildCard";

import { DSButton } from "@skillup/ui";

import placeholderImg from "assets/interview/dashboard/empty_card.png";
import { DashboardChildType } from "@skillup/espace-rh-bridge";
import { useDashboardExport } from "../../hooks/useDashboardExport";
import { TranslationType } from "hooks/useTranslation";

function PlaceholderCard<T extends DashboardChildType>({
  className,
  t,
  ...props
}: DashboardChildCardProps<T> & { t: TranslationType }) {
  const { exportChild, isExporting, isAvailable } = useDashboardExport(props.child);

  return (
    <DashboardChildCard
      className={cx(styles.PlaceholderCard, className)}
      title={props.typeName}
      t={t}
      {...props}
    >
      {() => (
        <div className={styles.placeholder}>
          <img className={styles.placeholderImg} src={placeholderImg} alt="bientôt disponible" />
          <div className={styles.message}>
            {t("dashboard.interviews.placeholderCard.soonAvailable", {
              defaultValue:
                "La synthèse pour les questions de type « {{ typeName }} » sera bientôt disponible.",
              typeName: props.typeName.toLowerCase(),
            })}
          </div>
          {isAvailable && (
            <>
              <div className={styles.details}>
                {t("dashboard.interviews.placeholderCard.download.details", {
                  defaultValue:
                    "En attendant, vous pouvez télécharger l’ensemble des réponses et commentaires associés à cette question en cliquant sur le bouton ci-dessous :",
                })}
              </div>
              <div className={styles.download}>
                <DSButton
                  emphasis="Mid"
                  label={t("dashboard.interviews.placeholderCard.download", {
                    defaultValue: "Télécharger",
                  })}
                  loading={isExporting}
                  onClick={exportChild}
                />
              </div>
            </>
          )}
        </div>
      )}
    </DashboardChildCard>
  );
}

export default PlaceholderCard;
