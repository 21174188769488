import { useState, useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import { useAreas, useEmployeeFields } from "hooks";

import { ListUtils } from "@skillup/shared-utils";
import { OrganizationRoutes } from "@skillup/espace-rh-bridge";
import { UseFilterProp, useUrlSyncedFilters } from "@skillup/ui";

import User from "utils/User";
import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";
import { CustomComponent } from "../components/CustomComponent";
import useJobs from "../hooks/useJobs";

export const useCollaboratorsGridFilters = () => {
  const { allAreas } = useAreas();
  const { data: jobs } = useJobs();
  const { t } = useTranslation();
  const { getFilters } = useEmployeeFields();
  const hasSkillsEnabled = User.hasSkillsEnabled();

  const [customFields, setCustomFields] = useState<{
    [field: string]: ListUtils.FilterConfiguration;
  }>({
    customField0: { type: ListUtils.FilterType.TEXT, label: "customField0" },
    customField1: { type: ListUtils.FilterType.TEXT, label: "customField1" },
    customField2: { type: ListUtils.FilterType.TEXT, label: "customField2" },
    customField3: { type: ListUtils.FilterType.TEXT, label: "customField3" },
    customField4: { type: ListUtils.FilterType.TEXT, label: "customField4" },
    customField5: { type: ListUtils.FilterType.TEXT, label: "customField5" },
    customField6: { type: ListUtils.FilterType.TEXT, label: "customField6" },
    customField7: { type: ListUtils.FilterType.TEXT, label: "customField7" },
    customField8: { type: ListUtils.FilterType.TEXT, label: "customField8" },
    customField9: { type: ListUtils.FilterType.TEXT, label: "customField9" },
  });

  const hasJobs = useMemo(
    () => hasSkillsEnabled && jobs && jobs.length > 0,
    [hasSkillsEnabled, jobs]
  );

  const fetchCustomFields = async () =>
    buildRequest<OrganizationRoutes.GetCustomFields>({
      method: "GET",
      path: "/organization/{companyUuid}/custom-fields",
      params: { companyUuid: User.getUserCompanyUuid() },
    })();

  useQuery(["fetchCustomFields"], fetchCustomFields, {
    refetchOnWindowFocus: false,
    onSuccess: (customFieldsValues) => {
      setCustomFields(() =>
        customFieldsValues
          .filter((f) => f.hasValues)
          .reduce(
            (acc, field) => ({
              ...acc,
              [field.field]: {
                type: ListUtils.FilterType.TEXT,
                label: field.label,
                placeholder: field.label,
              },
            }),
            {}
          )
      );
    },
  });

  const dateConfig = {
    operator: ListUtils.FilterOperator.EQUALS,
    operators: [
      ListUtils.FilterOperator.EQUALS,
      ListUtils.FilterOperator.BEFORE,
      ListUtils.FilterOperator.AFTER,
      ListUtils.FilterOperator.BETWEEN,
    ],
  };

  const numberConfig = {
    operator: ListUtils.FilterOperator.EQUALS,
    operators: [
      ListUtils.FilterOperator.EQUALS,
      ListUtils.FilterOperator.LESS_THAN,
      ListUtils.FilterOperator.GREATER_THAN,
      ListUtils.FilterOperator.GREATER_THAN_OR_EQUAL,
      ListUtils.FilterOperator.LESS_THAN_OR_EQUAL,
      ListUtils.FilterOperator.BETWEEN,
    ],
  };

  const config = useMemo(
    () => ({
      conf: {
        branch: getFilters().branch,
        division: getFilters().division,
        email: getFilters().email,
        firstName: getFilters().firstName,
        fullName: getFilters().fullName,
        lastName: getFilters().lastName,
        perimeters: getFilters().perimeters,
        registrationNumber: getFilters().registrationNumber,
        role: getFilters().role,
        service: getFilters().service,
        site: getFilters().site,
        ...(hasJobs ? { jobName: { type: ListUtils.FilterType.MULTISELECT } } : {}),
        ...(hasSkillsEnabled ? { skills: { type: ListUtils.FilterType.SINGLESELECT } } : {}),

        // DATE
        birthDate: { type: ListUtils.FilterType.DATE },
        joinDate: { type: ListUtils.FilterType.DATE },
        expectedDepartureDate: { type: ListUtils.FilterType.DATE },
        seniorityDate: { type: ListUtils.FilterType.DATE },
        roleDate: { type: ListUtils.FilterType.DATE },

        // NUMBER
        // grossYearlyWage,
        // grossHourlyWage,
        age: { type: ListUtils.FilterType.NUMBER },

        // STRING
        branchCode: { type: ListUtils.FilterType.TEXT },
        contract: { type: ListUtils.FilterType.TEXT },
        contractCategory: { type: ListUtils.FilterType.TEXT },
        divisionCode: { type: ListUtils.FilterType.TEXT },
        gender: { type: ListUtils.FilterType.TEXT },
        invoicingBudgetaryCode: { type: ListUtils.FilterType.TEXT },
        joinRole: { type: ListUtils.FilterType.TEXT },
        phone: { type: ListUtils.FilterType.TEXT },
        roleCode: { type: ListUtils.FilterType.TEXT },
        serviceCode: { type: ListUtils.FilterType.TEXT },
        subBranch: { type: ListUtils.FilterType.TEXT },
        subBranchCode: { type: ListUtils.FilterType.TEXT },
        subDivision: { type: ListUtils.FilterType.TEXT },
        subDivisionCode: { type: ListUtils.FilterType.TEXT },
        subService: { type: ListUtils.FilterType.TEXT },
        subServiceCode: { type: ListUtils.FilterType.TEXT },

        company: { type: ListUtils.FilterType.TEXT },
        managerRRH: { type: ListUtils.FilterType.TEXT },
        managerHRBP: { type: ListUtils.FilterType.TEXT },

        // MULTISELECT
        // subCompanies,
        // groups,

        // BOOLEAN
        // hasBookingManager,

        ...customFields,
      },
      initialValues: {
        fullName: { visibilityMode: "always" },
        perimeters: {
          initialValue: allAreas.map(({ uuid }) => uuid),
          options: allAreas.map(({ uuid, name }) => ({
            label: name,
            value: uuid,
          })),
          visibilityMode: allAreas.length > 1 ? "always" : "never",
        },
        ...(hasSkillsEnabled
          ? {
              skills: {
                component: CustomComponent,
                darkMode: true,
                operators: [
                  ListUtils.FilterOperator.EQUALS,
                  ListUtils.FilterOperator.CONTAINS,
                  ListUtils.FilterOperator.BEFORE,
                  ListUtils.FilterOperator.AFTER,
                  ListUtils.FilterOperator.LESS_THAN,
                  ListUtils.FilterOperator.GREATER_THAN,
                  ListUtils.FilterOperator.GREATER_THAN_OR_EQUAL,
                  ListUtils.FilterOperator.LESS_THAN_OR_EQUAL,
                ],
              },
            }
          : {}),
        age: numberConfig,
        birthDate: dateConfig,
        joinDate: dateConfig,
        expectedDepartureDate: dateConfig,
        seniorityDate: dateConfig,
        roleDate: dateConfig,
        ...(hasJobs
          ? {
              jobName: {
                darkMode: true,
                operator: ListUtils.FilterOperator.EQUALS,
                options: jobs.map(({ uuid, name }) => ({
                  label: name,
                  value: uuid,
                })),
              },
            }
          : {}),
        ...customFields,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allAreas, customFields, hasSkillsEnabled, hasJobs]
  );

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(
    config.conf,
    config.initialValues as UseFilterProp<typeof config.conf>
  );

  const processedFilterValues = useMemo(() => {
    const { skills, ...rest } = filterValues;
    const processedSkills = Object.entries(filterValues)
      .filter(([key]) => key === "skills" || key.startsWith("skills_$"))
      .map(([_, value]) => value);

    return {
      ...rest,
      jobName: filterValues.jobName ?? undefined,
      skills: processedSkills.length > 0 ? processedSkills : undefined,
    };
  }, [filterValues]);

  const filterCategories = [
    {
      id: "collaborator",
      label: t("filters.categories.collaborator", { defaultValue: "Infos collaborateur" }),
      filters: [
        "firstName",
        "lastName",
        "fullName",
        "email",
        "phone",
        "gender",
        "registrationNumber",
        "role",
        "roleCode",
        "contract",
        "contractCategory",
        "branch",
        "branchCode",
        "subBranch",
        "subBranchCode",
        "division",
        "divisionCode",
        "subDivision",
        "subDivisionCode",
        "service",
        "serviceCode",
        "subService",
        "subServiceCode",
        "site",
        "perimeters",
        "birthDate",
        "joinDate",
        "expectedDepartureDate",
        "seniorityDate",
        "roleDate",
        "age",
        "invoicingBudgetaryCode",
        "joinRole",
        "company",
        "managerRRH",
        "managerHRBP",
        "customField0",
        "customField1",
        "customField2",
        "customField3",
        "customField4",
        "customField5",
        "customField6",
        "customField7",
        "customField8",
        "customField9",
      ],
    },
    {
      id: "job",
      label: t("filters.categories.job", { defaultValue: "Fiches de postes" }),
      filters: [
        "jobName",
        // "customField0", "customField1", "customField2", "customField3", "customField4",
      ],
    },
    {
      id: "skills",
      label: t("filters.categories.skills", { defaultValue: "Compétences" }),
      filters: ["skills"],
    },
  ];

  return [config.conf, filters, processedFilterValues, setFilterValues, filterCategories] as [
    typeof config.conf,
    typeof filters,
    typeof processedFilterValues,
    typeof setFilterValues,
    typeof filterCategories,
  ];
};
