import { z } from "zod";
import { TranslationFunction } from "@skillup/design-system";

import { DateTime } from "@skillup/shared-utils";
import { IUpdateProjectData } from "@skillup/espace-rh-bridge";
import { GetSessionReviewsOutput } from "@skillup/training-bridge";

import { TranslationType } from "hooks/useTranslation";
import { parseDateRange } from "utils/dates/dates";

import { FormDate, State } from "../types";
import { AssistiveErrorText } from "../components";
import { hasColdReviewsEnabled, hasHotReviewsEnabled } from "utils/User";

function generateSessionReferenceRandomPart() {
  return `${Math.floor(Math.random() * 1000)}`.padStart(3, "0");
}

export function generateReference(
  programRef?: string,
  sessionDate: string = new Date().toISOString()
) {
  if (!programRef) {
    return undefined;
  }

  const year = new Date(sessionDate).getFullYear().toString().slice(2);
  const randomNumber = generateSessionReferenceRandomPart();

  let reference = programRef;
  if (programRef.length > 15) {
    reference = reference.slice(0, 15);
  }

  return `${year}-${reference}-${randomNumber}`;
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export function formatDates(dates: FormDate[]): string[] {
  return dates
    .sort((a, b) => {
      const aStartDate = a.dateRange[0];
      const bStartDate = b.dateRange[0];

      const aStartHour = a.hourRange?.[0];
      const bStartHour = b.hourRange?.[0];

      if (!isValidDate(aStartDate) || !isValidDate(bStartDate)) {
        return 0;
      }
      const dateDiff = aStartDate.getTime() - bStartDate.getTime();

      if (dateDiff === 0 && isValidDate(aStartHour) && isValidDate(bStartHour)) {
        return aStartHour.getTime() - bStartHour.getTime();
      }

      return dateDiff;
    })
    .map(({ dateRange, hourRange, allDay }) => {
      const startDate = dateRange?.[0];
      const endDate = dateRange?.[1];
      const startTime = hourRange?.[0];
      const endTime = hourRange?.[1];

      const newStartDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        // for all-day case:
        // default to 02:00AM so that the date is still correct
        // warning: this value is only working for France
        // with minus 2 hours if UTC+2 (summer time in France) or minus 1 hour if UTC+1 (winter time in France)
        //
        startTime?.getHours() ?? 2,
        startTime?.getMinutes() ?? 0,
        startTime?.getSeconds() ?? 0
        // toISOString will convert the date to UTC for DB
        // and it takes into account of the daylight saving time (DST)
        // warning: the toISOString is not working if it is done for an other timezone (eg.: From France to Martinique)
      ).toISOString();

      const newEndDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime?.getHours() ?? 2,
        endTime?.getMinutes() ?? 0,
        endTime?.getSeconds() ?? 0
      ).toISOString();

      if (allDay) {
        return `${newStartDate.split("T")[0]}/${newEndDate.split("T")[0]}`;
      }

      return `${newStartDate}/${newEndDate}`;
    });
}

export function formatPayloadForCreation(state: State) {
  const dates = formatDates(state.dates);

  return {
    sessionData: {
      dates,
      city: state.city,
      total: state.price.type === "total" ? state.price.value : null,
      totalWithTax: state.price.type === "total" ? state.priceWithTax : null,
      price: state.price.type === "perTrainee" ? state.price.value : null,
      priceWithTax: state.price.type === "perTrainee" ? state.priceWithTax : null,
      areas: state.areas,
      stock: state.max ?? null,
      minStock: state.min ?? null,
      trainers: state.trainers.map(({ uuid }) => uuid),
      room: state.room,
      reference: state.reference,
      link: state.link,
      evalGroupsGenerated: state.evalGroupsGenerated,
    },
    intraUuid: state.intra.uuid,
  };
}

export function formatPayloadForUpdate(state: State): IUpdateProjectData {
  const dates = formatDates(state.dates);

  return {
    city: state.city,
    dates,
    pricePerTrainee: state.price.type === "perTrainee" ? state.price.value : null,
    pricePerTraineeWithTax: state.price.type === "perTrainee" ? state.priceWithTax : null,
    bookingPrice: state.price.type === "total" ? state.price.value : null,
    bookingPriceWithTax: state.price.type === "total" ? state.priceWithTax : null,
    stock: state.max ?? null,
    minStock: state.min ?? null,
    selectedAreas: state.areas,
    selectedTrainers: state.trainers.map(({ uuid }) => uuid),
    room: state.room,
    link: state.link,
    reference: state.reference,
    evalGroupsGenerated: state.evalGroupsGenerated,
    isPostponed: state.isPostponed,
  };
}

export function manageDatesError(date: FormDate, t: TranslationType) {
  const [startDate, endDate] = date.dateRange || [];
  if (!isValidDate(startDate) || !isValidDate(endDate)) return "";

  if (startDate.getTime() > endDate.getTime()) {
    return (
      <AssistiveErrorText
        errorText={t("supervisor.sessions.errors.endDateBefore", {
          defaultValue:
            "La date de fin doit être postérieure ou égale à la date de début de session",
        })}
      />
    );
  }

  if (!date.allDay && startDate.getTime() === endDate.getTime()) {
    const [startHour, endHour] = date.hourRange || [];

    if (!isValidDate(startHour) || !isValidDate(endHour)) return "";

    if (startHour.getHours() === endHour.getHours()) {
      if (startHour.getMinutes() >= endHour.getMinutes()) {
        return (
          <AssistiveErrorText
            errorText={t("supervisor.sessions.errors.endHourBefore", {
              defaultValue: "L’heure de fin doit être postérieure à l’heure de début de session",
            })}
          />
        );
      }
    }
    if (startHour.getHours() > endHour.getHours()) {
      return (
        <AssistiveErrorText
          errorText={t("supervisor.sessions.errors.endHourBefore", {
            defaultValue: "L’heure de fin doit être postérieure à l’heure de début de session",
          })}
        />
      );
    }
  }

  return "";
}

export function processTimeError(
  index: number,
  timeError: { [key: number]: boolean },
  dateRange: Date[],
  hourRange?: Date[],
  allDay = false
) {
  if (allDay || !isValidDate(dateRange?.[0]) || !isValidDate(dateRange?.[1])) {
    return {
      ...timeError,
      [index]: false,
    };
  }

  if (
    dateRange[0].getTime() === dateRange[1].getTime() &&
    isValidDate(hourRange?.[0]) &&
    isValidDate(hourRange?.[1])
  ) {
    return {
      ...timeError,
      [index]:
        hourRange[0].getHours() > hourRange[1].getHours() ||
        (hourRange[0].getHours() === hourRange[1].getHours() &&
          hourRange[0].getMinutes() >= hourRange[1].getMinutes()),
    };
  }

  return {
    ...timeError,
    [index]: false,
  };
}

export function formatToDate({ date, hours }: { date: string; hours?: string }): Date {
  const fromZone = "Europe/Paris";

  const parsedDate = (date: string, format: string) => {
    const localDate = DateTime.fromFormat(date, format, {
      zone: fromZone,
    });
    const targetDate = localDate.toLocal();
    return targetDate.toJSDate();
  };

  if (hours) {
    return parsedDate(`${date} ${hours}`, "dd/MM/yyyy H:mm");
  }

  return parsedDate(date, "dd/MM/yyyy");
}

export function parsedDates(intervals: string[][]) {
  return intervals.map((interval) => {
    const intervalInfos = parseDateRange(interval, {
      dateFormat: "DD/MM/YYYY",
      hourFormat: "H:mm",
    });
    const { days, hours } = intervalInfos;
    const [startDay, endDay = startDay] = days;

    if (!hours) {
      return {
        dateRange: [formatToDate({ date: startDay }), formatToDate({ date: endDay })],
        allDay: true,
      };
    }
    if (hours) {
      const [startHour, endHour = startHour] = hours;
      return {
        dateRange: [formatToDate({ date: startDay }), formatToDate({ date: endDay })],
        hourRange: [
          formatToDate({ date: startDay, hours: startHour }),
          formatToDate({ date: endDay, hours: endHour }),
        ],
        allDay: false,
      };
    }

    return { dateRange: [new Date(), new Date()], allDay: true };
  });
}

export function disableReviewsCheckbox(reviewsState: GetSessionReviewsOutput) {
  if (reviewsState === undefined || reviewsState.evalGroupsGenerated === true) {
    if (
      reviewsState.hotReviewsAlreadyGenerated === true &&
      reviewsState.companyHasColdReviews === false
    )
      return true;
    if (
      reviewsState.hotReviewsAlreadyGenerated === true &&
      reviewsState.coldReviewsAlreadyGenerated === true
    )
      return true;
  }
  return false;
}

export function onlyColdReviewsWouldBeDisabled(reviewsState: GetSessionReviewsOutput) {
  return (
    reviewsState?.companyHasColdReviews === true &&
    reviewsState?.hotReviewsAlreadyGenerated === true &&
    reviewsState?.coldReviewsAlreadyGenerated === false
  );
}

export const sessionFormValidationSchema = (t: TranslationFunction, type: "create" | "update") => {
  const requiredField = t("common.form.validation.required", {
    defaultValue: "Ce champ est obligatoire",
  });

  return z
    .object({
      ...(type === "create"
        ? {
            intra: z
              .object({
                reference: z.string(),
              })
              .nullable()
              .superRefine((value, ctx) => {
                if (value === null) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: requiredField,
                  });
                }
              }),
          }
        : {
            summon: z.boolean().optional(),
          }),
      reference: z.string({
        required_error: requiredField,
      }),
      areas: z.array(z.string(), { required_error: requiredField }),
      city: z.string({
        required_error: requiredField,
      }),
      price: z.object({
        type: z.enum(["total", "perTrainee"]),
        value: z.number({ required_error: requiredField }).nonnegative(),
      }),
      priceWithTax: z.number().nonnegative().nullable().optional(),
      dates: z
        .array(
          z
            .object({
              allDay: z.boolean(),
              dateRange: z.array(z.date()),
              hourRange: z.array(z.date()).optional(),
            })
            .superRefine((dateConfig, ctx) => {
              if (!dateConfig.allDay) {
                if (dateConfig.hourRange?.length !== 2) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t("trainings.view.sessions.creation_modal.error_hour_range_required", {
                      defaultValue: "Vous devez renseigner une heure de début et de fin",
                    }),
                  });
                } else if (dateConfig.hourRange && dateConfig.dateRange) {
                  const [startHour, endHour] = dateConfig.hourRange;
                  const [startDate, endDate] = dateConfig.dateRange;
                  if (
                    !isValidDate(startHour) ||
                    !isValidDate(endHour) ||
                    !isValidDate(startDate) ||
                    !isValidDate(endDate)
                  ) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: t(
                        "trainings.view.sessions.creation_modal.error_date_hour_required",
                        {
                          defaultValue:
                            "Vous devez renseigner une date et une heure de début et de fin",
                        }
                      ),
                    });
                  }
                  if (startHour >= endHour && startDate.getTime() === endDate.getTime()) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: t(
                        "trainings.view.sessions.creation_modal.error_hour_range_invalid",
                        {
                          defaultValue: "L'heure de début doit être inférieure à l'heure de fin",
                        }
                      ),
                    });
                  }
                }
              }
              if (dateConfig.dateRange) {
                if (dateConfig.dateRange?.length !== 2) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t("trainings.view.sessions.creation_modal.error_date_missing", {
                      defaultValue: "Vous devez renseigner une date de début et de fin",
                    }),
                  });
                } else {
                  const [start, end] = dateConfig.dateRange;
                  if (start > end) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: t(
                        "trainings.view.sessions.creation_modal.error_date_range_invalid",
                        {
                          defaultValue: "La date de début doit être inférieure à la date de fin",
                        }
                      ),
                    });
                  }
                }
              }
            })
        )
        .min(
          1,
          t("trainings.view.sessions.creation_modal.error_date_range_required", {
            defaultValue: "Vous devez renseigner au moins renseinger une date",
          })
        ),
      evalGroupsGenerated:
        hasColdReviewsEnabled() || hasHotReviewsEnabled() ? z.boolean() : z.undefined(),
      trainers: z.array(
        z.object({
          uuid: z.string(),
          fullName: z.string(),
        })
      ),
      min: z.number().nonnegative().nullable().optional(),
      max: z.number().nonnegative().nullable().optional(),
    })
    .superRefine((session, ctx) => {
      const { min, max } = session;

      // Validate that min is less than or equal to max
      if (typeof min === "number" && typeof max === "number") {
        if (min > max) {
          // Error on 'min' field
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("supervisor.sessions.creationModal.minimum.inferior", {
              defaultValue: "La valeur minimale doit être inférieure ou égale à la valeur maximale",
            }),
            path: ["min"],
          });

          // Error on 'max' field (optional)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("supervisor.sessions.creationModal.maximum.superior", {
              defaultValue: "La valeur maximale doit être supérieure ou égale à la valeur minimale",
            }),
            path: ["max"],
          });
        }
      }

      // Additional validation for 'min' being non-negative
      if (typeof min === "number" && min < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("supervisor.sessions.creationModal.minimum.superior", {
            defaultValue: "La valeur minimale doit être supérieure ou égale à 0",
          }),
          path: ["min"],
        });
      }

      // Additional validation for 'max' being greater than 0
      if (typeof max === "number" && max <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("supervisor.sessions.creationModal.maximum.superior", {
            defaultValue: "La valeur maximale doit être supérieure à 0",
          }),
          path: ["max"],
        });
      }
    });
};
