import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Fuse from "fuse.js";
import { useCallback, useMemo } from "react";
import { isEmpty } from "lodash";

import { InterviewType, TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

import { transferTemplateCopy } from "./actions/transfer";
import { renameTemplate } from "./actions/renameTemplate";
import { duplicateTemplate } from "./actions/duplicateTemplate";
import { archiveTemplate } from "./actions/archiveTemplate";
import { unarchiveTemplate } from "./actions/unarchiveTemplate";
import { downloadTemplatePDF } from "./actions/downloadTemplate";
import { editParamsTemplate } from "./actions/editParamsTemplate";
import { createTemplate, type CreateTemplateParams } from "./actions/createTemplate";

export type Template = TemplatesRoutes.GetTemplates["response"]["templates"][0];
export type TemplateAction = TemplatesRoutes.GetTemplates["response"]["availableActions"][number];

/** Templates list */
async function getTemplates() {
  try {
    const result = await buildRequest<TemplatesRoutes.GetTemplates>({
      method: "GET",
      path: "/templates",
    })();

    return result;
  } catch (err) {
    return { templates: [], availableActions: [] };
  }
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

export function useTemplates(options: Options = defaultOptions) {
  const query = useQuery(["templates"], getTemplates, {
    ...defaultOptions,
    ...options,
  });

  const [archivedTemplates, templates]: [Template[], Template[]] = useMemo(
    () => splitTemplatesIntoActiveAndArchived(query.data?.templates ?? []),
    [query.data]
  );

  const availableActions = useMemo(() => {
    return query.data?.availableActions ?? [];
  }, [query.data]);

  const createMutation = useMutation(createTemplate, {
    mutationKey: ["create-trame"],
  });

  const unarchiveMutation = useMutation(unarchiveTemplate, {
    mutationKey: ["unarchive-trame"],
  });

  const create = useCallback(
    async (params: CreateTemplateParams) => {
      return createMutation.mutateAsync(params);
    },
    [createMutation]
  );

  const unarchiveByUuid = useCallback(
    async (templateUuid: string) => {
      const result = await unarchiveMutation.mutateAsync(templateUuid);
      await query.refetch();
      return result;
    },
    [unarchiveMutation, query]
  );

  const getPreviewLinksByUuid = useCallback(async (templateUuid: string) => {
    const preview = await getPreviewLink(templateUuid);
    return preview;
  }, []);

  const search = useCallback(
    async (search: string) => {
      if (isEmpty(search)) return templates;
      const fuse = new Fuse(templates, {
        keys: ["title"],
        threshold: 0.333,
        shouldSort: true,
        includeScore: true,
      });
      return fuse.search(search).map((result) => result.item);
    },
    [templates]
  );

  const searchOnArchived = useCallback(
    async (search: string) => {
      if (isEmpty(search)) return archivedTemplates;
      const fuse = new Fuse(archivedTemplates, {
        keys: ["title"],
        threshold: 0.333,
        shouldSort: true,
        includeScore: true,
      });
      return fuse.search(search).map((result) => result.item);
    },
    [archivedTemplates]
  );

  const canDoAction = useCallback(
    (action: (typeof availableActions)[number]) => {
      return availableActions.includes(action);
    },
    [availableActions]
  );

  const getByUuid = useCallback(
    (templateUuid: string) => {
      return templates.find((template) => template.uuid === templateUuid);
    },
    [templates]
  );

  return {
    templates,
    isLoading: query.isLoading,
    archivedTemplates,
    hasArchivedTemplates: archivedTemplates.length > 0,
    getByUuid,
    actions: {
      canDo: canDoAction,
      create,
      search,
      searchOnArchived,
      unarchiveByUuid,
      getPreviewLinksByUuid,
    },
  };
}

/** Individual template actions */

export const getPreviewLink = async (templateUuid: string) => {
  return buildRequest<TemplatesRoutes.Preview>({
    method: "GET",
    path: "/templates/{templateUuid}/v2/preview",
    params: { templateUuid },
  })();
};

export function useTemplate(templateUuid: string) {
  const queryClient = useQueryClient();

  const getPreviewLinks = useCallback(async () => {
    const preview = await getPreviewLink(templateUuid);
    return preview;
  }, [templateUuid]);

  const downloadPDF = useCallback(async () => {
    await downloadTemplatePDF(templateUuid);
  }, [templateUuid]);

  const archiveMutation = useMutation(archiveTemplate, {
    mutationKey: ["archive-trame"],
  });

  const renameMutation = useMutation(renameTemplate, {
    mutationKey: ["rename-trame"],
  });

  const editParamsMutation = useMutation(editParamsTemplate, {
    mutationKey: ["edit-params-trame"],
  });

  const archive = useCallback(async () => {
    const result = await archiveMutation.mutateAsync(templateUuid);
    await queryClient.invalidateQueries(["templates"]);
    return result;
  }, [archiveMutation, templateUuid, queryClient]);

  const rename = useCallback(
    async (title: string) => {
      const params = { templateUuid, title };
      const result = await renameMutation.mutateAsync(params);
      await queryClient.invalidateQueries(["templates"]);
      return result;
    },
    [renameMutation, templateUuid, queryClient]
  );

  const editParams = useCallback(
    async ({
      title,
      type,
      sections,
      choicesForOrdinalTargets,
    }: {
      title?: string;
      type?: InterviewType;
      sections?: Template["sections"];
      choicesForOrdinalTargets?: Template["choicesForOrdinalTargets"];
    }) => {
      const params = {
        templateUuid,
        title,
        type,
        sections,
        choicesForOrdinalTargets,
      };
      const result = await editParamsMutation.mutateAsync(params);
      await queryClient.invalidateQueries(["templates"]);
      return result;
    },
    [templateUuid, editParamsMutation, queryClient]
  );

  const saveContent = useCallback(
    async ({ sections, templateUuid }) => {
      const result = await editParamsMutation.mutateAsync({ sections, templateUuid });
      await queryClient.invalidateQueries(["templates"]);
      return result;
    },
    [editParamsMutation, queryClient]
  );

  const duplicate = useCallback(
    async (template: Template) => {
      await duplicateTemplate(template);
      await queryClient.invalidateQueries(["templates"]);
    },
    [queryClient]
  );

  const transferCopy = useCallback(
    async ({ toCompanyUuid, newTitle }: { toCompanyUuid: string; newTitle?: string }) => {
      await transferTemplateCopy({
        templateUuid,
        toCompanyUuid,
        newTitle,
      });
    },
    [templateUuid]
  );

  return {
    getPreviewLinks,
    rename,
    editParams,
    saveContent,
    archive,
    downloadPDF,
    transferCopy,
    duplicate,
  };
}

function splitTemplatesIntoActiveAndArchived(templates: Template[]): [Template[], Template[]] {
  return templates.reduce(
    ([archivedTemplates, activeTemplates], template) => {
      return template.archivedAt
        ? [[...archivedTemplates, template], activeTemplates]
        : [archivedTemplates, [...activeTemplates, template]];
    },
    [[], []]
  );
}
