import { createPortal } from "react-dom";
import { useRef, useMemo, useState, useEffect, RefObject, MutableRefObject } from "react";
import { Link, useParams, useHistory, useLocation, generatePath } from "react-router-dom";

import classNames from "classnames";
import { MdEdit as Edit } from "react-icons/md";

import CloseIcon from "@mui/icons-material/Close";
import { useVirtualizer } from "@tanstack/react-virtual";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ListUtils, getInitials } from "@skillup/shared-utils";
import { Flex, Grid, Text, Loader } from "@skillup/design-system";
import {
  DSButton,
  DSAvatar,
  FilterRef,
  DSTooltip,
  DSDropdown,
  UserPopover,
  DSDropdownItem,
} from "@skillup/ui";

import { Field } from "services/coreHR";
import { getUserInitials } from "utils/User";
import useTranslation from "hooks/useTranslation";

import { EmptyState } from "../EmptyState/EmptyState";
import { PeopleReviewRoutes } from "../../pages/router";
import { useMatrixData, type MatrixData } from "./useMatrixData";
import { View, views, Campaign, FiltersConfig } from "../../types";

import styles from "./TalentMatrix.module.scss";

const MAX_COLLABORATORS_FOR_ARBITRATED_MODE = 60;

const DRAWER_TYPES = {
  IDLE: "idle",
  NOT_POSITIONED_SELECTED: "not-positioned",
  POSITIONED_SELECTED: "positioned",
} as const;

type DrawerState =
  | { type: typeof DRAWER_TYPES.IDLE }
  | {
      type: typeof DRAWER_TYPES.NOT_POSITIONED_SELECTED;
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    }
  | {
      type: typeof DRAWER_TYPES.POSITIONED_SELECTED;
      xAxis: { id: string; scaleLabel: string; optionValue: number; optionLabel: string };
      yAxis: { id: string; scaleLabel: string; optionValue: number; optionLabel: string };
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    };

type TalentMatrixProps = {
  campaign: Campaign;
  filterRef: MutableRefObject<FilterRef>;
  drawer: RefObject<null | HTMLDivElement>;
  filterValues: ListUtils.FilterValues<FiltersConfig>;
};
export function TalentMatrix({ campaign, drawer, filterRef, filterValues }: TalentMatrixProps) {
  const { t } = useTranslation();
  const { currentView, drawerType } = useParams<{
    currentView: View;
    drawerType: string | undefined;
  }>();

  /**
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   */
  const scalesMap = campaign.scales.reduce((acc, scale) => {
    if (!acc[scale.id]) {
      acc[scale.id] = scale;
    }
    return acc;
  }, {});

  const {
    data,
    isLoading,
    mutations,
    xAxis: selectedXAxis,
    yAxis: selectedYAxis,
  } = useMatrixData({
    campaign,
    filters: filterValues,
    scalesMap,
  });

  const scales = useMemo(() => {
    return campaign.scales.map((scale) => scalesMap[scale.id]);
  }, [campaign.scales, scalesMap]);

  const cells = data?.matrix.flat() ?? [];

  const { nBoxWidth, xAxisLabel, xScaleLabels } = useX({ data });
  const { nBoxHeight, yAxisLabel, yScaleLabels } = useY({ data });

  const textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  > = useMemo(() => {
    return {
      "over-median": "plainText-onDark-default",
      p10: "plainText-onLight-default",
      p90: "plainText-onDark-default",
      "under-median": "plainText-onLight-default",
    };
  }, []);

  const history = useHistory();

  return (
    <Flex flexGrow={1}>
      <Flex gap="s" width="100%" flexDirection="column">
        {!data && (
          <Flex height="100%" alignItems="center" justifyContent="center" className={styles.loader}>
            <Loader fillSpace />
          </Flex>
        )}
        {data && (
          <>
            <Flex>
              <Flex flexGrow={1} flexDirection="column">
                <Text espaceFont="body1Bold" color="plainText-onLight-default">
                  {t("peopleReview.talentMatrix.positionedCollabCount", {
                    count: data.positionedCount,
                    defaultValue:
                      data.positionedCount > 1
                        ? `{{count}} collaborateurs positionnés`
                        : `{{count}} collaborateur positionné`,
                  })}
                </Text>
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                  {t("peopleReview.talentMatrix.positionedCollabsDetailsPart1", {
                    defaultValue: `Seuls les collaborateurs ayant été évalués sur ${xAxisLabel} et ${yAxisLabel} sont positionnés dans la matrice ci-dessous.`,
                  })}
                </Text>
                {(data.positionedCount >= 1 || data.notPositioned.reviews.length >= 1) && (
                  <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                    {t("peopleReview.talentMatrix.positionedCollabsDetailsPart2", {
                      defaultValue: `Cliquez sur une case pour afficher la liste des collaborateurs qu’elle contient.`,
                    })}
                  </Text>
                )}
              </Flex>
            </Flex>

            {currentView === views.detailed &&
            data.positionedCount > MAX_COLLABORATORS_FOR_ARBITRATED_MODE ? (
              <Flex
                padding="m"
                marginTop="m"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <EmptyState
                  label={t("peopleReview.talentMatrix.emptyState.activeArbitratedModeDisabled", {
                    count: MAX_COLLABORATORS_FOR_ARBITRATED_MODE,
                    defaultValue:
                      "Le mode détaillé n'est accessible que pour une matrice contenant moins de {{count}} collaborateurs positionnés",
                  })}
                />
                <Flex marginTop="s">
                  <DSButton
                    type="button"
                    buttonSize="M"
                    emphasis="High"
                    label={t("peopleReview.arbitratedMode.btn.leave", {
                      defaultValue: "Quitter le mode détaillé",
                    })}
                    onClick={() =>
                      history.push({
                        pathname: generatePath(PeopleReviewRoutes.PPRCampaign, {
                          campaignID: campaign.id,
                          currentView: views.matrix,
                        }),
                        search: location.search,
                      })
                    }
                  />
                </Flex>
              </Flex>
            ) : data.positionedCount < 1 && data.notPositioned.reviews.length < 1 ? (
              <Flex
                padding="m"
                marginTop="m"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <EmptyState
                  label={t("peopleReview.talentMatrix.emptyState.no-collaborators", {
                    defaultValue: "Aucun collaborateur ne correspond à vos filtres.",
                  })}
                />
                <Flex marginTop="s">
                  <DSButton
                    type="button"
                    emphasis="Low"
                    buttonSize="M"
                    label={t("peopleReview.talentGrid.resetFilters", {
                      defaultValue: "Réinitialiser les filtres",
                    })}
                    onClick={() => {
                      history.push(
                        generatePath(PeopleReviewRoutes.PPRCampaign, {
                          campaignID: campaign.id,
                          currentView: views.detailed,
                        })
                      );
                    }}
                  />
                </Flex>
              </Flex>
            ) : (
              <div>
                <div
                  className={classNames(
                    styles.container,
                    currentView === views.grid && styles.gridViewContainer
                  )}
                >
                  {selectedYAxis && (
                    <YAxis
                      allScales={scales}
                      scaleLabels={yScaleLabels}
                      label={selectedYAxis?.label}
                      selectedScales={[selectedXAxis, selectedYAxis]}
                      onAxisLabelClick={(scale) =>
                        mutations.swap({
                          newXAxisScaleID: selectedXAxis.id,
                          newYAxisScaleID: scale.id,
                        })
                      }
                    />
                  )}
                  {selectedXAxis && (
                    <XAxis
                      allScales={scales}
                      scaleLabels={xScaleLabels}
                      label={selectedXAxis?.label}
                      selectedScales={[selectedXAxis, selectedYAxis]}
                      onAxisLabelClick={(field) =>
                        mutations.swap({
                          newXAxisScaleID: field.id,
                          newYAxisScaleID: selectedYAxis.id,
                        })
                      }
                    />
                  )}
                  <SwapButton
                    onSwap={() => {
                      const oldX = selectedXAxis;

                      mutations.swap({
                        newXAxisScaleID: selectedYAxis.id,
                        newYAxisScaleID: oldX.id,
                      });
                    }}
                  />
                  {isLoading ? (
                    <Flex minHeight={"16rem"} justifyContent="center">
                      <Loader fillSpace />
                    </Flex>
                  ) : (
                    <Grid
                      className={styles.matrix}
                      gridTemplateColumnsCustom={`5rem repeat(${nBoxWidth}, 1fr)`}
                      gridTemplateRowsCustom={`repeat(${nBoxHeight}, auto) 3.125rem`}
                    >
                      <MatrixCells
                        cells={cells}
                        nBoxWidth={nBoxWidth}
                        textColor={textColor}
                        filterRef={filterRef}
                        nBoxHeight={nBoxHeight}
                        campaignID={campaign.id}
                        selectedXAxis={selectedXAxis}
                        selectedYAxis={selectedYAxis}
                      />
                    </Grid>
                  )}
                </div>

                {!isLoading && (
                  <Flex gap="xxs" flexDirection="column" alignItems="flex-start">
                    {data.notPositioned.reviews.length ? (
                      <Text espaceFont="body1Bold" color="plainText-onLight-default">
                        {t("people.talentMatrix.notPositionedCollab", {
                          count: data.notPositioned.reviews.length,
                          defaultValue:
                            data.notPositioned.reviews.length > 1
                              ? "{{count}} collaborateurs non positionnés"
                              : "{{count}} collaborateur non positionné",
                        })}
                      </Text>
                    ) : (
                      <Text espaceFont="body2Regular" color="plainText-onLight-default">
                        {t("peopleReview.talentMatrix.cell.notPositionnedCollabs.allPositioned", {
                          defaultValue: "Tous les collaborateurs sont positionnés",
                        })}
                      </Text>
                    )}

                    {!!data.notPositioned.reviews.length && (
                      <Flex
                        gap="xxs"
                        minWidth={"12rem"}
                        flexDirection="column"
                        className={classNames(styles.matrixCell, {
                          [styles.noSelected]: drawerType === DRAWER_TYPES.POSITIONED_SELECTED,
                          [styles.overMedian]: data.notPositioned.heatMapStatus === "over-median",
                          [styles.p10]: data.notPositioned.heatMapStatus === "p10",
                          [styles.p90]: data.notPositioned.heatMapStatus === "p90",
                          [styles.selected]: drawerType === DRAWER_TYPES.NOT_POSITIONED_SELECTED,
                          [styles.underMedian]: data.notPositioned.heatMapStatus === "under-median",
                        })}
                        onClick={() => {
                          const searchParams = new URLSearchParams(location.search);

                          history.replace({
                            pathname: generatePath(
                              PeopleReviewRoutes.PPRCampaignWithNotPositionedDrawer,
                              {
                                campaignID: campaign.id,
                                currentView,
                                drawerType: DRAWER_TYPES.NOT_POSITIONED_SELECTED,
                              }
                            ),
                            search: searchParams.toString(),
                          });
                        }}
                      >
                        {currentView === views.detailed ? (
                          <MatrixCellAvatar
                            textColor={textColor}
                            filterRef={filterRef}
                            campaignID={campaign.id}
                            cell={data.notPositioned}
                          />
                        ) : (
                          <MatrixCellDefault textColor={textColor} cell={data.notPositioned} />
                        )}
                      </Flex>
                    )}
                  </Flex>
                )}
              </div>
            )}
          </>
        )}
      </Flex>
      {drawer.current &&
        createPortal(
          <TalentMatrixDrawer
            data={data}
            cells={cells}
            filterRef={filterRef}
            campaignID={campaign.id}
            selectedYAxis={selectedYAxis}
            selectedXAxis={selectedXAxis}
            closeDrawer={() => {
              const searchParams = new URLSearchParams(location.search);

              history.replace({
                pathname: generatePath(PeopleReviewRoutes.PPRCampaign, {
                  campaignID: campaign.id,
                  currentView,
                }),
                search: searchParams.toString(),
              });
            }}
          />,
          drawer.current
        )}
    </Flex>
  );
}

type ArrowLeftProps = {
  label: string;
  hasMoreThanTwoFields: boolean;
  scales: NonNullable<Field["scale"]>[];
  currentScales: NonNullable<Field["scale"]>[];
  onChangeScale: (scale: NonNullable<Field["scale"]>) => void;
};

const ArrowLeft = ({
  currentScales,
  hasMoreThanTwoFields,
  label,
  onChangeScale,
  scales,
}: ArrowLeftProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowLeft)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>

      {hasMoreThanTwoFields && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<Edit />} />}
        >
          {scales?.map((scale) => (
            <DSDropdownItem
              key={scale.id}
              label={scale?.label}
              disabled={currentScales.includes(scale)}
              onClick={() => onChangeScale(scale)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type YAxisProps = {
  label: string;
  scaleLabels: string[];
  allScales: NonNullable<Field["scale"]>[];
  selectedScales: NonNullable<Field["scale"]>[];
  onAxisLabelClick: (scale: NonNullable<Field["scale"]>) => void;
};

const YAxis = ({ allScales, label, onAxisLabelClick, scaleLabels, selectedScales }: YAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.yAxis)}>
      <ArrowLeft
        label={label}
        scales={allScales}
        currentScales={selectedScales}
        hasMoreThanTwoFields={allScales.length > 2}
        onChangeScale={(scale) => onAxisLabelClick(scale)}
      />
    </Flex>
  </>
);

type ArrowBottomProps = {
  label: string;
  hasMoreThanTwoScales: boolean;
  scales: NonNullable<Field["scale"]>[];
  currentScales: NonNullable<Field["scale"]>[];
  onChangeField: (field: NonNullable<Field["scale"]>) => void;
};

const ArrowBottom = ({
  currentScales,
  hasMoreThanTwoScales,
  label,
  onChangeField,
  scales,
}: ArrowBottomProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowBottom)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>{" "}
      {hasMoreThanTwoScales && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          position="up-right"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<Edit />} />}
        >
          {scales.map((scale) => (
            <DSDropdownItem
              key={scale.id}
              label={scale?.label}
              disabled={currentScales.includes(scale)}
              onClick={() => onChangeField(scale)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type XAxisProps = {
  label: string;
  scaleLabels: string[];
  allScales: NonNullable<Field["scale"]>[];
  selectedScales: NonNullable<Field["scale"]>[];
  onAxisLabelClick: (scale: NonNullable<Field["scale"]>) => void;
};

const XAxis = ({ allScales, label, onAxisLabelClick, scaleLabels, selectedScales }: XAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.xAxis)}>
      <ArrowBottom
        label={label}
        scales={allScales}
        currentScales={selectedScales}
        hasMoreThanTwoScales={allScales.length > 2}
        onChangeField={(field) => onAxisLabelClick(field)}
      />
    </Flex>
  </>
);

type SwapButtonProps = {
  onSwap: () => void;
};

const SwapButton = ({ onSwap }: SwapButtonProps) => (
  <Flex alignItems="center" justifyContent="center" className={styles.axisSwap}>
    <DSTooltip direction="top" label="Inverser les axes">
      <DSButton iconOnly type="button" buttonSize="M" icon={<SwapHorizIcon />} onClick={onSwap} />
    </DSTooltip>
  </Flex>
);

type UseXParams = {
  data: MatrixData;
};

const useX = ({ data }: UseXParams) => {
  const nBoxWidth = data?.xAxis.options.length;
  const xAxisLabel = data?.xAxis.label;
  const xScaleLabels = data?.xAxis.options.map((option) => option?.label);

  return { nBoxWidth, xAxisLabel, xScaleLabels };
};

type UseYParams = {
  data: MatrixData;
};

const useY = ({ data }: UseYParams) => {
  const nBoxHeight = data?.yAxis.options.length;
  const yAxisLabel = data?.yAxis.label;
  const yScaleLabels = data?.yAxis.options
    .sort((a, b) => b.value - a.value)
    .map((option) => option?.label);

  return { nBoxHeight, yAxisLabel, yScaleLabels };
};

type TalentMatrixDrawerProps = {
  data: MatrixData;
  campaignID: string;
  closeDrawer: () => void;
  cells: MatrixData["matrix"][number];
  filterRef: TalentMatrixProps["filterRef"];
  selectedXAxis: NonNullable<Field["scale"]>;
  selectedYAxis: NonNullable<Field["scale"]>;
};

const TalentMatrixDrawer = ({
  campaignID,
  cells,
  closeDrawer,
  data,
  filterRef,
  selectedXAxis,
  selectedYAxis,
}: TalentMatrixDrawerProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [drawerState, setDrawerState] = useState<DrawerState>({ type: "idle" });

  const params = useParams<{ drawerType: string; xAxisValue: string; yAxisValue: string }>();

  useEffect(() => {
    if (params.drawerType === DRAWER_TYPES.NOT_POSITIONED_SELECTED) {
      setDrawerState({
        type: DRAWER_TYPES.NOT_POSITIONED_SELECTED,
        collaborators: data?.notPositioned.reviews ?? [],
      });
    } else if (params.xAxisValue) {
      const xAxisValue = params.xAxisValue;
      const yAxisValue = params.yAxisValue;

      if (xAxisValue && yAxisValue) {
        const cell = cells.find(
          (cell) => cell.xValue.toString() === xAxisValue && cell.yValue.toString() === yAxisValue
        );
        if (cell) {
          setDrawerState({
            type: DRAWER_TYPES.POSITIONED_SELECTED,
            collaborators: cell.reviews,
            xAxis: {
              id: selectedXAxis.id,
              optionLabel: selectedXAxis.options.find((o) => o.value === cell.xValue)?.label,
              optionValue: cell.xValue,
              scaleLabel: selectedXAxis.label,
            },
            yAxis: {
              id: selectedYAxis.id,
              optionLabel: selectedYAxis.options.find((o) => o.value === cell.yValue)?.label,
              optionValue: cell.yValue,
              scaleLabel: selectedYAxis.label,
            },
          });
        }
      }
    } else {
      setDrawerState({ type: DRAWER_TYPES.IDLE });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.notPositioned.reviews, params]);

  const cellSelectedRedirectionURL = useMemo(() => {
    if (drawerState.type !== DRAWER_TYPES.POSITIONED_SELECTED) return null;

    const params = new URLSearchParams(location.search);

    params.append(drawerState.xAxis.id, drawerState.xAxis.optionValue.toString());
    params.append(drawerState.yAxis.id, drawerState.yAxis.optionValue.toString());

    return `${generatePath(PeopleReviewRoutes.PPRCampaign, {
      campaignID,
      currentView: views.grid,
    })}?${params.toString()}`;
  }, [drawerState, location.search, campaignID]);

  if (drawerState.type === "idle") return null;

  return (
    <Flex padding="s" flexDirection="column" className={styles.talentMatrixDrawer}>
      <Flex padding="s" width={"100%"} className={styles.talentMatrixDrawerContainer}>
        <DSButton
          iconOnly
          label="close"
          icon={<CloseIcon />}
          className={styles.talentMatrixDrawerCloseButton}
          onClick={closeDrawer}
        />

        <Flex width={"100%"} flexDirection="column">
          <Flex flexDirection="column">
            {drawerState.type === DRAWER_TYPES.NOT_POSITIONED_SELECTED ? (
              <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
                {t("peopleReview.talentMatrix.drawer.notPositionedCollabs", {
                  defaultValue: "Collaborateurs non positionnés",
                })}
              </Text>
            ) : (
              <>
                <Flex marginBottom="xs" flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {drawerState.xAxis.scaleLabel}
                  </Text>
                  <Text espaceFont="body2Regular" color="plainText-onLight-default">
                    {drawerState.xAxis.optionLabel}
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {drawerState.yAxis.scaleLabel}
                  </Text>
                  <Text espaceFont="body2Regular" color="plainText-onLight-default">
                    {drawerState.yAxis.optionLabel}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>

          <Flex flex={1} marginTop="m" overflowY="auto" flexDirection="column">
            {!drawerState.collaborators.length ? (
              <Text espaceFont="body2Regular" color="plainText-onLight-default">
                {t("peopleReview.talentMatrix.drawer.positionedCollabs.empty", {
                  defaultValue: "Aucun collaborateur",
                })}
              </Text>
            ) : (
              <DrawerContent campaignID={campaignID} collaborators={drawerState.collaborators} />
            )}
          </Flex>
          <Flex marginTop="xs">
            {drawerState.type === DRAWER_TYPES.POSITIONED_SELECTED && filterRef.current && (
              <Link to={cellSelectedRedirectionURL}>
                <DSButton
                  emphasis="Mid"
                  buttonSize="S"
                  label={t("peopleReview.talentMatrix.drawer.goto-talentgrid", {
                    defaultValue: "Voir dans le tableau d'arbitrage",
                  })}
                />
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const DrawerContent = ({
  campaignID,
  collaborators,
}: {
  campaignID: string;
  collaborators: MatrixData["matrix"][number][number]["reviews"];
}) => {
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: collaborators.length,
    estimateSize: () => 34,
    getScrollElement: () => parentRef.current,
    overscan: 10,
  });

  const items = virtualizer.getVirtualItems();
  return (
    <div
      ref={parentRef}
      style={{
        contain: "strict",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{
            left: 0,
            position: "absolute",
            top: 0,
            transform: `translateY(${items[0]?.start ?? 0}px)`,
            width: "100%",
          }}
        >
          {items.map((virtualRow) => {
            const collaborator = collaborators[virtualRow.index];
            const userPopoverRedirectionParams = new URLSearchParams(location.search);
            userPopoverRedirectionParams.append("fullName", collaborator.fullName);
            const fullName =
              collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`;

            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
                className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
              >
                <Flex marginBottom="s" key={collaborator.id}>
                  <UserPopover
                    position="down-right"
                    properties={[collaborator.role]}
                    user={{
                      uuid: collaborator.id,
                      fullName,
                      initials: getUserInitials({ fullName }),
                    }}
                    profileBtn={{
                      label: t("peopleReview.talentMatrix.drawer.avatar-popover.goto-talentgrid", {
                        defaultValue: "Voir dans le tableau d'arbitrage",
                      }),
                      redirectOnClick: {
                        linkComponent: Link,
                        to: `${generatePath(PeopleReviewRoutes.PPRCampaign, {
                          campaignID,
                          currentView: views.grid,
                        })}?${userPopoverRedirectionParams.toString()}`,
                      },
                    }}
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <DSAvatar
                        size="S"
                        darkMode
                        type="Initials"
                        imageUrl={undefined}
                        initials={getInitials(collaborator)}
                        randomColorSeedString={collaborator.id}
                      />
                      <Text
                        marginLeft="xs"
                        espaceFont={"body2Regular"}
                        color="plainText-onLight-default"
                      >
                        {collaborator.fullName ??
                          `${collaborator.firstName} ${collaborator.lastName}`}
                      </Text>
                    </Flex>
                  </UserPopover>
                </Flex>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

type MatrixCellsProps = {
  nBoxWidth: number;
  nBoxHeight: number;
  campaignID: string;
  cells: MatrixData["matrix"][number];
  filterRef: MutableRefObject<FilterRef>;
  selectedYAxis: NonNullable<Field["scale"]>;
  selectedXAxis: NonNullable<Field["scale"]>;
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
};

const YAxisLabel = ({ label }: { label: string }) => (
  <Flex alignItems="center" className={styles.yScaleLabelContainer}>
    <p className={styles.yScaleLabel}>{label}</p>
  </Flex>
);

const MatrixCell = ({
  campaignID,
  cell,
  filterRef,
  textColor,
}: {
  nBoxWidth: number;
  campaignID: string;
  filterRef: MutableRefObject<FilterRef>;
  cell: MatrixData["matrix"][number][number];
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
}) => {
  const { currentView, drawerType, xAxisValue, yAxisValue } = useParams<{
    currentView: View;
    xAxisValue: string | undefined;
    yAxisValue: string | undefined;
    drawerType: string | undefined;
  }>();
  const history = useHistory();

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={currentView === views.detailed ? "flex-start" : "center"}
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);

        history.replace({
          pathname: generatePath(PeopleReviewRoutes.PPRCampaignWithPositionedDrawer, {
            campaignID,
            currentView,
            drawerType: DRAWER_TYPES.POSITIONED_SELECTED,
            xAxisValue: cell.xValue.toString(),
            yAxisValue: cell.yValue.toString(),
          }),
          search: searchParams.toString(),
        });
      }}
      className={classNames(
        currentView === views.detailed && styles.detailedViewMatrixCell,
        styles.matrixCell,
        {
          [styles.noSelected]:
            (drawerType === DRAWER_TYPES.POSITIONED_SELECTED &&
              (cell.xValue.toString() !== xAxisValue || cell.yValue.toString() !== yAxisValue)) ||
            drawerType === DRAWER_TYPES.NOT_POSITIONED_SELECTED,
          [styles.overMedian]: cell.heatMapStatus === "over-median",
          [styles.p10]: cell.heatMapStatus === "p10",
          [styles.p90]: cell.heatMapStatus === "p90",
          [styles.selected]:
            drawerType === DRAWER_TYPES.POSITIONED_SELECTED &&
            cell.xValue.toString() === xAxisValue &&
            cell.yValue.toString() === yAxisValue,
          [styles.underMedian]: cell.heatMapStatus === "under-median",
        }
      )}
    >
      {currentView === views.detailed ? (
        <MatrixCellAvatar
          cell={cell}
          textColor={textColor}
          filterRef={filterRef}
          campaignID={campaignID}
        />
      ) : (
        <MatrixCellDefault cell={cell} textColor={textColor} />
      )}
    </Flex>
  );
};

const XAxisLabels = ({ options }: { options: Field["scale"]["options"] }) => (
  <>
    {options.map((option) => (
      <Flex
        alignItems="center"
        justifyContent="center"
        key={`x-${option.label}`}
        className={styles.xScaleLabelContainer}
      >
        <p className={styles.xScaleLabel}>{option.label}</p>
      </Flex>
    ))}
  </>
);

const MatrixCells = ({
  campaignID,
  cells,
  filterRef,
  nBoxHeight,
  nBoxWidth,
  selectedXAxis,
  selectedYAxis,
  textColor,
}: MatrixCellsProps) => {
  const cellsRender = useMemo(() => {
    const render = [];
    for (let index = 0; index < cells.length; index++) {
      const cell = cells[index];
      if (index % nBoxWidth === 0) {
        const label = selectedYAxis.options[nBoxHeight - 1 - Math.floor(index / nBoxWidth)]?.label;
        render.push(<YAxisLabel label={label} key={`y-${label}`} />);
      }

      render.push(
        <MatrixCell
          cell={cell}
          nBoxWidth={nBoxWidth}
          textColor={textColor}
          filterRef={filterRef}
          campaignID={campaignID}
          key={`${cell.xValue}-${cell.yValue}`}
        />
      );
    }

    render.push(<div key="spacer" />);
    render.push(<XAxisLabels options={selectedXAxis.options} />);
    return render;
  }, [
    selectedXAxis.options,
    cells,
    nBoxWidth,
    textColor,
    filterRef,
    campaignID,
    selectedYAxis.options,
    nBoxHeight,
  ]);

  return <>{cellsRender}</>;
};

type MatrixCellProps = {
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
  cell: {
    fraction: number;
    reviews: MatrixData["notPositioned"]["reviews"];
    heatMapStatus: MatrixData["notPositioned"]["heatMapStatus"];
  };
};

const MatrixCellDefault = ({ cell, textColor }: MatrixCellProps) => {
  const { t } = useTranslation();

  const userInCellCount = cell.reviews.length;

  return (
    <>
      <DSTooltip
        direction="right"
        label={`${(cell.fraction ?? 0).toFixed(2)} %`}
        darkMode={cell.heatMapStatus === "p90" || cell.heatMapStatus === "over-median"}
      >
        <Text espaceFont="h2" color={textColor[cell.heatMapStatus]}>
          {Math.round(cell.fraction ?? 0)} %
        </Text>
      </DSTooltip>

      <Text espaceFont="captionBold" color={textColor[cell.heatMapStatus]}>
        {t("peopleReview.talentMatrix.positionedCollabDetails", {
          count: userInCellCount,
          defaultValue:
            userInCellCount > 1 ? "{{count}} collaborateurs" : "{{count}} collaborateur",
        })}
      </Text>
    </>
  );
};

type MatrixCellAvatarProps = {
  campaignID: string;
  filterRef: MutableRefObject<FilterRef>;
  cell: {
    reviews: MatrixData["notPositioned"]["reviews"];
    heatMapStatus: MatrixData["notPositioned"]["heatMapStatus"];
  };
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
};
const MatrixCellAvatar = ({ campaignID, cell, filterRef, textColor }: MatrixCellAvatarProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Flex width="100%" flexWrap="wrap">
      {!!cell.reviews.length &&
        cell.reviews.map((collaborator) => (
          <Flex margin="xxs" flex="0 0 10rem" alignItems="center" key={collaborator.id}>
            <UserPopover
              position="down-right"
              properties={[collaborator.role]}
              user={{
                uuid: collaborator.id,
                fullName:
                  collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`,
                initials: getInitials({
                  fullName:
                    collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`,
                }),
              }}
              profileBtn={{
                label: t("peopleReview.talentMatrix.drawer.avatar-popover.goto-talentgrid", {
                  defaultValue: "Voir dans le tableau d'arbitrage",
                }),
                onClick: () => {
                  filterRef.current.addFilter("fullName");
                  filterRef.current.setValue("fullName", {
                    type: "text",
                    operator: "contains",
                    value: collaborator.fullName,
                  });

                  const searchParams = new URLSearchParams(location.search);

                  history.push({
                    pathname: generatePath(PeopleReviewRoutes.PPRCampaign, {
                      campaignID,
                      currentView: views.grid,
                    }),
                    search: searchParams.toString(),
                  });
                },
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <DSAvatar
                  size="S"
                  darkMode
                  type="Initials"
                  imageUrl={undefined}
                  initials={getInitials(collaborator)}
                  randomColorSeedString={collaborator.id}
                />
                <Text
                  marginLeft="xs"
                  espaceFont="captionBold"
                  color={textColor[cell.heatMapStatus]}
                >
                  {collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`}
                </Text>
              </Flex>
            </UserPopover>
          </Flex>
        ))}
    </Flex>
  );
};
