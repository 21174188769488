import useTranslation from "hooks/useTranslation";

import DSLayout from "components/DSLayout";

export default function ReferenceNewInterView() {
  const { t } = useTranslation();

  return (
    <DSLayout
      title={t("trainings.view.reference_new_inter.title", {
        defaultValue: "Création d'un programme inter",
      })}
    >
      <></>
    </DSLayout>
  );
}
