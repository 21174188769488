import { ClosedQuestionResponse } from "@skillup/espace-rh-bridge";
import { TranslationType } from "hooks/useTranslation";

import { user } from "../ClosedEndedQuestion";
import styles from "./UnresponsiveUsersBanner.module.scss";

function getUnresponsiveUsersCount(allUsers: user[], dashboardPayload: ClosedQuestionResponse) {
  return (
    allUsers.length -
    dashboardPayload.answers.reduce((acc, answer) => {
      return acc + answer.replies.count;
    }, 0)
  );
}

export const UnresponsiveUsersBanner = ({
  users,
  dashboardPayload,
  t,
}: {
  users: user[];
  dashboardPayload: ClosedQuestionResponse;
  t: TranslationType;
}) => {
  const unresponsiveUsersCount = getUnresponsiveUsersCount(users, dashboardPayload);

  return (
    unresponsiveUsersCount > 0 && (
      <div className={styles.infos}>
        <p>
          {t("dashboard.interviews.unresponsiveuser.optional", {
            defaultValue: "La réponse à cette question n'est pas obligatoire.",
          })}
        </p>
        <div className={styles.users}>
          <p>
            {t("dashboard.interviews.unresponsiveuser", {
              defaultValue: "{{ count }} collaborateur n'a pas répondu",
              defaultValue_other: "{{ count }} collaborateurs n'ont pas répondu",
              count: unresponsiveUsersCount,
            })}
          </p>
        </div>
      </div>
    )
  );
};
