import React from "react";

import { DSModal } from "@skillup/ui";

import styles from "./ValidateSummonModal.module.scss";
import { useMachine } from "@xstate/react";
import useTranslation from "hooks/useTranslation";
import { confirmMachine } from "../../../../../../stateMachine/confirm";

export function ValidateSummoningModal({
  onClose,
  onConfirm,
  onBack,
  children,
  title,
  confirmButtonLabel,
  cancelButtonLabel,
}: {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onBack: () => void;
  children: React.ReactElement | React.ReactElement[];
  title: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}) {
  const [current, send] = useMachine(confirmMachine, {
    actions: {
      onBack: () => onBack(),
      onClose: () => onClose(),
    },
    services: {
      onConfirm: async () => onConfirm(),
    },
  });
  const { t } = useTranslation();

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={() => send("ABORT")}>
        <DSModal.Header.Title title={title} />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.ModalContent}>{children}</div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={() => send("BACK")}
          disabled={current.context.loading}
          label={
            cancelButtonLabel ??
            t("trainings.common.back.label", {
              defaultValue: "Retour",
            })
          }
        />
        <DSModal.Footer.PrimaryButton
          loading={current.context.loading}
          onClick={() => send("CONFIRM")}
          label={
            confirmButtonLabel ??
            t("trainings.common.confirm.label", {
              defaultValue: "Confirmer",
            })
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
}
