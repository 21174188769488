import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Flex, Label as DSLabel, DSNumberInput, Select } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";

import { Divided } from "../ChildWrappers";

import styles from "./TrainingsHistory.module.scss";

type TrainingsHistory = Extract<Child, { kind: "trainingsHistory" }>;

export function TrainingsHistoryChild({
  child,
  indexes,
  updateTemplate,
  t,
}: {
  child: TrainingsHistory;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  updateTemplate: (data: TrainingsHistory) => void;
  t: TranslationType;
}) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const changeDurationNumber = useCallback(
    (newMaxAge: TrainingsHistory["maxAge"]) => {
      updateTemplate({
        ...child,
        maxAge: newMaxAge,
      });
    },
    [child, updateTemplate]
  );

  const changeDurationUnit = useCallback(
    (newUnit: TrainingsHistory["ageUnit"]) => {
      updateTemplate({
        ...child,
        ageUnit: newUnit,
      });
    },
    [child, updateTemplate]
  );

  return (
    <Divided>
      <Flex column className={styles.trainingsHistoryChild}>
        <p>
          {t("interviews.templateBuilder.trainingsHistoryChild.header", {
            defaultValue:
              "Le bloc « Historique de formation » liste l’ensemble des formations qu’a réalisées le collaborateur.",
          })}
        </p>

        <Flex column className={styles.trainingsHistoryChild__timeSettings}>
          <DSLabel
            label={t("interviews.templateBuilder.maxAge", {
              defaultValue: "Durée de l'historique",
            })}
            required
          />
          <Flex row>
            <DSNumberInput
              min={1}
              max={20}
              name="interviews-history-maxAge"
              value={child.maxAge?.toString() ?? undefined}
              onChange={(newMaxAge) => changeDurationNumber(Number(newMaxAge))}
              autoFocus={highlightUuid === child.uuid}
            />

            <Select
              className={styles.trainingsHistoryChild__unitSelect}
              options={[
                {
                  label: t("interviews.templateBuilder.month", { defaultValue: "Mois" }),
                  value: "months",
                },
                {
                  label: t("interviews.templateBuilder.years", { defaultValue: "An(s)" }),
                  value: "years",
                },
              ]}
              value={child.ageUnit}
              onChange={(newUnit) => changeDurationUnit(newUnit)}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="interviewsHistory"
          order={["comment", "downloadPDF"]}
          override={{
            comment: t("interviews.templateBuilder.trainingsHistory.permissions.comment", {
              defaultValue: "Qui peut commenter l'historique de formation ?",
            }),
          }}
          t={t}
        />
      </Flex>
    </Divided>
  );
}
