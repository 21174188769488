import { DSModal } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import styles from "./ConfirmationModal.module.scss";

const CANCEL_LABEL = "Annuler";

interface ConfirmationModalProps {
  title: string;
  isOpen: boolean;
  saveLabel: string;
  confirm: () => void;
  content: JSX.Element;
  closeModal: () => void;
}

const ConfirmationModal = ({
  closeModal,
  confirm,
  content,
  isOpen,
  saveLabel,
  title,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <DSModal isOpen={isOpen} portalTarget={document.body} className={styles.confirmationModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={title} />
      </DSModal.Header>

      <DSModal.Content>
        <Flex paddingVertical="s" flexDirection="column">
          {content}
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("portal.skills.skillsList.modalCustomFields.cancelLabel", {
            defaultValue: CANCEL_LABEL,
          })}
        />
        <DSModal.Footer.PrimaryButton label={saveLabel} onClick={confirm} />
      </DSModal.Footer>
    </DSModal>
  );
};

export default ConfirmationModal;
