import { TargetsRoutes } from "@skillup/espace-rh-bridge";
import { useModal } from "@skillup/ui";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { buildRequest } from "utils/buildRequest";
import { UserTargetDetailModal } from "../components";

async function getUserTarget({ userUuid, targetUuid }: { userUuid: string; targetUuid: string }) {
  try {
    const result = await buildRequest<TargetsRoutes.GetUserTarget>({
      method: "GET",
      path: "/user/{userUuid}/target/{targetUuid}",
      params: { userUuid, targetUuid },
    })();
    return result;
  } catch (err) {
    return null;
  }
}

export const useUserTargetDetailModal = ({
  userUuid,
  handleDeleteTarget,
}: {
  userUuid: string;
  handleDeleteTarget: (targetUuid: string) => void;
}) => {
  const { isOpen, show, hide } = useModal();
  const [targetUuid, setTargetUuid] = useState<string | null>(null);

  const {
    data: target,
    isLoading,
    error,
  } = useQuery(
    ["userTarget", userUuid, targetUuid],
    () => getUserTarget({ userUuid, targetUuid }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!targetUuid,
    }
  );

  const openUserTargetDetailModal = useCallback(
    (targetUuid: string) => {
      show();
      setTargetUuid(targetUuid);
    },
    [show, setTargetUuid]
  );

  const closeModal = useCallback(() => {
    setTargetUuid(null);
    hide();
  }, [hide, setTargetUuid]);

  const userTargetDetailModal = useMemo(() => {
    // TODO handle error
    if (!target || isLoading || error) {
      return null;
    }
    return (
      <UserTargetDetailModal
        isOpen={isOpen}
        target={target}
        closeModal={closeModal}
        handleDeleteTarget={handleDeleteTarget}
      />
    );
  }, [target, isLoading, error, isOpen, closeModal, handleDeleteTarget]);

  return {
    userTargetDetailModal,
    openUserTargetDetailModal,
  };
};
