import { useState, useEffect } from "react";

import { MdSearch as Search } from "react-icons/md";

import { format } from "@skillup/shared-utils";
import { Flex, Text } from "@skillup/design-system";
import { DSModal, DSTextInput, HorizontalDivider } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { useActions } from "services/peopleReview";

import ArchiveActionButton from "./../../buttons/ArchiveActionButton";

import styles from "./ArchivedActions.module.scss";

interface ArchivedActionsModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const ArchivedActionsModal = ({ closeModal, isOpen }: ArchivedActionsModalProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { archivedActions } = useActions();

  const archivedActionsFiltered = archivedActions?.filter((action) =>
    action.label.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    if (archivedActions.length === 0) closeModal();
  }, [archivedActions.length, closeModal]);

  return (
    <DSModal isOpen={isOpen} portalTarget={document.body} className={styles.actionArchivedModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={
            archivedActions?.length > 1
              ? t("supervisor.people-review-management.actions.archivedActions", {
                  count: archivedActions?.length,
                  defaultValue: "{{count}} actions archivées",
                })
              : t("supervisor.people-review-management.actions.archivedAction", {
                  defaultValue: "1 action archivée",
                })
          }
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex height="100%" flexDirection="column">
          <Flex height="100%" marginTop="xs" flexDirection="row" className={styles.modalSearch}>
            <DSTextInput
              value={searchQuery}
              name="searchAction"
              actionButton={<Search />}
              className={styles.searchInput}
              onChange={setSearchQuery}
              placeholder={t(
                "supervisor.people-review-management.actions.archivedModal.searchPlaceholder",
                {
                  defaultValue: "Rechercher une action",
                }
              )}
            />
          </Flex>

          {archivedActionsFiltered.length > 0 ? (
            <Flex height="100%" flexDirection="column">
              {archivedActionsFiltered?.map((action, index) => (
                <Flex width="100%" key={action.uuid} flexDirection="column">
                  <Flex gap="xxs" width="100%" alignItems="center">
                    <Flex width="100%" flexDirection="column">
                      <Flex alignItems="center">
                        <div
                          className={styles["status-indicator"]}
                          style={{
                            backgroundColor: action.color,
                          }}
                        ></div>
                        <Text
                          fontSize="1rem"
                          espaceFont="body1Regular"
                          color="plainText-onLight-default"
                        >
                          {action.label}
                        </Text>
                      </Flex>

                      {action.archival && (
                        <Text
                          fontSize="0.75rem"
                          espaceFont="body1Regular"
                          color="plainText-onLight-lighter"
                        >
                          {t("actions.archivedModal.row.archivedAtBy", {
                            archivedDate: format(new Date(action.archival.date), "dd/MM/yyyy"),
                            archivedUser: action?.archival.author.name,
                            defaultValue:
                              "Action archivée le {{archivedDate}} par {{archivedUser}}",
                          })}
                        </Text>
                      )}
                    </Flex>
                    <ArchiveActionButton action={action} />
                  </Flex>
                  {archivedActionsFiltered?.length - 1 !== index && (
                    <HorizontalDivider top={"xs"} bottom={"xs"} />
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <Text marginBottom="s" espaceFont="body1Regular">
              {t("supervisor.people-review-management.actions.archivedModal.noResult", {
                defaultValue: "Aucune action ne correspond à votre saisie",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};
