import React, { useState } from "react";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";
import type { InterviewUser } from "@skillup/types";
import { isUndefined } from "lodash";
import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";

import { QuestionsStep, FinalStep, MailingSettings } from "./components/Steps";
import { createNewCampaign as createNewCampaignRequest } from "./requests/CreateCampain";
import { Step } from "./models/Step";

import CreateFormInterviewWrapper from "./CreateInterviewFormWrapper";
import useTranslation from "hooks/useTranslation";
import OptionsStep from "./components/Steps/OptionsStep";

interface IState {
  titleForHR: string;
  titleForAttendees: string;
  template?: { title: string; uuid: string };
  hideFromEmployeeUntil?: { managerState: InterviewUser["state"] };
  managerOnly?: boolean;
  confidential: boolean;
  mailingSettings: CampaignMailingSettings;
}

const CreateNewAdHocInterviews = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    titleForHR: "",
    titleForAttendees: "",
    confidential: false,
    mailingSettings: {
      notificationCreated: true,
      notificationManagerChange: true,
      notificationShared: true,
      notificationSigned: true,
      notificationReopen: true,
      reminderMinus14: true,
      reminderMinus7: true,
      reminderMinus1: true,
      reminderPlus1: true,
    },
  });

  const {
    titleForHR,
    titleForAttendees,
    template,
    hideFromEmployeeUntil,
    managerOnly,
    confidential,
    mailingSettings,
  } = state;

  const [canCreateCampaign, setCanCreateCampaign] = useState(false);

  const steps: Step[] = [
    {
      label: t("interviews.campaigncreation.step.modelandname", {
        defaultValue: "Trame et nom",
      }),
      id: "modelAndName",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <QuestionsStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          setQuestionsTemplate={(template) => setState({ template })}
          setCampaignTitle={(campaignName, scope: "HR" | "attendees") => {
            if (scope === "HR") {
              setState({ titleForHR: campaignName });
            } else if (scope === "attendees") {
              setState({ titleForAttendees: campaignName });
            }
          }}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.workflowandconfidentiality", {
        defaultValue: "Workflow et confidentialité",
      }),
      id: "workflowAndConfidentiality",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <OptionsStep
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          setHideFromEmployeeUntil={(hideFromEmployeeUntil) => setState({ hideFromEmployeeUntil })}
          managerOnly={managerOnly}
          setManagerOnly={(managerOnly) => setState({ managerOnly })}
          confidential={confidential}
          setConfidentiality={(confidential) => setState({ confidential })}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.notifications", {
        defaultValue: "Notifications",
      }),
      id: "notifications",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <MailingSettings
          mailingSettings={mailingSettings}
          updateOne={(key, enabled) =>
            setState({ mailingSettings: { ...mailingSettings, [key]: enabled } })
          }
          updateAllNotif={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("notification"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          updateAllRemind={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("remind"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          warning={false}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.summary", {
        defaultValue: "Synthèse",
      }),
      id: "summary",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <FinalStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          type="adHoc"
          setCanCreateCampaign={setCanCreateCampaign}
          managerOnly={managerOnly}
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          confidential={confidential}
        />
      ),
    },
  ];

  const createNewCampaign = async (toggleLoading: (nextValue?: boolean) => void) => {
    const campaignUuid = await createNewCampaignRequest({
      interviews: [],
      toggleLoading,
      t,
      confidential,
      data: {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "adHoc",
        hideFromEmployeeUntil,
        mailingSettings,
      },
    });

    if (campaignUuid) history.push(`/responsable/campagne/${campaignUuid}`);
  };
  return (
    <CreateFormInterviewWrapper
      steps={steps}
      title={t("interviews.chooseCampaignTypeModal.type.oneOff", {
        defaultValue: "Entretiens ponctuels",
      })}
      sendButtonLabel={t("interviews.chooseCampaignTypeModal.type.oneOff.create", {
        defaultValue: "Créer les entretiens",
      })}
      createNewCampaign={createNewCampaign}
      canCreateCampaign={canCreateCampaign}
    />
  );
};

export default CreateNewAdHocInterviews;
