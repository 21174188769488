import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getHierarchyManagers(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.GetHierarchyManagers>({
    method: "GET",
    params: {
      campaignID,
    },
    path: `/campaigns/{campaignID}/hierarchy-managers`,
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
