import { DSAlert, DSAlertDisplay, DSAlertType, DSButton, DSModal, useModal2 } from "@skillup/ui";
import { type UserTarget } from "@skillup/espace-rh-bridge";

import useTranslation from "hooks/useTranslation";

interface Props {
  readonly target: UserTarget;
  readonly onDelete: () => void;
}

export const DeleteTargetButton = ({ target, onDelete }: Props) => {
  const { t } = useTranslation();
  const { isOpen: isDeleteModalOpen, show: showDeleteModal, hide: hideDeleteModal } = useModal2();

  return (
    <>
      <DSButton
        emphasis="Low"
        buttonSize="S"
        onClick={() => {
          showDeleteModal();
        }}
        label={t("interview.user.target.delete", {
          defaultValue: `[DEV] Supprimer l’objectif`,
        })}
      />
      <DSModal isOpen={isDeleteModalOpen}>
        <DSModal.Header onClose={hideDeleteModal}>
          <DSModal.Header.Title
            title={t("interview.user.target.delete.title", {
              defaultValue: `Supprimer l’objectif « {{name}} »`,
              name: target.name,
            })}
          />
        </DSModal.Header>

        <DSModal.Content>
          <></>

          <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
            {t("interview.user.target.delete.message", {
              defaultValue: `ATTENTION : Vous êtes sur le point de supprimer cet objectif. Cette action est irréversible.`,
            })}
          </DSAlert>
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={hideDeleteModal}
            label={t("interview.user.target.delete.cancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            label={t("interview.user.target.delete.confirm", {
              defaultValue: "Confirmer la suppression",
            })}
            onClick={onDelete}
          />
        </DSModal.Footer>
      </DSModal>
    </>
  );
};
