import React, { useState, useEffect } from "react";
import { isEmpty, uniq } from "lodash";

import { FieldSection } from "@skillup/types";
import { ScheduleRoutes, ScheduleFieldsRoute } from "@skillup/espace-rh-bridge";

import useTranslation, { type TranslationType } from "hooks/useTranslation";
import { FieldRoutesType } from "types/api";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import Colors from "uiAssets/Colors";
import Loader from "components/Loader";
import InteractiveButton from "components/InteractiveButton";

import EditInput from "./EditInput";
import styles from "./Fields.module.scss";

interface IProps {
  data: Array<FieldSection>;
  rowsUuid: Array<string>;
  fetch: () => void;
  reloadRows: (uuids: string[]) => Promise<void>;
  companyFields: FieldRoutesType.GetV2["response"];
}

const updateFields = async (
  rowsUuid: Array<string>,
  fields: Array<{ binding: string; value: string | number | boolean }>,
  fetch: () => void,
  t: TranslationType
) => {
  if (rowsUuid.length === 1) {
    await buildRequest<ScheduleFieldsRoute.Patch>({
      method: "POST",
      path: "/scheduleRow/fields/{rowUuid}",
      params: { rowUuid: rowsUuid[0] },
      payload: {
        fields,
      },
    })();
  } else {
    await buildRequest<ScheduleRoutes.PatchFields>({
      method: "POST",
      path: "/scheduleRow/fields",
      payload: {
        rowsUuid,
        fields,
      },
    })();

    Acta.dispatchEvent("sendAppMessage", {
      message: t("trainingSchedule.sidePanel.update_fields.success", {
        defaultValue: "Modifications effectuées sur {{ count }} lignes",
        count: rowsUuid.length,
      }),
      type: "success",
    });
  }

  fetch();
};

export default (props: IProps) => {
  const { data, rowsUuid, fetch, reloadRows, companyFields } = props;
  const { t } = useTranslation();

  if (isEmpty(data)) {
    return <Loader />;
  }
  const [blocks, setBlocks] = useState<FieldSection[]>(data);
  const [changedFields, setChangedFields] = useState<string[]>([]);

  useEffect(() => {
    setBlocks(data);
  }, [data]);

  useEffect(() => {
    setChangedFields([]); // reset changed when row changes
  }, [rowsUuid]);

  const saveChanges = async () => {
    if (rowsUuid.length > 1) {
      Acta.setState("modalDisplayed", {
        content: (
          <div className={styles.confirmationModaleContent}>
            <label className={styles.modaleMessage}>
              {t("trainings.view.schedule_row.sidepanel.modifyModal.description", {
                defaultValue:
                  "Êtes-vous sûr.e de vouloir modifier les {{ count }} lignes sélectionnées ?",
                count: rowsUuid.length,
              })}
            </label>
            <div className={styles.warning}>
              <label className={styles.warningMessage}>
                {t("trainings.view.schedule_row.sidepanel.modifyModal.warning", {
                  defaultValue: "Attention, les valeurs précédentes seront écrasées.",
                })}
              </label>
            </div>
            <div className={styles.buttonsWrapper}>
              <InteractiveButton
                label={t("trainings.view.schedule_row.sidepanel.modifyModal.submit", {
                  defaultValue: "Oui, appliquer",
                })}
                className={styles.modaleButton}
                onClick={() => {
                  Acta.dispatchEvent("closeModal");
                  persitValues();
                }}
              />
            </div>
          </div>
        ),
        size: "small",
        title: t("trainings.view.schedule_row.sidepanel.modifyModal.title", {
          defaultValue: "Enrichissement en masse",
        }),
        showOverflow: true,
      });
    } else {
      await persitValues();
    }
  };

  const persitValues = async () => {
    if (changedFields.length === 0) return;
    const blockFields = blocks.reduce((fields, block) => {
      return [...fields, ...block.fields];
    }, []);

    const fieldsToUpdate = changedFields
      .map((fieldName) => blockFields.find((field) => field.fieldName === fieldName))
      .map(({ fieldName, value, rawValue, type }) => {
        if (type === "select") {
          return { binding: fieldName, value: rawValue };
        }

        return { binding: fieldName, value };
      });

    await updateFields(rowsUuid, fieldsToUpdate, fetch, t);

    reloadRows(rowsUuid);

    setChangedFields([]);
  };

  const handleChange = (blockIndex: number) => (fieldName: string, newValue: string | number) => {
    const updatedBlocks = [...blocks];
    updatedBlocks[blockIndex].fields = updatedBlocks[blockIndex].fields.map((field) => {
      if (field.fieldName !== fieldName) return field;
      const value = ["number", "monetary"].includes(field.type)
        ? parseFloat(newValue as string)
        : newValue;

      return {
        ...field,
        value,
        rawValue: field.type === "monetary" ? (value as number) * 100 : value,
      };
    });
    setBlocks(updatedBlocks);
    setChangedFields(uniq([...changedFields, fieldName]));
  };

  const handleSelectChange =
    (blockIndex: number) => (fieldName: string, newValue: { key: string; value: string }) => {
      const updatedBlocks = [...blocks];
      updatedBlocks[blockIndex].fields = updatedBlocks[blockIndex].fields.map((field) => {
        if (field.fieldName !== fieldName) return field;

        return {
          ...field,
          value: newValue.value,
          rawValue: newValue.key,
        };
      });
      setBlocks(updatedBlocks);
      setChangedFields(uniq([...changedFields, fieldName]));
    };

  return (
    <div className={styles.FinancialDetails}>
      <InteractiveButton
        onClick={saveChanges}
        label={t("common.action.save", {
          defaultValue: "Enregistrer",
        })}
        size="xsmall"
        style={{
          position: "absolute",
          top: 12,
          right: 29,
        }}
        background={changedFields.length > 0 ? undefined : Colors.borderColor}
      />
      {blocks.map((block, blockIndex) => (
        <div className={styles.block} key={blockIndex}>
          <h3>
            {t(
              `trainings.view.schedule_row.sidepanel.follow_sections.${block.title.toLowerCase().replace(/ /g, "_")}`,
              {
                defaultValue: block.title,
              }
            )}
          </h3>

          <ul>
            {block.fields.map((field) => {
              const companyField = companyFields.find((f) => f.binding === field.fieldName);

              return (
                <EditInput
                  numberOfRows={rowsUuid.length}
                  key={field.fieldName}
                  editMode={true}
                  field={{ ...field, isStandard: companyField?.isStandard }}
                  handleChange={handleChange(blockIndex)}
                  handleSelectChange={handleSelectChange(blockIndex)}
                />
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};
