import { useState } from "react";

import { Select, MenuItem } from "@mui/material";
import type { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import type { Review, Campaign } from "../../../types";

/*
 * @todo fetch Scale type from core-hr
 * @todo fetch Scale type from core-hr
 * @todo fetch Scale type from core-hr
 * @todo fetch Scale type from core-hr
 */
interface ScaleOption {
  value: number;
  label: string;
}

interface Scale {
  id: string;
  label: string;
  options: ScaleOption[];
}

const SelectReviewCriteria = ({
  params,
  reviewCriteria,
}: {
  reviewCriteria: Scale;
  params: GridRenderEditCellParams;
}) => {
  const { t } = useTranslation();
  // isOpen is true by default to avoid having to click twice on the cell to open the select
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Select
      fullWidth
      open={isOpen}
      id={`select-${reviewCriteria.id}`}
      value={params.row.fields[reviewCriteria.id] ?? ""} // "" to avoid warning about value being undefined
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Removes the inner outline/border
        },
      }}
      MenuProps={{
        sx: {
          "& .MuiMenu-list": {
            paddingBottom: "0.25rem",
            paddingTop: "0.25rem",
          },
        },
      }}
      onChange={(event) => {
        if (event.target.value !== "") {
          params.api.setEditCellValue({
            id: params.id,
            field: reviewCriteria.id,
            value: event.target.value.toString(),
          });
        }

        setIsOpen(false);
        params.api.stopCellEditMode({ id: params.id, field: reviewCriteria.id }); // Closes the select menu and triggers mutate
      }}
    >
      {reviewCriteria.options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          <Flex flexDirection="column">
            <Text
              color="plainText-onLight-default"
              espaceFont={
                value.toString() === params.row.fields[reviewCriteria.id]
                  ? "body1Bold"
                  : "body1Regular"
              }
            >
              {label}
            </Text>

            {params.row.managerFields[reviewCriteria.id] === value.toString() && (
              <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                {t(`peopleReview.talentGrid.managerEvaluation`, {
                  defaultValue: "Évaluation manager",
                })}
              </Text>
            )}
          </Flex>
        </MenuItem>
      ))}
    </Select>
  );
};

export function getCampaignFieldsColDefs(campaign: Campaign): GridColDef<Review>[] {
  return campaign.scales.map((scale) => {
    return {
      type: "singleSelect",
      editable: campaign.permissions.review.isEnabled,
      field: scale.id,
      flex: 1,
      getOptionLabel: (option: (typeof scale.options)[number]) => option?.label ?? "",
      getOptionValue: (option: (typeof scale.options)[number]) => option?.value ?? "",
      headerName: scale.label,
      minWidth: 150,
      renderEditCell: (params) => <SelectReviewCriteria params={params} reviewCriteria={scale} />,
      valueGetter: (params) => params.row.fields[scale.id],
      valueOptions: scale.options.map(({ label, value }) => ({ label, value: value.toString() })),
      valueSetter: (params) => {
        return {
          ...params.row,
          fields: {
            ...params.row.fields,
            [scale.id]: params.value,
          },
        };
      },
    };
  });
}
