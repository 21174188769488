import { useMemo } from "react";

import { Text, Flex } from "@skillup/design-system";
import { Label, DSAlert, DSButton, DSAlertType, DSAlertDisplay } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { useTemplates } from "services/peopleReview";
import { CampaignCreationState } from "types/peopleReview";

interface SummaryProps {
  nextStep: () => void;
  prevStep: () => void;
  hasManagers: boolean;
  summary: Pick<
    CampaignCreationState,
    | "observers"
    | "templateID"
    | "titleForHR"
    | "coordinators"
    | "managersHelpText"
    | "titleForManagers"
    | "collaboratorManagerPairs"
    | "managersPreparationEndDate"
  >;
}

export function Summary({ hasManagers, nextStep, prevStep, summary }: SummaryProps) {
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const templateName = useMemo(
    () => templates.find(({ uuid }) => uuid === summary.templateID)?.title,
    [templates, summary.templateID]
  );

  return (
    <Flex gap="l" width="100%" maxWidth="30rem" flexDirection="column" marginHorizontal="auto">
      <Flex flexDirection="column">
        <Label
          label={t("peopleReview.campaignCreationSteps.summary.titleForHR", {
            defaultValue: "Intitulé de la campagne pour les RH",
          })}
        />
        <Text espaceFont="body1Regular" color="plainText-onLight-default">
          {summary.titleForHR}
        </Text>
      </Flex>

      {hasManagers && (
        <Flex flexDirection="column">
          <Label
            label={t("peopleReview.campaignCreationSteps.summary.titleForManagers", {
              defaultValue: "Intitulé de la campagne pour les managers",
            })}
          />
          <Text espaceFont="body1Regular" color="plainText-onLight-default">
            {summary.titleForManagers}
          </Text>
        </Flex>
      )}
      <Flex flexDirection="column">
        <Label
          label={t("peopleReview.campaignCreationSteps.summary.template", {
            defaultValue: "Modèle de campagne",
          })}
        />
        <Text espaceFont="body1Regular" color="plainText-onLight-default">
          {templateName}
        </Text>
      </Flex>

      <Flex flexDirection="column">
        <Label
          label={t("peopleReview.campaignCreationSteps.summary.collaboratorManagerPairs", {
            defaultValue: "Population",
          })}
        />
        <Text espaceFont="body1Regular" color="plainText-onLight-default">
          {t("peopleReview.campaignCreationSteps.summary.collaboratorManagerPairsCount", {
            count: summary.collaboratorManagerPairs.length,
            defaultValue:
              summary.collaboratorManagerPairs.length > 1
                ? "{{count}} collaborateurs"
                : "{{count}} collaborateur",
          })}
        </Text>
      </Flex>

      <Flex flexDirection="column">
        <Label
          label={t("peopleReview.campaignCreationSteps.summary.coordinators-observers", {
            defaultValue: "Responsables",
          })}
        />
        <Text espaceFont="body1Regular" color="plainText-onLight-default">
          {t("peopleReview.campaignCreationSteps.summary.coordinatorsCount", {
            count: summary.coordinators.length,
            defaultValue:
              summary.coordinators.length > 1
                ? "{{count}} coordinateurs"
                : "{{count}} coordinateur",
          })}
          {t("peopleReview.campaignCreationSteps.summary.observersCount", {
            count: summary.observers.length,
            defaultValue:
              summary.observers.length > 1
                ? " et {{count}} observateurs"
                : " et {{count}} observateur",
          })}
        </Text>
      </Flex>

      {hasManagers && (
        <DSAlert type={DSAlertType.INFO} display={DSAlertDisplay.INLINE}>
          {t("peoplereview.campaignCreation.summary.info", {
            defaultValue:
              "La création de campagne ne déclenche l'envoi d'aucun mail de notification. C'est au démarrage de la campagne que les managers seront notifiés.",
          })}
        </DSAlert>
      )}

      <Flex justifyContent="space-between">
        <DSButton
          emphasis="Low"
          onClick={prevStep}
          label={t("previousStep", {
            defaultValue: "Précédent",
          })}
        />
        <DSButton
          onClick={nextStep}
          label={t("peopleReview.campaignCreation.createCampaign", {
            defaultValue: "Créer la campagne",
          })}
        />
      </Flex>
    </Flex>
  );
}
