export const DatagridFields = {
  lastName: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.lastNname",
    traductionDefaultValue: "Nom",
    key: "lastName",
  },
  firstName: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.firstNname",
    traductionDefaultValue: "Prénom",
    key: "firstName",
  },
  email: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.email",
    traductionDefaultValue: "Email",
    key: "email",
  },
  manager0Lvl0: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.manager0Lvl0",
    traductionDefaultValue: "Manager formations N+1",
    key: "manager0Lvl0",
  },
  manager0Lvl1: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.manager0Lvl1",
    traductionDefaultValue: "Manager formations N+2",
    key: "manager0Lvl1",
  },
  bookingManager: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.bookingManager",
    traductionDefaultValue: "Gestionnaire de réservation",
    key: "bookingManager",
  },
  subCompanies: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.subCompanies",
    traductionDefaultValue: "Sous-entreprise",
    key: "subCompanies",
  },
  role: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.role",
    traductionDefaultValue: "Rôle",
    key: "role",
  },
  registrationNumber: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.registrationNumber",
    traductionDefaultValue: "Matricule",
    key: "registrationNumber",
  },
  joinDate: {
    fieldType: "DATE",
    traductionKey: "collaborator.field.joinDate",
    traductionDefaultValue: "Date d'embauche",
    key: "joinDate",
  },
  groups: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.groups",
    traductionDefaultValue: "Groupes",
    key: "groups",
  },
  areas: {
    fieldType: "TEXT",
    traductionKey: "collaborator.field.areas",
    traductionDefaultValue: "Périmètres",
    key: "areas",
  },
} as const;
