import { ReactNode, ComponentClass, useMemo } from "react";
import { Route, Switch, RouteComponentProps, useLocation } from "react-router-dom";

import User from "utils/User";
import useAdminProperties, { saveChanges } from "./state/useAdminProperties";

import DSLayout from "components/DSLayout";
import ErrorPage from "components/ErrorPage";
import UnknownErrorIcon from "assets/error/unknown_error.svg";

import PortalContent from "./PortalContent/PortalContent";
import PortalFunctionnalities from "./PortalFunctionnalities/PortalFunctionnalities";
import PortalNeeds from "./PortalNeeds/PortalNeeds";
import PortalAppearence from "./PortalAppearence/PortalAppearence";

import PortalOnboarding from "../PortalOnboarding";
import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "./AdminPortalToggle/AdminPortalToggle";

import styles from "./AdminPortal.module.scss";
import useTranslation, { TranslationType } from "hooks/useTranslation";

interface IRouteInterface {
  path: string;
  label: string;
  component: ReactNode;
}

const PortalRoutes = (t: TranslationType): Array<IRouteInterface> => [
  {
    component: PortalContent,
    label: "Contenu",
    path: "/responsable/v2/gestion-du-portail",
  },
  {
    component: PortalFunctionnalities,
    label: "Fontionnalités",
    path: "/responsable/v2/gestion-du-portail/fonctionnalites",
  },
  {
    component: PortalNeeds,
    label: "Receuil de besoin",
    path: "/responsable/v2/gestion-du-portail/receuil-de-besoin",
  },
  {
    component: PortalAppearence,
    label: "Apparence",
    path: "/responsable/v2/gestion-du-portail/apparence",
  },
  {
    component: PortalOnboarding,
    label: "Message Accueil",
    path: "/responsable/v2/gestion-du-portail/message-accueil",
  },
];

export default function AdminPortal() {
  const { data, refetch, loading, error: adminError } = useAdminProperties();
  const isSkillupAdmin = User.isSkillupAdmin();
  const { t } = useTranslation();

  const handleOnChange = async (body: CompanyAdminPayload) => {
    await saveChanges(body);
    await refetch();
  };

  const layout = [
    {
      tab: {
        id: "contenu",
        label: t("trainings.view.portal_configuration.nav_items.contenu", {
          defaultValue: "Contenu",
        }),
        url: "/responsable/v2/gestion-du-portail",
      },
    },
  ];

  const layouts = (
    [
      {
        tab: {
          id: "contenu",
          label: t("trainings.view.portal_configuration.nav_items.contenu", {
            defaultValue: "Contenu",
          }),
          url: "/responsable/v2/gestion-du-portail",
        },
      },
      {
        tab: {
          id: "fonctionnalities",
          label: t("trainings.view.portal_configuration.nav_items.fonctionnalities", {
            defaultValue: "Fonctionnalités",
          }),
          url: "/responsable/v2/gestion-du-portail/fonctionnalites",
        },
      },
      {
        tab: {
          id: "recueil-besoin",
          label: t("trainings.view.portal_configuration.nav_items.recueil-besoin", {
            defaultValue: "Recueil de besoin",
          }),
          url: "/responsable/v2/gestion-du-portail/receuil-de-besoin",
        },
      },
      {
        tab: {
          id: "apparence",
          label: t("trainings.view.portal_configuration.nav_items.apparence", {
            defaultValue: "Apparence",
          }),
          url: "/responsable/v2/gestion-du-portail/apparence",
        },
      },
      {
        tab: {
          id: "message",
          label: t("trainings.view.portal_configuration.nav_items.message", {
            defaultValue: "Message d'accueil",
          }),
          url: "/responsable/v2/gestion-du-portail/message-accueil",
        },
      },
    ] as const
  ).map((e) => {
    return {
      ...e,
      primaryButton: (
        <AdminPortalToggle
          propertiesKey="portalEnabled"
          label={t("trainings.view.portal_configuration.nav_items.action.toggle_training_portal", {
            defaultValue: "Activer le portail de formation",
          })}
          type={PortalToggleType.ENABLED}
          isSelected={data?.properties ? data?.properties["portalEnabled"] : false}
          onChange={handleOnChange}
        />
      ),
    };
  });

  const { pathname } = useLocation();

  const selectedTab = useMemo(() => {
    return layouts.find((e) => e.tab.url === pathname)?.tab.id;
  }, [pathname, layouts]);
  return (
    <DSLayout
      title={t("trainings.view.portal_configuration.nav_items.title", {
        defaultValue: "Configuration du portail",
      })}
      layouts={isSkillupAdmin ? layouts : layout}
      activeLayout={selectedTab}
    >
      {!loading && (
        <div className={styles.AdminPortal}>
          <Switch>
            {isSkillupAdmin ? (
              !adminError ? (
                PortalRoutes(t).map(({ path, component }) => (
                  <Route
                    exact
                    key={path}
                    path={path}
                    component={component as ComponentClass<RouteComponentProps, any>}
                  />
                ))
              ) : (
                <ErrorPage
                  message={t("trainings.view.portal_configuration.nav_items.error_page", {
                    defaultValue:
                      "Désolé, une incohérence dans la configuration nous empêche d’afficher cette page. Nous avons été notifiés et nous mettons tout en œuvre pour corriger le problème. Veuillez réessayer ultérieurement.",
                  })}
                  iconSrc={UnknownErrorIcon}
                />
              )
            ) : (
              <Route exact path="/responsable/v2/gestion-du-portail" component={PortalContent} />
            )}
          </Switch>
        </div>
      )}
    </DSLayout>
  );
}
