import { MdMoreVert as MoreVert } from "react-icons/md";
import { useCallback } from "react";
import { DSButton, DSDropdown, DSDropdownDivider } from "@skillup/ui";

import { useTemplates, type Template, TemplateAction } from "services/interviews";

import { TemplateActions } from "../../components";
import { State } from "../../hooks";
import { asyncPreviewAction } from "../../utils";
import useTranslation from "hooks/useTranslation";

type ActionsProps = {
  template: Template;
  setActionsState: (state: State) => void;
  mainAction: TemplateAction;
  onClose?: () => void;
};

export const TemplateSidePanelActions = ({
  mainAction,
  template,
  setActionsState,
  onClose,
}: ActionsProps) => {
  const { t } = useTranslation();
  const {
    actions: { canDo, getPreviewLinksByUuid },
  } = useTemplates({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const previewLinks = useCallback(async () => {
    if (template && template.uuid) {
      return getPreviewLinksByUuid(template.uuid);
    }
    return null;
  }, [template, getPreviewLinksByUuid]);

  return (
    <DSDropdown button={<DSButton darkMode icon={<MoreVert />} iconOnly />}>
      {canDo("rename") ? (
        TemplateActions.Rename({ template: template, setActionsState: setActionsState, t })
      ) : (
        <></>
      )}
      {canDo("archive") ? (
        TemplateActions.Archive({ template: template, setActionsState: setActionsState, t })
      ) : (
        <></>
      )}
      {canDo("duplicate") ? TemplateActions.Duplicate({ template: template, onClose, t }) : <></>}

      {canDo("rename") || canDo("archive") || canDo("duplicate") ? <DSDropdownDivider /> : <></>}

      {canDo("downloadPDF") && mainAction !== "downloadPDF" ? (
        TemplateActions.DownloadPDF({ templateUuid: template.uuid, t })
      ) : (
        <></>
      )}

      {canDo("preview") ? (
        TemplateActions.Preview({
          as: "collab",
          asyncAction: () => asyncPreviewAction("collab", previewLinks),
          t,
        })
      ) : (
        <></>
      )}
      {canDo("preview") ? (
        TemplateActions.Preview({
          as: "manager",
          asyncAction: () => asyncPreviewAction("manager", previewLinks),
          t,
        })
      ) : (
        <></>
      )}

      {canDo("transfer") || canDo("createCampaign") ? <DSDropdownDivider /> : <></>}
      {canDo("transfer") ? (
        TemplateActions.TransferCopy({ template: template, setActionsState: setActionsState })
      ) : (
        <></>
      )}
      {canDo("createCampaign") ? (
        TemplateActions.CreateCampaign({ template: template, setActionsState: setActionsState })
      ) : (
        <></>
      )}
    </DSDropdown>
  );
};
