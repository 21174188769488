import { useCallback } from "react";
import { DSDropdownItem } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { PlanType, exportNewXLSX } from "./exportCSV";

export interface ExportSchedulesProps {
  readonly scheduleUuid: string;
  readonly areas: string[];
  readonly type: PlanType;
}

const ExportSchedules = (props: ExportSchedulesProps) => {
  const { t } = useTranslation();

  const handleExport = useCallback(
    (pType?: PlanType) => async () => {
      await exportNewXLSX({
        type: pType,
        scheduleUuid: props.scheduleUuid,
        activeAreas: props.areas,
      });
    },
    [props]
  );

  return (
    <>
      <DSDropdownItem
        label={
          props.type === "plan"
            ? t("trainings.view.approved_schedule_row.action.export", {
                defaultValue: "Exporter le plan",
              })
            : t("trainings.view.pending_schedule_row.action.export", {
                defaultValue: "Exporter le recueil",
              })
        }
        onClick={handleExport(props.type)}
      />
      <DSDropdownItem
        label={t("trainings.view.schedule_row.action.export", {
          defaultValue: "Exporter le recueil et le plan",
        })}
        onClick={handleExport()}
      />
    </>
  );
};

export default ExportSchedules;
