import { DSModal, Flex } from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";
import styles from "./WarningQuitWithoutSave.module.scss";

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  t: TranslationType;
}

export const WarningQuitWithoutSave = ({ isOpen, closeModal, onConfirm, t }: IProps) => {
  return (
    <DSModal isOpen={isOpen} className={styles.WarningQuitWithoutSave}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.templateBuilder.quitWithoutSave.title", {
            defaultValue: "Abandonner les modifications ?",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.content}>
          <p>
            {t("interviews.templateBuilder.quitWithoutSave.content.firstPart", {
              defaultValue:
                "Cette trame d'entretien contient des modifications qui n'ont pas été enregistrées.",
            })}
          </p>
          <p>
            {t("interviews.templateBuilder.quitWithoutSave.content.secondPart", {
              defaultValue:
                "Si vous quittez cette page sans avoir enregistré vos modifications, elles seront définitivement perdues.",
            })}
          </p>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.templateBuilder.quitWithoutSave.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          label={t("interviews.templateBuilder.quitWithoutSave.confirm", {
            defaultValue: "Abandonner les modifications",
          })}
          onClick={onConfirm}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
