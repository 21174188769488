import { MdInfoOutline as InfoOutline } from "react-icons/md";
import { useCallback, useMemo } from "react";
import { compact } from "lodash";
import cx from "classnames";
import {
  DSButton,
  DSCheckbox,
  DSFormGroupTextInput,
  Label as DSLabel,
  DSRadio,
  DSRadioGroup,
  Flex,
  Select,
  Switch,
} from "@skillup/ui";

import type { ReviewType, TemplateTargetCategory } from "@skillup/espace-rh-bridge";

import { TranslationType } from "hooks/useTranslation";

import { useBuilderContext } from "../../../../BuilderContext";
import { Divided } from "../ChildWrappers";

import { TargetsPermissions } from "./TargetsPermissions";
import { BuilderFormInput } from "../../BuilderFormInput";
import type { Child } from "../../../../reducer/types";
import { useTargetCategories } from "services/interviews";

import styles from "./TargetsChild.module.scss";

type Targets = Extract<Child, { kind: "targets" }>;

type TargetsProps = {
  child: Targets;
  onChange: (data: Targets, choicesForOrdinalTargets?: string[]) => void;
  openEditTargetSettingsModal: () => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  t: TranslationType;
};

export function Targets({
  child,
  onChange,
  openEditTargetSettingsModal,
  indexes,
  t,
}: TargetsProps) {
  const { targetCategories } = useTargetCategories();
  const { template } = useBuilderContext();
  const isSavedChild = useMemo(
    () =>
      template.sections
        .flatMap((section) => section.pages.flatMap((page) => page.children))
        .find((c) => c.uuid === child.uuid)
        ? true
        : false,
    [template, child.uuid]
  );

  const choicesForOrdinalTargets = useMemo(() => {
    return template.choicesForOrdinalTargets ?? [];
  }, [template]);

  const isChoicesForOrdinalTargetsEmpty = useMemo(() => {
    return !(choicesForOrdinalTargets && choicesForOrdinalTargets.length > 0);
  }, [choicesForOrdinalTargets]);

  const childDescription = useMemo(() => {
    switch (child.type) {
      case "current":
        return t("interviews.templateBuilder.targets.current.description", {
          defaultValue:
            "Ce bloc permet aux participants de passer en revue (évaluation et/ou commentaire) les objectifs du collaborateur.",
        });
      case "next":
        return t("interviews.templateBuilder.targets.next.description", {
          defaultValue:
            "Ce bloc permet aux participants de fixer des objectifs pour le collaborateur.",
        });
      default:
        return "";
    }
  }, [child.type, t]);

  const updateTemplate = useCallback(
    (data: Targets) => {
      onChange(data, choicesForOrdinalTargets);
    },
    [onChange, choicesForOrdinalTargets]
  );

  const handleChange = useCallback(
    (key: string, value: string) => {
      updateTemplate({ ...child, [key]: value });
    },
    [child, updateTemplate]
  );

  const updateReviewType = useCallback(
    (reviewType: ReviewType) => {
      updateTemplate({ ...child, reviewType });
    },
    [child, updateTemplate]
  );

  const handleToggleShowCreationCategories = useCallback(
    (status) => {
      if (!status) {
        updateTemplate({
          ...child,
          isCreateOptionsVisible: false,
          creationCategories: child.displayCategories,
          creationTargetCategories: child.displayTargetCategories,
          hidePeriod: undefined,
          hideWeight: undefined,
          disableGoalSelection: undefined,
          restrictScaleTo: undefined,
          disallow: {
            ...child.disallow,
            employee: {
              ...child.disallow?.employee,
              creation: true,
            },
            manager: {
              ...child.disallow?.manager,
              creation: true,
            },
          },
        });
      } else {
        updateTemplate({
          ...child,
          isCreateOptionsVisible: true,
          creationCategories: child.displayCategories,
          creationTargetCategories: child.displayTargetCategories,
          restrictScaleTo: undefined,
          hidePeriod: false,
          hideWeight: false,
          disableGoalSelection: false,
        });
      }
    },
    [child, updateTemplate]
  );

  const changeRestrictScaleTo = useCallback(
    (value?: "ordinal" | "numeric") => {
      updateTemplate({
        ...child,
        restrictScaleTo: value,
      });
    },
    [child, updateTemplate]
  );
  return (
    <Divided>
      <Flex column className={styles.TargetsChild}>
        <p className={styles.childDescription}>{childDescription}</p>
        {child.type === "current" && (
          <>
            <Flex column>
              {targetCategories.length > 0 && (
                <DSFormGroupTextInput
                  label={t("interviews.templateBuilder.targets.current.categories", {
                    defaultValue: "Catégorie(s) des objectifs à passer en revue",
                  })}
                >
                  <Select
                    canSelectAll
                    multi
                    extraValuesLabel={(count) =>
                      t("interviews.templateBuilder.targets.categories.extraLabel", {
                        defaultValue: "{{ count }} catégories sélectionnées",
                        count: count,
                      })
                    }
                    allValuesLabel={t("interviews.templateBuilder.targets.categories.allLabel", {
                      defaultValue: "Toutes les catégories",
                    })}
                    selectAllLabel={t("interviews.templateBuilder.targets.categories.selectAll", {
                      defaultValue: "Tout selectionner",
                    })}
                    options={targetCategories.map((category) => ({
                      value: { uuid: category.uuid, label: category.label },
                      label: category.label,
                    }))}
                    value={child.displayTargetCategories ?? []}
                    equalFn={(a, b) => a?.uuid === b?.uuid}
                    onChange={(values?: TemplateTargetCategory[]) => {
                      const compactedValues = compact(values);
                      updateTemplate({
                        ...child,
                        displayTargetCategories: compactedValues,
                        displayCategories: compactedValues.map(({ label }) => label?.valueOf()),
                        creationTargetCategories: compactedValues,
                        creationCategories: compactedValues.map(({ label }) => label?.valueOf()),
                      });
                    }}
                  />
                </DSFormGroupTextInput>
              )}
              <DSButton
                label={t("interviews.templateBuilder.targets.categories.manage", {
                  defaultValue: "Gérer les catégories d'objectif de cette trame",
                })}
                className={styles["button--underline"]}
                onClick={() =>
                  window.open("/responsable/configuration-des-entretiens-et-des-objectifs")
                }
                buttonSize="S"
                emphasis="Low"
              />
            </Flex>
            <Switch
              className={styles.switch}
              active={child.isCreateOptionsVisible}
              onToggle={handleToggleShowCreationCategories}
              label={t("interviews.templateBuilder.targets.current.create", {
                defaultValue: "Permettre de créer des objectifs à passer en revue",
              })}
            />
          </>
        )}

        {(child.isCreateOptionsVisible || child.type === "next") && (
          <>
            <Flex column>
              {child.type === "next" && targetCategories.length > 0 && (
                <DSFormGroupTextInput
                  label={t("interviews.templateBuilder.targets.categories.create", {
                    defaultValue:
                      "Catégorie(s) dans lesquelles les objectifs pourront être définis",
                  })}
                  required
                >
                  <Select
                    canSelectAll
                    multi
                    options={targetCategories.map((category) => ({
                      value: { uuid: category.uuid, label: category.label },
                      label: category.label,
                    }))}
                    extraValuesLabel={(count) =>
                      t("interviews.templateBuilder.targets.categories.extraLabel", {
                        defaultValue: "{{ count }} catégories sélectionnées",
                        count: count,
                      })
                    }
                    allValuesLabel={t("interviews.templateBuilder.targets.categories.allLabel", {
                      defaultValue: "Toutes les catégories",
                    })}
                    selectAllLabel={t("interviews.templateBuilder.targets.categories.selectAll", {
                      defaultValue: "Tout selectionner",
                    })}
                    value={child.creationTargetCategories ?? []}
                    equalFn={(a, b) => a?.uuid === b?.uuid}
                    onChange={(values?: TemplateTargetCategory[]) => {
                      const compactedValues = compact(values);
                      updateTemplate({
                        ...child,
                        creationTargetCategories: compactedValues,
                        creationCategories: compactedValues.map(({ label }) => label?.valueOf()),
                        displayTargetCategories: compactedValues,
                        displayCategories: compactedValues.map(({ label }) => label?.valueOf()),
                      });
                    }}
                  />
                </DSFormGroupTextInput>
              )}
              {child.type === "current" && child.displayTargetCategories?.length > 0 && (
                <p className={styles.childDescription}>
                  {t("interviews.templateBuilder.targets.current.create.description", {
                    defaultValue:
                      "Les nouveaux objectifs pourront être créés dans les catégories sélectionnées plus haut.",
                  })}
                </p>
              )}
              {child.type === "next" && (
                <DSButton
                  label={t("interviews.templateBuilder.targets.categories.manage", {
                    defaultValue: "Gérer les catégories d'objectif de cette trame",
                  })}
                  className={styles["button--underline"]}
                  onClick={() =>
                    window.open("/responsable/configuration-des-entretiens-et-des-objectifs")
                  }
                  buttonSize="S"
                  emphasis="Low"
                />
              )}
              <div className={styles["radioBlock"]}>
                <DSFormGroupTextInput
                  label={t("interviews.templateBuilder.targets.scale.choice", {
                    defaultValue: "Type d'objectifs pouvant être créés",
                  })}
                  required
                >
                  <DSRadioGroup name={"targets-type-to-evaluate" + child.uuid}>
                    <DSRadio
                      onChange={() => changeRestrictScaleTo()}
                      label={t("interviews.templateBuilder.targets.scale.both", {
                        defaultValue: "Quantitatifs & Qualitatifs",
                      })}
                      checked={!child.restrictScaleTo}
                    />
                    <DSRadio
                      onChange={() => changeRestrictScaleTo("numeric")}
                      label={t("interviews.templateBuilder.targets.scale.numeric", {
                        defaultValue: "Quantitatifs",
                      })}
                      checked={child.restrictScaleTo === "numeric"}
                    />
                    <DSRadio
                      onChange={() => changeRestrictScaleTo("ordinal")}
                      label={t("interviews.templateBuilder.targets.scale.ordinal", {
                        defaultValue: "Qualitatifs",
                      })}
                      checked={child.restrictScaleTo === "ordinal"}
                    />
                  </DSRadioGroup>
                </DSFormGroupTextInput>
              </div>
              {child.restrictScaleTo !== "numeric" && (
                <DSButton
                  icon={isChoicesForOrdinalTargetsEmpty ? <InfoOutline /> : undefined}
                  label={
                    isChoicesForOrdinalTargetsEmpty
                      ? t("interviews.templateBuilder.targets.scale.ordinal.empty", {
                          defaultValue:
                            "Veuillez définir une échelle d'évaluation pour les objectifs qualitatifs",
                        })
                      : t("interviews.templateBuilder.targets.scale.ordinal.edit", {
                          defaultValue:
                            "Modifier l’échelle d’évaluation des objectifs qualitatifs de cette trame",
                        })
                  }
                  className={cx(
                    styles["button--underline"],
                    isChoicesForOrdinalTargetsEmpty ? styles["button--underline--errored"] : ""
                  )}
                  onClick={openEditTargetSettingsModal}
                  buttonSize="S"
                  emphasis="Low"
                />
              )}
            </Flex>
            <Flex column>
              <DSLabel
                label={t("interviews.templateBuilder.targets.properties", {
                  defaultValue: "Propriétés affichées",
                })}
              />
              <DSCheckbox
                name={`${child.uuid}-hidePeriod`}
                label={t("interviews.templateBuilder.targets.hidePeriod", {
                  defaultValue: "Période",
                })}
                checked={!child.hidePeriod}
                onChange={(checked) => {
                  updateTemplate({
                    ...child,
                    hidePeriod: !checked,
                  });
                }}
              />
              <DSCheckbox
                name={`${child.uuid}-hideWeight`}
                label={t("interviews.templateBuilder.targets.hideWeight", {
                  defaultValue: "Pondération",
                })}
                checked={!child.hideWeight}
                onChange={(checked) => {
                  updateTemplate({
                    ...child,
                    hideWeight: !checked,
                  });
                }}
              />
              {child.restrictScaleTo !== "ordinal" && (
                <DSCheckbox
                  helpText={t("interviews.templateBuilder.targets.disableGoalSelection.helpText", {
                    defaultValue:
                      "Par défaut, l'évaluation des objectifs quantitatifs s'exprime en pourcentage. Cochez cette case si vous préférez que les participants à l’entretien indiquent un résultat à atteindre.",
                  })}
                  name={`${child.uuid}-disableGoalSelection`}
                  label={t("interviews.templateBuilder.targets.disableGoalSelection", {
                    defaultValue: "Résultat attendu",
                  })}
                  checked={!child.disableGoalSelection}
                  onChange={(checked) => {
                    updateTemplate({
                      ...child,
                      disableGoalSelection: !checked,
                    });
                  }}
                />
              )}
            </Flex>
            {child.targetNamePlaceholder !== undefined ? (
              <Flex column>
                <div className={styles.labelAndButton}>
                  <DSLabel
                    label={t("interviews.templateBuilder.targets.targetNamePlaceholder", {
                      defaultValue: "Placeholder pour le champ « intitulé » de l'objectif",
                    })}
                  />
                  <DSButton
                    label={t("interviews.templateBuilder.delete", { defaultValue: "Supprimer" })}
                    className={styles["button--underline"]}
                    onClick={() => handleChange("targetNamePlaceholder", undefined)}
                    buttonSize="S"
                    emphasis="Low"
                  />
                </div>
                <BuilderFormInput
                  type="textarea"
                  value={child.targetNamePlaceholder}
                  onChange={(newValue) => handleChange("targetNamePlaceholder", newValue)}
                  debounceValue={300}
                  name={`targets-child-${child.uuid}-targetNamePlaceholder`}
                />
              </Flex>
            ) : (
              <DSButton
                label={t("interviews.templateBuilder.targets.targetNamePlaceholder.add", {
                  defaultValue: "Ajouter un placeholder pour le champ « intitulé » de l'objectif",
                })}
                className={styles["button--underline"]}
                onClick={() => handleChange("targetNamePlaceholder", "")}
                buttonSize="S"
                emphasis="Low"
              />
            )}
            {child.targetDescriptionPlaceholder !== undefined ? (
              <Flex column>
                <div className={styles.labelAndButton}>
                  <DSLabel
                    label={t("interviews.templateBuilder.targets.targetDescriptionPlaceholder", {
                      defaultValue: "Placeholder pour le champ « descriptif » de l'objectif",
                    })}
                  />
                  <DSButton
                    label={"Supprimer"}
                    className={styles["button--underline"]}
                    onClick={() => handleChange("targetDescriptionPlaceholder", undefined)}
                    buttonSize="S"
                    emphasis="Low"
                  />
                </div>
                <BuilderFormInput
                  type="textarea"
                  value={child.targetDescriptionPlaceholder}
                  onChange={(newValue) => handleChange("targetDescriptionPlaceholder", newValue)}
                  debounceValue={300}
                  name={`targets-child-${child.uuid}-targetDescriptionPlaceholder`}
                />
              </Flex>
            ) : (
              <DSButton
                label={t("interviews.templateBuilder.targets.targetDescriptionPlaceholder.add", {
                  defaultValue: "Ajouter un placeholder pour le champ « descriptif » de l'objectif",
                })}
                className={styles["button--underline"]}
                onClick={() => handleChange("targetDescriptionPlaceholder", "")}
                buttonSize="S"
                emphasis="Low"
              />
            )}
          </>
        )}
      </Flex>
      <div>
        <TargetsPermissions
          child={child}
          currentShowCreation={child.isCreateOptionsVisible}
          updateReviewType={updateReviewType}
          choicesForOrdinalTargets={choicesForOrdinalTargets}
          indexes={indexes}
          isSavedChild={isSavedChild}
          t={t}
        />
      </div>
    </Divided>
  );
}
