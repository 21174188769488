import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";
import { FormatDate } from "@skillup/shared-utils";

import { TranslationType } from "hooks/useTranslation";

import ActionsCell from "../components/ActionsCell";
import { Collaborator } from "../../../api/getUsers";
import { CollaboratorRow } from "./parseCollaboratorIntoRow";
import { DatagridFields as dFields } from "./getDatagridFields";

const renderFlexCell = (value: string) => (
  <Flex width="100%" alignItems="center" justifyContent="center">
    <Flex width="100%" justifyContent="flex-end">
      {value}
    </Flex>
  </Flex>
);

const generateColumns = ({
  actions,
  collaborators,
  maxLevel,
  t,
}: {
  maxLevel: number;
  t: TranslationType;
  collaborators: Collaborator[];
  actions: { editRowCollaborator: (row: CollaboratorRow) => void };
}): GridColDef[] => {
  const columns: Array<GridColDef> = [
    {
      field: "lastName",
      filterable: true,
      headerName: t(dFields.lastName.traductionKey, {
        defaultValue: dFields.lastName.traductionDefaultValue,
      }),
      minWidth: 100,
    },
    {
      field: "firstName",
      filterable: true,
      headerName: t(dFields.firstName.traductionKey, {
        defaultValue: dFields.firstName.traductionDefaultValue,
      }),
      minWidth: 100,
    },
    {
      field: "email",
      filterable: true,
      headerName: t(dFields.email.traductionKey, {
        defaultValue: dFields.email.traductionDefaultValue,
      }),
      minWidth: 200,
    },
    {
      field: "manager0Lvl0",
      filterable: true,
      headerName: t(dFields.manager0Lvl0.traductionKey, {
        defaultValue: dFields.manager0Lvl0.traductionDefaultValue,
      }),
      minWidth: 200,
    },
    {
      field: "manager0Lvl1",
      filterable: true,
      headerName: t(dFields.manager0Lvl1.traductionKey, {
        defaultValue: dFields.manager0Lvl1.traductionDefaultValue,
      }),
      minWidth: 200,
    },
    {
      field: "bookingManager",
      filterable: true,
      headerName: t(dFields.bookingManager.traductionKey, {
        defaultValue: dFields.bookingManager.traductionDefaultValue,
      }),
      minWidth: 200,
    },
    {
      field: "subCompanies",
      filterable: true,
      headerName: t(dFields.subCompanies.traductionKey, {
        defaultValue: dFields.subCompanies.traductionDefaultValue,
      }),
      minWidth: 175,
    },
    {
      field: "role",
      filterable: true,
      headerName: t(dFields.role.traductionKey, {
        defaultValue: dFields.role.traductionDefaultValue,
      }),
      minWidth: 125,
    },
    {
      field: "registrationNumber",
      filterable: true,
      headerName: t(dFields.registrationNumber.traductionKey, {
        defaultValue: dFields.registrationNumber.traductionDefaultValue,
      }),
      minWidth: 175,
    },
    {
      field: "joinDate",
      filterable: true,
      headerName: t(dFields.joinDate.traductionKey, {
        defaultValue: dFields.joinDate.traductionDefaultValue,
      }),
      minWidth: 175,
      renderCell: ({ row }: GridValueGetterParams<CollaboratorRow>) => {
        const joinDate = row["joinDate"]
          ? FormatDate.toStringLiteralFormatFromUtc(row["joinDate"], "dd/MM/yyyy")
          : "";
        return renderFlexCell(joinDate);
      },
      sortable: true,
    },
    {
      field: "areas",
      filterable: true,
      headerName: t(dFields.areas.traductionKey, {
        defaultValue: dFields.areas.traductionDefaultValue,
      }),
      minWidth: 125,
    },
    {
      field: "groups",
      filterable: true,
      headerName: t(dFields.groups.traductionKey, {
        defaultValue: dFields.groups.traductionDefaultValue,
      }),
      minWidth: 125,
    },
    ...Array.from({ length: maxLevel }, (_, i) => i + 1).map((level) => ({
      field: `observerLvl${level}`,
      filterable: true,
      headerName: t("collaborator.field.observerLvl", {
        defaultValue: `Manager entretiens N+{{level}}`,
        level: level,
      }),
      minWidth: 200,
    })),
  ];

  columns.push({
    type: "actions",
    field: "actions",
    filterable: false,
    getActions: ({ row }) => [<ActionsCell t={t} row={row} actions={actions} />],
    sortable: false,
    width: 50,
  });

  return columns;
};

export default generateColumns;
