import React, { useState, useRef } from "react";
import { useClickAway } from "react-use";
import { FcCheckmark } from "react-icons/fc";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { TextInput } from "@skillup/ui";
import { TrainingUtils } from "@skillup/training-bridge";

import type { FieldRoutesType } from "types/api";
import useTranslation from "hooks/useTranslation";
import Colors from "uiAssets/Colors";

import styles from "./FieldsSettings.module.scss";

interface IField {
  index?: number;
  binding: string;
  fromStandard?: boolean;
  type: "number" | "text" | "textarea" | "select" | "monetary";
  defaultValue: string | number;
  label: string;
  uuid: string;
  options?: { key: string; value: string }[];
  schedules: string[];
}

interface IProps {
  activatedFields: string[];
  fields: IField[];
  type: "plan" | "collection";
  addFieldToConfiguration: (field: IField) => void;
  reorderConfiguration: (fields: string[]) => void;
  removeFieldFromConfiguration: (field: string) => void;
  onClickAway: (e: any) => void;
  companyFields: FieldRoutesType.GetV2["response"];
}

// a little function to help us with reordering the result
const reorder = function <T>(list: T[], startIndex, endIndex): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250
  background: "#fff",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const FieldsSettings: React.FunctionComponent<IProps> = ({
  activatedFields,
  fields,
  addFieldToConfiguration,
  reorderConfiguration,
  removeFieldFromConfiguration,
  onClickAway,
  companyFields,
}) => {
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState<string>();

  const self = useRef<HTMLDivElement>(null);
  useClickAway(self, onClickAway);

  const isToggled = (field: IField) => activatedFields.includes(field.binding);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      const fieldToRemove = activatedFields[source.index];
      removeFieldFromConfiguration(fieldToRemove);
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const updatedFields = reorder(activatedFields, source.index, destination.index);
      reorderConfiguration(updatedFields);
    }
  };

  const fieldsWithIsStandard = fields.map((field) => {
    const companyField = companyFields.find((f) => f.binding === field.binding);
    return {
      ...field,
      isStandard: companyField?.isStandard,
    };
  });

  return (
    <div className={styles.fieldsSettings} ref={self} style={{ top: "140px" }}>
      <h3>
        {t("trainings.view.schedule_row.datatable.add_columns", {
          defaultValue: "Ajouter des colonnes",
        })}
      </h3>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="selectedFields">
          {(provided, snapshot) => (
            <div
              className={styles.selectedFields}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              aria-label="Colonnes ajoutées"
            >
              {activatedFields.map((binding, index) => {
                const field = fieldsWithIsStandard.find((f) => f.binding === binding);
                const selected = activatedFields.includes(field.binding);

                return (
                  <Draggable
                    key={field.binding}
                    draggableId={`${index}-${field.binding}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        className={styles.fieldCheckBox}
                        onClick={() => addFieldToConfiguration(field)}
                      >
                        {selected ? (
                          <FcCheckmark color={Colors.success} size={14} />
                        ) : (
                          <div style={{ width: 19 }} />
                        )}{" "}
                        {TrainingUtils.Translation.translateFieldLabel(
                          {
                            binding: field.binding,
                            label: field.label,
                            isStandard: field.isStandard,
                          },
                          i18n,
                          t
                        )}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={styles.separator} />

      <div className={styles.fieldsPool} aria-label="Colonnes disponibles">
        <TextInput
          placeholder={t("trainings.view.schedule_row.datatable.search_field", {
            defaultValue: "Rechercher un champ à ajouter au tableau",
          })}
          onChange={(newValue) => setFilter(newValue)}
          value={filter}
          className={styles.searchInput}
        />
        <div className={styles.list}>
          {fieldsWithIsStandard
            .filter((field) => !isToggled(field))
            .filter((field) => field.label.toLowerCase().includes((filter || "").toLowerCase()))
            .map((field) => (
              <div
                className={styles.fieldLabel}
                onClick={() => addFieldToConfiguration(field)}
                key={field.uuid}
              >
                {TrainingUtils.Translation.translateFieldLabel(
                  {
                    binding: field.binding,
                    label: field.label,
                    isStandard: field.isStandard,
                  },
                  i18n,
                  t
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FieldsSettings;
