import useTranslation from "hooks/useTranslation";
import { Link, generatePath } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { DSTooltip } from "@skillup/ui";
import { Flex, Text, Grid } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import {
  getReviewCriteriaList,
  getReviewCriteriaListCacheKey,
} from "services/coreHR/fields/getReviewCriteriaList";

import { ReviewCriteriaRoutes } from "../review-criteria.router";

import styles from "./ReviewCriteriaList.module.scss";

export function ReviewCriteriaList() {
  const { t } = useTranslation();
  const { data } = useQuery(getReviewCriteriaListCacheKey(), getReviewCriteriaList);

  return (
    <DSLayout
      title={t("core-hr.layout.title", {
        defaultValue: "Configuration des critères de positionnement",
      })}
    >
      <Flex gap="xs" padding="l" flexDirection="column">
        <Text espaceFont="h5" color="plainText-onLight-lighter">
          {t("core-hr.review-criteria-list.title", {
            defaultValue: "Critères de positionnement standards",
          })}
        </Text>
        <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
          {t("core-hr.review-criteria-list.description", {
            defaultValue:
              "Ces critères sont nécessaires au bon fonctionnement de l'application. Elles ne peuvent pas être supprimées et certaines de leurs propriétés ne peuvent pas être modifiées.",
          })}
        </Text>
        {data && (
          <Grid gap="s" gridTemplateColumns={{ columnNumbers: 3, columnWidth: "minmax(0, 1fr)" }}>
            {data.map(({ scale }) => (
              <Link
                key={scale.id}
                className={styles.link}
                to={generatePath(ReviewCriteriaRoutes.reviewCriteria, { id: scale.fieldID })}
              >
                <Flex
                  gap="s"
                  padding="xs s"
                  flexDirection="column"
                  className={styles.card}
                  backgroundColor="surface-light"
                >
                  <Flex gap="xxs" flexDirection="column">
                    <Text espaceFont="body1Bold" color="plainText-onLight-default">
                      {scale.label}
                    </Text>
                    {/* 
                  @not-working enabledOnOverflow
                */}
                    <DSTooltip enabledOnOverflow label={scale.description}>
                      <Text
                        hasEllipsis
                        lineClamp={3}
                        espaceFont="captionRegular"
                        color="plainText-onLight-lighter"
                      >
                        {scale.description}
                      </Text>
                    </DSTooltip>
                  </Flex>
                  <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                    {t("core-hr.review-criteria-list.card.options-count", {
                      count: scale.options.length,
                      defaultValue:
                        scale.options.length > 1
                          ? "Critère évalué sur {{count}} niveaux"
                          : "Critère évalué sur {{count}} niveau",
                    })}
                  </Text>
                </Flex>
              </Link>
            ))}
          </Grid>
        )}
      </Flex>
    </DSLayout>
  );
}
