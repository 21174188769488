import React from "react";
import { Bulb } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const HideFromManagerUntil = ({
  status,
  t,
}: {
  status: string;
  t: (l: string, p: object) => string;
}) => (
  <RectangleTag
    label={t("interviews.shareToManager", {
      defaultValue: "Partagé au responsable après : {{status}}",
      status,
    })}
    icon={<Bulb />}
    backgroundColor="#EE82EE"
    textColor="#000000"
  />
);

export { HideFromManagerUntil };
