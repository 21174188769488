import { useHistory, generatePath } from "react-router-dom";
import { useMemo, useState, Dispatch, SetStateAction } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Text, Flex } from "@skillup/design-system";
import { Status, DSRadio, DSModal, DSButton, StatusType } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { closeCampaign } from "services/peopleReview/useCampaignDetails/updateCampaignState";
import useCampaignFeedbackForm from "services/peopleReview/campaign/useCampaignFeedbackForm";
import { setNonArbitratedReviewsAsDone } from "services/peopleReview/useCampaignDetails/setNonArbitratedReviewsAsDone";

import { Campaign } from "../../types";
import { PeopleReviewRoutes } from "../../pages/router";
import { StyledTitleContainer } from "./CloseCampaignModal.styled";
import { CampaignFeedbackForm } from "../CampaignFeedbackForm/CampaignFeedbackForm";

interface CloseCampaignModalProps {
  campaign: Campaign;
  onClose: () => void;
}

const NON_ARBITRATED_REVIEWS_CHOICE = {
  ARBITRATE: "ARBITRATE",
  CONSULT: "CONSULT",
} as const;

type NonArbitratedReviewsChoice =
  (typeof NON_ARBITRATED_REVIEWS_CHOICE)[keyof typeof NON_ARBITRATED_REVIEWS_CHOICE];

export function CloseCampaignModal({ campaign, onClose }: CloseCampaignModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);

  const { isLoading: isCloseLoading, mutate: close } = useMutation(closeCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      onClose();
    },
  });

  const nonArbitratedReviewsCount =
    campaign.reviewTotalCount -
    campaign.nonArbitratedOutsideCoordinatorPerimeterCount -
    campaign.arbitratedTotalCount;

  const [nonArbitratedReviewsChoice, setNonArbitratedReviewsChoice] =
    useState<NonArbitratedReviewsChoice>(NON_ARBITRATED_REVIEWS_CHOICE.CONSULT);
  const history = useHistory();

  const { isLoading: isArbitrateLoading, mutate: arbitrate } = useMutation(
    setNonArbitratedReviewsAsDone,
    {
      onSuccess() {
        queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
        queryClient.invalidateQueries({
          queryKey: ["people-reviews-campaign-reviews", campaign.id],
        });
      },
    }
  );

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.campaign.closeCampaignModal.title", {
            defaultValue: "Clôturer la campagne",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        {nonArbitratedReviewsCount > 0 ? (
          <Flex gap="s" maxWidth="42rem" paddingBottom="s" flexDirection="column">
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("people-review.campaign.closeCampaignModal.nonArbitratedReviews", {
                count: nonArbitratedReviewsCount,
                defaultValue:
                  nonArbitratedReviewsCount === 1
                    ? "La campagne ne peut pas être clôturée car 1 ligne de votre périmètre n’a pas été arbitrée. Vous devez traiter cette ligne pour pouvoir poursuivre."
                    : "La campagne ne peut pas être clôturée car {{count}} lignes de votre périmètre n’ont pas été arbitrées. Vous devez traiter ces lignes pour pouvoir poursuivre.",
              })}
            </Text>
            <DSRadio
              checked={nonArbitratedReviewsChoice === NON_ARBITRATED_REVIEWS_CHOICE.CONSULT}
              onChange={() => {
                setNonArbitratedReviewsChoice(NON_ARBITRATED_REVIEWS_CHOICE.CONSULT);
              }}
              label={t("peopleReview.campaign.closeCampaignModal.consult", {
                count: nonArbitratedReviewsCount,
                defaultValue:
                  nonArbitratedReviewsCount === 1
                    ? "Consulter la ligne non arbitrée"
                    : `Consulter les {{count}} lignes non arbitrées`,
              })}
            />
            <DSRadio
              checked={nonArbitratedReviewsChoice === NON_ARBITRATED_REVIEWS_CHOICE.ARBITRATE}
              onChange={() => {
                setNonArbitratedReviewsChoice(NON_ARBITRATED_REVIEWS_CHOICE.ARBITRATE);
              }}
              label={t("peopleReview.campaign.closeCampaignModal.arbitrate", {
                count: nonArbitratedReviewsCount,
                defaultValue:
                  nonArbitratedReviewsCount === 1
                    ? "Passer la ligne comme arbitrée"
                    : `Passer les {{count}} lignes comme arbitrées`,
              })}
            />
          </Flex>
        ) : campaign.nonArbitratedOutsideCoordinatorPerimeterCount > 0 ? (
          <Flex gap="s" maxWidth="42rem" paddingBottom="s" flexDirection="column">
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("people-review.campaign.closeCampaignModal.nonArbitratedOutsidePerimeter", {
                defaultValue:
                  "La campagne ne peut pas être clôturée car il existe des lignes non arbitrées pour des collaborateurs hors de votre périmètre.",
              })}
            </Text>
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("people-review.campaign.closeCampaignModal.nonArbitratedResponsibility", {
                defaultValue:
                  "Ces lignes ne peuvent être traitées que par les coordinateurs qui en ont la responsabilité.",
              })}
            </Text>
          </Flex>
        ) : (
          <CampaignFeedbackConfiguration
            isEdit={isEdit}
            campaign={campaign}
            setIsEdit={setIsEdit}
          />
        )}
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.campaign.closeCampaignModal.cancel", {
            defaultValue: "Annuler",
          })}
        />

        {nonArbitratedReviewsCount > 0 ? (
          <DSModal.Footer.PrimaryButton
            loading={isArbitrateLoading}
            disabled={isArbitrateLoading}
            label={
              nonArbitratedReviewsChoice === NON_ARBITRATED_REVIEWS_CHOICE.CONSULT
                ? t("peopleReview.campaign.closeCampaignModal.consult", {
                    count: nonArbitratedReviewsCount,
                    defaultValue:
                      nonArbitratedReviewsCount === 1
                        ? "Consulter la ligne non arbitrée"
                        : "Consulter les {{count}} lignes non arbitrées",
                  })
                : t("peopleReview.campaign.closeCampaignModal.next", {
                    defaultValue: "Suivant",
                  })
            }
            onClick={() => {
              if (nonArbitratedReviewsChoice === NON_ARBITRATED_REVIEWS_CHOICE.CONSULT) {
                history.push({
                  pathname: generatePath(PeopleReviewRoutes.PPRCampaign, {
                    campaignID: campaign.id,
                    currentView: "grid",
                  }),
                  search: new URLSearchParams([
                    ["coordinatorStatus", "no_manager_prep"],
                    ["coordinatorStatus", "manager_prep"],
                    ["coordinatorStatus", "in_progress"],
                  ]).toString(),
                });

                onClose();
              } else {
                arbitrate({ campaignID: campaign.id });
              }
            }}
          />
        ) : campaign.nonArbitratedOutsideCoordinatorPerimeterCount > 0 ? (
          <DSModal.Footer.PrimaryButton
            disabled
            tooltipDirection="bottom"
            label={t("peopleReview.campaign.closeCampaignModal.closeCampaign", {
              defaultValue: "Clôturer la campagne",
            })}
            tooltip={t("peopleReview.campaign.closeCampaignModal.closeCampaignTooltip", {
              defaultValue:
                "Une campagne ne peut être clôturée que si l’ensemble de ses lignes ont été arbitrées.",
            })}
          />
        ) : (
          <DSModal.Footer.PrimaryButton
            tooltipDirection="bottom"
            disabled={isEdit || isCloseLoading}
            onClick={() => close(campaign.id)}
            label={t("peopleReview.campaign.closeCampaignModal.closeCampaign", {
              defaultValue: "Clôturer la campagne",
            })}
            tooltip={
              isEdit
                ? t("peopleReview.campaign.feedbackSettingsModal.closeCampaignTooltip", {
                    defaultValue: "Les modifications doivent être enregistrées avant de poursuivre",
                  })
                : undefined
            }
          />
        )}
      </DSModal.Footer>
    </DSModal>
  );
}

function CampaignFeedbackConfiguration({
  campaign,
  isEdit,
  setIsEdit,
}: {
  isEdit: boolean;
  campaign: Campaign;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}) {
  const {
    handleChange,
    handleSubmit,
    isCommentSharedToManager,
    sharedToManagerActionIds,
    sharedToManagerCoreHRScaleIDs,
  } = useCampaignFeedbackForm({
    campaignID: campaign.id,
    onMutationSuccess: () => {
      setIsEdit(false);
    },
  });

  return !isEdit ? (
    <CampaignFeedbackSummary
      campaign={campaign}
      setIsEdit={setIsEdit}
      data={{
        isCommentSharedToManager,
        sharedToManagerActionIds,
        sharedToManagerCoreHRScaleIDs,
      }}
    />
  ) : (
    <CampaignFeedbackForm
      hasSubmitButton
      campaign={campaign}
      onChange={handleChange}
      onSubmit={handleSubmit}
      data={{
        isCommentSharedToManager,
        sharedToManagerActionIds,
        sharedToManagerCoreHRScaleIDs,
      }}
    />
  );
}

function CampaignFeedbackSummary({
  campaign,
  data,
  setIsEdit,
}: {
  campaign: Campaign;
  setIsEdit: (isEdit: boolean) => void;
  data: {
    isCommentSharedToManager: boolean;
    sharedToManagerActionIds: string[];
    sharedToManagerCoreHRScaleIDs: string[];
  };
}) {
  const { t } = useTranslation();
  const { isCommentSharedToManager, sharedToManagerActionIds, sharedToManagerCoreHRScaleIDs } =
    data;

  const campaignFields = useMemo(() => {
    return campaign.scales.map((scale) => {
      const isFieldShared = sharedToManagerCoreHRScaleIDs.includes(scale.id);
      return {
        key: scale.label,
        status: isFieldShared,
      };
    });
  }, [campaign.scales, sharedToManagerCoreHRScaleIDs]);

  const campaignActions = useMemo(() => {
    return campaign.actions.map((action) => {
      const isActionShared = sharedToManagerActionIds.includes(action.id);

      return {
        key: action.label,
        status: isActionShared,
      };
    });
  }, [campaign.actions, sharedToManagerActionIds]);

  return (
    <Flex gap="s" paddingBottom="s" flexDirection="column">
      <Text espaceFont="body1Regular" color="plainText-onLight-default">
        {t("peopleReview.campaign.closeCampaignModal.description", {
          defaultValue:
            "Voici les éléments qui seront partagés avec les managers une fois la campagne clôturée :",
        })}
      </Text>

      <Flex
        gap="m"
        position="relative"
        paddingVertical="xs"
        paddingHorizontal="s"
        flexDirection="column"
        backgroundColor="greyscale-lighter"
      >
        <Flex gap="m" flexDirection="column">
          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.evalutationCriteria", {
                  defaultValue: "Critères d'évaluation",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              {campaignFields.map((field) => (
                <Flex
                  gap="s"
                  key={field.key}
                  flexDirection="row"
                  alignItems="center"
                  paddingVertical="xxs"
                  justifyContent="space-between"
                >
                  <Status
                    type={field.status ? StatusType.SUCCESS : StatusType.DONE}
                    label={
                      field.status
                        ? t("peopleReview.campaign.closeCampaignModal.shared", {
                            defaultValue: "Partagé",
                          })
                        : t("peopleReview.campaign.closeCampaignModal.notShared", {
                            defaultValue: "Non partagé",
                          })
                    }
                  />

                  <Flex flex={1}>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {t(field.key)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.actions", {
                  defaultValue: "Actions et commentaires associés",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              {campaignActions.map((action) => (
                <Flex
                  gap="s"
                  key={action.key}
                  flexDirection="row"
                  alignItems="center"
                  paddingVertical="xxs"
                  justifyContent="space-between"
                >
                  <Status
                    type={action.status ? StatusType.SUCCESS : StatusType.DONE}
                    label={
                      action.status
                        ? t("peopleReview.campaign.closeCampaignModal.shared", {
                            defaultValue: "Partagé",
                          })
                        : t("peopleReview.campaign.closeCampaignModal.notShared", {
                            defaultValue: "Non partagé",
                          })
                    }
                  />

                  <Flex flex={1}>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {t(action.key)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.other", {
                  defaultValue: "Autres",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              <Flex
                gap="s"
                flexDirection="row"
                alignItems="center"
                paddingVertical="xxs"
                justifyContent="space-between"
              >
                <Status
                  type={isCommentSharedToManager ? StatusType.SUCCESS : StatusType.DONE}
                  label={
                    isCommentSharedToManager
                      ? t("peopleReview.campaign.closeCampaignModal.shared", {
                          defaultValue: "Partagé",
                        })
                      : t("peopleReview.campaign.closeCampaignModal.notShared", {
                          defaultValue: "Non partagé",
                        })
                  }
                />

                <Flex flex={1}>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaign.closeCampaignModal.generalComment", {
                      defaultValue: "Commentaire général du coordinateur",
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="row-reverse">
            <DSButton
              buttonSize="S"
              emphasis="Mid"
              onClick={() => setIsEdit(true)}
              label={t("peopleReview.campaign.closeCampaignModal.edit", {
                defaultValue: "Modifier",
              })}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
