import React, { useState } from "react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

import { ManualSessionData } from "./Session";
import styles from "./Session.module.scss";
import useTranslation from "hooks/useTranslation";

const DatesPicker = ({
  sessionData,
  setSessionData,
}: {
  sessionData: ManualSessionData;
  setSessionData: (sessionData: ManualSessionData) => void;
}) => {
  const [focusedDateInput, setFocusedDateInput] = useState<"startDate" | "endDate">();
  const { t } = useTranslation();

  return (
    <div className={styles.datesPicker} aria-label="dates">
      <label>
        {t("trainings.view.add_trainees_modal.manual_import.realized_dates.title", {
          defaultValue: "Dates",
        })}
      </label>
      <div className={styles.dates}>
        <div className={styles.dateBlock}>
          <SingleDatePicker
            id="startDate"
            ariaLabel={t("trainings.entity.session.property.start_date", {
              defaultValue: "Date de début",
            })}
            placeholder={t("trainings.entity.session.property.start_date", {
              defaultValue: "Date de début",
            })}
            numberOfMonths={1}
            focused={focusedDateInput === "startDate"}
            onFocusChange={() => setFocusedDateInput("startDate")}
            onClose={() => setFocusedDateInput(undefined)}
            date={sessionData.sessionStartDate && moment(sessionData.sessionStartDate)}
            isDayBlocked={() => false}
            isOutsideRange={() => false}
            onDateChange={(date) =>
              setSessionData({
                ...sessionData,
                sessionStartDate: date ? date.format("YYYY-MM-DD") : undefined,
                sessionEndDate: undefined,
              })
            }
          />
        </div>
        <div className={styles.dateBlock}>
          <SingleDatePicker
            id="endDate"
            ariaLabel={t("trainings.entity.session.property.end_date", {
              defaultValue: "Date de fin",
            })}
            placeholder={t("trainings.entity.session.property.end_date", {
              defaultValue: "Date de fin",
            })}
            numberOfMonths={1}
            isDayBlocked={(newEndDate) => newEndDate.isBefore(sessionData.sessionStartDate)}
            isOutsideRange={() => false}
            date={sessionData.sessionEndDate && moment(sessionData.sessionEndDate)}
            focused={focusedDateInput === "endDate"}
            onFocusChange={() => setFocusedDateInput("endDate")}
            onClose={() => setFocusedDateInput(undefined)}
            disabled={!sessionData.sessionStartDate}
            onDateChange={(date) => {
              setSessionData({
                ...sessionData,
                sessionEndDate: date ? date.format("YYYY-MM-DD") : undefined,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DatesPicker;
