import i18n from "i18n";

import { Duration, Timespan } from "@skillup/shared-utils";
import { Project } from "services/sessions";

const { t: localizeEn } = i18n.cloneInstance({ lng: "en" });

export const getDefaultTitle = (project: Project, locale: "fr" | "fr-en" = "fr") => {
  if (locale === "fr") {
    return `CONVOCATION Formation "${project.training.name}" par ${project.training.organization}, ${
      project.properties.dates.length > 1 ? "à partir du" : "le"
    } ${new Date(project.properties.startDate).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })} à ${project.properties.city}`;
  } else {
    return `CONVOCATION Formation "${project.training.name}" ${
      project.properties.dates.length > 1 ? "à partir du" : "le"
    } ${new Date(project.properties.startDate).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })} à ${project.properties.city} / Training Invitation "${project.training.name}" ${
      project.properties.dates.length > 1 ? "starting on" : "on"
    } ${new Date(project.properties.startDate).toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })} à ${project.properties.city}`;
  }
};

const getFrContent = (project: Project) => {
  const preparedDates = project.properties.dates.map((datesArr) => datesArr[0] + "/" + datesArr[1]);
  const formatedDates = Timespan.convertForIntraSessions("fr-FR", preparedDates);

  return `Bonjour,<br /><br />
  Nous vous invitons à vous présenter à la formation "${project.training.name}":<br />
  ${formatedDates.map((session) => `- ${session}`).join(`<br />`)}
  <br />
  ${
    project.training.duration
      ? `Durée de la formation : ${Duration.formatDurationAsHours(project.training.duration)}`
      : ""
  }<br />
  La formation aura lieu à ${project.properties.city}${
    project.properties.room ? `, salle ${project.properties.room}` : ""
  }.<br /><br />
  Nous comptons sur votre présence et assiduité lors de la formation et restons à votre disposition pour toute information que vous jugeriez utile.
  
  Si vous ne pouvez pas participer à cette formation, veuillez informer votre service RH.<br />
  ${project.properties.link ? `\nLien de connexion : ${project.properties.link}\n` : ""}<br />
  Cordialement,<br />
  Le service Développement RH et Formation.`;
};

const getEnContent = (project: Project) => {
  const preparedDates = project.properties.dates.map((datesArr) => datesArr[0] + "/" + datesArr[1]);
  const formatedDates = Timespan.convertForIntraSessions("en-US", preparedDates);

  return `Hello,<br /><br />
    We invite you to attend the training "${project.training.name}":<br />
    ${formatedDates.map((session) => `- ${session}`).join(`<br />`)}
    <br />
    ${
      project.training.duration
        ? `Training duration: ${Duration.formatDurationAsHours(project.training.duration, localizeEn)}`
        : ""
    }<br />

    The training will take place at ${project.properties.city}${
      project.properties.room ? `, room ${project.properties.room}` : ""
    }<br /><br />
    We count on your presence and active participation during the training.
    Please let us know if you require any additional information.
    If you are unable to attend this training, kindly inform your HR department.<br />

    ${project.properties.link ? `\nConnection link : ${project.properties.link}\n<br />` : ""}<br />
  
    Best regards,<br/>
    The HR Development and Training Team
  `;
};

export const getDefaultContent = (project: Project, locale: "fr" | "fr-en" = "fr") => {
  const contents = {
    fr: getFrContent,
    "fr-en": (project: Project) => {
      return `
        ${getFrContent(project)}
        <br /><br /><hr /><br />
        ${getEnContent(project)}`;
    },
  };

  return contents[locale](project);
};
