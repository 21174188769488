import { useEffect, useState } from "react";
import {
  EmployeeField,
  getEmployeeProfileConfiguration,
  HistoricalDataConfiguration as THistoricalDataConfiguration,
} from "./services";
import DSLayout from "components/DSLayout";
import { Flex, Text } from "@skillup/design-system";
import { FieldConfiguration } from "./components/FieldConfiguration/FieldConfiguration";
import styles from "./EmployeeProfile.module.scss";
import { HistoricalDataConfiguration } from "./components/HistoricalData/HistoricalData";
import { useQuery } from "@tanstack/react-query";
import useTranslation from "hooks/useTranslation";
import { getEmployeeProfileConfigurationQueryKey } from "./services/get-employee-profile-configuration.service";
import { UpdatableFieldConfiguration } from "./types";

export const EmployeeProfile = () => {
  const { t } = useTranslation();
  const [employeeProfileConfiguration, setEmployeeProfileConfiguration] = useState<
    | {
        employeeFields: EmployeeField[];
        historicalDataConfiguration: THistoricalDataConfiguration;
        fieldConfiguration: UpdatableFieldConfiguration[];
      }
    | undefined
  >(undefined);

  const { data, isFetched } = useQuery(
    [getEmployeeProfileConfigurationQueryKey],
    getEmployeeProfileConfiguration
  );

  useEffect(() => {
    if (isFetched) {
      setEmployeeProfileConfiguration(data);
    }
  }, [isFetched, data]);

  const saveFieldConfiguration = (fields: UpdatableFieldConfiguration[]) => {
    if (employeeProfileConfiguration === undefined) {
      return;
    }
    setEmployeeProfileConfiguration((prev) => {
      if (prev === undefined) {
        return prev;
      }
      return {
        ...prev,
        fieldConfiguration: fields,
      };
    });
  };

  const saveHistoricalDataConfiguration = (configuration: THistoricalDataConfiguration) => {
    if (employeeProfileConfiguration === undefined) {
      return;
    }
    setEmployeeProfileConfiguration((prev) => {
      if (prev === undefined) {
        return prev;
      }
      return {
        ...prev,
        historicalDataConfiguration: configuration,
      };
    });
  };

  return (
    <DSLayout title={"Profil collaborateur"}>
      <Flex padding="l" flexDirection="column" flex={1}>
        <Flex maxWidth={"37.5rem"} marginRight="m">
          <Text espaceFont="body1Regular" color="plainText-onLight-default">
            {t("EmployeeProfile.description", {
              defaultValue:
                "Le profil collaborateur reprend l’ensemble des données liées aux collaborateurs pour les mettre à disposition",
            })}
            :
            <ul className={styles.descriptionList}>
              <li>
                {t("EmployeeProfile.description.option1", {
                  defaultValue: "des collaborateurs eux-mêmes sur leur page “Mon profil”",
                })}
              </li>
              <li>
                {t("EmployeeProfile.description.option2", {
                  defaultValue: "à leurs managers via leur page “Mon équipe”",
                })}
              </li>
            </ul>
          </Text>
        </Flex>
        {employeeProfileConfiguration === undefined ? (
          <Flex>
            <Text>Loading...</Text>
          </Flex>
        ) : (
          <Flex marginTop="3xl" gap="l">
            <FieldConfiguration
              fieldConfiguration={employeeProfileConfiguration.fieldConfiguration}
              employeeFields={employeeProfileConfiguration.employeeFields}
              saveFieldConfiguration={saveFieldConfiguration}
            />
            <HistoricalDataConfiguration
              configuration={employeeProfileConfiguration.historicalDataConfiguration}
              saveHistoricalDataConfiguration={saveHistoricalDataConfiguration}
            />
          </Flex>
        )}
      </Flex>
    </DSLayout>
  );
};
