import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { type Locales } from "@skillup/shared-utils";

import { DatePicker } from "./DatePicker";
import { DatePickerWrapper, BetweenOperator } from "../../../commons.styled";
import { Flex } from "components/Flex";

interface DateFilterProps {
  onChange: (value: Date | Array<Date>) => void;
  lang?: Locales;
  value: {
    value: Date | Array<Date>;
    operator: string;
  };
  darkMode?: boolean;
}

const DateFilter = ({ onChange, lang = "fr", value, darkMode = false }: DateFilterProps) => {
  const { t } = useTranslation();
  const [firstValue, setFirstValue] = React.useState<Date | null>(
    Array.isArray(value.value) ? value.value[0] : value.value
  );
  const [secondValue, setSecondValue] = React.useState<Date | null>(
    Array.isArray(value.value) ? value.value[1] : value.value
  );

  useEffect(() => {
    if (firstValue && secondValue) {
      onChange([firstValue, secondValue]);
    } else if (firstValue) {
      onChange(firstValue);
    }
  }, [firstValue, secondValue]);

  return (
    <Flex>
      <DatePickerWrapper darkMode={darkMode} noRadius={value?.operator === "between"}>
        <DatePicker
          lang={lang}
          value={firstValue}
          onChange={setFirstValue}
          slotProps={{ textField: { readOnly: true } }}
        />
      </DatePickerWrapper>
      {value?.operator === "between" && (
        <>
          <BetweenOperator darkMode={darkMode}>
            {t("design-system.operator.and", { defaultValue: "Et le" })}
          </BetweenOperator>
          <DatePickerWrapper darkMode={darkMode} noRadius={false}>
            <DatePicker
              lang={lang}
              value={secondValue}
              onChange={setSecondValue}
              slotProps={{ textField: { readOnly: true } }}
            />
          </DatePickerWrapper>
        </>
      )}
    </Flex>
  );
};

export { DateFilter };
