import { type Locales } from "@skillup/shared-utils";

import { type TranslationObject } from "../../types";
import type { TranslationFunction, CustomI18n } from "../../adapters/external/localization/types";

// This array contains all field bindings whose values need to be translated
const fieldsToValueTranslate: {
  [key: string]: ((rawValue: string | number | undefined) => string | null) | undefined;
} = {
  trainingMode: undefined,
  rowStatus: undefined,
  state: undefined,
  trainingIsCPF: undefined,
  deniedOrCancelledReason: undefined,
  fileStatus: undefined,
  trainingTypes: undefined,
  trainingType: undefined,
  rowType: undefined,
  sourceOfDemand: undefined,
  trainingTag: (rawValue) =>
    !rawValue ? null : `trainings.entity.training.property.tag.slug.${rawValue}.label`,
};

const fieldsToValueTranslateList = Object.keys(fieldsToValueTranslate);

export const translateFieldValue = (
  field: { binding: string; value?: string | number; rawValue?: string | number },
  localize: TranslationFunction
) => {
  const { value, binding, rawValue } = field;

  if (fieldsToValueTranslateList.includes(binding)) {
    const translationKey = fieldsToValueTranslate[binding]
      ? fieldsToValueTranslate[binding](rawValue)
      : `trainings.entity.schedule_row.property.field.${binding}.${rawValue}`;

    if (translationKey) {
      return localize(translationKey, {
        defaultValue: value?.toString(),
      });
    }
  }

  return field.value ?? "";
};

export const translateDynamicObject = (
  translations: TranslationObject,
  key: string,
  language: Locales,
  options?: { defaultValue?: string }
) => {
  const { defaultValue } = options || {};
  const translation = translations[language];

  if (language === "cimode") {
    return key;
  }

  if (!translation) {
    return defaultValue || key;
  }

  return translation[key] || defaultValue || key;
};

export const translateFieldLabel = (
  field: { binding: string; label: string; isStandard: boolean },
  i18n: CustomI18n,
  localize: TranslationFunction
) => {
  const { t: localizeFr } = i18n.cloneInstance({ lng: "fr" });

  if (!field.isStandard) {
    return field.label;
  }

  const translationKey = `trainings.entity.schedule_row.property.field.${field.binding}.label`;
  if (
    field.label !==
    localizeFr(translationKey, {
      defaultValue: field.label,
    })
  ) {
    return field.label;
  }

  return localize(translationKey, {
    defaultValue: field.label,
  });
};
