import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { cloneDeep, throttle, debounce } from "lodash";
import { DragOverEvent, DragStartEvent } from "@dnd-kit/core";
import { useDispatch, useSelector } from "react-redux";

import useTranslation from "hooks/useTranslation";
import {
  type TemplateItemPosition,
  move,
  selectTemplateSections,
  setHighlightUuid,
} from "../../reducer";
import { dndTemplateReducer } from "./dndTemplateReducer";
import { Overlay } from "../components";
import { useBuilderContext } from "../../BuilderContext";
import type { DNDTemplate } from "./types";
import { getChildLabel } from "./utils";

export function useTemplateDnd() {
  const { t } = useTranslation();
  const sections = useSelector(selectTemplateSections);
  const { parentsIds, updatedItemIdRef, closedItems, setClosedItems } = useBuilderContext();
  const [disabledSortablePages, setDisabledSortablePages] = useState(false);
  const [disabledSortableChildren, setDisabledSortableChildren] = useState(false);
  const [fromOriginalPosition, setFromOriginalPosition] = useState<
    TemplateItemPosition<any> | undefined
  >();
  const [overlayTemplate, setOverlayTemplate] = useState<DNDTemplate | undefined>();

  const storeDispatch = useDispatch();

  // local reducer only for DND (dragOver)
  const [templateState, structureDispatch] = useReducer(
    useMemo(() => dndTemplateReducer, []),
    {
      sections: [],
    } as DNDTemplate
  );

  // update structure state when templateStore changes, with a version of template without data
  // + create a copy for Overlay, since it must not be updated on dragOver
  useEffect(() => {
    if (!sections) return;
    const templateWithoutData = {
      sections: sections.map((section) => ({
        uuid: section.uuid,
        type: "section",
        title: section.title,
        errors:
          section.uuid === sections[0].uuid ? section.pages[0].children[0].errors : section.errors,
        pages: section.pages.map((page) => ({
          uuid: page.uuid,
          type: "page",
          title: page.title,
          hideInBuilder: page.hideInBuilder,
          errors: page.errors,
          children: page.children.map((child) => ({
            uuid: child.uuid,
            type: "child",
            kind: child.kind,
            kindType: "type" in child ? child.type : undefined,
            label: getChildLabel(child, t),
            errors: child.errors,
          })),
        })),
      })),
    } as DNDTemplate;
    const templateClone = cloneDeep(templateWithoutData);
    structureDispatch({ type: "init", template: templateClone });
    setOverlayTemplate(templateClone);
  }, [sections, setOverlayTemplate, t]);

  // To do : find a better way to update parentsIds (maybe in the context ?)
  useEffect(() => {
    if (!overlayTemplate) return;
    overlayTemplate.sections.forEach((section) => {
      parentsIds.current[section.uuid] = undefined;
      section.pages.forEach((page) => {
        parentsIds.current[page.uuid] = [section.uuid];
        page.children.forEach((child) => {
          parentsIds.current[child.uuid] = [section.uuid, page.uuid];
        });
      });
    });
  }, [overlayTemplate, parentsIds]);

  const overlay = useMemo(() => {
    if (!fromOriginalPosition || !overlayTemplate) return null;
    switch (fromOriginalPosition.type) {
      case "section":
        const section = overlayTemplate?.sections[fromOriginalPosition.sectionIndex];
        return <Overlay.Section section={section} />;
      case "page":
        const page =
          overlayTemplate?.sections[fromOriginalPosition.sectionIndex]?.pages[
            fromOriginalPosition.pageIndex
          ];
        return <Overlay.Page page={page} />;
      case "child":
        const child =
          overlayTemplate?.sections[fromOriginalPosition.sectionIndex]?.pages[
            fromOriginalPosition.pageIndex
          ]?.children[fromOriginalPosition.childIndex];
        return <Overlay.Child child={child} />;
      default:
        return null;
    }
  }, [fromOriginalPosition, overlayTemplate]);

  const openClosedItems = useMemo(
    () =>
      debounce((id: string) => {
        if (closedItems[id]) {
          setClosedItems((prev) => ({ ...prev, [id]: undefined }));
        }
      }, 300),
    [closedItems, setClosedItems]
  );

  const moveStructure = useMemo(
    () =>
      throttle((from: TemplateItemPosition<any>, to: TemplateItemPosition<any>) => {
        switch (from.type) {
          case "page":
            // Move the page to SortableContext item array of the other section
            if (from.sectionIndex !== to.sectionIndex) {
              structureDispatch({
                type: "movePageToSection",
                fromPosition: from,
                toSectionIndex: to.sectionIndex,
              });
            }
            break;
          case "child":
            // Move the child to SortableContext item array of the other page/section
            if (!(from.sectionIndex === to.sectionIndex && from.pageIndex === to.pageIndex)) {
              structureDispatch({ type: "moveChildToPage", fromPosition: from, toPosition: to });
            }
            break;
          // sections are in the same SortableContext so we don't need to do anything, we already
          // disabled sortable pages and children droppable collision when dragging a section
          case "section":
          default:
            break;
        }
      }, 50),
    [structureDispatch]
  );

  // DRAG HANDLERS
  const handleDragStart = useCallback((event: DragStartEvent) => {
    if (event.active?.data.current.type) {
      const startPosition: TemplateItemPosition<any> = { ...event.active.data.current };
      setFromOriginalPosition(startPosition);
      switch (event.active?.data.current.type) {
        case "section":
          setDisabledSortablePages(true);
          setDisabledSortableChildren(true);
          break;
        case "page":
          setDisabledSortableChildren(true);
          break;
      }
    }
  }, []);

  const handleDragOver = useMemo(
    () =>
      throttle((event: DragOverEvent) => {
        const { active, over } = event;
        if (!(active?.data.current.type && over?.data.current.type)) return;
        const [from, to]: [TemplateItemPosition<any>, TemplateItemPosition<any>] = [
          active.data.current,
          over.data.current,
        ];
        if (closedItems[over.id]) {
          if (
            over.id !== active.id &&
            ((from.type !== "section" && to.type === "section") ||
              (from.type === "child" && to.type === "page"))
          ) {
            return openClosedItems(`${over.id}`);
          }
        } else {
          openClosedItems.cancel();
          return moveStructure(from, to);
        }
      }, 50),
    [moveStructure, closedItems, openClosedItems]
  );

  const handleDragEnd = useCallback(
    (event: DragOverEvent) => {
      setDisabledSortablePages(false);
      setDisabledSortableChildren(false);
      const { active, over } = event;
      if (!(active?.data.current.type && over?.data.current.type)) {
        setFromOriginalPosition(undefined);
        return;
      }
      const to: TemplateItemPosition<any> = { ...over.data.current };
      storeDispatch(setHighlightUuid(`${active.id}`));
      updatedItemIdRef.current = {
        uuid: `${active.id}`,
        fromAction: "dragEnd",
      };
      switch (fromOriginalPosition.type) {
        case "section":
          storeDispatch(
            move({ blockType: "section", fromPosition: fromOriginalPosition, toPosition: to })
          );
          break;
        case "page":
          storeDispatch(
            move({ blockType: "page", fromPosition: fromOriginalPosition, toPosition: to })
          );
          break;
        case "child":
          storeDispatch(
            move({ blockType: "child", fromPosition: fromOriginalPosition, toPosition: to })
          );
          break;
        default:
          break;
      }
      setFromOriginalPosition(undefined);
    },
    [fromOriginalPosition, storeDispatch, updatedItemIdRef]
  );

  const handleDragCancel = useCallback(() => {
    setDisabledSortablePages(false);
    setDisabledSortableChildren(false);
    setFromOriginalPosition(undefined);
    setOverlayTemplate(undefined);
  }, [
    setDisabledSortablePages,
    setDisabledSortableChildren,
    setFromOriginalPosition,
    setOverlayTemplate,
  ]);

  //cleanup debounced/throttled functions
  useEffect(() => {
    return () => {
      openClosedItems.cancel();
    };
  }, [openClosedItems]);

  useEffect(() => {
    return () => {
      moveStructure.cancel();
    };
  }, [moveStructure]);

  useEffect(() => {
    handleDragOver.cancel();
  }, [handleDragOver]);

  return {
    templateState,
    handleDragStart,
    moveStructure,
    handleDragEnd,
    handleDragCancel,
    handleDragOver,
    disabledSortablePages,
    disabledSortableChildren,
    dragActive: fromOriginalPosition,
    overlay,
  };
}
