/* eslint-disable react-hooks/exhaustive-deps */
import { MdGroups as Groups } from "react-icons/md";
import { Duration } from "@skillup/shared-utils";
import { ColumnType } from "@skillup/ui";
import { useMemo } from "react";
import { uniqBy } from "lodash";
import type { Habilitation } from "../types";
import { TranslationType } from "hooks/useTranslation";

function useTableData(
  habilitations: Array<Habilitation>,
  t: TranslationType
): {
  data: Array<TableRow>;
  columns: Array<{ key: string; title: string }>;
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns(habilitations, t),
      data: generateTableRows(habilitations ?? [], t),
    }),
    [habilitations]
  );

  return tableData;
}

export default useTableData;

function generateTableRows(habilitations: Array<Habilitation>, t: TranslationType) {
  return habilitations?.map((habilitation) => parseHabilitationIntoRow(habilitation, t));
}

const generateColumns = (habilitations: Array<Habilitation>, t: TranslationType) => {
  const nameOptions = uniqBy(habilitations, (h) => {
    return h.name;
  })
    .map((e) => ({ value: e.name, label: e.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const categoryOptions = uniqBy(habilitations, (h) => {
    return h.category.name;
  })
    .map((e) => ({ value: e.category.name, label: e.category.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const columns = [
    {
      key: "name",
      title: t("trainings.entity.habilitation.property.name", {
        defaultValue: "Nom de l'habilitation",
      }),
      type: ColumnType.SEARCH_SELECT,
      options: nameOptions,
      largeFilterForm: true,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.name === value);
      },
      width: 21.875,
    },
    {
      key: "category",
      title: t("trainings.entity.habilitation.property.category", {
        defaultValue: "Catégorie",
      }),
      type: ColumnType.SEARCH_SELECT,
      options: categoryOptions,
      largeFilterForm: true,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.category === value);
      },
      width: 21.875,
    },
    {
      key: "validity",
      title: t("trainings.entity.habilitation.property.validity", {
        defaultValue: "Durée de validité",
      }),
      width: 12.5,
      filterable: false,
    },
    {
      key: "deadlineAnticipation",
      title: t("trainings.entity.habilitation.property.deadlineAnticipation", {
        defaultValue: "Anticipation échéance",
      }),
      width: 12.5,
      filterable: false,
    },
    {
      key: "trackingsCount",
      title: t("trainings.entity.habilitation.property.trackingsCount", {
        defaultValue: "Nombre de collaborateurs associés",
      }),
      icon: <Groups />,
      filterable: false,
      width: 5,
    },
  ];

  return columns;
};

export type TableRow = ReturnType<typeof parseHabilitationIntoRow>;
function parseHabilitationIntoRow(habilitation: Habilitation, t: TranslationType) {
  return {
    id: habilitation.uuid,
    data: {
      actions: habilitation.actions,
      trackingsCount: habilitation.trackingsCount,
      name: habilitation.name,
      category: habilitation.category.name,
      validity:
        habilitation.validity === "limited"
          ? Duration.fromIsoToHumain(habilitation.duration)
          : t("trainings.entity.habilitation.property.validity_unlimited", {
              defaultValue: "Illimitée",
            }),
      deadlineAnticipation:
        habilitation.validity === "limited"
          ? Duration.fromIsoToHumain(habilitation.deadlineAnticipation)
          : "-",
    },
  };
}
