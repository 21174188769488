import { Summary } from "@skillup/types";

const statusForManager = {
  approved_by_hr: () => "Validée",
  approved_by_manager: () => "Validée",
  cancelled_by_hr: () => "Annulée",
  cancelling: () => "Annulée",
  denied_by_hr: () => "Refusée",
  denied_by_manager: () => "Refusée",
  done: () => "Réalisée",
  invalid: () => "Annulée",
  lost: () => "Annulée",
  pending: () => "Validée",
  pending_for_manager: () => "À valider",
  positioned: () => "Inscrit",
  positioning: () => "Inscrit",
  processing: () => "Inscrit",
  submitted: () => "Inscrit",
  won: () => "Inscrit",
  attended: () => "Réalisé",
};

const statusForHR = (summary?: Summary) => ({
  attended: "Réalisé",
  approved_by_hr: "À inscrire",
  approved_by_manager: summary?.isSpecificRequest ? "À requalifier" : "À valider",
  cancelled_by_hr: "Annulée",
  cancellation_pending: "Annulée",
  cancelling: "Annulée",
  denied_by_hr: "Refusée",
  denied_by_manager: "Refusée",
  done: "Réalisée",
  invalid: "Annulée",
  lost: "Annulée",
  pending: summary?.isSpecificRequest ? "À requalifier" : "À valider",
  pending_for_manager: "En attente de validation manager",
  positioned: "Inscrit",
  positioning: "Choix de session en cours",
  processing: "Inscrit",
  submitted: "Inscrit",
  won: "Inscrit",
});

const statusLabelsForHR = {
  attended: "Formation réalisée.",
  approved_by_hr:
    "Cette demande a été validée et passée au plan. Vous pouvez désormais inscrire ce stagiaire.",
  approved_by_manager: "Cette demande est en attente de validation de votre part.",
  cancelled_by_hr: "Cette demande a été annulée.",
  cancelling: "Cette demande est en cours d’annulation.",
  denied_by_hr: "Cette demande a été refusée.",
  denied_by_manager: "Cette demande a été refusée.",
  done: "Cette session est réalisée.",
  invalid: "Cette demande a été annulée.",
  lost: "Cette demande d’inscription a été refusée par l‘organisme.",
  pending: "Cette demande est en attente de validation de votre part.",
  pending_for_manager: "Cette demande est en attente de validation manager.",
  positioned: "Cette demande d‘inscription est en cours de traitement.",
  positioning: "Un e-mail de demande d'inscription a été envoyé.",
  processing: "Cette demande d‘inscription est en cours de traitement.",
  submitted: "Cette demande d‘inscription est en cours de traitement.",
  won: "Cette demande d’inscription a été acceptée par l’organisme.",
};

export { statusForManager, statusForHR, statusLabelsForHR };

export default {
  statusForManager,
  statusForHR,
  statusLabelsForHR,
};
