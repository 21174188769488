import { ITraining, USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import InteractiveButton from "components/InteractiveButton";

import { IUser } from "./checkUser";

import styles from "./UserChoiceModal.module.scss";
import AddTraineesContent from "components/AddTraineesModal/AddTraineesContent";
import { useUsersList } from "components/AddTraineesModal/add-trainees-context";
import { switchOnError } from "containers/Supervisor/routes/TrainingSchedule/Modals/AddRow/helper";
import { useToggle } from "react-use";
import Acta from "utils/Acta";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";
import useTranslation from "hooks/useTranslation";

export type UserList = IUser[];

export interface IProps {
  onSubmit: (users: UserList, onError?: Function) => Promise<void>;
  training?: Pick<ITraining, "name" | "organization" | "price" | "duration">;
  showManagerEmailInput: boolean;
  usersToPickFrom?: Array<IUser>;
}

export default ({ onSubmit, training }: IProps) => {
  const {
    state: { selectedUsers },
  } = useUsersList();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();

  const submit = async () => {
    toggleLoading(true);
    try {
      if (selectedUsers.length === 0) {
        throw new Error("users");
      }

      if (selectedUsers.some((user) => !!user.errors?.length)) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Certains collaborateurs sélectionnés contiennent des erreurs",
          type: "error",
        });

        return;
      }

      await onSubmit(selectedUsers);
    } catch (err) {
      switchOnError(err, t);
    } finally {
      toggleLoading(false);
    }
  };

  const groups = useAreaUserGroups(t);

  return (
    <div className={styles.UserChoiceModal}>
      <div className={styles.preview} aria-label="preview-formation">
        <p className={styles.recall}>
          {t("trainings.view.schedule.add_trainees_modal.program_from_skillup.label", {
            defaultValue: "Programme créé depuis Skillup",
          })}
        </p>
        {training && (
          <div>
            <p>
              {t("trainings.view.schedule.add_trainees_modal.training_selected.label", {
                defaultValue: `Formation sélectionée: {{name}} - {{organization}} - {{price}} - {{duration}}`,
                name: training.name,
                organization: training.organization,
                price: training.price,
                duration: training.duration,
              })}
            </p>
          </div>
        )}
      </div>
      <AddTraineesContent
        canUploadManager={false}
        scope={USER_FILE_VALIDATION_TYPE.FREE_ROW}
        customUsersLists={
          groups && [
            {
              label: t("trainings.entity.training.add_trainees_modal.select_by_area.label", {
                defaultValue: "Sélectionner par périmètre",
              }),
              groups,
            },
          ]
        }
      />
      <div className={styles.nextStep}>
        <InteractiveButton
          label={t("trainings.common.button.add.label", {
            defaultValue: "Ajouter",
          })}
          onClick={submit}
          loading={loading}
        />
      </div>
    </div>
  );
};
