import { useMachine } from "@xstate/react";
import { TranslationFunction } from "@skillup/learning-service-core";
import { useCallback, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { Project } from "services/sessions";
import { summonMachine, type EmailPayload } from "./stateMachine";

import { ChooseSummonTypeModal } from "./ChooseSummonTypeModal";
import { EmailSettingsModal } from "./EmailSettingsModal";
import { SettingCalendarEventsModal } from "./SettingCalendarEventsModal";
import { ValidateSummoningModal } from "./ValidateSummonModal";
import { SummonProjectV2 } from "services/sessions/summon";
import useTranslation from "hooks/useTranslation";

type UseSummonModalParmas = {
  summonSession: (params: SummonProjectV2) => Promise<boolean>;
};

export const useSummonModal = ({ summonSession }: UseSummonModalParmas) => {
  const [current, send] = useMachine(summonMachine);
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const startSummoning = useCallback(
    (session: Project) => {
      // maybe protect with a match
      send({ type: "TRIGGER_SUMMON", payload: { session } });
    },
    [send]
  );

  const handleSummonSession = useCallback(
    async (params: SummonProjectV2) => {
      const summoningResponse = await summonSession(params);
      if (summoningResponse === false) {
        addToast(
          t("trainings.view.summon.modal.confirm.alert.error.label", {
            defaultValue: "Erreur lors de la convocation de la session",
          }),
          {
            appearance: "error",
          }
        );
        send("BACK");
        return;
      }
      addToast(
        "isSummonedBySkillup" in params
          ? t("trainings.view.summon.modal.confirm.alert.succes.by_skillup.label", {
              defaultValue: "L'email de convocation a été envoyé avec succès",
            })
          : t("trainings.view.summon.modal.confirm.alert.succes.label", {
              defaultValue: "La session a bien été convoquée hors Skillup",
            }),
        {
          appearance: "success",
        }
      );
      send("TERMINATE");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [send, addToast, summonSession]
  );

  const cancelSummoning = useCallback(() => {
    send("ABORT");
  }, [send]);

  const modal = useMemo(() => {
    switch (true) {
      case current.matches("choosingType"):
        return (
          <ChooseSummonTypeModal
            session={current.context.session}
            onClose={cancelSummoning}
            summonViaSkillup={() => send("START_SKILLUP_SUMMON")}
            selfSummon={() => send("VALIDATE_SELF_SUMMON")}
          />
        );

      case current.matches("emailSettings"):
        return (
          <EmailSettingsModal
            session={current.context.session}
            onClose={cancelSummoning}
            onConfirm={(email: EmailPayload) => {
              if (email.options.sendCalendarEvents) {
                send({ type: "SET_CALENDAR_EVENTS", payload: { email } });
              } else {
                send({ type: "VALIDATE_SKILLUP_SUMMON", payload: { email } });
              }
            }}
            emailPayload={current.context.email}
            onBack={() => send("BACK")}
          />
        );

      case current.matches("validateSelfSummoning"):
        return (
          <ValidateSummoningModal
            onClose={cancelSummoning}
            onConfirm={async () => {
              await handleSummonSession({
                sessionUuid: current.context.session.properties.uuid,
                rows: current.context.session.positionnedTrainees.map((trainee) => trainee.row),
                dateIntervals: [],
              });
            }}
            onBack={() => send("BACK")}
            title={t("trainings.view.summon.modal.confirm.summon.label", {
              defaultValue: "Convoquer la session hors Skillup",
            })}
          >
            <div>
              {t("trainings.view.summon.modal.confirm.warning.label", {
                defaultValue:
                  "Si vous convoquez la session hors Skillup, cette dernière passera à l'état “Convoquée” mais les stagiaires ne recevront pas de convocation de notre part.",
              })}
            </div>
          </ValidateSummoningModal>
        );

      case current.matches("settingCalendarEvents"):
        return (
          <SettingCalendarEventsModal
            session={current.context.session}
            onClose={cancelSummoning}
            onConfirm={(calendarEvents) => {
              send({
                type: "VALIDATE_SKILLUP_SUMMON_WITH_EVENTS",
                payload: {
                  calendarEvents,
                },
              });
            }}
            onBack={() => send("BACK")}
            calendarEvents={current.context.calendarEvents}
          />
        );

      case current.matches("validateSkillupSummoning"):
        return (
          <ValidateSummoningModal
            onClose={cancelSummoning}
            onConfirm={async () => {
              await handleSummonSession({
                sessionUuid: current.context.session.properties.uuid,
                subject: current.context.email.subject,
                content: current.context.email.body,
                dateIntervals: [],
                rows: current.context.session.positionnedTrainees.map((trainee) => trainee.row),
                sendCalendarInvitations: current.context.email.options.sendCalendarEvents,
                sendCalendarTrainers: current.context.email.options.sendCalendarEvents,
                sendCopyToHR: current.context.email.options.sendRecapToHR,
                sendCopyToManagers: current.context.email.options.sendRecapToManagers,
                attachments: current.context.email.attachments ?? [],
                isSummonedBySkillup: true,
              });
            }}
            onBack={() => send("BACK")}
            title={t("trainings.view.summon.modal.confirm.title.label", {
              defaultValue: "Confirmation",
            })}
            confirmButtonLabel={t("trainings.common.send.label", {
              defaultValue: "Envoyer",
            })}
          >
            <SkillupSummonModalContent
              session={current.context.session}
              emailPayload={current.context.email}
            />
          </ValidateSummoningModal>
        );

      case current.matches("validateSkillupSummoningWithEvents"): {
        return (
          <ValidateSummoningModal
            onClose={cancelSummoning}
            onConfirm={async () => {
              await handleSummonSession({
                sessionUuid: current.context.session.properties.uuid,
                subject: current.context.email.subject,
                content: current.context.email.body,
                dateIntervals: current.context.calendarEvents,
                rows: current.context.session.positionnedTrainees.map((trainee) => trainee.row),
                sendCalendarInvitations: current.context.email.options.sendCalendarEvents,
                sendCalendarTrainers: current.context.email.options.sendCalendarEvents,
                sendCopyToHR: current.context.email.options.sendRecapToHR,
                sendCopyToManagers: current.context.email.options.sendRecapToManagers,
                attachments: current.context.email.attachments ?? [],
                isSummonedBySkillup: true,
              });
            }}
            onBack={() => send("BACK")}
            title={t("trainings.view.summon.modal.confirm.title.label", {
              defaultValue: "Confirmation",
            })}
            confirmButtonLabel={"Envoyer"}
          >
            <SkillupSummonModalContent
              session={current.context.session}
              emailPayload={current.context.email}
            />
          </ValidateSummoningModal>
        );
      }

      case current.matches("Idle"):
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, send, cancelSummoning, handleSummonSession]);

  return {
    startSummoning,
    cancelSummoning,
    modal,
  };
};

function SkillupSummonModalContent(props: { session: Project; emailPayload: EmailPayload }) {
  const { t } = useTranslation();

  const traineesAndTrainersItem = useMemo(() => {
    return traineesAndTrainersString(
      props.session.positionnedTrainees.length,
      props.session.trainers.length,
      props.emailPayload.options.sendCalendarEvents,
      t
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <ul>
      <li>{traineesAndTrainersItem}</li>
      {props.emailPayload?.options.sendRecapToManagers && (
        <li>
          {t("trainings.view.summon.modal.confirm.info.manager_notify.label.singular", {
            defaultValue: "Son manager recevra un email d'information.",
          })}
        </li>
      )}
      {props.emailPayload?.options.sendRecapToHR && (
        <li>
          {t("trainings.view.summon.modal.confirm.info.collaborator_notify.label", {
            defaultValue:
              "Vous recevrez un récapitulatif de la convocation contenant l'email de convocation.",
          })}
        </li>
      )}
    </ul>
  );
}

function traineesAndTrainersString(
  traineesCount: number,
  trainersCount: number,
  calendarEvents: boolean,
  t: TranslationFunction
) {
  const trainees = t("trainings.view.summon.modal.confirm.string_builder.trainees.label", {
    defaultValue: "Le stagiaire",
    count: traineesCount,
  });
  const trainers =
    trainersCount === 0
      ? ""
      : t("trainings.view.summon.modal.confirm.string_builder.trainers.label", {
          defaultValue: " et le formateur",
          count: trainersCount,
        });
  const verb = t("trainings.view.summon.modal.confirm.string_builder.verb.label", {
    defaultValue: "recevra",
    count: traineesCount + trainersCount,
  });
  const calendarEventString = calendarEvents
    ? t("trainings.view.summon.modal.confirm.string_builder.calendar_event.label", {
        defaultValue: " contenant une invitation agenda",
      })
    : "";

  return `${trainees} ${trainers} ${verb} ${t(
    "trainings.view.summon.modal.confirm.string_builder.convocation_email.label",
    { defaultValue: "un email de convocation" }
  )} ${calendarEventString}.`;
}
