import { type CSSProperties } from "react";

import styled from "styled-components";
import { type ColorsValues } from "commons/colors";

import {
  translateColor,
  translateSpacing,
  type SpacingTokenValues,
  translateDSSpacingToken,
} from "../utils";

export type FlexInterface = Pick<
  CSSProperties,
  | "top"
  | "flex"
  | "left"
  | "width"
  | "right"
  | "height"
  | "zIndex"
  | "bottom"
  | "flexGrow"
  | "flexWrap"
  | "gridArea"
  | "maxWidth"
  | "minWidth"
  | "overflow"
  | "position"
  | "flexBasis"
  | "minHeight"
  | "overflowX"
  | "overflowY"
  | "maxHeight"
  | "alignItems"
  | "flexDirection"
  | "justifyContent"
> & {
  zIndex?: string;

  gap?: SpacingTokenValues;

  backgroundColor?: ColorsValues;
  paddingTop?: SpacingTokenValues;

  paddingLeft?: SpacingTokenValues;
  paddingRight?: SpacingTokenValues;
  paddingBottom?: SpacingTokenValues;
  paddingVertical?: SpacingTokenValues;
  padding?: string | SpacingTokenValues;

  paddingHorizontal?: SpacingTokenValues;
  marginTop?: "auto" | SpacingTokenValues;

  marginLeft?: "auto" | SpacingTokenValues;
  marginRight?: "auto" | SpacingTokenValues;
  marginBottom?: "auto" | SpacingTokenValues;
  marginVertical?: "auto" | SpacingTokenValues;
  margin?: "auto" | string | SpacingTokenValues;

  marginHorizontal?: "auto" | SpacingTokenValues;
};

const FlexWrapper = styled.div<FlexInterface>`
  display: flex;

  & {
    ${({ flex }) => flex && `flex: ${flex}`};

    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};

    ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};

    ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow}`};

    ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};

    ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}`};

    ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`};

    ${({ gap }) => gap && `gap: ${translateDSSpacingToken(gap)}`};

    ${({ width }) => width && `width: ${width}`};

    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};

    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};

    ${({ height }) => height && `height: ${height}`};

    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};

    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};

    ${({ position }) => position && `position: ${position}`};

    ${({ top }) => top && `top: ${top}`};
    ${({ bottom }) => bottom && `bottom: ${bottom}`};
    ${({ left }) => left && `left: ${left}`};
    ${({ right }) => right && `right: ${right}`};

    ${({ marginVertical }) =>
      marginVertical &&
      `margin-top: ${translateDSSpacingToken(
        marginVertical
      )}; margin-bottom: ${translateDSSpacingToken(marginVertical)}`};

    ${({ marginHorizontal }) =>
      marginHorizontal &&
      `margin-right: ${translateDSSpacingToken(
        marginHorizontal
      )}; margin-left: ${translateDSSpacingToken(marginHorizontal)}`};

    ${({ marginTop }) => marginTop && `margin-top: ${translateDSSpacingToken(marginTop)}`};

    ${({ marginBottom }) =>
      marginBottom && `margin-bottom: ${translateDSSpacingToken(marginBottom)}`};

    ${({ marginLeft }) => marginLeft && `margin-left: ${translateDSSpacingToken(marginLeft)}`};

    ${({ marginRight }) => marginRight && `margin-right: ${translateDSSpacingToken(marginRight)}`};

    ${({ margin }) => margin && `margin: ${translateSpacing(margin)}`};

    ${({ paddingVertical }) =>
      paddingVertical &&
      `padding-top: ${translateDSSpacingToken(
        paddingVertical
      )}; padding-bottom: ${translateDSSpacingToken(paddingVertical)}`};

    ${({ paddingHorizontal }) =>
      paddingHorizontal &&
      `padding-right: ${translateDSSpacingToken(
        paddingHorizontal
      )}; padding-left: ${translateDSSpacingToken(paddingHorizontal)}`};

    ${({ paddingTop }) => paddingTop && `padding-top: ${translateDSSpacingToken(paddingTop)}`};

    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${translateDSSpacingToken(paddingBottom)}`};

    ${({ paddingLeft }) => paddingLeft && `padding-left: ${translateDSSpacingToken(paddingLeft)}`};

    ${({ paddingRight }) =>
      paddingRight && `padding-right: ${translateDSSpacingToken(paddingRight)}`};

    ${({ padding }) => padding && `padding: ${translateSpacing(padding)}`};

    ${({ overflowY }) => overflowY && `overflow-y: ${overflowY}`};

    ${({ overflowX }) => overflowX && `overflow-y: ${overflowX}`};

    ${({ overflow }) => overflow && `overflow: ${overflow}`};

    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};

    ${({ backgroundColor }) =>
      backgroundColor && `background-color: ${translateColor(backgroundColor)}`};
  }
`;

export default FlexWrapper;
