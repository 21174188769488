import { Text, Flex } from "@skillup/design-system";
import { DSButton, DSTextInput } from "@skillup/ui";
import { useMemo, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Search from "@mui/icons-material/Search";
import styles from "./EditFieldConfiguration.module.scss";
import type { EmployeeField } from "../../../services";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SortableItem } from "./components/SortableItem";
import useTranslation from "hooks/useTranslation";
import { UpdatableFieldConfiguration } from "../../../types";

type EditFieldConfigurationProps = {
  employeeProfileConfiguration: {
    employeeFields: EmployeeField[];
    fieldConfiguration: UpdatableFieldConfiguration[];
  };
  saveFieldConfiguration: (fields: UpdatableFieldConfiguration[]) => void;
  disabledEdit: () => void;
};

export const EditFieldConfiguration = ({
  employeeProfileConfiguration,
  saveFieldConfiguration,
  disabledEdit,
}: EditFieldConfigurationProps) => {
  const { t } = useTranslation();
  const [employeeFieldSearch, setEmployeeFieldSearch] = useState<string>("");
  const [tempFieldConfiguration, setTempFieldConfiguration] = useState<
    UpdatableFieldConfiguration[]
  >(employeeProfileConfiguration.fieldConfiguration);

  const disabledEmployeeFields = useMemo(() => {
    if (!employeeFieldSearch) {
      return employeeProfileConfiguration.employeeFields.filter((field) => {
        return !tempFieldConfiguration.find(
          (fieldConfiguration) => fieldConfiguration.binding === field.binding
        );
      });
    }
    return employeeProfileConfiguration.employeeFields
      .filter((field) => field.label.toLowerCase().includes(employeeFieldSearch.toLowerCase()))
      .filter((field) => {
        return !tempFieldConfiguration.find(
          (fieldConfiguration) => fieldConfiguration.binding === field.binding
        );
      });
  }, [employeeProfileConfiguration, tempFieldConfiguration, employeeFieldSearch]);

  const fieldMap = useMemo(() => {
    return employeeProfileConfiguration.employeeFields.reduce<Record<string, EmployeeField>>(
      (acc, field) => {
        acc[field.binding] = field;
        return acc;
      },
      {}
    );
  }, [employeeProfileConfiguration]);

  const handleChangeEmployeeProfileConfiguration = (bindingToChange: string) => {
    const updatedFieldConfiguration = [...tempFieldConfiguration];
    const index = tempFieldConfiguration.findIndex((f) => f.binding === bindingToChange);
    if (index === -1) {
      updatedFieldConfiguration.push({
        binding: bindingToChange,
        order: tempFieldConfiguration.length + 1,
      });
    } else {
      updatedFieldConfiguration.splice(index, 1);
    }
    setTempFieldConfiguration(updatedFieldConfiguration);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = tempFieldConfiguration.findIndex((item) => item.binding === active.id);
      const newIndex = tempFieldConfiguration.findIndex((item) => item.binding === over.id);
      const sortedItems = arrayMove<UpdatableFieldConfiguration>(
        tempFieldConfiguration,
        oldIndex,
        newIndex
      );
      sortedItems.forEach((item, index) => {
        item.order = index + 1;
      });
      setTempFieldConfiguration(sortedItems.sort((a, b) => a.order - b.order));
    }
  };

  const handleSave = () => {
    saveFieldConfiguration(tempFieldConfiguration);
    disabledEdit();
  };

  return (
    <Flex
      className={styles.body}
      flexDirection="column"
      backgroundColor="surface-light"
      padding="s"
      gap="s"
    >
      {/* Body header */}
      <Flex flexDirection="column" maxWidth={"600px"} marginRight="m">
        <Flex marginBottom="xs">
          <Text espaceFont="h5" id="privacy">
            {t("employeeProfile.fieldConfiguration.title", {
              defaultValue: "Informations mises en avant",
            })}
          </Text>
        </Flex>
        <Flex>
          <Text espaceFont="body2Regular">
            {t("employeeProfile.fieldConfiguration.edit.subTitle", {
              defaultValue:
                "Choisissez jusqu’à 12 données à afficher dans le bloc d’informations du profil et du mini-profil.",
            })}
          </Text>
        </Flex>
      </Flex>

      {/* Body content */}
      <Flex marginTop="s" gap="xxl">
        {/* Body 1st section */}
        <Flex flexDirection="column" flex={1}>
          <Flex>
            <Text espaceFont="captionBold">
              {t("employeeProfile.fieldConfiguration.edit.EmployeeFields.title", {
                defaultValue: "Données disponibles",
              })}
            </Text>
          </Flex>
          <Flex marginTop="xxs">
            <DSTextInput
              name="EmployeeFieldSearch"
              placeholder={t("employeeProfile.fieldConfiguration.edit.EmployeeFields.searchInput", {
                defaultValue: "Chercher une donnée",
              })}
              onChange={setEmployeeFieldSearch}
              iconRight={<Search />}
              className={styles.searchInput}
            />
          </Flex>
          <Flex marginTop="xs" flexDirection="column">
            {disabledEmployeeFields.map((field) => (
              <Flex
                key={field.binding}
                alignItems="center"
                justifyContent="space-between"
                padding="xxs"
                gap="xs"
              >
                <Text espaceFont="captionRegular">{field.label}</Text>
                <DSButton
                  iconOnly
                  icon={<VisibilityOff />}
                  buttonSize="S"
                  onClick={() => handleChangeEmployeeProfileConfiguration(field.binding)}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>

        {/* Body 2nd section */}
        <Flex flexDirection="column" flex={1}>
          <Flex>
            <Text espaceFont="captionBold">
              {t("employeeProfile.fieldConfiguration.edit.fieldConfiguration.title", {
                defaultValue: "Données affichées",
              })}
            </Text>
          </Flex>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              items={tempFieldConfiguration.map((item) => item.binding)}
              strategy={verticalListSortingStrategy}
            >
              <Flex
                marginTop="xxs"
                padding="s"
                flex={1}
                flexDirection="column"
                className={styles.fieldConfigurationContainer}
              >
                {tempFieldConfiguration.map((field) => (
                  <SortableItem key={field.binding} id={field.binding}>
                    <Flex
                      className={styles.fieldConfigurationItem}
                      padding="xxs"
                      gap="xs"
                      alignItems="center"
                      justifyContent="space-between"
                      flex={1}
                    >
                      <Flex alignItems="center" gap="xs">
                        <Text espaceFont="captionRegular">{fieldMap[field.binding].label}</Text>
                      </Flex>
                      <DSButton
                        iconOnly
                        icon={<Visibility />}
                        buttonSize="S"
                        onClick={() => handleChangeEmployeeProfileConfiguration(field.binding)}
                      />
                    </Flex>
                  </SortableItem>
                ))}
              </Flex>
            </SortableContext>
          </DndContext>
        </Flex>
      </Flex>
      <Flex gap="xs" justifyContent="flex-end">
        <DSButton
          label={t("employeeProfile.fieldConfiguration.edit.btn.cancel", {
            defaultValue: "Annuler",
          })}
          buttonSize="S"
          emphasis="Mid"
          onClick={disabledEdit}
        />
        <DSButton
          label={t("employeeProfile.fieldConfiguration.edit.btn.confirm", {
            defaultValue: "Enregistrer les modifications",
          })}
          buttonSize="S"
          emphasis="High"
          onClick={handleSave}
        />
      </Flex>
    </Flex>
  );
};
