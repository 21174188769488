import { useMemo, useState, useCallback } from "react";
import { useSetState } from "react-use";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";

import cx from "classnames";
import { isNil } from "lodash";
import useTranslation from "hooks/useTranslation";

import { formatError } from "services/errors";
import type { TrackingRoutes, UploadFileRoute } from "types/api";
import { buildSafeRequest, buildFileRequest } from "utils/buildRequest";

import {
  DSButton,
  Modal,
  FileDrop,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
  DatePicker,
  DSFormGroupTextInput,
  RadioBox,
  Label,
} from "@skillup/ui";
import { fr } from "@skillup/shared-utils";
import { Future, Either } from "@skillup/monads";

import { useTrackingByUuid } from "../../state/tracking";
import TrackingUuidNotFound from "../TrackingUuidNotFound";

import styles from "./RenewTracking.module.scss";

interface Props {
  trackingUuid: string;
  readonly onClose: () => void;
}

type Payload = TrackingRoutes.Renew["payload"];

type IForm = {
  validSince?: Date;
  document?: File;
  isShared: boolean;
};

const renewTracking = async (
  trackingUuid: string,
  { validSince }: Payload,
  file?: File,
  isShared?: boolean
): Promise<Either<TrackingRoutes.Renew["errors"], TrackingRoutes.Renew["response"]>> => {
  let fileUuid: string;
  if (file) {
    const uploadRequest = buildFileRequest<UploadFileRoute>({
      file,
      method: "POST",
      path: "/files",
      query: {
        context: "regulatory",
      },
    });
    try {
      const uploadedFile = await uploadRequest();
      fileUuid = uploadedFile.uuid;
    } catch (e) {
      return Either.Left("tracking.renew.failed_to_link_doc");
    }
  }

  const request = await buildSafeRequest<TrackingRoutes.Renew>({
    method: "POST",
    path: "/tracking/{uuid}/renew",
    params: {
      uuid: trackingUuid,
    },
    payload: {
      validSince,
      document: fileUuid && {
        fileUuid,
        type: "training_certificate",
        isShared,
      },
    },
  });

  return request.run();
};

function buildPayloadFromForm(form: IForm): Payload {
  const { validSince } = form;

  return {
    validSince: validSince?.toISOString(),
  };
}

export default ({ trackingUuid, onClose }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const tracking = useTrackingByUuid(trackingUuid);

  const [form, setFormState] = useSetState<IForm>({ isShared: true });

  const [loading, setLoading] = useState(false);
  const isFormStateValid = useMemo(() => form.validSince, [form]);

  const submitRequest = async () => {
    const request = renewTracking(
      trackingUuid,
      buildPayloadFromForm(form),
      form.document,
      form.isShared
    );

    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec du renouvellement de la ligne de suivi.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_habilitation) => {
        addToast(
          t("tracking.renew.success", { defaultValue: "Ligne de suivi renouvelée avec succès" }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  function handleInputChange(key: keyof IForm) {
    return (value: IForm[typeof key]) => {
      setFormState({
        [key]: value,
      });
    };
  }

  const onDrop = useCallback(
    async ([document]: File[]) => {
      setFormState({
        document,
      });
    },
    [setFormState]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
  });

  if (tracking.isLeft()) {
    return <TrackingUuidNotFound trackingUuid={trackingUuid} onClose={onClose} />;
  }

  const data = tracking.right();

  return (
    <Modal
      title={t("trainings.view.regulatory_collaborator_tracking.action.renewal", {
        defaultValue: "Valider le renouvellement",
      })}
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.renewTracking}>
        <div className={styles.trackingItem}>
          <div className={styles.trackingHabilitation}>
            {t("trainings.view.regulatory_collaborator_tracking.renewal_modal.habilitation_for", {
              defaultValue: "{{ habilitation }} pour {{ user }}",
              habilitation: data.habilitation.name,
              user: data.user.fullName,
            })}
          </div>
        </div>
        <div className={cx(styles.inputLine, styles.inline)}>
          <DSFormGroupTextInput
            className={styles.label}
            label={t(
              "trainings.view.regulatory_collaborator_tracking.renewal_modal.habilitation_validity",
              {
                defaultValue: "Début de validité de la nouvelle habilitation",
              }
            )}
            required
          >
            <DatePicker
              placeholder={t(
                "trainings.view.regulatory_collaborator_tracking.renewal_modal.date.placeholder",
                {
                  defaultValue: "Choisissez une date",
                }
              )}
              value={form.validSince}
              onChange={handleInputChange("validSince")}
              autoFormat
              locale={fr}
              className={cx(styles.input, styles.inputDate)}
              autoFocus
            />
          </DSFormGroupTextInput>
        </div>
        <div className={styles.inputLine}>
          <DSFormGroupTextInput
            className={styles.label}
            label={t(
              "trainings.view.regulatory_collaborator_tracking.renewal_modal.drop_file.title",
              {
                defaultValue: "Glissez-déposez ici l’attestation de formation du collaborateur.",
              }
            )}
          >
            <FileDrop
              className={styles.fileDrop}
              isDragActive={isDragActive}
              rootProps={getRootProps()}
              inputProps={getInputProps()}
              btnLabel={t(
                "trainings.view.regulatory_collaborator_tracking.renewal_modal.drop_file.button",
                {
                  defaultValue: "Choisir un fichier",
                }
              )}
              dropLabel={t(
                "trainings.view.regulatory_collaborator_tracking.renewal_modal.drop_file.assistive_text",
                {
                  defaultValue: "ou glisser déposer un fichier ici",
                }
              )}
              value={form.document?.name}
            />
          </DSFormGroupTextInput>
        </div>
        <div className={styles.inputLine}>
          <Label
            label={t(
              "trainings.view.regulatory_collaborator_tracking.renewal_modal.share_document.title",
              {
                defaultValue: "Partager le document avec le collaborateur et son manager",
              }
            )}
          />
          <div className={styles.radioBoxes}>
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.isShared === true}
              label={t("common.view.radio_box.yes", {
                defaultValue: "Oui",
              })}
              value={true}
              onClick={(v) =>
                isNil(v)
                  ? handleInputChange("isShared")(undefined)
                  : handleInputChange("isShared")(v)
              }
            />
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.isShared === false}
              label={t("common.view.radio_box.no", {
                defaultValue: "Non",
              })}
              value={false}
              onClick={(v) =>
                isNil(v)
                  ? handleInputChange("isShared")(undefined)
                  : handleInputChange("isShared")(v)
              }
            />
          </div>
          {form?.isShared && (
            <p className={styles.warningText}>
              {t("trainings.view.regulatory_collaborator_tracking.renewal_modal.document_find", {
                defaultValue:
                  "Le collaborateur et son manager retrouveront ce document dans leur espace personnel",
              })}
            </p>
          )}
        </div>
        <div className={styles.disclaimer}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {t("trainings.view.regulatory_collaborator_tracking.renewal_modal.warning", {
              defaultValue:
                "Vous êtes sur le point de valider le renouvellement d’une formation obligatoire. La ligne actuelle sera archivée et une nouvelle ligne sera créée pour assurer son suivi.",
            })}
          </DSAlert>
        </div>
        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label={t("common.view.modal.cancel", {
              defaultValue: "Annuler",
            })}
            onClick={onClose}
          />
          <DSButton
            className={styles.button}
            disabled={!isFormStateValid}
            loading={loading}
            onClick={submitRequest}
            emphasis="High"
            label={t("common.view.modal.confirm", {
              defaultValue: "Confirmer",
            })}
          />
        </div>
      </div>
    </Modal>
  );
};
