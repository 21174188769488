import React, { useEffect, useState } from "react";

import faker from "faker";
import useTranslation from "hooks/useTranslation";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { useToasts } from "react-toast-notifications";
import { generatePath, useHistory } from "react-router-dom";
import { trpc } from "utils/trpc";

import { Flex, Text } from "@skillup/design-system";
import { Label as DSLabel, DSButton, Loader, useModal2 } from "@skillup/ui";

import Sortable from "../../../Skills/components/Sortable/Sortable";
import ConfirmationModal from "../../../Skills/components/Modals/ConfirmationModal";
import { JobTemplateFieldsWrapper } from "./JobTemplateFields.styled";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { TranslationType } from "hooks/useTranslation";
import DSLayout from "components/DSLayout";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { TemplateFieldsSchema } from "./TemplateFieldsFormSchema";

import { JobLayout } from "./JobTemplateFields.styled";

const TITLE_TEXT = "Paramétrage de la structure des fiches de poste";
const DESCRIPTION_TEXT =
  "Vous pouvez ajouter autant de sections que nécessaire. L'ordre que vous définirez ici sera repris dans les formulaires de création et de visualisation des fiches de poste.";
const NAME_LABEL = "Intitulé de la section";
const ADD_FIELD_LABEL = "Ajouter une section";
const SAVE_LABEL = "Enregistrer les modifications";
const SAVE_TOAST_TEXT_SUCCESS = "Sections enregistrées avec succès";
const SAVE_TOAST_TEXT_ERROR = "Une erreur s'est produite lors de l'enregistrement des sections";

interface JobTemplateField {
  ref: string;
  name?: string;
  version?: number;
  index?: number;
  uuid?: string;
}

interface JobTemplateToRemove {
  uuid: string;
  version: number;
}
interface ButtonProps {
  t: TranslationType;
  loading: boolean;
}

const setNewIndexes = (field: JobTemplateField, index: number) => ({
  uuid: field.uuid,
  name: field.name,
  version: field.uuid ? field.version + 1 : field.version,
  index: index + 1,
});

const HandleSubmitButton = ({ t, loading }: ButtonProps) => {
  const { submitForm, isValid } = useFormikContext();
  const history = useHistory();

  const handleSubmit = () => {
    submitForm();
    history.push("/responsable/configuration-du-module-competences/structure-de-fiches-de-poste");
  };

  const isDisabled = !isValid;

  const tooltipStr = t("portal.config.skills.tooltip", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  return (
    <DSNewHeaderButton
      label={t("portal.config.skills.jobTemplateFields.save", { defaultValue: SAVE_LABEL })}
      onClick={handleSubmit}
      loading={loading}
      type="submit"
      darkMode={true}
      disabled={isDisabled}
      tooltip={isDisabled ? tooltipStr : undefined}
      tooltipDarkMode={false}
    />
  );
};

const JobTemplateFieldsForm = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const confirmationModal = useModal2();

  const [fields, setFields] = useState<JobTemplateField[]>([]);
  const [fieldsToRemove, setFieldsToRemove] = useState<JobTemplateToRemove[]>([]);
  const [currentField, setCurrentField] = useState<JobTemplateField>(null);

  const { status, data, error, refetch } = trpc.jobTemplates.getAll.useQuery();

  const setTemplateFieldsMutation = trpc.jobTemplates.setTemplateFields.useMutation({
    onSuccess: () => {
      addToast(
        t("portal.config.skills.jobTemplateFields.add.success", {
          defaultValue: SAVE_TOAST_TEXT_SUCCESS,
        }),
        {
          appearance: "success",
        }
      );
      refetch();
    },
    onError: (e) =>
      addToast(
        t("portal.config.skills.jobTemplateFields.add.error", {
          defaultValue: SAVE_TOAST_TEXT_ERROR,
        }),
        {
          appearance: "error",
        }
      ),
  });

  const removeTemplateFieldMutation = trpc.jobTemplates.remove.useMutation();

  useEffect(() => {
    if (data) {
      setFields(
        data.map(
          (field) =>
            ({
              ...field,
              ref: faker.datatype.uuid(),
            }) as JobTemplateField
        )
      );
    }
  }, [data]);

  const handleSubmit = ({ templateFields }) => {
    if (fieldsToRemove.length > 0) {
      removeTemplateFieldMutation.mutate(fieldsToRemove);
    }

    setTemplateFieldsMutation.mutate({
      fields: templateFields.map(setNewIndexes).filter((f: JobTemplateField) => f.name !== ""),
    });
  };

  const confirmRemove = () => {
    if (currentField.uuid) {
      setFieldsToRemove([
        ...fieldsToRemove,
        { uuid: currentField.uuid, version: currentField.version + 1 },
      ]);
      setFields(fields.filter((field) => field.uuid !== currentField.uuid));
    }
    confirmationModal.hide();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ templateFields: fields }}
      onSubmit={handleSubmit}
      validationSchema={toFormikValidationSchema(TemplateFieldsSchema)}
    >
      {({ values }) => (
        <DSLayout
          title={t("portal.config.skills.jobTemplateFields.back.btn.title", {
            defaultValue: "Structure des fiches de poste",
          })}
          layouts={
            [
              {
                primaryButton: <HandleSubmitButton t={t} loading={status === "loading"} />,
              },
            ] as const
          }
          parent={{
            title: t("skills.configuration.skills.title", {
              defaultValue: "Configuration du module Compétences",
            }),
            titleUrl: generatePath(
              "/responsable/configuration-du-module-competences/structure-de-fiches-de-poste"
            ),
          }}
        >
          <JobLayout>
            <JobTemplateFieldsWrapper>
              <Flex flexDirection="column" marginTop="m">
                <Text espaceFont="body2Bold" color="plainText-onLight-default">
                  {t("portal.config.skills.jobTemplateFields.list.title", {
                    defaultValue: TITLE_TEXT,
                  })}
                </Text>
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter" marginTop="xs">
                  {t("portal.config.skills.jobTemplateFields.list.description", {
                    defaultValue: DESCRIPTION_TEXT,
                  })}
                </Text>
                <Form>
                  <ConfirmationModal
                    isOpen={confirmationModal.isOpen}
                    closeModal={confirmationModal.hide}
                    confirm={() => confirmRemove()}
                    content={
                      <>
                        <Text
                          fontSize="fontSizeS"
                          color="plainText-onLight-default"
                          marginBottom="xs"
                          espaceFont="body1Regular"
                        >
                          {t(
                            "portal.config.skills.jobTemplateFields.delete.confirmModal.firstText",
                            {
                              defaultValue:
                                "En supprimant cette section, vous supprimerez les informations contenues dans celle-ci dans chacune des fiches de postes.",
                            }
                          )}
                        </Text>
                        <Text
                          fontSize="fontSizeS"
                          marginBottom="xs"
                          color="status-error-darker"
                          espaceFont="body1Bold"
                        >
                          {t(
                            "portal.config.skills.jobTemplateFields.delete.confirmModal.secondText",
                            {
                              defaultValue: "Cette action est irréversible.",
                            }
                          )}
                        </Text>
                        <Text
                          fontSize="fontSizeS"
                          color="plainText-onLight-default"
                          marginBottom="xs"
                          espaceFont="body1Regular"
                        >
                          {t(
                            "portal.config.skills.jobTemplateFields.delete.confirmModal.thirdText",
                            {
                              defaultValue:
                                "La suppression ne sera réalisée qu’après avoir enregistré les modifications.",
                            }
                          )}
                        </Text>
                      </>
                    }
                    title={t("portal.config.skills.jobTemplateFields.delete", {
                      defaultValue: "Suppression d’une section",
                    })}
                    saveLabel={t("portal.config.skills.jobTemplateFields.confirModal.confirm", {
                      defaultValue: "Continuer",
                    })}
                  />
                  <Flex flexDirection="column">
                    {status === "loading" && <Loader />}
                    {status === "error" && <div>{error.message}</div>}
                    {status === "success" && (
                      <Flex flexDirection="column" marginTop="m">
                        <FieldArray
                          name="templateFields"
                          render={({ move, remove, push }) => (
                            <React.Fragment>
                              <Flex marginBottom="xs" marginLeft="xxl">
                                <DSLabel
                                  required
                                  label={t("portal.config.skills.jobTemplateFields.nameLabel", {
                                    defaultValue: NAME_LABEL,
                                  })}
                                />
                              </Flex>

                              <Sortable
                                fields={values.templateFields}
                                setCurrentField={setCurrentField}
                                confirmationModal={confirmationModal}
                                arrayHelpers={{ move, remove }}
                                inputName="templateFields"
                              />
                              <Flex marginTop="xs" width="fit-content">
                                <DSButton
                                  type="button"
                                  emphasis="Mid"
                                  onClick={() => {
                                    push({
                                      name: "",
                                      ref: faker.datatype.uuid(),
                                      version: 1,
                                      index: values.templateFields.length + 1,
                                    });
                                  }}
                                  buttonSize="S"
                                  label={t("portal.config.skills.jobTemplateFields.addFieldLabel", {
                                    defaultValue: ADD_FIELD_LABEL,
                                  })}
                                />
                              </Flex>
                            </React.Fragment>
                          )}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Form>
              </Flex>
            </JobTemplateFieldsWrapper>
          </JobLayout>
        </DSLayout>
      )}
    </Formik>
  );
};

export default JobTemplateFieldsForm;
