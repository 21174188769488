import { colors, Icon } from "@skillup/ui";
import { ISimpleSelectionUser } from "@skillup/types";

import useTranslation from "hooks/useTranslation";

import styles from "../AddTraineesContent.module.scss";

interface IProps {
  onSelect: (users: ISimpleSelectionUser | ISimpleSelectionUser[]) => void;
  usersToPickFrom?: Array<ISimpleSelectionUser>;
  label: string;
}

const PendingUsersList = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.filterSection}>
        <h2>{props.label}</h2>
        <div className={styles.waitingList}>
          {props.usersToPickFrom?.length ? (
            <div>
              {props.usersToPickFrom?.length > 1 && (
                <li key="all" aria-label="all" tag-test="waiting-user">
                  <p>
                    {t("trainings.view.add_trainees_modal.pending_users_list.all.label", {
                      defaultValue: "Tous ({{count}})",
                      count: props.usersToPickFrom?.length,
                    })}
                  </p>
                  <div
                    aria-label="select-all-users"
                    className={styles.chevronList}
                    onClick={() => {
                      props.onSelect(props.usersToPickFrom);
                    }}
                  >
                    <Icon.ChevronRight color={colors.primaryBlue} size="1.125rem" />
                  </div>
                </li>
              )}
              {props.usersToPickFrom?.map((user) => (
                <li key={user.email} aria-label={user.email} tag-test="waiting-user">
                  <p>{user.fullName || user.email}</p>
                  <div
                    aria-label={`select-user-${user.fullName}`}
                    className={styles.chevronList}
                    onClick={() => {
                      props.onSelect(user);
                    }}
                  >
                    <Icon.ChevronRight color={colors.primaryBlue} size="1.125rem" />
                  </div>
                </li>
              ))}
            </div>
          ) : (
            <p className={styles.emptyWaitingList}>
              {t("trainings.view.add_trainees_modal.empty_list.label", {
                defaultValue: "La liste est vide",
              })}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PendingUsersList;
