import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import type { TargetCategoryForConfiguration } from "@skillup/espace-rh-bridge";
import { ChipNumber, DSDropdown, DSDropdownItem, DSTooltip } from "@skillup/ui";
import { useMemo } from "react";
import { MdTrackChanges as TrackChanges } from "react-icons/md";
import useTranslation, { TranslationType } from "hooks/useTranslation";

export type CategoryRow = ReturnType<typeof parseTableRows>;
type RenameHandler = (category: Pick<TargetCategoryForConfiguration, "uuid" | "label">) => void;

const useTableData = (
  categories: Array<TargetCategoryForConfiguration>,
  renameHandler: RenameHandler
): {
  data: CategoryRow[];
  columns: Array<GridColDef>;
} => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(generateTableColumns(renameHandler), [renameHandler]);
  const data = useMemo(() => generateTableRows(categories ?? []), [categories]);

  return { columns, data };
};

export default useTableData;

function generateTableRows(categories: Array<TargetCategoryForConfiguration>) {
  return categories.map(parseTableRows);
}

const parseTableRows = (category: TargetCategoryForConfiguration) => ({
  id: category.uuid,
  title: category.label,
  targets: category.targetCount,
  structures: category.structures.length,
});

const generateTableColumns = (renameHandler: RenameHandler) => (): Array<GridColDef> => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "title",
      headerName: t("supervisor.interviews.configuration.column.title", {
        defaultValue: "Intitulé de la catégorie",
      }),
      filterable: true,
      minWidth: 256,
      flex: 1,
      sortComparator: (a: string, b: string) => a.localeCompare(b),
    },
    {
      field: "targets",
      renderHeader: () => <TrackChanges size="1rem" />,
      description: t("supervisor.interviews.configuration.column.targets", {
        defaultValue: "Nombre d'objectifs créés dans cette catégorie",
      }),
      filterable: false,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
    {
      field: "structures",
      headerName: t("supervisor.interviews.configuration.column.structures", {
        defaultValue: "Nombre de campagnes utilisant cette catégorie",
      }),
      filterable: false,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
    {
      field: "actions",
      width: 50,
      filterable: false,
      sortable: false,
      type: "actions",
      getActions: generateRowActions(renameHandler, t),
    },
  ];
  return columns;
};

const generateRowActions =
  (renameHandler: RenameHandler, t: TranslationType) =>
  ({ row }: GridRowParams<CategoryRow>) => [
    <DSDropdown buttonSize="S" overflow="initial">
      <DSTooltip
        disabled={row.structures === 0}
        label={t("interviews.targets.configuration.rename", {
          defaultValue:
            "{{ count }} campagne utilise cette catégorie, elle ne peut plus être renommée.",
          defaultValue_other:
            "{{ count }} campagnes utilisent cette catégorie, elle ne peut plus être renommée.",
          count: row.structures,
        })}
      >
        <DSDropdownItem
          label={t("supervisor.interviews.configuration.rename.action", {
            defaultValue: "Renommer la catégorie",
          })}
          disabled={row.structures > 0}
          onClick={() => renameHandler({ uuid: row.id, label: row.title })}
        />
      </DSTooltip>
    </DSDropdown>,
  ];
