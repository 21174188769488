import { useState } from "react";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";

import { Either, Future } from "@skillup/monads";
import { DSButton, Modal, Select, SelectProps } from "@skillup/ui";
import { TrackingRoutes } from "@skillup/espace-rh-api/src/routes/tracking";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import useTranslation, { TranslationType } from "hooks/useTranslation";

import { Tracking } from "../../../state/tracking";

import styles from "./UpdateStateModal.module.scss";

export enum TrackingState {
  PENDING = "pending",
  WON = "won",
  REALIZED = "realized",
}

export interface Props {
  tracking: Tracking;
  onClose: () => void;
}

type Payload = TrackingRoutes.UpdateState["payload"];

const selectOptions = (t: TranslationType): SelectProps<Payload["state"]>["options"] => [
  {
    value: "pending",
    label: t("trainings.view.regulatory_collaborator_tracking.modal.update.pending", {
      defaultValue: "À inscrire",
    }),
  },
  {
    value: "won",
    label: t("trainings.view.regulatory_collaborator_tracking.modal.update.won", {
      defaultValue: "Inscrit(e)",
    }),
  },
  {
    value: "realized",
    label: t("trainings.view.regulatory_collaborator_tracking.modal.update.realized", {
      defaultValue: "Réalisé",
    }),
  },
];

const updateTrackingState = async (
  trackingUuid: string,
  state: `${TrackingState}`
): Promise<
  Either<TrackingRoutes.UpdateState["errors"], TrackingRoutes.UpdateState["response"]>
> => {
  const request = await buildSafeRequest<TrackingRoutes.UpdateState>({
    method: "POST",
    path: "/tracking/{trackingUuid}/update-state",
    params: {
      trackingUuid,
    },
    payload: {
      state: state ?? null,
    },
  });

  return request.run();
};

const UpdateStateModal = ({ tracking, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useToggle(false);

  const [state, setState] = useState<`${TrackingState}`>(tracking.associatedRowState);

  const submitRequest = async () => {
    setIsLoading(true);
    const request = updateTrackingState(tracking.uuid, state);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la modification de l'état",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.associatedRowState.update.success", {
            defaultValue: "État modifié avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setIsLoading(false);
  };

  return (
    <Modal
      size="big"
      title={t("trainings.view.regulatory_collaborator_tracking.modal.update_state", {
        defaultValue: "Modifier l'état",
      })}
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.updateStateModal}>
        <Select
          className={styles.input}
          placeholder={t("trainings.view.regulatory_collaborator_tracking.modal.select_state", {
            defaultValue: "Sélectionnez un état",
          })}
          value={state}
          options={selectOptions(t)}
          onChange={(value) => setState(value)}
          clearable
          autoFocus
          isSearchable={false}
        />
        <div className={styles.actions}>
          <DSButton
            label={t("common.action.cancel", {
              defaultValue: "Annuler",
            })}
            emphasis="Low"
            onClick={onClose}
          />
          <DSButton
            loading={isLoading}
            label={t("common.action.confirm", {
              defaultValue: "Confirmer",
            })}
            emphasis="High"
            onClick={submitRequest}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateStateModal;
