import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal, Label, DSTextInput, Flex, Select, AssistiveArea } from "@skillup/ui";

import { InterviewType, interviewTypes } from "types/Interview";
import { type Template, useTemplate } from "services/interviews";
import styles from "./EditParamsTemplateModal.module.scss";
import useTranslation from "hooks/useTranslation";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

const EditParamsTemplateModal = ({ template, isOpen, closeModal }: IProps) => {
  const { t } = useTranslation();
  const { editParams } = useTemplate(template.uuid);
  const { addToast } = useToasts();

  const [newTitle, setNewTitle] = useState<string>(template.title);
  const [newType, setNewType] = useState<InterviewType>(template.type);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await editParams({ title: newTitle, type: newType });
      if (response.success) {
        addToast("Paramètres de la trame mis à jour", { appearance: "success" });
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Echec lors de la mise à jour de la trame");
      }
    } catch (err) {
      addToast("Echec lors de la mise à jour de la trame", { appearance: "error" });
      closeModal();
      setLoading(false);
    }
  };

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.editParamsTemplateModal.title", {
            defaultValue: "Paramètres généraux de la trame",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.contentContainer}>
          <Label
            label={t("interviews.editParamsTemplateModal.newTitle", {
              defaultValue: "Titre de la trame",
            })}
            required
          />
          <div className={styles.marginBottom}>
            <DSTextInput
              value={newTitle}
              onChange={(value) => setNewTitle(value)}
              name={"new title"}
              autoFocus
              required
            />
            <AssistiveArea
              text={t("interviews.editParamsTemplateModal.newTitle.help", {
                defaultValue:
                  "Le titre de la trame n’est visible que pour les administrateurs habilités à lancer des campagnes d’entretien.",
              })}
            />
          </div>
          <Flex column>
            <Label
              label={t("interviews.editParamsTemplateModal.type", {
                defaultValue: "Type d'entretien",
              })}
              required
            />
            <Select
              className={styles.DSComponent}
              placeholder={t("interviews.editParamsTemplateModal.type.placeholder", {
                defaultValue: "Sélectionner un type d'entretien",
              })}
              options={interviewTypes.map((type) => ({ label: type, value: type }))}
              value={newType}
              clearable={false}
              isSearchable={false}
              onChange={(value: InterviewType) => {
                setNewType(value);
              }}
            />
          </Flex>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.editParamsTemplateModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          label={t("interviews.editParamsTemplateModal.save", {
            defaultValue: "Enregistrer",
          })}
          tooltip={
            newTitle === template.title && newType === template.type
              ? t("interviews.editParamsTemplateModal.save.tooltip", {
                  defaultValue: "Tous les champs obligatoires doivent être remplis",
                })
              : ""
          }
          onClick={handleOnClick}
          disabled={newTitle === template.title && newType === template.type}
          loading={loading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export { EditParamsTemplateModal };
