import { useMemo, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import { DSButton, Modal, DSFormGroupTextInput, TextArea } from "@skillup/ui";
import { Future } from "@skillup/monads";

import { formatError } from "services/errors";
import useTranslation from "hooks/useTranslation";

import { useTrackingByUuid } from "../../state/tracking";
import createTrackingNote from "./createTrackingNote";
import updateTrackingNote from "./updateTrackingNote";

import styles from "./CreateOrManageTrackingNote.module.scss";

interface Props {
  readonly trackingUuid: string;
  readonly note: string;
  readonly userUuid: string;
  readonly onClose: () => void;
}

export default ({ trackingUuid, note, userUuid, onClose }: Props) => {
  const tracking = useTrackingByUuid(trackingUuid);
  const trackingNotFound = useMemo(() => tracking.isLeft(), [tracking]);
  const [newNote, setNewNote] = useState<string>(note);

  const { addToast } = useToasts();
  const { t } = useTranslation();

  useEffect(() => {
    if (trackingNotFound) {
      onClose();
    }
  }, [trackingNotFound, onClose]);

  const [loading, setLoading] = useState(false);

  const createRequest = async () => {
    const request = createTrackingNote(trackingUuid, newNote, userUuid);
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de l'ajout de la note",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.note.creation.success", {
            defaultValue: "note ajoutée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  const updateRequest = async () => {
    const request = updateTrackingNote(trackingUuid, newNote, userUuid);
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de la modification de la note",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.note.updated.success", {
            defaultValue: "note modifiée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  return (
    <Modal
      title={
        note
          ? t("trainings.view.regulatory_collaborator_tracking.modal.tracking_note.modify", {
              defaultValue: "Modifier la note",
            })
          : t("trainings.view.regulatory_collaborator_tracking.modal.tracking_note.add", {
              defaultValue: "Ajouter une note",
            })
      }
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.createOrManageNoteWrapper}>
        <div className={styles.createOrManageNote}>
          <div className={styles.inputLine}>
            <DSFormGroupTextInput className={styles.label}>
              <TextArea
                value={newNote}
                placeholder={t(
                  "trainings.view.regulatory_collaborator_tracking.modal.tracking_note.placeholder",
                  {
                    defaultValue: "Votre note ici...",
                  }
                )}
                onChange={(e) => setNewNote(e)}
                className={styles.input}
                autoFocus
              />
            </DSFormGroupTextInput>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label={t("common.action.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSButton
            className={styles.button}
            disabled={!newNote}
            loading={loading}
            onClick={!note ? createRequest : updateRequest}
            emphasis="High"
            label={
              note
                ? t(
                    "trainings.view.regulatory_collaborator_tracking.modal.tracking_note.action.modify",
                    {
                      defaultValue: "Modifier la note",
                    }
                  )
                : t(
                    "trainings.view.regulatory_collaborator_tracking.modal.tracking_note.action.add",
                    {
                      defaultValue: "Ajouter la note",
                    }
                  )
            }
          />
        </div>
      </div>
    </Modal>
  );
};
