import React from "react";

import Icon from "components/Icon";
import useTranslation from "hooks/useTranslation";

import Colors from "uiAssets/Colors";
import { check } from "uiAssets/Icons";

import { IFilters, ITrainingAggregations } from "../../../Actions/getTrainings";
import styles from "./SessionTypeFilter.module.scss";

interface Props {
  readonly aggregations: ITrainingAggregations;
  readonly filters: IFilters;
  readonly onToggleSessionType: (sessionType?: "distancial") => void;
}

const SessionTypeFilter = ({ aggregations, filters, onToggleSessionType }: Props) => {
  const { t } = useTranslation();

  const distancialSessionsActive = filters?.sessionType ?? false;
  // @ts-ignore
  const distancialSessionsCount = aggregations?.sessionType?.distancial?.doc_count ?? 0;

  return (
    <div className={styles.SessionTypeFilter}>
      <div
        onClick={() => onToggleSessionType(distancialSessionsActive ? undefined : "distancial")}
        className={styles.checkbox}
      >
        <div>{distancialSessionsActive && <Icon icon={check} fill={Colors.blue} width={10} />}</div>
        {t("trainings.view.requalification_modal.sidebar.filters.distancial_sessions", {
          defaultValue: "Session à distance",
        })}
      </div>
      <span>{distancialSessionsCount}</span>
    </div>
  );
};

export default SessionTypeFilter;
