import TimeField from "react-simple-timefield";
import { DateTime } from "@skillup/shared-utils";

import styles from "./CalendarHourSettingInput.module.scss";
import useTranslation from "hooks/useTranslation";
interface Props {
  days: string[];
  hours: string[];
  updateHours: (hours: string[]) => void;
  key: number;
  showError: boolean;
}

const CalendarHourSettingInput = (props: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.interval} key={props.key}>
      <div>
        <p className={styles.dates}>
          {props.days.length === 1 ? (
            formatDate(props.days[0], i18n.language)
          ) : (
            <>
              {formatDate(props.days[0], i18n.language)}&nbsp;
              {" > "}
              &nbsp;{formatDate(props.days[1], i18n.language)}
            </>
          )}
        </p>
      </div>

      <div className={styles.interval}>
        <p>
          {" "}
          {t("trainings.view.summon.modal.calendar_event.time_prefix", {
            defaultValue: "de",
          })}{" "}
        </p>
        <TimeField
          value={props.hours[0]}
          onChange={(_e, value) => {
            props.updateHours([value, props.hours[1]]);
          }}
          input={<input required type="text" className={props.showError ? styles.error : ""} />}
        />
        <p>
          {" "}
          {t("trainings.view.summon.modal.calendar_event.time_bewteen", {
            defaultValue: "à",
          })}{" "}
        </p>
        <TimeField
          value={props.hours[1]}
          onChange={(_e, value) => {
            props.updateHours([props.hours[0], value]);
          }}
          input={<input required type="text" className={props.showError ? styles.error : ""} />}
        />
      </div>
    </div>
  );
};

function formatDate(date: string, locale: string) {
  return DateTime.fromISO(date, {
    locale: "fr",
    zone: "utc",
  }).toLocaleString(DateTime.DATE_FULL, { locale: locale });
}
export { CalendarHourSettingInput };
