import { InterviewWithStates } from "@skillup/types";
import { DSCard, DSProgressBar, Status, StatusType, TypeProgressBar } from "@skillup/ui";
import { TFunction } from "hooks/useTranslation";
import get from "lodash/get";
import map from "lodash/map";
import { InterviewRoles } from "types/Interview";
import styles from "./CompletionRatesStyles.module.scss";

interface IProps {
  readonly interviews?: InterviewWithStates[];
  readonly campaignIsClosed?: boolean;
  readonly t: TFunction;
}

const INITIAL_STATE_CLOTURED = { signed: 0, unsigned: 0 };
const INITIAL_STATE_NOT_CLOTURED = { signed: 0, shared: 0, pending: 0 };

const CompletionRates = ({ interviews = [], campaignIsClosed = false, t }: IProps): JSX.Element => {
  const total = interviews.length;
  const initialState = campaignIsClosed ? INITIAL_STATE_CLOTURED : INITIAL_STATE_NOT_CLOTURED;

  const getDataByRole = (role: InterviewRoles) => {
    return interviews.reduce((acc, interview) => {
      const key = get(get(interview, role, {}), "state");

      // If campaign is closed, we don't want to display shared and pending interviews
      if (campaignIsClosed && (key === "shared" || key === "pending")) {
        return acc;
      }

      const count = get(acc, key, 0);

      // @ts-ignore
      return { ...acc, [key]: count + 1 };
    }, initialState);
  };

  const totalSigned = interviews.filter(
    (interview) => interview.employee.state === "signed" && interview.manager.state === "signed"
  ).length;

  const employeeData = map(getDataByRole(InterviewRoles.EMPLOYEE), (count) => {
    return {
      value: `${count}/${total}`,
    };
  });

  const managerData = map(getDataByRole(InterviewRoles.MANAGER), (count) => {
    return { value: `${count}/${total}` };
  });
  const totalPercentage = Math.round((totalSigned / total) * 100);

  return (
    <div className={styles.CompletionRates}>
      <div className={styles.content}>
        <div className={styles.column}>
          <label className={styles.title}>
            {t("interviews.status", { defaultValue: "Statuts" })}
          </label>
          <StatusList campaignIsClosed={campaignIsClosed} t={t} />
        </div>
        <div className={styles.column}>
          <p>{t("interviews.employees", { defaultValue: "Collaborateurs" })}</p>
          {employeeData.map((d) => {
            return <label>{d.value}</label>;
          })}
        </div>
        <div className={styles.column}>
          <p>{t("interviews.managers", { defaultValue: "Responsables" })}</p>
          {managerData.map((d) => {
            return <label>{d.value}</label>;
          })}
        </div>
      </div>

      <DSCard className={styles.Card} showTitle={false}>
        <div className={styles.header}>
          <p className={styles.percentage}>{!isNaN(totalPercentage) ? totalPercentage : 0}%</p>
          <p>
            {t("interviews.interwiewSignedByBothParts", {
              defaultValue: "des entretiens ont été signés par les 2 parties",
            })}
            ({totalSigned}/{total})
          </p>
        </div>
        <DSProgressBar
          data={[{ label: "", value: (totalSigned / total) * 100 }]}
          type={TypeProgressBar.PERCENTAGE}
          layout="block"
          legendsLayout="column"
          showLegends={false}
        />
      </DSCard>
    </div>
  );
};

export default CompletionRates;

const StatusList = ({
  campaignIsClosed,
  t,
}: {
  campaignIsClosed: Boolean;
  readonly t: TFunction;
}) => {
  if (campaignIsClosed) {
    return (
      <>
        <div>
          <Status
            label={t("interviews.signed", { defaultValue: "Signé" })}
            type={StatusType.SUCCESS}
          />
        </div>
        <div>
          <Status
            label={t("interviews.notSigned", { defaultValue: "Non signé" })}
            type={StatusType.ERROR}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Status
          label={t("interviews.signed", { defaultValue: "Signé" })}
          type={StatusType.SUCCESS}
        />
      </div>
      <div>
        <Status
          label={t("manager.interviews.shared.title", { defaultValue: "Partagé" })}
          type={StatusType.PROGRESS}
        />
      </div>
      <div>
        <Status
          label={t("manager.interviews.pending.title", { defaultValue: "À préparer" })}
          type={StatusType.DONE}
        />
      </div>
    </>
  );
};
