// To Migrate -
import React, { type ButtonHTMLAttributes } from "react";
import cx from "classnames";

import { type Themes, decorationColors } from "../commonProps";
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip";

import styles from "./Avatar.module.scss";
import { Person, Camera } from "components/MaterialIcons";
import { Button } from "../Button/Button";

const iconSizes = {
  XXL: 42,
  XL: 32,
  L: 21,
  M: 16,
  S: 10,
  XS: 10,
};

export type AvatarColors = (typeof decorationColors)[number];

export interface AvatarProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  readonly tooltip?: TooltipProps["label"];
  readonly tooltipDirection?: TooltipProps["direction"];
  readonly initials?: string;
  readonly imageUrl?: string;
  readonly onChangePicture?: () => void;
  readonly type?: "Icon" | "Picture" | "Initials";
  readonly buttonType?: "button" | "submit" | "reset";
  readonly size?: "XXL" | "XL" | "L" | "M" | "S" | "XS";
  readonly color?: AvatarColors;
  readonly generateRandomColor?: boolean;
  readonly randomColorSeedString?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly greyMode?: boolean;
  readonly onClick?: () => void;
  readonly hovering?: boolean;
}

/**
 * This function return a decoration color based on a seed string
 */
export function getDecorationColor(randomColorSeedString: string): (typeof decorationColors)[number] {
  let hash = 0;
  for (let i = 0; i < randomColorSeedString.length; i++) {
    const code = randomColorSeedString.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash;
  }
  const userHash = Math.abs(hash);
  const color = decorationColors[userHash % decorationColors.length];
  return color;
}

const Avatar = ({
  className,
  initials,
  size = "M",
  color,
  generateRandomColor = true,
  randomColorSeedString = "defaultRandomColorSeedString",
  type = "Initials",
  buttonType,
  imageUrl,
  tooltip,
  tooltipDirection,
  darkMode = false,
  theme,
  greyMode = false,
  onClick,
  hovering = false,
  onChangePicture,
  ...props
}: AvatarProps) => {
  if (generateRandomColor && randomColorSeedString) {
    color = getDecorationColor(randomColorSeedString);
  }

  if (greyMode) {
    color = "Grey";
  }
  return (
    <Tooltip
      label={tooltip ?? undefined}
      direction={tooltipDirection ?? "top"}
      className={cx(className, styles.AvatarWrapper)}
      style={props.style}
      theme={theme}
      darkMode={darkMode}
      onClick={onClick}
    >
      <button
        className={cx(
          styles.Avatar,
          styles[theme],
          styles[color ?? "SkyBlue"],
          styles[size],
          styles[type],
          {
            [styles.darkMode]: darkMode,
            [styles.clickable]: !!onClick && hovering,
            [styles.pointer]: !!onClick,
          }
        )}
        aria-label={`avatar-${initials}`}
        disabled={!onClick}
        type={buttonType || "button"}
        {...props}
      >
        {type == "Picture" && <img src={imageUrl} alt="" className={cx(styles.avatar)} />}
        {type == "Icon" && <Person size={iconSizes[size]} />}
        {type == "Initials" && <label>{initials}</label>}
        {onChangePicture && size === "XXL" && (
          <Button
            className={styles.editButton}
            onClick={onChangePicture}
            iconOnly
            icon={<Camera />}
            darkMode
            buttonSize="M"
            theme={theme}
          />
        )}
      </button>
    </Tooltip>
  );
};

export { Avatar };
