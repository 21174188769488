import { MdOutlineTableRows as OutlineTableRows } from "react-icons/md";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { countBy, isEmpty, upperFirst } from "lodash";

import { Collaborator } from "../../api";

import {
  DropDownCheckbox,
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  ToggleButtonGroup,
} from "@skillup/ui";
import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";
import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import LoaderShimmer from "components/LoaderShimmer";

import placeholderImg from "assets/collaborator/empty_training.png";
import styles from "./UserTraining.module.scss";
import TimelineView from "./partials/TimelineView";
import TableView from "./partials/TableView";
import { IContext, SupervisorUserViewContext } from "../../Context";

export const STATES_ORDER = {
  realized: 1,
  subscribed: 2,
  waiting_for_subscription: 3,
  approved: 4,
  pending_for_manager: 5,
  pending_for_HR: 5,
  pending_for_additionnal_validation: 6,
  cancelled: 7,
  denied: 9,
  to_arbitrate: 9,
  denied_or_cancelled: 9,
  isSpecificTrainingRequest: 9,
};

const StatesOrderKeys = Object.keys(STATES_ORDER);

interface Props {
  readonly user: Collaborator;
}

export default ({ user }: Props) => {
  const { t } = useTranslation();

  const rootRef = useRef(null);

  const [view, setView] = useState<"table" | "timeline">("table");
  const [filters, setFilters] = useState<{ [key: string]: number }>();

  const {
    dispatch,
    state: {
      training: { filter },
    },
  } = useContext<IContext>(SupervisorUserViewContext);

  const {
    data: trainings,
    loading,
    error,
  } = useTypedFetch<CollaboratorsRoutes.GetTrainings>({
    method: "GET",
    path: `/collaborators/{uuid}/trainings`,
    params: { uuid: user.uuid },
  });

  const trainingsFiltered = useMemo(
    () => trainings?.filter((training) => !filter || filter.includes(training.state)) || [],
    [trainings, filter]
  );

  const views: { label: string; slug: string; icon: any }[] = useMemo(
    () => [
      {
        label: t("supervisor.view.collaborators.training_tab.action.toogle.select_view.property.tooltip.table", {
          defaultValue: "Vue tableau",
        }),
        slug: "table",
        icon: <OutlineTableRows />,
      },
      {
        label: t("supervisor.view.collaborators.training_tab.action.toogle.select_view.property.tooltip.timeline", {
          defaultValue: "Vue chronologique",
        }),
        slug: "timeline",
        icon: <ViewTimelineOutlinedIcon />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    // show only filters with data
    const trainingStates = countBy(trainings, "state");
    const trainingStatesOrdered = {};
    StatesOrderKeys.forEach((state) => {
      if (trainingStates[state]) trainingStatesOrdered[state] = trainingStates[state];
    });
    setFilters(trainingStatesOrdered);
    dispatch({ type: "setTrainings", trainings });
  }, [trainings, dispatch]);

  if (loading) {
    return (
      <div className={styles.UserTraining}>
        <div className={styles.loading}>
          <LoaderShimmer className={styles.loadingLogo} />
          <p>Chargement en cours…</p>
        </div>
      </div>
    );
  }

  if (isEmpty(trainings) && isEmpty(trainingsFiltered) && !error) {
    return (
      <div className={styles.UserTraining}>
        <img className={styles.placeholderImg} src={placeholderImg} alt="bientôt disponible" />
        <p>{user.fullName} n’a pas encore fait de demande de formation.</p>
      </div>
    );
  }

  return (
    <div className={styles.UserTraining} ref={rootRef}>
      {!error && (
        <div className={styles.filter}>
          <DropDownCheckbox
            className={styles.dropdownCheckbox}
            labels={{
              itemSingular: t("supervisor.view.collaborators.training_tab.action.filter.property.item_singular", {
                defaultValue: "statut",
              }),
              itemPlural: t("supervisor.view.collaborators.training_tab.action.filter.property.item_plural", {
                defaultValue: "statuts",
              }),
              allItemsSelected: t("supervisor.view.collaborators.training_tab.action.filter.property.all_items", {
                defaultValue: "Tous les statuts",
              }),
              noItemSelected: t("supervisor.view.collaborators.training_tab.action.filter.property.no_item", {
                defaultValue: "Aucun statut",
              }),
            }}
            items={(filters ? Object.keys(filters) : []).map((state) => ({
              label: `${upperFirst(t(`training.request.state.${state}`))} (${filters[state]})`,
              value: state,
              isSelected: !filter || filter.includes(state),
            }))}
            onChange={(selection) => {
              dispatch({
                type: "setTrainingFilter",
                filter: selection.filter((state) => state.isSelected).map((state) => state.value),
              });
            }}
          />
          <div>
            <ToggleButtonGroup
              className={styles.toggle}
              value={view}
              options={views.map((view) => ({
                label: view.label,
                value: view.slug,
                icon: view.icon,
              }))}
              onChange={() => setView(view === "table" ? "timeline" : "table")}
            />
          </div>
        </div>
      )}
      {error && (
        <div className={styles.UserTraining}>
          <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.FULL_BLEED}>
            Impossible de récupérer les formations du collaborateur
          </DSAlert>
        </div>
      )}
      {!isEmpty(trainingsFiltered) && !error && view === "timeline" && (
        <TimelineView trainings={trainingsFiltered} />
      )}
      {!isEmpty(trainingsFiltered) && !error && view === "table" && (
        <TableView trainings={trainingsFiltered} />
      )}
    </div>
  );
};
