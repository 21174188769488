import React from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";

import styles from "./SkillsListDetail.module.scss";
import { SkillItem } from "./components/SkillItem";

interface SkillsProps {
  readonly name: string;
  readonly level?: number;
  readonly levelName?: string;
  readonly levelDescription?: string;
  readonly totalLevel: Array<{ level: number; name: string }>;
  readonly expectedLevel?: number;
  readonly expectedLevelName?: string;
  readonly expectedLevelDescription?: string;
  readonly comment?: string;
  readonly evaluatedBy: string;
  readonly evaluatedAt: string;
}

interface CategoryProps {
  readonly name: string;
  readonly skills: SkillsProps[];
}

export interface SkillsListDetailsProps {
  readonly categories: CategoryProps[];
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly toReach?: boolean;
  readonly onlyExpected?: boolean;
}

const SkillsListDetail = ({
  categories,
  theme,
  darkMode,
  className,
  toReach,
  onlyExpected,
}: SkillsListDetailsProps) => {
  return (
    <div
      className={cx(
        styles.SkillsList,
        styles[theme],
        {
          [styles.darkMode]: darkMode,
        },
        className
      )}
    >
      {categories.map((category, i) => {
        return (
          <div className={styles.categoriesContainer} key={i}>
            <div className={styles.categoryName}>{category.name}</div>
            {category.skills.map((skill, index) => (
              <SkillItem
                skill={skill}
                key={index}
                categoryName={category.name}
                darkMode={darkMode}
                toReach={toReach}
                onlyExpected={onlyExpected}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export { SkillsListDetail };
