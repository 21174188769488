import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal, DSAlert, DSAlertDisplay, DSAlertType } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { useTemplate, type Template } from "services/interviews";
import useTranslation, { type TranslationType } from "hooks/useTranslation";
import { ChoicesSettings, useChoices } from "./ChoicesSettings";
import styles from "./TargetSettingsModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

export const TargetSettingsTemplateModal = ({ template, isOpen, closeModal }: IProps) => {
  const { t } = useTranslation();
  const templateActions = useTemplate(template.uuid);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);

  const { choices, addChoice, removeChoice, handleChangeChoices, handleReorderChoices } =
    useChoices(template);

  const send = (t: TranslationType) => async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await templateActions.editParams({
        choicesForOrdinalTargets: choices.reduce(
          (acc, choice) => (choice.title !== "" ? [...acc, choice.title] : acc),
          []
        ),
      });
      if (response.success) {
        addToast(
          t("interviews.templates.targetSettingsModal.success", {
            defaultValue: "Trame mise à jour",
          }),
          { appearance: "success" }
        );
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Echec lors de la modification de la trame");
      }
    } catch (err) {
      addToast(
        t("interviews.templates.targetSettingsModal.error", {
          defaultValue: "Echec lors de la modification de la trame",
        }),
        { appearance: "error" }
      );
    }
  };

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.templates.targetSettingsModal.title", {
            defaultValue: "Paramètres liés aux objectifs",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.TargetSettingsModal}>
        <Flex flexDirection="column" gap="l">
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
            <>
              {t("interviews.templates.targetSettingsModal.warning", {
                defaultValue:
                  "Tous les blocs de type « objectifs » de la trame actuelle hériteront des paramètres définis ici.",
              })}
            </>
          </DSAlert>
          <ChoicesSettings
            choices={choices}
            addChoice={addChoice}
            removeChoice={removeChoice}
            handleChangeChoices={handleChangeChoices}
            handleReorderChoices={handleReorderChoices}
          />
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          label={"Enregistrer"}
          onClick={send(t)}
          disabled={
            template.choicesForOrdinalTargets?.toString() ===
            choices.map(({ title }) => title).toString()
          }
          loading={loading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
