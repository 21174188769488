import styled, { css } from "styled-components";
import { DSColor, SkuiSpacing } from "components/DesignSystemContext";

const onDarkBorder = css`
  border-color: ${DSColor["border-onDark"]};
`;

const onLightBorder = css`
  border-color: ${DSColor["border-onLight"]};
`;

export const darkModeStyles = css`
  background-color: ${DSColor["surface-dark"]};
  border: 1px solid ${DSColor["border-onLight"]};
  color: ${DSColor["plainText-onDark-darker"]};
`;

export const lightModeStyles = css`
  background-color: ${DSColor["surface-light"]};
  border: 1px solid ${DSColor["border-onDark"]};
  color: ${DSColor["plainText-onLight-lighter"]};
`;

const getThemeStyles = (darkMode: boolean) => (darkMode ? lightModeStyles : darkModeStyles);

export const FilterCard = styled.div<{ darkMode: boolean }>`
  display: flex;
  overflow: hidden;
  flex: 1;
  align-items: center;
  flex-direction: row;

  ${({ darkMode }) => getThemeStyles(darkMode)};
`;

export const Cell = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  padding: 0;
`;

export const SelectWrapper = styled(Cell)`
  div {
    font-size: 0.75rem;
  }

  > div > div {
    border-radius: 0px 4px 4px 0px;
  }
`;

export const BetweenOperator = styled(Cell)<{ darkMode: boolean }>`
  font-size: 0.625rem;
  font-weight: 700;
  padding: 0 ${SkuiSpacing.xs};
  color: ${DSColor["plainText-onLight-lighter"]};

  ${({ darkMode }) => getThemeStyles(darkMode)};
  ${({ darkMode }) =>
    darkMode
      ? `background-color: ${DSColor["surface-light-darker"]}`
      : `background-color: ${DSColor["surface-dark-darker"]}`};
`;

export const DatePickerWrapper = styled.div<{ noRadius: boolean; darkMode: boolean }>`
  border: 1px solid ${DSColor["border-onLight"]};

  ${({ darkMode }) => getThemeStyles(darkMode)};
  ${({ noRadius }) => (noRadius ? "border-radius: 0;" : "border-radius:0 4px 4px 0;")};

  display: flex;
  fieldset {
    border: none !important;
  }

  input {
    color: ${DSColor["plainText-onLight-default"]};
  }

  > div {
    border-left: 1px solid ${DSColor["border-onLight"]};
  }
`;

export const StyledSelect = styled.div<{ darkMode?: boolean }>`
  display: flex;
  flex: 1;
  border: ${({ darkMode }) => (darkMode ? `1px` : `1px solid`)};
  border-radius: 0 4px 4px 0;
  ${({ darkMode }) => (darkMode ? onDarkBorder : onLightBorder)};

  > div > div {
    ${({ darkMode }) => !darkMode && `border: none`};
  }
`;

export const NumberFilterWrapper = styled.div`
  display: flex;

  > div {
    height: 100%;
    border-radius: 0 4px 4px 0;
  }
`;
