import { MdArrowUpward as ArrowUp } from "react-icons/md";
import { MdArrowDownward as ArrowDown } from "react-icons/md";
import { MdDelete as Delete } from "react-icons/md";
import { useCallback } from "react";
import {
  Label as DSLabel,
  DSButton,
  DSFormGroupTextInput,
  Select,
  Flex,
  DSTooltip,
} from "@skillup/ui";

import { Divided } from "../../ChildWrappers";
import { TableColumn, TableColumnType, TableColumnDisallow } from "../types";

import { TableChildColumnPermissions } from "./TableChildColumnPermissions";
import { BuilderFormInput } from "../../../BuilderFormInput";

import styles from "../TableChild.module.scss";
import { TranslationType } from "hooks/useTranslation";

const COLUMN_TYPES_LABELS = (t: TranslationType): { [key in TableColumnType]: string } => ({
  text: t("interviews.templateBuilder.table.cells.types.text", {
    defaultValue: "À remplir par le participant - Texte libre",
  }),
  fixed: t("interviews.templateBuilder.table.cells.types.fixed", { defaultValue: "Valeur fixe" }),
  dropdown: t("interviews.templateBuilder.table.cells.types.dropdown", {
    defaultValue: "À remplir par le participant - Choix multiple",
  }),
});

export const TableChildColumn = ({
  childId,
  rowsCount,
  column,
  index,
  onDeleteColumn,
  onUpdateColumn,
  onMoveColumnUpOrDown,
  disabledMoveUp,
  disabledMoveDown,
  t,
}: {
  childId: string;
  rowsCount: number;
  column: TableColumn;
  index: number;
  onDeleteColumn: (index: number) => void;
  onUpdateColumn: (index: number, column: TableColumn) => void;
  onMoveColumnUpOrDown: (index: number, direction: "up" | "down") => void;
  disabledMoveUp?: boolean;
  disabledMoveDown?: boolean;
  t: TranslationType;
}) => {
  const handleChangeTitle = useCallback(
    (value: string) => {
      onUpdateColumn(index, {
        ...column,
        title: value,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleChangeType = useCallback(
    (value: TableColumnType) => {
      switch (value) {
        case "text":
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow:
              column.type !== "fixed"
                ? column.disallow
                : { employee: { reply: false }, manager: { reply: true } },
            type: "text",
          });
          break;
        case "fixed":
          const values = new Array(rowsCount).fill("");
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow: { employee: { reply: true }, manager: { reply: true } },
            type: "fixed",
            values: values,
          });
          break;
        case "dropdown":
          onUpdateColumn(index, {
            uuid: column.uuid,
            title: column.title,
            disallow:
              column.type !== "fixed"
                ? column.disallow
                : { employee: { reply: false }, manager: { reply: true } },
            type: "dropdown",
            choices: [],
          });
          break;
      }
    },
    [index, column, rowsCount, onUpdateColumn]
  );

  // FIXED COLUMN
  const handleChangeColumnFixedValue = useCallback(
    (value: string, valueIndex: number) => {
      if (column.type !== "fixed") {
        return;
      }
      const newValues = [...column.values];
      newValues[valueIndex] = value;
      onUpdateColumn(index, {
        ...column,
        values: newValues,
      });
    },
    [index, column, onUpdateColumn]
  );

  // DROPDOWN COLUMN
  const handleChangeColumnDropdownChoice = useCallback(
    (value: string, choiceIndex: number) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = [...column.choices];
      newChoices[choiceIndex] = value;
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleRemoveColumnDropdownChoice = useCallback(
    (choiceIndex: number) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = [...column.choices];
      newChoices.splice(choiceIndex, 1);
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );
  const handleAddColumnDropdownChoice = useCallback(
    (value: string) => {
      if (column.type !== "dropdown") {
        return;
      }
      const newChoices = column.choices.concat(value);
      onUpdateColumn(index, {
        ...column,
        choices: newChoices,
      });
    },
    [index, column, onUpdateColumn]
  );

  // PERMISSIONS
  const handleSetColumnPermissionReply = useCallback(
    (disallow: TableColumnDisallow) => {
      onUpdateColumn(index, {
        ...column,
        disallow: disallow,
      });
    },
    [index, column, onUpdateColumn]
  );

  const handleDeleteColumn = useCallback(() => {
    onDeleteColumn(index);
  }, [index, onDeleteColumn]);

  return (
    <Divided key={column.uuid}>
      <div className={styles.table__column__content}>
        <div className={styles.table__column__content__header}>
          <div>
            {t("interviews.templateBuilder.table.columns.index", {
              defaultValue: "Colonne {{ count }}",
              count: index + 1,
            })}
          </div>
          <div className={styles.table__column__content__header__buttons}>
            <DSButton
              label={t("interviews.templateBuilder.table.columns.delete", {
                defaultValue: "Supprimer une colonne",
              })}
              buttonSize="S"
              emphasis="Mid"
              onClick={handleDeleteColumn}
            />
            <DSTooltip
              label={t("interviews.templateBuilder.table.columns.moveUp", {
                defaultValue: "Déplacer la colonne vers le haut",
              })}
              withPortal
            >
              <DSButton
                disabled={disabledMoveUp}
                icon={<ArrowUp />}
                iconOnly
                buttonSize="S"
                emphasis="Mid"
                onClick={() => onMoveColumnUpOrDown(index, "up")}
              />
            </DSTooltip>
            <DSTooltip
              label={t("interviews.templateBuilder.table.columns.moveDown", {
                defaultValue: "Déplacer la colonne vers le bas",
              })}
              withPortal
            >
              <DSButton
                disabled={disabledMoveDown}
                icon={<ArrowDown />}
                iconOnly
                buttonSize="S"
                emphasis="Mid"
                onClick={() => onMoveColumnUpOrDown(index, "down")}
              />
            </DSTooltip>
          </div>
        </div>
        <Flex column className={styles.table__column__content__form}>
          <BuilderFormInput
            type="text"
            value={column.title}
            onChange={handleChangeTitle}
            label={t("interviews.templateBuilder.table.columns.label", {
              defaultValue: "Libellé de la colonne",
            })}
            name={`child-${childId}-column-${column.uuid}-label`}
            placeholder={t("interviews.templateBuilder.table.placeholder", {
              defaultValue: "Saisissez le libellé du tableau",
            })}
          />
          <DSFormGroupTextInput
            label={t("interviews.templateBuilder.table.cells.types", {
              defaultValue: "Type des cellules de la colonne",
            })}
            required
          >
            <Select
              onChange={handleChangeType}
              options={Object.entries(COLUMN_TYPES_LABELS(t)).map(([value, label]) => ({
                value,
                label,
              }))}
              value={column.type}
              defaultValue={{ value: column.type, label: COLUMN_TYPES_LABELS(t)[column.type] }}
            />
          </DSFormGroupTextInput>
          {column.type === "fixed" &&
            column.values.map((value, valueIndex) => (
              <div key={valueIndex}>
                <BuilderFormInput
                  type="text"
                  value={value ? value.toString() : ""}
                  onChange={(newValue) => handleChangeColumnFixedValue(newValue, valueIndex)}
                  debounceValue={100}
                  label={t("interviews.templateBuilder.table.cells.fixed.lines.label", {
                    defaultValue: "Contenu de la ligne {{ count }}",
                    count: valueIndex + 1,
                  })}
                  name={`child-${childId}-column-${column.uuid}-value-${valueIndex}`}
                  placeholder={t("interviews.templateBuilder.table.cells.lines.placeholder", {
                    defaultValue: "Saisissez le contenu de la ligne {{ count }}",
                    count: valueIndex + 1,
                  })}
                />
              </div>
            ))}
          {column.type === "dropdown" && (
            <Flex column className={styles["table__column--dropdown__choices"]}>
              <DSLabel
                label={t("interviews.templateBuilder.choices.label", {
                  defaultValue: "Choix possibles",
                })}
                required
              />
              {column.choices.map((choice, choiceIndex, arr) => (
                <Flex
                  row
                  className={styles["table__column--dropdown__choices__item"]}
                  key={`${choiceIndex}-${arr.length}`}
                >
                  <BuilderFormInput
                    type="text"
                    value={choice}
                    onChange={(newChoice) =>
                      handleChangeColumnDropdownChoice(newChoice, choiceIndex)
                    }
                    debounceValue={300}
                    name={`child-${childId}-column-${column.uuid}-choice-${choiceIndex}-label`}
                    placeholder={t("interviews.templateBuilder.question.choices.placeholder", {
                      defaultValue: "Libellé du choix {{ count }}",
                      count: choiceIndex + 1,
                    })}
                    inputClassName={styles["table__column--dropdown__choices__item__input"]}
                  />
                  <div
                    className={styles["table__column--dropdown__choices__item__deleteButton"]}
                    aria-label="Supprimer ce choix"
                  >
                    <DSButton
                      iconOnly
                      icon={<Delete />}
                      tooltip={t("interviews.templateBuilder.question.choices.delete", {
                        defaultValue: "Supprimer le choix",
                      })}
                      tooltipDirection="left"
                      onClick={() => handleRemoveColumnDropdownChoice(choiceIndex)}
                    />
                  </div>
                </Flex>
              ))}
              <DSButton
                label={t("interviews.templateBuilder.question.choices.add", {
                  defaultValue: "Ajouter un choix possible",
                })}
                onClick={() => handleAddColumnDropdownChoice("")}
                buttonSize="S"
                emphasis="Mid"
              />
            </Flex>
          )}
        </Flex>
      </div>
      <TableChildColumnPermissions
        childId={childId}
        column={column}
        index={index}
        onUpdatePermissions={handleSetColumnPermissionReply}
        t={t}
      />
    </Divided>
  );
};
