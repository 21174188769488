import { MdOutlineTableRows as OutlineTableRows } from "react-icons/md";
import { MdTrackChanges as TrackChanges } from "react-icons/md";
import { MdPsychology as Psychology } from "react-icons/md";
import { MdHistory as History } from "react-icons/md";
import { MdSchool as School } from "react-icons/md";
import { MdInsertDriveFile as InsertDriveFile } from "react-icons/md";
import React, { Fragment, useMemo } from "react";
import { match } from "react-router-dom";

import Acta from "utils/Acta";
import { IWithRouter } from "utils/types";

import { DSButton } from "@skillup/ui";
import { DashboardChildType } from "@skillup/espace-rh-bridge";

import ChooseCampaignModale from "../CampaignsList/ChooseCampaignModale";
import ClosedEndedQuestion from "./components/ClosedEndedQuestion/ClosedEndedQuestion";
import OpenEndedQuestion from "./components/OpenEndedQuestion/OpenEndedQuestion";
import PlaceholderCard from "./components/PlaceholderCard/PlaceholderCard";

import GlobalFilters from "./components/GlobalFilters/GlobalFilters";
import Summary from "./components/Summary/Summary";
import TableOfContent from "./components/TableOfContent/TableOfContent";
import { StructureChildPayload } from "./components/DashboardChildCard/DashboardChildCard";
import DSLayout from "components/DSLayout";
import useTranslation, { TranslationType } from "hooks/useTranslation";

import { useDashboardData, DashboardDataContextProvider } from "./context";
import { useCampaigns } from "./state/campaigns";
import NoInterviewsSVG from "./assets/NoInterviews";

import styles from "./InterviewDashboard.module.scss";

interface IProps extends IWithRouter {
  match: match<{ interviewUuid?: string }>;
}

const Dashboard = ({ match: { params } }: IProps) => {
  const { interviewUuid } = params;
  const { userAreas } = useDashboardData();
  const fetchedCampaigns = useCampaigns(userAreas);
  const { t } = useTranslation();

  const noInterviewsAfterLoad = useMemo(
    () => fetchedCampaigns && !fetchedCampaigns.length,
    [fetchedCampaigns]
  );

  const chooseCampaignModale = () => {
    Acta.setState("modalDisplayed", {
      content: <ChooseCampaignModale />,
      size: "small",
      title: "Nouveau",
      showOverflow: true,
    });
  };

  return (
    <DashboardDataContextProvider>
      <DSLayout
        title={t("dashboard.interviews.layout", { defaultValue: "Dashboard - Entretiens" })}
      >
        <div className={styles.Dashboard}>
          {!noInterviewsAfterLoad && fetchedCampaigns && (
            <div className={styles.content}>
              <div className={styles.main}>
                <GlobalFilters activeCampaign={interviewUuid} t={t} />
                <Summary t={t} />
                <DashboardChildren t={t} />
              </div>
              <div className={styles.secondary}>
                <TableOfContent />
              </div>
            </div>
          )}
          {noInterviewsAfterLoad && (
            <div className={styles.noInterviews}>
              <p>
                {t("dashboard.interviews.empty", {
                  defaultValue:
                    "Vous n’avez encore lancé aucune campagne d’entretiens, il n’y a rien à afficher ici pour l’instant.",
                })}
              </p>
              <DSButton
                className={styles.campaignButton}
                label={t("dashboard.interviews.createCampaign", {
                  defaultValue: "Créer une campagne",
                })}
                onClick={chooseCampaignModale}
                buttonSize="M"
                emphasis="High"
              />
              <NoInterviewsSVG />
            </div>
          )}
        </div>
      </DSLayout>
    </DashboardDataContextProvider>
  );
};

function DashboardChildren({ t }: { t: TranslationType }) {
  const { campaignStructure } = useDashboardData();

  return (
    <div className={styles.DashboardChildren}>
      {campaignStructure.map((section) => (
        <Fragment key={section.uuid}>
          <h2
            id={`section-${section.uuid}`}
            className={section.pages.length > 1 ? "" : styles.aloneTitle}
          >
            {section.title}
          </h2>
          {section.pages.map((page) => (
            <Fragment key={page.uuid}>
              {section.pages.length > 1 && <h3 id={`page-${page.uuid}`}>{page.title}</h3>}
              {page.children.map((child) => (
                <DashboardChild key={child.uuid} child={child} t={t} />
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  );
}
interface DashboardChildProps {
  child: StructureChildPayload;
  t: TranslationType;
}
function DashboardChild({ child, t }: DashboardChildProps) {
  switch (child.type) {
    case DashboardChildType.CLOSED_QUESTION:
      return <ClosedEndedQuestion child={child} t={t} />;
    case DashboardChildType.OPEN_QUESTION:
      return <OpenEndedQuestion child={child} t={t} />;
    case DashboardChildType.TABLE:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.table", { defaultValue: "Tableau" })}
          title={child.label}
          icon={<OutlineTableRows />}
          t={t}
        />
      );
    case DashboardChildType.CURRENT_TARGETS:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.targets.current", {
            defaultValue: "Objectifs évalués",
          })}
          icon={<TrackChanges />}
          t={t}
        />
      );
    case DashboardChildType.GLOBAL_TARGETS_COMPLETION:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.targetsGlobalCompletion", {
            defaultValue: "Taux d’atteinte global",
          })}
          icon={<OutlineTableRows />}
          t={t}
        />
      );
    case DashboardChildType.FUTURE_TARGETS:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.targets.next", {
            defaultValue: "Objectifs futurs",
          })}
          icon={<TrackChanges />}
          t={t}
        />
      );
    case DashboardChildType.SKILLS:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.skills", { defaultValue: "Compétences" })}
          icon={<Psychology />}
          t={t}
        />
      );
    case DashboardChildType.TRAININGS_HISTORY:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.trainingsHistory", {
            defaultValue: "Historique de formations",
          })}
          icon={<History />}
          t={t}
        />
      );
    case DashboardChildType.INTERVIEWS_HISTORY:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.interviewsHistory", {
            defaultValue: "Historique d’entretiens",
          })}
          icon={<History />}
          t={t}
        />
      );
    case DashboardChildType.TRAININGS_COLLECTION:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.trainingsCollection", {
            defaultValue: "Demande de formation",
          })}
          icon={<School />}
          t={t}
        />
      );
    case DashboardChildType.UPLOAD:
      return (
        <PlaceholderCard
          child={child}
          typeName={t("dashboard.interviews.child.upload", { defaultValue: "Ajout de fichier" })}
          icon={<InsertDriveFile />}
          t={t}
        />
      );
    default:
      return null;
  }
}

export default Dashboard;
