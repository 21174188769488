import { TranslationType } from "hooks/useTranslation";
import { Section } from "../types";

export const getSectionErrors = (section: Section, t: TranslationType) => {
  return !section.title
    ? [
        {
          structure: t("interviews.templateBuilder.errors.section.structure", {
            defaultValue: "Vous n'avez pas défini de libellé",
          }),
          header: t("interviews.templateBuilder.errors.section.header", {
            defaultValue: "Section sans libellé",
          }),
        },
      ]
    : undefined;
};
