import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import { TranslationType } from "hooks/useTranslation";

export default async (
  rows: string[],
  scheduleUuid: string,
  reloadRows: (uuids: string[]) => void,
  t: TranslationType
) => {
  try {
    await DataLayer.request({
      method: "POST",
      url: `/v1/schedule/move-to-plan/${scheduleUuid}`,
      body: JSON.stringify({ rows }),
    });

    Acta.dispatchEvent("sendAppMessage", {
      message: t("trainings.view.pending_schedule_row.property.validate_modal.success", {
        defaultValue: "Ces lignes ont été ajoutées au plan.",
        count: rows.length,
      }),
      type: "success",
    });

    reloadRows(rows);
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: t("trainings.view.pending_schedule_row.property.validate_modal.error", {
        defaultValue: "Echec de l’ajout au plan",
      }),
      type: "error",
    });
  }
};
