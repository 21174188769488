import { useMemo } from "react";
import { DSSidePanel, DSTooltip, DSUserPreview, Flex } from "@skillup/ui";
import { ReviewStatus } from "@skillup/training-bridge";
import { ReviewsRoutes } from "@skillup/espace-rh-bridge";
import { useTypedFetch } from "hooks";
import { getUserInitials } from "utils/User";
import useTranslation from "hooks/useTranslation";

import { getCompletedAtAndStartLiteralDates } from "../utils/dateFormater";
import {
  MainAction,
  MenuActions,
  ReviewPanelContentNotReviewed,
} from "../../components/ReviewSidepanel";
import PieChart from "./PieChart";
import QuestionsDetails from "./QuestionsDetails";
import styles from "./ColdReviewSidePanel.module.scss";

interface Props {
  readonly uuid: string;
  readonly onClose: () => void;
  readonly refetchRow: () => void;
}

const ColdReviewSidePanel = ({ uuid, onClose, refetchRow }: Props) => {
  const { t } = useTranslation();

  const { data: reviewsDetails = [], refetch } = useTypedFetch<ReviewsRoutes.GetColdReviewsDetails>(
    {
      method: "POST",
      path: `/reviews/cold/details`,
      payload: {
        reviewUuids: [uuid],
      },
    }
  );

  const invalidateData = useMemo(() => {
    return () => {
      refetch();
      refetchRow();
    };
  }, [refetch, refetchRow]);

  const [reviewDetails] = reviewsDetails;

  if (!reviewDetails) {
    return null;
  }

  const { completedAt, startDate } = getCompletedAtAndStartLiteralDates(reviewDetails);
  const { comment: headerComment } = reviewDetails.data || {};

  const tooltipDisabled = reviewDetails.session.training.name.length > 60;

  const completedReview = reviewDetails.status === ReviewStatus.COMPLETED;

  return (
    <DSSidePanel
      isOpen={!!reviewDetails}
      className={styles.ColdReviewSidePanel}
      isAnimated={false}
      onBackgroundClick={onClose}
    >
      <DSSidePanel.Header onClose={onClose}>
        <DSSidePanel.Header.Title
          title={t("trainings.manage.reviews.sidepanel.title", {
            name: reviewDetails.trainee.fullName,
            defaultValue: "Évaluation de {{ name }}",
          })}
        />
        <DSTooltip
          disabled={!tooltipDisabled}
          className={styles.subtitleWrapper}
          label={reviewDetails.session.training.name}
        >
          <DSSidePanel.Header.SubTitle subtitle={reviewDetails.session.training.name} />
        </DSTooltip>
        {reviewDetails.data?.comment && (
          <DSSidePanel.Header.CustomContent>
            <div className={styles.headerContent}>
              {headerComment && headerComment.trim() && (
                <>
                  <DSUserPreview
                    className={styles.userPreview}
                    initials={getUserInitials(reviewDetails.trainee)}
                    fullName={reviewDetails.trainee.fullName}
                    size="M"
                  />
                  <Flex className={styles.comment} column>
                    <div> {headerComment} </div>
                  </Flex>
                </>
              )}
            </div>
          </DSSidePanel.Header.CustomContent>
        )}
        {!completedReview && (
          <Flex className={styles.actionMenu}>
            <MainAction
              status={reviewDetails.status}
              reviewUuid={reviewDetails.uuid}
              invalidateData={invalidateData}
            />
            <MenuActions status={reviewDetails.status} url={reviewDetails.url} />
          </Flex>
        )}
      </DSSidePanel.Header>

      <DSSidePanel.Content key={reviewDetails.uuid}>
        <div className={styles.content}>
          {reviewDetails && completedReview && (
            <>
              <p className={styles.pieTitle}>
                {t("trainings.manage.reviews.sidepanel.pie.title", {
                  defaultValue: "Ratio de réponse",
                })}
              </p>
              <div className={styles.SidePanelContent}>
                <PieChart reviewDetails={reviewDetails} />
                <QuestionsDetails reviewDetails={reviewDetails} />
              </div>

              <section className={styles.info}>
                <p>
                  {t("trainings.manage.reviews.session.completedAt", {
                    date: completedAt,
                    defaultValue: "Complétée le : {{ date }}",
                  })}
                </p>
                <p>
                  {t("trainings.manage.reviews.session.startDate", {
                    date: startDate,
                    defaultValue: "Début de la session : {{ date }}",
                  })}
                </p>
                <p>
                  {t("trainings.manage.reviews.session.reference", {
                    reference: reviewDetails?.session.reference,
                    defaultValue: "ID de session : {{ reference }}",
                  })}
                </p>
                <p>
                  {t("trainings.manage.reviews.session.reference", {
                    organization: reviewDetails?.session.training.trainingOrganization,
                    defaultValue: "Organisme : {{ organization }}",
                  })}
                </p>
              </section>
            </>
          )}
          {reviewDetails && !completedReview && (
            <ReviewPanelContentNotReviewed reviewDetails={reviewDetails} />
          )}
        </div>
      </DSSidePanel.Content>

      <DSSidePanel.FooterEmpty />
    </DSSidePanel>
  );
};

export default ColdReviewSidePanel;
