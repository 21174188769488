import { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useToggle } from "react-use";

import { CampaignExportStates } from "@skillup/espace-rh-bridge";
import { DSButton } from "@skillup/ui";

import { TFunction } from "hooks/useTranslation";
import { useExportAsyncContext } from "../ExportAsyncContext";

interface Props {
  readonly interviewsUuids: string[];
  readonly t: TFunction;
}

export const DownloadRepliesButton = ({ interviewsUuids, t }: Props): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);
  const { addToast } = useToasts();

  const { exportCampaignAsync, userNotificationState } = useExportAsyncContext();

  const downloadReplies = useCallback(async (): Promise<void> => {
    toggleLoading(true);
    try {
      await exportCampaignAsync(interviewsUuids);
    } catch (e) {
      addToast(
        t("campaign.header.exportCampaignError", {
          defaultValue:
            "Une erreur est survenue ; si elle persiste, veuillez contacter le support.",
        }),
        {
          appearance: "error",
        }
      );
    } finally {
      toggleLoading(false);
    }
  }, [toggleLoading, addToast, interviewsUuids, exportCampaignAsync, t]);

  const disableAsyncExportButton =
    userNotificationState === CampaignExportStates.IN_PROGRESS ? true : false;

  return (
    <DSButton
      loading={loading}
      buttonSize="M"
      label={t("campaign.header.exportCampaign", {
        defaultValue: "Exporter les entretiens (XLS)",
      })}
      fontWeight="light"
      emphasis="Low"
      onClick={downloadReplies}
      disabled={disableAsyncExportButton || !interviewsUuids.length}
      tooltip={
        disableAsyncExportButton
          ? t("campaign.header.exportCampaignExists", {
              defaultValue: "Un export est déjà en cours",
            })
          : interviewsUuids.length === 0
            ? t("campaign.header.exportCampaignNoInterview", {
                defaultValue: "Aucun entretien dans cette campagne",
              })
            : undefined
      }
    />
  );
};
