import { Label } from "@skillup/ui";

import DocumentListItem from "../DocumentListItem/DocumentsListItem";

import { Tracking, TrackingDocument } from "../../../types";

import styles from "./DocumentList.module.scss";

interface Props {
  readonly tracking: Tracking;
  readonly refetchTrackings: () => void;
}

const DocumentList = ({ tracking, refetchTrackings }: Props) => {
  return (
    <section className={styles.trackingSection}>
      <Label label="Documents associés" />
      <div className={styles.content}>
        <div>
          {tracking?.documents?.length > 0 ? (
            tracking.documents?.map((doc: TrackingDocument, key: number) => (
              <DocumentListItem
                document={doc}
                trackingUuid={tracking.uuid}
                refetchTrackings={refetchTrackings}
                key={key}
              />
            ))
          ) : (
            <p className={styles.documentName}>Aucun document</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default DocumentList;
