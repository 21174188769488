import { useMemo, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { Route, Switch, useHistory } from "react-router-dom";

import { saveAs } from "file-saver";

import { useModal, DSDropdownItem } from "@skillup/ui";

/** Utils */
import Acta from "utils/Acta";
import User from "utils/User";
import { trpc } from "utils/trpc";
/** Global components*/
import DSLayout from "components/DSLayout";
import useSettings from "hooks/useSettings";
/** Hooks */
import useTranslation from "hooks/useTranslation";
import createUserAccessChecker from "hooks/userAccessChecker";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import Jobs from "./Jobs/pages/List";
import Assignments from "./Assignments";
/** Employees */
import Employees from "./Legacy/Employees";
import { ListSkills } from "./Skills/pages";
/** Skills */
import SkillsList from "./Legacy/SkillsList";
/** Jobs */
import JobsDescription from "./Legacy/JobsV1";
import UploadJobsModal from "./Legacy/JobsV1/UploadJobsModal";
import CreateJobFieldModal from "./Legacy/JobsV1/CreateJobField";
import UploadSkillsModalV1 from "./Legacy/SkillsList/UploadSkillsModal";
import { JobsUploadModal } from "./Jobs/components/Modals/JobsUploadModal";
import UploadAssignmentsModal from "./Legacy/Employees/UploadAssignmentsModal";
import CreateSkillCategoryModal from "./Legacy/SkillsList/CreateSkillCategoryModal";
/** Local components */
import { ManageJobsCustomFieldsModal, ManageSkillsCustomFieldsModal } from "./components/Modals";
import { UploadSkillsModal, UploadEmployeesJobsModal } from "./Skills/pages/List/components/Modal";

/** Styles */
import styles from "./Skills.module.scss";

const Skills = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const { settings, userAccessModules } = useSettings();
  const manageSkillsCustomFields = useModal();
  const manageSkillsImport = useModal();
  const manageJobsImport = useModal();
  const manageEmployeesJobsImport = useModal();
  const manageJobsCustomFields = useModal();
  const { addToast } = useToasts();
  const getTemplate = trpc.skills.getTemplate.useQuery(undefined, { enabled: false });
  const exportSkills = trpc.skills.exportSkills.useQuery(undefined, { enabled: false });
  const exportJobs = trpc.jobs.exportJobs.useQuery(undefined, { enabled: false });
  const exportEmployees = trpc.employees.exportEmployees.useQuery(undefined, { enabled: false });
  const republishSkillsMutator = trpc.skills.republishSkills.useMutation({
    onError: (e) => {
      console.log(e);
      addToast(
        t("jobs.list.label.republishSkills.error", {
          defaultValue: `Echec de la republication des skills, aller dans la console pour les détails`,
        }),
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      addToast(
        t("jobs.list.label.republishSkills.success", {
          defaultValue: `Les skills avec une différence de version ont été republiés.`,
        }),
        {
          appearance: "success",
        }
      );
    },
  });

  const republishJobMutator = trpc.jobs.republishJobs.useMutation({
    onError: (e) => {
      console.log(e);
      addToast(
        t("jobs.list.label.republishJobs.error", {
          defaultValue: `Echec de la republication des jobs, aller dans la console pour les détails`,
        }),
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      addToast(
        t("jobs.list.label.republishJobs.success", {
          defaultValue: `Les jobs avec une différence de version ont été republiés.`,
        }),
        {
          appearance: "success",
        }
      );
    },
  });

  const republishArchivedJobsMutator = trpc.jobs.republishArchivedJobs.useMutation({
    onError: (e) => {
      console.log(e);
      addToast(
        t("jobs.list.label.republishArchivedJobs.error", {
          defaultValue: `Echec de la republication des jobs, aller dans la console pour les détails`,
        }),
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      addToast(
        t("jobs.list.label.republishArchivedJobs.success", {
          defaultValue: `Les jobs archivés ont été republiés.`,
        }),
        {
          appearance: "success",
        }
      );
    },
  });

  const handleRepublishJobs = useCallback(() => {
    republishJobMutator.mutate();
  }, [republishJobMutator]);

  const handleRepublishArchivedJobs = useCallback(() => {
    republishArchivedJobsMutator.mutate();
  }, [republishArchivedJobsMutator]);

  const handleRepublishSkills = useCallback(() => {
    republishSkillsMutator.mutate();
  }, [republishSkillsMutator]);

  const exportSkillTemplate = useCallback(() => {
    getTemplate.refetch().then((result) => {
      if (result.isSuccess) {
        const blob = new Blob([new Uint8Array(result.data.data as unknown as ArrayBufferLike)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "template.xlsx");
      }
    });
  }, [getTemplate]);

  const exportAllSkills = useCallback(() => {
    exportSkills.refetch().then((result) => {
      if (result.isSuccess) {
        const blob = new Blob([new Uint8Array(result.data.data as unknown as ArrayBufferLike)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "competences.xlsx");
      }
    });
  }, [exportSkills]);

  const exportAllJobs = useCallback(() => {
    exportJobs.refetch().then((result) => {
      if (result.isSuccess) {
        const blob = new Blob([new Uint8Array(result.data.data as unknown as ArrayBufferLike)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "fiches-de-poste.xlsx");
      }
    });
  }, [exportJobs]);

  const exportAllEmployees = useCallback(() => {
    exportEmployees.refetch().then((result) => {
      if (result.isSuccess) {
        const blob = new Blob([new Uint8Array(result.data.data as unknown as ArrayBufferLike)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "collaborateurs.xlsx");
      }
    });
  }, [exportEmployees]);

  const layouts = useMemo(() => {
    const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);
    let result = [];
    if (UserAccessChecker.Skills.toSkillsJobs()) {
      result.push({
        dropdownContent: [
          User.isSkillupDeveloper() && [
            <DSDropdownItem
              key="republish-jobs"
              disabled={republishJobMutator.isLoading}
              label="[DEV] Republish ALL (don't spam me)"
              onClick={handleRepublishJobs}
            />,
            <DSDropdownItem
              key="republish-archived-jobs"
              disabled={republishArchivedJobsMutator.isLoading}
              label="[DEV] Republish archived jobs (don't spam me)"
              onClick={handleRepublishArchivedJobs}
            />,
            <DSDropdownItem
              key="import-job"
              label="[DEV] Importer des fiches de poste"
              onClick={manageJobsImport.show}
            />,
          ],
          User.isSkillupAdmin() && [
            <DSDropdownItem
              key="import-job-field"
              label="[OPS] Gérer les champs personnalisés des fiches de poste"
              onClick={manageJobsCustomFields.show}
            />,
          ],
          <DSDropdownItem
            key="export-skills"
            onClick={exportAllJobs}
            label={t("jobs.button.exportJobs", {
              defaultValue: "Exporter les fiches de poste",
            })}
          />,
        ],
        primaryButton: (
          <DSNewHeaderButton
            onClick={() => history.push(`${pathname}/new`)}
            label={t("jobs.button.createJob", {
              defaultValue: "Créer une fiche de poste",
            })}
          />
        ),
        tab: {
          id: "fiches-de-poste",
          label: t("jobs.layout.jobsTab", {
            defaultValue: "Fiches de poste",
          }),
          url: "/responsable/referentiels/fiches-de-poste",
        },
      });
      if (User.isSkillupDeveloper()) {
        result.push({
          dropdownContent: User.isSkillupDeveloper()
            ? [
                <DSDropdownItem
                  key="import-job"
                  label="Importer des fiches de poste"
                  onClick={() =>
                    Acta.setState("modalDisplayed", {
                      content: <UploadJobsModal />,
                      size: "auto",
                      title: "Import de fiches de poste",
                    })
                  }
                />,
                <DSDropdownItem
                  key="import-job-field"
                  label="Ajouter un champ de fiche de poste"
                  onClick={() =>
                    Acta.setState("modalDisplayed", {
                      content: <CreateJobFieldModal />,
                      size: "auto",
                      title: "Ajouter un champ de fiche de poste",
                    })
                  }
                />,
              ]
            : undefined,
          primaryButton: (
            <DSNewHeaderButton
              onClick={() => history.push(`${pathname}/new`)}
              label={t("jobs.button.createJob", {
                defaultValue: "Créer une fiche de poste",
              })}
            />
          ),
        });
      }
    }
    if (UserAccessChecker.Skills.toSkillsRequired()) {
      result.push({
        dropdownContent: [
          User.isSkillupDeveloper() && [
            <DSDropdownItem
              key="republish-skills"
              disabled={republishSkillsMutator.isLoading}
              label="[DEV] Republish ALL (don't spam me)"
              onClick={handleRepublishSkills}
            />,
            <DSDropdownItem
              key="import-skill"
              label="[DEV] Importer des compétences"
              onClick={manageSkillsImport.show}
            />,
            <DSDropdownItem
              key="export-skill-template"
              label="[DEV] Exporter le template d'import des compétences"
              onClick={exportSkillTemplate}
            />,
          ],
          User.isSkillupAdmin() && [
            <DSDropdownItem
              key="import-category"
              label="[OPS] Gérer les champs personnalisés des compétences"
              onClick={manageSkillsCustomFields.show}
            />,
          ],
          <DSDropdownItem
            key="export-skills"
            onClick={exportAllSkills}
            label={t("skills.list.skills.exportSills", {
              defaultValue: "Exporter les compétences",
            })}
          />,
        ],
        primaryButton: (
          <DSNewHeaderButton
            onClick={() => history.push(`${pathname}/new`)}
            label={t("skills.list.skills.createSkill", {
              defaultValue: "Créer une compétence",
            })}
          />
        ),
        tab: {
          id: "competences",
          label: t("skills.layout.skillsTab", {
            defaultValue: "Compétences",
          }),
          url: "/responsable/referentiels/competences",
        },
      });
      if (User.isSkillupDeveloper()) {
        result.push({
          dropdownContent: User.isSkillupDeveloper()
            ? [
                <DSDropdownItem
                  key="import-skill"
                  label="Importer des compétences"
                  onClick={() =>
                    Acta.setState("modalDisplayed", {
                      content: <UploadSkillsModalV1 />,
                      size: "auto",
                      title: "Import de compétences",
                    })
                  }
                />,
                <DSDropdownItem
                  key="import-category"
                  label="Ajouter une catégorie"
                  onClick={() =>
                    Acta.setState("modalDisplayed", {
                      content: <CreateSkillCategoryModal />,
                      size: "auto",
                      title: "Ajouter une catégorie",
                    })
                  }
                />,
              ]
            : undefined,
          primaryButton: (
            <DSNewHeaderButton
              label={"Créer une compétence"}
              onClick={() => history.push(`${pathname}/new`)}
            />
          ),
        });
      }
    }

    if (UserAccessChecker.Skills.toSkillsEmployees()) {
      result.push({
        dropdownContent: [
          User.isSkillupDeveloper() && (
            <DSDropdownItem
              key="import-employees-jobs"
              label="[DEV] Importer des associations de fiches de poste"
              onClick={manageEmployeesJobsImport.show}
            />
          ),
          <DSDropdownItem
            key="export-employees"
            label="Exporter les associations fiches de postes <-> collaborateurs"
            onClick={exportAllEmployees}
          />,
        ],
        tab: {
          id: "collaborators",
          label: t("collaborators.layout.collaboratorsTab", {
            defaultValue: "Collaborateurs",
          }),
          url: "/responsable/referentiels/collaborators",
        },
      });
      if (User.isSkillupDeveloper()) {
        result.push({
          dropdownContent: User.isSkillupDeveloper()
            ? [
                <DSDropdownItem
                  key="import-assign"
                  label="Importer des assignations de poste"
                  onClick={() =>
                    Acta.setState("modalDisplayed", {
                      content: <UploadAssignmentsModal />,
                      size: "auto",
                      title: "Import d'assignations de poste",
                    })
                  }
                />,
              ]
            : undefined,
        });
      }
    }

    return result;
  }, [
    exportSkillTemplate,
    exportAllSkills,
    exportAllJobs,
    exportAllEmployees,
    history,
    manageJobsCustomFields.show,
    manageSkillsCustomFields.show,
    manageSkillsImport.show,
    manageEmployeesJobsImport.show,
    manageJobsImport.show,
    pathname,
    settings,
    t,
    userAccessModules,
    handleRepublishJobs,
    republishJobMutator.isLoading,
    handleRepublishSkills,
    republishSkillsMutator.isLoading,
    handleRepublishArchivedJobs,
    republishArchivedJobsMutator.isLoading,
  ]);

  const selectedTab = useMemo(() => {
    return layouts.length > 0 ? layouts.find((e) => e?.tab?.url === pathname)?.tab.id : null;
  }, [layouts, pathname]);

  return (
    <DSLayout
      layouts={layouts}
      activeLayout={selectedTab}
      title={t("skills.repository.layout.name", {
        defaultValue: "Référentiels",
      })}
    >
      <div className={styles.Skills}>
        <div className={styles.skillContainer}>
          <ManageSkillsCustomFieldsModal
            isOpen={manageSkillsCustomFields.isOpen}
            closeModal={manageSkillsCustomFields.hide}
          />

          <ManageJobsCustomFieldsModal
            isOpen={manageJobsCustomFields.isOpen}
            closeModal={manageJobsCustomFields.hide}
          />

          <UploadSkillsModal close={manageSkillsImport.hide} open={manageSkillsImport.isOpen} />

          <JobsUploadModal close={manageJobsImport.hide} open={manageJobsImport.isOpen} />

          <UploadEmployeesJobsModal
            close={manageEmployeesJobsImport.hide}
            open={manageEmployeesJobsImport.isOpen}
          />

          <Switch>
            <Route exact path="/responsable/referentiels/fiches-de-poste-v1">
              <JobsDescription />
            </Route>

            <Route exact path="/responsable/referentiels/fiches-de-poste">
              <Jobs />
            </Route>

            <Route exact path="/responsable/referentiels/competences-v1">
              <SkillsList />
            </Route>

            <Route exact path="/responsable/referentiels/competences">
              <ListSkills />
            </Route>

            <Route exact path="/responsable/referentiels/collaborators-v1">
              <Employees />
            </Route>

            <Route exact path="/responsable/referentiels/collaborators">
              <Assignments />
            </Route>
          </Switch>
        </div>
      </div>
    </DSLayout>
  );
};

export default Skills;
