import useTranslation, { TFunction, TranslationType } from "hooks/useTranslation";
import { uniqBy } from "lodash";
import { useMemo } from "react";

import { renderCellDate } from "@skillup/shared-utils";
import { InterviewWithStates } from "@skillup/types";
import { Column, ColumnType } from "@skillup/ui";
import { InterviewStatus } from "services/interviews/components";
import { interviewStatusKeyToValue } from "utils/InterviewStatusKeyToValue";

function useTableData(
  interviews: Array<InterviewWithStates>,
  campaignType: "ongoing" | "legacy" | "adHoc"
): {
  data: Array<InterviewRow>;
  columns: Array<any>;
} {
  const { t } = useTranslation();
  const tableData = useMemo(
    () => ({
      columns: generateColumns(interviews, campaignType, t),
      data: generateTableRows(interviews ?? [], t),
    }),
    [interviews, campaignType, t]
  );

  return tableData;
}

export default useTableData;

const generateColumns = (
  interviews: InterviewWithStates[],
  campaignType: "ongoing" | "legacy" | "adHoc",
  t: TFunction
) => {
  const statusManagerOptions = uniqBy(interviews, (i) => {
    return i.manager.state;
  })
    .map((e) => ({
      value: e.manager.state,
      label: interviewStatusKeyToValue(t, e.manager.state).shortValue,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const statusCollaboratorOptions = uniqBy(interviews, (i) => {
    return interviewStatusKeyToValue(t, i.employee.state).shortValue;
  })
    .map((e) => ({
      value: e.employee.state,
      label: interviewStatusKeyToValue(t, e.employee.state).shortValue,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const perimeterOptions = uniqBy(interviews, (i) => {
    return i.employee.userAreasNames[0];
  })
    .map((i) => ({
      value: i.employee.userAreasNames[0],
      label: i.employee.userAreasNames[0],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const columns: Array<Column<InterviewRow>> = [
    {
      key: "collaborator",
      title: t("manager.interviews.columns.collaborator", {
        defaultValue: "Collaborateur",
      }),
      minWidth: 16,
    },
    {
      key: "collaboratorStatus",
      title: t("manager.interviews.columns.collaboratorStatus", {
        defaultValue: "Statut collaborateur",
      }),
      type: ColumnType.SEARCH_SELECT,
      width: 13.125,
      filterable: true,
      options: statusCollaboratorOptions,
      renderCell: ({ collaboratorStatus }) => (
        <InterviewStatus userInterviewStatus={collaboratorStatus} />
      ),
    },
    {
      key: "manager",
      title: t("manager.interviews.columns.manager", {
        defaultValue: "Responsable de l’entretien",
      }),
      minWidth: 16,
    },
    {
      key: "managerStatus",
      title: t("manager.interviews.columns.managerStatus", {
        defaultValue: "Statut responsable",
      }),
      type: ColumnType.SEARCH_SELECT,
      width: 13.125,
      filterable: true,
      options: statusManagerOptions,
      renderCell: ({ managerStatus }) => <InterviewStatus userInterviewStatus={managerStatus} />,
    },
    ...(campaignType !== "legacy"
      ? [
          {
            key: "startDate",
            title: t("interview.columns.startDate", {
              defaultValue: "Date de début",
            }),
            type: ColumnType.DATE,
            width: 14.375,
            sortable: true,
            sortFn: (a: InterviewRow, b: InterviewRow) => {
              const dateA = a.data.startDate;
              const dateB = b.data.startDate;
              return +dateA - +dateB;
            },
            renderCell: ({ startDate }) => renderCellDate(startDate),
          },
        ]
      : []),
    {
      key: "endDate",
      title: t("campaign.campaignSignDate", {
        defaultValue: "Date limite de signature",
      }),
      type: ColumnType.DATE,
      width: 14.375,
      sortable: true,
      sortFn: (a: InterviewRow, b: InterviewRow) => {
        const dateA = a.data.endDate;
        const dateB = b.data.endDate;
        return +dateA - +dateB;
      },
      renderCell: ({ endDate }) => renderCellDate(endDate),
    },
    {
      key: "collaboratorPerimeter",
      title: t("campaign.table.collaboratorPerimeter", {
        defaultValue: "Périmètre collaborateur",
      }),
      options: perimeterOptions,
      type: ColumnType.SEARCH_SELECT,
      width: 14.375,
    },
  ];

  return columns;
};

function generateTableRows(
  interviews: InterviewWithStates[],
  t: TranslationType
): Array<InterviewRow> {
  return interviews?.map((interview) => parseCollaboratorIntoRow(interview, t));
}

function parseCollaboratorIntoRow(interview: InterviewWithStates, t: TranslationType) {
  return {
    id: interview.uuid,
    data: {
      collaborator: interview.employee.fullName,
      collaboratorStatus: interview.employee.state,
      collaboratorPerimeter: interview.employee.userAreasNames[0],
      manager: interview.manager.fullName,
      managerStatus: interview.manager.state,
      startDate: new Date(interview.startDate),
      endDate: new Date(interview.endDate),
    },
  };
}

export type InterviewRow = ReturnType<typeof parseCollaboratorIntoRow>;
