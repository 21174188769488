import { Dispatch, SetStateAction } from "react";

import { Flex, Text } from "@skillup/design-system";
import { Label, Status, DSButton, StatusType, DSSimpleTextArea } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { StyledActionTitle } from "./Action.styled";
import { SelectedAction } from "../../AssignActionsModal";

import styles from "./Action.module.scss";

interface ActionProps {
  isReadonly: boolean;
  actions: SelectedAction[];
  selectedAction: SelectedAction;
  labelsMap: { [key: string]: string };
  handleRemove: (SelectedAction) => void;
  campaignActions: { id: string; label: string }[];
  setActions: Dispatch<SetStateAction<SelectedAction[]>>;
}

export const Action = ({
  actions,
  handleRemove,
  isReadonly,
  labelsMap,
  selectedAction,
  setActions,
}: ActionProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      padding="s"
      flexDirection="column"
      className={styles.Action}
      key={selectedAction.actionID}
    >
      <Flex flexDirection="column">
        {selectedAction.actionID.includes("random_skillup_action_") ? (
          <>
            <Label
              required
              className={styles.Action__Label}
              label={t("peopleReview.talentGrid.actionType", { defaultValue: "Type d'action" })}
            />
            <Text>{labelsMap[selectedAction.actionID]}</Text>
          </>
        ) : (
          <Flex justifyContent="space-between">
            <StyledActionTitle espaceFont="h4">
              {labelsMap[selectedAction.actionID]}
            </StyledActionTitle>

            {(selectedAction.origin === "MANAGER" || !!selectedAction.managerComment) && (
              <div>
                <Status
                  type={StatusType.SUCCESS}
                  label={t("peopleReview.talentGrid.managerProposition", {
                    defaultValue: "Proposition manager",
                  })}
                />
              </div>
            )}
          </Flex>
        )}
      </Flex>

      {!!selectedAction.managerComment && (
        <Flex marginBottom="xs" flexDirection="column">
          <Label
            className={styles.Action__Label}
            label={t("peopleReview.talentGrid.managerComment", {
              defaultValue: "Commentaire manager",
            })}
          />
          <Text espaceFont="body2Regular" color="plainText-onLight-default">
            {selectedAction.managerComment
              ? selectedAction.managerComment
              : t("peopleReview.talentGrid.noComment", { defaultValue: "Aucun commentaire." })}
          </Text>
        </Flex>
      )}

      <Flex flexDirection="column">
        <Label
          className={styles.Action__Label}
          label={t("peopleReview.talentGrid.coordinatorComment", {
            defaultValue: "Commentaire coordinateur",
          })}
        />
        <DSSimpleTextArea
          disabled={isReadonly}
          defaultValue={selectedAction.coordinatorComment ?? ""}
          onChange={(newValue) => {
            setActions(
              actions.map((s) => {
                if (s.actionID === selectedAction.actionID) {
                  return {
                    ...s,
                    coordinatorComment: newValue,
                  };
                }
                return s;
              })
            );
          }}
        />
      </Flex>

      {!isReadonly && (
        <Flex flexDirection="row" justifyContent="flex-end">
          <DSButton
            emphasis="Low"
            buttonSize="S"
            onClick={() => handleRemove(selectedAction)}
            label={t("peopleReview.talentGrid.deleteAction", {
              defaultValue: "Supprimer l'action",
            })}
          />
        </Flex>
      )}
    </Flex>
  );
};
