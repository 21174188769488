import React, { PureComponent } from "react";
import { match } from "react-router-dom";

import { IWithRouter } from "utils/types";
import TrainingsResults from "containers/TrainingsResults";
import { TrainingRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

interface IProps extends IWithRouter {
  readonly match: match<{ slug?: string }>;
}

interface IState {
  formationsData?: object;
}

export default class TrainingsCategory extends PureComponent<IProps, IState> {
  public state: IState = {};

  public componentDidMount(): void {
    this.loadData();
  }

  public componentDidUpdate(prevProps: IWithRouter): void {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.loadData();
    }
  }

  private loadData = async (): Promise<void> => {
    const { location, match } = this.props;
    const request = buildRequest<TrainingRoutes.Search.SearchBySlug>({
      method: "GET",
      path: "/training/search-by-slug/{userScope}/{slug}",
      params: {
        slug: `formations-${match.params.slug}${location.search || ""}`,
        userScope: "supervisor",
      },
    });
    const formationsData = await request();
    this.setState({
      formationsData,
    });
  };

  public render(): JSX.Element {
    const { formationsData } = this.state as IState;
    // @ts-ignore
    return <TrainingsResults {...this.props} formationsData={formationsData} />;
  }
}
