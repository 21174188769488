import { useCallback, useState, useMemo } from "react";
import { useToasts } from "react-toast-notifications";

import { DSFormGroupTextInput, DatePicker, DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { ParseDate, FormatDate } from "@skillup/shared-utils";

import { formatError } from "services/errors";
import type { TrackingRoutes } from "types/api";
import useTranslation from "hooks/useTranslation";
import { buildSafeRequest } from "utils/buildRequest";

import { Tracking } from "../../../state/tracking";

import styles from "./UpdateValidityModal.module.scss";

export interface Props {
  tracking: Tracking;
  onClose: () => void;
}

enum ModalState {
  Loading,
  Idle,
}

const UpdateValidityModal = ({ tracking, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [date, setDate] = useState<string>(
    tracking.validSince == null
      ? undefined
      : FormatDate.ToStringFormat(ParseDate.FromISO(tracking.validSince), "yyyy-MM-dd")
  );
  const [state, setState] = useState<ModalState>(ModalState.Idle);

  const title = useMemo(() => {
    return tracking.validSince == null
      ? t("trainings.view.regulatory_collaborator_tracking.modal.add_validity_date", {
          defaultValue: "Ajouter une date de validité",
        })
      : t("trainings.view.regulatory_collaborator_tracking.modal.modify_validity_date", {
          defaultValue: "Modifier la date de validité",
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking]);

  const handleSubmit = useCallback(async () => {
    setState(ModalState.Loading);
    const request = await buildSafeRequest<TrackingRoutes.UpdateValidity>({
      method: "POST",
      params: { trackingUuid: tracking.uuid },
      payload: { validityDate: date },
      path: "/tracking/{trackingUuid}/update-validity",
    });

    await Future.unwrap(
      request.run(),
      (error) => handleError(addToast, t, error, setState),
      () => handleSuccess(addToast, t, onClose)
    );
  }, [tracking, date, addToast, t, onClose]);

  return (
    <Modal size="big" title={title} onClose={onClose} disableOnClickAway>
      <div className={styles.updateValidityModal}>
        <DSFormGroupTextInput
          label={t("trainings.view.regulatory_collaborator_tracking.modal.validity_date", {
            defaultValue: "Date de validité",
          })}
        >
          <DatePicker
            value={date && new Date(date)}
            onChange={(value) => setDate(value?.toISOString())}
            autoFormat
            autoFocus
          />
        </DSFormGroupTextInput>

        <div className={styles.actions}>
          <DSButton
            label={t("common.action.cancel", {
              defaultValue: "Annuler",
            })}
            emphasis="Low"
            onClick={onClose}
          />
          <DSButton
            loading={state === ModalState.Loading}
            disabled={date == null}
            label={t("common.action.confirm", {
              defaultValue: "Confirmer",
            })}
            emphasis="High"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateValidityModal;

function handleSuccess(addToast, t, onClose: () => void) {
  addToast(
    t("tracking.updateValidity.success", {
      defaultValue: "La date de validité a bien été mise à jour.",
    }),
    {
      appearance: "success",
      autoDismiss: true,
    }
  );
  onClose();
}

function handleError(addToast, t, error: string, setState) {
  addToast(
    formatError(t, error, {
      defaultValue:
        "Une erreur est survenue lors de l'enregistrement de la nouvelle date de validité.",
    }),
    {
      appearance: "error",
      autoDismiss: true,
    }
  );

  setState(ModalState.Idle);
}
