import React from "react";

import Acta from "utils/Acta";
import useTranslation from "hooks/useTranslation";

import Colors from "uiAssets/Colors";
import { trashBin as trashBinIcon, cross as crossIcon } from "uiAssets/StrokeIcons";
import InteractiveButton from "components/InteractiveButton";

export interface IProps {
  onDelete: () => void;
}

export const DeleteFileModal = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }}>
      <p
        style={{
          color: "#333",
          padding: "20px 20px 0",
        }}
      >
        {t("trainings.view.delete_file_modal.description", {
          defaultValue: "Attention, cette action est irréversible.",
        })}
      </p>
      <InteractiveButton
        background={Colors.gradientRed}
        color="#fff"
        label={t("common.action.delete", {
          defaultValue: "supprimer",
        })}
        onClick={() => props.onDelete()}
        strokeIconLeft={trashBinIcon}
        style={{ margin: 0, display: "inline-block" }}
      />
      <InteractiveButton
        label={t("common.action.cancel", {
          defaultValue: "annuler",
        })}
        onClick={() => Acta.dispatchEvent("closeModal")}
        strokeIconLeft={crossIcon}
        style={{ margin: 20, display: "inline-block" }}
      />
    </div>
  );
};
