import { useCallback, useEffect, useState } from "react";
import {
  CampaignsRoutes,
  InterviewCreationRuleError,
  PossibleCodes,
} from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";
import { CampaignCreationData } from "../models/CampaignCreationData";
import { createData, createPayload } from "../requests/CreateCampain";

type FinalStepState =
  | {
      step: "checking";
    }
  | {
      step: "errors";
      errors: string[][];
      warnings: string[][];
      showErrors: boolean;
      showWarnings: boolean;
    }
  | {
      step: "canCreate";
      warnings: string[][];
      showWarnings: boolean;
    };

export const useCampaignCreationErrors = (props: CampaignCreationData) => {
  const [state, setState] = useState<FinalStepState>({
    step: "checking",
  });

  const performCheck = useCallback(async () => {
    if (!props) {
      return;
    }
    const payload = createPayload({
      interviews: props.interviews,
      confidential: props.confidential,
      data: createData({
        type: props.type,
        titleForHR: props.titleForHR,
        titleForAttendees: props.titleForAttendees,
        template: props.template,
        hideFromEmployeeUntil: props.hideFromEmployeeUntil,
        duration: props.duration,
        start: props.start,
        timezoneOffset: 0,
        mailingSettings: props.mailingSettings,
      }),
    });

    const res = await buildRequest<CampaignsRoutes.RunCheck>({
      method: "POST",
      path: "/campaigns/run-campaign-checks",
      payload,
    })();

    if (res.errors?.length) {
      setState({
        step: "errors",
        errors: parseErrors(res.errors, "Erreur"),
        warnings: parseErrors(res.warnings, "Warning"),
        showErrors: true,
        showWarnings: true,
      });
    } else {
      setState({
        step: "canCreate",
        warnings: parseErrors(res.warnings ?? [], "Warning"),
        showWarnings: true,
      });
    }
  }, [props]);

  useEffect(() => {
    if (state.step === "checking") {
      performCheck();
    }
  }, [state, performCheck]);

  return {
    step: state.step,
    errors: state.step === "errors" ? state.errors : [],
    warnings: state.step !== "checking" ? state.warnings : [],
  };
};

const parseErrors = (errors: InterviewCreationRuleError[], errorType: "Erreur" | "Warning") => {
  const mappedErrors = errors
    .map((e) => {
      switch (e.code) {
        case PossibleCodes.NoOrdinalChoices:
        case PossibleCodes.NoCategories:
        case PossibleCodes.CampaignIsArchived:
          return { Erreur: e.message, "Type d'erreur": errorType };
        case PossibleCodes.UserHasNoJobAssignement:
          return e.payload.map((e2) => ({
            "Type d'erreur": errorType,
            Erreur: e.message,
            "Adresse email": e2.email,
            Nom: e2.fullName,
          }));
      }
    })
    .flat();

  const columns = [
    { label: "Type d'erreur", width: 100 },
    { label: "Erreur", width: 300 },
    { label: "Adresse email", width: 200 },
    { label: "Nom", width: 250 },
  ];

  const data: string[][] = [
    columns.map((column) => column.label),
    ...mappedErrors.map((error) => columns.map((column) => error[column.label])),
  ];
  return data;
};
