import { DSButton } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { Template, useTemplate } from "services/peopleReview";

import styles from "./ArchiveTemplateButton.module.scss";

function ArchiveTemplateButton({ template }: { template: Template }) {
  const { t } = useTranslation();
  const { mutations } = useTemplate(template.uuid);

  return (
    <DSButton
      buttonSize="S"
      emphasis="Mid"
      loading={mutations.unarchive.isLoading}
      className={styles.modalArchivTemplateBtn}
      onClick={() => {
        mutations.unarchive.mutate();
      }}
      label={t("supervisor.people-review-management.templates.archivedModal.buttonUnarchive", {
        defaultValue: "Désarchiver",
      })}
    />
  );
}

export default ArchiveTemplateButton;
