import { useFormik } from "formik";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Text, Flex } from "@skillup/design-system";
import { Label, DSModal, DSSimpleTextArea } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { updateFieldsDataForEmployee } from "services/peopleReview/field/updateFieldsDataForEmployee";

import { Review, Campaign } from "../../types";

import styles from "./CommentModal.module.scss";

type CommentModalProps = {
  review: Review;
  campaign: Campaign;
  onClose: () => void;
};

export function CommentModal({ campaign, onClose, review }: CommentModalProps) {
  const isReadonly = !campaign.permissions["review"].isEnabled;

  if (isReadonly)
    return <ReadOnlyCommentModal review={review} campaign={campaign} onClose={onClose} />;

  return <EditCommentModal review={review} campaign={campaign} onClose={onClose} />;
}

type ReadOnlyCommentModalProps = Omit<CommentModalProps, "isReadonly">;

const ReadOnlyCommentModal = ({ campaign, onClose, review }: ReadOnlyCommentModalProps) => {
  const { t } = useTranslation();

  const campaignNotStarted = campaign.state === "not_started";

  return (
    <DSModal isOpen className={styles.editCommentModal}>
      <DSModal.Header onClose={onClose}>
        {review.fullName ? (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.title", {
              defaultValue: "Commentaires pour {{fullName}}",
              fullName: review.fullName,
            })}
          />
        ) : (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.titleNoName", {
              defaultValue: "Commentaires",
            })}
          />
        )}
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        {campaignNotStarted && (
          <Text marginBottom="s" espaceFont="body1Regular">
            Aucun commentaire définie pour {review.fullName}.
          </Text>
        )}

        {campaign.hasManagerPreparation && (
          <Flex paddingBottom="s" flexDirection="column">
            <Label
              className={styles.label}
              label={t("peopleReview.talentGrid.changeCommentModal.managerLabel", {
                defaultValue: "Commentaire Manager",
              })}
            />
            {review.managerComment ? (
              <Text
                paddingVertical="xs"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {review.managerComment}
              </Text>
            ) : (
              <Text
                paddingVertical="xs"
                espaceFont="body1Italic"
                color="plainText-onLight-placeholder"
              >
                {!review.manager
                  ? t("peopleReview.talentGrid.changeCommentModal.manager.placeholderNoManager", {
                      defaultValue: "Ce collaborateur n'a pas de manager.",
                    })
                  : t("peopleReview.talentGrid.changeCommentModal.manager.placeholder", {
                      defaultValue: "{{managerFullName}} n'a pas laissé de commentaire.",
                      managerFullName: review.manager,
                    })}
              </Text>
            )}
          </Flex>
        )}
        <Flex paddingBottom="s" flexDirection="column">
          <Label
            className={styles.label}
            label={t("peopleReview.talentGrid.changeCommentModal.coordinatorlabel", {
              defaultValue: "Commentaire Coordinateur",
            })}
          />
          {review.coordinatorComment ? (
            <Text paddingVertical="xs" espaceFont="body1Regular" color="plainText-onLight-default">
              {review.coordinatorComment}
            </Text>
          ) : (
            <Text
              paddingVertical="xs"
              espaceFont="body1Italic"
              color="plainText-onLight-placeholder"
            >
              {t("peopleReview.talentGrid.changeCommentModal.coordinator.placeholderNoManager", {
                defaultValue: "Aucun commentaire attribué à ce collaborateur.",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>

      <DSModal.FooterInfo>
        <DSModal.FooterInfo.CancelButton
          label={t("common.close", { defaultValue: "Fermer" })}
          onClick={onClose}
        />
      </DSModal.FooterInfo>
    </DSModal>
  );
};

type EditCommentModalProps = Omit<CommentModalProps, "isReadonly">;

const EditCommentModal = ({ campaign, onClose, review }: EditCommentModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateFieldsDataForEmployee, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      onClose();
    },
  });

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      coordinatorComment: review.coordinatorComment || "",
    },
    onSubmit: (values) => {
      mutate({
        campaignId: campaign.id,
        comment: values.coordinatorComment,
        data: [],
        reviewID: review.id,
      });
    },
  });

  return (
    <DSModal isOpen className={styles.editCommentModal}>
      <DSModal.Header onClose={onClose}>
        {review.fullName ? (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.title", {
              defaultValue: "Commentaires pour {{fullName}}",
              fullName: review.fullName,
            })}
          />
        ) : (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.titleNoName", {
              defaultValue: "Commentaires",
            })}
          />
        )}
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        {campaign.hasManagerPreparation && (
          <Flex paddingBottom="s" flexDirection="column">
            <Label
              className={styles.label}
              label={t("peopleReview.talentGrid.changeCommentModal.managerLabel", {
                defaultValue: "Commentaire Manager",
              })}
            />
            {review.managerComment ? (
              <Text
                paddingVertical="xs"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {review.managerComment}
              </Text>
            ) : (
              <Text
                paddingVertical="xs"
                espaceFont="body1Italic"
                color="plainText-onLight-placeholder"
              >
                {!review.manager
                  ? t("peopleReview.talentGrid.changeCommentModal.manager.placeholderNoManager", {
                      defaultValue: "Ce collaborateur n'a pas de manager.",
                    })
                  : t("peopleReview.talentGrid.changeCommentModal.manager.placeholder", {
                      defaultValue: "{{managerFullName}} n'a pas laissé de commentaire.",
                      managerFullName: review.manager,
                    })}
              </Text>
            )}
          </Flex>
        )}
        <Flex paddingBottom="s" flexDirection="column">
          <Label
            className={styles.label}
            label={t("peopleReview.talentGrid.changeCommentModal.coordinatorlabel", {
              defaultValue: "Commentaire Coordinateur",
            })}
          />
          <DSSimpleTextArea
            rows={3}
            id="coordinatorComment"
            name="coordinatorComment"
            className={styles.textArea}
            defaultValue={values.coordinatorComment}
            onChange={(val, e) => handleChange(e)}
            placeholder={t("peopleReview.talentGrid.changeCommentModal.coordinator.placeholder", {
              defaultValue: "Saisissez un commentaire",
            })}
          />
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.talentGrid.changeCommentModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          type="submit"
          disabled={values.coordinatorComment === review.coordinatorComment}
          onClick={() => handleSubmit()}
          label={t("peopleReview.talentGrid.changeCommentModal.confirm", {
            defaultValue: "Confirmer le changement",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
