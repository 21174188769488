import React from "react";

import { MdInfoOutline as InfoOutline } from "react-icons/md";
import { getIn, Field, type FieldProps, ErrorMessage } from "formik";

import { DSTextInput } from "@skillup/ui";

import { Flex } from "../Flex";
import { Text } from "../Text";

interface Props {
  readonly name: string;
  readonly value?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly placeholder?: string;
  readonly defaultValue?: string;
}

export const FormikInputText = ({
  className,
  defaultValue,
  disabled,
  name,
  placeholder,
  value,
}: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <DSTextInput
            value={value}
            name={field.name}
            disabled={disabled}
            className={className}
            placeholder={placeholder}
            defaultValue={defaultValue}
            error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
            onBlur={form.handleBlur}
            onChange={(_, e) => form.handleChange(e)}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
            <Flex gap="xs" alignItems="center">
              <InfoOutline />
              {msg}
            </Flex>
          </Text>
        )}
      />
    </>
  );
};
