import React from "react";

import { FieldRoutesType } from "@skillup/espace-rh-bridge";
import { useQuery } from "@tanstack/react-query";

import useAreas from "hooks/useAreas";

import SupervisorTrainingSchedule from "./SupervisorTrainingSchedule";
import { buildRequest } from "utils/buildRequest";
import Loader from "components/Loader";

const useCompanyFields = () =>
  useQuery(
    ["trainings_company_fields"],
    async () => {
      try {
        const companyFields = await buildRequest<FieldRoutesType.GetV2>({
          method: "GET",
          path: "/fields-v2",
          target: "API",
          query: {},
        })();

        return companyFields;
      } catch (e) {
        console.error(e);

        return undefined;
      }
    },
    { refetchInterval: 60000 }
  );

export default () => {
  const areas = useAreas();
  const { data: companyFields, isLoading } = useCompanyFields();

  if (isLoading || !companyFields) {
    return <Loader />;
  }

  return <SupervisorTrainingSchedule areas={areas} fields={companyFields} />;
};
