import { Status } from "@skillup/ui";
import {
  getStatusValueToStatusType,
  interviewStatusKeyToValue,
} from "utils/InterviewStatusKeyToValue";
import { InterviewState } from "@skillup/espace-rh-bridge";
import useTranslation from "hooks/useTranslation";

export const InterviewStatus = ({
  userInterviewStatus,
}: {
  userInterviewStatus: InterviewState;
}) => {
  const { t } = useTranslation();

  return (
    <Status
      label={interviewStatusKeyToValue(t, userInterviewStatus).shortValue}
      type={getStatusValueToStatusType(userInterviewStatus)}
    />
  );
};
