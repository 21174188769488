import { useState } from "react";
import { atom, useRecoilState } from "recoil";

import { DataTable, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";

import User from "utils/User";
import useTranslation from "hooks/useTranslation";

import { useFilteredTrackings } from "../state/tracking";

import UserAreaSelect from "../components/UserAreaSelect/UserAreaSelect";
import TrackingSidePanel from "../components/TrackingSidePanel/TrackingSidePanel";

import {
  useSidepanel,
  useTrackingsModals,
  useTrackingsTableData,
  useTrackingsActions,
} from "./hooks";
import { TrackingRow } from "./hooks/useTrackingsTableData";
import { TrackingAction, ModalStates } from "./hooks/useTrackingActions";

import Reglementaire from "../Reglementaire";
import StatusFilter from "./components/StatusFilter";

import styles from "./Trackings.module.scss";

const Trackings = () => {
  const { t } = useTranslation();

  const { data, refetch } = useFilteredTrackings();
  const [{ state: actionsState, getActionLabel, getNewStateFromAction }, setTrackingsActions] =
    useTrackingsActions();
  const {
    state: { tracking: sidePanelTracking },
    toggle: toggleSidePanel,
    isOpen: isSidePanelOpen,
  } = useSidepanel();
  const activeSchedule = User.getActiveSchedule();
  const [filters, setFilters] = useState({});

  const modalToDisplay = useTrackingsModals(
    actionsState,
    setTrackingsActions,
    data?.trackings ?? [],
    refetch
  );

  const openActionModal = (trackingUuid: string, action: TrackingAction): void => {
    setTrackingsActions(getNewStateFromAction({ action, trackingUuid }));
  };

  const [page, setPage] = useState(0);
  const { rows, columns } = useTrackingsTableData(data?.trackings ?? [], openActionModal, t);
  const paginationHook = useRecoilState(trackingsPagination);

  return (
    <Reglementaire
      actions={{
        label: t("trainings.view.regulatory_collaborator_tracking.action.add", {
          defaultValue: "Ajouter une ligne de suivi",
        }),
        onClick: () =>
          setTrackingsActions({
            state: ModalStates.Creating,
          }),
      }}
    >
      <div className={styles.tracking}>
        <div className={styles.globalFilters}>
          <StatusFilter t={t} />
          <UserAreaSelect />
        </div>
        <div className={styles.deleteList}>
          {rows && (
            <DataTable<TrackingRow>
              containerClassName={styles.Table}
              rows={rows}
              columns={columns}
              filters={{
                activeFilters: filters,
                onFiltersChange: setFilters,
              }}
              defaultSort={{ key: "status", desc: false }}
              onClickRow={(row) =>
                toggleSidePanel({
                  newSelection: data.trackings.find((t) => t.uuid === row.id),
                })
              }
              actions={(row) => {
                const actions = row.data.actions.map((action) => (
                  <DSDropdownItem
                    key={action.type}
                    label={getActionLabel(action, t)}
                    disabled={action.disabled}
                    onClick={() => {
                      openActionModal(row.id, action);
                    }}
                    tooltipLabel={action.tooltip}
                    tooltipDirection="top"
                  />
                ));
                const url =
                  activeSchedule &&
                  `/responsable/${activeSchedule.uuid}/plan-de-formation/plan-all?traineeFullName=${row.data.user}`;

                const goToButton = url && (
                  <DSDropdownItem
                    key={row.id}
                    label={t(
                      "trainings.view.regulatory_collaborator_tracking.action.go_to_user_plan",
                      {
                        defaultValue: "Voir dans le plan les lignes de {{ user }}",
                        user: row.data.user,
                      }
                    )}
                    onClick={() => {
                      const win = window.open(url, "_blank");
                      win.focus();
                    }}
                  />
                );
                return actions.concat([<DSDropdownDivider key={"divider"} />, goToButton]);
              }}
              header={{
                selectedRowsLabel: (selected, total) =>
                  t("trainings.view.regulatory_collaborator_tracking.table.filtered_count", {
                    defaultValue: "{{count}} ligne%s de suivi sélectionnée%s",
                    count: total,
                  }),
                totalRowsLabel: (total) => {
                  if (total < data?.trackings?.length) {
                    return t(
                      "trainings.view.regulatory_collaborator_tracking.table.filtered_count",
                      {
                        defaultValue: "{{count}} lignes de suivi filtrées sur {{total}}",
                        count: total,
                        total: data?.trackings.length,
                      }
                    );
                  }

                  return t("trainings.view.regulatory_collaborator_tracking.table.lines_count", {
                    defaultValue: "{{count}} lignes de suivi",
                    count: total,
                  });
                },
              }}
              pagination={{
                rowsPerPageLabel: t("trainings.view.regulatory.table.row_per_page", {
                  defaultValue: "Lignes par page",
                }),
                itemsCountLabel: t(
                  "trainings.view.regulatory_collaborator_tracking.table.item_count",
                  {
                    defaultValue: "Lignes de suivi %range% sur %count%",
                  }
                ),
                pageLabel: t("common.view.table.page", {
                  defaultValue: "Page",
                }),
                stateHook: paginationHook,
                activeTablePage: page,
                onPageChange: setPage,
              }}
              mode="compact"
            />
          )}
        </div>
      </div>

      {isSidePanelOpen && (
        <TrackingSidePanel
          tracking={sidePanelTracking}
          onClose={() => toggleSidePanel({ newSelection: undefined })}
          refetchTrackings={refetch}
          openActionModal={openActionModal}
          getActionLabel={(action) => getActionLabel(action, t)}
        />
      )}

      {modalToDisplay}
    </Reglementaire>
  );
};

export default Trackings;

const trackingsPagination = atom<number>({
  key: "trackings.pagination",
  default: 10,
});
