import { useMemo } from "react";

import PortalCategories from "../../PortalCategories";
import AdminPortalToggle, { PortalToggleType } from "../AdminPortalToggle/AdminPortalToggle";
import useAdminProperties, { saveChanges } from "../state/useAdminProperties";

import styles from "./PortalContent.module.scss";
import useTranslation from "hooks/useTranslation";

export default function PortalContentCategories() {
  const { data, refetch } = useAdminProperties();
  const { t } = useTranslation();

  const adminProperties = useMemo(() => {
    return data?.properties ?? null;
  }, [data]);

  const handleOnChange = async (body) => {
    await saveChanges(body);
    await refetch();
  };

  return (
    <div id="section-categories" className={styles.contentToggles}>
      <h3 className={styles.title}>
        {t("trainings.view.portal_configuration.categories.title", {
          defaultValue: "Catégories",
        })}
      </h3>
      <div className={styles.switchList}>
        <AdminPortalToggle
          propertiesKey="portalTagsDisabled"
          label={t("trainings.view.portal_configuration.categories.action.enable", {
            defaultValue: "Activer les catégories",
          })}
          type={PortalToggleType.DISABLED}
          isSelected={adminProperties ? adminProperties["portalTagsDisabled"] : null}
          onChange={handleOnChange}
        />
      </div>
      <span className={styles.description}>
        {t("trainings.view.portal_configuration.categories.description", {
          defaultValue:
            "Ajouter les catégories que vous souhaitez afficher dans le portail formation de vos collaborateurs.",
        })}
      </span>
      <PortalCategories t={t} />
    </div>
  );
}
