import React, { useEffect, useRef } from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";

import styles from "./SkillsList.module.scss";
import { SkillItem } from "./components/SkillItem";

interface SkillsProps {
  readonly name: string;
  readonly skillUuid: string;
  readonly level?: number;
  readonly totalLevel: Array<{ level: number; name: string }>;
  readonly generated: boolean;
}

interface CategoryProps {
  readonly name: string;
  readonly skills: SkillsProps[];
}

export interface SkillsListProps {
  readonly anchor?: string;
  readonly allCategories: CategoryProps[];
  readonly categoriesDisplayed: CategoryProps[];
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly onEdit: (skillUuid: string) => void;
  readonly onRemove: (skillUuid: string) => void;
}

const SkillsList = ({
  anchor,
  allCategories,
  categoriesDisplayed,
  theme,
  darkMode,
  className,
  onEdit,
  onRemove,
}: SkillsListProps) => {
  const refs = useRef(
    allCategories.reduce((acc, category) => {
      category.skills.forEach((skill) => {
        acc[skill.skillUuid] = React.createRef();
      });
      return acc;
    }, {})
  );

  useEffect(() => {
    if (anchor && refs.current[anchor] && refs.current[anchor].current) {
      const element = refs.current[anchor].current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [anchor]);

  return (
    <div
      className={cx(
        styles.SkillsList,
        styles[theme],
        {
          [styles.darkMode]: darkMode,
        },
        className
      )}
    >
      {categoriesDisplayed.map((category, i) => {
        return (
          <div key={i}>
            <div className={styles.categoryName}>{category.name}</div>
            <div
              className={cx(styles.categoriesContainer, {
                [styles.generatedCategory]: category.skills[0].generated,
              })}
            >
              {category.skills.map((skill) => {
                return (
                  <span key={skill.skillUuid} ref={refs.current[skill.skillUuid]}>
                    <SkillItem
                      skill={skill}
                      key={skill.skillUuid}
                      onEdit={onEdit}
                      onRemove={onRemove}
                      anchor={anchor}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { SkillsList };
