import { useQuery } from "@tanstack/react-query";

import { DevMonitoringRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

async function getNotificationsMonitoring(props: { campaignID: string }) {
  const result = await buildRequest<DevMonitoringRoutes.GetCampaignNotifications>({
    method: "GET",
    params: { campaignID: props.campaignID },
    path: `/monitoring/dev/notifications/{campaignID}`,
    target: "PEOPLE_REVIEW",
  })();

  return result;
}

function useNotifications(props: { campaignID?: string }) {
  const query = useQuery(
    ["people-review-notifications-monitoring", props.campaignID],
    async () => {
      if (!props.campaignID) {
        return { notifications: [] };
      }

      return getNotificationsMonitoring({ campaignID: props.campaignID });
    },
    {
      ...defaultOptions,
    }
  );

  return {
    isError: query.isError,
    isLoading: query.isLoading,
    notifications: query.data?.notifications,
  };
}

export const Monitoring = {
  useNotifications,
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};
