import { TranslationType } from "hooks/useTranslation";
import { Page } from "../types";

export const getPageErrors = (page: Page, t: TranslationType) => {
  return !page.title
    ? [
        {
          structure: t("interviews.templateBuilder.errors.page.structure", {
            defaultValue: "Vous n'avez pas défini de libellé",
          }),
          header: t("interviews.templateBuilder.errors.page.header", {
            defaultValue: "Sous-section sans libellé",
          }),
        },
      ]
    : undefined;
};
