import {
  type FilterConfiguration,
  type FilterConfigurationMap,
  FilterType,
  type FilterTypes,
  type FilterValue,
} from "./types";

export abstract class IFilterManager<Result, Config extends FilterConfigurationMap> {
  constructor(protected readonly filterConfig: Config) {}

  abstract buildTextFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.TEXT>,
    filterValue: FilterValue<typeof FilterType.TEXT>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result;

  abstract buildMultiSelectFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.MULTISELECT>,
    filterValue: FilterValue<typeof FilterType.MULTISELECT>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result;

  abstract buildSingleSelectFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.SINGLESELECT>,
    filterValue: FilterValue<typeof FilterType.SINGLESELECT>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result;

  abstract buildNumberFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.NUMBER>,
    filterValue: FilterValue<typeof FilterType.NUMBER>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result;

  abstract buildDateFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.DATE>,
    filterValue: FilterValue<typeof FilterType.DATE>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result;

  buildFilter<T extends FilterTypes>(
    id: string,
    filterValue: FilterValue<T>,
    isJsonField?: boolean,
    column?: any,
    jsonFieldKey?: any
  ): Result | undefined {
    if (
      !filterValue.value ||
      (Array.isArray(filterValue.value) && filterValue.value.length === 0)
    ) {
      return undefined;
    }

    const filter = this.filterConfig[id] as FilterConfiguration<T>;

    if (!filter) {
      return undefined;
    }

    // Typescript does not narrow down the type of objects so we must parse them manually
    const { type } = filter;
    switch (type) {
      case FilterType.TEXT:
        return this.buildTextFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.TEXT>,
          filterValue as FilterValue<typeof FilterType.TEXT>,
          isJsonField,
          column,
          jsonFieldKey
        );
      case FilterType.MULTISELECT:
        return this.buildMultiSelectFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.MULTISELECT>,
          filterValue as FilterValue<typeof FilterType.MULTISELECT>,
          isJsonField,
          column,
          jsonFieldKey
        );
      case FilterType.SINGLESELECT:
        return this.buildSingleSelectFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.SINGLESELECT>,
          filterValue as FilterValue<typeof FilterType.SINGLESELECT>,
          isJsonField,
          column,
          jsonFieldKey
        );
      case FilterType.NUMBER:
        const filterNumber = filterValue as FilterValue<typeof FilterType.NUMBER>;

        if (typeof filterNumber.value == "string") filterNumber.value = Number(filterNumber.value);
        if (Array.isArray(filterNumber.value)) {
          if (filterNumber.value.length !== 2)
            throw new Error(`Array value for numbers must be a tuple`);

          filterNumber.value = [Number(filterNumber.value[0]), Number(filterNumber.value[1])];
        }

        return this.buildNumberFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.NUMBER>,
          filterValue as FilterValue<typeof FilterType.NUMBER>,
          isJsonField,
          column,
          jsonFieldKey
        );
      case FilterType.DATE:
        const filterDate = filterValue as FilterValue<typeof FilterType.DATE>;

        if (typeof filterDate.value == "string") filterDate.value = new Date(filterDate.value);
        if (Array.isArray(filterDate.value)) {
          if (filterDate.value.length !== 2)
            throw new Error(`Array value for dates must be a tuple`);

          filterDate.value = [new Date(filterDate.value[0]), new Date(filterDate.value[1])];
        }

        return this.buildDateFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.DATE>,
          filterDate,
          isJsonField,
          column,
          jsonFieldKey
        );
    }

    return undefined;
  }
}
