import { useToggle } from "react-use";
import cx from "classnames";

import useTranslation from "hooks/useTranslation";
import InteractiveButton from "components/InteractiveButton";

import type { Project } from "../Actions/getProjects";
import deleteProject from "../Actions/deleteProject";

import styles from "./DeleteConfirmationModal.module.scss";

interface Props {
  project: Project;
  refetchList: VoidFunction;
}

const ListModal = ({ project, refetchList }: Props) => {
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();

  return (
    <div className={styles.ListModal}>
      <p className={cx({ [styles.centered]: true })}>
        {t("trainings.entity.session.deleteModal.confirmation", {
          defaultValue: "Êtes-vous sûr de vouloir définitivement supprimer cette session ?",
        })}
      </p>

      <div className={styles.actions}>
        <InteractiveButton
          size="small"
          name="confirmDelete"
          label={t("trainings.entity.session.deleteModal.button.confirm", {
            defaultValue: "Supprimer la session",
          })}
          style={{ margin: 0, marginRight: 15 }}
          loading={loading}
          onClick={async () => {
            await deleteProject(project, toggleLoading);
            refetchList();
          }}
        />
      </div>
    </div>
  );
};

export default ListModal;
