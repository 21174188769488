import { MdHourglassTop as HourglassTop } from "react-icons/md";
import { MdDone as Done } from "react-icons/md";
import { Dispatch, SetStateAction } from "react";

import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { DSTooltip } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { ListUtils } from "@skillup/shared-utils";
import { ICompanyField, IParsedField } from "@skillup/training-bridge";

import { TranslationType } from "hooks/useTranslation";

import ActionsCell from "../components/ActionsCell";
import { DeleteIntraModalState } from "../IntraListView";
import styles from "../IntraListView.module.scss";
import { TrainingIntra } from "../types";
import { getColTypeFromField, getFieldComparators, getFieldValue } from "./filters";

type ColumnProps = {
  t: TranslationType;
  history: any;
  setModalState: Dispatch<SetStateAction<DeleteIntraModalState>>;
  fields: IParsedField[];
};

export function generateColumns({
  t,
  history,
  setModalState,
  fields,
}: ColumnProps): GridColDef<TrainingIntra>[] {
  const fieldColumns: GridColDef<TrainingIntra>[] = fields.map((field) => ({
    field: `fieldValues.${field.binding}`,
    minWidth: 150,
    type: getColTypeFromField(field),
    headerName: t(`trainings.entity.field.property.${field.binding}.label`, {
      defaultValue: field.label,
    }),
    renderCell: (params) => {
      const value = getFieldValue(params.row.fields[field.binding]);

      return (
        <Flex className={styles.cell} flexDirection="column">
          <DSTooltip
            label={value}
            cropLongLabel={false}
            enabledOnOverflow
            tooltipClassName={styles.tooltip}
          >
            {value}
          </DSTooltip>
        </Flex>
      );
    },
  }));

  return [
    {
      field: "name",
      minWidth: 250,
      flex: 1,
      headerName: t("trainings.entity.training.property.title.label", {
        defaultValue: "Intitulé du programme",
      }),
      renderCell: ({ row: { name, reference } }) => {
        return (
          <Flex className={styles.cell} flexDirection="column">
            <DSTooltip
              label={name}
              enabledOnOverflow
              cropLongLabel={false}
              tooltipClassName={styles.tooltip}
            >
              {name}
            </DSTooltip>
            <p className={styles.subtitle}>{reference}</p>
          </Flex>
        );
      },
    },
    {
      field: "trainingOrganizationName",
      minWidth: 150,
      flex: 1,
      headerName: t("trainings.entity.training.property.organization.label", {
        defaultValue: "Organisme de formation",
      }),
      renderCell: ({ row: { trainingOrganizationName } }) => {
        return (
          <Flex className={styles.cell} flexDirection="column">
            <DSTooltip
              label={trainingOrganizationName}
              enabledOnOverflow
              cropLongLabel={false}
              tooltipClassName={styles.tooltip}
            >
              {trainingOrganizationName}
            </DSTooltip>
          </Flex>
        );
      },
    },
    {
      field: "createdAt",
      minWidth: 150,
      headerName: t("trainings.entity.training.property.created_at.label", {
        defaultValue: "Date de création",
      }),
      renderCell: ({ row: { rawCreatedAt } }) => {
        return (
          <Flex className={styles.cell} flexDirection="column">
            <DSTooltip
              label={rawCreatedAt}
              enabledOnOverflow
              cropLongLabel={false}
              tooltipClassName={styles.tooltip}
            >
              {rawCreatedAt}
            </DSTooltip>
          </Flex>
        );
      },
    },
    ...fieldColumns,
    {
      field: "stats.futureSessionsCount",
      type: "number",
      minWidth: 100,
      renderHeader: () => {
        return <HourglassTop size="1rem" />;
      },
      renderCell: ({ row: { stats: { futureSessionsCount } = {} } }) => {
        return futureSessionsCount;
      },
    },
    {
      field: "stats.doneSessionsCount",
      type: "number",
      minWidth: 100,
      renderHeader: () => {
        return <Done size="1rem" />;
      },
      renderCell: ({ row: { stats: { doneSessionsCount } = {} } }) => {
        return doneSessionsCount;
      },
    },
    {
      field: "actions",
      filterable: false,
      sortable: false,
      type: "actions",
      width: 50,
      getActions: (params: GridRowParams<TrainingIntra>) => [
        <ActionsCell {...params} history={history} setModalState={setModalState} />,
      ],
    },
  ];
}

export const initialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: "name", sort: "desc" }],
  },
  pagination: {
    paginationModel: {
      pageSize: 20,
    },
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ["actions"],
  },
};

export function displayRows(
  rows: TrainingIntra[],
  fields: ICompanyField[],
  config: ListUtils.FilterConfigurationMap,
  filterValues: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>,
  sortOptions: ListUtils.SortingProperties = { property: "name", direction: "ASC" }
): TrainingIntra[] {
  const datasetFilterManager = new ListUtils.InMemoryDataset(config, rows, {
    trainingNameOrReference: ListUtils.InMemoryDataset.StringComparator,
    name: ListUtils.InMemoryDataset.StringComparator,
    trainingOrganizationName: ListUtils.InMemoryDataset.StringComparator,
    createdAt: ListUtils.InMemoryDataset.DateComparator,
    ...getFieldComparators(fields),
  });

  return datasetFilterManager.getRows({
    filterValues,
    sortOptions,
  });
}
