import {
  AssistiveArea,
  Select,
  DSTextArea,
  DSTextInput,
  DSNumberInput,
  DSFormGroupTextInput,
} from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { Level_1_Tag } from "../utils/useTags";
import { analyzePrice } from "../utils/analyzePrice";

import styles from "./FormElements.module.scss";
import { analyzeDuration } from "../utils/analyzeDuration";

const modeDropDownOptions = [
  { label: "Présentiel", value: "presential" },
  { label: "Digital", value: "digital" },
  { label: "E-learning", value: "elearning" },
  { label: "MOOC", value: "mooc" },
  { label: "Individuel", value: "individual" },
  { label: "Blended Learning", value: "blended" },
  { label: "Distanciel", value: "distancial" },
  { label: "Classe virtuelle", value: "virtual-classroom" },
  { label: "Webinar", value: "webinar" },
  { label: "Conférence", value: "conference" },
];

export function PropertyInput({
  label,
  name,
  onChange,
  placeholder,
  value,
  disablePadding,
  required,
}: {
  label: string;
  name: string;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
  disablePadding?: boolean;
  required?: boolean;
}) {
  return (
    <div className={disablePadding ? "" : styles.input}>
      <DSFormGroupTextInput label={label} required={required}>
        <DSTextInput name={name} placeholder={placeholder} onChange={onChange} value={value} />
      </DSFormGroupTextInput>
    </div>
  );
}

export function TrainingOrganizationSelect({
  selectedTrainingOrganization,
  trainingOrganizations,
  onChange,
}: {
  selectedTrainingOrganization: string | undefined;
  trainingOrganizations: string[];
  onChange: (newValue: string | undefined) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.input}>
      <DSFormGroupTextInput
        label={t("trainings.entity.training.property.organization.label", {
          defaultValue: "Nom de l'organisme",
        })}
        required
      >
        <Select
          placeholder={t(`trainings.entity.training.property.organization.label.placeholder`, {
            defaultValue: "Ex : Orsys",
          })}
          options={trainingOrganizations.map((trainingOrganization) => ({
            label: trainingOrganization,
            value: trainingOrganization,
          }))}
          value={selectedTrainingOrganization}
          onChange={onChange}
          createIfInexistent
          clearable
        />
      </DSFormGroupTextInput>
    </div>
  );
}

export function CategorySelect({
  tags,
  onChange,
  selectedTag,
}: {
  tags: Level_1_Tag[];
  selectedTag: string | undefined;
  onChange: (newTag: Level_1_Tag) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.input}>
      <DSFormGroupTextInput
        label={t("trainings.entity.training.property.category.label", {
          defaultValue: "Catégorie",
        })}
        required
      >
        <Select
          placeholder={t(`trainings.entity.training.property.category.label.placeholder`, {
            defaultValue: "Sélectionnez une catégorie",
          })}
          options={tags.map((tag) => ({
            label: t(`trainings.entity.training.property.tag.slug.${tag.seoSlug}.label`, {
              defaultValue: tag.label,
            }),
            value: tag.value,
          }))}
          value={selectedTag}
          clearable
          onChange={(newTag) => {
            const selectedTag = tags.find((tag) => tag.value === newTag);
            onChange(selectedTag);
          }}
        />
      </DSFormGroupTextInput>
    </div>
  );
}

export function PriceInput({
  label,
  name,
  onChange,
  placeholder,
  value,
  rawValue,
  disablePadding,
}: {
  label: string;
  name: string;
  value: string;
  rawValue: number | undefined;
  placeholder?: string;
  onChange: (newValue: number | undefined) => void;
  disablePadding?: boolean;
}) {
  const { isInvalid, isInferiorToZero, isNotANumber } = analyzePrice(rawValue);
  return (
    <div className={styles.small}>
      <DSFormGroupTextInput label={label} required>
        <>
          <DSNumberInput
            name={name}
            placeholder={placeholder}
            required
            error={isInvalid}
            onChange={(newPrice) => {
              if (newPrice === "") {
                onChange(undefined);
                return;
              }
              if (newPrice.endsWith("0") && (newPrice.includes(".") || newPrice.includes(","))) {
                onChange(undefined);
                return;
              }
              onChange(parseFloat(newPrice));
            }}
            min={0}
            step={0.01}
            value={value != null ? value.toString() : undefined}
          />
          {isInferiorToZero && (
            <AssistiveArea mode="error" text="Le prix doit être supérieur ou égal à zéro." />
          )}

          {isNotANumber && <AssistiveArea mode="error" text="Le prix doit être un nombre." />}
        </>
      </DSFormGroupTextInput>
    </div>
  );
}

export function DurationInput({
  label,
  name,
  rawValue,
  onChange,
  placeholder,
  value,
  disablePadding,
}: {
  label: string;
  name: string;
  value: string;
  rawValue: number | undefined;
  placeholder?: string;
  onChange: (newValue: number | undefined) => void;
  disablePadding?: boolean;
}) {
  const { isInvalid, isInferiorToZero, isNotANumber } = analyzeDuration(rawValue);
  return (
    <div className={styles.small}>
      <DSFormGroupTextInput label={label} required>
        <>
          <DSNumberInput
            name={name}
            placeholder={placeholder}
            required
            error={isInvalid}
            onChange={(newDuration) => {
              if (newDuration === "") {
                onChange(undefined);
                return;
              }
              if (
                newDuration.endsWith("0") &&
                (newDuration.includes(".") || newDuration.includes(","))
              ) {
                onChange(undefined);
                return;
              }
              onChange(parseFloat(newDuration));
            }}
            min={0}
            step={0.1}
            value={value != null ? value.toString() : undefined}
          />
          {isInferiorToZero && (
            <AssistiveArea mode="error" text="La durée doit être supérieure ou égale à zéro." />
          )}

          {isNotANumber && <AssistiveArea mode="error" text="La durée doit être un nombre." />}
        </>
      </DSFormGroupTextInput>
    </div>
  );
}

export function ModeSelect({
  onChange,
  selectedMode,
}: {
  selectedMode: string | undefined;
  onChange: (newMode: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.input}>
      <DSFormGroupTextInput
        label={t("trainings.entity.training.property.mode.label", { defaultValue: "Mode" })}
        required
      >
        <Select
          placeholder={t(`trainings.entity.training.property.mode.label.placeholder`, {
            defaultValue: "Sélectionnez un mode",
          })}
          options={modeDropDownOptions.map((mode) => ({
            label: t(`trainings.entity.training.property.mode.${mode.value}.label`, {
              defaultValue: mode.label,
            }),
            value: mode.value,
          }))}
          value={selectedMode}
          clearable
          onChange={onChange}
        />
      </DSFormGroupTextInput>
    </div>
  );
}

export function PropertyWYSIWYG({
  label,
  name,
  onChange,
  value,
}: {
  label: string;
  name: string;
  value: string;
  onChange: (newValue: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.input}>
      <DSFormGroupTextInput label={label}>
        <DSTextArea
          placeholder={t("trainings.entity.training.property.wysiwyg.label.placeholder", {
            defaultValue: "Écrivez le contenu désiré",
          })}
          id={name}
          value={value}
          onChange={onChange}
        />
      </DSFormGroupTextInput>
    </div>
  );
}
