import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { Label as DSLabel, DSButton, Flex, DSCheckbox, DSSortable } from "@skillup/ui";

import { selectHighlightUuid } from "../../../../../reducer";
import { Divided } from "../../ChildWrappers";
import { Permissions } from "../../../Permissions/Permissions";
import { QuestionChild } from "../Question";
import { BuilderFormInput } from "../../../BuilderFormInput";

import styles from "./MultipleChoicesQuestion.module.scss";
import { TranslationType } from "hooks/useTranslation";

type MultipleChoicesQuestionChild =
  | Extract<QuestionChild, { type: "checkbox" }>
  | Extract<QuestionChild, { type: "radio" }>;

type MultipleChoicesQuestionProps = {
  child: MultipleChoicesQuestionChild;
  updateTemplate: (data: QuestionChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  t: TranslationType;
};

type ChoiceWithId = {
  id: string;
  label: string;
};

export const MultipleChoicesQuestion = ({
  child,
  updateTemplate,
  indexes,
  t,
}: MultipleChoicesQuestionProps) => {
  const defaultChoicesWithId = useMemo(() => {
    return child.choices.map((choice) => ({
      id: uuid(),
      label: choice.label,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [choicesWithId, setChoicesWithId] = useState<ChoiceWithId[]>(defaultChoicesWithId);

  useEffect(() => {
    updateTemplate({
      ...child,
      choices: choicesWithId.map((choice) => ({
        label: choice.label,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicesWithId]);

  const highlightUuid = useSelector(selectHighlightUuid);
  const changeLabel = useCallback(
    (value: string) => {
      updateTemplate({ ...child, label: value });
    },
    [child, updateTemplate]
  );

  const changeDescription = useCallback(
    (value: string) => {
      updateTemplate({ ...child, description: value });
    },
    [child, updateTemplate]
  );

  const removeDescription = useCallback(() => {
    updateTemplate({ ...child, description: undefined });
  }, [child, updateTemplate]);

  const handleAddChoice = () => {
    setChoicesWithId((prev) => {
      return [
        ...prev,
        {
          id: uuid(),
          label: "",
        },
      ];
    });
  };

  const handleChangeChoices = (value: string, index: number) => {
    setChoicesWithId((prev) => {
      const newChoicesKeyIndex = [...prev];
      newChoicesKeyIndex[index] = {
        ...newChoicesKeyIndex[index],
        label: value,
      };
      return newChoicesKeyIndex;
    });
  };

  const handleRemoveChoice = (id) => {
    setChoicesWithId((prev) => {
      return prev.filter((choice) => choice.id !== id);
    });
  };

  const handleReorderChoices = (
    items: {
      id: string;
      title: React.ReactNode;
    }[],
    startIndex: number,
    endIndex: number
  ) => {
    setChoicesWithId((prev) => {
      const reorderedChoices = [...prev];
      const [replaced] = reorderedChoices.splice(startIndex, 1);
      reorderedChoices.splice(endIndex, 0, replaced);
      return reorderedChoices;
    });
  };

  const changeRequired = useCallback(
    (value: boolean) => {
      updateTemplate({ ...child, required: value });
    },
    [child, updateTemplate]
  );

  const choiceItems = useMemo(() => {
    return choicesWithId?.map((choice, index) => ({
      id: choice.id,
      title: (
        <BuilderFormInput
          value={choice.label}
          onChange={(newChoice) => handleChangeChoices(newChoice, index)}
          debounceValue={300}
          name={`child-${child.uuid}-choice-${index}-label`}
          placeholder={t("interviews.templateBuilder.question.choices.placeholder", {
            defaultValue: "Libellé du choix {{ count }}",
            count: index + 1,
          })}
          inputClassName={styles.choiceInput}
        />
      ),
    }));
  }, [choicesWithId, child.uuid, t]);

  return (
    <Divided>
      <div className={styles.container}>
        <div>
          <BuilderFormInput
            type="textarea"
            value={child.label}
            onChange={changeLabel}
            debounceValue={300}
            label={t("interviews.templateBuilder.question", {
              defaultValue: "Libellé de la question",
            })}
            name={`question-child-${child.uuid}-label`}
            required
            placeholder={t("interviews.templateBuilder.question.placeholder", {
              defaultValue: "Saisissez le libellé de la question",
            })}
            autoFocus={highlightUuid === child.uuid}
          />
        </div>
        <div className={styles.descriptionHelp}>
          {child.description !== undefined ? (
            <div>
              <div className={styles.labelAndButton}>
                <DSLabel
                  label={t("interviews.templateBuilder.question.description", {
                    defaultValue: "Description de la question",
                  })}
                />
                <DSButton
                  label={t("interviews.templateBuilder.question.description.delete", {
                    defaultValue: "Supprimer la description",
                  })}
                  className={styles.buttonUnderline}
                  onClick={removeDescription}
                  buttonSize="S"
                  emphasis="Low"
                />
              </div>
              <BuilderFormInput
                type="textarea"
                value={child.description}
                onChange={changeDescription}
                debounceValue={300}
                name={`question-child-${child.uuid}-description`}
                placeholder={t("interviews.templateBuilder.question.description.update", {
                  defaultValue: "Saisissez la description de la question",
                })}
              />
            </div>
          ) : (
            <DSButton
              label={t("interviews.templateBuilder.question.description.add", {
                defaultValue: "Ajouter une description",
              })}
              className={styles.buttonUnderline}
              onClick={() => changeDescription("")}
              buttonSize="S"
              emphasis="Low"
            />
          )}
        </div>
        <div>
          <DSLabel
            label={t("interviews.templateBuilder.choices.label", {
              defaultValue: "Choix possibles",
            })}
            required
          />
          <Flex className={styles.choiceRows}>
            <DSSortable
              handle
              removable
              removeAlwaysVisible
              onRemove={handleRemoveChoice}
              items={choiceItems}
              // @ts-expect-error the types in the DS are wrong, they should be generic
              reorderItems={handleReorderChoices}
            />
          </Flex>
          <DSButton
            label={t("interviews.templateBuilder.question.choices.add", {
              defaultValue: "Ajouter un choix possible",
            })}
            onClick={() => handleAddChoice()}
            buttonSize="S"
            emphasis="Mid"
          />
        </div>
      </div>
      <div>
        <Flex className={styles.permissions__action}>
          <DSCheckbox
            name={`${child.uuid}-required`}
            className={styles.permissions__actionChoice}
            label={t("interviews.templateBuilder.requiredField", {
              defaultValue: "Réponse requise",
            })}
            checked={child.required}
            onChange={changeRequired}
          />
        </Flex>
        <Permissions
          key={child.uuid}
          indexes={indexes}
          kind={child.kind}
          uuid={child.uuid}
          restrictions={child.disallow}
          order={["reply", "comment"]}
          mandatory={
            child.required
              ? {
                  reply: t("interviews.templateBuilder.question.requiredField.assistiveText", {
                    defaultValue:
                      "La réponse à cette question étant requise, vous devez sélectionner au moins un participant qui devra répondre.",
                  }),
                }
              : undefined
          }
          t={t}
        />
      </div>
    </Divided>
  );
};
