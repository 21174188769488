import { uniqBy, uniq } from "lodash";
import { EmployeeFields, EmployeeFilters } from "@skillup/core-hr-bridge";
import { ListUtils } from "@skillup/shared-utils";
import { InterviewPairs } from "./models/InterviewPair";
import { TranslationType } from "hooks/useTranslation";

export const getInterviewsEmployee = (interviews: InterviewPairs[]) => {
  return uniqBy(
    interviews.map((interview) => interview.employee),
    "uuid"
  );
};
export const getInterviewsManager = (interviews: InterviewPairs[]) => {
  return uniq(interviews.map((interview) => interview.manager));
};

export function getPopulationFiltersConfig(t: TranslationType) {
  return {
    [EmployeeFields.perimeters.key]: {
      type: ListUtils.FilterType.MULTISELECT,
      label: t(EmployeeFields.perimeters.traductionKey, {
        defaultValue: EmployeeFields.perimeters.traductionDefaultValue,
      }),
    },
    [EmployeeFields.contract.key]: {
      type: EmployeeFilters.contract.type,
      label: t(EmployeeFields.contract.traductionKey, {
        defaultValue: EmployeeFields.contract.traductionDefaultValue,
      }),
    },
    [EmployeeFields.contractCategory.key]: {
      type: EmployeeFilters.contractCategory.type,
      label: t(EmployeeFields.contractCategory.traductionKey, {
        defaultValue: EmployeeFields.contractCategory.traductionDefaultValue,
      }),
    },
    [EmployeeFields.email.key]: {
      type: EmployeeFilters.email.type,
      label: t(EmployeeFields.email.traductionKey, {
        defaultValue: EmployeeFields.email.traductionDefaultValue,
      }),
    },
    [EmployeeFields.firstName.key]: {
      type: EmployeeFilters.firstName.type,
      label: t(EmployeeFields.firstName.traductionKey, {
        defaultValue: EmployeeFields.firstName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.lastName.key]: {
      type: EmployeeFilters.lastName.type,
      label: t(EmployeeFields.lastName.traductionKey, {
        defaultValue: EmployeeFields.lastName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.branch.key]: {
      type: EmployeeFilters.branch.type,
      label: t(EmployeeFields.branch.traductionKey, {
        defaultValue: EmployeeFields.branch.traductionDefaultValue,
      }),
    },
    [EmployeeFields.division.key]: {
      type: EmployeeFilters.division.type,
      label: t(EmployeeFields.division.traductionKey, {
        defaultValue: EmployeeFields.division.traductionDefaultValue,
      }),
    },
    [EmployeeFields.registrationNumber.key]: {
      type: EmployeeFilters.registrationNumber.type,
      label: t(EmployeeFields.registrationNumber.traductionKey, {
        defaultValue: EmployeeFields.registrationNumber.traductionDefaultValue,
      }),
    },
    [EmployeeFields.role.key]: {
      type: EmployeeFilters.role.type,
      label: t(EmployeeFields.role.traductionKey, {
        defaultValue: EmployeeFields.role.traductionDefaultValue,
      }),
    },
    [EmployeeFields.service.key]: {
      type: EmployeeFilters.service.type,
      label: t(EmployeeFields.service.traductionKey, {
        defaultValue: EmployeeFields.service.traductionDefaultValue,
      }),
    },
    [EmployeeFields.site.key]: {
      type: EmployeeFilters.site.type,
      label: t(EmployeeFields.site.traductionKey, {
        defaultValue: EmployeeFields.site.traductionDefaultValue,
      }),
    },
  };
}

type UserArea = {
  uuid: string;
  name: string;
};

export function filtersConfigInitialValues(allAreas: UserArea[], t: TranslationType) {
  return {
    [EmployeeFields.perimeters.key]: {
      placeholder: t(EmployeeFields.perimeters.traductionKey, {
        defaultValue: EmployeeFields.perimeters.traductionDefaultValue,
      }),
      options: allAreas.map(({ uuid, name }) => ({ label: name, value: uuid })),
      visibilityMode: "always" as const,
    },
    [EmployeeFields.contract.key]: {
      placeholder: t(EmployeeFields.contract.traductionKey, {
        defaultValue: EmployeeFields.contract.traductionDefaultValue,
      }),
    },
    [EmployeeFields.contractCategory.key]: {
      placeholder: t(EmployeeFields.contractCategory.traductionKey, {
        defaultValue: EmployeeFields.contractCategory.traductionDefaultValue,
      }),
    },
    [EmployeeFields.email.key]: {
      placeholder: t(EmployeeFields.email.traductionKey, {
        defaultValue: EmployeeFields.email.traductionDefaultValue,
      }),
    },
    [EmployeeFields.firstName.key]: {
      placeholder: t(EmployeeFields.firstName.traductionKey, {
        defaultValue: EmployeeFields.firstName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.lastName.key]: {
      placeholder: t(EmployeeFields.lastName.traductionKey, {
        defaultValue: EmployeeFields.lastName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.branch.key]: {
      placeholder: t(EmployeeFields.branch.traductionKey, {
        defaultValue: EmployeeFields.branch.traductionDefaultValue,
      }),
    },
    [EmployeeFields.division.key]: {
      placeholder: t(EmployeeFields.division.traductionKey, {
        defaultValue: EmployeeFields.division.traductionDefaultValue,
      }),
    },
    [EmployeeFields.registrationNumber.key]: {
      placeholder: t(EmployeeFields.registrationNumber.traductionKey, {
        defaultValue: EmployeeFields.registrationNumber.traductionDefaultValue,
      }),
    },

    [EmployeeFields.role.key]: {
      placeholder: t(EmployeeFields.role.traductionKey, {
        defaultValue: EmployeeFields.role.traductionDefaultValue,
      }),
    },
    [EmployeeFields.service.key]: {
      placeholder: t(EmployeeFields.service.traductionKey, {
        defaultValue: EmployeeFields.service.traductionDefaultValue,
      }),
    },
    [EmployeeFields.site.key]: {
      placeholder: t(EmployeeFields.site.traductionKey, {
        defaultValue: EmployeeFields.site.traductionDefaultValue,
      }),
    },
  };
}
