import React, { useCallback, useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import { pick } from "lodash";
import { ISimpleSelectionUser } from "@skillup/types";

import {
  chevronRight as chevronRightIcon,
  chevronLeft as chevronLeftIcon,
} from "uiAssets/StrokeIcons";

import Acta from "utils/Acta";
import User from "utils/User";

import ManualTraining, { ManualTrainingData } from "../../ManualRowForms/Training";
import ManualSession, { ManualSessionData, AvailableState } from "../../ManualRowForms/Session";
import ManualUserSelection from "../../ManualRowForms/UserSelection";
import ControlledHeader from "../../components/ControlledHeader/ControlledHeader";
import Choices from "../../components/Choices";
import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";

import { ScheduleRowManualRequestRoute } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

interface Props {
  addRowsToList: (rowsUuids: string[]) => Promise<void>;
  showCatalog: () => void;
  scheduleUuid: string;
}

interface IState {
  training?: ManualTrainingData;
  session?: Required<ManualSessionData>;
  desiredState?: AvailableState;
  users: ISimpleSelectionUser[];
  step: "choice" | "training" | "session" | "user" | "save";
  isSaving: boolean;
}

const createRequestWithSession = async (
  scheduleUuid: string,
  { users, session, training }: Required<Pick<IState, "users" | "training" | "session">>
) => {
  const sessionData = pick(session, ["sessionStartDate", "sessionEndDate", "sessionCity"]);

  const addRowToPlanHTTPRequest =
    buildRequest<ScheduleRowManualRequestRoute.CreateForPlanWithSession>({
      method: "POST",
      path: "/scheduleRow/manual/plan/with-session",
      payload: {
        trainingData: {
          ...training,
          sessionDuration: training.sessionDuration,
        },
        sessionData,
        users: users.map((user) => user.uuid),
        scheduleUuid,
      },
    });

  const data = await addRowToPlanHTTPRequest();
  return data.createdRows;
};

const createRequestWithoutSession = async (
  scheduleUuid: string,
  { users, training }: Required<Pick<IState, "users" | "training">>
) => {
  const addRowToPlanHTTPRequest =
    buildRequest<ScheduleRowManualRequestRoute.CreateForPlanWithoutSession>({
      method: "POST",
      path: "/scheduleRow/manual/plan",
      payload: {
        trainingData: {
          ...training,
          sessionDuration: training.sessionDuration,
        },
        users: users.map((user) => user.uuid),
        scheduleUuid,
      },
    });

  const data = await addRowToPlanHTTPRequest();
  return data.createdRows;
};

interface IState {
  training?: ManualTrainingData;
  session?: Required<ManualSessionData>;
  desiredState?: AvailableState;
  users: ISimpleSelectionUser[];
  step: "choice" | "training" | "session" | "user" | "save";
  isSaving: boolean;
}

const AddRowToPlanModal = ({ addRowsToList, showCatalog, scheduleUuid }: Props) => {
  const [state, setState] = useSetState<IState>({ users: [], step: "choice", isSaving: false });
  const { isSaving, training, session, desiredState, step, users } = state;
  const useTaxSystem = User.isCompanyWithTax();
  const { t } = useTranslation();

  const save = useCallback(async () => {
    setState({ isSaving: true });
    try {
      const createdRows = await(
        desiredState === "won"
          ? createRequestWithSession(scheduleUuid, {
              users,
              session,
              training,
            })
          : createRequestWithoutSession(scheduleUuid, {
              users,
              training,
            })
      );

      addRowsToList(createdRows);

      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.add_trainees_modal.toast.added_succes", {
          defaultValue: "Ajout réussi !",
        }),
        type: "success",
      });
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.add_trainees_modal.toast.error", {
          defaultValue: "Une erreur est survenue lors de cet ajout de ligne",
        }),
        type: "error",
      });
      setState({ step: "session", isSaving: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training, desiredState, session, users, scheduleUuid, addRowsToList, setState]);

  useEffect(() => {
    if (step === "save" && !isSaving) {
      save();
    }
  }, [step, save, isSaving]);

  const backButtonHandler = useMemo(() => {
    if (step === "training") return () => setState({ step: "choice" });
    if (step === "session") return () => setState({ step: "training" });
    if (step === "user") return () => setState({ step: "session" });
    return undefined;
  }, [step, setState]);

  const headerLabel = {
    choice: t("trainings.view.add_trainees_modal.schedule_choice.header.label", {
      defaultValue: "Ajouter au plan",
    }),
    training: t("trainings.view.add_trainees_modal.schedule_training.header.label", {
      defaultValue: "Ajouter une ligne hors Skillup 1/3",
    }),
    session: t("trainings.view.add_trainees_modal.schedule_session.header.label", {
      defaultValue: "Ajouter une ligne hors Skillup 2/3",
    }),
    user: t("trainings.view.add_trainees_modal.schedule_user.header.label", {
      defaultValue: "Ajouter une ligne hors Skillup 3/3",
    }),
  }[step];

  return (
    <div>
      <ControlledHeader label={headerLabel} backButtonHandler={backButtonHandler} />
      {step === "choice" && (
        <Choices
          choices={[
            {
              description: t(
                "trainings.view.pending_schedule_row.property.add_modal.action.from_skillup.description",
                {
                  defaultValue:
                    "Ajouter une ligne au plan depuis mes programmes, mes formations recommandées ou le catalogue Skillup.",
                }
              ),
              title: t(
                "trainings.view.add_trainees_modal.schedule_choice.from_skillup.title.label",
                {
                  defaultValue: "Depuis Skillup",
                }
              ),
              icon: chevronRightIcon,
              onClick: showCatalog,
            },
            {
              description: t(
                "trainings.view.pending_schedule_row.property.add_modal.action.unmanaged_by_skillup.description",
                {
                  defaultValue:
                    "Ajouter une ligne au plan sans que cette dernière ne soit reliée à un programme du catalogue Skillup. Vous pourrez renseigner les informations nécessaires à son suivi dans Skillup.",
                }
              ),
              title: t("trainings.view.add_trainees_modal.schedule_choice.out_skillup.title.label", {
                defaultValue: "Programme hors Skillup",
              }),
              icon: chevronLeftIcon,
              onClick: () => setState({ step: "training" }),
            },
          ]}
        />
      )}

      {step === "training" && (
        <ManualTraining
          useTaxSystem={useTaxSystem}
          trainingData={training}
          onSuccess={(trainingData) => {
            setState({ training: trainingData, step: "session" });
          }}
        />
      )}

      {step === "session" && (
        <ManualSession
          trainingData={training}
          initialSessionData={session && desiredState ? { ...session } : undefined}
          desiredState={desiredState}
          target="plan"
          onSuccess={(data) => {
            setState({
              session: data.sessionData,
              desiredState: data.desiredState,
              step: "user",
            });
          }}
        />
      )}
      {["user", "save"].includes(step) && (
        <UsersListProvider>
          <ManualUserSelection
            isLoading={step === "save"}
            trainingData={training}
            target="plan"
            onSuccess={(data) => {
              setState({
                users: data.users,
                step: "save",
              });
            }}
          />
        </UsersListProvider>
      )}
    </div>
  );
};

export default AddRowToPlanModal;
