import React, { useRef, useState, useCallback } from "react";
import { useToggle } from "react-use";

import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";
import { Summary } from "@skillup/types";

import useTranslation from "hooks/useTranslation";

import { buildRequest } from "utils/buildRequest";
import User from "utils/User";
import Acta from "utils/Acta";

import InteractiveButton from "components/InteractiveButton";
import DropDown, { TranslatedDropDown, DropDownOptions } from "components/DropDown";
import TextInput from "components/TextInput";
import { statusForHR } from "constants/status";

import { IRelationCall } from "../utils/fetchItems";
import styles from "./PositioningModalStyles.module.scss";

interface MoveToOtherScheduleProps {
  scheduleUuid: string;
  reload: () => void;
  rowsUuids: string[];
  currentState: IRelationCall["primaryState"];
  summary: Summary;
}

export default ({
  reload,
  rowsUuids,
  scheduleUuid,
  currentState,
  summary,
}: MoveToOtherScheduleProps) => {
  const { t } = useTranslation();

  const scheduleDropdown = useRef<DropDown<DropDownOptions<string>>>(null);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [message, setMessage] = useState<string>();

  const submit = useCallback(async () => {
    if (!scheduleDropdown || !scheduleDropdown.current) return;

    const schedule = scheduleDropdown.current.getValue();
    if (!schedule) return;

    try {
      toggleSubmitting(true);

      await buildRequest<ScheduleRowRoutes.MoveToOtherSchedule>({
        method: "POST",
        path: "/scheduleRow/move-to-schedule/{scheduleUuid}",
        params: { scheduleUuid: schedule },
        payload: { rows: rowsUuids, message },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.pending_schedule_row.property.move_to_plan_modal.success", {
          defaultValue: "Opération effectuée.",
        }),
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      reload();
    } catch (error) {
      console.error(error);
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.pending_schedule_row.property.move_to_plan_modal.error", {
          defaultValue: "La demande a échoué.",
        }),
        type: "error",
      });
    } finally {
      toggleSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, toggleSubmitting, scheduleDropdown, rowsUuids, reload]);

  const statusForSummary = statusForHR(summary);

  const computeMessage = (rows) => {
    return t("trainings.view.pending_schedule_row.property.move_to_plan_modal.status_warning", {
      defaultValue: "Les statuts des lignes seront conservés",
      status: statusForSummary[currentState],
      count: rows.length,
    });
  };

  const schedules = User.getSchedules({ archived: true })
    .filter((s) => s.uuid !== scheduleUuid)
    .map((s) => ({ label: s.name, value: s.uuid }));

  return (
    <div className={styles.positioningModal}>
      <TranslatedDropDown
        label={t("trainings.view.pending_schedule_row.property.move_to_plan_modal.target", {
          defaultValue: "Déplacer vers:",
        })}
        options={schedules}
        placeholder={t(
          "trainings.view.pending_schedule_row.property.move_to_plan_modal.target_placeholder",
          {
            defaultValue: "Sélectionnez un plan",
          }
        )}
        ref={scheduleDropdown}
      />

      <TextInput
        onChange={(_e, value) => setMessage(value)}
        label={t("trainings.view.pending_schedule_row.property.move_to_plan_modal.comment", {
          defaultValue: "Commentaire (facultatif)",
        })}
        placeholder={t(
          "trainings.view.pending_schedule_row.property.move_to_plan_modal.comment_placeholder",
          {
            defaultValue: "Laissez un commentaire dans votre activité",
          }
        )}
        alwaysOpen
      />
      <p style={{ marginBottom: 20 }}>{computeMessage(rowsUuids)}.</p>
      <InteractiveButton
        loading={submitting}
        label={t("common.action.move", {
          defaultValue: "Déplacer",
        })}
        size="small"
        onClick={submit}
        style={{ fontWeight: 500 }}
      />
    </div>
  );
};
