import { FieldsRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";
import { z } from "zod";

/**
 * @todo
 * move this in bridge when hapi can understand zod
 */
export const editReviewCriteriaPayloadValidation = z.object({
  id: z.string().uuid(),
  description: z.string().optional().nullable(),
  forceNewVersion: z.boolean(),
  label: z.string(),
  options: z.array(z.object({
    id: z.string().uuid().optional(),
    description: z.string().optional().nullable(),
    label: z.string(),
    value: z.number(),
  })),
})

export const getReviewCriteriaHistoryCacheKey = (id: string) => [
  "core-hr",
  "review-criteria",
  "history",
  id,
];
export function getReviewCriteriaHistory(fieldID: string) {
  return async () => {
    const result = await buildRequest<FieldsRoutes.GetReviewCriteriaHistory>({
      method: "GET",
      path: "/review-criteria/{fieldID}/history",
      target: "CORE_HR",
      params: { fieldID },
    })();

    return result;
  };
}
