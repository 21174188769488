import React, { useCallback, useEffect } from "react";
import { useSetState } from "react-use";

import { ScheduleRowManualRequestRoute } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

import ManualTraining, { ManualTrainingData } from "../ManualRowForms/Training";
import ControlledHeader from "../components/ControlledHeader/ControlledHeader";

import styles from "./UpdateRow.module.scss";

interface Props {
  scheduleUuid: string;
  reload: (updatedRow: string) => Promise<void>;
  rowUuid: string;
  trainingToEdit: ManualTrainingData;
}

const UpdateRowModal = ({ reload, rowUuid, trainingToEdit }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useSetState<{
    training: ManualTrainingData;
    step: "training" | "save";
    isSaving: boolean;
  }>({ step: "training", training: trainingToEdit, isSaving: false });
  const { isSaving, training, step } = state;
  const useTaxSystem = User.isCompanyWithTax();

  const update = useCallback(async () => {
    setState({ isSaving: true });
    try {
      const { updatedRow } = await buildRequest<ScheduleRowManualRequestRoute.Edit>({
        method: "PUT",
        params: { rowUuid },
        path: "/scheduleRow/manual/{rowUuid}",
        payload: {
          trainingData: {
            ...training,
            sessionDuration: training.sessionDuration,
          },
        },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: t(
          "trainings.view.pending_schedule_row.property.update_unmanaged_by_skillup_modal.success",
          {
            defaultValue: "Edition réussie !",
          }
        ),
        type: "success",
      });

      Acta.dispatchEvent("closeModal");
      reload(updatedRow);
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t(
          "trainings.view.pending_schedule_row.property.update_unmanaged_by_skillup_modal.error",
          {
            defaultValue: "Une erreur est survenue lors de l'édition de cette demande",
          }
        ),
        type: "error",
      });
      setState({ step: "training", isSaving: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training, rowUuid, reload, setState]);

  useEffect(() => {
    if (step === "save" && !isSaving) {
      update();
    }
  }, [step, update, isSaving]);

  return (
    <div className={styles.updateRow}>
      <ControlledHeader
        label={t(
          "trainings.view.pending_schedule_row.property.update_unmanaged_by_skillup_modal.title",
          {
            defaultValue: "Edition de la demande hors Skillup",
          }
        )}
      />

      {["training", "save"].includes(step) && (
        <ManualTraining
          isLoading={isSaving}
          useTaxSystem={useTaxSystem}
          trainingData={training}
          onSuccess={(trainingData) => {
            setState({ training: trainingData, step: "save" });
          }}
        />
      )}
    </div>
  );
};

export default UpdateRowModal;
