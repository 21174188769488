import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";
import styles from "./Steps.module.scss";

export const TargetAlert = ({ t }: { t: TranslationType }) => (
  <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE} className={styles.alert}>
    <div>
      {t("interviews.campaigncreation.choosemodel.alert.message", {
        defaultValue:
          "La création d'objectifs n'est effective qu'à la signature d'un entretien ou à la clôture de la campagne dans laquelle il apparaît.",
      })}
      <br />
      <strong>
        {t("interviews.campaigncreation.choosemodel.alert.description", {
          defaultValue:
            "Pensez donc à clôturer vos précédentes campagnes pour vous assurer que les objectifs sont bien créés et peuvent être évalués.",
        })}
      </strong>
    </div>
  </DSAlert>
);
