import { useState, useEffect } from "react";
import cx from "classnames";

import TextInput from "components/TextInput";
import Icon from "components/Icon";
import Colors from "uiAssets/Colors";
import { user, magnifyingGlass } from "uiAssets/StrokeIcons";
import { cross } from "uiAssets/Icons";

import styles from "./UserSearchInput.module.scss";
import { searchUsersByQuery } from "fetchers/users-fetcher";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import type { SearchByQueryRouteType } from "types/api";
import { TranslationType } from "hooks/useTranslation";

export type IUserIdentity = SearchByQueryRouteType["response"][0];

interface IProps {
  autoFocus?: boolean;
  className?: string;
  onSelect?: (u: IUserIdentity) => void;
  filter?: (u: IUserIdentity) => boolean;
  styles?: React.CSSProperties;
  undelineColor?: string;
  userAreas?: string[];
  "aria-label"?: string;
  t: TranslationType;
}

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);
  return debouncedValue;
};

const searchUsers = async (query, setResults, setHidden, userAreas) => {
  if (!query || query.length === 0) {
    setResults([]);
    setHidden(true);
  } else {
    const users = await searchUsersByQuery(query, USER_FILE_VALIDATION_TYPE.NONE);
    setHidden(false);
    setResults(users);
  }
};

const select = (u, onSelect, setResults, setHidden, resetInput) => {
  if (onSelect) onSelect(u);
  setResults([]);
  setHidden(true);
  resetInput();
};

const UserSearchInput = (props: IProps) => {
  const { className, onSelect, filter, t } = props;
  const [results, setResults] = useState<Array<IUserIdentity>>([]);
  const [hidden, setHidden] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    searchUsers(debouncedQuery, setResults, setHidden, props.userAreas);
  }, [debouncedQuery, props.userAreas]);

  const resetInput = () => {
    setQuery("");
  };

  const filtered = filter ? results.filter(filter) : results;

  return (
    <div className={cx(styles.UserSearchInput, className)}>
      <TextInput
        aria-label={props["aria-label"]}
        type="text"
        alwaysOpen
        autoComplete="off"
        defaultValue={query}
        autoFocus={props.autoFocus}
        onChange={(_e, value) => setQuery(value)}
        small
        underlineColor={props.undelineColor || Colors.blue}
        style={{ margin: 0, padding: 0 }}
        name="search-user"
        placeholder="Ex: Claire Moreaux"
        strokeIcon={magnifyingGlass}
      />
      {!hidden && (
        <div className={styles.results} aria-label="liste-utilisateurs">
          {filtered.map((u) => (
            <div
              key={u.email}
              onClick={() => select(u, onSelect, setResults, setHidden, resetInput)}
            >
              <Icon icon={user} width={20} />
              <div className={styles.info}>
                <span>{u.fullName}</span>
                <span>{u.email}</span>
              </div>
            </div>
          ))}
          {filtered.length === 0 && (
            <div>
              <Icon icon={cross} width={20} />
              <div
                onClick={() => {
                  setHidden(true);
                }}
                className={styles.info}
              >
                <span>
                  {t("collaborators.findUser.input.noResults", {
                    defaultValue: "Pas de résultats",
                  })}
                </span>
                <span>
                  {t("collaborators.findUser.input.collaboratorNotFound", {
                    defaultValue: "Collaborateur introuvable en base de données.",
                  })}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearchInput;
