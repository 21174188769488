import React from "react";
import { DSCheckbox } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

interface Props {
  review: {
    uuid: string;
    state: string;
    scope: string;
  };
  checked: boolean;
  setState: Function;
  scope: string;
}

export default function Review({ review, checked, setState, scope }: Props) {
  const { t } = useTranslation();

  const scopeLabel =
    scope === "hot"
      ? t("trainings.entity.review.property.scope.hot", { defaultValue: "chaud" })
      : t("trainings.entity.review.property.scope.cold", { defaultValue: "froid" });

  const label = t("trainings.view.cancel_review.review_label", {
    defaultValue: `Évaluation à {{ scopeLabel }}`,
    scopeLabel,
  });

  if (!review) {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={label}
        helpText={t("trainings.view.cancel_review.empty_review", {
          defaultValue: `L'évaluation à {{ scopeLabel }} n'existe pas.`,
          scopeLabel,
        })}
      />
    );
  }

  if (["abandoned", "cancelled"].includes(review.state)) {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={label}
        helpText={t("trainings.view.cancel_review.already_cancelled", {
          defaultValue: `L'évaluation à {{ scopeLabel }} a déjà été annulée.`,
          scopeLabel,
        })}
      />
    );
  }

  if (review.state === "completed") {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={label}
        helpText={t("trainings.view.cancel_review.already_cancelled", {
          defaultValue: `L'évaluation à {{ scopeLabel }} a déjà été complétée.`,
          scopeLabel,
        })}
      />
    );
  }

  return (
    <>
      <DSCheckbox
        checked={checked}
        onChange={() => setState({ [scope]: !checked })}
        label={label}
      />
    </>
  );
}
