import { PeopleReviewRoutes } from "@skillup/espace-rh-bridge";

import { buildFileRequest } from "utils/buildRequest";

export async function uploadReviewedEmployees(file: File) {
  const result = await buildFileRequest<PeopleReviewRoutes.UploadReviewedEmployees>({
    file,
    method: "POST",
    path: "/upload-reviewed-employees",
  })();

  const employees = result.employees.map((item) =>
    Object.assign({ id: item.collaborator?.uuid }, item)
  );

  return {
    employees,
    prefilledEvaluationsByScaleLabelAndUserID: result.prefilledEvaluationsByScaleLabelAndUserID,
  };
}
