import { useEffect, useMemo, useRef } from "react";
import { CSVLink } from "react-csv";

import { DSAlert, DSAlertDisplay, DSAlertType, DSCard } from "@skillup/ui";

import DateUtils from "utils/dates";
import { CampaignCreationData } from "../../models/CampaignCreationData";
import { TargetAlert } from "./TargetAlert";
import { useCampaignCreationErrors } from "../../hooks/useCampaignCreationErrors";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import styles from "./Steps.module.scss";

interface IProps extends CampaignCreationData {
  setCanCreateCampaign: (arg: boolean) => void;
  readonly displayTargetAlert?: boolean;
}

const FinalStep = (props: IProps): JSX.Element => {
  const {
    titleForAttendees,
    titleForHR,
    template,
    type,
    setCanCreateCampaign,
    displayTargetAlert,
  } = props;

  const { t } = useTranslation();

  const typeEntretienLabel = {
    ongoing: t("campaign.ongoingCampaign", { defaultValue: "Entretiens en continu" }),
    legacy: t("campaign.legacyCampaign", { defaultValue: "Campagne d'entretiens" }),
    adHoc: t("campaign.adHocCampaign", { defaultValue: "Entretiens ponctuels" }),
  };

  const errorState = useCampaignCreationErrors({
    ...props,
    type: props.type === "ongoing" ? "adHoc" : props.type, // TODO: clean this up. clear up the use of ongoing type which actually creates adHoc campaign
  });
  const csvLink = useRef<CSVLink | null>(null);

  const thereAreErrors = useMemo(
    () => errorState.step === "errors" && errorState.errors.length > 1,
    [errorState]
  );

  useEffect(() => {
    if (errorState.step === "canCreate") {
      setCanCreateCampaign(true);
    }
  }, [errorState, setCanCreateCampaign]);

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.FinalStepCard} showTitle={false}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.element}>
              <div className={styles.title}>
                {t("interviews.campaigncreation.final.type", {
                  defaultValue: "Type de campagne",
                })}
              </div>
              <div className={styles.value}>{typeEntretienLabel[type]}</div>
            </div>
            <div className={styles.element}>
              <div className={styles.title}>
                {t("interviews.campaigncreation.final.template", {
                  defaultValue: "Trame d'entretien",
                })}
                Trame d'entretien
              </div>
              <div className={styles.value}>{template.title}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.element}>
              <div className={styles.title}>
                {t("interviews.campaigncreation.final.titleForHR", {
                  defaultValue: "Nom de la campagne (pour les RH)",
                })}
              </div>
              <div className={styles.value}>{titleForHR}</div>
            </div>
            <div className={styles.element}>
              <div className={styles.title}>
                {t("interviews.campaigncreation.final.titleForAttendees", {
                  defaultValue: "Nom de la campagne (pour les participants)",
                })}
              </div>
              <div className={styles.value}>{titleForAttendees}</div>
            </div>
          </div>
          {type === "legacy" && RenderClassicCampaignRecap(props, t)}
          {type === "ongoing" && renderOngoingCampaignRecap(props, t)}
          {type === "adHoc" && renderAdHocCampaignRecap(props, t)}
        </div>
      </DSCard>

      {(errorState.step === "errors" || errorState.step === "canCreate") && (
        <span className={styles.FinalStepAlert}>
          {errorState.step === "errors" && thereAreErrors && (
            <DSAlert
              className={styles.alert}
              type={DSAlertType.ERROR}
              display={DSAlertDisplay.INLINE}
              closeButton={false}
              buttonLabel={t("interviews.campaigncreation.final.error.download", {
                defaultValue: "Télécharger le rapport d'erreur",
              })}
              onAction={() => {
                if (csvLink.current) csvLink.current.link.click();
              }}
            >
              {t("interviews.campaigncreation.final.error", {
                defaultValue:
                  "La campagne ne peut pas être créée car les informations renseignées contiennent des erreurs.",
              })}
              <CSVLink
                data={errorState.errors}
                filename="campaign_errors.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </DSAlert>
          )}
          {displayTargetAlert && <TargetAlert t={t} />}
        </span>
      )}
    </div>
  );
};

const RenderClassicCampaignRecap = (props: IProps, t: TranslationType) => {
  const { start, duration, interviews, managerOnly, hideFromEmployeeUntil, confidential } = props;

  const { start: startDate, endDate } = DateUtils.getLegacyCampaignDates({
    start,
    duration,
  });

  const interviewsCount = interviews?.length || 0;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>
            {t("interviews.campaigncreation.final.startDate", {
              defaultValue: "Date de début de campagne",
            })}
          </div>
          {/* TODO: handle start: number case */}
          <div className={styles.value}>{startDate}</div>
        </div>
        <div className={styles.element}>
          <div className={styles.title}>
            {t("interviews.campaigncreation.final.endDate", {
              defaultValue: "Date limite de signature",
            })}
          </div>
          <div className={styles.value}>{endDate}</div>
        </div>
      </div>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil, t)}
      {ConfidentialityDetails(confidential, t)}

      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>
            {t("interviews.campaigncreation.final.interviewsCount", {
              defaultValue: "Nombre d’entretiens à venir",
            })}
          </div>
          <div className={styles.value}>{interviewsCount}</div>
        </div>
      </div>
    </>
  );
};

const renderOngoingCampaignRecap = (props: IProps, t: TranslationType) => {
  const { start, duration, managerOnly, hideFromEmployeeUntil, confidential } = props;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>
            {t("interviews.campaigncreation.final.start", {
              defaultValue: "Date de début (jours après l'embauche)",
            })}
          </div>
          <div className={styles.value}>{start}</div>
        </div>
        <div className={styles.element}>
          <div className={styles.title}>
            {t("interviews.campaigncreation.final.duration", {
              defaultValue: "Délai de signature (durée en jours)",
            })}
          </div>
          <div className={styles.value}>{duration}</div>
        </div>
      </div>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil, t)}
      {ConfidentialityDetails(confidential, t)}
    </>
  );
};

const renderAdHocCampaignRecap = (props: IProps, t: TranslationType) => {
  const { managerOnly, hideFromEmployeeUntil, confidential } = props;

  return (
    <>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil, t)}
      {ConfidentialityDetails(confidential, t)}
    </>
  );
};

const ConfidentialityDetails = (confidential: boolean, t: TranslationType) => {
  return (
    <div className={styles.row}>
      <div className={styles.element}>
        <div className={styles.title}>
          {t("interviews.campaigncreation.final.confidential.title", {
            defaultValue: "Confidentialité de la campagne",
          })}
        </div>
        {confidential ? (
          <div className={styles.value}>
            {t("interviews.campaigncreation.final.confidential.yes.detail", {
              defaultValue:
                "La campagne est confidentielle. Les entretiens ne seront consultables que par les collaborateurs et leurs responsables d’entretien. Ils ne seront pas accessibles aux futurs managers ainsi qu’aux supérieurs hiérarchiques des collaborateurs.",
            })}
          </div>
        ) : (
          <div className={styles.value}>
            {t("interviews.campaigncreation.final.confidential.no.detail", {
              defaultValue:
                "Les entretiens de cette campagne seront accessibles aux futurs managers ainsi qu’aux supérieurs hiérarchiques (N+2, N+3…) des collaborateurs",
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const WorfklowDetails = (
  managerOnly: boolean,
  hideFromEmployeeUntil: { managerState: "pending" | "shared" | "signed" | "unsigned" },
  t: TranslationType
) => {
  return (
    <div className={styles.row}>
      <div className={styles.element}>
        <div className={styles.title}>
          {t("interviews.campaigncreation.final.workflow.title", {
            defaultValue: "Préférences de workflow",
          })}
        </div>
        {managerOnly && (
          <div className={styles.value}>
            {t("interviews.campaigncreation.final.workflow.managerOnly", {
              defaultValue: "Entretiens gérés unilatéralement par les responsables",
            })}
          </div>
        )}
        {hideFromEmployeeUntil && (
          <div className={styles.value}>
            {t("interviews.campaigncreation.final.workflow.hideFromEmployeeUntil", {
              defaultValue:
                "Entretiens ouverts aux collaborateurs uniquement après partage du responsable",
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalStep;
