import { useDropzone } from "react-dropzone";
import { useState, useCallback } from "react";
import useTranslation from "hooks/useTranslation";

import { Formik } from "formik";

import { Flex, Text } from "@skillup/design-system";
import { DatasetKey, datasetKeys, DatasetsRoutes } from "@skillup/core-hr-bridge";
import { Select, DSAlert, FileDrop, DSAlertType, DSAlertDisplay } from "@skillup/ui";

import dataLayer from "utils/DataLayer";
import { CampaignCreationState } from "types/peopleReview";
import { buildURL, buildFileRequest } from "utils/buildRequest";

const getFile = async (file, key) =>
  await buildFileRequest<DatasetsRoutes["Import"]>({
    file,
    method: "POST",
    params: { datasetKey: key },
    path: `/datasets/{datasetKey}`,
    target: "CORE_HR",
  })();

export const ImportExportDatasets = () => {
  const { t } = useTranslation();
  const [datasetKey, setDatasetKey] = useState<DatasetKey>(datasetKeys[0]);
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleFileUpload = useCallback(
    async (file: File) => {
      try {
        await getFile(file, datasetKey);
        setIsLoading(false);
        setError(null);
      } catch (e) {
        setError(e.message);
      }
    },
    [datasetKey]
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize: 52428800,
    multiple: false,
    onDrop: async (files: File[]) => {
      if (isLoading) return;
      setUploadedFile(files[0]);
      handleFileUpload(files[0]);
    },
  });

  return (
    <Flex gap="s" padding="s" flexDirection="column">
      <Select
        clearable={false}
        value={datasetKey}
        isSearchable={false}
        placeholder="Aucun Dataset séléctionné"
        onChange={(option) => {
          if (!option) return;
          setDatasetKey(option);
        }}
        options={datasetKeys.map((option) => ({
          label: option.toString(),
          value: option,
        }))}
      />

      <Flex gap="s">
        <Formik
          validateOnMount={false}
          initialValues={[] as CampaignCreationState["collaboratorManagerPairs"]}
          onSubmit={() => {}}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Text espaceFont="h4" marginBottom="s">
                {t("peoplereview.upload.title", {
                  defaultValue: "Importer un fichier",
                })}
              </Text>
              <Flex gap="s" minWidth="100%" marginBottom="s" flexDirection="column">
                {!isLoading && (
                  <FileDrop
                    value={uploadedFile?.name}
                    rootProps={getRootProps()}
                    isDragActive={isDragActive}
                    inputProps={getInputProps()}
                    btnLabel={t("peoplereview.upload.drop.button", {
                      defaultValue: "Choisir un fichier",
                    })}
                    dropLabel={t("peoplereview.upload.drop.message", {
                      defaultValue: "ou glisser déposer un fichier ici",
                    })}
                  />
                )}
              </Flex>
            </form>
          )}
        </Formik>

        <Flex gap="s" flexDirection="column">
          <Text espaceFont="h4">
            {t("peoplereview.export.title", {
              defaultValue: "Exporter",
            })}
          </Text>
          <a
            target="_blank"
            rel="noreferrer"
            href={dataLayer.buildRootURL(
              "CORE_HR",
              buildURL({
                params: { datasetKey },
                path: "/datasets/{datasetKey}",
                target: "CORE_HR",
              })
            )}
          >
            Export as CSV
          </a>
        </Flex>
      </Flex>

      {error && (
        <Flex padding="s" flexDirection="column">
          <Text>
            {JSON.parse(error).message ??
              t("peoplereview.upload.error", {
                defaultValue: "Une erreur est survenue",
              })}
          </Text>
          <Flex flexDirection="column">
            {JSON.parse(error).data.map((error) =>
              error.issues.map((issue) => {
                return (
                  <Flex marginBottom="s">
                    <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
                      {issue.path} - {issue.message}
                    </DSAlert>
                  </Flex>
                );
              })
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
