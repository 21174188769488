import { useCallback, useMemo } from "react";

import { DataTable, DSButton, Flex } from "@skillup/ui";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { useTemplates } from "services/interviews";

import { useTemplatesActions, useTemplatesModals, ModalStates } from "../hooks";
import useTranslation from "hooks/useTranslation";
import useTableData, { CampaignTemplateRow } from "./useTableData";
import useTemplatesSidePanelActions, { SidePanelStates } from "./useTemplatesSidePanelActions";
import useTemplatesSidePanel from "./useTemplatesSidePanel";
import { getActions } from "./getTemplateListActions";
import { getInterviewTypeLabel } from "utils/interviews";

import styles from "./TemplatesList.module.scss";

export const TemplatesList = () => {
  const { t } = useTranslation();
  const {
    templates,
    hasArchivedTemplates,
    actions: { canDo, getPreviewLinksByUuid },
  } = useTemplates();

  const translatedTemplates = useMemo(
    () =>
      templates.map((template) => {
        return { ...template, type: getInterviewTypeLabel(template.type, t) };
      }),
    [t, templates]
  );
  const { columns, data: tableData } = useTableData(translatedTemplates);
  const [{ state: actions }, setActionsState] = useTemplatesActions();
  const [{ state: sidePanelState }, setSidePanelState] = useTemplatesSidePanelActions();
  const sidePanel = useTemplatesSidePanel(sidePanelState, setSidePanelState);
  const closeSidePanel = useCallback(() => {
    setSidePanelState({ state: SidePanelStates.Idle, selectedRow: null });
  }, [setSidePanelState]);
  const modalToDisplay = useTemplatesModals(actions, setActionsState, closeSidePanel);

  const openCreateModal = useCallback(() => {
    setActionsState({ state: ModalStates.Create });
  }, [setActionsState]);

  const handleClickRow = useCallback(
    (rowId: string) => {
      const template = templates.find((template) => template.uuid === rowId);
      setSidePanelState(
        sidePanelState.selectedRow === rowId
          ? { state: SidePanelStates.Idle, selectedRow: null }
          : {
              state: SidePanelStates.Open,
              template: template,
              openActionModal: setActionsState,
              selectedRow: rowId,
            }
      );
    },
    [setSidePanelState, setActionsState, sidePanelState.selectedRow, templates]
  );

  const openArchivedTemplatesModal = () => {
    setActionsState({ state: ModalStates.ArchivedList });
  };

  const layouts = useMemo(
    () =>
      canDo("create")
        ? [
            {
              primaryButton: (
                <DSNewHeaderButton
                  id="create-template-button"
                  label={t("interviews.templates.create", {
                    defaultValue: "Créer une nouvelle trame",
                  })}
                  onClick={openCreateModal}
                />
              ),
            },
          ]
        : [],
    [canDo, openCreateModal, t]
  );

  return (
    <DSLayout
      title={t("interviews.interviewTemplates", { defaultValue: "Trames d'entretien" })}
      layouts={layouts}
    >
      <Flex className={styles.container} column>
        <DataTable<CampaignTemplateRow>
          className={styles.table}
          columns={columns}
          rows={tableData}
          defaultSort={{ key: "date", desc: true }}
          actions={(row) => {
            const template = templates.find((template) => template.uuid === row.id);
            if (template && template.uuid) {
              return getActions(
                template,
                setActionsState,
                canDo,
                () => getPreviewLinksByUuid(template.uuid),
                t
              );
            }
            return [];
          }}
          header={{
            totalRowsLabel: (total) =>
              t("interviews.templates", {
                defaultValue: "{{ count }} trame",
                defaultValue_other: "{{ count }} trames",
                count: total,
              }),
          }}
          pagination={{
            rowsPerPageLabel: t("datagrid.footer.linesPerPage", {
              defaultValue: "Lignes par page",
            }),
            itemsCountLabel: t("datagrid.footer.range.on.count", {
              defaultValue: "Objets %range% sur %count%",
            }),
            pageLabel: t("datagrid.footer.page.label", {
              defaultValue: "Page",
            }),
          }}
          onClickRow={(row) => handleClickRow(row.id)}
          mode="compact"
        />
        {hasArchivedTemplates && (
          <div className={styles.container__footer}>
            <DSButton
              buttonSize="S"
              emphasis="Low"
              label={t("interviews.templates.display.archived.modal", {
                defaultValue: "Afficher les trames archivées",
              })}
              onClick={openArchivedTemplatesModal}
            />
          </div>
        )}
        {sidePanel}
        {modalToDisplay}
      </Flex>
    </DSLayout>
  );
};
