import { useEffect } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal, DSAlert, DSAlertDisplay, DSAlertType } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";

import HabilitationForm, {
  IForm,
  ValidateForm,
  LoadHabilitationToForm,
  BuildPayloadFromForm,
} from "../HabilitationForm";

import { Payload } from "../../components/HabilitationForm/config";
import styles from "./ModifyHabilitation.module.scss";

import { buildSafeRequest } from "utils/buildRequest";

import { Habilitation } from "../../types";
import { formatError } from "services/errors";

interface Props {
  readonly onClose: () => void;
  readonly habilitation: Habilitation;
}

const modifyHabilitation = async (habilitationUuid: string, payload: Payload) => {
  const request = await buildSafeRequest<HabilitationsRoutes.Update>({
    method: "POST",
    path: "/habilitations/{habilitationUuid}",
    params: {
      habilitationUuid,
    },
    payload,
  });

  return request.run();
};

export default ({ habilitation, onClose }: Props) => {
  const [form, setFormState] = useSetState<IForm>({});
  const { addToast } = useToasts();
  const { t } = useTranslation();

  useEffect(() => {
    setFormState(LoadHabilitationToForm(habilitation));
  }, [setFormState, habilitation]);

  const handleSubmit = (): void => {
    const { form: newForm, hasError } = ValidateForm(form);

    if (hasError) {
      setFormState(newForm);
    }

    if (!hasError) {
      fireSubmitRequest({ habilitation, form, addToast, t, onClose });
    }
  };

  const habilitationIsAlreadyTracked = habilitation.trackingsCount > 0;

  return (
    <Modal
      title={t("trainings.view.regulatory.form.update_habilitation.title", {
        defaultValue: "Modifier une habilitation",
      })}
      onClose={onClose}
      size="small"
      disableOnClickAway
    >
      {habilitationIsAlreadyTracked && (
        <div className={styles.alert}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {t("trainings.view.regulatory.form.update_habilitation.alert_tracking", {
              defaultValue:
                "En modifiant cette habilitation, vous impactez {{count}} lignes de suivi actives. Les lignes archivées ne seront pas modifiées.",
              count: habilitation.trackingsCount,
            })}
          </DSAlert>
        </div>
      )}
      <div className={styles.modifyHabilitation}>
        <HabilitationForm form={form} setFormState={setFormState} />
        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label={t("common.action.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label={t("common.action.confirm", { defaultValue: "Confirmer" })}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

async function fireSubmitRequest({
  habilitation,
  form,
  addToast,
  t,
  onClose,
}: {
  habilitation:
    | {
        duration: string;
        deadlineAnticipation: string;
        validity: "limited";
        name: string;
        uuid: string;
        category: { uuid: string; name: string };
      }
    | { validity: "advitam"; name: string; uuid: string; category: { uuid: string; name: string } };
  form: IForm;
  addToast;
  t;
  onClose: () => void;
}) {
  const request = modifyHabilitation(habilitation.uuid, BuildPayloadFromForm(form));
  await handleRequest(request, addToast, t, onClose);
}

async function handleRequest(request, addToast: any, t: any, onClose: () => void) {
  await Future.unwrap(
    request,
    (err) => {
      addToast(
        formatError(t, err, {
          defaultValue: "Une erreur est survenue lors de la modification de l'habilitation.",
        }),
        { appearance: "error", autoDismiss: true }
      );
    },
    () => {
      addToast(
        t("habilitation.modification.success", {
          defaultValue: "Habilitation modifiée avec succès",
        }),
        { appearance: "success", autoDismiss: true }
      );
      onClose();
    }
  );
}
