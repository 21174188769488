import { useCallback, useMemo, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import moment from "moment";
import { AddToast, useToasts } from "react-toast-notifications";

import { DSButton } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import RequalificationModal from "components/RequalificationModal";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import User from "utils/User";
import type { TrainingRoutes } from "@skillup/espace-rh-bridge";

import { parseReferenceFromName } from "../utils/parseReferenceFromName";

import styles from "./IntraCreationView.module.scss";
import { IntraData, IntraForm } from "../components/IntraForm";
import { isValidIntraData } from "../utils/isValidIntraData";
import { IntraActions, useIntras } from "services/intras";
import useTranslation from "hooks/useTranslation";

type ReferenceEditionState = "inference" | "custom";
type State =
  | IntraData
  | {
      properties: Partial<IntraProperties>;
      tag: undefined;
    };

export default function IntraCreationView() {
  const { create } = useIntras();
  const { intraData, setIntraData, setProperty, setTag } = useIntraCreationData();
  const canCreate = useMemo(
    () => isValidIntraData(intraData, { checkTaxes: User.isCompanyWithTax() }),
    [intraData]
  );
  const { addToast } = useToasts();
  const { t } = useTranslation();

  return (
    <DSLayout
      title={t("trainings.programCreation", { defaultValue: "Création d'un programme" })}
      parent={{
        title: t("trainings.programs", {
          defaultValue: "Programmes",
        }),
        titleUrl: generatePath("/responsable/programmes/gestion-intras/"),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              disabled={!canCreate}
              tooltip={!canCreate ? "Veuillez remplir tous les champs obligatoires" : undefined}
              label="Enregistrer"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(intraData, create, addToast);
              }}
            />
          ),
        },
      ]}
    >
      <div className={styles.creationView}>
        <DSButton
          label="Créer un programme depuis le catalogue"
          buttonSize={"S"}
          emphasis={"Mid"}
          onClick={() => showCatalogModal(setIntraData)}
          type="button"
        />
        <IntraForm setTag={setTag} intraData={intraData} setProperty={setProperty} />
      </div>
    </DSLayout>
  );
}

function useIntraCreationData() {
  const { state: interData } = useLocation<IntraData | undefined>();
  const [intraData, setIntraData] = useState<State>(
    interData ?? { properties: {}, tag: undefined }
  );

  const [referenceEditionState, setReferenceEditionState] =
    useState<ReferenceEditionState>("inference");

  const setProperty = useCallback(
    <T extends keyof IntraProperties>(property: T) =>
      (value: IntraProperties[T]) => {
        let newProperties: Partial<IntraProperties> = {
          [property]: value,
        };

        if (property === "reference") {
          setReferenceEditionState("custom");
        }

        if (property === "name" && referenceEditionState === "inference") {
          newProperties = {
            ...newProperties,
            reference: parseReferenceFromName(value as string),
          };
        }

        setIntraData((oldIntraData) => ({
          tag: oldIntraData.tag,
          properties: {
            ...oldIntraData.properties,
            ...newProperties,
          },
        }));
      },
    [setIntraData, referenceEditionState, setReferenceEditionState]
  );

  const setTag = useCallback(
    (newTag) => {
      setIntraData({
        properties: intraData?.properties,
        tag: newTag,
      });
    },
    [intraData, setIntraData]
  );

  return {
    intraData,
    setIntraData,
    setProperty,
    setTag,
  };
}

function showCatalogModal(setIntraData: (intraData: IntraData) => void) {
  function Footer({ selection, isLoading, setState: localSetState }) {
    const submit = async () => {
      if (!isLoading) {
        localSetState({ isLoading: true });
        const request = buildRequest<TrainingRoutes.Search.GetBySlug>({
          method: "GET",
          path: "/training/{slug}",
          params: {
            slug: selection.seoSlug,
          },
        });
        const data = await request();
        const mappedValues = mapForIntraCreation(data.training);
        setIntraData(mappedValues);
        localSetState({ isLoading: false });
        Acta.dispatchEvent("closeModal");
      }
    };

    return (
      <div className={styles.actions}>
        {selection && (
          <div className={styles.info}>
            <p>Formation séléctionnée</p>
            <b>{selection.name}</b>
            <p>
              {selection.organization} - {selection.price} - {selection.duration}
            </p>
          </div>
        )}
        <div className={styles.spacer} />
        <DSButton
          name="submit"
          label="Créer un intra depuis ce programme"
          disabled={!selection}
          onClick={submit}
          loading={isLoading}
        />
      </div>
    );
  }

  Acta.setState("modalDisplayed", {
    border: true,
    content: (
      <RequalificationModal rows={[]} displayedTrainingTypes={["inter"]} renderFooter={Footer} />
    ),
    noHeader: true,
    size: "xxLarge",
  });
}

export function mapForIntraCreation(
  data,
  typeOfIntraCreation: "duplicationFromProgram" | "creationFromCatalog" = "creationFromCatalog"
): IntraData {
  const { properties, organization, categories } = data;

  const orgWithTax = User.isCompanyWithTax();

  const duration = moment.duration(properties.duration).asHours();
  const [firstList] = categories;
  const tag = {
    value: firstList?.[firstList.length - 1]?.uuid,
    label: firstList?.[firstList.length - 1]?.name,
  };

  return {
    properties: {
      name: properties.name,
      trainingOrganization: organization.name,
      mode: properties.mode,
      duration,
      description: properties.description,
      targetAudience: properties.targetAudience,
      objectives: properties.objectives,
      prerequisites: properties.prerequisites,
      program: properties.program,
      reference: parseReferenceFromName(properties.name),
      trailer: properties.trailer,
      price: typeOfIntraCreation === "duplicationFromProgram" ? properties.price : undefined,
      priceWithTax:
        typeOfIntraCreation === "duplicationFromProgram" && orgWithTax
          ? properties.priceWithTax
          : undefined,
    },
    tag,
  };
}

function handleSubmit(intraData: State, create: IntraActions.CREATE, addToast: AddToast) {
  create({
    data: {
      description: intraData.properties.description,
      duration: intraData.properties.duration,
      mode: intraData.properties.mode,
      prerequisites: intraData.properties.prerequisites,
      price: intraData.properties.price,
      priceWithTax: intraData.properties.priceWithTax,
      program: intraData.properties.program,
      objectives: intraData.properties.objectives,
      targetAudience: intraData.properties.targetAudience,
      name: intraData.properties.name,
      trainingOrganization: intraData.properties.trainingOrganization,
      trailer: intraData.properties.trailer,
      tag: intraData.tag?.value,
      reference: intraData.properties.reference,
    },
    v2: true,
    addToast,
  });
}

export type IntraProperties = {
  description?: string;
  duration: number;
  mode:
    | "presential"
    | "blended"
    | "elearning"
    | "mooc"
    | "distancial"
    | "individual"
    | "digital"
    | "virtual-classroom"
    | "webinar"
    | "conference";
  prerequisites?: string;
  price: number;
  priceWithTax: number | null;
  program?: string;
  objectives?: string;
  targetAudience?: string;
  name: string;
  trainingOrganization: string;
  trailer?: string;
  reference: string;
  slug: string;
};
