import { DSFormGroupTextInput, Modal } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";
import { Campaign } from "./useCampaignModals";
import styles from "./ParameterCampaignModal.module.scss";

interface IProps {
  readonly campaign: Campaign;
}

export default ({ campaign }: IProps) => {
  const { t } = useTranslation();

  const typeEntretienLabel = {
    ongoing: t("campaign.ongoingCampaign", { defaultValue: "Entretiens en continu" }),
    legacy: t("campaign.legacyCampaign", { defaultValue: "Campagne d'entretiens" }),
    adHoc: t("campaign.adHocCampaign", { defaultValue: "Entretiens ponctuels" }),
  };

  return (
    campaign && (
      <Modal title={t("campaign.parameters.title", { defaultValue: "Paramètres de la campagne" })}>
        <div className={styles.content}>
          <DSFormGroupTextInput
            label={t("campaign.parameters.interviewType", { defaultValue: "Type d'entretiens" })}
          >
            <span>{typeEntretienLabel[campaign.type]}</span>
          </DSFormGroupTextInput>

          {campaign.template && (
            <DSFormGroupTextInput
              label={t("campaign.parameters.template", { defaultValue: "Trame d'entretien" })}
            >
              <span>{campaign.template.title}</span>
            </DSFormGroupTextInput>
          )}

          {(campaign.managerOnly || campaign.hideFromEmployeeUntil) && (
            <DSFormGroupTextInput
              label={t("campaign.parameters.workflow", { defaultValue: "Préférences de workflow" })}
            >
              <div>
                {campaign.managerOnly && (
                  <p>
                    {t("campaign.parameters.managerOnly", {
                      defaultValue: "• Entretiens gérés unilatéralement par les responsables",
                    })}
                  </p>
                )}
                {campaign.hideFromEmployeeUntil && (
                  <p>
                    {t("campaign.parameters.hideFromEmployeeUntil", {
                      defaultValue:
                        "• Entretiens ouverts aux collaborateurs uniquement après le partage du responsable",
                    })}{" "}
                  </p>
                )}
              </div>
            </DSFormGroupTextInput>
          )}
        </div>
      </Modal>
    )
  );
};
