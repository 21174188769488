import { useMemo, useState, useCallback } from "react";
import { useSetState } from "react-use";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { isNil } from "lodash";

import {
  DSButton,
  Modal,
  FileDrop,
  Select,
  SelectProps,
  DSFormGroupTextInput,
  RadioBox,
  Label,
} from "@skillup/ui";
import { Future, Either } from "@skillup/monads";

import { buildSafeRequest, buildFileRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

import type { TrackingRoutes, UploadFileRoute } from "types/api";
import { formatError } from "services/errors";

import { useTrackingByUuid } from "../../state/tracking";
import DocumentListItem from "../../Trackings/components/DocumentListItem/DocumentsListItem";
import { TrackingDocument } from "../../types";
import TrackingUuidNotFound from "../TrackingUuidNotFound";

import styles from "./CreateOrManageTrackingDocument.module.scss";

interface Props {
  trackingUuid: string;
  documents: Array<{ uuid: string }>;
  refetchTrackings: () => void;
  onClose: () => void;
}

type Payload = TrackingRoutes.CreateDocument["payload"];

type IForm = {
  type?: Payload["type"] | null;
  file?: File;
  isShared: boolean;
};

const createTrackingDoc = async (
  trackingUuid: string,
  { type, file, isShared }: IForm
): Promise<
  Either<TrackingRoutes.CreateDocument["errors"], TrackingRoutes.CreateDocument["response"]>
> => {
  let fileUuid: string;
  const uploadRequest = buildFileRequest<UploadFileRoute>({
    file,
    method: "POST",
    path: "/files",
    query: {
      context: "regulatory",
    },
  });
  try {
    const uploadedFile = await uploadRequest();
    fileUuid = uploadedFile.uuid;
  } catch (e) {
    return Either.Left("tracking.doc.failed_to_link_doc");
  }

  const request = await buildSafeRequest<TrackingRoutes.CreateDocument>({
    method: "POST",
    path: "/tracking/{uuid}/document",
    params: {
      uuid: trackingUuid,
    },
    payload: {
      type,
      fileUuid,
      isShared,
    },
  });

  return request.run();
};

const initialFormState: IForm = {
  isShared: true,
  file: undefined,
  type: null,
};

export default ({ trackingUuid, documents, refetchTrackings, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const tracking = useTrackingByUuid(trackingUuid);

  const hasDocuments = documents.length > 0;

  const [form, setFormState] = useSetState<IForm>(initialFormState);

  const [loading, setLoading] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(!hasDocuments);

  const isFormStateValid = useMemo(() => form.type && form.file && !isNil(form.isShared), [form]);

  const submitRequest = async () => {
    const request = createTrackingDoc(trackingUuid, form);
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de l'ajout du document",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        setShowUploadFile(false);
      },
      (_habilitation) => {
        addToast(
          t("tracking.document.creation.success", {
            defaultValue: "Document ajouté avec succès",
          }),
          {
            appearance: "success",
          }
        );

        refetchTrackings();
        setFormState(initialFormState);
      }
    );

    setLoading(false);
  };

  function handleInputChange(key: keyof IForm) {
    return (value: IForm[typeof key]) => {
      setFormState({
        [key]: value,
      });
    };
  }

  const onDrop = useCallback(
    async ([file]: File[]) => {
      setFormState({
        file,
      });
    },
    [setFormState]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
  });

  const selectOptions: SelectProps<Payload["type"]>["options"] = [
    {
      value: "training_certificate",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.training_certificate", {
        defaultValue: "Attestation de formation",
      }),
    },
    {
      value: "driving_authorisation",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.driving_authorisation", {
        defaultValue: "Autorisation de conduite",
      }),
    },
    {
      value: "medical_certificate",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.medical_certificate", {
        defaultValue: "Certificat médical",
      }),
    },
    {
      value: "summons",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.summons", {
        defaultValue: "Convocation",
      }),
    },
    {
      value: "license",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.license", {
        defaultValue: "Permis",
      }),
    },
    {
      value: "other",
      label: t("trainings.view.regulatory_collaborator_tracking.modal.other", {
        defaultValue: "Divers",
      }),
    },
  ];

  if (tracking.isLeft()) {
    return <TrackingUuidNotFound trackingUuid={trackingUuid} onClose={onClose} />;
  }

  const data = tracking.right();

  return (
    <Modal
      title={
        hasDocuments
          ? t("trainings.view.regulatory_collaborator_tracking.modal.manage.title", {
              defaultValue: "Gérer les documents",
            })
          : t("trainings.view.regulatory_collaborator_tracking.modal.add.title", {
              defaultValue: "Ajouter un document",
            })
      }
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.createOrManageDocument}>
        <div className={styles.trackingUser}>
          {t("trainings.view.regulatory_collaborator_tracking.modal.manage.habilitation_for", {
            defaultValue: "{{ habilitation }} pour {{ user }}",
            habilitation: data.habilitation.name,
            user: data.user.fullName,
          })}
        </div>
        {hasDocuments && (
          <div className={styles.manageDocument}>
            {documents.map((document: TrackingDocument, key) => (
              <DocumentListItem
                document={document}
                trackingUuid={trackingUuid}
                refetchTrackings={refetchTrackings}
                key={key}
              />
            ))}
          </div>
        )}

        {!showUploadFile && (
          <div className={styles.addButtonWrapper}>
            <DSButton
              className={styles.addButton}
              onClick={() => setShowUploadFile(true)}
              emphasis="Low"
              label={t("trainings.view.regulatory_collaborator_tracking.action.add-document", {
                defaultValue: "Ajouter un document",
              })}
            />
          </div>
        )}

        {/* block upload */}
        {showUploadFile && (
          <div className={styles.createDocumentWrapper}>
            <div className={styles.createDocument}>
              {hasDocuments && (
                <div className={styles.title}>
                  {t("trainings.view.regulatory_collaborator_tracking.action.add-document", {
                    defaultValue: "Ajouter un document",
                  })}
                </div>
              )}
              <div className={styles.inputLine}>
                <DSFormGroupTextInput
                  className={styles.label}
                  label={t("trainings.view.regulatory_collaborator_tracking.modal.file_type", {
                    defaultValue: "Type de fichier",
                  })}
                  required
                >
                  <Select
                    className={styles.input}
                    placeholder={t(
                      "trainings.view.regulatory_collaborator_tracking.modal.file_type_placeholder",
                      {
                        defaultValue: "Sélectionner un type de fichier",
                      }
                    )}
                    value={form.type}
                    options={selectOptions}
                    onChange={(value) => handleInputChange("type")(value)}
                    autoFocus
                  />
                </DSFormGroupTextInput>
              </div>
              <div className={styles.inputLine}>
                <DSFormGroupTextInput className={styles.label}>
                  <FileDrop
                    className={styles.fileDrop}
                    isDragActive={isDragActive}
                    rootProps={getRootProps()}
                    inputProps={getInputProps()}
                    btnLabel={t(
                      "trainings.view.regulatory_collaborator_tracking.modal.action.select_document",
                      {
                        defaultValue: "Sélectionner un document",
                      }
                    )}
                    dropLabel={t(
                      "trainings.view.regulatory_collaborator_tracking.modal.action.drop_document",
                      {
                        defaultValue: "Glissez-déposez ici le document",
                      }
                    )}
                    value={form.file?.name}
                  />
                </DSFormGroupTextInput>
              </div>

              <div className={styles.inputLine}>
                <Label
                  label={t(
                    "trainings.view.regulatory_collaborator_tracking.modal.action.share_document",
                    {
                      defaultValue: "Partager le document avec le collaborateur et son manager",
                    }
                  )}
                />
                <div className={styles.radioBoxes}>
                  <RadioBox
                    className={styles.radioBoxElement}
                    checked={form?.isShared === true}
                    label={t("common.view.radio_box.yes", {
                      defaultValue: "Oui",
                    })}
                    value={true}
                    onClick={(v) =>
                      isNil(v)
                        ? handleInputChange("isShared")(undefined)
                        : handleInputChange("isShared")(v)
                    }
                  />
                  <RadioBox
                    className={styles.radioBoxElement}
                    checked={form?.isShared === false}
                    label={t("common.view.radio_box.no", {
                      defaultValue: "Non",
                    })}
                    value={false}
                    onClick={(v) =>
                      isNil(v)
                        ? handleInputChange("isShared")(undefined)
                        : handleInputChange("isShared")(v)
                    }
                  />
                </div>
                {form?.isShared && (
                  <p className={styles.warningText}>
                    {t(
                      "trainings.view.regulatory_collaborator_tracking.modal.collab_manager_find_document",
                      {
                        defaultValue:
                          "Le collaborateur et son manager retrouveront ce document dans leur espace personnel",
                      }
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              <DSButton
                className={styles.button}
                disabled={!isFormStateValid}
                loading={loading}
                onClick={submitRequest}
                emphasis="High"
                label={t(
                  "trainings.view.regulatory_collaborator_tracking.modal.action.add_document",
                  {
                    defaultValue: "Ajouter le document",
                  }
                )}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
