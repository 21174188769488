import { TranslationType } from "hooks/useTranslation";
import Acta from "utils/Acta";

export const switchOnError = (err: Error, t: TranslationType) => {
  switch (err.message) {
    case "users":
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.training_schedule.add_row.error.users.label", {
          defaultValue: "Veuillez sélectionner au moins un collaborateur",
        }),
        type: "error",
      });
      break;
    case "address":
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.training_schedule.add_row.error.address.label", {
          defaultValue: "Veuillez indiquer un lieu de session",
        }),
        type: "error",
      });
      break;
    case "dates":
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.training_schedule.add_row.error.dates.label", {
          defaultValue: "Veuillez renseigner les dates de la session",
        }),
        type: "error",
      });
      break;
    case "invalidEndDate":
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.training_schedule.add_row.error.invalid_end_date.label", {
          defaultValue: "La date de fin de session est antérieure à la date de début de session",
        }),
        type: "error",
      });
      break;
    default:
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.training_schedule.add_row.error.send_app_message.label", {
          defaultValue: "Une erreur est survenue lors de cet ajout de ligne",
        }),
        type: "error",
      });
      break;
  }
};
