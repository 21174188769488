import React from "react";

import { Project } from "../getProjects";
import Acta from "utils/Acta";

import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";
import { TranslationType } from "hooks/useTranslation";

const showDeleteConfirmationModal = (
  project: Project,
  refetchList: VoidFunction,
  t: TranslationType
) => {
  Acta.setState("modalDisplayed", {
    title: t("trainings.entity.session.deleteModal.title", {
      defaultValue: "Supprimer la session",
    }),
    size: "small",
    content: <DeleteConfirmationModal refetchList={refetchList} project={project} />,
  });
};

export default showDeleteConfirmationModal;
