import moment from "moment";
import { Currency, getMonetarySymbol, NumberUtil } from "@skillup/shared-utils";
import { DSTooltip } from "@skillup/ui";
import { QuestionData } from "../../../types";
import { GridAlignment, GridColDef } from "@mui/x-data-grid-pro";

import styles from "./Summary.module.scss";

export const generateColumns = (t, data: QuestionData, currency: Currency): GridColDef<any>[] => {
  const currencySymbol = getMonetarySymbol({ currency });

  const columns = data?.columns?.map((column) => ({
    field: column.name,
    sortable: false,
    headerName: t(`training.dashboard.grid.column.header.${column.name}`, {
      // type should be monetary for currency columns
      defaultValue: column.type === "number" ? `${column.name} ({{currency}})` : column.name,
      currency: currencySymbol,
    }),
    renderCell: ({ row }) => (
      <DSTooltip label={row.data[column.name]} className={styles.TooltipCell} enabledOnOverflow>
        {column.name === "training_plan_plan_completion_phase"
          ? t(row.data[column.name] ?? "training.dashboard.grid.column.status.total")
          : row.data[column.name] ?? 0}
      </DSTooltip>
    ),
    align:
      column.name === "training_plan_plan_completion_phase" ? "left" : ("right" as GridAlignment),
    headerAlign:
      column.name === "training_plan_plan_completion_phase" ? "left" : ("right" as GridAlignment),
    minWidth: 110,
    flex: 1,
    type: column.type === "number" ? "number" : "string",
  }));

  const statusColumnIndex = columns?.findIndex(
    (column) => column.field === "training_plan_plan_completion_phase"
  );
  const statusColumn = columns?.splice(statusColumnIndex, 1)[0];
  columns?.unshift(statusColumn);

  return columns || [];
};

export const generateRows = (t, data: QuestionData) => {
  if (!data) return [];

  return data?.data.map((row, index) => ({
    id: index,
    data: row.reduce((acc, value, i) => {
      if (data?.columns[i].type === "duration") {
        const hours = moment.duration(value).asHours();
        const roundedHours = Math.ceil(hours);
        acc[data.columns[i].name] = NumberUtil.decimalNumberToFormatedString(roundedHours);
        return acc;
      }

      if (data?.columns[i].type === "number") {
        const roundedNumber = Math.ceil(Number(value));
        acc[data.columns[i].name] = NumberUtil.decimalNumberToFormatedString(roundedNumber);
        return acc;
      }

      if (data?.columns[i].name === "Statut") {
        if (value === null) {
          acc[data.columns[i].name] = t("training.dashboard.grid.column.status.total", {
            defaultValue: "Total plan",
          });
        } else {
          acc[data.columns[i].name] = t(value);
        }

        return acc;
      }

      acc[data.columns[i].name] = value;
      return acc;
    }, {}),
  }));
};
