import { MdSearch as Search } from "react-icons/md";
import { Fragment, useCallback, useState } from "react";
import { useToggle } from "react-use";
import { isEmpty, isNil } from "lodash";
import { atom, useRecoilState } from "recoil";
import useTranslation from "hooks/useTranslation";

import { colors, DataTable, DSDropdownItem, DSTextInput } from "@skillup/ui";
import { InterviewWithStates, IRoutes } from "@skillup/types";
import { CampaignTemplate, ISO8601Date, InterviewsRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import DataLayer from "utils/DataLayer";

import { CampaignUserUpdateModal } from "./modals";
import { ReopenModal } from "./modals";
import { DeletionModal } from "./modals";

import InterviewsHeader from "./InterviewsHeader";
import useTableData from "./useTabledata";

import styles from "./InterviewsStyles.module.scss";

interface IProps {
  readonly campaign: {
    uuid: string;
    titleForHR: string;
    type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
    template?: CampaignTemplate;
    archivedAt?: ISO8601Date;
  };
  readonly interviews: InterviewWithStates[];
  readonly resync: () => Promise<InterviewWithStates[]>;
}

function Interviews({ campaign, interviews, resync }: IProps) {
  const [isDownloadingPDF, toggleDownloadingPDF] = useToggle(false);
  const [isSendingReminder, toggleSendingReminder] = useToggle(false);
  const [filterQuery, setFilterQuery] = useState<string>();
  const { t } = useTranslation();

  const downloadInterviewAsPDF = useCallback(
    async (interview): Promise<InterviewWithStates> => {
      if (isDownloadingPDF) return undefined;

      toggleDownloadingPDF(true);

      try {
        await downloadTypedFileAsUser<InterviewsRoutes.GetPDF>(
          {
            method: "GET",
            path: "/interview/{interviewUuid}/pdf",
            params: { interviewUuid: interview.uuid },
          },
          {
            target: "API",
            deduceFileDataFromResponseHeaders: true,
          }
        );
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: t("campaign.interview.canDownloadInterview", {
            defaultValue: "Impossible de télécharger l'entretien",
          }),
          type: "error",
        });
      } finally {
        toggleDownloadingPDF(false);
        return undefined;
      }
    },
    [toggleDownloadingPDF, isDownloadingPDF, t]
  );
  const sendReminder = useCallback(
    async (interview): Promise<InterviewWithStates> => {
      if (isSendingReminder) return undefined;

      toggleSendingReminder(true);

      try {
        await DataLayer.request({
          method: "POST",
          url: `/v1/campaigns/${campaign.uuid}/interviews/${interview.uuid}/reminder`,
          body: JSON.stringify({}),
        });

        Acta.dispatchEvent("sendAppMessage", {
          message: t("campaign.interview.reminderSent", {
            defaultValue: "Relance envoyée.",
          }),
          type: "success",
        });
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: t("campaign.interview.reminderNotSent", {
            defaultValue: "Impossible d'envoyer une relance.",
          }),
          type: "error",
        });
      } finally {
        toggleSendingReminder(false);
        return undefined;
      }
    },
    [toggleSendingReminder, isSendingReminder, campaign, t]
  );

  const { columns, data: dataTable } = useTableData(interviews ?? [], campaign.type);
  const paginationHook = useRecoilState(interviewsPagination);

  const interviewsToDisplay =
    isNil(filterQuery) && isEmpty(filterQuery)
      ? dataTable
      : dataTable.filter((interview) =>
          `${interview.data.collaborator} ${interview.data.manager}`
            .toLowerCase()
            .includes(filterQuery.toLowerCase())
        );

  const isCampaignClosed = !isNil(campaign?.archivedAt);

  return (
    <div className={styles.Interviews}>
      <label className={styles.label}>
        {t("campaign.interview.searchCollabOrManager.title", {
          defaultValue: "Rechercher un entretien",
        })}
      </label>

      <div className={styles.row}>
        <div className={styles.search}>
          <DSTextInput
            name="search-collab-or-manager"
            value={filterQuery}
            onChange={(newQuery) => setFilterQuery(newQuery)}
            placeholder={t("campaign.interview.searchCollabOrManager", {
              defaultValue: "Collaborateur, responsable",
            })}
            actionButton={<Search size="1.375rem" color={colors.blueyGrey} />}
          />
        </div>
      </div>

      <DataTable
        columns={columns}
        rows={interviewsToDisplay}
        header={{
          selectedRowsLabel: (selected, total) =>
            t("campaign.interview.interviewsToDisplay", {
              defaultValue: "{{count}} entretien sélectionné sur {{total}}",
              defaultValue_other: "{{count}} entretiens sélectionnés sur {{total}}",
              count: selected,
              total,
            }),
          totalRowsLabel: (total) => {
            if (total < interviews.length) {
              return t("campaign.interview.filteredInterviews", {
                defaultValue: "{{count}} entretien filtré sur {{total}}",
                defaultValue_other: "{{count}} entretiens filtrés sur {{total}}",
                count: total,
                total: interviews.length,
              });
            } else {
              return t("campaign.interview.interviews", {
                defaultValue: "{{count}} entretien",
                defaultValue_other: "{{count}} entretiens",
                count: total,
              });
            }
          },
          actions: () => (
            <InterviewsHeader campaign={campaign} interviews={interviews} resync={resync} />
          ),
        }}
        defaultSort={{ key: "collaboratorStatus", desc: false }}
        actions={(row) => {
          const interview = interviews.find((i) => i.uuid === row.id);
          return (
            <Fragment>
              {campaign.type === "adHoc" && (
                <DSDropdownItem
                  key="send-reminder"
                  label={
                    isSendingReminder
                      ? t("campaign.interview.ongoingReminder", {
                          defaultValue: "Envoi en cours...",
                        })
                      : t("campaign.interview.sendReminder", {
                          defaultValue: "Envoyer une relance",
                        })
                  }
                  onClick={() => {
                    if (!isSendingReminder) return sendReminder(interview);
                    return undefined;
                  }}
                  disabled={isDownloadingPDF}
                />
              )}
              <DSDropdownItem
                key="download-pdf"
                label={
                  isDownloadingPDF
                    ? t("campaign.interview.ongoingExport", {
                        defaultValue: "Export en cours...",
                      })
                    : t("campaign.interview.exportInterview", {
                        defaultValue: "Exporter l’entretien (PDF)",
                      })
                }
                onClick={() => {
                  if (!isDownloadingPDF) return downloadInterviewAsPDF(interview);
                  return undefined;
                }}
                disabled={isDownloadingPDF}
              />
              <DSDropdownItem
                key="change-manager"
                label={t("campaign.interview.changeManager", {
                  defaultValue: "Changer le responsable de l’entretien",
                })}
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <CampaignUserUpdateModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: t("campaign.interview.changeUserManager", {
                      defaultValue: "Changer le responsable de {{fullName}}",
                      fullName: interview.employee.fullName,
                    }),
                  })
                }
                disabled={
                  interview.manager.state === "signed" ||
                  interview.employee.state === "signed" ||
                  isCampaignClosed
                }
              />
              <DSDropdownItem
                key="open-interview"
                label={t("campaign.interview.reopenInterview", {
                  defaultValue: "Rouvrir l’entretien",
                })}
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <ReopenModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: t("campaign.interview.reopenInterview", {
                      defaultValue: "Rouvrir l’entretien",
                    }),
                  })
                }
                disabled={
                  (!["signed", "unsigned"].includes(interview.manager.state) &&
                    !["signed", "unsigned"].includes(interview.employee.state)) ||
                  isCampaignClosed
                }
              />
              <DSDropdownItem
                key="delete-interview"
                label={t("campaign.interview.deleteInterview", {
                  defaultValue: "Supprimer l'entretien",
                })}
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <DeletionModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: t("campaign.interview.deleteInterview", {
                      defaultValue: "Supprimer l'entretien",
                    }),
                  })
                }
              />
            </Fragment>
          );
        }}
        pagination={{
          rowsPerPageLabel: t("footer.linesPerPage", {
            defaultValue: "Lignes par page",
          }),
          itemsCountLabel: t("manager.interviews.columns.itemscountlabel", {
            defaultValue: "Entretiens %range% sur %count%",
          }),
          pageLabel: t("manager.interviews.page", {
            defaultValue: "Page",
          }),
          stateHook: paginationHook,
        }}
        mode="compact"
      />
    </div>
  );
}

const interviewsPagination = atom<number>({
  key: "interviews.pagination",
  default: 10,
});

export default Interviews;
