import useTranslation from "hooks/useTranslation";

import { DSDropdown, DSDropdownDivider, DSDropdownItem } from "@skillup/ui";
import { ScheduleItemProps } from "./ScheduleItem";

export default function ScheduleItemMenu({ schedule, onEdit }: ScheduleItemProps) {
  const { t } = useTranslation();
  return (
    <DSDropdown>
      <DSDropdownItem
        label={t("supervisor.portalschedules.schedule.rename", {
          defaultValue: "Renommer",
        })}
        onClick={() => onEdit("rename")}
      />
      <DSDropdownDivider />
      <DSDropdownItem
        label={
          schedule.budget
            ? t("supervisor.portalschedules.schedule.budget.edit", {
                defaultValue: "Modifier le budget",
              })
            : t("supervisor.portalschedules.schedule.budget.add", {
                defaultValue: "Ajouter un budget",
              })
        }
        onClick={() => onEdit("budget")}
      />
      <DSDropdownDivider />
      {!schedule.active &&
        !schedule.archivedAt && [
          <DSDropdownItem
            label={t("supervisor.portalschedules.schedule.activate", {
              defaultValue: "Rendre actif",
            })}
            onClick={() => onEdit("activate")}
          />,
          <DSDropdownDivider />,
        ]}
      <DSDropdownItem
        label={
          schedule.isImported
            ? t("supervisor.portalschedules.schedule.markAsNotImported", {
                defaultValue: "Ne plus considérer comme plan importé",
              })
            : t("supervisor.portalschedules.schedule.markAsImported", {
                defaultValue: "Considérer comme plan importé",
              })
        }
        onClick={() => onEdit(schedule.isImported ? "mask-as-not-imported" : "mask-as-imported")}
      />
      {!schedule.active && [
        <DSDropdownDivider />,
        <DSDropdownItem
          label={
            schedule.archivedAt
              ? t("supervisor.portalschedules.schedule.archive", {
                  defaultValue: "Désarchiver",
                })
              : t("supervisor.portalschedules.schedule.unarchive", {
                  defaultValue: "Archiver",
                })
          }
          onClick={() => onEdit(schedule.archivedAt ? "unarchive" : "archive")}
        />,
        <DSDropdownItem
          label={t("supervisor.portalschedules.schedule.delete", {
            defaultValue: "Supprimer",
          })}
          onClick={() => onEdit("delete")}
        />,
      ]}
    </DSDropdown>
  );
}
