import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import { useParams, generatePath } from "react-router-dom";
import { useMemo, useState, useEffect, useCallback } from "react";

import { Formik, useFormikContext } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useMediaQueries } from "@skillup/ui";
import { Flex, Loader, Footer } from "@skillup/design-system";

import { RouterInputs } from "utils/trpc";
import DSLayout from "components/DSLayout";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import {
  Job,
  Field,
  FormJob,
  JobFields,
  JobSection,
  JobTemplate,
  FieldEntity,
  TemplateField,
} from "types/skills";

import { JobSchema } from "../../JobFormSchema";
import { JobForm } from "../../components/Form/JobForm";
import { parseFormFields } from "../../components/Form/helper";
import { JobsProvider, useJobsContext } from "../../JobsContext";
import {
  createSkills,
  getAllJobTemplates,
  getAllFieldsForEntity,
  getDefaultEvaluationScale,
} from "../../../queries";

interface ButtonProps {
  loading: boolean;
  t: TranslationType;
  state: CreateJobState;
}

type FieldType = Exclude<RouterInputs["fields"]["getAllFields"], void>["entity"];

const parseFormtemplateFields = (fields: JobSection[]): JobFields[] => {
  const formFieldValues = fields.map((field) => ({
    uuid: field.uuid,
    index: field.index,
    name: field.name,
    value: field.content ?? "",
  }));

  return formFieldValues;
};

const HandleSubmitButton = ({ loading, state, t }: ButtonProps) => {
  const { dirty, isValid, submitForm } = useFormikContext();
  const { isButtonDisabled, tooltip } = useButtonStatus(state, { dirty, isValid });

  return (
    <DSNewHeaderButton
      type="submit"
      loading={loading}
      tooltipDarkMode={false}
      disabled={isButtonDisabled}
      tooltip={isButtonDisabled ? tooltip : undefined}
      onClick={submitForm}
      label={t("jobs.button.createTheJob", {
        defaultValue: "Créer la fiche de poste",
      })}
    />
  );
};

const CreateJob = () => {
  return (
    <JobsProvider>
      <Layout />
    </JobsProvider>
  );
};

const Layout = () => {
  const [state, setState] = useState<CreateJobState>({
    step: "set-title",
  }) as [CreateJobState, (newState: CreateJobState) => void];
  const { t } = useTranslation();
  const [loading, toggleLoading] = useToggle(false);
  const { isMobile } = useMediaQueries();
  const { addToast } = useToasts();
  const { jobUuid } = useParams<{ jobUuid: string }>();

  const { createJob, getJob, jobList } = useJobsContext();

  const { data, status } = getJob(jobUuid);

  const job = useMemo(() => {
    if (!data) return undefined;
    return { ...data, updatedAt: new Date(data?.updatedAt) } as unknown as Job;
  }, [data]);

  const jobNames = jobList?.map((j) => j.name);

  const fieldResult = getAllFieldsForEntity(FieldEntity.JOB as unknown as FieldType);
  const fields: Field[] | undefined = fieldResult.data as unknown as Field[] | undefined;

  const defaultEvaluationScaleResult = getDefaultEvaluationScale();
  const defaultEvaluationScale = defaultEvaluationScaleResult.data;

  const templateFieldsResult = getAllJobTemplates();
  const templateFields: undefined | JobTemplate[] =
    templateFieldsResult.data as unknown as JobTemplate[];

  const handleSubmit = useCallback(
    async (values: FormJob) => {
      toggleLoading(true);

      const getFieldValues = (fields: JobFields[], valueField: "value" | "content") => {
        return fields
          .filter((field) => field.value)
          .map((field): TemplateField => {
            return {
              uuid: field.uuid,
              [valueField]: field.value,
            } as TemplateField;
          });
      };

      const skillGeneratedWithAi = values.skills.filter((s) => s.generated);
      const skillsAlreadyExisting = values.skills
        .filter((s) => !s.generated)
        .map((s) => ({
          expectedLevelUuid: s.expectedLevelUuid ?? null,
          skillId: s.skillUuid,
        }));

      const payload = {
        command: "create",
        fields: getFieldValues(values.fields, "value"),
        generatedWithAi: state.step === "ai-generation-finished",
        name: values.jobName,
        sections: getFieldValues(values.sections, "content"),
        skills: skillsAlreadyExisting,
      };

      if (skillGeneratedWithAi.length > 0) {
        const createSkillsPayload = skillGeneratedWithAi.map((skill) => ({
          categoryId: skill.categoryId,
          description: skill.description ?? "",
          evaluationScaleUuid: defaultEvaluationScale.uuid,
          fields: [],
          generatedWithAi: true,
          name: skill.skillName,
        }));

        createSkills(createSkillsPayload)
          .then((skillsCreated) => {
            const newSkillsGeneratedByAi = skillsCreated.map((skill, index) => ({
              expectedLevelUuid: skillGeneratedWithAi[index].expectedLevelUuid ?? null,
              skillId: skill.uuid,
            }));

            payload.skills = [...payload.skills, ...newSkillsGeneratedByAi];
            setTimeout(() => {
              createJob({ ...payload, generatedWithAi: true });
            }, 3000);
          })
          .catch(() => {
            toggleLoading(false);
            addToast(
              t("portal.config.skills.createSkills.error", {
                defaultValue: "Les compétences générées par l'IA n'ont pas pu être créées",
              }),
              {
                appearance: "error",
              }
            );
          });
      } else {
        createJob(payload);
      }
    },
    [addToast, createJob, defaultEvaluationScale?.uuid, state, t, toggleLoading]
  );

  useEffect(() => {
    if (jobUuid && job) {
      setState({ step: "duplicate-job" });
    }
  }, [jobUuid, job]);

  const initialValues = useMemo(() => {
    return {
      uuid: job?.uuid ?? undefined,
      fields: job?.fields ? parseFormFields(job?.fields) : parseFormFields(fields as JobFields[]),
      jobName: job?.name ? job?.name + " (copie)" : "",
      sections: job?.sections
        ? parseFormtemplateFields(job?.sections)
        : parseFormFields(templateFields as JobFields[]),
      skills: job?.skills
        ? job?.skills.map(({ expectedLevelUuid, skill }) => ({
            expectedLevelUuid,
            skillUuid: skill.uuid,
          }))
        : [],
    };
  }, [fields, job, templateFields]);

  if (fieldResult.isLoading || (status !== "success" && jobUuid)) {
    return (
      <DSLayout
        title={t("jobs.label.newJob", {
          defaultValue: "Nouvelle fiche de poste",
        })}
      >
        <Loader />
      </DSLayout>
    );
  }

  return (
    <>
      {isMobile ? (
        <NotAvailableInMobileView />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(JobSchema)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validate={(values) => {
            if (jobNames?.includes(values.jobName)) {
              return {
                jobName: t("jobs.form.error.nameAlreadyTaken", {
                  defaultValue: "Une fiche de poste avec cet intitulé existe déjà",
                }),
              };
            }
            return {};
          }}
        >
          {(formikProps) => {
            return (
              <DSLayout
                isRelative={true}
                title={t("jobs.label.newJob", {
                  defaultValue: "Nouvelle fiche de poste",
                })}
                parent={{
                  title: t("jobs.title.jobSheets", {
                    defaultValue: "Fiches de poste",
                  }),
                  titleUrl: generatePath("/responsable/referentiels/fiches-de-poste"),
                }}
              >
                <Flex flex="1" flexDirection="column">
                  <JobForm
                    state={state}
                    setState={setState}
                    isCreationPage={true}
                    formikProps={formikProps}
                    onTitleChange={() => toggleLoading(false)}
                  />
                </Flex>

                {state.step !== "set-title" && (
                  <Footer
                    containerWidth={"650px"}
                    firstColumnChildren={
                      <HandleSubmitButton t={t} state={state} loading={loading} />
                    }
                  />
                )}
              </DSLayout>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export type CreateJobState =
  | { step: "set-title" }
  | { step: "duplicate-job" }
  | { step: "manual-creation" }
  | { step: "ai-generation-finished" }
  | { step: "ai-job-generation-loading" };

function useButtonStatus(state: CreateJobState, formikProps: { dirty: boolean; isValid: boolean }) {
  const { t } = useTranslation();
  const isInputEmpty = !formikProps.isValid || !formikProps.dirty;

  const mandatoryFieldTranslation = t("portal.config.skills.tooltip-manual-creation", {
    defaultValue:
      "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
  });

  const aiGenerationLoadingTranslation = t("portal.config.skills.tooltip-ai-loading", {
    defaultValue: "La génération de votre fiche de poste peut prendre une dizaine de secondes",
  });

  const cannotCreateJobTranslation = t("portal.config.skills.tooltip-set-title", {
    defaultValue: "Vous ne pouvez pas créer la fiche de poste à ce stade",
  });

  switch (state.step) {
    case "manual-creation":
      return {
        isButtonDisabled: isInputEmpty,
        tooltip: mandatoryFieldTranslation,
      };
    case "ai-job-generation-loading":
      return {
        isButtonDisabled: true,
        tooltip: aiGenerationLoadingTranslation,
      };
    case "ai-generation-finished":
      return {
        isButtonDisabled: false,
        tooltip: undefined,
      };
    case "duplicate-job":
      return {
        isButtonDisabled: false,
        tooltip: undefined,
      };
    case "set-title":
    default:
      return {
        isButtonDisabled: true,
        tooltip: isInputEmpty ? mandatoryFieldTranslation : cannotCreateJobTranslation,
      };
  }
}

export default CreateJob;
