import { useState } from "react";
import { Flex } from "@skillup/ui";
import DSLayout from "components/DSLayout";
import User from "utils/User";
import styles from "./TargetCategoriesAndScalesLists.module.scss";
import { TargetCategoriesDataGrid } from "./components/TargetCategoriesDataGrid";
import { TargetCategoriesLegacyDataTable } from "./components/TargetCategoriesLegacyDataTable";
import { TargetScaleDataGrid } from "./components/TargetScalesDataGrid";
import { TargetScaleDataGridLegacy } from "./components/TargetScalesDataGridLegacy";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import { TargetCategoriesRoutes } from "@skillup/espace-rh-bridge";
import { useToasts } from "react-toast-notifications";
import { useTargetCategories } from "./hooks/useTargetCategories";
import useTranslation from "hooks/useTranslation";

async function exportTargetCategories() {
  await downloadTypedFileAsUser<TargetCategoriesRoutes.ExportCategories>(
    {
      method: "GET",
      path: "/targetCategories/export",
    },
    {
      target: "API",
      deduceFileDataFromResponseHeaders: true,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }
  );
}

export const TargetCategoriesAndScalesLists = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { targetCategories } = useTargetCategories();
  const { t } = useTranslation();

  const handleExportCategories = async () => {
    try {
      setLoading(true);
      await exportTargetCategories();
    } catch (err) {
      addToast(
        t("supervisor.interviews.configuration.export.error", {
          defaultValue: "Une erreur est survenue lors de l'export des catégories",
        }),
        {
          appearance: "error",
        }
      );
    }
    setLoading(false);
  };

  return (
    <DSLayout
      title={t("supervisor.interviews.configuration.layout", {
        defaultValue: "Configuration des entretiens et des objectifs",
      })}
      layouts={
        User.isSkillupAdmin() && targetCategories?.length > 0
          ? [
              {
                primaryButton: (
                  <DSNewHeaderButton
                    label={t("supervisor.interviews.configuration.export", {
                      defaultValue: "[OPS] Exporter les catégories d'objectifs",
                    })}
                    onClick={handleExportCategories}
                    loading={loading}
                  />
                ),
              },
            ]
          : undefined
      }
    >
      <Flex className={styles.container} column>
        <Flex className={styles.blockTable} column>
          <h4>
            {t("supervisor.interviews.configuration.categories", {
              defaultValue: "Catégories d'objectifs",
            })}
          </h4>
          <TargetCategoriesDataGrid />
        </Flex>
        {User.isSkillupDeveloper() && ( // double security on this legacy table that will have to be removed or hidden before releasing the page
          <Flex className={styles.blockTable} column>
            <h4>
              {t("supervisor.interviews.configuration.legacyCategories", {
                defaultValue: "Catégories d'objectifs (LEGACY)",
              })}
            </h4>
            <TargetCategoriesLegacyDataTable />
          </Flex>
        )}
        {User.isSkillupAdmin() && ( // not released yet
          <Flex className={styles.blockTable} column>
            <h4>
              {t("supervisor.interviews.configuration.targetScale", {
                defaultValue: "Échelles d'évaluation pour les objectifs qualitatifs",
              })}
            </h4>
            <TargetScaleDataGrid />
          </Flex>
        )}
        {User.isSkillupDeveloper() && ( // double security on this legacy table that will have to be removed or hidden before releasing the page
          <Flex className={styles.blockTable} column>
            <h4>
              {t("supervisor.interviews.configuration.legacyTargetScale", {
                defaultValue: "Échelles d'évaluation pour les objectifs qualitatifs (LEGACY)",
              })}
            </h4>
            <TargetScaleDataGridLegacy />
          </Flex>
        )}
      </Flex>
    </DSLayout>
  );
};
