export const FilterType = {
  TEXT: "text",
  MULTISELECT: "multiselect",
  SINGLESELECT: "select",
  NUMBER: "number",
  DATE: "date",
} as const;

export const FilterOperator = {
  CONTAINS: "contains",
  EQUALS: "equals",
  GREATER_THAN: "greater_than",
  GREATER_THAN_OR_EQUAL: "greater_than_or_equal",
  LESS_THAN: "less_than",
  LESS_THAN_OR_EQUAL: "less_than_or_equal",
  BEFORE: "before",
  AFTER: "after",
  BETWEEN: "between",
} as const;

export type FilterTypes = (typeof FilterType)[keyof typeof FilterType];
export type FilterOperators = (typeof FilterOperator)[keyof typeof FilterOperator];

const operatorList = [
  FilterOperator.CONTAINS,
  FilterOperator.EQUALS,
  FilterOperator.GREATER_THAN,
  FilterOperator.LESS_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN_OR_EQUAL,
  FilterOperator.BETWEEN,
];

export const FilterTypeOperator: {
  [key in FilterTypes]: FilterOperators[];
} = {
  [FilterType.TEXT]: operatorList,
  [FilterType.MULTISELECT]: operatorList,
  [FilterType.SINGLESELECT]: operatorList,
  [FilterType.NUMBER]: operatorList,
  [FilterType.DATE]: operatorList,
};

export type FilterValueType = {
  [FilterType.TEXT]: string;
  [FilterType.MULTISELECT]: Array<string> | Array<number>;
  [FilterType.SINGLESELECT]: Array<string> | string | number;
  [FilterType.NUMBER]: number | [number, number];
  [FilterType.DATE]: Date | [Date, Date];
};

export type FilterVisibility = "always" | "never" | "default";

export interface FilterConfiguration<FilterType extends FilterTypes = FilterTypes> {
  type: FilterType;
  label?: string;
}

export interface FilterValue<FilterType extends FilterTypes = FilterTypes>
  extends FilterConfiguration<FilterType> {
  operator: (typeof FilterTypeOperator)[FilterType][number];
  value: FilterValueType[FilterType] | undefined;
  data?: any;
}

export type FilterValues<T extends FilterConfigurationMap> = {
  [key in keyof T]: FilterValue<T[key]["type"]>;
};

export type FilterConfigurationMap = {
  [id: string]: FilterConfiguration;
};
