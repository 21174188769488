import type { InterviewTypes } from "types/api";
import { InterviewType } from "@skillup/espace-rh-bridge";

export enum InterviewRoles {
  EMPLOYEE = "employee",
  MANAGER = "manager",
  OBSERVER = "observer",
}

const interviewTypes: InterviewType[] = [
  "Autre",
  "Bilan à 6 ans",
  "Entretien annuel",
  "Entretien professionnel",
];

export { interviewTypes, type InterviewType, type InterviewTypes };
