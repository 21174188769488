import { ScheduleDashboardRoutes } from "@skillup/espace-rh-bridge";
import { type FormatMonetaryOptions, formatMonetary } from "@skillup/shared-utils";
import { type TranslationFunction } from "@skillup/learning-service-core";

import {
  cross as crossIcon,
  hourglass as hourglassIcon,
  edit as editIcon,
  clipboard as clipboardIcon,
  numbers as numbersIcon,
} from "uiAssets/StrokeIcons";
import useAreas from "hooks/useAreas";
import useTypedFetch from "hooks/useTypedFetch";

import DataLines, { IDataLine } from "../Components/DashboardDataLines";
import AccountTrainingScheduleLoader from "../Components/SupervisorTrainingScheduleLoader";

import Split from "./Split";
import Table from "./Table";

import styles from "./CollectionDashboardStyles.module.scss";
import useSettings from "hooks/useSettings";
import useTranslation from "hooks/useTranslation";

type CollectionDashboardGlobalData =
  ScheduleDashboardRoutes.GetDashboardDataForSchedule<"collection">["response"]["globalData"];

interface IProps {
  scheduleUuid?: string;
}

const buildData: (
  data: CollectionDashboardGlobalData,
  monatoryOptions: FormatMonetaryOptions,
  t: TranslationFunction
) => Array<IDataLine> = (globalData: CollectionDashboardGlobalData, monatoryOptions, t) => {
  const lines: IDataLine[] = [
    {
      contents: [
        t("trainings.view.leagacy_dashboard.main_card.header.collection.label", {
          defaultValue: "Recueil",
        }),
        t("trainings.view.leagacy_dashboard.main_card.header.trainings.label", {
          defaultValue: "Formations",
        }),
        t("trainings.view.leagacy_dashboard.main_card.header.interns.label", {
          defaultValue: "Stagiaires",
        }),
        t("trainings.view.leagacy_dashboard.main_card.header.hours.label", {
          defaultValue: "Heures",
        }),
        t("trainings.view.leagacy_dashboard.main_card.header.total.label", {
          defaultValue: "Total",
        }),
      ],
      key: "header",
      type: "header",
    },
    {
      contents: [
        t("trainings.view.leagacy_dashboard.waiting_for_manager.label", {
          defaultValue: "En attente de validation manager",
        }),
        (globalData.toArbitrate || {}).actions || 0,
        (globalData.toArbitrate || {}).trainees || 0,
        (globalData.toArbitrate || {}).hours || 0,
        formatMonetary((globalData.toArbitrate || {}).budget, monatoryOptions),
      ],
      icon: hourglassIcon,
      key: "arbitration",
    },
    {
      contents: [
        t("trainings.view.leagacy_dashboard.to_requalified.label", {
          defaultValue: "À requalifier",
        }),
        (globalData.toRequalify || {}).actions || 0,
        (globalData.toRequalify || {}).trainees || 0,
        (globalData.toRequalify || {}).hours || 0,
        formatMonetary((globalData.toRequalify || {}).budget, monatoryOptions),
      ],
      icon: clipboardIcon,
      iconColor: "#666",
      isVisible: (settings) => settings.portalHRSpecificTrainingEnabled,
      key: "requalification",
    },
    {
      contents: [
        t("trainings.view.leagacy_dashboard.to_validate_hr.label", {
          defaultValue: "À valider RH",
        }),
        (globalData.toValidate || {}).actions || 0,
        (globalData.toValidate || {}).trainees || 0,
        (globalData.toValidate || {}).hours || 0,
        formatMonetary((globalData.toValidate || {}).budget, monatoryOptions),
      ],
      icon: editIcon,
      iconColor: "#666",
      key: "validation",
    },
    {
      contents: [
        t("trainings.view.leagacy_dashboard.total_collection.label", {
          defaultValue: "Total au recueil",
        }),
        (globalData.totalCollection || {}).actions || 0,
        (globalData.totalCollection || {}).trainees || 0,
        (globalData.totalCollection || {}).hours || 0,
        formatMonetary((globalData.totalCollection || {}).budget, monatoryOptions),
      ],
      icon: numbersIcon,
      iconColor: "#666",
      key: "total-collection",
      type: "total",
    },
    {
      contents: [
        t("trainings.view.leagacy_dashboard.denied.label", {
          defaultValue: "Réfusé",
        }),
        (globalData.denied || {}).actions || 0,
        (globalData.denied || {}).trainees || 0,
        (globalData.denied || {}).hours || 0,
        formatMonetary((globalData.denied || {}).budget, monatoryOptions),
      ],
      icon: crossIcon,
      iconColor: "#ff7979",
      key: "denied",
    },
  ];

  return lines;
};

const CollectionDashboard = (props: IProps) => {
  const { scheduleUuid } = props;
  const { activeAreas, hasAllAreasActive } = useAreas();
  const areas = hasAllAreasActive ? "" : activeAreas?.length ? activeAreas.join(",") : "";
  const settings = useSettings();
  const { i18n, t } = useTranslation();

  const { data: dashboardData } = useTypedFetch<
    ScheduleDashboardRoutes.GetDashboardDataForSchedule<"collection">
  >({
    method: "GET",
    path: "/schedule-dashboard/{type}/{scheduleUuid?}",
    params: {
      type: "collection",
      scheduleUuid,
    },
    query: { areas },
  });

  if (!dashboardData) {
    return <AccountTrainingScheduleLoader />;
  }

  return (
    <div className={styles.CollectionDashboard}>
      <DataLines
        lines={buildData(
          dashboardData.globalData,
          {
            locale: i18n.language,
            currency: settings.settings.currency,
          },
          t
        )}
      />
      <div className={styles.tables}>
        {dashboardData.tableData.map((table) => (
          // @ts-ignore
          <Table key={table.name} {...table} />
        ))}
      </div>
      <div className={styles.splits}>
        {dashboardData.splitsData.map((split) => (
          // @ts-ignore
          <Split key={split.name} {...split} />
        ))}
      </div>
    </div>
  );
};

export default CollectionDashboard;
