import { TranslationType } from "hooks/useTranslation";
import { InterviewType } from "../types/Interview";

const getInterviewTypeLabels: (t: TranslationType) => { label: string; value: InterviewType }[] = (
  t
) => {
  return [
    { label: t("interviews.type.other", { defaultValue: "Autre" }), value: "Autre" },
    {
      label: t("interviews.type.sixyears", { defaultValue: "Bilan à 6 ans" }),
      value: "Bilan à 6 ans",
    },
    {
      label: t("interviews.type.yearly", { defaultValue: "Entretien annuel" }),
      value: "Entretien annuel",
    },
    {
      label: t("interviews.type.professional", { defaultValue: "Entretien professionnel" }),
      value: "Entretien professionnel",
    },
  ];
};

const getInterviewTypeLabel = (type: InterviewType, t: TranslationType): string => {
  switch (type) {
    case "Autre":
      return t("interviews.type.other", { defaultValue: "Autre" });
    case "Bilan à 6 ans":
      return t("interviews.type.sixyears", { defaultValue: "Bilan à 6 ans" });
    case "Entretien annuel":
      return t("interviews.type.yearly", { defaultValue: "Entretien annuel" });
    case "Entretien professionnel":
      return t("interviews.type.professional", { defaultValue: "Entretien professionnel" });
    default:
      return "";
  }
};

export { getInterviewTypeLabels, getInterviewTypeLabel };
