/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { get } from "lodash";
import {
  type FilterConfiguration,
  type FilterConfigurationMap,
  type FilterType,
  type FilterValue,
  type FilterValues,
} from "./types";
import { IFilterManager } from "./IFilterManager";

export class InMemoryDatasetFilter<
  T extends object,
  Config extends FilterConfigurationMap,
> extends IFilterManager<(data: T[]) => T[], Config> {
  constructor(
    configuration: Config,
    private readonly dataset: T[],
    private readonly dataAccessor?: string
  ) {
    super(configuration);
  }

  override buildMultiSelectFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.MULTISELECT>,
    filterValue: FilterValue<typeof FilterType.MULTISELECT>
  ) {
    switch (filterValue.operator) {
      case "contains":
        return (data: T[]) =>
          data.filter((item) =>
            filterValue.value!.some(
              (value) => get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) === value
            )
          );
      case "equals":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) === filterValue.value
          );
      default:
        throw new Error(
          `${filterValue.operator} operator not implemented for the multi select filter type`
        );
    }
  }

  override buildTextFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.TEXT>,
    filterValue: FilterValue<typeof FilterType.TEXT>
  ) {
    switch (filterValue.operator) {
      case "contains":
        return (data: T[]) =>
          data.filter((item) =>
            get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)
              ?.toString()
              .toLowerCase()
              .includes(filterValue.value!.toString().toLowerCase())
          );

      case "equals":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)
                ?.toString()
                .toLowerCase() === filterValue.value
          );
      default:
        throw new Error(
          `${filterValue.operator} operator not implemented for the text filter type`
        );
    }
  }

  override buildSingleSelectFilter(
    id: string,
    _filter: FilterConfiguration<"select">,
    filterValue: FilterValue<"select">
  ): (data: T[]) => T[] {
    switch (filterValue.operator) {
      case "equals":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) === filterValue.value
          );
      default:
        throw new Error(
          `${filterValue.operator} operator not implemented for the single select filter type`
        );
    }
  }

  override buildNumberFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.NUMBER>,
    filterValue: FilterValue<typeof FilterType.NUMBER>
  ) {
    switch (filterValue.operator) {
      case "equals":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) === filterValue.value
          );
      case "greater_than":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) > filterValue.value!
          );
      case "less_than":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) < filterValue.value!
          );
      case "greater_than_or_equal":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) >= filterValue.value!
          );
      case "less_than_or_equal":
        return (data: T[]) =>
          data.filter(
            (item) =>
              get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) <= filterValue.value!
          );
      default:
        throw new Error(
          `${filterValue.operator} operator not implemented for the number filter type`
        );
    }
  }

  override buildDateFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.DATE>,
    filterValue: FilterValue<typeof FilterType.DATE>
  ) {
    switch (filterValue.operator) {
      case "equals":
        return (data: T[]) =>
          data.filter(
            (item) =>
              new Date(
                get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)
              ).getTime() === new Date(filterValue.value as string | number | Date).getTime()
          );
      case "after":
        return (data: T[]) =>
          data.filter(
            (item) =>
              new Date(get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)) >
              new Date(filterValue.value! as string | number | Date)
          );
      case "before":
        return (data: T[]) =>
          data.filter(
            (item) =>
              new Date(get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)) <
              new Date(filterValue.value! as string | number | Date)
          );
      default:
        throw new Error(
          `${filterValue.operator} operator not implemented for the select filter type`
        );
    }
  }

  public filterDataSet(filterValues: FilterValues<Config>) {
    const filterMethods = Object.keys(this.filterConfig)
      .filter((filterId) => !!filterValues[filterId] && filterValues[filterId].value !== undefined)
      .map((filterId) => this.buildFilter(filterId, filterValues[filterId]))
      .filter((filter) => !!filter);

    return filterMethods.reduce((acc, filter) => filter!(acc), [...this.dataset]);
  }
}
