import { v4 as uuid } from "uuid";

import { TranslationType } from "hooks/useTranslation";
import { BuilderTemplate } from "../types";
import { getChildErrors, getPageErrors, getSectionErrors } from "../utils";
import { PayloadAction } from "@reduxjs/toolkit";

export const checkTemplateErrors = (
  state: BuilderTemplate,
  action: PayloadAction<{ choicesForOrdinalTargets: string[]; t: TranslationType }>
) => {
  return {
    ...state,
    sections: state.sections.map((section) => ({
      uuid: section.uuid ?? uuid(),
      title: section.title,
      pages: section.pages.map((page) => ({
        uuid: page.uuid ?? uuid(),
        title: page.title,
        hideInBuilder: page.hideInBuilder,
        children: page.children.map((child) => ({
          uuid: child.uuid ?? uuid(),
          ...child,
          errors: getChildErrors(child, action.payload.t, action.payload.choicesForOrdinalTargets),
        })),
        errors: getPageErrors(page, action.payload.t),
      })),
      errors: getSectionErrors(section, action.payload.t),
    })),
  };
};
