import React, { type ReactElement, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { last } from "lodash";

import { components, type ValueContainerProps as RSValueContainerProps } from "react-select";

import { type Themes } from "components/commonProps";
import { Chip, type ChipDeleteEvent } from "components/Chip/Chip";

import { type SelectOption, type SelectProps } from "../type";

import styles from "./ValueContainer.module.scss";

interface ValueContainerProps<T> {
  valueContainerProps: RSValueContainerProps<SelectOption<T>>;
  selectProps?: SelectProps<T> & {
    readonly multi: true;
    readonly value?: T[];
    readonly maxShownValues?: number;
    readonly selectPlaceholder?: string;
    readonly extraValuesLabel?: (count: number) => React.ReactNode;
    readonly onChange?: (options: T[] | undefined) => void;
  };
  handleSelect?: (option: any) => void;
  children?: React.ReactNode | React.ReactNode[];
}

export function ValueContainer<T>({ valueContainerProps, children }: ValueContainerProps<T>) {
  const Input = last(React.Children.toArray(valueContainerProps.children));
  return (
    <components.ValueContainer {...valueContainerProps} className={styles.valueContainer}>
      {children}
      {Input}
    </components.ValueContainer>
  );
}

export function PlaceHolderContainer<T>({
  valueContainerProps,
  selectProps,
}: ValueContainerProps<T>) {
  const { t } = useTranslation();
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const Input = last(React.Children.toArray(valueContainerProps.children)) as ReactElement;
  const ReactiveInput = React.cloneElement(Input, {
    onFocus: (e) => {
      setShowPlaceholder(false);
      Input.props?.onFocus?.(e);
    },
    onBlur: (e) => {
      setShowPlaceholder(true);
      Input.props?.onBlur?.(e);
    },
  });
  return (
    <components.ValueContainer {...valueContainerProps}>
      <div className={cx(styles.placeholder, { [styles.darkMode]: selectProps?.darkMode })}>
        {showPlaceholder && !valueContainerProps?.selectProps?.inputValue && (
          <span>
            {selectProps?.selectPlaceholder ??
              t("select.multi.placeholder", {
                defaultValue: selectProps?.placeholder ?? "Choisir une ou plusieurs valeurs",
              })}
          </span>
        )}
        {ReactiveInput}
      </div>
    </components.ValueContainer>
  );
}

export function ChipValueContainer<T>({
  valueContainerProps,
  selectProps,
  handleSelect,
}: ValueContainerProps<T>) {
  const { t } = useTranslation();
  // Limit number of items shown in multi-select
  // Code inspired by https://codesandbox.io/s/custom-react-select-sjtib
  const length = valueContainerProps.getValue().length;
  const optionsLength = valueContainerProps.options?.length;
  const maxShownValues = selectProps?.maxShownValues ?? 1;

  const { hasExtraValues, hasChips, extraLabel } = useMemo(() => {
    const extraValuesLabel =
      selectProps?.extraValuesLabel ?? ((count: number) => `${count} éléments sélectionnés`);
    const hasExtraValues = length > maxShownValues;
    const config = {
      hasExtraValues,
      hasChips: hasExtraValues && maxShownValues !== 1,
      extraLabel: extraValuesLabel(length),
    };
    if (optionsLength === length) {
      return {
        ...config,
        extraLabel:
          selectProps?.allValuesLabel ||
          t("select.all-values", {
            defaultValue: "Tous les éléments",
          }),
      };
    }
    if (maxShownValues !== 1) {
      return { ...config, extraLabel: extraValuesLabel(length - maxShownValues) };
    }
    return config;
  }, [selectProps, maxShownValues, length, optionsLength]);

  const onDelete = useCallback(
    (e: ChipDeleteEvent<T>) => {
      handleSelect?.(
        valueContainerProps.getValue().filter((opt: SelectOption<T>) => opt.value !== e.value)
      );
    },
    [handleSelect, valueContainerProps]
  );
  if (maxShownValues === undefined || length <= maxShownValues)
    return (
      <ValueContainer valueContainerProps={valueContainerProps}>
        {valueContainerProps.getValue().map((selectOption) => (
          <Chip
            key={JSON.stringify(selectOption.value)}
            label={selectOption.label}
            value={selectOption.value}
            onDelete={onDelete}
            dismissible
            className={styles.chip}
            darkMode={selectProps?.darkMode}
            theme={selectProps?.theme as Themes}
          />
        ))}
      </ValueContainer>
    );

  const displayedChips = valueContainerProps
    .getValue()
    .slice(0, maxShownValues)
    .map((selectOption) => (
      <Chip
        key={JSON.stringify(selectOption.value)}
        label={selectOption.label}
        value={selectOption.value}
        dismissible
        onDelete={onDelete}
        className={styles.chip}
        darkMode={selectProps?.darkMode}
        theme={selectProps?.theme as Themes}
      />
    ));

  return (
    <ValueContainer valueContainerProps={valueContainerProps}>
      {hasChips && displayedChips}
      {hasExtraValues && (
        <div aria-label={`extravalues-label-${extraLabel}`} className={styles.missingItems}>
          {extraLabel}
        </div>
      )}
    </ValueContainer>
  );
}
