import React from "react";
import { debounce, isEmpty } from "lodash";
import FroalaEditorComponent from "react-froala-wysiwyg";
import cx from "classnames";

// // js for plugins
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";

// // Traduction.
import "froala-editor/js/languages/fr.js";
import "froala-editor/js/languages/en_gb.js";
import "froala-editor/js/languages/de.js";
import "froala-editor/js/languages/it.js";
import "froala-editor/js/languages/es.js";

import { type Themes } from "components/commonProps";

import styles from "./TextArea.module.scss";

const COLORS = [
  "#4490A7",
  "#60C3D7",
  "#CEEDF3",
  "#B28600",
  "#FFBB00",
  "#FEEDB4",
  "#AD4A48",
  "#F4645D",
  "#FCD2CF",
  "#6B9627",
  "#99CD32",
  "#E1F0C1",
  "#B375A7",
  "#FE9FD8",
  "#FFE0F2",
  "#B24A00",
  "#FF6600",
  "#FFD1B3",
  "#1A304D",
  "#909AA7",
  "#C9CED4",
  "#E5E8EB",
  "#FFFFFF",
  "REMOVE",
];
const DEFAULT_CONFIG = {
  attribution: false,
  listAdvancedTypes: false,
  linkList: false,
  linkStyles: false,
  language: "fr",
  linkMultipleStyles: false,
  pastePlain: true,
  placeholderText: "Ecrivez le contenu désiré.",
  toolbarSticky: true,
  videoUpload: false,
  colorsHEXInput: false,
  colorsStep: 6,
  colorsBackground: COLORS,
  colorsText: COLORS,
  paragraphFormat: {
    H2: "Titre",
    H3: "Sous-titre",
    N: "Paragraphe",
  },
  toolbarButtons: [
    "bold",
    "italic",
    "underline",
    "paragraphFormat",
    "|",
    "insertLink",
    "insertVideo",
    "|",
    "formatUL",
    "formatOL",
    "|",
    "outdent",
    "indent",
    "|",
    "selectAll",
    "clearFormatting",
    "|",
    "help",
    "html",
    "|",
    "undo",
    "redo",
  ],
};

export interface TextAreaProps {
  readonly id?: string;
  readonly value?: HTMLInputElement["value"];
  readonly placeholder?: HTMLInputElement["placeholder"];
  readonly error?: boolean;
  readonly fitParentHeight?: boolean;
  readonly onChange: (value: string) => void;
  readonly className?: string;
  readonly debounceValue?: number;
  readonly toolbarButtons?: string[];
  readonly froalaLanguage?: string;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const TextArea = ({
  id,
  placeholder = DEFAULT_CONFIG.placeholderText,
  value,
  error,
  fitParentHeight,
  className,
  onChange,
  theme,
  darkMode,
  debounceValue,
  froalaLanguage,
  toolbarButtons,
}: TextAreaProps) => {
  const debounceOnChange = debounce(
    (newModel: string) => {
      if (newModel === value) {
        return;
      }
      onChange(newModel);
    },
    debounceValue ? debounceValue : 300
  );

  const config = {
    language: froalaLanguage ?? DEFAULT_CONFIG.language,
    placeholderText: placeholder,
    editorClass: cx(styles.editor, theme),
    key: process.env.REACT_APP_FROALA_LICENSE_KEY,
    toolbarButtons: toolbarButtons ?? DEFAULT_CONFIG.toolbarButtons,
  };

  return (
    <div
      id={id}
      className={cx(styles.TextArea, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.empty]: isEmpty(value),
        [styles.error]: !!error,
        [styles.fitParentHeight]: fitParentHeight,
      })}
    >
      <FroalaEditorComponent
        key={id}
        model={value}
        tag="textarea"
        config={{ ...DEFAULT_CONFIG, ...config }}
        onModelChange={debounceOnChange}
      />
    </div>
  );
};

export { TextArea };
