import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal, Label, DSTextInput } from "@skillup/ui";

import { useTemplate, type Template } from "services/interviews";
import styles from "./RenameTemplateModal.module.scss";
import useTranslation, { type TranslationType } from "hooks/useTranslation";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

const RenameTemplateModal = ({ template, isOpen, closeModal }: IProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const templateActions = useTemplate(template.uuid);
  const [newTitle, setNewTitle] = useState<string>(template.title);
  const [loading, setLoading] = useState<boolean>(false);

  const send = (t: TranslationType) => async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await templateActions.rename(newTitle);
      if (response.success) {
        addToast(t("interviews.renameTemplateModal.success", { defaultValue: "Trame renommée" }), {
          appearance: "success",
        });
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Error while renaming template");
      }
    } catch (err) {
      addToast(
        t("interviews.renameTemplateModal.success", {
          defaultValue: "Echec lors du renommage de la trame",
        }),
        {
          appearance: "error",
        }
      );
    }
  };
  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.renameTemplateModal.title", {
            defaultValue: "Renommer une trame",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Label
          label={t("interviews.renameTemplateModal.newTitle", {
            defaultValue: "Titre de la trame",
          })}
          required
        />
        <div className={styles.marginBottom}>
          <DSTextInput
            value={newTitle}
            onChange={setNewTitle}
            name={"new title"}
            autoFocus
            required
          />
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.renameTemplateModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          label={t("interviews.renameTemplateModal.save", {
            defaultValue: "Enregistrer les modifications",
          })}
          onClick={send(t)}
          disabled={newTitle === template.title}
          loading={loading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export { RenameTemplateModal };
