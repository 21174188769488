export const Locales = ["fr", "en", "de", "it", "es", "cimode"] as const;
export type Locales = (typeof Locales)[number];

export interface IDateFormats {
  shortDate: string;
}

export const dateFormats: { [key in Locales]: IDateFormats } = {
  fr: {
    shortDate: "dd/MM/yyyy",
  },
  en: {
    shortDate: "MM/dd/yyyy",
  },
  de: {
    shortDate: "dd.MM.yyyy",
  },
  es: {
    shortDate: "dd/MM/yyyy",
  },
  it: {
    shortDate: "dd/MM/yyyy",
  },
  cimode: {
    shortDate: "dd/MM/yyyy",
  },
};
