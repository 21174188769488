import React from "react";
import { Tick } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const CampaignIsOver = ({ t }: { t: (l: string, p: object) => string }) => (
  <RectangleTag
    label={t("interviews.CampaignFinished", {
      defaultValue: "Campagne Terminée",
    })}
    icon={<Tick />}
    backgroundColor="#eeffed"
    textColor="#0eaf85"
  />
);

export { CampaignIsOver };
