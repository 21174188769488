import useTranslation from "hooks/useTranslation";

import { Text, Flex } from "@skillup/design-system";
import { LegendParent, LegendContainer, LevelGetIcon, LevelExpectedIcon } from "./Legend.styled";

const Legend = () => {
  const { t } = useTranslation();

  return (
    <LegendParent>
      <LegendContainer>
        <Text espaceFont="body2Bold" color="plainText-onLight-default">
          {t("collaborator.skills.legend", {
            defaultValue: "Légende :",
          })}
        </Text>

        <Flex alignItems="center">
          <LevelExpectedIcon />
          <Text espaceFont="captionRegular" marginLeft="xs" marginVertical="xs">
            {t("collaborator.skills.expectedLevel", {
              defaultValue: "Niveau de maîtrise attendu",
            })}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <LevelGetIcon />
          <Text espaceFont="captionRegular" marginLeft="xs">
            {t("collaborator.skills.evaluatedLevel", {
              defaultValue: "Niveau de maîtrise évalué",
            })}
          </Text>
        </Flex>
      </LegendContainer>
    </LegendParent>
  );
};

export default Legend;
