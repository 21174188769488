/**
 * @deprecated Use DSTextInput instead
 */
export { TextInput } from "./Legacy_components/TextInput";
export * as Icon from "./Legacy_components/Icon";
export { Tag } from "./Legacy_components/Tag";
export { Link } from "./Legacy_components/Link";
export { Skill } from "./Legacy_components/Skill";
export { Toast } from "./Legacy_components/Toast";
export { Badge } from "./Legacy_components/Badge";
export { Rating } from "./Legacy_components/Rating";
export { Avatar } from "./Legacy_components/Avatar";
export { Button } from "./Legacy_components/Button";
export { Toggle } from "./Legacy_components/Toggle";
export { Tooltip } from "./Legacy_components/Tooltip";
export { Details } from "./Legacy_components/Details";
export { Popover } from "./Legacy_components/Popover";
export { DSPopover } from "./components/Popover";
export { TextArea } from "./Legacy_components/TextArea";
export { Checkbox } from "./Legacy_components/Checkbox";
export { RadioBox } from "./Legacy_components/RadioBox";
export * as RectangleTags from "./Legacy_components/RectangleTags";
export { RadioForm } from "./Legacy_components/RadioForm";
export type { SVGProps as ISVGProps } from "./Legacy_components/Icon";
export { Checkboxes } from "./Legacy_components/Checkboxes";
export { FlatButton } from "./Legacy_components/FlatButton";
export { FoldedCard } from "./Legacy_components/FoldedCard";
export { StatusBadge } from "./Legacy_components/StatusBadge";
export { SearchInput } from "./Legacy_components/SearchInput";
export { ProgressBar } from "./Legacy_components/ProgressBar";
export { NumberInput } from "./Legacy_components/NumberInput";
export { TextDropdown } from "./Legacy_components/TextDropdown";
export { DropDownMenu } from "./Legacy_components/DropDownMenu";
export { FloatingTabs } from "./Legacy_components/FloatingTabs";
export { CatalogSearch } from "./Legacy_components/CatalogSearch";
export { FilterShortcut } from "./Legacy_components/FilterShortcut";
export { UserWithAvatar } from "./Legacy_components/UserWithAvatar";
export { UserImportError } from "./Legacy_components/UserImportError";
export { DateRangePicker } from "./Legacy_components/DateRangePicker";
export { DropDownCheckbox } from "./Legacy_components/DropDownCheckbox";
export { TargetEvaluation } from "./Legacy_components/TargetEvaluation";
export { CatalogTrainingCard } from "./Legacy_components/TrainingCards/CatalogTrainingCard";
export { TrainingSummaryCard } from "./Legacy_components/TrainingCards/TrainingSummaryCard";
export { SearchResultsTrainingCard } from "./Legacy_components/TrainingCards/SearchResultsTrainingCard";

export { colors } from "./assets/colors";
export { useMediaQueries } from "./hooks/useMediaQueries";
export { useDebounce } from "./hooks/useDebounce";

export {
  DesignSystemContextProvider,
  DesignSystemContext,
  SkuiSpacing,
  DSColor,
  EspaceFont,
} from "./components/DesignSystemContext";

export {
  Alert as DSAlert,
  AlertType as DSAlertType,
  AlertDisplay as DSAlertDisplay,
} from "./components/Alert";

export {
  Dropdown as DSDropdown,
  DSDropdownItem,
  DSDropdownAsyncItem,
  DSDropdownTitle,
  DSDropdownDivider,
} from "./components/Dropdown";

export {
  NavigationSideNav as DSNavigationSideNav,
  Item as DSNavigationSideNavItem,
  SubItem as DSNavigationSideNavSubItem,
} from "./components/NavigationSideNav";

export {
  DSDataTable as DataTable,
  DataTableProps,
  ColumnType,
  Column,
  DataTableRow,
} from "./components/DataTable";

export { DSList } from "./components/List";

export {
  Select,
  MenuPlacement as SelectMenuPlacement,
  SelectPropsWithoutTheme as SelectProps,
} from "./components/Select";

export {
  ProgressBar as DSProgressBar,
  TypeProgressBar,
  ValueLayoutProgressBar,
  dataInPercent,
  COLORS,
} from "./components/ProgressBar";

export * as DSColors from "./atoms/Colors/ColorsExport.module.scss";
export * from "./components/commonProps";
export { TypeGauge } from "./components/Gauge";
export { Chip, type ChipDeleteEvent } from "./components/Chip";
export { NumberChip as ChipNumber } from "./components/ChipNumber";
export { Flex } from "./components/Flex";
export { Card as DSCard } from "./components/Card";
export { DSDataGrid } from "./components/DSDataGrid";
export { usePersistColumnSettings } from "./components/DSDataGrid/hooks/usePersistColumnSettings";
export { Switch } from "./components/Switch";
export { Loader } from "./components/Loader";
export { UserPopover } from "./components/UserPopover";
export { Avatar as DSAvatar, getDecorationColor } from "./components/Avatar";
export { Stepper } from "./components/Stepper";
export { Label } from "./components/Form/Label";
export { Tooltip as DSTooltip } from "./components/Tooltip";
export { Carousel } from "./components/Carousel";
export { Timeline } from "./components/Timeline";
export { FileDrop } from "./components/FileDrop";
export { DSSortable } from "./components/DSSortable";
export { Navigation } from "./components/Navigation";
export { Pagination } from "./components/Pagination";
export { DatePicker } from "./components/DatePicker";
export { Modal, useModal } from "./components/Modal";
export { TextArea as DSTextArea } from "./components/Form/TextArea";
export { Checkbox as DSCheckbox } from "./components/Form/Checkbox";
export { Status, StatusType } from "./components/Status";
export { Property } from "./components/Property";
export { AutoComplete as Autocomplete } from "./components/Autocomplete";
export { AutocompleteBasic } from "./components/Autocomplete/Basic";
export { AvatarGroup as DSAvatarGroup } from "./components/AvatarGroup";
export { TextInput as DSTextInput } from "./components/Form/TextInput";
export { TableOfContent } from "./components/TableOfContent";
export { ExpansionPanel } from "./components/ExpansionPanel";
export { Button as DSButton, type DSButtonProps } from "./components/Button";
export { AssistiveArea } from "./components/Form/AssistiveArea";
export { NavigationHeader } from "./components/NavigationHeader";
export { ToggleButtonGroup as ToggleButtonGroup } from "./components/ToggleButtonGroup";
export { SimpleTextArea as DSSimpleTextArea } from "./components/Form/SimpleTextArea";
export { generateModalContext, DSModal, DSDialog } from "./components/DSModal";
export { Modal as Modal2, useModal as useModal2 } from "./components/Modal2";
export { generateSidePanelContext, DSSidePanel } from "./components/DSSidePanel";
export { VerticalDivider } from "./components/Divider/VerticalDivider";
export { HorizontalDivider } from "./components/Divider/HorizontalDivider";
export { NavigationTabs as NavigationTabs, type NavigationTab } from "./components/NavigationTabs";
export { ThemedNumberInput as DSNumberInput } from "./components/Form/Inputs/NumberInput";
export { DSFormGroupText } from "./components/Form/Inputs/FormGroupText";
export { DashboardCardView as DSDashboardCardView } from "./components/Dashboard/DashboardCardView";

export { FormGroupInput as DSFormGroupTextInput } from "./components/FormGroup/FormGroupTextInput";
export { FormGroupSwitch as DSFormGroupSwitch } from "./components/FormGroup/FormGroupSwitch";
export { SkillsList as DSSkillsList } from "./components/SkillsList";
export { SkillsListDetail as DSSkillsListDetail } from "./components/SkillsListDetail";
export { UserPreview as DSUserPreview } from "./components/UserPreview";
export { Rating as DSRating } from "./components/training/Rating";
export { Radio as DSRadio, RadioGroup as DSRadioGroup } from "./components/Form/Radio";

export { DSFilters, SelectOperator } from "./components/Filters";
export { useFilters, useUrlSyncedFilters } from "./components/Filters/hooks";
export {
  useShortenedFilters,
  useUrlSyncedShortenedFilters,
} from "./components/Filters/shortenedHooks";
export type {
  DSFiltersProps,
  FilterRef,
  FilterProp,
  FilterProps,
  UseFilterProp,
} from "./components/Filters";

export type { OnFilterHandler } from "./components/DSDataGrid/types";

export { TrainingProgram as TrainingProgram } from "./components/Training";

export { Pie } from "./components/Pie";
export { Radar } from "./components/Radar";

export { useResize } from "./hooks/useResize";
export { useIsOverflow } from "./hooks/useIsOverflow";
