import styled, { css } from "styled-components";

import { DSColor, SkuiSpacing } from "@skillup/ui";

export const darkModeStyles = css`
  background-color: ${DSColor["surface-dark-darker"]};
  color: ${DSColor["plainText-onDark-darker"]};
`;

export const lightModeStyles = css`
  background-color: ${DSColor["surface-light-darker"]};
  color: ${DSColor["plainText-onLight-lighter"]};
`;

const getThemeStyles = (darkMode: boolean) => (darkMode ? lightModeStyles : darkModeStyles);

export const FilterCard = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: row;
`;

export const Cell = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0;

  > div > div {
    border-radius: 4px 0px 0px 4px;
  }
`;

export const SelectWrapper = styled(Cell)<{ noRadius?: boolean; isFirstElement?: boolean }>`
  div {
    font-size: 0.75rem;
    ${({ isFirstElement }) => isFirstElement && `border-right: 0`};
  }
  > div {
    min-width: 145px;
    > div {
      cursor: pointer;
      ${({ isFirstElement }) =>
        isFirstElement ? "border-radius: 4px 0px 0px 4px" : "border-radius: 0px 4px 4px 0px"};
      ${({ noRadius }) => noRadius && "border-radius: 0"};
    }
  }
`;

export const BetweenOperator = styled(Cell)<{ darkMode: boolean }>`
  ${({ darkMode }) => getThemeStyles(darkMode)};
  font-size: 0.625rem;
  font-weight: 700;
  height: 42px;
  border-left: 0;
  border-right: 0;
  padding: 0 ${SkuiSpacing.s};
`;
