import { MdInfoOutline as InfoOutline } from "react-icons/md";
import { MdOutlineFileDownload as FileDownload } from "react-icons/md";
import { MdDelete as Delete } from "react-icons/md";
import { MdAttachFile as AttachFile } from "react-icons/md";
import React, { useMemo, useState } from "react";
import cx from "classnames";
import { useDropzone } from "react-dropzone";

import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSButton,
  DSCheckbox,
  DSModal,
  DSTextArea,
  DSTextInput,
  DSTooltip,
  Flex,
  Label as DSLabel,
  Loader as DSLoader,
} from "@skillup/ui";

import { type Project } from "services/sessions";
import type { DeleteFileRoute, UploadFileRoute } from "types/api";
import downloadFileAsUser from "utils/downloadFileAsUser";
import { buildFileRequest, buildRequest } from "utils/buildRequest";
import User from "utils/User";
import useTranslation from "hooks/useTranslation";

import { EmailPayload } from "./stateMachine";

import styles from "./EmailSettingsModal.module.scss";

import { getDefaultContent, getDefaultTitle } from "./EmailSettingsModal.utils";

export type UploadFileResponse = {
  uuid: string;
  name: string;
};

type Attachment = {
  file?: UploadFileResponse;
  name: string;
  loading?: boolean;
  downloading?: boolean;
};

type EmailSettingsModalProps = {
  session: Project;
  onClose: () => void;
  onConfirm: (emailPayload: EmailPayload) => void;
  onBack: () => void;
  emailPayload?: EmailPayload;
};

export function EmailSettingsModal({
  session,
  onClose,
  onConfirm,
  onBack,
  emailPayload,
}: EmailSettingsModalProps) {
  const user = User.getUserData();
  const locale = user.activeCompany.portalTranslationEnabled ? "fr-en" : "fr";
  const [state, setState] = useState<EmailPayload>(
    emailPayload ?? {
      body: getDefaultContent(session, locale),
      subject: getDefaultTitle(session, locale),
      attachments: [],
      options: {
        sendCalendarEvents: false,
        sendRecapToHR: false,
        sendRecapToManagers: false,
      },
    }
  );
  const { t } = useTranslation();

  const genericSetOption =
    <T extends keyof EmailPayload["options"]>(option: T) =>
    (value: EmailPayload["options"][T]) => {
      setState({ ...state, options: { ...state.options, [option]: value } });
    };

  const setFilesUuids = (filesUuids: string[]) => {
    setState({ ...state, attachments: filesUuids });
  };

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.view.summon.modal.title.label", {
            defaultValue: "E-mail de convocation",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex column className={styles.modalContent}>
          <Flex>
            <div className={styles.leftPanel}>
              <DSLabel
                label={t("trainings.view.summon.modal.trainee_to_summon.label", {
                  defaultValue: "Stagiaires à convoquer",
                })}
              />
              <Users
                users={session.positionnedTrainees.map((trainee) => trainee.fullName)}
                maxStringLength={150}
                className={cx(styles.customTextarea, styles.trainees)}
              />
              {session.trainers.length !== 0 && (
                <>
                  <DSLabel
                    label={t("trainings.view.summon.modal.trainer.label", {
                      defaultValue: "Formateur",
                    })}
                  />
                  <Users
                    users={session.trainers.map((trainer) => trainer.fullName)}
                    maxStringLength={70}
                    className={cx(styles.customTextarea, styles.trainers)}
                  />
                </>
              )}
              <Email state={state} setState={setState} />
            </div>
            <Flex className={styles.rightPanel} column>
              <div className={styles.sendParams}>
                <DSLabel
                  label={t("trainings.view.summon.modal.send_settings.label", {
                    defaultValue: "Paramètres d'envoi",
                  })}
                  className={styles.sendParamsTitle}
                />
                <CheckBoxes stateOptions={state.options} setOptions={genericSetOption} />
              </div>
              <Attachments
                attachments={attachments}
                setAttachments={setAttachments}
                setFilesUuids={setFilesUuids}
              />
            </Flex>
          </Flex>
          <Alerts
            positionnedTrainees={session.positionnedTrainees}
            summonedTrainees={session.summonedTrainees}
            properties={session.properties}
          />
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("trainings.common.back.label", {
            defaultValue: "Retour",
          })}
          onClick={onBack}
        />
        <DSModal.Footer.PrimaryButton
          label={t("trainings.common.next.label", {
            defaultValue: "Suivant",
          })}
          onClick={() => onConfirm(state)}
          disabled={attachments.some((attachment) => attachment.loading)}
        />
      </DSModal.Footer>
    </DSModal>
  );
}

type UsersProps = {
  users: string[];
  maxStringLength: number;
  className?: string;
};

const Users = ({ users, maxStringLength, className }: UsersProps) => {
  const [isFullListDisplayed, setIsFullListDisplayed] = useState(false);

  const allUsersString = users.map((trainee, index) => (index > 0 ? " " : "") + trainee).toString();

  const usersStringToDisplay = allUsersString.slice(0, maxStringLength);

  let numberOfUsersDisplayed = 0;
  for (const letter of usersStringToDisplay) {
    if (letter === ",") numberOfUsersDisplayed++;
  }

  return (
    <span
      className={cx(className, {
        [styles.fullUsersList]: isFullListDisplayed,
      })}
    >
      {usersStringToDisplay}
      {usersStringToDisplay.length === maxStringLength && (
        <MoreUsers
          hiddenUsers={users.length - numberOfUsersDisplayed}
          remainingUsersString={allUsersString.slice(maxStringLength)}
          isFullListDisplayed={isFullListDisplayed}
          setIsFullListDisplayed={setIsFullListDisplayed}
        />
      )}
    </span>
  );
};

type MoreUsersProps = {
  hiddenUsers: number;
  remainingUsersString: string;
  isFullListDisplayed: boolean;
  setIsFullListDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
};

const MoreUsers = ({
  hiddenUsers,
  remainingUsersString,
  isFullListDisplayed,
  setIsFullListDisplayed,
}: MoreUsersProps) => {
  const { t } = useTranslation();

  const preLabel = isFullListDisplayed ? `${remainingUsersString} ` : "... ";
  const label = isFullListDisplayed
    ? t("trainings.common.hide.label", {
        defaultValue: "Masquer",
      })
    : t("trainings.view.summon.modal.and_hidden_users_other.label", {
        defaultValue: "et {{count}} autre",
        count: hiddenUsers,
      });

  return (
    <span>
      {preLabel}
      <u
        className={styles.moreUsersLink}
        role="button"
        onClick={() => setIsFullListDisplayed(!isFullListDisplayed)}
      >
        {label}
      </u>
    </span>
  );
};

type EmailProps = {
  state: EmailPayload;
  setState: React.Dispatch<React.SetStateAction<EmailPayload>>;
};

const Email = ({ state, setState }: EmailProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <DSLabel
        label={t("trainings.view.summon.modal.email_object.label", {
          defaultValue: "Objet de l'email",
        })}
        required
      />
      <DSTextInput
        style={{ height: 40, marginBottom: 16, marginRight: 1, marginLeft: 1 }}
        name="title"
        autoFocus
        value={state.subject}
        onChange={(value) => setState({ ...state, subject: value })}
      />
      <Flex className={styles.textArea}>
        <DSTextArea
          value={state.body}
          froalaLanguage={i18n.language as any}
          fitParentHeight
          onChange={(value) => setState({ ...state, body: value })}
        />
      </Flex>
    </div>
  );
};

type StateOptions = {
  sendCalendarEvents: boolean;
  sendRecapToHR: boolean;
  sendRecapToManagers: boolean;
};

type CheckBoxesProps = {
  stateOptions: StateOptions;
  setOptions: <T extends keyof EmailPayload["options"]>(option: T) => (value: boolean) => void;
};

const CheckBoxes = ({ stateOptions, setOptions }: CheckBoxesProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Flex className={styles.checkboxWithTooltip}>
        <DSCheckbox
          label={t("trainings.view.summon.modal.include_calendar_invitation.label", {
            defaultValue: "Inclure une invitation agenda",
          })}
          checked={stateOptions.sendCalendarEvents}
          onChange={(value) => {
            setOptions("sendCalendarEvents")(value);
          }}
          className={styles.checkbox}
        />
        <DSTooltip
          label={t("trainings.view.summon.modal.tooltip.hours_settings_next_step.label", {
            defaultValue:
              "Le paramétrage des heures de début et de fin des invitations agenda se fera à l'étape suivante",
          })}
        >
          <div className={styles.infoOutline}>
            <InfoOutline />
          </div>
        </DSTooltip>
      </Flex>
      <DSCheckbox
        label={t("trainings.view.summon.modal.checkbox.send_email.label", {
          defaultValue: "Envoyer un e-mail d'information aux managers des stagiaires",
        })}
        checked={stateOptions.sendRecapToManagers}
        onChange={(value) => setOptions("sendRecapToManagers")(value)}
        className={styles.checkbox}
      />
      <DSCheckbox
        label={t("trainings.view.summon.modal.checkbox.receive_email.label", {
          defaultValue: "Recevoir un e-mail récapitulatif contenant la convocation",
        })}
        checked={stateOptions.sendRecapToHR}
        onChange={(value) => setOptions("sendRecapToHR")(value)}
        className={styles.checkbox}
      />
    </div>
  );
};

type FileItemProps = {
  attachment: Attachment;
  key: number;
  downloadAttachment: (file: UploadFileResponse) => Promise<void>;
  removeAttachment: (file: UploadFileResponse) => Promise<void>;
};

const FileItem = ({ attachment, key, downloadAttachment, removeAttachment }: FileItemProps) => {
  const { t } = useTranslation();

  return (
    <Flex key={key} className={styles.fileItem}>
      <>
        <Flex className={styles.infoLabel}>
          <DSTooltip
            cropLongLabel={false}
            label={t("trainings.view.summon.modal.tooltip.email_attachment.label", {
              defaultValue:
                "Les pièces jointes seront intégrées dans l'email sous forme de liens. Quiconque disposant de ces liens pourra les consulter sans authentification. Si les documents contiennent des informations confidentielles, assurez-vous de ne les partager qu'avec des personnes autorisées.",
            })}
          >
            <div className={styles.infoOutline}>
              <InfoOutline />
            </div>
          </DSTooltip>
          <DSLabel label={attachment.name} className={styles.fileName} />
        </Flex>
        <Flex className={styles.actions}>
          {attachment.loading ? (
            <DSLoader width={24} className={styles.fileLoader} />
          ) : (
            <>
              <p>
                {attachment.downloading ? (
                  <DSLoader width={24} className={styles.fileLoader} />
                ) : (
                  <DSButton
                    label="download"
                    emphasis="Low"
                    icon={<FileDownload />}
                    iconOnly
                    onClick={() => downloadAttachment(attachment.file)}
                  />
                )}
              </p>
              <p>
                <DSButton
                  label="download"
                  emphasis="Low"
                  icon={<Delete />}
                  iconOnly
                  onClick={() => removeAttachment(attachment.file)}
                />
              </p>
            </>
          )}
        </Flex>
      </>
    </Flex>
  );
};

type AttachmentsParams = {
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  setFilesUuids: (filesUuids: string[]) => void;
};

const Attachments = ({ attachments, setAttachments, setFilesUuids }: AttachmentsParams) => {
  const updateBothState = (attachments: Attachment[]) => {
    const files = attachments
      .map((attachment) => (attachment.file ? attachment.file.uuid : undefined))
      .filter((a) => !!a);

    setFilesUuids(files);
    setAttachments(attachments);
  };

  async function onDrop(files: File[]) {
    const newAttachments: Attachment[] = files.map((file) => ({
      loading: true,
      name: file.name,
    }));
    setAttachments([...attachments, ...newAttachments]);
    setTimeout(() => {
      const modalContainer = document.querySelector(`.${styles.ConvocationModal}`)?.parentNode;
      if (modalContainer instanceof HTMLElement)
        modalContainer.scrollTop = modalContainer.scrollHeight;
    });
    const droppedFiles = await Promise.all(
      files.map(async (file, i) => {
        const attachment = newAttachments[i];
        const uploadRequest = buildFileRequest<UploadFileRoute>({
          file,
          method: "POST",
          path: "/files",
          query: {
            context: "session-summoning",
          },
        });
        try {
          const uploadedFile = await uploadRequest();
          return { ...attachment, loading: false, file: uploadedFile };
        } catch (e) {
          console.error("error");
          return undefined;
        }
      })
    );
    updateBothState([...attachments, ...droppedFiles]);
  }

  async function downloadAttachment(file: UploadFileResponse) {
    setAttachments(
      attachments.map((a) => (a.file?.uuid !== file.uuid ? a : { ...a, downloading: true }))
    );
    downloadFileAsUser(`/v1/files/${file.uuid}/download`, file.name, "API")
      .catch(console.error)
      .finally(() =>
        setAttachments((attachments) =>
          attachments.map((a) => (a.file?.uuid !== file.uuid ? a : { ...a, downloading: false }))
        )
      );
  }

  async function removeAttachment(file: UploadFileResponse) {
    const deleteRequest = buildRequest<DeleteFileRoute>({
      method: "DELETE",
      path: "/files/{uuid}",
      params: {
        uuid: file.uuid,
      },
    });
    await deleteRequest();
    const newAttachements = attachments.filter((a) => a.file !== file);
    updateBothState(newAttachements);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
  });
  const { t } = useTranslation();

  return (
    <div>
      <Flex className={styles.attachments}>
        {attachments
          .map((attachment, i) => (
            <FileItem
              attachment={attachment}
              key={i}
              downloadAttachment={downloadAttachment}
              removeAttachment={removeAttachment}
            />
          ))
          .reverse()}
      </Flex>
      <div {...getRootProps()}>
        <DSButton
          className={styles.attachmentLink}
          label={t("trainings.view.summon.modal.checkbox.add_attachment.label", {
            defaultValue: "Ajouter une pièce jointe",
          })}
          emphasis="Low"
          icon={<AttachFile size={22} />}
        />
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

type AlertsProps = {
  positionnedTrainees: Project["positionnedTrainees"];
  summonedTrainees: Project["summonedTrainees"];
  properties: Project["properties"];
};

const Alerts = ({ positionnedTrainees, summonedTrainees, properties }: AlertsProps) => {
  const { t } = useTranslation();
  const { isValid, alertText } = useMemo(() => {
    const peopleNumber = positionnedTrainees.length + summonedTrainees.length;
    const minIsValid = properties.minStock ? peopleNumber >= properties.minStock : true;
    const maxIsValid = properties.stock ? peopleNumber <= properties.stock : true;
    let alertText = "";

    if (!maxIsValid) {
      alertText = t("trainings.view.summon.modal.alert.max_stock_limit.label", {
        defaultValue:
          "Le nombre de participants ({{count}}) est supérieur au nombre de participants maximum ({{max_stock}})",
        count: peopleNumber,
        max_stock: properties.stock,
      });
    } else if (!minIsValid) {
      alertText = t("trainings.view.summon.modal.alert.min_stock_limit.label", {
        defaultValue:
          "Le nombre de participants ({{count}}) est inférieur au nombre de participants minimum ({{min_stock}})",
        count: peopleNumber,
        min_stock: properties.minStock,
      });
    }

    return {
      isValid: minIsValid && maxIsValid,
      alertText,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionnedTrainees, summonedTrainees, properties]);

  const { showNoEmailDisclaimer, traineesWithNoEmail } = useMemo(() => {
    const show = positionnedTrainees.some((trainee) => trainee.noEmail);
    const traineesNames = positionnedTrainees
      .filter((trainee) => trainee.noEmail)
      .map((trainee, index) => (index > 0 ? " " : "") + trainee.fullName);
    return {
      showNoEmailDisclaimer: show,
      traineesWithNoEmail: traineesNames,
    };
  }, [positionnedTrainees]);

  return (
    <div className={styles.contentBottom}>
      {!isValid && (
        <div className={styles.alert}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {alertText}
          </DSAlert>
        </div>
      )}
      {showNoEmailDisclaimer && (
        <div className={styles.alert}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {t("trainings.view.summon.modal.alert.no_email.label", {
              defaultValue:
                "Parmi les stagiaires à convoquer, certains n'ont pas d'adresse email :",
            })}{" "}
            <Users users={traineesWithNoEmail} maxStringLength={100} />.
            {t("trainings.view.summon.modal.alert.no_email_2.label", {
              defaultValue: "Ils ne recevront donc pas l'email de convocation.",
            })}
          </DSAlert>
        </div>
      )}
    </div>
  );
};
