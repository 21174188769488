import { DSCard, Select, DSDropdownItem, DSTooltip } from "@skillup/ui";
import styles from "./DashboardChildCard.module.scss";
import cx from "classnames";
import { useSetState } from "react-use";
import { useDebounce } from "use-debounce";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useDashboardChild, {
  DashboardChildPayload,
  InterviewActiveAttendeeRoles,
} from "../../hooks/useDashboardChild";
import { useDashboardExport } from "../../hooks/useDashboardExport";
import LoaderShimmer from "components/LoaderShimmer";

import { CampaignsRoutes, DashboardChildType, InterviewRoles } from "@skillup/espace-rh-bridge";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { TranslationType } from "hooks/useTranslation";

export type StructureChildPayload =
  CampaignsRoutes.Dashboard.GetCampaign["response"]["structure"]["sections"][0]["pages"][0]["children"][0];
export interface Props<T extends DashboardChildType> {
  child: StructureChildPayload;
  className?: string;
  icon: React.ReactNode;
  typeName: string;
  withRoleFilter?: boolean;
  title?: string;
  subTitle?: string;
  children?: (
    dashboardPayload: DashboardChildPayload<T>,
    state: DashboardChildState
  ) => React.ReactNode;
  bodyRef?: MutableRefObject<HTMLDivElement>;
  onShowComments?: () => void;
  t: TranslationType;
}
interface DashboardChildState {
  attendeeRole: InterviewActiveAttendeeRoles;
}

function DashboardChildCard<T extends DashboardChildType>({
  child,
  className,
  icon,
  withRoleFilter,
  title,
  subTitle,
  typeName,
  children,
  bodyRef,
  t,
}: Props<T>) {
  const { containerRef, isVisible } = useIntersectionObserver();
  const [isVisibleDebounced] = useDebounce(isVisible, 300);
  const [scrollPosition, setScrollPosition] = useState("none");

  // TODO: do it better, each type should have its own default role function
  // ex: Targets have an evaluate & create vs reply in questions
  function getDefaultRole() {
    if (
      [DashboardChildType.CLOSED_QUESTION, DashboardChildType.OPEN_QUESTION].includes(child.type)
    ) {
      const employeeCanReply = !child.disallow?.employee?.reply;
      if (employeeCanReply) return InterviewRoles.EMPLOYEE;
      return InterviewRoles.MANAGER;
    }

    return InterviewRoles.EMPLOYEE;
  }

  const [state, setState] = useSetState<DashboardChildState>({
    attendeeRole: getDefaultRole(),
  });
  const { dashboardPayload } = useDashboardChild(child.type, child.uuid, {
    ...(withRoleFilter && {
      attendeeRole: state.attendeeRole,
    }),
    enabled: isVisibleDebounced,
  });

  // handle background drop on scroll
  const scrollRef = useRef(null);
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const isBottom = Math.ceil(scrollTop + clientHeight) === scrollHeight;
    const isTop = scrollTop === 0;
    if (isTop && isBottom) {
      setScrollPosition("noScroll");
    } else if (isTop) {
      setScrollPosition("scrollTop");
    } else if (isBottom) {
      setScrollPosition("scrollBottom");
    } else {
      setScrollPosition("scroll");
    }
  };
  useEffect(() => {
    if (!isVisibleDebounced || !scrollRef.current) return;
    handleScroll();
  }, [scrollRef, isVisibleDebounced]);

  const { exportChild, isExporting, isAvailable } = useDashboardExport(child);

  return (
    <div className={cx(styles.DashboardChildCard, className)} ref={containerRef}>
      <DSCard
        width="100%"
        className={styles.card}
        contentClassName={styles.cardContent}
        showTitle={false}
        actionPosition="header"
        labels={[
          <div className={styles.header} key="header">
            <div className={styles.icon}>
              <DSTooltip label={typeName} className={styles.iconWrapper}>
                {icon}
              </DSTooltip>
            </div>
            <div className={styles.title}>
              {title && <h2>{title}</h2>}
              {subTitle && <h3>{subTitle}</h3>}
            </div>
          </div>,
        ]}
        actionItems={
          isAvailable ? (
            <DSDropdownItem
              label={t("dashboard.interviews.childCard.download", {
                defaultValue: "Exporter cette question\n(réponses et commentaires)",
              })}
              disabled={isExporting}
              onClick={exportChild}
            />
          ) : undefined
        }
      >
        {dashboardPayload ? (
          <div
            className={cx(styles.body, styles[scrollPosition], {
              [styles.hasFilter]: withRoleFilter,
            })}
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {withRoleFilter && (
              <div className={styles.filters}>
                <div className={styles.filter}>
                  <div className={styles.label}>
                    {t("dashboard.interviews.show.answers", {
                      defaultValue: "Afficher les réponses :",
                    })}
                  </div>
                  <Select<InterviewActiveAttendeeRoles>
                    className={styles.options}
                    isSearchable={false}
                    placeholder={t("dashboard.interviews.childCard.select.all", {
                      defaultValue: "Toutes les réponses",
                    })}
                    value={state.attendeeRole}
                    onChange={(attendeeRole) => setState({ attendeeRole })}
                    options={[
                      {
                        label: t("dashboard.interviews.childCard.select.employee", {
                          defaultValue: "Des collaborateurs",
                        }),
                        value: InterviewRoles.EMPLOYEE,
                      },
                      {
                        label: t("dashboard.interviews.childCard.select.manager", {
                          defaultValue: "Des responsables de l'entretien",
                        }),
                        value: InterviewRoles.MANAGER,
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            <div ref={bodyRef}>{children(dashboardPayload, state)}</div>
          </div>
        ) : (
          <div className={cx(styles.body, styles.loading)}>
            <LoaderShimmer className={styles.loadingLogo} />
            <p>
              {t("dashboard.interviews.childCard.loading", {
                defaultValue: "Chargement en cours…",
              })}
            </p>
          </div>
        )}
      </DSCard>
    </div>
  );
}

export default DashboardChildCard;
