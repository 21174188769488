import { useState, useEffect } from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { cross } from "uiAssets/Icons";

import styles from "./UserSearch.module.scss";
import { SearchInput } from "@skillup/ui";
import { ISimpleUserIdentity, USER_FILE_VALIDATION_TYPE } from "@skillup/types";
import { searchUsersByQuery } from "fetchers/users-fetcher";
import useTranslation from "hooks/useTranslation";

import type { SearchByQueryRouteType } from "types/api";

type IUserIdentity = SearchByQueryRouteType["response"][0];

interface IProps {
  autoFocus?: boolean;
  className?: string;
  onSelect?: (u: IUserIdentity) => void;
  selectManager?: (args: { email: string; organizationsByUuid: string[] }) => void;
  scope?: USER_FILE_VALIDATION_TYPE;
  filter?: (u: IUserIdentity) => boolean;
  styles?: React.CSSProperties;
  undelineColor?: string;
  "aria-label"?: string;
}

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);
  return debouncedValue;
};

const searchUsers = async (query, setResults, setHidden, scope: USER_FILE_VALIDATION_TYPE) => {
  if (!query || query.length === 0) {
    setResults([]);
    setHidden(true);
  } else {
    const users = await searchUsersByQuery(query, scope);
    setHidden(false);
    setResults(users);
  }
};

const select = (
  user: ISimpleUserIdentity,
  onSelect,
  onSelectManager,
  setResults,
  setHidden,
  resetInput,
  scope
) => {
  const selectManager =
    scope === USER_FILE_VALIDATION_TYPE.LEGACY_INTERVIEW_CAMPAIGN ||
    scope === USER_FILE_VALIDATION_TYPE.ONGOING_INTERVIEW_CAMPAIGN;
  if (onSelect) onSelect(user, selectManager);

  setResults([]);
  setHidden(true);
  resetInput();
};

const UserSearch = (props: IProps) => {
  const { className, onSelect, filter, scope, selectManager } = props;
  const [results, setResults] = useState<Array<IUserIdentity>>([]);
  const [hidden, setHidden] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");

  const { t } = useTranslation();

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    searchUsers(debouncedQuery, setResults, setHidden, scope);
  }, [debouncedQuery, setResults, setHidden, scope]);

  const resetInput = () => {
    setQuery("");
  };

  const filtered = filter ? results.filter(filter) : results;

  return (
    <div className={cx(styles.UserSearchInput, className)}>
      <SearchInput
        key={"scheduleNameInput"}
        aria-label="search-input"
        placeholder={t("trainings.view.add_trainees_modal.search.placeholder", {
          defaultValue: "Rechercher",
        })}
        onChange={(value) => setQuery(value)}
        value={query}
      />
      {!hidden && (
        <div className={styles.results} aria-label="users-list">
          {filtered.map((user) => (
            <div
              key={user.email}
              className={styles.result}
              onClick={() =>
                select(user, onSelect, selectManager, setResults, setHidden, resetInput, scope)
              }
              aria-label={user.email}
            >
              <div className={styles.info}>
                <span className={styles.name}>{user.fullName}</span>
                <span className={styles.email}>{user.email}</span>
              </div>
            </div>
          ))}
          {filtered.length === 0 && (
            <div className={styles.noResult} aria-label="empty-list">
              <Icon icon={cross} width={20} />
              <div
                onClick={() => {
                  setHidden(true);
                }}
                className={styles.info}
              >
                <span>
                  {t("trainings.view.add_trainees_modal.list.no_result", {
                    defaultValue: "Pas de résultats",
                  })}
                </span>
                <span>
                  {t("trainings.view.add_trainees_modal.list.no_data_db", {
                    defaultValue: "Collaborateur introuvable en base de données.",
                  })}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
