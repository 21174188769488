import React from "react";
import cx from "classnames";

import styles from "./SkillLevel.module.scss";
import { Tooltip } from "components/Tooltip";

interface SkillLevelProps {
  readonly level?: number;
  readonly totalLevel: Array<{ level: number; name: string }>;
  readonly expectedLevel?: number;
}

const SkillLevel = ({ level, totalLevel, expectedLevel }: SkillLevelProps) => {
  return (
    <div className={cx(styles.SkillLevel)}>
      {totalLevel
        .sort((a, b) => a.level - b.level)
        .map((e, i) => (
          <Tooltip key={e.level} label={e.name} flex direction="top">
            <span
              key={i}
              className={cx(styles.level, {
                [styles.reached]: level !== undefined && level >= e.level,
              })}
            >
              <span
                className={cx(styles.expectedLevel, {
                  [styles.expected]: expectedLevel !== undefined && expectedLevel === e.level,
                })}
              ></span>
            </span>
          </Tooltip>
        ))}
    </div>
  );
};

export { SkillLevel };
