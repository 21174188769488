import { useModal } from "@skillup/ui";
import { Dispatch } from "react";
import { useState } from "react";

import { useHabilitations, useFilteredHabilitations } from "../state/habilitations";
import { Habilitation } from "../types";
import { TranslationType } from "hooks/useTranslation";

export enum ModalStates {
  Modifying,
  LinkingUsers,
  Deleting,
  Idle,
  Creating,
}

export type State =
  | { state: ModalStates.Idle }
  | { state: ModalStates.Creating }
  | { state: ModalStates.Modifying; habilitation: Habilitation }
  | { state: ModalStates.LinkingUsers; habilitation: Habilitation }
  | { state: ModalStates.Deleting; habilitation: Habilitation };

type HabilitationsActionsHook = [
  {
    state: State;
    getLabelFromAction: typeof getLabelFromAction;
    getNewModalStateFromAction: typeof getNewModalStateFromAction;
    getTooltipFromAction: typeof getTooltipFromAction;
  },
  Dispatch<State>,
];
const useHabilitationsActions = (): HabilitationsActionsHook => {
  const { show, hide } = useModal();
  const { refetch } = useHabilitations();
  const { refetch: refetchFiltered } = useFilteredHabilitations();

  const [state, setState] = useState<State>({ state: ModalStates.Idle });

  return [
    { state, getLabelFromAction, getNewModalStateFromAction, getTooltipFromAction },
    (stateChange: State) => {
      switch (stateChange.state) {
        case ModalStates.Idle: {
          hide();
          refetch();
          refetchFiltered();
          break;
        }
        case ModalStates.Creating:
        case ModalStates.Modifying:
        case ModalStates.LinkingUsers:
        case ModalStates.Deleting: {
          show();
          break;
        }
      }

      return setState(stateChange);
    },
  ];
};

export default useHabilitationsActions;

const HabilitationActionsMap: Record<Habilitation["actions"][0]["type"], string> = {
  addTrackingsInBulk: "Associer des collaborateurs",
  modify: "Modifier l'habilitation",
  delete: "Supprimer l'habilitation",
};

function getLabelFromAction(action: Habilitation["actions"][0], t: TranslationType): string {
  return t("trainings.view.regulatory.action." + action.type, {
    defaultValue: HabilitationActionsMap[action.type],
  });
}

function getTooltipFromAction(
  habilitation: Habilitation,
  action: Habilitation["actions"][0],
  t: TranslationType
): string {
  if (!action.tooltip) {
    return undefined;
  }

  return t("trainings.view.regulatory.action." + action.type + "." + action.tooltip, {
    defaultValue:
      "Cette habilitation est associée à {{count}} collaborateurs et ne peut donc pas être supprimée.",
    count: habilitation.trackingsCount,
  });
}

const ModalStatesMapFromAction = {
  addTrackingsInBulk: ModalStates.LinkingUsers,
  modify: ModalStates.Modifying,
  delete: ModalStates.Deleting,
};

function getNewModalStateFromAction({
  action,
  habilitation,
}: {
  action: Habilitation["actions"][0];
  habilitation: Habilitation;
}) {
  return {
    state: ModalStatesMapFromAction[action.type],
    habilitation,
  };
}
