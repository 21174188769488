import { useHistory } from "react-router-dom";
import { useRef, useMemo, Suspense, useState, useEffect, useCallback } from "react";

import {
  GridColDef,
  useGridApiRef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { ListUtils } from "@skillup/shared-utils";
import { Text, Flex, Loader } from "@skillup/design-system";
import {
  useModal,
  DSButton,
  FilterRef,
  DSFilters,
  DSDataGrid,
  useMediaQueries,
  usePersistColumnSettings,
} from "@skillup/ui";

import { plural } from "utils/locale";
import useTranslation from "hooks/useTranslation";
import EmptyView from "containers/Supervisor/components/Empty";
import { stringToDates, computeFilteredRows } from "helpers/functions";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import { SkillRow } from "./utils/parseSkillIntoRow";
import useSkillsTableData from "./useSkillsTableData";
import { SkillsSidePanel } from "./components/SidePanel";
import { ToolbarButton } from "./components/Table/ToolbarButton";
import { useSkillsGridFilters } from "./utils/useSkillsGridFilters";
import { SkillsProvider, useSkillsContext } from "../../SkillsContext";
import { ArchiveConfirmModal, ArchivedSkillsModal } from "./components/Modal";

const ListSkills = () => {
  return (
    <SkillsProvider>
      <Flex height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </SkillsProvider>
  );
};

const Layout = () => {
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const confirmModal = useModal();
  const archivedModal = useModal();
  const sidePanelState = useModal();

  const [selectedSkill, setSelectedSkill] = useState<null | SkillRow>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const history = useHistory();
  const pathname = history.location.pathname;

  const { getSkills, removeSkill, updateArchiveStatus } = useSkillsContext();

  const { data: skillData, error: skillListError, status: skillListStatus } = getSkills();

  const [conf, filters, filterValues, setFilterValues, setFilterConfig] = useSkillsGridFilters(t);
  const filterRef = useRef<FilterRef>();

  const { archivedSkills, skills } = useMemo(() => {
    const processedSkills = stringToDates(skillData) ?? [];
    const archivedSkills = processedSkills?.filter((skill) => skill.isArchived);

    if (skillData && skillData.length > 0) {
      skillData[0].fields.map((customField) => {
        setFilterConfig(({ conf, initialValues }) => {
          return {
            conf: {
              ...conf,
              [customField.name]: {
                type: ListUtils.FilterType.TEXT,
                label: customField.name,
              },
            },
            initialValues: {
              ...initialValues,
              [customField.name]: {
                label: customField.name,
                placeholder: `Filtrer par ${customField.name}`,
              },
            },
          };
        });
      });
    }

    return {
      archivedSkills,
      skills: processedSkills,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillData]);

  const addActionToArchive = (row: SkillRow) => {
    setSelectedSkill(row);
    if (Object.keys(row.employeesWithSkill).length > 0) {
      if (selectedRows.length === 0) {
        setSelectedRows([row.uuid]);
      }
      confirmModal.show();
    } else {
      handleArchiveStatus({ isArchive: true, uuids: [row.uuid] });
    }
  };

  const { columns, rows } = useSkillsTableData({ actions: addActionToArchive, skills, t });

  const skillsToDisplay: SkillRow[] = useMemo(() => {
    return computeFilteredRows(rows, filterValues);
  }, [rows, filterValues]);

  const handleArchiveStatus = useCallback(
    ({ isArchive, uuids }: { uuids: string[]; isArchive: boolean }) => {
      updateArchiveStatus(
        isArchive,
        skills.concat(archivedSkills).filter((skill) => uuids.includes(skill.uuid))
      );
      setSelectedRows([]);
      if (archivedSkills.length === 1 || isArchive) {
        confirmModal.hide();
      }
    },
    [archivedSkills, confirmModal, skills, updateArchiveStatus]
  );

  const removeArchivedSkill = ({ uuid }: { uuid: string }) => {
    const skill = archivedSkills.find((skill) => uuid === skill.uuid);

    removeSkill(skill);
  };

  const archiveMultipleSkills = useCallback(() => {
    const uuids = Array.from(selectedRows).map(String);

    const areSkillsLinkedToJobs = skillsToDisplay.some(
      (skill) => uuids.includes(skill.uuid) && skill.jobsCount > 0
    );

    if (areSkillsLinkedToJobs) {
      confirmModal.show();
    } else {
      handleArchiveStatus({ isArchive: true, uuids });
    }
  }, [selectedRows, skillsToDisplay, confirmModal, handleArchiveStatus]);

  const toolbarActions = useMemo(
    () => [
      selectedRows.length > 1 && (
        <ToolbarButton
          key="archiveMultipleSkills"
          selectedRows={selectedRows}
          archiveMultipleSkills={archiveMultipleSkills}
        />
      ),
    ],
    [archiveMultipleSkills, selectedRows]
  );

  /* This useEffect is here to solve the problem
  that when we dynamically add a column, it is pinned instead of action column */
  const updateColumns = useCallback(() => {
    if (apiRef.current?.updateColumns) {
      apiRef.current.updateColumns(columns);
    }
  }, [apiRef, columns]);

  useEffect(updateColumns, [updateColumns]);

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };

  const { gridInitialState } = usePersistColumnSettings({
    apiRef,
    persistenceId: `grid-skills-skills.v3`,
  });

  const initialState = {
    ...gridInitialState,
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 20,
      },
    },
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
      right: ["actions"],
    },
  };

  if (isMobile) return <NotAvailableInMobileView />;

  switch (skillListStatus) {
    case "loading":
      return <Loader fillSpace />;
    case "error":
      return <Text>Error loading skills: {skillListError.message}</Text>;
    case "success":
      if (skillData.length === 0)
        return (
          <EmptyView
            withStyle
            buttonClick={() => history.push(`${pathname}/new`)}
            buttonLabel={t("skills.skillList.label.createSkill", {
              defaultValue: "Créer une compétence",
            })}
            message={t("skills.skillList.label.noSkillCreated", {
              defaultValue: "Vous n’avez pas encore créé de compétence.",
            })}
          />
        );
      else
        return (
          <Flex paddingTop="s" paddingHorizontal="s" flexDirection="column">
            <Flex flexGrow="1" flexDirection="column">
              <DSFilters
                t={t}
                config={conf}
                ref={filterRef}
                filters={filters}
                onChange={setFilterValues}
              />
              <DSDataGrid
                editable
                pagination
                apiRef={apiRef}
                columns={columns}
                checkboxSelection
                disableRowSelectionOnClick
                initialState={initialState}
                rows={skillsToDisplay || []}
                toolbarButtons={toolbarActions}
                rowCount={skillsToDisplay.length}
                onFilter={handleTableColumnFilterClick}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRows(newRowSelectionModel);
                }}
                emptyOverlay={{
                  text: t("skills.list.skill.emptyOverlayText", {
                    defaultValue: "Aucune compétence ne correspond à votre recherche.",
                  }),
                }}
                entityName={plural(
                  skillsToDisplay.length || 0,
                  `%n ${t("skills.list.skill.entityName", {
                    defaultValue: "compétence",
                  })}%s`
                )}
                onRowClick={(row) => {
                  const currentSkill = rows.find((skill) => skill.uuid === row.id);
                  setSelectedSkill(currentSkill);
                  setSelectedRows([row.id]);
                  sidePanelState.show();
                }}
                errorOverlay={{
                  text: [
                    t("skills.list.job.errorOverlayText.firstSentence", {
                      defaultValue: `Une erreur est survenue lors du chargement des fiches de poste.`,
                    }),
                    t("skills.list.collaborator.errorOverlayText.secondSentence", {
                      defaultValue: `Veuillez réessayer ultérieurement.`,
                    }),
                    t("skills.list.collaborator.errorOverlayText.thirdSentence", {
                      defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
                    }),
                  ],
                }}
              />
            </Flex>

            {archivedSkills?.length > 0 && (
              <Flex marginTop="m" marginBottom="s">
                <DSButton
                  buttonSize="S"
                  emphasis="Low"
                  onClick={archivedModal.show}
                  label={t("skills.button.list.seeArchivedSkills", {
                    defaultValue: "Voir {{label}}",
                    label: plural(archivedSkills?.length, "la compétence archivée", {
                      pluralText: "les %n compétences archivées",
                    }),
                  })}
                />
              </Flex>
            )}

            {selectedSkill && (
              <SkillsSidePanel
                skill={selectedSkill}
                close={sidePanelState.hide}
                isOpen={sidePanelState.isOpen}
                openConfirmModal={confirmModal.show}
              />
            )}

            <ArchivedSkillsModal
              close={archivedModal.hide}
              open={archivedModal.isOpen}
              archivedSkills={archivedSkills}
              remove={(skillUuid) => {
                removeArchivedSkill({ uuid: skillUuid });
              }}
              unarchive={(skillUuid) => {
                handleArchiveStatus({ isArchive: false, uuids: [skillUuid] });
              }}
            />

            <ArchiveConfirmModal
              close={confirmModal.hide}
              open={confirmModal.isOpen}
              confirm={(uuids) => {
                handleArchiveStatus({ isArchive: true, uuids });
              }}
              selectedSkills={Array.from(selectedRows).map((id) =>
                skills.find((skill) => skill.uuid === id)
              )}
            />
          </Flex>
        );
    default:
      return (
        <Flex alignItems="center" flexDirection="column">
          <Text>
            {t("skills.skillList.label.error", {
              defaultValue: "Une erreur est survenue lors du chargement des compétences",
            })}
          </Text>
          <DSButton
            buttonSize="S"
            emphasis="Low"
            onClick={window.location.reload}
            label={t("skills.skillList.button.reload", {
              defaultValue: "Recharger la page",
            })}
          />
        </Flex>
      );
  }
};

export default ListSkills;
