import { TranslationType } from "hooks/useTranslation";
import type { Child } from "../../../../reducer";
import { MultipleChoicesQuestion } from "./MultipleChoicesQuestion/MultipleChoicesQuestion";
import { TextQuestion } from "./TextQuestion/TextQuestion";

export type QuestionChild = Extract<Child, { kind: "question" }>;

type QuestionProps = {
  child: QuestionChild;
  updateTemplate: (data: QuestionChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  t: TranslationType;
};

const Question = ({ child, updateTemplate, indexes, t }: QuestionProps) => {
  switch (child.type) {
    case "checkbox":
    case "radio":
      return (
        <MultipleChoicesQuestion
          child={child}
          updateTemplate={updateTemplate}
          indexes={indexes}
          t={t}
        />
      );
    case "text":
      return <TextQuestion child={child} updateTemplate={updateTemplate} indexes={indexes} t={t} />;
    default:
      return <div>Unknown question type</div>;
  }
};

export { Question };
