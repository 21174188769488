import { FieldRoutesType } from "@skillup/espace-rh-bridge";
import { EditableCompanyField } from "@skillup/training-bridge";
import { TranslationType } from "hooks/useTranslation";
import { buildRequest } from "utils/buildRequest";
import { z } from "zod";

export const compareValues = (field: EditableCompanyField, values: EditableCompanyField) => {
  const hasSameRestrictions =
    field.restrictions.length === values.restrictions.length &&
    field.restrictions.every((v, i) => v === values.restrictions[i]);
  const hasSameOptions =
    field.options.length === values.options.length &&
    field.options.every(
      (v, i) =>
        v.key === values.options[i].key &&
        v.value === values.options[i].value &&
        v.disabled === values.options[i].disabled
    );

  return (
    field.binding === values.binding &&
    field.defaultValue === values.defaultValue &&
    field.type === values.type &&
    field.label === values.label &&
    field.isTrainingField === values.isTrainingField &&
    field.isHidden === values.isHidden &&
    field.massEditAvailable === values.massEditAvailable &&
    hasSameRestrictions &&
    hasSameOptions
  );
};

export const fieldFormValidation = (t: TranslationType, isCreate: boolean) => {
  const fieldErrors = {
    required_error: t("fields.form.errors.required", {
      defaultValue: "Champ requis",
    }),
  };

  return z.object({
    binding: z
      .string(fieldErrors)
      .refine((value) => /^[a-zA-Zéèàâùôûî][a-zA-Z0-9éèàâùôûî_\s-]*$/g.test(value!), {
        message: t("fields.form.errors.binding.format", {
          defaultValue: "Le format de l'identifiant technique est invalide.",
        }),
      })
      .refine(
        async (value) => {
          if (!isCreate) {
            return true;
          }

          const result = await buildRequest<FieldRoutesType.GetV2>({
            method: "GET",
            path: "/fields-v2",
            target: "API",
            query: {
              bindings: [value],
            },
          })();

          if (result.length > 0) {
            return false;
          }

          return true;
        },
        t("fields.form.errors.binding.unicity", {
          defaultValue: "Cet identifiant technique est déjà utilisé",
        })
      ),
    type: z.enum(["select", "number", "text", "monetary", "textarea", "date"], fieldErrors),
    label: z.string(fieldErrors),
    isTrainingField: z.boolean(fieldErrors),
    restrictions: z.array(z.string()),
    massEditAvailable: z.boolean(),
    options: z
      .array(z.object({ key: z.string(), value: z.string(fieldErrors) }))
      .superRefine((items, ctx) => {
        const uniqueValues = new Map<string, number>();
        items.forEach((item, idx) => {
          const firstAppearanceIndex = uniqueValues.get(item.value);
          if (firstAppearanceIndex !== undefined) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t("field.config.errors.duplicateOption", {
                defaultValue: "Cette valeur existe déjà",
              }),
              path: [idx, "value"],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t("field.config.errors.duplicateOption", {
                defaultValue: "Cette valeur existe déjà",
              }),
              path: [firstAppearanceIndex, "value"],
            });
            return;
          }
          uniqueValues.set(item.value ?? "", idx);
        });
      })
      .optional(),
  });
};

export const generateBindingFromLabel = (label: string) => {
  return (
    label
      // eslint-disable-next-line no-useless-escape
      .replace(/[.,\/#!$%\^\?&\*;:{}=\-`~()]/g, "")
      .replace(/\s+/g, "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  );
};
