import { useState } from "react";

import { MdSearch as Search } from "react-icons/md";

import { format } from "@skillup/shared-utils";
import { Flex, Text } from "@skillup/design-system";
import { DSModal, DSButton, DSTextInput, HorizontalDivider } from "@skillup/ui";

import User from "utils/User";
import { ProcessedSkill } from "types/skills";
import useTranslation from "hooks/useTranslation";

import styles from "../../ListSkills.module.scss";

interface ArchivedSkillsModalProps {
  open: boolean;
  close: () => void;
  archivedSkills: ProcessedSkill[];
  remove: (skillUuid: string) => void;
  unarchive: (skillUuid: string) => void;
}

const ArchivedSkillsModal = ({
  archivedSkills,
  close,
  open,
  remove,
  unarchive,
}: ArchivedSkillsModalProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const archivedSkillsFiltered = archivedSkills?.filter((skill) =>
    skill.name.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <DSModal isOpen={open} portalTarget={document.body} className={styles.skillArchivedModal}>
      <DSModal.Header onClose={close}>
        <DSModal.Header.Title
          title={
            archivedSkills?.length > 1
              ? t("skills.label.list.archivedSkills", {
                  count: archivedSkills?.length,
                  defaultValue: "{{count}} compétences archivées",
                })
              : t("skills.label.list.archivedSkill", {
                  defaultValue: "1 compétence archivée",
                })
          }
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex height="100%" flexDirection="column">
          <Flex height="100%" marginTop="xs" flexDirection="row" className={styles.modalSearch}>
            <DSTextInput
              name="searchSkill"
              value={searchQuery}
              actionButton={<Search />}
              className={styles.searchInput}
              onChange={setSearchQuery}
              placeholder={t("skills.archivedModal.searchPlaceholder", {
                defaultValue: "Rechercher une compétence",
              })}
            />
          </Flex>

          {archivedSkillsFiltered.length > 0 ? (
            <Flex height="100%" flexDirection="column">
              {archivedSkillsFiltered?.map((skill, index) => (
                <Flex width="100%" key={skill.uuid} flexDirection="column">
                  <Flex gap="xxs" width="100%" alignItems="center">
                    <Flex width="100%" flexDirection="column">
                      <Text
                        fontSize="1rem"
                        espaceFont="body1Regular"
                        color="plainText-onLight-default"
                      >
                        {skill.name}
                      </Text>
                      <Text
                        fontSize="0.75rem"
                        espaceFont="body1Regular"
                        color="plainText-onLight-lighter"
                      >
                        {t("skills.archivedModal.row.archivedAtBy", {
                          archivedDate: format(new Date(skill.updatedAt), "dd/MM/yyyy"),
                          archivedUser: skill?.updatedBy
                            ? skill?.updatedBy.fullName
                            : "Admin Skillup",
                          defaultValue:
                            "Compétence archivée le {{archivedDate}} par {{archivedUser}}",
                        })}
                      </Text>
                    </Flex>
                    <DSButton
                      emphasis="Mid"
                      buttonSize="S"
                      className={styles.modalArchiveBtn}
                      onClick={() => unarchive(skill.uuid)}
                      label={t("skills.archivedModal.buttonUnarchive", {
                        defaultValue: "Désarchiver",
                      })}
                    />
                    {User.isSkillupAdmin() && (
                      <DSButton
                        emphasis="Mid"
                        buttonSize="S"
                        className={styles.modalArchiveBtn}
                        onClick={() => {
                          remove(skill.uuid);
                        }}
                        label={t("skills.archivedModal.deleteRowButton", {
                          defaultValue: "[DEV] Supprimer",
                        })}
                      />
                    )}
                  </Flex>
                  {archivedSkillsFiltered?.length - 1 !== index && (
                    <HorizontalDivider top={"xs"} bottom={"xs"} />
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <Text marginBottom="s" espaceFont="body1Regular">
              {t("skills.archivedSkillsModal.noResult", {
                defaultValue: "Aucune compétence ne correspond à votre saisie",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};

export default ArchivedSkillsModal;
