import { trpc } from "utils/trpc";

const useJobs = () => {
  const { data } = trpc.jobs.getAll.useQuery();

  const parsedJobs = data
    ?.filter((job) => !job.isArchived)
    .map((job) => ({
      uuid: job.uuid,
      name: job.name,
    }));

  return { data: parsedJobs };
};

export default useJobs;
