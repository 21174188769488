/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useState,
  useMemo,
  forwardRef,
  type ForwardedRef,
  useImperativeHandle,
} from "react";
import cx from "classnames";
import { useUpdateEffect } from "react-use";

import { ListUtils } from "@skillup/shared-utils";

import { colors } from "assets/colors";
import { type Themes } from "components/commonProps";

import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Tooltip } from "components/Tooltip";
import { Filter } from "./components/Filter";
import { TextInput } from "components/Form/TextInput";
import { Dropdown } from "components/Dropdown/Dropdown";
import { ExpandMore, Refresh, Search } from "components/MaterialIcons";

import styles from "./Filters.module.scss";
import { type CompleteFilterProps, type FilterProp } from "./types";

const isFilterVisible = (filterId, filters: FilterProp<any>, init = false) => {
  if (!filters || !filters[filterId]) {
    return false;
  }

  const filter = filters[filterId];

  if (filter.visibilityMode === "always") {
    return true;
  }

  if (filter.visibilityMode === "never") {
    return false;
  }

  if (init && filter.value !== undefined) {
    return true;
  }

  if (filter.defaultValue !== undefined) {
    return true;
  }

  return false;
};

const getFilterValue = (
  filter: CompleteFilterProps<any>,
  init = false
): string | null | undefined => {
  if (init && filter.value !== undefined) {
    return filter.value;
  }

  if (filter.defaultValue !== undefined) {
    return filter.defaultValue;
  }

  return undefined;
};

interface FilterCategory {
  id: string;
  label: string;
  filters: string[];
}

export interface Props<T extends ListUtils.FilterConfigurationMap> {
  readonly theme: Themes;
  readonly filters: FilterProp<T>;
  readonly config: T;
  readonly onChange: (values: ListUtils.FilterValues<T>) => void;
  readonly className?: string;
  readonly t: (key: string, params?: { defaultValue: string } & any) => string;
  readonly translationPrefix?: string;
  readonly darkMode?: boolean;
  readonly isFilterableFilterList?: boolean;
  readonly duplicateFilters?: Array<string>;
  readonly filterCategories?: FilterCategory[];
}

export interface FilterRef {
  addFilter(id: string): void;
  setValue(id: string, value: ListUtils.FilterValue): void;
  setValues(values: ListUtils.FilterValues<any>): void;
}

function PureFilters<T extends ListUtils.FilterConfigurationMap>(
  {
    t,
    translationPrefix = "filters",
    config,
    theme,
    className,
    filters,
    onChange,
    darkMode = false,
    isFilterableFilterList = false,
    duplicateFilters = [],
    filterCategories = [],
  }: Props<T>,
  ref: ForwardedRef<FilterRef>
): JSX.Element | null {
  const [values, setValues] = useState<ListUtils.FilterValues<T>>(
    Object.keys(filters ?? {}).reduce(
      (acc, filterId) => ({
        ...acc,
        [filterId]: {
          value: getFilterValue(filters[filterId], true),
          operator: filters[filterId].operator ?? ListUtils.FilterOperator.CONTAINS,
          type: filters[filterId].type,
          data: filters[filterId].data,
        },
      }),
      {} as ListUtils.FilterValues<T>
    )
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [visibleFilters, setVisibleFilters] = useState(
    Object.keys(config ?? {}).filter((filterId) => isFilterVisible(filterId, filters ?? {}, true))
  );
  const [filterInstances, setFilterInstances] = useState<{ [key: string]: number }>({});

  const translate = (key: string, obj?: any) => t(`${translationPrefix}.${key}`, obj);

  useImperativeHandle(
    ref,
    () => ({
      setValue(id: keyof T, value: ListUtils.FilterValue) {
        setValues((oldValues) => ({ ...oldValues, [id]: value }));
      },
      setValues(values: ListUtils.FilterValues<T>) {
        setValues(values);
      },
      addFilter(id: string) {
        handleAddFilter(id);
      },
    }),
    [visibleFilters]
  );
  // Events
  useUpdateEffect(() => {
    onChange(
      Object.keys(values)
        .filter(
          (filterId) =>
            values[filterId] &&
            values[filterId].value !== undefined &&
            values[filterId].value !== null
        )
        .reduce(
          (acc, filterId) => ({
            ...acc,
            [filterId]: values[filterId],
          }),
          {} as ListUtils.FilterValues<T>
        )
    );
  }, [values]);

  // Event handlers
  const handleAddFilter = (id: keyof T) => {
    if (!config[id]) {
      return;
    }

    const instances = filterInstances[String(id)] || 0;
    const newFilterId = instances > 0 ? `${String(id)}_$${instances}` : String(id);

    if (duplicateFilters.includes(String(id))) {
      setFilterInstances((prev) => ({
        ...prev,
        [String(id)]: instances + 1,
      }));
    }

    setVisibleFilters((prev) => [...prev, newFilterId]);
    setValues((prev) => ({
      ...prev,
      [newFilterId]: {
        value: getFilterValue(filters![id.toString()]),
        operator: filters![id.toString()].operator ?? ListUtils.FilterOperator.CONTAINS,
        type: config[id].type,
        data: filters![id.toString()].data,
      },
    }));
  };

  const handleUpdateFilter = (id: string, value: any) => {
    setValues((values) => ({
      ...values,
      [id]: value,
    }));
  };

  const handleResetFilter = (id: string) => {
    const baseId = id.split("_$")[0];
    setValues((values) => ({
      ...values,
      [id]: {
        value: getFilterValue(filters![baseId]),
        operator: filters![baseId].operator ?? ListUtils.FilterOperator.CONTAINS,
        type: config[baseId].type,
      },
    }));
  };

  const handleResetAllFilter = () => {
    setValues(
      Object.keys(filters ?? {}).reduce(
        (acc, filterId) => ({
          ...acc,
          [filterId]: {
            value: getFilterValue(filters![filterId]),
            operator: filters![filterId].operator ?? ListUtils.FilterOperator.CONTAINS,
            type: config[filterId].type,
          },
        }),
        {} as ListUtils.FilterValues<T>
      )
    );
    setVisibleFilters(
      Object.keys(config ?? {}).filter((filterId) => isFilterVisible(filterId, filters ?? {}))
    );
    setFilterInstances({});
  };

  const handleDeleteFilter = (id: string) => {
    setValues((values) => ({
      ...values,
      [id]: undefined,
    }));
    setVisibleFilters((visibleFilters) => visibleFilters.filter((filterId) => filterId !== id));
  };

  const notVisibleFilters = useMemo(
    () =>
      Object.keys(config ?? {})
        .filter((id) => duplicateFilters.includes(id) || !visibleFilters.some((f) => f === id))
        .sort((idA, idB) => {
          const labelA = filters[idA]?.label ?? translate(`filter.label.${idA}`);
          const labelB = filters[idB]?.label ?? translate(`filter.label.${idB}`);
          return labelA.localeCompare(labelB);
        }),
    [config, values]
  );

  const normalizeString = (str: string) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  const searchFilter = (id: string) => {
    if (!isFilterableFilterList || !searchTerm.trim()) return true;
    const label = filters[id]?.label ?? translate(`filter.label.${id}`);
    return normalizeString(label.toLowerCase()).includes(normalizeString(searchTerm.toLowerCase()));
  };

  const getItemLabel = (id: string): string => {
    if (filters[id]?.label) return filters[id].label;

    const customFields = Array.from({ length: 10 }, (_, i) => `customField${i}`);

    if (customFields.includes(id)) return id;

    return translate(`filter.label.${id}`);
  };

  const renderDropdownItem = (id: string) => (
    <Dropdown.Item
      data-testid={`filter-${id}`}
      key={id}
      label={getItemLabel(id)}
      onClick={() => handleAddFilter(id)}
    />
  );

  const renderFilterList = () => {
    if (filterCategories.length > 0) {
      return filterCategories.map((category) => {
        const categoryFilters = notVisibleFilters.filter((id) => category.filters.includes(id));
        const filteredCategoryFilters = categoryFilters.filter(searchFilter);

        if (filteredCategoryFilters.length === 0) return null;

        return (
          <div key={category.id} className={styles.FilterCategory}>
            <div className={styles.CategoryLabel}>{category.label}</div>
            {filteredCategoryFilters.map(renderDropdownItem)}
          </div>
        );
      });
    }

    return notVisibleFilters.filter(searchFilter).map(renderDropdownItem);
  };

  return (
    <Flex
      role="filters-container"
      className={cx(styles.Filters, styles[theme], className, { [styles.darkMode]: darkMode })}
    >
      <Flex className={cx(styles.FilterContainer)}>
        {visibleFilters
          .sort((idA, idB) => {
            const filterABase = idA.split("_$")[0];
            const filterBBase = idB.split("_$")[0];
            const filterA = filters[filterABase];
            const filterB = filters[filterBBase];

            if (filterA.sortPosition === undefined && filterB.sortPosition === undefined) {
              return 0;
            }

            if (filterA.sortPosition === undefined) {
              return 1;
            }

            if (filterB.sortPosition === undefined) {
              return -1;
            }

            return filterA.sortPosition - filterB.sortPosition;
          })
          .map((filterId, index) => {
            const baseId = filterId.split("_$")[0];
            return (
              <Filter
                key={`${filterId}-${index}`}
                t={translate}
                id={filterId}
                darkMode={darkMode}
                translationPrefix={translationPrefix}
                filter={filters[baseId]}
                config={config[baseId]}
                value={values[filterId]}
                theme={theme}
                onChange={handleUpdateFilter}
                onReset={handleResetFilter}
                onDelete={handleDeleteFilter}
              />
            );
          })}
        <Flex role="filters-actions" className={cx(styles.ActionContainer)}>
          <Dropdown
            theme={theme}
            disabled={notVisibleFilters.length === 0}
            darkMode={darkMode}
            button={
              <Button
                role="add-filter-button"
                emphasis="Low"
                buttonSize="S"
                darkMode={darkMode}
                label={t(`${translationPrefix}-add_filter`, {
                  defaultValue: "Ajouter un filtre",
                })}
                actionIcon={<ExpandMore />}
              />
            }
          >
            {isFilterableFilterList && (
              <TextInput
                name="filter-search"
                className={styles.FilterSearchInput}
                placeholder={t("filter.search", { defaultValue: "Rechercher un champ" })}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e)}
                actionButton={<Search size="1.375rem" color={colors.blueyGrey} />}
              />
            )}
            {renderFilterList()}
          </Dropdown>
          <Tooltip
            direction="top"
            darkMode={darkMode}
            label={t(`${translationPrefix}-reset_all`, {
              defaultValue: "Réinitialiser les filtres",
            })}
          >
            <Button
              role="clear-filters-button"
              className={cx(styles.RefreshButton)}
              buttonSize="S"
              iconOnly
              darkMode={darkMode}
              icon={<Refresh />}
              onClick={handleResetAllFilter}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Filters = forwardRef(PureFilters);

export { Filters };
