import { ISimpleSelectionUser } from "@skillup/types";
import { uniqBy } from "lodash";

import { TranslationType } from "hooks/useTranslation";

export const SESSION_STATES = {
  provisional: "provisional",
  achieved: "achieved",
  summoned: "summoned",
  canceled: "canceled",
  closed: "closed",
  expired: "expired",
} as const;

export const DEFAULT_SESSION_STATES = {
  provisional: "Provisoire",
  achieved: "Réalisée",
  summoned: "Convoquée",
  canceled: "Annulée",
  closed: "Clôturée",
  expired: "Date dépassée",
} as const;

type ValueOf<T> = T[keyof T];

export type SessionState = ValueOf<typeof SESSION_STATES>;

export const AFTER_SUMMONED_STATES = Array.from<SessionState>([
  SESSION_STATES.achieved,
  SESSION_STATES.summoned,
  SESSION_STATES.canceled,
  SESSION_STATES.closed,
]);
export const buildRegisteredUsers = (
  project: any,
  t?: TranslationType
): Array<ISimpleSelectionUser> => {
  const users = uniqBy(
    [...project.positionnedTrainees, ...project.summonedTrainees].map((user) =>
      project.summonedTrainees.includes(user)
        ? {
            ...user,
            type: t(`training.entity.session.user_type.summoned.label`, {
              defaultValue: "Convoqué",
            }),
          }
        : {
            ...user,
            type: t(`training.entity.session.user_type.positioned.label`, {
              defaultValue: "En attente de convocation",
            }),
          }
    ),
    "email"
  );

  return users;
};
