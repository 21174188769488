import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { DSAlert, DSAlertDisplay, DSAlertType, DSModal } from "@skillup/ui";
import { type Project } from "services/sessions";
import { parseDateRange } from "utils/dates/dates";
import useTranslation from "hooks/useTranslation";

import { CalendarHourSettingInput } from "./CalendarHourSettingInput";
import styles from "./SettingCalendarEventsModal.module.scss";
import { type CalendarEvent } from "./stateMachine";

type CalendarEventWithValidation = CalendarEvent & { isValid: boolean };
export function SettingCalendarEventsModal({
  onBack,
  onClose,
  onConfirm,
  session,
  calendarEvents,
}: {
  calendarEvents: CalendarEvent[];
  session: Project;
  onClose: () => void;
  onConfirm: (calendarEvents: CalendarEvent[]) => void;
  onBack: () => void;
}) {
  const [events, setEvents] = useState<CalendarEventWithValidation[]>(
    calendarEvents?.map((calendarEvent) => ({
      ...calendarEvent,
      isValid: true,
    })) ??
      session.properties.dates.map((interval) => {
        const { hours } = parseDateRange(interval);
        if (hours) return { hours, isValid: isValidHours(hours), dateInterval: interval };
        return { hours: ["09:00", "18:00"], isValid: true, dateInterval: interval };
      })
  );
  const { t } = useTranslation();

  const handleEventHoursChange = useCallback(
    (hour, index) => {
      const newEvents = [...events];
      newEvents[index] = { ...newEvents[index], hours: hour, isValid: isValidHours(hour) };
      setEvents(newEvents);
    },
    [events]
  );

  const showError = useMemo(() => {
    return events.filter(({ isValid }) => !isValid).length > 0;
  }, [events]);

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.view.summon.modal.calendar_event.title.label", {
            defaultValue: "Invitation agenda",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.headLine}>
          <p>
            {t("trainings.view.summon.modal.calendar_event.settings.label", {
              defaultValue: "Paramétrage des heures de début et de fin des invitations agenda :",
            })}
          </p>
        </div>
        <div className={styles.alignDateSettingsContainer}>
          <div className={styles.dateSettingsContainer}>
            {events.map(({ hours, isValid, dateInterval }, index) => (
              <CalendarHourSettingInput
                days={dateInterval}
                key={index}
                hours={hours}
                updateHours={(hour) => handleEventHoursChange(hour, index)}
                showError={!isValid}
              />
            ))}
          </div>
        </div>
        {showError && (
          <DSAlert
            className={styles.alert}
            type={DSAlertType.ERROR}
            display={DSAlertDisplay.INLINE}
          >
            {t("trainings.view.summon.modal.calendar_event.alert.hours.label", {
              defaultValue: "L'heure de fin doit être postérieure à l'heure de début.",
            })}
          </DSAlert>
        )}
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onBack} label={t("trainings.common.back.label", {
            defaultValue: "Retour",
          })} />
        <DSModal.Footer.PrimaryButton
          onClick={() => {
            onConfirm(events.map(({ hours, dateInterval }) => ({ hours, dateInterval })));
          }}
          label={t("trainings.common.next.label", {
            defaultValue: "Suivant",
          })}
          disabled={showError}
        />
      </DSModal.Footer>
    </DSModal>
  );
}

const isValidHours = (hours: string[]) => {
  const [start, end] = hours;
  if (_.isEmpty(start) || _.isEmpty(end)) {
    return false;
  }
  const startSplit = start.split(":");
  const endSplit = end.split(":");
  const startMinutes = Number(startSplit[0]) * 60 + Number(startSplit[1]);
  const endMinutes = Number(endSplit[0]) * 60 + Number(endSplit[1]);

  return startMinutes < endMinutes;
};
