import { Flex, Text } from "@skillup/design-system";
import styles from "./ReadOnlyFieldConfiguration.module.scss";
import { DSButton } from "@skillup/ui";
import { EmployeeField } from "../../../services";
import { useMemo } from "react";
import useTranslation from "hooks/useTranslation";
import { UpdatableFieldConfiguration } from "../../../types";

type ReadOnlyFieldConfigurationProps = {
  employeeProfileConfiguration: {
    fieldConfiguration: UpdatableFieldConfiguration[];
    employeeFields: EmployeeField[];
  };
  enableEdit: () => void;
};

export const ReadOnlyFieldConfiguration = ({
  employeeProfileConfiguration,
  enableEdit,
}: ReadOnlyFieldConfigurationProps) => {
  const { t } = useTranslation();

  const fieldsMap = useMemo(() => {
    return employeeProfileConfiguration.employeeFields.reduce<Record<string, EmployeeField>>(
      (acc, field) => {
        acc[field.binding] = field;
        return acc;
      },
      {}
    );
  }, [employeeProfileConfiguration.employeeFields]);

  const fields = useMemo(() => {
    return employeeProfileConfiguration.fieldConfiguration
      .sort((a, b) => a.order - b.order)
      .map((field) => fieldsMap[field.binding]);
  }, [fieldsMap, employeeProfileConfiguration.fieldConfiguration]);

  return (
    <Flex gap="s" flexDirection="column">
      <Flex gap="xxs">
        <Text espaceFont="h5">
          {t("employeeProfile.fieldConfiguration.title", {
            defaultValue: "Informations mises en avant",
          })}
        </Text>
      </Flex>
      <Flex gap="xxs" flexDirection="column">
        <Flex>
          <Text espaceFont="captionBold">
            {t("employeeProfile.fieldConfiguration.subTitle", {
              defaultValue: "Données affichées",
            })}
          </Text>
        </Flex>
        <Flex
          className={styles.fieldConfigurationList}
          gap="xxs"
          padding="s"
          flexDirection="column"
          backgroundColor="surface-light"
        >
          {employeeProfileConfiguration.fieldConfiguration.length === 0 && (
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("employeeProfile.fieldConfiguration.readOnly.noFieldConfiguration", {
                defaultValue: "Aucune donnée affichée",
              })}
            </Text>
          )}
          {fields.map(({ label }) => (
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {label}
            </Text>
          ))}
        </Flex>
      </Flex>
      <Flex>
        <DSButton
          label={t("employeeProfile.fieldConfiguration.readOnly.btn.edit", {
            defaultValue: "Modifier",
          })}
          buttonSize="S"
          emphasis="Mid"
          onClick={enableEdit}
        />
      </Flex>
    </Flex>
  );
};
