import { isNil } from "lodash";

import { TranslationType } from "hooks/useTranslation";
import { Child } from "../../reducer";

export function getChildLabel(child: Child, t: TranslationType) {
  switch (child.kind) {
    case "question":
      if (child.type === "radio") {
        return child.label;
      }
      if (child.type === "checkbox") {
        return child.label;
      }
      if (child.type === "text") {
        return child.label;
      }
      return "Unknown question type";
    case "targets":
      if (child.type === "current") {
        return t("interviews.templateBuilder.child.target.current", {
          defaultValue: "Revue des objectifs",
        });
      }
      return t("interviews.templateBuilder.child.target.next", {
        defaultValue: "Fixation d'objectifs",
      });
    case "table":
      return child.label;
    case "guidelines":
      return trimHtmlTagsAndEntities(child.description);
    case "personnalisableGuidelines":
      return trimHtmlTagsAndEntities(child.description);
    case "conclusion":
      return "Partage et signature";
    case "html":
      return trimHtmlTagsAndEntities(child.content);
    case "title":
      return child.title;
    case "interviewsHistory":
      return t("interviews.templateBuilder.child.interviewsHistory", {
        defaultValue: "Historique d'entretiens",
      });
    case "trainingsHistory":
      return t("interviews.templateBuilder.child.trainingsHistory", {
        defaultValue: "Historique de formation",
      });
    case "trainingsCollection":
      return t("interviews.templateBuilder.child.trainingsCollection", {
        defaultValue: "Demande de formation",
      });
    case "job":
      return child.title;
    case "upload":
      return t("interviews.templateBuilder.child.upload", {
        defaultValue: "Téléchargement de fichiers",
      });
    case "globalTargetsCompletion":
      return t("interviews.templateBuilder.child.globalTargetsCompletion", {
        defaultValue: "Taux d’atteinte global",
      });
    case "skills":
      return t("interviews.templateBuilder.child.skills", {
        defaultValue: "Compétences",
      });
    case "userSpecificData":
      return t("interviews.templateBuilder.child.userSpecificData", {
        defaultValue: "Données spécifiques à l'utilisateur",
      });
    default:
      return "Unknown child kind";
  }
}

export function trimHtmlTagsAndEntities(htmlString: string) {
  if (isNil(htmlString)) {
    return "";
  }
  return htmlString.replace(/<[^>]+>|&(?:[a-z\d]+|#\d+|#x[a-f\d]+);/gi, "");
}
