import { FieldsRoutes } from "@skillup/core-hr-bridge";

import { buildRequest } from "utils/buildRequest";

export type Field = FieldsRoutes.GetList["response"]["fields"][0];

export async function getFields(): Promise<Field[]> {
  try {
    const result = await buildRequest<FieldsRoutes.GetList>({
      method: "GET",
      path: `/fields`,
      target: "CORE_HR",
    })();

    return result.fields;
  } catch (err) {
    throw new Error("Error fetching fields");
  }
}
