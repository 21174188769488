import React from "react";
import { Users } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const ManagerOnlyCampaign = ({ t }: { t: (l: string, p: object) => string }) => (
  <RectangleTag
    label={t("interviews.managedByManager", { defaultValue: "Géré par le responsable" })}
    icon={<Users />}
    backgroundColor="#EE82EE"
    textColor="#000000"
  />
);

export { ManagerOnlyCampaign };
