import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { type Locales } from "@skillup/shared-utils";

import { BetweenOperator, NumberFilterWrapper } from "../../../commons.styled";
import { ThemedNumberInput } from "components/Form/Inputs/NumberInput";
import { ListUtils } from "@skillup/shared-utils";

interface NumberFilterProps {
  onChange: (value: string | [string, string]) => void;
  lang?: Locales;
  value: {
    value: string | [string, string];
    operator: string;
  };
  darkMode?: boolean;
  className: string;
}

const NumberFilter = ({ onChange, className, value, darkMode = false }: NumberFilterProps) => {
  const { t } = useTranslation();
  const [firstValue, setFirstValue] = React.useState<string>(
    Array.isArray(value.value) ? value.value[0] : value.value
  );
  const [secondValue, setSecondValue] = React.useState<string>(
    Array.isArray(value.value) ? value.value[1] : value.value
  );

  useEffect(() => {
    onChange(
      value?.operator === ListUtils.FilterOperator.BETWEEN ? [firstValue, secondValue] : firstValue
    );
  }, [firstValue, secondValue]);

  return (
    <NumberFilterWrapper>
      <ThemedNumberInput
        className={className}
        autoFocus
        value={firstValue}
        onChange={setFirstValue}
      />
      {value?.operator === ListUtils.FilterOperator.BETWEEN && (
        <>
          <BetweenOperator darkMode={darkMode}>
            {t("design-system.operator.and", { defaultValue: "Et le" })}
          </BetweenOperator>
          <ThemedNumberInput autoFocus value={secondValue} onChange={setSecondValue} />
        </>
      )}
    </NumberFilterWrapper>
  );
};

export { NumberFilter };
