import { useCallback, useMemo, useState } from "react";
import { compact } from "lodash";
import {
  DSButton,
  DSCheckbox,
  DSFormGroupTextInput,
  Label as DSLabel,
  DSRadio,
  DSRadioGroup,
  DSTooltip,
  Flex,
  Select,
  Switch,
} from "@skillup/ui";

import type { ReviewType, TargetCategory } from "@skillup/espace-rh-bridge";

// import { useBuilderContext } from "../../../../BuilderContext";
import { Divided } from "../ChildWrappers";

import { TargetsPermissions } from "./TargetsPermissions";
import { BuilderFormInput } from "../../BuilderFormInput";
import type { Child } from "../../../../reducer/types";
import { useTargetCategories } from "services/interviews";

import styles from "./TargetsChild.module.scss";

type Targets = Extract<Child, { kind: "targets" }>;

type TargetsProps = {
  child: Targets;
  // [CSB] we do not allow to edit target scales for now
  onChange: (data: Targets /*, choicesForOrdinalTargets?: string[]*/) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

export function Targets({ child, onChange, indexes }: TargetsProps) {
  const { targetCategories } = useTargetCategories();
  // const choicesForOrdinalTargets = useMemo(() => {
  //   return structure.choicesForOrdinalTargets ?? [];
  // }, [structure]);

  const childDescription = useMemo(() => {
    switch (child.type) {
      case "current":
        return "Ce bloc permet aux participants de passer en revue (évaluation et/ou commentaire) les objectifs du collaborateur.";
      case "next":
        return "Ce bloc permet aux participants de fixer des objectifs pour le collaborateur.";
    }
  }, [child.type]);

  const [isCreateOptionsVisible, setIsCreateOptionVisible] = useState<boolean>(
    !child.disallow.manager.creation || !child.disallow.employee.creation
  );

  const updateStructure = useCallback(
    (data: Targets) => {
      onChange(data /*, choicesForOrdinalTargets*/);
    },
    [onChange /*, choicesForOrdinalTargets*/]
  );

  const handleChange = useCallback(
    (key: string, value: string) => {
      updateStructure({ ...child, [key]: value });
    },
    [child, updateStructure]
  );

  const updateReviewType = useCallback(
    (reviewType: ReviewType) => {
      updateStructure({ ...child, reviewType });
    },
    [child, updateStructure]
  );

  const handleToggleShowCreationCategories = useCallback(
    (status) => {
      if (!status) {
        setIsCreateOptionVisible(false);
        updateStructure({
          ...child,
          creationCategories: child.displayCategories,
          creationTargetCategories: child.displayTargetCategories,
          hidePeriod: undefined,
          hideWeight: undefined,
          disableGoalSelection: undefined,
          restrictScaleTo: undefined,
          disallow: {
            ...child.disallow,
            employee: {
              ...child.disallow?.employee,
              creation: true,
            },
            manager: {
              ...child.disallow?.manager,
              creation: true,
            },
          },
        });
      } else {
        setIsCreateOptionVisible(true);
        updateStructure({
          ...child,
          creationCategories: [],
          creationTargetCategories: [],
          restrictScaleTo: undefined,
          hidePeriod: false,
          hideWeight: false,
          disableGoalSelection: false,
        });
      }
    },
    [child, updateStructure]
  );

  const changeRestrictScaleTo = useCallback(
    (value?: "ordinal" | "numeric") => {
      updateStructure({
        ...child,
        restrictScaleTo: value,
      });
    },
    [child, updateStructure]
  );
  return (
    <Divided>
      <Flex column className={styles.TargetsChild}>
        <p className={styles.childDescription}>{childDescription}</p>
        {child.type === "current" && (
          <>
            <Flex column>
              {targetCategories.length > 0 && ( // [CSB] [TCY] replace with targetCategories
                <DSTooltip
                  // [CSB] changes disabled
                  label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
                  className={styles.targetCategoriesTooltip}
                >
                  <DSFormGroupTextInput label="Catégorie(s) des objectifs à passer en revue">
                    <Select
                      canSelectAll
                      multi
                      extraValuesLabel={(count) => `${count} catégories sélectionnées`}
                      allValuesLabel="Toutes les catégories"
                      selectAllLabel="Tout selectionner"
                      className={styles.targetCategoriesDropdown}
                      // [CSB] [TCY] restore with new categories
                      options={targetCategories.map((category) => ({
                        value: category,
                        label: category.label,
                      }))}
                      // [CSB] [TCY] restore with new categories
                      value={child.displayTargetCategories ?? []}
                      equalFn={(a, b) => a?.uuid === b?.uuid}
                      onChange={(values?: TargetCategory[]) => {
                        const compactedValues = compact(values);
                        updateStructure({
                          ...child,
                          displayTargetCategories: compactedValues,
                          displayCategories: compactedValues.map(({ label }) => label?.valueOf()),
                          creationTargetCategories: compactedValues,
                          creationCategories: compactedValues.map(({ label }) => label?.valueOf()),
                        });
                      }}
                      // [CSB] changes disabled
                      disabled
                    />
                  </DSFormGroupTextInput>
                </DSTooltip>
              )}
            </Flex>
            <DSTooltip
              // [CSB] permission changes disabled
              label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
            >
              <Switch
                className={styles.switch}
                active={isCreateOptionsVisible}
                onToggle={handleToggleShowCreationCategories}
                label="Permettre de créer des objectifs à passer en revue"
                // [CSB] disabled for now
                disabled
              />
            </DSTooltip>
          </>
        )}

        {(isCreateOptionsVisible || child.type === "next") && (
          <>
            <Flex column>
              {child.type === "next" &&
                targetCategories.length > 0 && ( // [CSB] [TCY] replace with targetCategories
                  <DSTooltip
                    // [CSB] changes disabled
                    label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
                    className={styles.targetCategoriesTooltip}
                  >
                    <DSFormGroupTextInput
                      label="Catégorie(s) dans lesquelles les objectifs pourront être définis"
                      required
                    >
                      <Select
                        canSelectAll
                        multi
                        // [CSB] [TCY] restore with new categories
                        options={targetCategories.map((category) => ({
                          value: category,
                          label: category.label,
                        }))}
                        extraValuesLabel={(count) => `${count} catégories sélectionnées`}
                        allValuesLabel="Toutes les catégories"
                        selectAllLabel="Tout selectionner"
                        // [CSB] [TCY] restore with new categories
                        value={child.creationTargetCategories ?? []}
                        equalFn={(a, b) => a?.uuid === b?.uuid}
                        onChange={(values?: TargetCategory[]) => {
                          const compactedValues = compact(values);
                          updateStructure({
                            ...child,
                            creationTargetCategories: compactedValues,
                            creationCategories: compactedValues.map(({ label }) =>
                              label?.valueOf()
                            ),
                            displayTargetCategories: compactedValues,
                            displayCategories: compactedValues.map(({ label }) => label?.valueOf()),
                          });
                        }}
                        // [CSB] changes disabled
                        disabled
                      />
                    </DSFormGroupTextInput>
                  </DSTooltip>
                )}
              {/* [CSB] category management disabled
              {child.type === "current" && child.displayTargetCategories?.length > 0 && (
                <p className={styles.childDescription}>
                  {
                    "Les nouveaux objectifs pourront être créés dans les catégories sélectionnées plus haut."
                  }
                </p>
              )}
              {child.type === "next" && (
                <DSButton
                  label={"Gérer les catégories d'objectif de cette trame"}
                  className={styles["button--underline"]}
                  onClick={() =>
                    window.open("/responsable/configuration-des-entretiens-et-des-objectifs")
                  }
                  buttonSize="S"
                  emphasis="Low"
                />
              )} */}
              <div className={styles["radioBlock"]}>
                <DSFormGroupTextInput label="Type d'objectifs pouvant être créés" required>
                  <DSRadioGroup name={"targets-type-to-evaluate" + child.uuid}>
                    <DSRadio
                      onChange={() => changeRestrictScaleTo()}
                      label={"Quantitatifs & Qualitatifs"}
                      checked={!child.restrictScaleTo}
                      // [CSB] changes disabled
                      disabled
                      tooltip={{
                        label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                      }}
                    />
                    <DSRadio
                      onChange={() => changeRestrictScaleTo("numeric")}
                      label={"Quantitatifs"}
                      checked={child.restrictScaleTo === "numeric"}
                      // [CSB] changes disabled
                      disabled
                      tooltip={{
                        label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                      }}
                    />
                    <DSRadio
                      onChange={() => changeRestrictScaleTo("ordinal")}
                      label={"Qualitatifs"}
                      checked={child.restrictScaleTo === "ordinal"}
                      // [CSB] changes disabled
                      disabled
                      tooltip={{
                        label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                      }}
                    />
                  </DSRadioGroup>
                </DSFormGroupTextInput>
              </div>
            </Flex>
            <Flex column>
              <DSLabel label="Propriétés affichées" />
              <DSCheckbox
                name={`${child.uuid}-hidePeriod`}
                label="Période"
                checked={!child.hidePeriod}
                onChange={(checked) => {
                  updateStructure({
                    ...child,
                    hidePeriod: !checked,
                  });
                }}
                // [CSB] changes disabled
                disabled
                tooltip={{
                  label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                }}
              />
              <DSCheckbox
                name={`${child.uuid}-hideWeight`}
                label="Pondération"
                checked={!child.hideWeight}
                onChange={(checked) => {
                  updateStructure({
                    ...child,
                    hideWeight: !checked,
                  });
                }}
                // [CSB] changes disabled
                disabled
                tooltip={{
                  label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                }}
              />
              {child.restrictScaleTo !== "ordinal" && (
                <DSCheckbox
                  helpText="Par défaut, l'évaluation des objectifs quantitatifs s'exprime en pourcentage. Cochez cette case si vous préférez que les participants à l’entretien indiquent un résultat à atteindre."
                  name={`${child.uuid}-disableGoalSelection`}
                  label="Résultat attendu"
                  checked={!child.disableGoalSelection}
                  onChange={(checked) => {
                    updateStructure({
                      ...child,
                      disableGoalSelection: !checked,
                    });
                  }}
                  // [CSB] changes disabled
                  disabled
                  tooltip={{
                    label: "Cet élément ne peut pas être modifié sur une campagne en cours.",
                  }}
                />
              )}
            </Flex>
            {child.targetNamePlaceholder !== undefined ? (
              <Flex column>
                <div className={styles.labelAndButton}>
                  <DSLabel label="Placeholder pour le champ « intitulé » de l'objectif" />
                  <DSButton
                    label={"Supprimer"}
                    className={styles["button--underline"]}
                    onClick={() => handleChange("targetNamePlaceholder", undefined)}
                    buttonSize="S"
                    emphasis="Low"
                  />
                </div>
                <BuilderFormInput
                  type="textarea"
                  value={child.targetNamePlaceholder}
                  onChange={(newValue) => handleChange("targetNamePlaceholder", newValue)}
                  debounceValue={300}
                  name={`targets-child-${child.uuid}-targetNamePlaceholder`}
                />
              </Flex>
            ) : (
              <DSButton
                label={"Ajouter un placeholder pour le champ « intitulé » de l'objectif"}
                className={styles["button--underline"]}
                onClick={() => handleChange("targetNamePlaceholder", "")}
                buttonSize="S"
                emphasis="Low"
              />
            )}
            {child.targetDescriptionPlaceholder !== undefined ? (
              <Flex column>
                <div className={styles.labelAndButton}>
                  <DSLabel label="Placeholder pour le champ « descriptif » de l'objectif" />
                  <DSButton
                    label={"Supprimer"}
                    className={styles["button--underline"]}
                    onClick={() => handleChange("targetDescriptionPlaceholder", undefined)}
                    buttonSize="S"
                    emphasis="Low"
                  />
                </div>
                <BuilderFormInput
                  type="textarea"
                  value={child.targetDescriptionPlaceholder}
                  onChange={(newValue) => handleChange("targetDescriptionPlaceholder", newValue)}
                  debounceValue={300}
                  name={`targets-child-${child.uuid}-targetDescriptionPlaceholder`}
                />
              </Flex>
            ) : (
              <DSButton
                label={"Ajouter un placeholder pour le champ « descriptif » de l'objectif"}
                className={styles["button--underline"]}
                onClick={() => handleChange("targetDescriptionPlaceholder", "")}
                buttonSize="S"
                emphasis="Low"
              />
            )}
          </>
        )}
      </Flex>
      <div>
        <TargetsPermissions
          child={child}
          currentShowCreation={isCreateOptionsVisible}
          updateReviewType={updateReviewType}
          // choicesForOrdinalTargets={choicesForOrdinalTargets}
          indexes={indexes}
        />
      </div>
    </Divided>
  );
}
