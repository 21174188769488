import { Flex } from "@skillup/ui";
import cx from "classnames";

import { TranslationType } from "hooks/useTranslation";

import shareImg from "assets/interview/templates/conclusion/share.png";
import shareImg2x from "assets/interview/templates/conclusion/share@2x.png";
import prepareImg from "assets/interview/templates/conclusion/prepare.png";
import prepareImg2x from "assets/interview/templates/conclusion/prepare@2x.png";
import signatureImg from "assets/interview/templates/conclusion/signature.png";
import signatureImg2x from "assets/interview/templates/conclusion/signature@2x.png";

import styles from "./Conclusion.module.scss";

const Conclusion = ({ t }: { t: TranslationType }) => {
  return (
    <div className={styles.contentContainer}>
      <Flex className={cx(styles.ImagesSteps)}>
        <Flex column center className={cx(styles.step, styles.first)}>
          <img
            className={styles.illustration}
            src={prepareImg}
            srcSet={`${prepareImg2x} 2x`}
            alt="prepare"
          />
          <span className={styles.stepLabel}>{t("interview.conclusion.step.prepare")}</span>
        </Flex>

        <Flex column center className={cx(styles.step, styles.middle)}>
          <img
            className={styles.illustration}
            src={shareImg}
            srcSet={`${shareImg2x} 2x`}
            alt="prepare"
          />
          <span className={styles.stepLabel}>{t("interview.conclusion.step.share")}</span>
        </Flex>

        <Flex column center className={cx(styles.step, styles.last)}>
          <img
            className={styles.illustration}
            src={signatureImg}
            srcSet={`${signatureImg2x} 2x`}
            alt="signature"
          />
          <span className={styles.stepLabel}>{t("interview.conclusion.step.signature")}</span>
        </Flex>

        <Flex className={styles.bar}>
          <Flex className={styles.progress} />
        </Flex>
      </Flex>
    </div>
  );
};

export { Conclusion };
