import { useMemo } from "react";
import { useLocalStorage } from "react-use";
import useTranslation from "hooks/useTranslation";

import { ToggleButtonGroup } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import type { ParsedSkillEvaluation } from "@skillup/gepp";

import { SkillHeader } from "./components/SkillHeader";
import { RadarList } from "./components/RadarList";
import { SkillListDetails } from "./UserEvaluation.styled";

interface Props {
  readonly skillEvaluation: ParsedSkillEvaluation;
}

const UserEvaluation = ({ skillEvaluation }: Props) => {
  const { t } = useTranslation();
  const [view, setView] = useLocalStorage<"radar" | "list">("skillView", "list");

  const { job, categoriesData, latestEvaluatedAt } = useMemo(() => {
    if (
      !skillEvaluation?.skillsByCategory.length ||
      !skillEvaluation?.skillsByCategory[0].skills.length
    ) {
      return {
        categoriesData: [],
        latestEvaluatedAt: new Date(),
      };
    }
    let latestEvaluatedAt = new Date(skillEvaluation.skillsByCategory[0].skills[0].evaluatedAt);
    const categoriesData = skillEvaluation.skillsByCategory.map((category) => ({
      name:
        category.name === "Sans catégorie"
          ? t("collab.skills.category", { defaultValue: "Compétences sans catégories" })
          : category.name,
      skills: category.skills.map((skill) => {
        const {
          name,
          level,
          levelName,
          levelDescription,
          expectedLevel,
          expectedLevelName,
          expectedLevelDescription,
          totalLevel,
          comment,
          evaluatedBy,
          evaluatedAt,
        } = skill;

        const evaluationDate = new Date(evaluatedAt);

        if (evaluationDate > latestEvaluatedAt) {
          latestEvaluatedAt = evaluationDate;
        }
        const levelRange = {
          min: totalLevel[0].level,
          max: totalLevel[totalLevel.length - 1].level,
        };

        return {
          name,
          level,
          levelName,
          levelDescription,
          expectedLevel,
          expectedLevelName,
          expectedLevelDescription,
          totalLevel,
          levelRange,
          comment,
          evaluatedBy,
          evaluatedAt: evaluationDate.toLocaleDateString("fr-FR"),
        };
      }),
    }));

    const job = skillEvaluation.job;

    return { job, latestEvaluatedAt, categoriesData };
  }, [skillEvaluation, t]);

  const evaluations = useMemo(() => {
    let totalSkills = 0;
    let skillsExpected = 0;
    let skillsOverExpected = 0;

    if (!categoriesData.length) return { totalSkills, skillsExpected, skillsOverExpected };

    for (const category of categoriesData) {
      for (const skill of category.skills) {
        if (skill.level > skill.expectedLevel) {
          skillsOverExpected++;
        }
        if (skill.level === skill.expectedLevel) {
          skillsExpected++;
        }

        if (skill.expectedLevel) {
          totalSkills++;
        }
      }
    }

    return { totalSkills, skillsExpected, skillsOverExpected };
  }, [categoriesData]);

  const tabs: { label: string; slug: string }[] = [
    {
      label: t("skillList.radarView", {
        defaultValue: "Vue radar",
      }),
      slug: "radar",
    },
    {
      label: t("skillList.listView", {
        defaultValue: "Vue liste",
      }),
      slug: "list",
    },
  ];

  return (
    <Flex width="100%" flexDirection="column">
      <SkillHeader
        totalEvaluations={evaluations.totalSkills}
        expectedEvaluations={evaluations.skillsExpected}
        overExpectedEvaluations={evaluations.skillsOverExpected}
        job={job as { uuid: string; name: string }}
        evaluatedAt={latestEvaluatedAt}
      />
      <Flex marginVertical="s" height="34px">
        <ToggleButtonGroup
          value={view}
          options={tabs.map((tab) => ({
            label: tab.label,
            value: tab.slug,
          }))}
          onChange={() => setView(view === "radar" ? "list" : "radar")}
        />
      </Flex>
      {view === "list" ? (
        <SkillListDetails categories={categoriesData} />
      ) : (
        <RadarList categories={categoriesData} />
      )}
    </Flex>
  );
};

export default UserEvaluation;
