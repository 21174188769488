import { CampaignIsArchivable, CampaignsRoutes } from "@skillup/espace-rh-bridge";
import { InterviewWithStates } from "@skillup/types";
import { DSDropdownItem } from "@skillup/ui";
import InteractiveButton from "components/InteractiveButton";
import { TFunction } from "hooks/useTranslation";
import { isEmpty, isNil } from "lodash";
import { useToasts } from "react-toast-notifications";
import Colors from "uiAssets/Colors";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import User from "utils/User";
import styles from "./CampaignActions.module.scss";
import { Campaign } from "./modals/useCampaignModals";
import { EditionContext } from "./modals/utils";

interface Props {
  readonly campaign?: Campaign;
  readonly interviews?: Array<InterviewWithStates>;
  readonly onDelete: () => void;
  readonly resync: () => Promise<void>;
  readonly setActionsState?: (stateChange: EditionContext) => void;
  readonly onClick?: () => void;
  readonly t: TFunction;
}

const CampaignActions = ({ campaign, interviews, onDelete, setActionsState, t }: Props) => {
  const { addToast } = useToasts();
  const cantArchiveMessage = t("campaigns.cantArchiveMessage", {
    defaultValue:
      "Vous ne pouvez pas clôturer une campagne tant que la date limite de signature n’est pas passée",
  });
  const cantArchiveMessageInvisible = t("campaigns.cantArchiveMessageInvisible", {
    defaultValue:
      "Vous ne pouvez pas clôturer cette campagne car certains entretiens auxquels vous n’avez pas accès n’ont pas encore atteint la date limite de signature.",
  });

  const deleteCampaign = async (): Promise<void> => {
    try {
      await buildRequest<CampaignsRoutes.Delete>({
        method: "DELETE",
        path: `/campaigns/{campaignUuid}`,
        params: { campaignUuid: campaign.uuid },
      })();
      Acta.dispatchEvent("closeModal");

      addToast(
        t("campaign.deleteCampaignSuccess", {
          defaultValue: "La campagne « {{campaignTitle}} » a bien été supprimée.",
          campaignTitle: campaign.titleForHR,
        }),
        {
          appearance: "success",
        }
      );
      onDelete();
    } catch (error) {
      Acta.dispatchEvent("closeModal");

      addToast(
        t("campaign.deleteCampaignError", {
          defaultValue: "Impossible de supprimer la campagne « {{campaignTitle}} ».",
          campaignTitle: campaign.titleForHR,
        }),
        {
          appearance: "error",
        }
      );

      console.error(error);
    }
  };

  let campaignActions = [];

  // open to client
  campaignActions.push(
    <DSDropdownItem
      key="campaign-update"
      data-target="campaign-update"
      onClick={() => {
        setActionsState(EditionContext.MODIFY);
      }}
      label={t("campaign.campaignUpdate", {
        defaultValue: "Modifier la campagne",
      })}
    />
  );

  campaignActions.push(
    <DSDropdownItem
      key="campaign-parameter"
      data-target="campaign-parameters"
      onClick={() => {
        setActionsState(EditionContext.PARAMETER);
      }}
      label={t("campaign.campaignParameter", {
        defaultValue: "Paramètres de la campagne",
      })}
    />
  );

  if (campaign.type === "legacy") {
    campaignActions.push(
      <DSDropdownItem
        key="campaign-mailing-update"
        data-target="campaign-mailing-update"
        onClick={() => {
          setActionsState(EditionContext.NOTIFICATION);
        }}
        label={t("campaign.campaignMailingUpdate", {
          defaultValue: "Paramètres notifications",
        })}
      />
    );
  }

  if (User.isSkillupAdmin()) {
    if (!campaign.archivedAt) {
      campaignActions.push(
        <DSDropdownItem
          key="campaign-template-update"
          data-target="campaign-template-update"
          onClick={() => {
            setActionsState(EditionContext.UPDATE_STRUCTURE);
          }}
          label={t("campaign.campaignTemplateUpdate", {
            defaultValue: "[OPS] Corriger la trame de la campagne",
          })}
        />
      );
    }
    campaignActions.push(
      <DSDropdownItem
        key="campaign-delete"
        onClick={() =>
          Acta.setState("modalDisplayed", {
            content: (
              <div className={styles.confirmationModal}>
                <p
                  className={styles.modalText}
                  dangerouslySetInnerHTML={{
                    __html: t("campaign.delete.description", {
                      defaultValue: `Êtes-vous sûr(e) de vouloir supprimer définitivement la campagne <strong>{{titleForHR}}</strong> ? Attention cette action est irréversible.`,
                      titleForHR: campaign.titleForHR,
                    }),
                  }}
                />
                <div className={styles.buttonWrapper}>
                  <InteractiveButton
                    label={t("previousStep", {
                      defaultValue: "Précédent",
                    })}
                    onClick={() => Acta.dispatchEvent("closeModal")}
                    size="small"
                    background="#fff"
                    color={Colors.blue}
                  />
                  <InteractiveButton
                    label={t("campaign.delete", {
                      defaultValue: "Supprimer",
                    })}
                    onClick={deleteCampaign}
                    size="small"
                  />
                </div>
              </div>
            ),
            size: "small",
            title: t("campaign.deleteForever", {
              defaultValue: "Supprimer définitivement la campagne",
            }),
          })
        }
        label={t("campaign.delete.ops", {
          defaultValue: "[OPS] Supprimer la campagne",
        })}
      />
    );
  }

  if (!isNil(interviews) && !isEmpty(interviews) && isNil(campaign.archivedAt)) {
    campaignActions.push(
      <DSDropdownItem
        key="campaign-archive"
        onClick={() => setActionsState(EditionContext.CLOSE)}
        label={t("campaign.campaignArchive", {
          defaultValue: "Clôturer la campagne",
        })}
        disabled={campaign.isArchivable !== CampaignIsArchivable.YES}
        tooltipLabel={
          campaign.isArchivable === CampaignIsArchivable.NO
            ? cantArchiveMessage
            : campaign.isArchivable ===
                CampaignIsArchivable.NOT_VISIBLE_INTERVIEW_ARE_NOT_ARCHIVABLE
              ? cantArchiveMessageInvisible
              : null
        }
        tooltipDirection={campaign.isArchivable !== CampaignIsArchivable.YES ? "bottom" : null}
      />
    );
  }

  return campaignActions;
};

export default CampaignActions;
