import { InterviewWithStates, IRoutes } from "@skillup/types";
import Button from "components/Button";
import FindUser from "components/FindUser";
import { IUserIdentity } from "containers/Supervisor/routes/Projects/Components/UserSearchInput";
import useTranslation from "hooks/useTranslation";
import { isUndefined } from "lodash";
import { useState } from "react";
import Acta from "utils/Acta";
import styles from "./AddUserModal.module.scss";
import SetInterviewModal from "./SetInterviewModal";

interface IProps {
  readonly campaignUuid: string;
  readonly campaignType: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
  readonly interviews: InterviewWithStates[];
  readonly userAreas?: Array<string>;
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const AddUserModal = ({
  campaignUuid,
  interviews,
  userAreas,
  resync,
  campaignType,
}: IProps): JSX.Element => {
  const [employee, setEmployee] = useState<IUserIdentity>();
  const { t } = useTranslation();

  return (
    <div className={styles.AddUserModal}>
      <FindUser
        user={employee}
        onSelect={setEmployee}
        filter={(user) =>
          campaignType === "adHoc"
            ? true
            : !interviews.map(({ employee }) => employee.email).includes(user.email)
        }
        userAreas={userAreas}
        showRightIcon
        t={t}
      />
      <footer className={styles.actions} data-target="add-user">
        {employee && (
          <Button
            onClick={() => setEmployee(undefined)}
            disabled={isUndefined(employee)}
            className={styles.backButton}
          >
            {t("interview.actions.goback", {
              defaultValue: "Retour",
            })}
          </Button>
        )}
        <Button
          onClick={() =>
            Acta.setState("modalDisplayed", {
              content: (
                <SetInterviewModal
                  campaignUuid={campaignUuid}
                  employee={employee as IUserIdentity}
                  resync={resync}
                  interviews={interviews}
                  // interviews props is necessary to call this modal back as a previous step
                />
              ),
              size: "small",
              title: t("campaign.addUuser.title", { defaultValue: "Planifier un entretien" }),
              showOverflow: true,
            })
          }
          id="add-employee"
          disabled={isUndefined(employee)}
        >
          {t("interview.actions.next", {
            defaultValue: "Suivant",
          })}
        </Button>
      </footer>
    </div>
  );
};

export default AddUserModal;
