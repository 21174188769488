import { FieldsRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

export const getReviewCriteriaListCacheKey = () => ["core-hr", "review-criteria", "list"];
export const getReviewCriteriaList = async () => {
  const result = await buildRequest<FieldsRoutes.GetList>({
    method: "GET",
    path: `/fields`,
    target: "CORE_HR",
  })();

  return result.fields;
};
