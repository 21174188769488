import { useState, useEffect } from "react";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";
import type { InterviewUser } from "@skillup/types";
import { isUndefined, uniqBy } from "lodash";

import {
  QuestionsStep,
  UsersStep,
  DatesStep,
  FinalStep,
  MailingSettings,
} from "./components/Steps";
import { InterviewPairs } from "./models/InterviewPair";
import { Step } from "./models/Step";
import { createNewCampaign as createNewCampaignRequest } from "./requests/CreateCampain";
import CreateFormInterviewWrapper from "./CreateInterviewFormWrapper";
import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";
import { getInterviewsEmployee, getInterviewsManager } from "./helper";
import useTranslation from "hooks/useTranslation";
import User from "utils/User";

import OptionsStep from "./components/Steps/OptionsStep";
import {
  CampaignMailingSettings,
  InterviewPageChild,
  Targets,
  TemplateTargetCategory,
} from "@skillup/espace-rh-bridge";
import { getStructuresTargetsChildren } from "./requests/getStructuresTargetsChildren";
import { getTemplateTargetsChildren } from "./requests/getTemplateTargetsChildren";

interface IState {
  titleForHR: string;
  titleForAttendees: string;
  interviews: InterviewPairs[];
  template?: { title: string; uuid: string };
  managerOnly?: boolean;
  start?: number;
  duration?: number;
  ongoingStartDate?: number;
  hideFromEmployeeUntil?: { managerState: InterviewUser["state"] };
  mailingSettings: CampaignMailingSettings;
  confidential: boolean;
}

const CreateNewInterviewCampaign = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    titleForHR: "",
    titleForAttendees: "",
    interviews: [],
    mailingSettings: {
      notificationStart: true,
      notificationCreated: true,
      notificationManagerChange: true,
      notificationShared: true,
      notificationSigned: true,
      notificationReopen: true,
      reminderMinus14: true,
      reminderMinus7: true,
      reminderMinus1: true,
      reminderPlus1: true,
    },
    confidential: false,
  });

  const {
    titleForHR,
    titleForAttendees,
    template,
    interviews,
    managerOnly,
    start,
    duration,
    hideFromEmployeeUntil,
    mailingSettings,
    confidential,
  } = state;

  const [canCreateCampaign, setCanCreateCampaign] = useState(false);

  const [displayTargetAlert, setDisplayTargetAlert] = useState(false);

  useEffect(() => {
    const callAnalysis = async () => {
      if (template && User.isDemoUser() === false) {
        // https://skillupco.atlassian.net/browse/SKL-2709
        const res = await analyseTemplateChildren(template.uuid);
        setDisplayTargetAlert(res);
      }
    };
    callAnalysis();
  }, [template, setDisplayTargetAlert]);

  const steps: Step[] = [
    {
      label: t("interviews.campaigncreation.step.modelandname", {
        defaultValue: "Trame et nom",
      }),
      id: "modelAndName",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <QuestionsStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          setQuestionsTemplate={(template) => setState({ template })}
          setCampaignTitle={(campaignName, scope: "HR" | "attendees") => {
            if (scope === "HR") {
              setState({ titleForHR: campaignName });
            } else if (scope === "attendees") {
              setState({ titleForAttendees: campaignName });
            }
          }}
          displayTargetAlert={displayTargetAlert}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.dates", {
        defaultValue: "Dates",
      }),
      id: "dates",
      disabled: isUndefined(start) || isUndefined(duration),
      component: (
        <DatesStep
          start={start}
          setStart={(start) => setState({ start })}
          duration={duration}
          setDuration={(duration) => setState({ duration })}
          type="legacy"
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.workflowandconfidentiality", {
        defaultValue: "Workflow et confidentialité",
      }),
      id: "workflowAndConfidentiality",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(start) &&
        !isUndefined(duration)
      ),
      component: (
        <OptionsStep
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          setHideFromEmployeeUntil={(hideFromEmployeeUntil) => setState({ hideFromEmployeeUntil })}
          managerOnly={managerOnly}
          setManagerOnly={(managerOnly) => setState({ managerOnly })}
          confidential={confidential}
          setConfidentiality={(confidential) => setState({ confidential })}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.attendees", {
        defaultValue: "Participants",
      }),
      id: "attendees",
      disabled: !(
        interviews.length > 0 &&
        getInterviewsEmployee(interviews).every((employee) => !employee.errors?.length) &&
        getInterviewsManager(interviews).every((manager) => !manager.errors?.includes("not-found"))
      ),
      component: (
        <UsersListProvider
          content={{
            selectedPairs: interviews,
          }}
        >
          <UsersStep
            interviews={interviews}
            setInterviews={(interviews) => setState({ interviews })}
            campaignType="legacy"
          />
        </UsersListProvider>
      ),
    },
    {
      label: t("interviews.campaigncreation.step.notifications", {
        defaultValue: "Notifications",
      }),
      id: "notifications",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        interviews.length > 0 &&
        !isUndefined(start) &&
        !isUndefined(duration)
      ),
      component: (
        <MailingSettings
          mailingSettings={mailingSettings}
          updateOne={(key, enabled) =>
            setState({ mailingSettings: { ...mailingSettings, [key]: enabled } })
          }
          updateAllNotif={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("notification"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          updateAllRemind={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("remind"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          warning={false}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.summary", {
        defaultValue: "Synthèse",
      }),
      id: "summary",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        interviews.length > 0 &&
        !isUndefined(start) &&
        !isUndefined(duration)
      ),
      component: (
        <FinalStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          interviews={interviews}
          type="legacy"
          start={start}
          duration={duration}
          setCanCreateCampaign={setCanCreateCampaign}
          managerOnly={managerOnly}
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          confidential={confidential}
          displayTargetAlert={displayTargetAlert}
        />
      ),
    },
  ];

  const createNewCampaign = async (toggleLoading: (nextValue?: boolean) => void) => {
    const timezoneOffset = new Date(start).getTimezoneOffset() * 60 * 1000;

    const campaignUuid = await createNewCampaignRequest({
      interviews,
      toggleLoading,
      t,
      confidential,
      data: {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "legacy",
        duration,
        preparationTime: duration - 1,
        startDate: start - timezoneOffset,
        hideFromEmployeeUntil,
        mailingSettings,
      },
    });

    if (campaignUuid) history.push(`/responsable/campagne/${campaignUuid}`);
  };

  return (
    <CreateFormInterviewWrapper
      steps={steps}
      title={t("interviews.campaigncreation.title", { defaultValue: "Créer une campagne" })}
      sendButtonLabel={t("interviews.campaigncreation.button", {
        defaultValue: "Créer la campagne",
      })}
      createNewCampaign={createNewCampaign}
      canCreateCampaign={canCreateCampaign}
    />
  );
};

export default CreateNewInterviewCampaign;

const analyseTemplateChildren = async (templateUuid: string) => {
  const templateTargetsChildren = await getTemplateTargetsChildren({
    templateUuid,
  });
  if (templateTargetsChildren.length === 0) return false;

  const structuresTargetsChildren = await getStructuresTargetsChildren();
  if (structuresTargetsChildren === undefined) return false;

  // [TCY-1] remove legacy categories analysis
  const commonLegacyCategories = performLegacyCategoriesAnalysis(
    templateTargetsChildren,
    structuresTargetsChildren
  );

  const commonTargetCategories = performTargetCategoriesAnalysis(
    templateTargetsChildren,
    structuresTargetsChildren
  );

  return commonLegacyCategories || commonTargetCategories;
};

function performLegacyCategoriesAnalysis(
  templateTargetsChildren: Targets[],
  structuresTargetsChildren: Record<string, InterviewPageChild[]>
) {
  const selectedTemplateCategories = getTargetsChildrenLegacyCategories(templateTargetsChildren);
  const structuresCategories = Object.values(structuresTargetsChildren).reduce(
    (acc: string[], targetsChildren: Targets[]) =>
      Array.from(new Set([...acc, ...getTargetsChildrenLegacyCategories(targetsChildren)])),
    []
  );

  if (
    // if no category is selected, but there exists some targets children, then the "default" category is selected
    (structuresCategories.find((category: string) => category === "default") ||
      structuresCategories.length === 0) &&
    (selectedTemplateCategories.find((category: string) => category === "default") ||
      selectedTemplateCategories.length === 0)
  ) {
    return true;
  }

  return (
    structuresCategories.filter((category: string) => selectedTemplateCategories.includes(category))
      .length !== 0
  );
}

function performTargetCategoriesAnalysis(
  templateTargetsChildren: Targets[],
  structuresTargetsChildren: Record<string, InterviewPageChild[]>
) {
  const selectedTemplateCategories = getTargetsChildrenTargetCategories(templateTargetsChildren);
  const structuresCategories = Object.values(structuresTargetsChildren).reduce(
    (acc: string[], targetsChildren: Targets[]) =>
      Array.from(new Set([...acc, ...getTargetsChildrenTargetCategories(targetsChildren)])),
    []
  );

  if (
    // if no category is selected, but there exists some targets children, then the "default" category is selected
    (structuresCategories.find((cat: TemplateTargetCategory) => cat.label === "default") ||
      structuresCategories.length === 0) &&
    (selectedTemplateCategories.find((cat: TemplateTargetCategory) => cat.label === "default") ||
      selectedTemplateCategories.length === 0)
  ) {
    return true;
  }

  return !!structuresCategories.find((category: { uuid: string; label: string }) =>
    selectedTemplateCategories.some((selectedCategory) => selectedCategory.uuid === category.uuid)
  );
}

function getTargetsChildrenLegacyCategories(targetsChildren: Targets[]) {
  const legacyCategories = targetsChildren.reduce(
    (acc: string[], child: Targets) =>
      Array.from(
        new Set([...acc, ...(child.displayCategories ?? []), ...(child.creationCategories ?? [])])
      ),
    []
  );
  return legacyCategories;
}

function getTargetsChildrenTargetCategories(targetsChildren: Targets[]) {
  return targetsChildren.reduce((acc: TemplateTargetCategory[], child: Targets) => {
    const targetCategories = (child.creationTargetCategories ?? []).concat(
      child.displayTargetCategories ?? []
    );
    return uniqBy(acc.concat(targetCategories), "uuid");
  }, []);
}
