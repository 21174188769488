import {
  useRef,
  useMemo,
  Dispatch,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  SetStateAction,
} from "react";

import { isEqual, cloneDeep } from "lodash";

import { GridSortModel } from "@mui/x-data-grid-pro";

import { FilterProp } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";

import { trpc } from "utils/trpc";

import getUsers, { Collaborator } from "../../api/getUsers";
import { useCollaboratorsGridFilters } from "./utils/useCollaboratorsGridFilters";

type PaginationProperties = {
  page: number;
  pageSize: number;
};

type DirectionUp = Uppercase<GridSortModel[0]["sort"]>;

type GetUsers = Awaited<ReturnType<typeof getUsers>>;

type ProcessedFilterValues = {
  [key: string]: any;
  skills?: Array<ListUtils.FilterValue>;
};

type FilterCategory = {
  id: string;
  label: string;
  filters: string[];
};

type CollaboratorsContextType = {
  refetch: () => Promise<void>;
  users: {
    maxLevel: number;
    isLoading: boolean;
    collabs: Array<Collaborator>;
  };
  sortHook: {
    sortModel: GridSortModel;
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
  };
  paginationHook: {
    total: number;
    actualPaginationModel: PaginationProperties;
    paginationModel: ListUtils.PaginationProperties;
    setPaginationModel: Dispatch<
      SetStateAction<{
        page: number;
        pageSize: number;
      }>
    >;
  };
  filtersHook: {
    filterValues: ProcessedFilterValues;
    conf: ListUtils.FilterConfigurationMap;
    filterCategories: Array<FilterCategory>;
    filters: FilterProp<ListUtils.FilterConfigurationMap>;
    setFilterValues: Dispatch<SetStateAction<ListUtils.FilterConfigurationMap>>;
  };
  getSkills: () => {
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.skills.getSkills.useQuery>["error"];
    data:
      | undefined
      | Array<{
          name: string;
          uuid: string;
          levels: Array<{ uuid: string; name: string; level: number }>;
        }>;
  };
};

const CollaboratorsContext = createContext<CollaboratorsContextType>(null);

const useCollaboratorsContext = () => {
  const context = useContext(CollaboratorsContext);
  if (!context) {
    throw new Error("useCollaboratorsContext must be used within an CollaboratorsContext");
  }
  return context;
};

interface CollaboratorsProviderProps {
  children: React.ReactNode;
}

const CollaboratorsProvider = ({ children }: CollaboratorsProviderProps) => {
  const [conf, filters, filterValues, setFilterValues, filterCategories] =
    useCollaboratorsGridFilters();
  const [paginationModel, setPaginationModel] = useState<PaginationProperties>({
    page: 0,
    pageSize: 20,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(undefined);
  const [users, setUsers] = useState<GetUsers>(null);
  const [loading, setLoading] = useState(false);

  const getSkills = useCallback(() => {
    const {
      data: skillListData,
      error: skillListError,
      status: skillListStatus,
    } = trpc.skills.getAllSkills.useQuery();

  const skillsRevamp = skillListData
    ?.filter((skill) => !skill.isArchived)
    .map((skill) => ({
      uuid: skill.uuid,
      levels: skill.evaluationScale.levels.map((level) => ({
        uuid: level.uuid,
        level: level.level,
        name: level.name,
      })),
      name: skill.name,
    }));

    const skills = {
      data: skillsRevamp,
      error: skillListError,
      status: skillListStatus,
    };
    return skills;
  }, []);

  const getUsersQuery = async () => {
    setLoading(true);
    const { jobName, skills = [], ...employees } = filterValues;
    const users = await getUsers({
      filters: {
        employees,
        jobs: jobName,
        skills: Array.isArray(skills) ? skills : [skills],
      },
      pagination: paginationModel,
      sortBy:
        sortModel && sortModel.length > 0
          ? {
              direction: sortModel[0].sort.toUpperCase() as DirectionUp,
              property: sortModel[0].field,
            }
          : undefined,
    });
    setUsers(users);
    setLoading(false);
  };

  const oldValues = useRef(undefined);

  useEffect(() => {
    if (oldValues.current && isEqual(oldValues.current, filterValues)) return;

    getUsersQuery();

    oldValues.current = cloneDeep(filterValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, paginationModel, sortModel]);

  const totalRows = useMemo(() => {
    return users?.pagination.total || 0;
  }, [users?.pagination]);

  return (
    <CollaboratorsContext.Provider
      value={{
        filtersHook: {
          conf,
          filterCategories,
          filters,
          filterValues,
          setFilterValues,
        },
        getSkills,
        paginationHook: {
          actualPaginationModel: paginationModel,
          paginationModel: paginationModel,
          setPaginationModel,
          total: totalRows,
        },
        refetch: getUsersQuery,
        sortHook: {
          setSortModel,
          sortModel,
        },
        users: {
          collabs: users?.employeesWithHierarchy,
          isLoading: loading,
          maxLevel: users?.maxLevel,
        },
      }}
    >
      {children}
    </CollaboratorsContext.Provider>
  );
};

export { CollaboratorsContext, CollaboratorsProvider, useCollaboratorsContext };
