import { useRecoilState } from "recoil";

import { Select } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

import { TrackingFilterKeys, trackingsStatusFilter } from "../../state/filters";
import styles from "../Trackings.module.scss";

const StatusFilter = ({ t }: { t: TranslationType }) => {
  const [status, setStatus] = useRecoilState(trackingsStatusFilter);

  function handleChange(newStatus?: TrackingFilterKeys) {
    setStatus(newStatus);
  }

  return (
    <Select<TrackingFilterKeys>
      className={styles.statusFilter}
      value={status}
      aria-label="filter-select"
      onChange={handleChange}
      isSearchable={false}
      placeholder={t("trainings.view.regulatory_collaborator_tracking.filter.placeholder", {
        defaultValue: "Statut...",
      })}
      options={[
        {
          label: t("trainings.view.regulatory_collaborator_tracking.filter.all", {
            defaultValue: "Toutes",
          }),
          value: "all",
        },
        {
          label: t("trainings.view.regulatory_collaborator_tracking.filter.active_lines", {
            defaultValue: "Toutes",
          }),
          value: "active",
        },
        {
          label: t("trainings.view.regulatory_collaborator_tracking.filter.archived_lines", {
            defaultValue: "Lignes archivées",
          }),
          value: "archived",
        },
      ]}
    />
  );
};

export default StatusFilter;
