import { useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";
import { forEach } from "lodash";

import { DSAlert, DSAlertDisplay, DSAlertType, DSModal, useMediaQueries } from "@skillup/ui";

import { Collaborator, UpdateCollaboratorPayload } from "../../api";
import { IRadioOption } from "components/RadioButtons/RadioButtons";
import useSubCompanies from "hooks/useCompany";

import styles from "./EditUserModal.module.scss";
import UserForm, {
  BuildPayloadFromForm,
  IForm,
  LoadCollabToForm,
  ParseChildrenCompaniesFromOrganization,
  ParseUserAreasFromCredentials,
} from "../UserForm";
import Acta from "utils/Acta";
import { FieldElement } from "../UserForm/config";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { TranslationType } from "hooks/useTranslation";

const customValues: Array<FieldElement> = [
  { name: "Carte d'identité du collaborateur", type: "Title" },
  { name: "Prénom", field: "firstName", type: "TextInput", required: true },
  { name: "Nom", field: "lastName", type: "TextInput", required: true },
  { name: "E-mail", field: "email", type: "TextInput", required: true },
  { name: "Genre", field: "gender", type: "TextInput" },
  { name: "Téléphone", field: "phone", type: "TextInput" },
  { name: "Matricule", field: "registrationNumber", type: "TextInput" },
  { name: "Date de naissance", field: "birthDate", type: "Date" },
  { name: "Date d'entrée", field: "joinDate", type: "Date" },
  { name: "Date de fin", field: "expectedDepartureDate", type: "Date" },
  { name: "Âge", field: "age", type: "NumberInput" },
  { name: "Ancienneté", field: "seniorityDate", type: "Date" },
  { name: "Organisation", type: "Title" },
  { name: "Code rôle", field: "roleCode", type: "TextInput" },
  { name: "Code département", field: "divisionCode", type: "TextInput" },
  { name: "Code direction", field: "branchCode", type: "TextInput" },
  { name: "Code service", field: "serviceCode", type: "TextInput" },
  { name: "Sous-département", field: "subDivision", type: "TextInput" },
  { name: "Sous-direction", field: "subBranch", type: "TextInput" },
  { name: "Sous-service", field: "subService", type: "TextInput" },
  { name: "Code sous-département", field: "subDivisionCode", type: "TextInput" },
  { name: "Code sous-direction", field: "subBranchCode", type: "TextInput" },
  { name: "Code sous-service", field: "subServiceCode", type: "TextInput" },
  { name: "Périmètres", field: "areas", type: "TextDropdown", required: true },
  { name: "Sous-entreprise", field: "subCompanies", type: "TextDropdown" },
  {
    name: "A un gestionnaire de réservation individuel",
    field: "hasBookingManager",
    type: "Radio",
    canUncheck: false,
  },
  {
    name: "Gestionnaire de réservation",
    field: "bookingManager",
    type: "UserSearch",
    display: (form) => {
      return form?.["hasBookingManager"]?.value ?? false;
    },
  },
  { name: "Groupes", field: "groups", type: "TextDropdown" },
  { name: "Société", field: "company", type: "TextInput" },
  { name: "Département", field: "division", type: "TextInput" },
  { name: "Direction", field: "branch", type: "TextInput" },
  { name: "Service", field: "service", type: "TextInput" },
  { name: "Site", field: "site", type: "TextInput" },
  { name: "RRH", field: "managerRRH", type: "TextInput" },
  { name: "HRBP", field: "managerHRBP", type: "TextInput" },
  { name: "Poste", type: "Title" },
  { name: "Rôle", field: "role", type: "TextInput" },
  { name: "Rôle à l'entrée", field: "joinRole", type: "TextInput" },
  { name: "Date d'entrée sur le rôle actuel", field: "roleDate", type: "Date" },
  { name: "Contrat", field: "contract", type: "TextInput" },
  { name: "Catégorie", field: "contractCategory", type: "TextInput" },
  { name: "Code d'imputation budgétaire", field: "invoicingBudgetaryCode", type: "TextInput" },
  { name: "Hiérarchie", type: "Title" },
  { name: "Manager N+1", field: "manager0Lvl0", type: "UserSearch" },
  { name: "Manager N+2", field: "manager0Lvl1", type: "UserSearch" },
];

type ModalState = {
  selection: IRadioOption | null;
  isLoading: boolean;
};

const initialState: ModalState = {
  selection: null as IRadioOption | null,
  isLoading: false,
};
export interface Props {
  isOpen: boolean;
  closeModal: () => void;
  userUuid: string;
  userData: Collaborator;
  updateRequest: (uuid: string, u: UpdateCollaboratorPayload) => void;
  t: TranslationType;
}

export default ({ isOpen, closeModal, userUuid, userData, updateRequest, t }: Props) => {
  const [{ isLoading }, setState] = useSetState<ModalState>(initialState);
  const [form, setFormState] = useSetState<IForm>({});
  const [maxLevel, setMaxLevel] = useState<number>(0);
  const [newObserverFields, setNewObserverFields] = useState<number>(0);
  const { addToast } = useToasts();
  const { isMobile } = useMediaQueries();

  const {
    activeCompany: {
      uuid: companyUuid,
      groups: companyGroups,
      accessControlList: { userAreas: possibleUserAreas },
    },
  } = Acta.getState("userData");

  const { company } = useSubCompanies({ companyUuid });

  const userAreas = useMemo(() => {
    return ParseUserAreasFromCredentials(possibleUserAreas);
  }, [possibleUserAreas]);

  const subCompanies = useMemo(() => {
    return ParseChildrenCompaniesFromOrganization(company);
  }, [company]);
  useEffect(() => {
    if (!isOpen) {
      setState(initialState);
    }

    let localMaxLevel = 0;
    forEach(userData.hierarchy?.observers, (observer) => {
      if (observer.level > localMaxLevel) {
        localMaxLevel = observer.level;
      }
    });
    setMaxLevel(localMaxLevel);

    const formData = LoadCollabToForm(userData, maxLevel, customValues);
    setFormState(formData);
  }, [userData, company, setFormState, maxLevel, setState, isOpen]);

  const send = async (): Promise<void> => {
    if (
      form.joinDate?.value &&
      form.expectedDepartureDate?.value &&
      moment(form.joinDate.value).isAfter(form.expectedDepartureDate.value)
    ) {
      addToast("La date de fin ne peut pas être antérieure à la date d'entrée", {
        appearance: "error",
      });
    } else {
      const payload = BuildPayloadFromForm(form, maxLevel + newObserverFields, customValues);
      updateRequest(userUuid, payload);
      closeModal();
    }
  };

  return (
    <DSModal isOpen={isOpen} className={styles.Modal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={`${userData.firstName} ${userData.lastName}`} />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.ModalContent}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {t("collaborators.modal.addUser.warning", {
              defaultValue: `Les modifications seront écrasées au prochain import de base utilisateurs. Assurez-vous
            donc de bien appliquer les mêmes modifications dans votre logiciel source.`,
            })}
          </DSAlert>

          <form className={styles.modalForm}>
            <UserForm
              form={form}
              userAreas={userAreas}
              companyGroups={companyGroups.map((g) => ({ label: g.name, value: g.uuid }))}
              setFormState={setFormState}
              subCompanies={subCompanies}
              maxLevel={maxLevel}
              newObserverFields={newObserverFields}
              setNewObserverFields={setNewObserverFields}
              customFields={customValues}
              t={t}
            />
          </form>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("collaborators.modal.updateUser.cancel", {
            defaultValue: `Annuler`,
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={isLoading}
          onClick={() => {
            send();
          }}
          label={
            isMobile
              ? t("collaborators.modal.addUser.saveMobile", {
                  defaultValue: `Enregistrer`,
                })
              : t("collaborators.modal.addUser.saveMobile", {
                  defaultValue: `Enregistrer les modifications`,
                })
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
};
