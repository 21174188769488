/**
 * Build the human redable wording for each possible
 * status for the one or multiple lines selected
 */

/* Possible statuses in TPrimaryState */

import { IRelationCall } from "./fetchItems";
import { statusForHR, statusLabelsForHR as statusLabels } from "constants/status";
import { IUser, TPrimaryState } from "@skillup/types";

export interface IPartialIUser extends Partial<IUser> {
  manager?: IUser;
  pendingDemands?: number;
  validatedDemands?: number;
}

export interface ILine {
  type: "inter" | "intra";
  session?: {
    startDate: string;
    city: string;
    address: string;
  };
  user: IPartialIUser;
  primaryState: TPrimaryState;
}

export default function getStatus({
  data,
}: {
  data: Array<IRelationCall>;
}): Array<{ key: string; options: any }> {
  if (!data) return [];

  /* Count how many unique primary state we have */
  let statusLinesCount = 0;
  const statusLines = {};
  for (const item of data) {
    if (!statusLines[item.primaryState]) {
      statusLinesCount++;
      statusLines[item.primaryState] = 1;
    } else {
      statusLines[item.primaryState]++;
    }
  }

  /* Build the result */
  const statusesResult: Array<{ key: string; options: any }> = [];

  const statusLinesCounterAsArray = Object.entries(statusLines);

  if (statusLinesCount === 1 && statusLinesCounterAsArray[0][1] === 1) {
    const key = statusLinesCounterAsArray[0][0];
    const label = `${(statusLabels || {})[key] || ""}`;

    return [{ key, options: { defaultValue: label } }];
  }

  const statuses = statusForHR();
  for (const statusLine of Object.entries(statusLines)) {
    const status = statusLine[0];
    const statusLabelCount = statusLine[1];
    const statusAsTxt = statuses[status] || "";

    statusesResult.push({
      key: status,
      options: {
        defaultValue: `{{ count }}} demandes ${statusAsTxt.toLowerCase()}`,
        count: statusLabelCount,
      },
    });
  }

  return statusesResult;
}
