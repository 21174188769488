import { useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useToasts } from "react-toast-notifications";
import isEmpty from "lodash/isEmpty";

import { DSButton, DSList, Flex } from "@skillup/ui";
import { ConfigurationRoutes, ScheduleOperationsRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import User from "utils/User";
import { useTypedFetch } from "hooks";

import ScheduleItem, { ScheduleAction } from "./components/ScheduleItem";
import ScheduleRenameModal from "./components/ScheduleModal/ScheduleRenameModal";
import ScheduleBudgetModal from "./components/ScheduleModal/ScheduleBudgetModal";
import ScheduleActivateModal from "./components/ScheduleModal/ScheduleActivateModal";
import ScheduleDeleteModal from "./components/ScheduleModal/ScheduleDeleteModal";
import ScheduleArchiveModal from "./components/ScheduleModal/ScheduleArchiveModal";

import styles from "./PortalSchedules.module.scss";
import useAdminProperties from "../../PortalManagement/components/AdminPortal/state/useAdminProperties";
import ImportedPlansModal from "./components/ScheduleModal/ImportedPlansModal";

export type Schedule = ConfigurationRoutes.GetScheduleListForCompany["response"][0];

export default () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { data: adminData } = useAdminProperties();
  const { activeCompany } = User.getUserData();

  const { data: scheduleList, refetch } =
    useTypedFetch<ConfigurationRoutes.GetScheduleListForCompany>({
      method: "GET",
      path: "/configuration/schedules-for-company/{companyUuid}",
      params: { companyUuid: activeCompany.uuid },
    });

  const [modal, setModal] = useState(null);

  const activeDescription = useMemo(() => {
    const forUsers = adminData?.properties["portalUsersCollectionEnabled"];
    const forManagers = adminData?.properties["portalManagersCollectionEnabled"];
    if (!forUsers && forManagers) {
      return t("supervisor.portalschedules.active.description.managers", {
        defaultValue:
          "Le recueil est actuellement activé pour les managers uniquement. L’ensemble des actions de formation générées depuis l’espace manager seront créées dans le plan actif.",
      });
    } else if (forUsers && !forManagers) {
      return t("supervisor.portalschedules.active.description.users", {
        defaultValue:
          "Le recueil est actuellement activé pour les collaborateurs uniquement. L’ensemble des actions de formation générées depuis l’espace collaborateur seront créées dans le plan actif.",
      });
    } else if (forUsers && forManagers) {
      return t("supervisor.portalschedules.active.description.usersandmanagers", {
        defaultValue:
          "Le recueil est actuellement activé pour les collaborateurs et les managers. L’ensemble des actions de formation générées depuis l’espace collaborateur et l’espace manager seront créées dans le plan actif.",
      });
    } else {
      return t("supervisor.portalschedules.active.description.none", {
        defaultValue:
          "Le recueil est actuellement désactivé pour les collaborateurs et les managers",
      });
    }
  }, [adminData, t]);

  const { activePlan, unactivePlans, archivedPlans } = useMemo(
    () =>
      scheduleList?.reduce(
        (acc, curr) => {
          if (curr.archivedAt) {
            acc.archivedPlans.push(curr);
          } else if (curr.active) {
            acc.activePlan = curr;
          } else {
            acc.unactivePlans.push(curr);
          }
          return acc;
        },
        {
          activePlan: null,
          archivedPlans: [],
          unactivePlans: [],
        }
      ) || {
        activePlan: null,
        archivedPlans: [],
        unactivePlans: [],
      },
    [scheduleList]
  );

  const handleEdit = async (action: ScheduleAction, schedule: Schedule) => {
    const modalProps = {
      schedule,
      onClose: (needRefresh) => {
        setModal(null);
        if (needRefresh) refetch();
      },
    };
    try {
      switch (action) {
        case "rename":
          setModal(<ScheduleRenameModal {...modalProps} />);
          break;
        case "budget":
          setModal(<ScheduleBudgetModal {...modalProps} />);
          break;
        case "activate":
          setModal(<ScheduleActivateModal {...modalProps} oldActivePlanName={activePlan?.name} />);
          break;
        case "delete":
          setModal(<ScheduleDeleteModal {...modalProps} />);
          break;
        case "archive":
          setModal(<ScheduleArchiveModal {...modalProps} />);
          break;
        case "unarchive":
          await buildRequest<ScheduleOperationsRoutes.Unarchive>({
            method: "POST",
            path: "/schedule/{scheduleUuid}/operations/unarchive",
            params: { scheduleUuid: schedule.uuid },
          })();
          addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
            appearance: "success",
          });
          refetch();
          break;
        case "mask-as-imported":
        case "mask-as-not-imported":
          await buildRequest<ScheduleOperationsRoutes.UpdateImportSetting>({
            method: "POST",
            path: "/schedule/{scheduleUuid}/operations/import-setting",
            params: { scheduleUuid: schedule.uuid },
            payload: { value: action === "mask-as-imported" },
          })();
          addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
            appearance: "success",
          });
          refetch();
          break;
        default:
          addToast(t("app.errors.message"), { appearance: "error" });
      }
    } catch (e) {
      addToast(t("app.errors.message"), { appearance: "error" });
    }
  };

  return (
    <Flex column className={styles.PortalSchedules}>
      <Flex column className={styles.Lists}>
        {activePlan && (
          <DSList
            title={t("supervisor.portalschedules.active.title", {
              defaultValue: "Plan actif",
            })}
            description={activeDescription}
          >
            <DSList.Item>
              <ScheduleItem
                schedule={activePlan}
                onEdit={(action) => handleEdit(action, activePlan)}
              />
            </DSList.Item>
          </DSList>
        )}
        {!isEmpty(unactivePlans) && (
          <DSList
            title={t("supervisor.portalschedules.inactive.title", {
              defaultValue: "Plans inactifs",
            })}
            description={t("supervisor.portalschedules.inactive.description", {
              defaultValue:
                "Les plans inactifs restent accessibles dans les vues « Recueil » et « Plan » de l’onglet « Formation ».",
            })}
            mode="muted"
          >
            {unactivePlans.map((schedule) => (
              <DSList.Item key={schedule.uuid}>
                <ScheduleItem
                  schedule={schedule}
                  onEdit={(action) => handleEdit(action, schedule)}
                />
              </DSList.Item>
            ))}
          </DSList>
        )}
        {!isEmpty(archivedPlans) && (
          <DSList
            title={t("supervisor.portalschedules.archived.title", {
              defaultValue: "Plan archivés",
            })}
            description={t("supervisor.portalschedules.archived.description", {
              defaultValue:
                "Les plans archivés ne sont plus accessibles dans les vues « Recueil » et « Plan » de l’onglet « Formation ».",
            })}
            mode="muted"
          >
            {archivedPlans.map((schedule) => (
              <DSList.Item key={schedule.uuid}>
                <ScheduleItem
                  schedule={schedule}
                  onEdit={(action) => handleEdit(action, schedule)}
                />
              </DSList.Item>
            ))}
          </DSList>
        )}
      </Flex>
      <DSButton
        label="Configuration des plans importés"
        emphasis="Low"
        buttonSize="S"
        className={styles.importedButton}
        onClick={() => {
          setModal(<ImportedPlansModal onClose={() => setModal(null)} />);
        }}
      />
      {modal}
    </Flex>
  );
};
