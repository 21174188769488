/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { v4 } from "uuid";

import { useMediaQueries } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { type ScheduleRowTypes, type PanelConfigType } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import DSLayout from "components/DSLayout";
import DSNewHeader from "components/DSNewHeader";

import { ConfigurationForm } from "../components/Configuration/ConfigurationForm";
import { SkeletonLoader } from "../components/Configuration/Skeleton";

import type { CardConfigForm, DashboardConfigForm, PanelConfigForm } from "../types";
import { TrainingDashboardContext } from "../context";
import { getPanelType } from "../utils/panel";
import { fetchAnalyticsConfig, getQuestions, saveAnalyticsConfig } from "../adapters/config";

import style from "../views/Configuration.module.scss";
import User from "utils/User";

const getCardPropertiesFromQuestion = (
  card: CardConfigForm,
  question: ScheduleRowTypes.Question,
  type: PanelConfigType
): ScheduleRowTypes.DashboardCardConfig => ({
  short_name: card.short_name ?? question.recommended_short_name,
  charts: question.available_charts,
  filters: question.available_filters,
  relative_width: type === "summary" || type === "monthly" ? 1 : 0.5,
  x_label: question.recommended_x_label,
  y_label: question.recommended_y_label,
  question_name: card.question_name,
});

export const TrainingDashboardConfiguration = () => {
  const { t } = useTranslation();

  const { isMobile } = useMediaQueries();

  const { data: questions, isLoading: questionLoading } = useQuery(
    ["trainings-dashboard-questions-config"],
    () =>
      getQuestions().then((questions) =>
        questions.filter(
          (question) => User.isCompanyWithTax() || question.name.indexOf("with_tax") === -1
        )
      )
  );

  const {
    data: dbConfig,
    refetch,
    isLoading: loading,
  } = useQuery(["trainings-dashboard-config"], () => fetchAnalyticsConfig());

  const [
    keyIndicators,
    detailedIndicators,
    summaryQuestion,
    monthlyQuestion,
    summaryQuestionWithTax,
    monthlyQuestionWithTax,
  ] = useMemo(
    () => [
      questions?.filter((question) => question.fields.length === 1),
      questions?.filter((question) => question.fields.length === 2),
      questions?.find((question) => question.name === "training_plan_summary"),
      questions?.find(
        (question) => question.name === "sums_of_price_with_training_plan_budgets_by_month"
      ),
      questions?.find((question) => question.name === "training_plan_summary_with_tax"),
      questions?.find(
        (question) => question.name === "sums_of_price_with_tax_with_training_plan_budgets_by_month"
      ),
    ],
    [questions]
  );

  const [indicatorDimensionMap, questionsMap, indicatorLabels, dimensionLabels] = useMemo(
    () => [
      detailedIndicators?.reduce(
        (acc, question) => {
          return {
            ...acc,
            [question.fields[0]]: {
              ...(acc[question.fields[0]] ?? {}),
              [question.fields[1]]: question.name,
            },
          };
        },
        {} as Record<string, Record<string, string>>
      ),
      questions?.reduce(
        (acc, question) => {
          return {
            ...acc,
            [question.name]: {
              indicator: question.fields[0],
              dimension: question.fields[1],
            },
          };
        },
        {} as Record<string, { indicator: string; dimension: string }>
      ),
      detailedIndicators?.reduce(
        (acc, question) => ({
          ...acc,
          [question.fields[0]]: question.recommended_x_label,
        }),
        {} as Record<string, string>
      ),
      detailedIndicators?.reduce(
        (acc, question) => ({
          ...acc,
          [question.fields[1]]: question.recommended_y_label,
        }),
        {} as Record<string, string>
      ),
    ],
    [detailedIndicators, questions]
  );

  const handleSaveForm = async (config: DashboardConfigForm) => {
    await saveAnalyticsConfig({
      short_name: config.short_name,
      description: dbConfig.description,
      panels: config.panels.map((panel: PanelConfigForm) => ({
        short_name: panel.short_name,
        description: "",
        relative_width: 1,
        cards: panel.cards.map((card: CardConfigForm) =>
          getCardPropertiesFromQuestion(
            card,
            questions.find((q) => q.name === card.question_name),
            panel.type
          )
        ),
      })),
      feature_tags: ["training"],
    });

    await refetch();
  };

  const configuration: DashboardConfigForm = useMemo(() => {
    if (!dbConfig || !questionsMap) {
      return undefined;
    }

    return {
      short_name: dbConfig.short_name,
      panels: dbConfig.panels
        .filter((panel) => panel.cards.length > 0)
        .map((panel: ScheduleRowTypes.DashboardPanelConfig): PanelConfigForm => {
          const type = getPanelType(
            panel,
            questions,
            summaryQuestion,
            monthlyQuestion,
            summaryQuestionWithTax,
            monthlyQuestionWithTax
          );

          if (!type) {
            return undefined;
          }

          return {
            id: v4(),
            type,
            short_name: panel.short_name,
            cards: panel.cards.map((card: ScheduleRowTypes.DashboardCardConfig) => ({
              id: v4(),
              short_name: card.short_name,
              question_name: card.question_name,
              indicator: questionsMap[card.question_name]?.indicator,
              dimension: questionsMap[card.question_name]?.dimension,
            })),
          };
        })
        .filter(Boolean) as PanelConfigForm[],
    };
  }, [dbConfig, questionsMap]);

  const isLoading = loading || questionLoading;

  return (
    <Flex className={style.configuration}>
      {isMobile ? (
        <DSLayout title="" isHiddenOnMobile showMenu className={style.mobileView}>
          <NotAvailableInMobileView />
        </DSLayout>
      ) : (
        <>
          {isLoading && (
            <DSNewHeader
              parent={{
                titleUrl: "/responsable/dashboard/training",
                title: t("training.dashboard.title", { defaultValue: "Dashboard formation" }),
              }}
              title={t("training.dashboard.configuration.title", {
                defaultValue: "Personnalisation du dashboard",
              })}
              layouts={[]}
            />
          )}
          {isLoading && <SkeletonLoader />}
          {!isLoading && !configuration && (
            <span>
              {t("trainings.view.dashboard.no_config_found", {
                defaultValue: "Aucune configuration trouvée",
              })}
            </span>
          )}
          {!isLoading && configuration && (
            <TrainingDashboardContext.Provider
              value={{
                detailedIndicatorsUtilities: {
                  indicatorDimensionMap,
                  questionsMap,
                  indicatorLabels,
                  dimensionLabels,
                },
                keyIndicators,
                detailedIndicators,
                summaryQuestion,
                monthlyQuestion,
                summaryQuestionWithTax,
                monthlyQuestionWithTax,
              }}
            >
              <ConfigurationForm initialValue={configuration} onSubmit={handleSaveForm} />
            </TrainingDashboardContext.Provider>
          )}
        </>
      )}
    </Flex>
  );
};
