import { Status, StatusType } from "@skillup/ui";

import type { TranslationType } from "hooks/useTranslation";

import type { Tracking } from "../../state/tracking";

function TrackingStatus({
  status,
  t,
  darkMode = false,
}: Pick<Tracking, "status"> & { t: TranslationType; darkMode?: boolean }) {
  switch (status) {
    case "valid":
      return (
        <Status
          type={StatusType.SUCCESS}
          label={t("trainings.entity.tracking.status.valid", {
            defaultValue: "À jour",
          })}
          darkMode={darkMode}
        />
      );
    case "pending":
      return (
        <Status
          type={StatusType.WARNING}
          label={t("trainings.entity.tracking.status.pending", {
            defaultValue: "En attente",
          })}
          darkMode={darkMode}
        />
      );
    case "archived":
      return (
        <Status
          type={StatusType.DONE}
          label={t("trainings.entity.tracking.status.archived", {
            defaultValue: "Archivée",
          })}
          darkMode={darkMode}
        />
      );
    case "expiring":
      return (
        <Status
          type={StatusType.WARNING}
          label={t("trainings.entity.tracking.status.expiring", {
            defaultValue: "Échéance proche",
          })}
          darkMode={darkMode}
        />
      );
    case "expired":
      return (
        <Status
          type={StatusType.ERROR}
          label={t("trainings.entity.tracking.status.expired", {
            defaultValue: "Expirée",
          })}
          darkMode={darkMode}
        />
      );
  }
}

export default TrackingStatus;
