import { useCallback, useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useToasts } from "react-toast-notifications";

import {
  DSModal,
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSFormGroupTextInput,
  DSTextInput,
  Select,
} from "@skillup/ui";
import { useQuery } from "@tanstack/react-query";
import { type FieldRoutesType } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import DataLayer from "utils/DataLayer";
import cx from "classnames";

import styles from "../Components/PostponeModal.module.scss";

interface Props {
  rows: string[];
  rowsAreManuallyAdded: boolean;
  rowsManagedBySkillup: boolean;
  type: string;
  rowStatus: string;
  onDone: () => void;
  onBack: () => void;
  onClose: (needRefresh?: boolean) => void;
}

const getField = async () => {
  try {
    const data = await buildRequest<FieldRoutesType.GetV2>({
      method: "GET",
      path: `/fields-v2`,
      query: {
        bindings: ["deniedOrCancelledReason"],
      },
    })();

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const PostponeModal = ({
  rows,
  rowsAreManuallyAdded,
  rowsManagedBySkillup,
  type,
  rowStatus,
  onDone,
  onBack,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>(null);
  const [messageOF, setMessageOF] = useState<string>(null);
  const [postponeReason, setPostponeReason] = useState<string>(null);

  const { data: [field] = [] } = useQuery(["denied-or-Cancelled-field"], () => getField());
  const fieldOptions = useMemo(
    () => field?.options.map(({ value, key }) => ({ label: value, value: key })),
    [field]
  );

  const displayCommentOF = useMemo(() => {
    return rowsManagedBySkillup && rowStatus === "plan-processing" && postponeReason !== "12";
  }, [rowsManagedBySkillup, rowStatus, postponeReason]);

  const disabledSubmit = useMemo(() => {
    return (type !== "deny" && displayCommentOF && !messageOF) || !postponeReason;
  }, [messageOF, postponeReason, type, displayCommentOF]);

  const handlePostponeSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      await DataLayer.request({
        body: JSON.stringify({
          rows,
          message,
          cancelledMessageForTrainingOrganization: messageOF,
          deniedOrCancelledReason: postponeReason,
        }),
        method: "POST",
        url: `/v1/scheduleRow/postpone`,
      });

      addToast(
        t("trainings.schedules.postpone.success", {
          defaultValue: "Demande reportée avec succès",
        }),
        {
          appearance: "success",
        }
      );

      if (rows.length === 1 && !rowsAreManuallyAdded) {
        if (rowsManagedBySkillup) {
          onDone();
        } else if (type === "Intra") {
          onDone();
        } else {
          onClose(true);
        }
      } else {
        onClose(true);
      }
    } catch (error) {
      addToast(t("trainings.schedules.postpone.error", { defaultValue: "La demande a échoué" }), {
        appearance: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    rows,
    rowsAreManuallyAdded,
    rowsManagedBySkillup,
    message,
    messageOF,
    postponeReason,
    type,
    onDone,
    onClose,
    addToast,
    t,
  ]);

  return (
    <DSModal isOpen className={styles.PostponeModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.schedules.postpone.title", {
            count: rows.length,
            defaultValue: "Reporter la demande",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <p>
          {t("trainings.schedules.postpone.description", {
            defaultValue:
              'Si vous confirmez cette action, la demande repassera dans l\'onglet "À inscrire". Vous pourrez ensuite réinscrire le stagiaire à une nouvelle session ou requalifier la demande.',
          })}
        </p>
        <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
          <ul
            className={cx({
              [styles.warnList]:
                rowsManagedBySkillup && rowStatus === "plan-processing" && postponeReason === "12",
            })}
          >
            <li>
              {t(`trainings.schedules.postpone.modal.alert.info`, {
                defaultValue: `Le collaborateur ne sera pas notifié du report`,
              })}
            </li>
            {rowsManagedBySkillup && rowStatus === "plan-processing" && postponeReason === "12" && (
              <li>
                {t(`trainings.schedules.postpone.modal.alert.info.of`, {
                  defaultValue: `L'organisme ne sera pas notifié du report`,
                })}
              </li>
            )}
          </ul>
        </DSAlert>
        <form onSubmit={(e) => e.preventDefault()}>
          <DSFormGroupTextInput
            label={t("trainings.schedules.postpone.reason.label", {
              defaultValue: "Motif du report",
            })}
            required
            placeholder={t("trainings.schedules.cancelOrPostpone.reason.placeholder", {
              defaultValue: "Choisir un motif",
            })}
          >
            <Select onChange={setPostponeReason} options={fieldOptions} />
          </DSFormGroupTextInput>
          {displayCommentOF && (
            <DSFormGroupTextInput
              label={t("trainings.schedules.postpone.modal.commentOF.label", {
                defaultValue: `Commentaire d'annulation destiné à l'organisme`,
              })}
              assistiveText={t("trainings.schedules.postpone.modal.commentOF.assistiveText", {
                defaultValue: "Ce commentaire sera transmis à l'organisme.",
              })}
              required
            >
              <DSTextInput
                placeholder={t("trainings.schedules.postpone.modal.comment.placeholder", {
                  defaultValue: "Laissez un commentaire dans votre activité",
                })}
                onChange={setMessageOF}
                name="messageOF"
              />
            </DSFormGroupTextInput>
          )}
          <DSFormGroupTextInput
            label={t("trainings.schedules.postpone.comment.label", {
              defaultValue: "Commentaire (facultatif)",
            })}
            assistiveText={t("trainings.schedules.postpone.comment.assistiveText", {
              defaultValue:
                "Le commentaire ci-dessus ne sera pas transmis au collaborateur mais figurera dans l'activité de la ligne",
            })}
          >
            <DSTextInput
              autoFocus={true}
              placeholder={t("trainings.schedules.postpone.comment.placeholder", {
                defaultValue: "Laissez un commentaire dans votre activité",
              })}
              onChange={(value) => setMessage(value)}
              name="message"
            />
          </DSFormGroupTextInput>
        </form>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          disabled={loading}
          onClick={onBack}
          label={t("app.modal.action.back", {
            defaultValue: "Retour",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handlePostponeSubmit}
          label={t("trainings.schedules.postpone.confirm", {
            defaultValue: "Confirmer le report",
          })}
          disabled={disabledSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
