import { useVirtualizer } from "@tanstack/react-virtual";
import { Modal, DSCheckbox, DSAvatar, HorizontalDivider } from "@skillup/ui";
import { useState, useMemo, useRef } from "react";
import cx from "classnames";
import {
  CampaignsRoutes,
  ClosedQuestionAnswer,
  InterviewRoles,
  InterviewActiveAttendeeRoles,
} from "@skillup/espace-rh-bridge";

import styles from "./RepliesDetailModal.module.scss";
import { useTypedFetch } from "hooks";
import { TranslationType } from "hooks/useTranslation";
import { useDashboardData } from "../../../context";
import { StructureChildPayload } from "../../DashboardChildCard/DashboardChildCard";

export interface Props {
  child: StructureChildPayload;
  attendeeRole: InterviewActiveAttendeeRoles;
  answer: ClosedQuestionAnswer;
  onClose: () => void;
  t: TranslationType;
}

function RepliesDetailModal({ child, attendeeRole, answer, onClose, t }: Props) {
  const { userAreas } = useDashboardData();

  const { data } = useTypedFetch<CampaignsRoutes.Dashboard.GetQuestionReplies>({
    method: "GET",
    path: "/interviews/dashboard/child/{childUuid}/question/replies",
    params: {
      childUuid: child.uuid,
    },
    query: {
      label: answer.label,
      role: attendeeRole,
      areas: userAreas?.join(","),
    },
  });

  const [showComments, setShowComments] = useState(false);

  const roleTitle = useMemo(() => {
    if (!attendeeRole)
      return t("dashboard.interviews.child.replies.roleTitle.none", {
        defaultValue: "Utilisateurs",
      });
    switch (attendeeRole) {
      case InterviewRoles.EMPLOYEE:
        return t("dashboard.interviews.child.replies.roleTitle.employee", {
          defaultValue: "Collaborateurs",
        });
      case InterviewRoles.MANAGER:
        return t("dashboard.interviews.child.replies.roleTitle.manager", {
          defaultValue: "Responsables",
        });
    }
  }, [attendeeRole, t]);

  const croppedTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  const title = useMemo(() => {
    return t("dashboard.interviews.child.replies.title", {
      defaultValue:
        "{{ roleTitle }} ayant répondu « {{ answer }} » à la question « {{ question }} »",
      roleTitle: roleTitle,
      answer: croppedTitle(answer.label, 30),
      question: croppedTitle(child.label, 30),
    });
  }, [roleTitle, answer.label, child.label, t]);

  return (
    <Modal title={title} onClose={onClose}>
      {data && data.replies.length > 0 ? (
        <div className={styles.RepliesDetailModal}>
          {!child.disallow?.[attendeeRole]?.comment && (
            <div className={styles.options}>
              <DSCheckbox
                name="question-comments"
                label={t("dashboard.interviews.child.replies.showComments", {
                  defaultValue: "Afficher les commentaires",
                })}
                checked={showComments}
                onChange={(checked) => setShowComments(checked)}
              />
            </div>
          )}
          <VirtualRepliesList showComments={showComments} replies={data.replies} t={t} />
        </div>
      ) : (
        <div className={cx(styles.RepliesDetailModal, styles.loading)} />
      )}
    </Modal>
  );
}

export default RepliesDetailModal;

type Replies = CampaignsRoutes.Dashboard.GetQuestionReplies["response"]["replies"];
/**
 * This component is virtualized in order to have a lighter performance
 * impact, since it might display thousands of attendees.
 * => Rendering only those that are visible in the viewport is
 * a good way to avoid ressources usage.
 */
function VirtualRepliesList({
  replies,
  showComments,
  t,
}: {
  replies: Replies;
  showComments: boolean;
  t: TranslationType;
}) {
  const bodyRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: replies.length,
    getScrollElement: () => bodyRef.current,
    estimateSize: () => replies.length,
  });

  return (
    <div className={styles.listWrapper}>
      <div
        ref={bodyRef}
        className={styles.list}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualReply) => {
          const reply = replies[virtualReply.index];

          return (
            <div
              key={`${virtualReply.key}_${showComments}`}
              style={{
                width: "100%",
              }}
            >
              {!!virtualReply.index && <HorizontalDivider top="xs" bottom="xs" />}
              <div className={styles.reply}>
                <div className={styles.avatar}>
                  <DSAvatar
                    generateRandomColor
                    initials={reply.author.initials}
                    size="L"
                    randomColorSeedString={reply.author.uuid}
                  />
                </div>
                <div className={styles.content}>
                  <div className={styles.user}>{reply.author.fullName}</div>
                  <div className={styles.role}>
                    {formatInterviewRole({
                      author: reply.author,
                      interview: reply.interview,
                      t: t,
                    })}
                  </div>
                  {showComments && <div className={styles.comment}>{reply.comment}</div>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function formatInterviewRole({ author, interview, t }: Replies[0] & { t: TranslationType }) {
  if (author.attendeeRole === InterviewRoles.MANAGER) {
    return t("dashboard.interviews.child.interviewRole.manager", {
      defaultValue: "Responsable d'entretien de {{ fullName }}",
      fullName: interview.employee.fullName,
    });
  }

  return t("dashboard.interviews.child.interviewRole.employee", {
    defaultValue: "Collaborateur",
  });
}
