import React, { useCallback, useRef, CSSProperties } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { useGoogleMap } from "@skillup/hooks";
import { useClickAway, useToggle } from "react-use";
import { isEmpty } from "lodash";
import cx from "classnames";

import useTranslation from "hooks/useTranslation";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import { mapPin, chevronRight } from "uiAssets/Icons";
import { cross } from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";

import styles from "./PlaceFilter.module.scss";
import useEnvironment from "hooks/useEnvironment";

export interface Place {
  readonly label: string;
  readonly lat: string;
  readonly lon: string;
}

interface Props {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly title?: string;
  readonly placeholder?: string;
  readonly defaultValue?: string;
  readonly onPlaceSelect?: (place: Place) => void;
  readonly onClearValue?: () => void;
}

const PlaceFilter = (props: Props) => {
  const { title, placeholder, onPlaceSelect, onClearValue, className, style } = props;
  const { isDevelopment } = useEnvironment();
  const self = useRef<HTMLDivElement>(null);

  const [loaded] = useGoogleMap({ development: isDevelopment });
  const [isPredictionsOpen, togglePredictions] = useToggle(false);
  const { t } = useTranslation();

  const {
    ready: isGooglePlacesReady,
    value: searchValue,
    suggestions: { data: predictions },
    setValue: setSearchValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "fr" },
      types: ["(cities)"],
    },
    debounce: 300,
    callbackName: "initMap", // load the script asynchronously
  });

  const handleSearchSuggestions = useCallback(
    (_e, value: string) => {
      if (value.length > 1 && loaded && isGooglePlacesReady) {
        return setSearchValue(value);
      }

      setSearchValue(value, false);
      clearSuggestions();
    },
    [setSearchValue, isGooglePlacesReady, clearSuggestions, loaded]
  );

  const setFilters = useCallback(
    async (label: string, placeId: string) => {
      setSearchValue(label, false);
      clearSuggestions();

      const [result] = await getGeocode({ placeId });
      const { lat, lng: lon } = await getLatLng(result);

      if (onPlaceSelect) {
        return onPlaceSelect({ label, lat: lat.toString(), lon: lon.toString() });
      }
    },
    [clearSuggestions, setSearchValue, onPlaceSelect]
  );

  const handleErase = useCallback(() => {
    setSearchValue("", false);

    if (onClearValue) {
      return onClearValue();
    }
  }, [setSearchValue, onClearValue]);

  useClickAway(self, () => {
    if (isPredictionsOpen && !isEmpty(predictions)) {
      togglePredictions(false);
    }
  });

  return (
    <div className={cx(styles.PlaceFilter, className)} style={style} ref={self}>
      <h3>
        {title ||
          t("trainings.view.requalification_modal.sidebar.session_place", {
            defaultValue: "Lieu de la session",
          })}
      </h3>

      <div className={styles.inputContainer}>
        <TextInput
          style={{ padding: 0, margin: 0 }}
          background="white"
          borderColor="#999"
          defaultValue={searchValue}
          icon={mapPin}
          onChange={handleSearchSuggestions}
          onFocus={() => togglePredictions(true)}
          placeholder={placeholder || "ex : Paris"}
          alwaysOpen
          autoComplete="off"
        />
        <div className={styles.cancelButton} onClick={handleErase}>
          <Icon strokeIcon={cross} width={15} />
        </div>

        {isPredictionsOpen && !isEmpty(predictions) && (
          <div className={styles.predictions}>
            {predictions.map(({ place_id, structured_formatting: { main_text } }) => (
              <button
                key={place_id}
                className={styles.prediction}
                onClick={() => setFilters(main_text, place_id)}
              >
                {main_text}
                <Icon icon={chevronRight} width={10} fill={Colors.mainColor} />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaceFilter;
