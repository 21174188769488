import { ModulesAccessList } from "@skillup/espace-rh-bridge";

import { ModuleType } from "hooks/useSettings";
import User from "utils/User";

const Configuration = (hasAccess: { to: (module: ModuleType) => boolean }) => {
  const toConfigurationAdminPlatform = () =>
    User.isSkillupDeveloper() ||
    ((User.isCompanyUser() || User.isSkillupAdmin()) &&
      hasAccess.to(ModulesAccessList.ConfigurationAdminPlatform));
  const toConfigurationPlans = () =>
    User.isSkillupDeveloper() ||
    (((User.isRF() && !User.hasFieldsV2Disable()) || User.isSkillupAdmin()) &&
      hasAccess.to(ModulesAccessList.ConfigurationPlans));
  const toConfigurationPortail = () =>
    User.isSkillupDeveloper() ||
    ((User.isCompanyUser() || User.isSkillupAdmin()) &&
      hasAccess.to(ModulesAccessList.ConfigurationPortail));
  const toConfigurationImports = () => User.isSkillupDeveloper();
  const toSkillsManagement = () =>
    User.isSkillupDeveloper() ||
    ((User.isCompanyUser() || User.isSkillupAdmin()) &&
      hasAccess.to(ModulesAccessList.ConfigurationAdminPlatform) &&
      User.canAccessCompetencesModule());

  const toConfiguration = () =>
    toConfigurationAdminPlatform() ||
    toConfigurationPlans() ||
    toConfigurationPortail() ||
    toConfigurationImports();

  return {
    toConfiguration,
    toSkillsManagement,
    toConfigurationAdminPlatform,
    toConfigurationPlans,
    toConfigurationPortail,
    toConfigurationImports,
  };
};

export default Configuration;
