import { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { sortBy } from "lodash";

import { FormatDate, formatMonetary, LocaleDate, ParseDate } from "@skillup/shared-utils";
import { Column, ColumnType, DSTooltip } from "@skillup/ui";
import { TrainingUtils } from "@skillup/training-bridge";

import User from "utils/User";
import useSettings from "hooks/useSettings";

import { TrainingResults } from "../types";
import styles from "../UserTraining.module.scss";

import AggregationsState from "./AggregationsState";

type Props = {
  trainings: TrainingResults;
};

const formatTrainingDates = (date: string, dateFormat: string) => {
  if (date && date.length > 4) {
    return FormatDate.ToStringFormat(ParseDate.FromStringFormat(date, "yyyy-MM-dd"), dateFormat);
  }
  return date;
};
const formatCreatedAt = (date: string, dateFormat: string) => {
  if (date.length > 4) {
    return FormatDate.ToStringFormat(ParseDate.FromISO(date), dateFormat);
  }
  return date;
};

function useTableData({ trainings }: Props): {
  data: TrainingRow[];
  columns: Column<TrainingRow>[];
} {
  const currentLanguage = User.getCurrentLanguage();
  const dateFormat = LocaleDate.dateFormats[currentLanguage as LocaleDate.Locales].shortDate;

  const { t, i18n } = useTranslation();
  const settings = useSettings();

  const tableData = useMemo(
    () => ({
      columns: generateColumns({ t, dateFormat, i18n, settings }),
      data: generateTableRows(trainings ?? []),
    }),
    [trainings, t, dateFormat, i18n, settings]
  );

  return tableData;
}

export default useTableData;

const generateColumns = ({
  t,
  dateFormat,
  i18n,
  settings,
}: {
  t: TFunction<"translation", undefined>;
  dateFormat: string;
  i18n;
  settings;
}) => {
  const generateDateCell = (value: string | number, label: string) => {
    return (
      <div className={styles.cell}>
        <div className={styles.title}>{value}</div>
        <div className={styles.subtitle}>{label}</div>
      </div>
    );
  };

  const columns: Array<Column<TrainingRow>> = [
    {
      key: "state",
      title: t("supervisor.view.collaborators.training_tab.property.table.header.state", {
        defaultValue: "Statut",
      }),
      filterable: false,
      renderCell: (training) => <AggregationsState state={training.state} />,
    },
    {
      key: "trainingName",
      title: t("supervisor.view.collaborators.training_tab.property.table.header.trainingName", {
        defaultValue: "Formation et organisme",
      }),
      renderCell: (training) => (
        <div className={styles.cell}>
          <div className={styles.title}>
            <DSTooltip
              label={training.trainingName}
              enabledOnOverflow
              tooltipClassName={styles.tooltip}
            >
              {training.trainingName}
            </DSTooltip>
          </div>
          <div className={styles.subtitle}>{training.organizationName}</div>
        </div>
      ),
    },
    {
      key: "date",
      title: t("supervisor.view.collaborators.training_tab.property.table.header.date", {
        defaultValue: "Date",
      }),
      filterable: false,
      renderCell: (training) => {
        const createdAtDate = formatCreatedAt(
          training.collectionData[0].value.toString(),
          dateFormat
        );

        const dateCell = TrainingUtils.ScheduleRow.formatScheduleRowDateAndLabel({
          t,
          createdAt: createdAtDate,
          startDate: formatTrainingDates(training.startDate, dateFormat),
          endDate: formatTrainingDates(training.endDate, dateFormat),
          state: training.state,
        });

        if (dateCell) {
          return generateDateCell(dateCell.value, dateCell.label);
        }
        return undefined;
      },
      sortFn: getRowWeight,
    },
    {
      key: "price",
      title: t("supervisor.view.collaborators.training_tab.property.table.header.price", {
        defaultValue: "Prix",
      }),
      type: ColumnType.NUMBER,
      renderCell: (training) =>
        formatMonetary(training.price, {
          locale: i18n.language,
          currency: settings?.settings?.currency,
        }),
    },
  ];

  return columns;
};

function generateTableRows(trainings: TrainingResults): Array<TrainingRow> {
  const rows = trainings?.map(parseTrainingIntoRow);

  return sortBy(rows, getRowWeight);
}

const getRowWeight = (a: TrainingRow) => {
  const trainingDateFormat = "yyyy-MM-dd";
  let startDate = a.data.startDate;
  let endDate = a.data.endDate;

  if (startDate && startDate.length === 4) {
    startDate = FormatDate.ToStringFormat(
      FormatDate.getFirstDayOfYear(startDate),
      trainingDateFormat
    );
  }

  if (endDate && endDate.length === 4) {
    endDate = FormatDate.ToStringFormat(FormatDate.getFirstDayOfYear(endDate), trainingDateFormat);
  }

  if (endDate) return -ParseDate.FromISO(endDate).valueOf();
  if (startDate) return -ParseDate.FromISO(startDate).valueOf();

  if (a.data.collectionData.length > 0) {
    let createdAt = a.data.collectionData[0].value.toString();

    if (createdAt.length === 4) {
      createdAt = FormatDate.ToStringFormat(
        FormatDate.getFirstDayOfYear(createdAt),
        trainingDateFormat
      );
    }

    return -ParseDate.FromISO(createdAt).valueOf();
  }

  return 0;
};

function parseTrainingIntoRow(training: TrainingResults[0]) {
  return {
    id: training.uuid,
    data: {
      state: training.state,
      trainingName: training.name,
      organizationName: training.organization.name,
      startDate: training.formattedTranslatableDates?.[0],
      endDate: training.formattedTranslatableDates?.[1],
      collectionData: training.collectionData,
      price: training.price,
      seoSlug: training.seoSlug,
      schedule: training.schedule,
    },
  };
}

export type TrainingRow = ReturnType<typeof parseTrainingIntoRow>;
