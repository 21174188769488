import { useState } from "react";
import { ReadOnlyFieldConfiguration } from "./ReadOnly/ReadOnlyFieldConfiguration";
import { EditFieldConfiguration } from "./Edit/EditFieldConfiguration";
import type { EmployeeField } from "../../services";
import { UpdatableFieldConfiguration } from "../../types";

type FieldConfigurationProps = {
  fieldConfiguration: UpdatableFieldConfiguration[];
  employeeFields: EmployeeField[];
  saveFieldConfiguration: (fields: UpdatableFieldConfiguration[]) => void;
};

export const FieldConfiguration = (props: FieldConfigurationProps) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);

  switch (isEditable) {
    case true:
      return (
        <EditFieldConfiguration
          employeeProfileConfiguration={{
            employeeFields: props.employeeFields,
            fieldConfiguration: props.fieldConfiguration,
          }}
          saveFieldConfiguration={props.saveFieldConfiguration}
          disabledEdit={() => setIsEditable(false)}
        />
      );
    default:
      return (
        <ReadOnlyFieldConfiguration
          employeeProfileConfiguration={{
            employeeFields: props.employeeFields,
            fieldConfiguration: props.fieldConfiguration,
          }}
          enableEdit={() => setIsEditable(true)}
        />
      );
  }
};
