import useTranslation from "hooks/useTranslation";

import { Flex, Text, Loader } from "@skillup/design-system";
import type { ParsedSkillEvaluation } from "@skillup/gepp";

import { trpc } from "utils/trpc";
import placeholderImg from "assets/collaborator/empty_skills.png";
import UserEvaluation from "./UserEvaluation";
import Legend from "./UserEvaluation/components/Legend";
import { UserSkillsWrapper, UserSkillsImgWrapper } from "./UserSkillsWrapper.styled";
import { Collaborator } from "../../api";

interface Props {
  readonly user: Collaborator;
}

const UserSkills = ({ user: { uuid, fullName } }: Props) => {
  const { t } = useTranslation();

  const employeeQuery = trpc.employees.getEmployeeByUuid.useQuery({ uuid });
  const skillEvalQuery = trpc.skillEvaluations.getLastEmployeeSkillEvaluations.useQuery({
    employeeUuid: uuid,
  });

  const { data, status } = employeeQuery;
  const { data: skillEvaluationData, status: getSkillEvalStatus } = skillEvalQuery;
  const skillEvaluation = skillEvaluationData as unknown as ParsedSkillEvaluation;

  const noJobMessage = t("collaborator.skills.hasNoJob", {
    defaultValue: " n'a pas encore de fiche de poste associée.",
  });
  const notEvaluatedMessage = t("collaborator.skills.skillsNotEvaluated", {
    defaultValue: " n'a pas encore reçu d'évaluation sur les compétences de sa fiche de poste",
  });

  const isLoading = status === "loading" || getSkillEvalStatus === "loading";
  const jobNotFoundForUser = !data?.jobs[0]?.name;
  const hasNoSkillEvaluations = !skillEvaluation?.skillsByCategory.length;

  if (isLoading) {
    return <Loader fillSpace />;
  }

  if (jobNotFoundForUser) {
    return (
      <UserSkillsImgWrapper>
        <Flex flexDirection="column">
          <img src={placeholderImg} alt="bientôt disponible" />
          <Text textAlign="center">
            {fullName}
            {noJobMessage}
          </Text>
        </Flex>
      </UserSkillsImgWrapper>
    );
  }

  if (hasNoSkillEvaluations) {
    return (
      <UserSkillsImgWrapper>
        <Flex flexDirection="column">
          <img src={placeholderImg} alt="bientôt disponible" />
          <Text textAlign="center">
            {fullName}
            {notEvaluatedMessage}
          </Text>
        </Flex>
      </UserSkillsImgWrapper>
    );
  }

  return (
    <>
      <Legend />
      <UserSkillsWrapper>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <UserEvaluation skillEvaluation={skillEvaluation} />
        </Flex>
      </UserSkillsWrapper>
    </>
  );
};

export default UserSkills;
