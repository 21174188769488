import { useState } from "react";
import useTranslation from "hooks/useTranslation";

import { DSModal, DSRadio, DSRadioGroup, DSAvatarGroup } from "@skillup/ui";

import { getUserInitials } from "utils/User";

import styles from "./UnsubscribeModal.module.scss";

type Props = {
  onSubmitCancel: () => void;
  onSubmitPostpone: () => void;
  onBack: () => void;
  onClose: () => void;
  trainees: Array<{
    uuid: string;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    row?: string;
    summoned: boolean;
    noEmail?: boolean;
    validatorLevel: number;
  }>;
};

enum ModalType {
  Postpone,
  Cancel,
}

const UnsubscribeModal = ({ onSubmitCancel, onSubmitPostpone, onBack, onClose, trainees }: Props) => {
  const { t } = useTranslation();

  const [modalType, setModalType] = useState<ModalType>();

  const handleSubmitNext = () => {
    if (modalType === ModalType.Postpone) {
      onSubmitPostpone();
    } else if (modalType === ModalType.Cancel) {
      onSubmitCancel();
    }
  };

  return (
    <>
      <DSModal isOpen className={styles.UnsubscribeModal}>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("trainings.manage.users.unsubscribe.title", {
              count: trainees.length,
              defaultValue: "Désinscrire {{ count }} stagiaire de la session",
            })}
          />
          <DSModal.Header.SubHeader>
            <DSAvatarGroup
              size="S"
              tooltipDirection="bottom"
              maxItems={5}
              totalItems={trainees.length}
              users={trainees.map((trainee) => {
                return {
                  uuid: trainee.uuid,
                  fullName: trainee.fullName,
                  initials: getUserInitials(trainee),
                };
              })}
            />
          </DSModal.Header.SubHeader>
        </DSModal.Header>

        <DSModal.Content className={styles.content}>
          <DSRadioGroup name="filterImportedScheduleFields">
            <DSRadio
              label={t("trainings.manage.users.unsubscribe.form.postpone", {
                count: trainees.length,
                defaultValue: "Reporter la demande",
              })}
              helperText={t("trainings.manage.users.unsubscribe.form.postpone.description", {
                defaultValue:
                  "Annuler la réservation mais permettre de choisir une autre date de formation ou un autre programme.",
              })}
              checked={modalType === ModalType.Postpone}
              onChange={() => setModalType(ModalType.Postpone)}
            />
            <DSRadio
              label={t("trainings.manage.users.unsubscribe.form.cancel", {
                count: trainees.length,
                defaultValue: "Annuler la demande",
              })}
              helperText={t("trainings.manage.users.unsubscribe.form.cancel.description", {
                defaultValue: "Annuler définitivement la réservation.",
              })}
              checked={modalType === ModalType.Cancel}
              onChange={() => setModalType(ModalType.Cancel)}
            />
          </DSRadioGroup>
        </DSModal.Content>

        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            label={t("trainings.manage.users.form.goback", { defaultValue: "Retour" })}
            onClick={onBack}
          />
          <DSModal.Footer.PrimaryButton
            disabled={modalType === undefined}
            label={t("trainings.manage.users.form.next", { defaultValue: "Suivant" })}
            onClick={handleSubmitNext}
          />
        </DSModal.Footer>
      </DSModal>
    </>
  );
};

export default UnsubscribeModal;
