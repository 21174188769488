import { MdHelp as Help } from "react-icons/md";
import { Flex } from "@skillup/design-system";
import { DSProgressBar, DSTooltip, TypeProgressBar } from "@skillup/ui";
import { isString } from "lodash";
import { QueryOptions, useTargetsCompletion } from "../../hooks";
import { TranslationType } from "hooks/useTranslation";
import styles from "./CompletionRate.module.scss";

const error_code_to_string = (code: string, t: TranslationType) => {
  switch (code) {
    case "error:uncompatible-ordinal-scales":
      return t("supervisor.view.targets.evaluated.completionRate.error.ordinalScales", {
        defaultValue:
          "Votre sélection contient des objectifs évalués sur des échelles non homogènes : il n'est donc pas possible d'afficher une synthèse des évaluations.",
      });
    case "error:inconsistent-weights":
      return t("supervisor.view.targets.evaluated.completionRate.error.weights", {
        defaultValue:
          "Votre sélection contient des objectifs dont la pondération n’est pas définie : il n’est donc pas possible d’afficher un taux d’atteinte global.",
      });
    default:
      return t("supervisor.view.targets.evaluated.completionRate.error.other", {
        defaultValue: "Une erreur est survenue",
      });
  }
};

export const CompletionRates = ({
  userUuid,
  queryOptions,
  t,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
  t: TranslationType;
}) => {
  const { numeric, ordinal } = useTargetsCompletion({
    userUuid,
    queryOptions,
  });

  return (
    <Flex flexDirection="row" alignItems="left" gap="m" paddingBottom="l">
      <NumericCompletionRates numeric={numeric} t={t} />
      <OrdinalCompletionRates ordinal={ordinal} t={t} />
    </Flex>
  );
};

const NumericCompletionRates = ({
  numeric,
  t,
}: {
  numeric?: string | number;
  t: TranslationType;
}) => {
  if (numeric === undefined) return null;

  return (
    <Flex
      flexDirection="column"
      paddingTop="s"
      paddingBottom="m"
      paddingHorizontal="m"
      gap="m"
      width="20rem"
      position="relative"
      className={styles.CompletionRate}
    >
      <DSTooltip
        className={styles.HelpTooltip}
        label={t("supervisor.view.targets.evaluated.completionRate.numeric.tooltip", {
          defaultValue:
            "Le taux d'atteinte global est calculé sur la base des évaluations réalisées par le manager uniquement",
        })}
      >
        <Help />
      </DSTooltip>
      <Flex flexDirection="column" gap="xxs">
        <div className={styles.Title}>
          {t("supervisor.view.targets.evaluated.completionRate.numeric.title", {
            defaultValue: "Objectifs quantitatifs",
          })}
        </div>
        <div className={styles.Caption}>
          {t("supervisor.view.targets.evaluated.completionRate.numeric.caption", {
            defaultValue: "Taux d’atteinte global",
          })}
        </div>
      </Flex>
      {isString(numeric) ? (
        <div className={styles.Message}>{error_code_to_string(numeric, t)}</div>
      ) : (
        <div className={styles.Numeric}>{Math.round(numeric)}&nbsp;%</div>
      )}
    </Flex>
  );
};

const OrdinalCompletionRates = ({
  ordinal,
  t,
}: {
  ordinal?: string | Record<string, number>;
  t: TranslationType;
}) => {
  if (ordinal === undefined) return null;

  const max = Object.values(ordinal).reduce((acc, current) => acc + current, 0);

  return (
    <Flex
      flexDirection="column"
      paddingVertical="s"
      paddingHorizontal="m"
      gap="m"
      width="20rem"
      className={styles.CompletionRate}
    >
      <Flex flexDirection="column" gap="xxs">
        <div className={styles.Title}>
          {t("supervisor.view.targets.evaluated.completionRate.ordinal.title", {
            defaultValue: "Objectifs qualitatifs",
          })}
        </div>
        <div className={styles.Caption}>
          {t("supervisor.view.targets.evaluated.completionRate.ordinal.caption", {
            defaultValue: "Distribution des évaluations",
          })}
        </div>
      </Flex>
      {isString(ordinal) ? (
        <div className={styles.Message}>{error_code_to_string(ordinal, t)}</div>
      ) : (
        <DSProgressBar
          darkMode={true}
          layout="list"
          type={TypeProgressBar.RAW}
          max={max}
          thin={true}
          labelFormatter={(label, value) => ""}
          valueFormatter={(label, value) => (
            <div className={`${styles.Ordinal} ${styles.Label}`}>
              <span className={styles.Bold}>{value}</span>/{max}{" "}
              <span className={styles.Bold}>{label}</span>
            </div>
          )}
          data={Object.entries(ordinal)
            .toReversed()
            .map(([label, value]) => ({
              label,
              value,
              color: "yellow",
            }))}
        />
      )}
    </Flex>
  );
};
