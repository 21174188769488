import React, { useCallback, useMemo, useState } from "react";
import ReadMoreReact from "read-more-react";
import cx from "classnames";

import { Duration, formatMonetary } from "@skillup/shared-utils";
import { TrainingUtils } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";
import User from "utils/User";
import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";
import downloadFileAsUser from "utils/downloadFileAsUser";

import Colors from "uiAssets/Colors";
import { calendar, chevronBottom } from "uiAssets/StrokeIcons";
import { signWarning, time } from "uiAssets/Icons";
import Icon from "components/Icon";
import InteractiveButton from "components/InteractiveButton";

import showDeleteConfirmationModal from "../Actions/Modals/showDeleteConfirmationModal";
import { Project } from "../Actions/getProjects";

import { SESSION_STATES } from "./helper";
import DropDownActionMenu from "./DropDownActionMenu";
import styles from "./Summary.module.scss";
import { generateModalContext } from "@skillup/ui";
import { UpdateModal } from "./Modals/UpdateModal";

export interface Props {
  project: Project;
  refetchList: VoidFunction;
  isFetching: boolean;
  openCancelModal?: VoidFunction;
  openSummonModal?: VoidFunction;
}

const downloadSpreadsheetPDF = (project: Project) => async (): Promise<void> => {
  const { properties } = project;
  try {
    const url = `/v1/pdf/spreadsheet/${properties.uuid}`;
    const filename = `emargement.pdf`;

    await downloadFileAsUser(url, filename, "API", "application/pdf");
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Impossible de télécharger l'entretien",
      type: "error",
    });
  }
};

const showDevConvocationModal = (project: Project) => async () => {
  if (window.confirm("Cette session va être convoqué sans activité et sans email")) {
    try {
      await DataLayer.request({
        body: JSON.stringify({
          rows: project.positionnedTrainees.map((t) => t.row),
        }),
        method: "POST",
        url: `/v1/projects/dev-convocation/${project.properties.uuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Modification enregistrées avec succès !",
        type: "success",
      });
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Erreur lors de la modification du statut de la session.",
        type: "error",
      });
    }
  }
};

const useUpdateModal = generateModalContext();

const Summary = ({ project, refetchList, isFetching, openCancelModal, openSummonModal }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayActionMenu, setDisplayActionMenu] = useState<boolean>(false);
  const updateModalV2 = useUpdateModal();

  const showDateWarning = project.properties.rawState === SESSION_STATES.expired;
  const stateClass = cx({ [styles.warning]: Boolean(showDateWarning) });

  // const intervals = DateUtils.computeForDisplay(project.properties.dates);
  const isProjectConducted =
    project.properties?.rawState === SESSION_STATES.achieved ||
    project.properties?.rawState === SESSION_STATES.summoned;

  const settings = useSettings();
  const { t, i18n } = useTranslation();

  const intervals = TrainingUtils.ScheduleRow.formatIntervalsForDisplay(project.properties.dates, {
    t,
    language: i18n.language,
  });

  const formattedPrice = useMemo(() => {
    const price = User.isCompanyWithTax()
      ? project.training.totalWithTax ?? project.training.total
      : project.training.total;

    return formatMonetary(price ?? 0, {
      currency: settings?.settings?.currency,
      locale: i18n.language,
      decimals: 2,
    });
  }, [project.training, settings, i18n]);

  const closeSession = useCallback(async () => {
    try {
      setIsLoading(true);

      await DataLayer.request({
        method: "POST",
        url: `/v1/projects/close-session/${project.properties.uuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "La session a été clotûrée avec succès",
        type: "success",
      });

      refetchList();
      setIsLoading(false);
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue",
        type: "error",
      });
    }
  }, [project.properties.uuid, setIsLoading, refetchList]);

  const openSession = useCallback(async () => {
    try {
      setIsLoading(true);

      await DataLayer.request({
        method: "POST",
        url: `/v1/projects/open-session/${project.properties.uuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "La session a été ré-ouverte avec succès",
        type: "success",
      });

      refetchList();
      setIsLoading(false);
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue",
        type: "error",
      });
    }
  }, [project.properties.uuid, setIsLoading, refetchList]);

  const computedStateActionWithLabel = useMemo(() => {
    const hasPendingPositionedTrainees = project.positionnedTrainees.length > 0;

    if (!project.properties) {
      return undefined;
    }

    switch (project.properties.rawState) {
      case SESSION_STATES.canceled:
        return {
          label: t("trainings.entity.session.sidepanel.actions.delete.session", {
            defaultValue: "Supprimer la session",
          }),
          action: () => showDeleteConfirmationModal(project, refetchList, t),
          isDisabled: false,
          showOthersActions: false,
        };

      case SESSION_STATES.closed:
        return {
          label: t("trainings.entity.session.sidepanel.actions.reopen.session", {
            defaultValue: "Ré-ouvrir la session",
          }),
          action: openSession,
          isDisabled: false,
          showOthersActions: true,
        };

      case SESSION_STATES.achieved:
        if (hasPendingPositionedTrainees) {
          return {
            label: t("trainings.entity.session.sidepanel.actions.summon.session", {
              defaultValue: "Convoquer la session",
            }),
            action: () => openSummonModal(),
            isDisabled: false,
            showOthersActions: true,
          };
        }

        return {
          label: t("trainings.entity.session.sidepanel.actions.close.session", {
            defaultValue: "Clôturer la session",
          }),
          action: closeSession,
          isDisabled: false,
          showOthersActions: true,
        };

      case SESSION_STATES.summoned:
      case SESSION_STATES.expired:
      case SESSION_STATES.provisional:
        if (hasPendingPositionedTrainees) {
          return {
            label: t("trainings.entity.session.sidepanel.actions.summon.session", {
              defaultValue: "Convoquer la session",
            }),
            action: () => openSummonModal(),
            isDisabled: false,
            showOthersActions: true,
          };
        }

        return {
          label: t("trainings.entity.session.sidepanel.actions.summon.session", {
            defaultValue: "Convoquer la session",
          }),
          action: null,
          isDisabled: true,
          showOthersActions: true,
        };
    }

    return undefined;
  }, [project, closeSession, openSession, refetchList, openSummonModal, t]);

  return (
    <div className={styles.Summary}>
      <div>
        <h3>{project.training.name}</h3>
        <span>
          {t("trainings.entity.session.sidepanel.organization", {
            defaultValue: "par {{ organization }}",
            organization: project.training.organization,
          })}
        </span>
        <div className={stateClass}>
          {showDateWarning && <Icon icon={signWarning} width={15} fill="#4F3B20" />}
          <span>{project.properties.state}</span>
        </div>
      </div>
      <div>
        <div className={styles.tags}>
          <span className={styles.duration}>
            <Icon icon={time} fill="#666" width={16} style={{ marginRight: 5 }} />
            <span>{Duration.formatDurationAsHours(project.training.duration, t)}</span>
          </span>
          <span className={cx(styles.tag, styles.mode)}>{project.training.mode}</span>
        </div>
        <span>{formattedPrice}</span>
      </div>
      <div>
        {intervals.map((interval) => (
          <span key={interval} className={stateClass}>
            {interval}
          </span>
        ))}
      </div>
      <div className={styles.city}>
        {t("trainings.entity.session.sidepanel.city", {
          defaultValue: "À {{ city }}",
          city: project.properties.city,
        })}
      </div>
      {project.properties.room && (
        <div className={styles.room}>
          {t("trainings.entity.session.sidepanel.room", {
            defaultValue: "Salle : {{ room }}",
            room: project.properties.room,
          })}
        </div>
      )}
      {project.trainers?.length > 0 && (
        <div className={styles.areas}>
          {t("trainings.entity.session.sidepanel.trainers", {
            defaultValue: "Formateur",
            count: project.trainers.length,
          })}
          :{" "}
          <ReadMoreReact
            text={project.trainers?.map((t) => t.fullName).join(", ")}
            ideal={100}
            max={100}
            key={project?.trainers.map((t) => t.fullName).join("")}
            readMoreText={t("trainings.entity.session.sidepanel.showMore", {
              defaultValue: "afficher tout",
            })}
          />
        </div>
      )}
      {project.properties.link && (
        <a href={project.properties.link} className={styles.link}>
          {t("trainings.entity.session.sidepanel.link", {
            defaultValue: "Lien de connexion",
          })}
        </a>
      )}
      <div className={styles.areas}>
        <strong>
          {t("trainings.entity.session.sidepanel.areas", {
            defaultValue: "Périmètres:",
          })}
        </strong>{" "}
        <ReadMoreReact
          text={project.areas.map((a) => a.name.replace(/ /g, "\xA0")).join(", ") + "."}
          ideal={200}
          max={200}
          key={project.areas.map((a) => a.name).join("")}
          readMoreText={t("trainings.entity.session.sidepanel.showMore", {
            defaultValue: "afficher tout",
          })}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <InteractiveButton
          strokeIconLeft={calendar}
          label={computedStateActionWithLabel?.label}
          size="small"
          name="summon"
          style={{ margin: 0 }}
          background={
            computedStateActionWithLabel?.isDisabled ? Colors.borderColor : Colors.gradientYellow
          }
          color={computedStateActionWithLabel?.isDisabled ? "#fff" : Colors.textColor}
          onClick={isLoading || isFetching ? () => null : computedStateActionWithLabel?.action}
        />
        <>
          {computedStateActionWithLabel?.showOthersActions && (
            <div
              className={cx(styles.showMore, { [styles.clicked]: displayActionMenu })}
              onClick={() => setDisplayActionMenu(!displayActionMenu)}
            >
              <span>
                {t("trainings.entity.session.sidepanel.otherActions", {
                  defaultValue: "Autres actions",
                })}
              </span>
              <Icon strokeIcon={chevronBottom} width={12} />
            </div>
          )}

          {displayActionMenu && (
            <>
              <div className={styles.background} onClick={() => setDisplayActionMenu(false)} />
              <DropDownActionMenu
                actions={[
                  ...(SESSION_STATES.closed !== project.properties?.rawState &&
                  SESSION_STATES.canceled !== project.properties?.rawState
                    ? [
                        {
                          action: updateModalV2.openModal,
                          label: t("trainings.entity.session.sidepanel.edit", {
                            defaultValue: "Modifier la session",
                          }),
                        },
                        {
                          action: () => openCancelModal(),
                          label: t("trainings.entity.session.sidepanel.cancel", {
                            defaultValue: "Annuler la session",
                          }),
                        },
                      ]
                    : []),
                  ...(isProjectConducted
                    ? [
                        {
                          action: downloadSpreadsheetPDF(project),
                          label: t("trainings.entity.session.sidepanel.download", {
                            defaultValue: "Télécharger la feuille d'émargement",
                          }),
                        },
                      ]
                    : []),
                  ...(User.isSkillupDeveloper() &&
                  project.properties?.rawState !== SESSION_STATES.canceled
                    ? [
                        {
                          action: showDevConvocationModal(project),
                          label: "[DEV] Convoquer",
                        },
                      ]
                    : []),
                ]}
              />
            </>
          )}
        </>
      </div>
      <UpdateModal
        onClose={() => {
          updateModalV2.closeModal();
          refetchList();
        }}
        isOpen={updateModalV2.isOpen}
        project={project}
        openSummonModal={openSummonModal}
      />
    </div>
  );
};

export default Summary;
