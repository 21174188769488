import { MouseEvent, CSSProperties, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { formatMonetary, FormatDate, ParseDate } from "@skillup/shared-utils";

import { Column, RowColumn } from "components/DataTable/types";
import { defaultColumnWidth } from "constants/dataTable";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import styles from "../../DataTableStyles.module.scss";
import CellDropDown from "./CellDropDown";
import bodyCellStyles from "./BodyCell.module.scss";

export interface IProps {
  className?: string;
  hovered: boolean;
  data: RowColumn;
  lazyLoading: boolean;
  editable: boolean;
  type: string;
  decimalPrices: boolean;
  width: CSSProperties["width"];
  onClick: (uuid: string) => void;
  column: Column;
  rowUuid: string;
}

export interface IState {
  defaultValue: RowColumn["value"];
}

const StatusComponent = ({ data }) => {
  const warningStatus = [
    // "Statut" column
    "unmanaged_by_skillup", //HORS SKILLUP
    "without_session", //SANS SESSION
    "pending_completion", // A COMPLETER
    "pending_summon", // A CONVOQUER
    // "Etat" column
    "approved_by_hr", // A INSCRIRE
    "positioning", // CHOIX DE SESSION EN COURS
    "pending_for_user_validation", // vALIDATION EN COURS
    "done", // EVALUATION EN COURS
    "pending_for_manager", // EN ATTENTE DE VALIDATION MANAGER
    "approved_by_manager", // A VALIDER RH
    "pending", // A REQUALIFER
    "denied_by_hr", // REFUSÉE
    "denied_by_manager", // REFUSÉE
  ];
  const okStatus = [
    // "Statut" column
    "with_session", // AVEC SESSION
    "planned", // EN COURS,
    "in_progress", // EN COURS
    undefined, // RÉALISÉ

    // "Etat" column
    "positioned", // INSCRIT
    "won", // RÉALISÉ / EN COURS / A VENIR
    "attended", // RÉALISÉ
    "closed", // RÉALISÉ
  ];
  const inactiveStatus = [
    // "Etat" column
    "cancelling", // ANNULATION EN COURS
    "cancellation_pending", // ANNULATION EN COURS
    "lost", // ANNULÉE,
    "cancelled_by_hr", // ANNULÉE
  ];

  return (
    <p
      className={cx(bodyCellStyles.badge, {
        [bodyCellStyles.yellow]: warningStatus.includes(data.rawValue),
        [bodyCellStyles.green]: okStatus.includes(data.rawValue),
        [bodyCellStyles.gray]: inactiveStatus.includes(data.rawValue),
      })}
    >
      {data.value.toUpperCase()}
    </p>
  );
};

export default function BodyCell(props: IProps) {
  const { i18n } = useTranslation();
  const settings = useSettings();

  const [defaultValue, setDefaultValue] = useState(props.data?.value);

  const { editable, type, width, data, hovered, column, lazyLoading } = props;

  useEffect(() => {
    if (props.data) {
      setDefaultValue(props.data?.value);
    }
  }, [props.data]);

  const handleClick = (_e: MouseEvent): void => {
    if (props.onClick && !props.editable && props.type !== "url") {
      props.onClick(props.rowUuid);
    }
  };

  let classNames = cx(styles.cell, {
    [styles.colHovered]: hovered,
    [styles.editable]: editable,
  });

  if (lazyLoading) {
    return (
      <div style={{ width: width || defaultColumnWidth }} className={cx(classNames)}>
        <div className={styles.loading} />
      </div>
    );
  }

  let content;

  switch (type) {
    case "text":
    case "date":
      if (column.binding === "seniorityDate" && defaultValue) {
        content = FormatDate.ToStringFormat(
          ParseDate.FromParsableJS(defaultValue as string),
          FormatDate.getDayMonthYearFormat(i18n.language)
        );
        break;
      }

      content = defaultValue ?? "";
      break;
    case "select": {
      if (column.binding === "rowStatus" || column.binding === "state") {
        content = <StatusComponent data={data} />;
        break;
      }

      if (!editable) {
        if (column.options) {
          const optionSelected = column.optionSelected || "default";
          // @ts-ignore
          if (column.options[optionSelected]) {
            // @ts-ignore
            const option = column.options[optionSelected].find((o) => o.value === defaultValue);
            content = (option && (option.label || option.value)) || "";
          } else content = defaultValue;
        } else {
          content = defaultValue;
        }
      } else {
        const optionSelected = column.optionSelected || "default";
        content = (
          <CellDropDown
            defaultValue={data.value}
            // @ts-ignore
            onSelect={(e) => this.updateDropDownValue(data.value, e)}
            // @ts-ignore
            options={column.options[optionSelected]}
          />
        );
        classNames = cx(classNames, styles.displayFlex);
      }
      break;
    }
    case "url":
      // TODO: 🤮
      // @ts-ignore
      if (data.value.url) {
        // @ts-ignore
        content = data.value.isExternal ? (
          // @ts-ignore
          <a href={data.value.url} target="_blank" rel="noopener noreferrer">
            {
              // @ts-ignore
              data.value.label
            }
          </a>
        ) : (
          // @ts-ignore
          <Link to={data.value.url} target="_blank">
            {
              // @ts-ignore
              data.value.label
            }
          </Link>
        );
      } else {
        content = defaultValue;
      }
      break;
    case "number":
      content = Number(data.value);
      break;
    case "price":
    case "monetary":
      const formatOptions = props.decimalPrices ? { decimals: 2 } : { decimals: 0 };

      // @ts-ignore
      content = isNaN(data.value)
        ? data.value
        : formatMonetary(Number(data.value), {
            locale: i18n.language,
            currency: settings?.settings?.currency,
            fixedDecimals: true,
            ...formatOptions,
          });
      break;
    default:
      content = String(data.value);
      break;
  }

  return (
    <div
      style={{ width: width || defaultColumnWidth }}
      onClick={handleClick}
      className={classNames}
    >
      {content}
    </div>
  );
}
