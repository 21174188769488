import { TranslationType } from "hooks/useTranslation";
import type { Child, ItemError } from "../types";

export const getChildErrors = (
  child: Child,
  t: TranslationType,
  choicesForOrdinalTargets?: string[]
) => {
  const errors: ItemError[] = [];
  switch (child.kind) {
    case "title":
      if (!child.title)
        errors.push({
          structure: t("interviews.templateBuilder.errors.child.title.structure", {
            defaultValue: "Vous n'avez pas défini de libellé",
          }),
          header: t("interviews.templateBuilder.errors.child.title.header", {
            defaultValue: "Titre sans libellé",
          }),
        });
      break;
    case "html":
      if (!child.variant)
        errors.push({
          structure: t("interviews.templateBuilder.errors.child.html.variant.structure", {
            defaultValue: "Vous n'avez pas renseigné de style",
          }),
          header: t("interviews.templateBuilder.errors.child.html.variant.header", {
            defaultValue: "Texte d'information sans style",
          }),
        });
      if (!child.content)
        errors.push({
          structure: t("interviews.templateBuilder.errors.child.html.content.structure", {
            defaultValue: "Vous n'avez pas renseigné de contenu",
          }),
          header: t("interviews.templateBuilder.errors.child.html.content.header", {
            defaultValue: "Texte d'information sans contenu",
          }),
        });
      break;
    case "userSpecificData":
      if (!child.userSpecificDataKey)
        errors.push({
          structure: t("interviews.templateBuilder.errors.child.userSpecificDataKey.structure", {
            defaultValue: "Vous n'avez pas renseigné de clé",
          }),
          header: t("interviews.templateBuilder.errors.child.userSpecificDataKey.header", {
            defaultValue: "Donnée utilisateur sans clé",
          }),
        });
      break;
    case "interviewsHistory":
      if (!child.maxAge)
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.interviewsHistory.maxAge.structure",
            {
              defaultValue: "Vous n'avez pas défini de durée d'historique",
            }
          ),
          header: t("interviews.templateBuilder.errors.child.interviewsHistory.maxAge.header", {
            defaultValue: "Historique d'entretien sans durée définie",
          }),
        });
      if (!child.ageUnit)
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.interviewsHistory.ageUnit.structure",
            {
              defaultValue: "Vous n'avez pas défini d'unité de durée",
            }
          ),
          header: t("interviews.templateBuilder.errors.child.interviewsHistory.ageUnit.header", {
            defaultValue: "Historique d'entretien sans unité de durée",
          }),
        });
      break;
    case "trainingsHistory":
      if (!child.maxAge)
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.trainingsHistory.maxAge.structure",
            {
              defaultValue: "Vous n'avez pas défini de durée d'historique",
            }
          ),
          header: t("interviews.templateBuilder.errors.child.trainingsHistory.maxAge.header", {
            defaultValue: "Historique de formation sans durée définie",
          }),
        });
      if (!child.ageUnit)
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.trainingsHistory.ageUnit.structure",
            {
              defaultValue: "Vous n'avez pas défini d'unité de durée",
            }
          ),
          header: t("interviews.templateBuilder.errors.child.trainingsHistory.ageUnit.header", {
            defaultValue: "Historique de formation sans unité de durée",
          }),
        });
      break;
    case "question":
      if (child.required && child.disallow) {
        if (child.disallow.employee?.reply && child.disallow.manager?.reply) {
          errors.push({
            structure: t("interviews.templateBuilder.errors.child.question.reply.structure", {
              defaultValue: "Vous n'avez pas défini de répondant",
            }),
            header: t("interviews.templateBuilder.errors.child.question.reply.header", {
              defaultValue: "Question avec réponse requise sans répondant selectionné",
            }),
          });
        }
      }
      switch (child.type) {
        case "text":
          if (!child.label)
            errors.push({
              structure: t("interviews.templateBuilder.errors.child.question.label.structure", {
                defaultValue: "Vous n'avez pas défini de libellé",
              }),
              header: t("interviews.templateBuilder.errors.child.question.text.label.header", {
                defaultValue: "Question ouverte sans libellé",
              }),
            });
          break;
        case "radio":
        case "checkbox":
          if (!child.label)
            errors.push({
              structure: t("interviews.templateBuilder.errors.child.question.label.structure", {
                defaultValue: "Vous n'avez pas défini de libellé",
              }),
              header: t("interviews.templateBuilder.errors.child.question.choices.label.header", {
                defaultValue: "Question à choix multiple sans libellé",
              }),
            });
          if (child.choices.length === 0)
            errors.push({
              structure: t(
                "interviews.templateBuilder.errors.child.question.choices.empty.structure",
                {
                  defaultValue: "Vous n'avez pas défini de choix de réponse",
                }
              ),
              header: t("interviews.templateBuilder.errors.child.question.choices.empty.header", {
                defaultValue: "Question à choix multiple sans choix de réponse",
              }),
            });
          break;
      }
      break;
    case "targets":
      if (child.type === "next" || child.isCreateOptionsVisible) {
        if (!(child.restrictScaleTo === "numeric")) {
          if (!choicesForOrdinalTargets || choicesForOrdinalTargets.length === 0) {
            errors.push({
              structure: t(
                "interviews.templateBuilder.errors.child.targets.emptyChoices.structure",
                {
                  defaultValue:
                    "Vous n'avez pas défini d'echelle d'évaluation pour les objectifs qualitatifs",
                }
              ),
              header: t("interviews.templateBuilder.errors.child.targets.emptyChoices.header", {
                defaultValue: "Aucune échelle d'évaluation définie pour les objectifs qualitatifs",
              }),
            });
          }
        }
        const disallow = child.disallow;
        if (disallow && disallow.employee.creation && disallow.manager.creation) {
          errors.push({
            structure: t(
              "interviews.templateBuilder.errors.child.targets.emptyCreation.structure",
              {
                defaultValue: "Vous n'avez pas accordé de droits de création d'objectifs",
              }
            ),
            header: t("interviews.templateBuilder.errors.child.targets.emptyCreation.header", {
              defaultValue: "Création d'objectifs sans droit de création accordé aux participants",
            }),
          });
        }
      }
      if (child.type === "current" && child.reviewType === undefined) {
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.targets.emptyReviewType.structure",
            {
              defaultValue: "Vous n'avez pas défini le type de revue",
            }
          ),
          header: t("interviews.templateBuilder.errors.child.targets.emptyReviewType.header", {
            defaultValue: "Revue des objectifs sans type de revue défini",
          }),
        });
      }
      break;
    case "table":
      checkTableChildErrors(child, errors, t);
      break;
    case "upload":
      if (child.disallow) {
        if (child.disallow.employee?.upload && child.disallow.manager?.upload) {
          errors.push({
            structure: t("interviews.templateBuilder.errors.child.upload.structure", {
              defaultValue: "Vous n'avez pas selectionné de participant pour partager un fichier",
            }),
            header: t("interviews.templateBuilder.errors.child.upload.header", {
              defaultValue: "Partage de fichiers sans participant selectionné",
            }),
          });
        }
      }
      break;
    case "personnalisableGuidelines":
      if (!child.description && !child.richDescription) {
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.description.structure",
            {
              defaultValue: "Vous n'avez pas défini de texte d'introduction",
            }
          ),
          header: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.description.header",
            {
              defaultValue: "Introduction sans texte défini",
            }
          ),
        });
      }
      if (!child.employee.title) {
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.employee.structure",
            {
              defaultValue: "Vous n'avez pas défini de libellé collaborateur",
            }
          ),
          header: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.employee.header",
            {
              defaultValue: "Introduction sans libellé collaborateur défini",
            }
          ),
        });
      }
      if (!child.manager.title) {
        errors.push({
          structure: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.manager.structure",
            {
              defaultValue: "Vous n'avez pas défini de libellé responsable",
            }
          ),
          header: t(
            "interviews.templateBuilder.errors.child.personnalisableGuidelines.manager.header",
            {
              defaultValue: "Introduction sans libellé responsable défini",
            }
          ),
        });
      }
      break;
    default:
      break;
  }
  return errors.length > 0 ? errors : undefined;
};

function checkTableChildErrors(child: Child, errors: ItemError[], t: TranslationType) {
  if (child.kind !== "table") return;
  if (!child.rowsCount || child.rowsCount === 0)
    errors.push({
      structure: t("interviews.templateBuilder.errors.child.table.emptyRows.structure", {
        defaultValue: "Vous n'avez pas renseigné le nombre de lignes",
      }),
      header: t("interviews.templateBuilder.errors.child.table.emptyRows.header", {
        defaultValue: "Tableau sans lignes",
      }),
    });

  const tableContentErrors = { columnType: 0, emptyChoice: 0, fixedValue: 0 };
  child.columns.forEach((column) => {
    if (!column.type) tableContentErrors.columnType++;
    if (column.type === "dropdown") {
      if (column?.choices?.length === 0) tableContentErrors.emptyChoice++;
    } else if (column.type === "fixed") {
      const emptyLines = child.rowsCount - column.values?.length;
      if (emptyLines > 0) tableContentErrors.fixedValue++;
    }
  });
  setColumnsErrors(tableContentErrors, errors, t);
}

function setColumnsErrors(tableContentErrors, errors, t) {
  if (tableContentErrors.columnType > 0)
    errors.push({
      structure: t("interviews.templateBuilder.errors.child.table.emptyTypes.structure", {
        defaultValue: "Vous n'avez pas défini de type pour {{ count }} colonne",
        defaultValue_other: "Vous n'avez pas défini de type pour {{ count }} colonnes",
        count: tableContentErrors.columnType,
      }),
      header: t("interviews.templateBuilder.errors.child.table.emptyTypes.structure", {
        defaultValue: "{{ count }} colonne de tableau sans type",
        defaultValue_other: "{{ count }} colonnes de tableau sans type",
        count: tableContentErrors.columnType,
      }),
    });
  if (tableContentErrors.emptyChoice > 0)
    errors.push({
      structure: t("interviews.templateBuilder.errors.child.table.emptyChoices.structure", {
        defaultValue: "Vous n'avez pas défini de choix dans {{ count }} colonne",
        defaultValue_other: "Vous n'avez pas défini de choix dans {{ count }} colonnes",
        count: tableContentErrors.emptyChoice,
      }),
      header: t("interviews.templateBuilder.errors.child.table.emptyChoices.structure", {
        defaultValue: "{{ count }} colonne du tableau sans choix de réponse",
        defaultValue_other: "{{ count }} colonnes du tableau sans choix de réponse",
        count: tableContentErrors.emptyChoice,
      }),
    });
  if (tableContentErrors.fixedValue > 0)
    errors.push({
      structure: t("interviews.templateBuilder.errors.child.table.fixedValue.structure", {
        defaultValue: "Vous n'avez pas défini de valeur(s) fixe(s) pour {{ count }} colonne",
        defaultValue_other: "Vous n'avez pas défini de valeur(s) fixe(s) pour {{ count }} colonnes",
        count: tableContentErrors.fixedValue,
      }),
      header: t("interviews.templateBuilder.errors.child.table.fixedValue.structure", {
        defaultValue: "{{ count }} colonne du tableau sans valeur(s) fixe(s) définie(s)",
        defaultValue_other: "{{ count }} colonnes du tableau sans valeur(s) fixe(s) définie(s)",
        count: tableContentErrors.fixedValue,
      }),
    });
}
