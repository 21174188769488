import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Flex, Text } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import EmptyView from "containers/Supervisor/components/Empty";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { usePeopleReviewCampaigns } from "services/peopleReview/campaign/usePeopleReviewCampaigns";

import { PeopleReviewRoutes } from "../router";
import { PPRCampaignCard } from "../../components";

import styles from "../../people-review.module.scss";

export function PPRCampaignsList() {
  const history = useHistory();
  const { t } = useTranslation();
  const { campaigns } = usePeopleReviewCampaigns();

  const { closedCampaigns, inProgressCampaigns, noPeopleReviews } = useMemo(
    () => ({
      closedCampaigns: campaigns.filter(({ state }) => state === "closed"),
      inProgressCampaigns: campaigns.filter(({ state }) => state !== "closed"),
      noPeopleReviews: campaigns.length === 0,
    }),
    [campaigns]
  );

  return (
    <DSLayout
      className={styles.layout}
      title={t("peoplereviews.list.title", { defaultValue: "People Review" })}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              onClick={() => history.push(PeopleReviewRoutes.CampaignCreation)}
              label={t("peoplereviews.list.button.createPeopleReview", {
                defaultValue: "Nouvelle campagne",
              })}
            />
          ),
        },
      ]}
    >
      <Flex
        width="100%"
        overflowY="auto"
        alignItems="center"
        flexDirection="column"
        justifyContent={noPeopleReviews ? "center" : "flex-start"}
      >
        {noPeopleReviews ? (
          <EmptyView
            buttonClick={() => history.push(PeopleReviewRoutes.CampaignCreation)}
            buttonLabel={t("peoplereviews.list.label.createPeopleReview", {
              defaultValue: "Créer une campagne de people review",
            })}
            message={t("peoplereviews.list.label.noPeopleReviewCreate", {
              defaultValue: "Vous n'avez pas encore créé de people review.",
            })}
          />
        ) : (
          <Flex
            gap="l"
            padding="m"
            width="100%"
            maxWidth="40rem"
            flexDirection="column"
            marginHorizontal="auto"
          >
            <Flex gap="s" flexDirection="column">
              <Text espaceFont="h3" color="plainText-onLight-lighter">
                {t("peoplereview.campaigns.inProgress.title", {
                  defaultValue: "Campagnes en cours",
                })}
              </Text>

              {inProgressCampaigns.map((campaign) => (
                <PPRCampaignCard key={campaign.id} campaign={campaign} />
              ))}
            </Flex>

            <Flex gap="s" flexDirection="column">
              <Text espaceFont="h3" color="plainText-onLight-lighter">
                {t("peoplereview.campaigns.closed.title", { defaultValue: "Campagnes clôturées" })}
              </Text>
              {closedCampaigns.map((campaign) => (
                <PPRCampaignCard key={campaign.id} campaign={campaign} />
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    </DSLayout>
  );
}
