import { useMemo, useState } from "react";

import {
  DSFormGroupTextInput,
  DSAvatarGroup,
  DSModal,
  DSTextInput,
  Select,
  DSTooltip,
} from "@skillup/ui";
import { FieldRoutesType, SessionUser } from "@skillup/espace-rh-bridge";
import { useQuery } from "@tanstack/react-query";

import { PartialCancelParams } from "services/sessions/partialCancel";
import { buildRequest } from "utils/buildRequest";

import { Project } from "services/sessions";
import { getUserInitials } from "utils/User";
import useConfirmAction from "hooks/useConfirmAction";
import useTranslation from "hooks/useTranslation";

import AttendeeNotificationAlert from "./AttendeeNotificationAlert";
import styles from "./CancelModal.module.scss";

const getField = async () => {
  try {
    const data = await buildRequest<FieldRoutesType.GetV2>({
      method: "GET",
      path: `/fields-v2`,
      query: {
        bindings: ["deniedOrCancelledReason"],
      },
    })();

    return data;
  } catch (err) {
    return [];
  }
};

type Props = {
  project: Project;
  trainees: Array<SessionUser>;
  onBack: () => void;
  onClose: () => void;
  partialCancel: (params: PartialCancelParams) => Promise<void>;
};

export const CancelModal = ({ project, trainees, onBack, onClose, partialCancel }: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>();
  const [deniedOrCancelledReason, setCancelReason] = useState<string>(null);

  const { data: [field] = [] } = useQuery(["denied-or-Cancelled-field"], () => getField());
  const fieldOptions = useMemo(
    () => field?.options.map(({ value, key }) => ({ label: value, value: key })),
    [field]
  );

  const onConfirm = () => {
    return partialCancel({
      rowsUuid: trainees.map((trainee) => trainee.row),
      message,
      deniedOrCancelledReason,
    }).then(() => {
      onClose();
    });
  };

  const { current, send } = useConfirmAction(onConfirm, onBack, onClose);

  return (
    <DSModal isOpen className={styles.CancelModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.manage.users.cancel.title", {
            count: trainees.length,
            defaultValue: "Annuler la participation de {{count}} stagiaire",
          })}
        />
        <DSModal.Header.SubHeader>
          <DSAvatarGroup
            size="S"
            tooltipDirection="bottom"
            maxItems={5}
            totalItems={trainees.length}
            users={trainees.map((trainee) => {
              return {
                uuid: trainee.uuid,
                fullName: trainee.fullName,
                initials: getUserInitials(trainee),
              };
            })}
          />
        </DSModal.Header.SubHeader>
      </DSModal.Header>

      <DSModal.Content>
        <p>
          {t("trainings.manage.users.cancel.description", {
            count: trainees.length,
            defaultValue:
              "Si vous confirmez cette action, le stagiaire ne pourra pas être réinscrit à d'autres sessions.",
          })}
        </p>
        <AttendeeNotificationAlert
          className={styles.attendeeNotificationAlert}
          type="cancel"
          project={project}
          trainees={trainees}
        />
        <DSFormGroupTextInput
          label={t("trainings.schedules.cancel.reason.label", {
            defaultValue: "Motif d'annulation",
          })}
          required
          placeholder={t("trainings.schedules.cancelOrPostpone.reason.placeholder", {
            defaultValue: "Choisir un motif",
          })}
        >
          <Select onChange={setCancelReason} options={fieldOptions} />
        </DSFormGroupTextInput>
        <DSFormGroupTextInput
          label={t("trainings.schedules.cancel.modal.comment.label", {
            defaultValue: "Commentaire interne (facultatif)",
          })}
          assistiveText={t("trainings.schedules.cancel.modal.comment.assistiveText", {
            defaultValue: `Le commentaire ci-dessus ne sera pas transmis au collaborateur mais figurera dans l'activité de la ligne`,
          })}
        >
          <DSTextInput
            autoFocus={true}
            placeholder={t("trainings.schedules.cancel.modal.comment.placeholder", {
              defaultValue: "Laissez un commentaire dans votre activité",
            })}
            onChange={setMessage}
            name="message"
          />
        </DSFormGroupTextInput>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          disabled={current.context.loading}
          label={t("trainings.manage.users.form.goback", { defaultValue: "Retour" })}
          onClick={() => send("BACK")}
        />
        <DSTooltip
          label={t("trainings.manage.users.form.cancelOrPostpone.tooltip.require", {
            defaultValue:
              "Tous les champs sont obligatoires (signalés par une astérisque rouge) doivent être renseignés",
          })}
          disabled={!!deniedOrCancelledReason}
        >
          <DSModal.Footer.PrimaryButton
            loading={current.context.loading}
            label={t("trainings.manage.users.form.cancel", {
              defaultValue: "Confirmer l'annulation",
            })}
            disabled={!deniedOrCancelledReason}
            onClick={() => send("CONFIRM")}
          />
        </DSTooltip>
      </DSModal.Footer>
    </DSModal>
  );
};
