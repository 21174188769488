import { ReadOnlyHistoricalDataConfiguration } from "./ReadOnlyHistoricalDataConfiguration/ReadOnlyHistoricalDataConfiguration";
import { EditHistoricalDataConfiguration } from "./EditHistoricalDataConfiguration/EditHistoricalDataConfiguration";
import { useState } from "react";
import { HistoricalDataConfiguration as THistoricalDataConfiguration } from "../../services";
import useTranslation from "hooks/useTranslation";

type HistoricalDataConfigurationProps = {
  configuration: THistoricalDataConfiguration;
  saveHistoricalDataConfiguration: (config: THistoricalDataConfiguration) => void;
};

export const HistoricalDataConfiguration = (props: HistoricalDataConfigurationProps) => {
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const historicalDataLabelMap: Record<keyof THistoricalDataConfiguration, string> = {
    salaryAndJob: t("employeeProfile.historicalData.salaryAndJob", {
      defaultValue: "Salaire et postes occupés",
    }),
    primesBonus: t("employeeProfile.historicalData.primesBonus", {
      defaultValue: "Primes/Bonus",
    }),
  };

  switch (isEditable) {
    case true:
      return (
        <EditHistoricalDataConfiguration
          configuration={props.configuration}
          historicalDataLabelMap={historicalDataLabelMap}
          saveHistoricalDataConfiguration={props.saveHistoricalDataConfiguration}
          disabledEdit={() => setIsEditable(false)}
        />
      );
    default:
      return (
        <ReadOnlyHistoricalDataConfiguration
          configuration={props.configuration}
          historicalDataLabelMap={historicalDataLabelMap}
          enableEdit={() => setIsEditable(true)}
        />
      );
  }
};
