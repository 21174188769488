import { RenameCategoryParams, TargetCategoryForConfiguration } from "@skillup/espace-rh-bridge";
import { DSModal } from "@skillup/ui";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTargetCategories } from "../hooks/useTargetCategories";
import { TranslationType } from "hooks/useTranslation";
import {
  TargetGoal,
  TargetLabel,
  TargetProperties,
  TargetScale,
  Scale,
} from "./CreateOrRenameCategoryCommons";
import styles from "./CreateOrRenameCategoryCommons.module.scss";

// SKL-2592 Le ticket renomme une catégorie d'objectifs. Mais dans la maquette, on prépare des mofications plus larges.
// Cette modale renomme mais prépare aussi les modifications futures.
export const RenameCategoryModal = ({
  targetCategory,
  targetCategoriesLabels,
  close,
  t,
}: {
  targetCategory: Pick<TargetCategoryForConfiguration, "uuid" | "label">;
  targetCategoriesLabels: string[];
  close: () => void;
  t: TranslationType;
}) => {
  const [categoryLabel, setCategoryLabel] = useState<string | undefined>(targetCategory.label);
  const [scale, setScale] = useState<Scale>("numeric");
  const [goal, setGoal] = useState<boolean>(false);
  const [period, setPeriod] = useState<boolean>(false);
  const [weight, setWeight] = useState<boolean>(false);

  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const { renameCategory } = useTargetCategories();

  const onTargetSave = async (params: RenameCategoryParams & { t: TranslationType }) => {
    setIsLoading(true);
    const response = await renameCategory(params);
    if (response) {
      addToast(
        t("supervisor.interviews.configuration.modal.edit.success", {
          defaultValue: "Catégorie modifiée",
        }),
        { appearance: "success" }
      );
    } else {
      addToast(
        t("supervisor.interviews.configuration.modal.edit.error", {
          defaultValue: "Échec lors de la modification de la catégorie",
        }),
        {
          appearance: "error",
        }
      );
    }
    close();
    setIsLoading(false);
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={close}>
        <DSModal.Header.Title
          title={t("supervisor.interviews.configuration.modal.edit.header", {
            defaultValue: "Modifier une catégorie d'objectifs",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <TargetLabel
          targetCategoriesLabels={targetCategoriesLabels}
          categoryLabel={categoryLabel}
          setCategoryLabel={setCategoryLabel}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
          t={t}
        />
        <TargetScale scale={scale} setScale={setScale} disabled />
        {scale !== "ordinal" && <TargetGoal goal={goal} setGoal={setGoal} disabled />}
        <TargetProperties
          period={period}
          setPeriod={setPeriod}
          weight={weight}
          setWeight={setWeight}
          disabled
        />
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("supervisor.interviews.configuration.modal.cancel", { defaultValue: "Annuler" })}
          onClick={close}
        />
        <DSModal.Footer.PrimaryButton
          label={t("supervisor.interviews.configuration.modal.edit.confirm", {
            defaultValue: "Enregistrer les modifications",
          })}
          onClick={() =>
            onTargetSave({
              uuid: targetCategory.uuid,
              label: categoryLabel,
              // scale,
              // goal,
              // period,
              // weight,
              t,
            })
          }
          disabled={!categoryLabel || categoryLabel.length === 0 || isDuplicate}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
