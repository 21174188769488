import { FieldsRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

export function editReviewCriteria(fieldID: string) {
  return async (payload: FieldsRoutes.EditReviewCriteria['payload']) => {
    const result = await buildRequest<FieldsRoutes.EditReviewCriteria>({
      method: "PUT",
      path: `/review-criteria/{fieldID}/edit`,
      target: "CORE_HR",
      params: { fieldID },
      payload
    })();

    return result;
  };
}
