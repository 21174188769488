import type { InterviewWithStates } from "@skillup/types";
import { useCallback } from "react";
import { useToggle } from "react-use";

import InteractiveButton from "components/InteractiveButton";
import useTranslation from "hooks/useTranslation";
import Colors from "uiAssets/Colors";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

interface IProps {
  readonly campaignUuid: string;
  readonly interview: InterviewWithStates;
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const ReopenModal = ({ campaignUuid, interview, resync }: IProps): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();

  const sendReopen = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/campaigns/${campaignUuid}/interviews/${interview.uuid}/reopen`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: t("campaign.reopenModel.interviewReopened", {
          defaultValue: "Entretien rouvert.",
        }),
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("campaign.reopenModel.cantReopenInterview", {
          defaultValue: "Impossible de réouvrir l'entretien",
        }),
        type: "error",
      });
    } finally {
      resync();
      toggleLoading(false);
      Acta.dispatchEvent("closeModal");
    }
  }, [resync, toggleLoading, campaignUuid, interview, t]);

  return (
    <div style={{ fontSize: 14, padding: 20 }}>
      <p
        dangerouslySetInnerHTML={{
          __html: t("campaign.reopenModel.alert", {
            defaultValue:
              "La réouverture de l’entretien permettra à <strong>{{employeeFullName}} </strong> et <strong>{{managerFullName}}</strong> de le modifier à nouveau.",
            employeeFullName: interview.employee.fullName,
            managerFullName: interview.manager.fullName,
          }),
        }}
      />
      <footer style={{ display: "flex", padding: 20 }}>
        <InteractiveButton
          label={t("campaign.commons.previous", {
            defaultValue: "Précécent",
          })}
          onClick={() => Acta.dispatchEvent("closeModal")}
          size="small"
          background="#fff"
          color={Colors.blue}
        />
        <InteractiveButton
          loading={loading}
          label={t("campaign.commons.reopen", {
            defaultValue: "Rouvrir",
          })}
          title="re-open-interview"
          onClick={sendReopen}
          size="small"
        />
      </footer>
    </div>
  );
};

export default ReopenModal;
