import { useMemo } from "react";

import { TranslationType } from "hooks/useTranslation";

import generateColumns from "./generateColumns";
import generateTableRows from "./generateTableRows";
import { Collaborator } from "../../../api/getUsers";
import { CollaboratorRow } from "./parseCollaboratorIntoRow";

type Props = {
  maxLevel: number;
  t: TranslationType;
  collaborators?: Array<Collaborator>;
  actions: { editRowCollaborator: (row: CollaboratorRow) => void };
};

export default function useTableData({ actions, collaborators, maxLevel, t }: Props) {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({ actions, collaborators, maxLevel, t }),
      rows: generateTableRows(collaborators),
    }),
    [collaborators, maxLevel, t, actions]
  );

  return tableData;
}
