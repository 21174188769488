import { useState } from "react";

import { DSButton } from "@skillup/ui";
import { Text, Grid, Flex } from "@skillup/design-system";

import User from "utils/User";
import { plural } from "utils/locale";
import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import { useActions, type Action } from "services/peopleReview";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { PR_Management_Routes } from "../router";
import { ActionCard } from "../../components/cards";
import {
  EditActionModal,
  CreateActionModal,
  ArchiveActionModal,
  ArchivedActionsModal,
} from "../../components/modals";

import styles from "./Actions.module.scss";

export const ActionsList = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<ActionsListState>({ step: "idle" });

  const { actions, archivedActions } = useActions();

  return (
    <DSLayout
      activeLayout={"Actions"}
      title="Configuration des People Reviews"
      layouts={[
        {
          tab: {
            id: "Modèles de campagnes",
            label: "Modèles de campagnes",
            url: PR_Management_Routes.TemplatesList,
          },
        },
        {
          primaryButton: (
            <DSNewHeaderButton
              label="Créer une nouvelle action"
              onClick={() => setState({ step: "creating" })}
            />
          ),
          tab: {
            id: "Actions",
            label: "Actions",
            url: PR_Management_Routes.ActionsList,
          },
        },
        User.isSkillupDeveloper() && {
          tab: {
            id: "Monitoring des campagnes",
            label: "Campagnes",
            url: PR_Management_Routes.DevMonitoringCampaigns,
          },
        },
      ]}
    >
      <Flex
        padding="m"
        flexDirection="column"
        className={styles.actions}
        justifyContent="space-between"
      >
        <CurrentModal state={state} closeModal={() => setState({ step: "idle" })} />

        <Grid
          gap="s"
          width="100%"
          gridTemplateColumns={{
            columnNumbers: "auto-fill",
            columnWidth: "minmax(12rem, 1fr)",
          }}
        >
          {actions?.map((action) => (
            <ActionCard
              key={action.uuid}
              label={action.label}
              color={action.color}
              assignedToTemplates={action.assignedToTemplates}
              onEdit={() => setState({ action, step: "editing" })}
              onArchive={() => setState({ action, step: "archiving" })}
            />
          ))}
        </Grid>
        <Flex marginTop="m">
          {archivedActions?.length > 0 ? (
            <DSButton
              buttonSize="S"
              emphasis="Low"
              onClick={() => setState({ step: "unarchive" })}
              label={t("supervisor.people-review-management.actions.displayArchivedAction", {
                defaultValue: "Voir {{label}}",
                label: plural(archivedActions?.length, "l'action archivée", {
                  pluralText: "les %n actions archivées",
                }),
              })}
            />
          ) : (
            <Text paddingVertical="xxs" paddingHorizontal="s" espaceFont="captionBold">
              {t("supervisor.people-review-management.actions.noActionArchived", {
                defaultValue: "Aucune action archivée",
              })}
            </Text>
          )}
        </Flex>
      </Flex>
    </DSLayout>
  );
};

type ActionsListState =
  | { step: "idle" }
  | { step: "creating" }
  | { step: "unarchive" }
  | { action: Action; step: "editing" }
  | { action: Action; step: "archiving" };

type VoidFunction = () => void;

interface CurrentModalProps {
  state: ActionsListState;
  closeModal: VoidFunction;
}

function CurrentModal({ closeModal, state }: CurrentModalProps) {
  switch (state.step) {
    case "archiving":
      return <ArchiveActionModal isOpen action={state.action} closeModal={closeModal} />;
    case "editing":
      return <EditActionModal isOpen action={state.action} closeModal={closeModal} />;
    case "creating":
      return <CreateActionModal isOpen closeModal={closeModal} />;
    case "unarchive":
      return <ArchivedActionsModal isOpen closeModal={closeModal} />;
    default:
      return null;
  }
}
