import { MdOpenInNew as OpenInNew } from "react-icons/md";
import { useState } from "react";
import { parse, differenceInDays } from "date-fns";
import { useToasts } from "react-toast-notifications";

import { DataTable, DSDropdownItem } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import { InterviewsRoutes } from "@skillup/espace-rh-bridge";
import { TranslationType } from "hooks/useTranslation";

import { InterviewResults } from "../types";
import styles from "../UserInterview.module.scss";
import useTableData from "./useTableData";
import DownloadModal from "./DownloadModal";

interface Props {
  readonly interviews: InterviewResults;
  t: TranslationType;
}

export default ({ interviews, t }: Props) => {
  const { addToast } = useToasts();
  const { baseUrl } = useBaseUrl();
  const [interviewDownloadModal, setInterviewDownloadModal] = useState<InterviewResults[0] | null>(
    null
  );

  const { columns, data: tableData } = useTableData({ interviews: interviews ?? [], t });

  const handleDownload = async (interviewUuid: string) => {
    try {
      await downloadTypedFileAsUser<InterviewsRoutes.GetPDF>(
        {
          method: "GET",
          path: "/interview/{interviewUuid}/pdf",
          params: { interviewUuid },
        },
        {
          target: "API",
          deduceFileDataFromResponseHeaders: true,
        }
      );
    } catch (error) {
      addToast(
        t("supervisor.view.collaborators.interviews.download.error", {
          defaultValue: "Une erreur est survenue pendant le téléchargement du fichier PDF.",
        }),
        {
          appearance: "error",
        }
      );
    }
  };

  return (
    <>
      <DataTable
        className={styles.table}
        header={{
          totalRowsLabel: (total) => {
            if (total < interviews.length) {
              return t("supervisor.view.collaborators.interviews.imported.count.filtered", {
                defaultValue: "{{ count }} entretien filtré sur {{ total }}",
                defaultValue_other: "{{ count }} entretiens filtrés sur {{ total }}",
                count: total,
                total: interviews.length,
              });
            }
            return t("supervisor.view.collaborators.interviews.imported.count.all", {
              defaultValue: "{{ count }} entretien",
              defaultValue_other: "{{ count }} entretiens",
              count: total,
            });
          },
        }}
        columns={columns}
        rows={tableData}
        mode="compact"
        actionsTooltip={t("supervisor.view.collaborators.interviews.imported.tooltip.action", {
          defautlValue: "Aucune action disponible pour cet entretien importé dans Skillup",
        })}
        actions={(row) =>
          row.data.source === "imported" && !row.data.file
            ? []
            : [
                <DSDropdownItem
                  key="download-pdf"
                  label={t("supervisor.view.collaborators.interviews.action", {
                    defaultValue: "Télécharger l'entretien (PDF)",
                  })}
                  onClick={() => {
                    const diff = differenceInDays(
                      parse(row.data.endDate, "dd/MM/yyyy", new Date()),
                      new Date()
                    );
                    if (diff >= 0) {
                      setInterviewDownloadModal(row.data);
                    } else {
                      handleDownload(row.data.uuid);
                    }
                  }}
                />,
                <DSDropdownItem
                  key="show-interview"
                  tooltipLabel={
                    row.data.source === "imported"
                      ? t("supervisor.view.collaborators.interviews.imported.tooltip", {
                          defaultValue: "Cet entretien a été importé dans Skillup",
                        })
                      : undefined
                  }
                  disabled={row.data.source === "imported"}
                  label={
                    <>
                      {t("supervisor.view.collaborators.interviews.action.openInNew", {
                        defaultValue: "Voir l’entretien dans le suivi de campagne",
                      })}
                      <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
                    </>
                  }
                  onClick={() => {
                    const win = window.open(
                      `${baseUrl}/campagne/${row.data.campaign.uuid}`,
                      "_blank"
                    );
                    win.focus();
                  }}
                />,
              ]
        }
        pagination={{
          rowsPerPageLabel: t("supervisor.view.collaborators.interviews.rowsPerPage", {
            defaultValue: "Entretiens par page",
          }),
          itemsCountLabel: t("supervisor.view.collaborators.interviews.itemsCountLabel", {
            defaultValue: "Entretiens %range% sur %count%",
          }),
          pageLabel: t("supervisor.view.collaborators.interviews.pageLabel", {
            defaultValue: "Page",
          }),
        }}
      />
      {interviewDownloadModal && (
        <DownloadModal
          onClose={() => setInterviewDownloadModal(null)}
          onConfirm={() => {
            handleDownload(interviewDownloadModal.uuid);
            setInterviewDownloadModal(null);
          }}
        />
      )}
    </>
  );
};
