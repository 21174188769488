import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  NewCategoryParams,
  RenameCategoryParams,
  TargetCategoriesRoutes,
} from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

async function getTargetCategoriesForConfiguration() {
  try {
    return buildRequest<TargetCategoriesRoutes.GetCategoriesForConfiguration>({
      method: "GET",
      path: "/targetCategoriesForConfiguration",
    })();
  } catch (err) {
    //add banner or error page
  }
  return [];
}

async function renameCategoryQuery(params: RenameCategoryParams) {
  try {
    const { uuid } = await buildRequest<TargetCategoriesRoutes.updateTargetCategory>({
      method: "PUT",
      path: "/targetCategories/{uuid}",
      params: { uuid: params.uuid },
      payload: params,
    })();
    if (uuid === undefined) throw new Error("Category update failed");
    return uuid;
  } catch (err) {
    return undefined;
  }
}

async function createCategory(params: NewCategoryParams) {
  try {
    const { uuid } = await buildRequest<TargetCategoriesRoutes.CreateCategory>({
      method: "POST",
      path: "/targetCategories/create",
      payload: params,
    })();
    if (uuid === undefined) throw new Error("Category creation failed");
    return uuid;
  } catch (err) {
    return undefined;
  }
}

export const useTargetCategories = () => {
  const queryClient = useQueryClient();
  const { data: targetCategories } = useQuery(
    ["targetCategoriesForConfiguration"],
    getTargetCategoriesForConfiguration,
    {
      refetchOnWindowFocus: false,
    }
  );

  const renameCategory = useCallback(
    async (params) => {
      const result = await renameCategoryQuery(params);
      await queryClient.invalidateQueries(["targetCategoriesForConfiguration"]);
      return result;
    },
    [queryClient]
  );

  const createNewCategory = useCallback(
    async (params) => {
      const result = await createCategory(params);
      await queryClient.invalidateQueries(["targetCategoriesForConfiguration"]);
      return result;
    },
    [queryClient]
  );

  return {
    targetCategories,
    renameCategory,
    createNewCategory,
  };
};
