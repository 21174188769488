import { CampaignTemplate, InterviewSectionPage } from "@skillup/types";
import { atom, selector } from "recoil";

const templateState = atom<CampaignTemplate | undefined>({
  key: "templateData",
  default: undefined,
});

export type Selection = {
  // section?: string;
  page?: string;
};

const selectionState = atom<Selection>({
  key: "selection",
  default: {},
});

const relatedCompanyState = atom<string>({
  key: "relatedCompany",
  default: "",
});

const selectedPageDataState = selector<InterviewSectionPage | undefined>({
  key: "selectedPageDataState",
  get: ({ get }) => {
    const templateData = get(templateState);
    const { page } = get(selectionState);

    const pagesToCheck = templateData?.sections?.map((s) => s.pages).flat();

    return pagesToCheck?.find((p) => p.uuid === page);
  },
  set: ({ set, get }, newPageData: InterviewSectionPage) => {
    const templateData = get(templateState);
    const selection = get(selectionState);

    const pageSection = templateData.sections?.find((section) => {
      return section.pages.map((p) => p.uuid).includes(selection.page);
    });

    set(templateState, {
      title: templateData.title,
      type: templateData.type,
      uuid: templateData.uuid,
      choicesForOrdinalTargets: templateData.choicesForOrdinalTargets,
      sections: templateData.sections.map((s) => {
        if (s.uuid !== pageSection.uuid) return s;
        return {
          ...s,
          pages: s.pages.map((p) => {
            if (p.uuid !== selection.page) {
              return p;
            }
            return newPageData;
          }),
        };
      }),
    });
  },
});

export { templateState, selectedPageDataState, selectionState, relatedCompanyState };
