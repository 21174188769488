import { Fragment } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";

import moment from "moment";
import useTranslation from "hooks/useTranslation";

import { DSAlert, DSAlertDisplay, DSAlertType, DSButton, Modal, Switch } from "@skillup/ui";

import CreateLabel from "components/CreateLabel";

import { IEditionState } from "./ModifyCampaignModal";

import { submitModification } from "./utils";
import { Campaign } from "./useCampaignModals";

import styles from "./NotificationCampaignModal.module.scss";

interface IProps {
  readonly campaign: Campaign;
  readonly warning: boolean;
  readonly resync: () => void;
  readonly onClose: () => void;
}
export default ({ campaign, warning, resync, onClose }: IProps) => {
  const { t } = useTranslation();
  const notificationsMapping = {
    notificationStart: t("campaign.notificationModal.notifications.notificationStart", {
      defaultValue: "Lancement de campagne",
    }),
    notificationCreated: t("campaign.notificationModal.notifications.notificationCreated", {
      defaultValue: "Création d'un entretien",
    }),
    notificationManagerChange: t(
      "campaign.notificationModal.notifications.notificationManagerChange",
      {
        defaultValue: "Changement de manager",
      }
    ),
    notificationShared: t("campaign.notificationModal.notifications.notificationShared", {
      defaultValue: "Partage de la préparation",
    }),
    notificationSigned: t("campaign.notificationModal.notifications.notificationSigned", {
      defaultValue: "Entretien prêt à être signé",
    }),
    notificationReopen: t("campaign.notificationModal.notifications.notificationReopen", {
      defaultValue: "Réouverture d’un entretien",
    }),
  };

  const remindersMapping = {
    reminderMinus14: t("campaign.notificationModal.reminders.reminderMinus14", {
      defaultValue: "14 jours avant la date de fin",
    }),
    reminderMinus7: t("campaign.notificationModal.reminders.reminderMinus7", {
      defaultValue: "7 jours avant la date de fin",
    }),
    reminderMinus1: t("campaign.notificationModal.reminders.reminderMinus1", {
      defaultValue: "La veille de la date de fin",
    }),
    reminderPlus1: t("campaign.notificationModal.reminders.reminderPlus1", {
      defaultValue: "Le lendemain de la date de fin",
    }),
  };

  const { addToast } = useToasts();

  const [state, setState] = useSetState<IEditionState>({
    isUpdating: false,
    startDate: campaign.startDate ? moment(campaign.startDate).startOf("day").valueOf() : undefined,
    duration: campaign.duration,
    frequency: campaign.frequency,
    titleForHR: campaign.titleForHR,
    titleForAttendees: campaign.titleForAttendees,
    mailingSettings: campaign.mailingSettings,
    errors: [],
    confidential: undefined,
  });

  const { mailingSettings } = state;

  const isAllDisabled = (type: string) =>
    Object.keys(mailingSettings)
      .filter((key) => key.includes(type))
      .every((key) => {
        return mailingSettings[key] === false;
      });

  const updateOne = (key: string, checked: boolean): void => {
    setState({ mailingSettings: { ...state.mailingSettings, [key]: checked } });
  };

  const updateAll = (type: string) => {
    let newMailingSettings = Object.assign({}, state.mailingSettings);
    Object.keys(newMailingSettings).forEach(function (key) {
      if (key.includes(type)) {
        newMailingSettings[key] = isAllDisabled(type);
      }
    });
    setState({ mailingSettings: newMailingSettings });
  };

  return (
    campaign && (
      <Modal
        title={t("campaign.campaignMailingUpdate", {
          defaultValue: "Paramètres notifications",
        })}
        className={styles.modal}
      >
        <div className={styles.content}>
          <div className={styles.toggles}>
            <section>
              <CreateLabel
                className={styles.label}
                label={t("campaign.notificationModal.notifyByEmail", {
                  defaultValue: "Notifications par mail par événement :",
                })}
              />
              <div className={styles.notificationsWrapper}>
                {Object.keys(notificationsMapping).map((key) => {
                  const label = notificationsMapping[key];
                  return (
                    <Fragment key={`toggle-${key}`}>
                      <div className={styles.notificationsToggleWrapper}>
                        <Switch
                          active={mailingSettings[key]}
                          onToggle={(checked) => updateOne(key, checked)}
                          label={label}
                        />
                      </div>
                    </Fragment>
                  );
                })}
                <DSButton
                  label={
                    isAllDisabled("notification")
                      ? t("campaign.notificationModal.activateAll", {
                          defaultValue: "Tout activer",
                        })
                      : t("campaign.notificationModal.desactivateAll", {
                          defaultValue: "Tout désactiver",
                        })
                  }
                  className={styles.toggleButton}
                  onClick={() => {
                    updateAll("notification");
                  }}
                  emphasis="Low"
                  buttonSize="S"
                />
              </div>
            </section>
            <section>
              <CreateLabel
                className={styles.label}
                label={t("campaign.notificationModal.unsignReminder", {
                  defaultValue: "Relances en cas de non-signature :",
                })}
              />
              <div className={styles.notificationsWrapper}>
                {Object.keys(remindersMapping).map((key) => {
                  const label = remindersMapping[key];
                  return (
                    <div key={`toggle-${key}`} className={styles.notificationsToggleWrapper}>
                      <Switch
                        active={mailingSettings[key]}
                        onToggle={(checked) => updateOne(key, checked)}
                        label={label}
                      />
                    </div>
                  );
                })}

                <DSButton
                  label={
                    isAllDisabled("reminder")
                      ? t("campaign.notificationModal.activateAll", {
                          defaultValue: "Tout activer",
                        })
                      : t("campaign.notificationModal.desactivateAll", {
                          defaultValue: "Tout désactiver",
                        })
                  }
                  className={styles.toggleButton}
                  onClick={() => {
                    updateAll("reminder");
                  }}
                  emphasis="Low"
                  buttonSize="S"
                />
              </div>
            </section>
          </div>

          {warning && (
            <div className={styles.alert}>
              <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                {t("campaign.notificationModal.alert", {
                  defaultValue:
                    "Les modifications apportées ne sont pas rétroactives : il est possible que certaines notifications aient déjà été envoyées.",
                })}
              </DSAlert>
            </div>
          )}

          <div className={styles.footer}>
            <DSButton
              className={styles.button}
              emphasis="Low"
              label={t("interview.modals.action.cancel", {
                defaultValue: "Annuler",
              })}
              onClick={onClose}
            />
            <DSButton
              className={styles.button}
              emphasis="High"
              label={t("interview.modals.action.saveUpdates", {
                defaultValue: "Enregistrer les modifications",
              })}
              onClick={() =>
                submitModification({
                  campaign,
                  state,
                  setState,
                  addToast,
                  onClose,
                  resync,
                  t,
                })
              }
            />
          </div>
        </div>
      </Modal>
    )
  );
};
