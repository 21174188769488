import React from "react";
import { useSetState } from "react-use";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useToasts } from "react-toast-notifications";

import useTranslation, { type TranslationType } from "hooks/useTranslation";
import Acta from "utils/Acta";
import Icon from "components/Icon";
import {
  edit as editIcon,
  cross as crossIcon,
  save as saveIcon,
  trashBin as trashBinIcon,
  chevronTop as chevronTopIcon,
  chevronBottom as chevronBottomIcon,
} from "uiAssets/StrokeIcons";
import TrainingCard from "./TrainingCard";

import DeleteListModal from "./DeleteListModal";
import RequalificationModal from "components/RequalificationModal";

import saveListName from "../actions/saveListName";
import addTrainingToList from "../actions/addTrainingToList";
import removeTrainingFromlist from "../actions/removeTrainingFromList";
import moveTrainingInList from "../actions/moveTrainingInList";
import { TrainingInterface } from "../types";

import styles from "./List.module.scss";
import RemoveTrainingFromList from "./RemoveTrainingFromListModal";
import AddButton from "./AddButton";

export interface IProps {
  removeList?: (uuid: string) => void;
  index: number;
  listLength: number;
  moveList?: (uuid: string | number, direction?: "up" | "down") => void;
  title?: string;
  trainings: TrainingInterface[];
  uuid: string;
}

export interface IState {
  isEditingTitle?: boolean;
  title?: string;
  trainings: Array<TrainingInterface>;
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
type TrainingInterfaceProperties = PropType<TrainingInterface, "properties">;

const showRequalificationModal = (action, t: TranslationType) => () => {
  Acta.setState("modalDisplayed", {
    content: (
      <RequalificationModal
        submitLabel={t("trainings.view.requalification_modal.add.training", {
          defaultValue: "Ajouter",
        })}
        hideComparison
        action={action}
        displayedTrainingTypes={["inter", "intra"]}
      />
    ),
    noHeader: true,
    border: true,
    size: "xxLarge",
  });
};

const List = (props: IProps) => {
  const [state, setState] = useSetState<IState>({
    isEditingTitle: false,
    title: props.title,
    trainings: props.trainings || [],
  });
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { removeList, index, listLength, moveList, uuid } = props;
  const { isEditingTitle, title, trainings } = state;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    moveTrainingInList(uuid, trainings, setState, oldIndex, newIndex);
  };

  const showRemoveTrainingFromListModal =
    (trainingProperties: TrainingInterfaceProperties) => () => {
      Acta.setState("modalDisplayed", {
        content: (
          <RemoveTrainingFromList
            confirmDelete={() =>
              removeTrainingFromlist(uuid, trainings, setState)(trainingProperties.uuid)
            }
            listName={title}
            trainingName={trainingProperties.name}
          />
        ),
        size: "small",
        title: "Retirer cette formation",
      });
    };

  const SortableItem = SortableElement(({ training }: { training: TrainingInterface }) => (
    // @ts-ignore
    <TrainingCard
      {...props}
      index={training.index}
      key={`${uuid}_${training.properties.uuid}`}
      name={training.properties.name}
      organizationLogo={training.organization.profileLogoOptiSmall}
      organizationName={training.organization.name}
      reviewsCount={training.reviewsCount || 0}
      reviewsGlobalScore={training.properties.reviewsGlobalScore}
      seoSlug={training.properties.seoSlug}
      trainingsLength={trainings.length}
      uuid={training.properties.uuid}
      onDeleteRequest={showRemoveTrainingFromListModal(training.properties)}
      isEditable
    />
  ));

  const SortableList = SortableContainer(
    ({ trainings: trainingItems }: { trainings: Array<TrainingInterface> }) => {
      return (
        <div>
          {trainingItems.map((value, i) => (
            <SortableItem key={`item-${i}`} index={i} training={value} />
          ))}
        </div>
      );
    }
  );

  return (
    <section className={styles.list}>
      <header>
        {isEditingTitle ? (
          <form onSubmit={saveListName({ uuid, addToast, t, setState })}>
            <input defaultValue={title} name="listNameInput" />
            <button type="button" onClick={() => setState({ isEditingTitle: false })}>
              <Icon strokeIcon={crossIcon} width={14} />
            </button>
            <button type="submit">
              <Icon strokeIcon={saveIcon} width={14} />
            </button>
          </form>
        ) : (
          <h2>
            <button onClick={() => setState({ isEditingTitle: true })}>
              <Icon strokeIcon={editIcon} width={14} />
            </button>
            {title}
          </h2>
        )}
        {!isEditingTitle && (
          <div>
            {index > 0 && (
              <button onClick={() => moveList(index, "up")}>
                <Icon strokeIcon={chevronTopIcon} width={16} />
              </button>
            )}
            {index < listLength - 1 && (
              <button onClick={() => moveList(index, "down")}>
                <Icon strokeIcon={chevronBottomIcon} width={16} />
              </button>
            )}
            <button
              onClick={() =>
                Acta.setState("modalDisplayed", {
                  title: "Supprimer cette liste",
                  size: "small",
                  content: (
                    <DeleteListModal
                      title={title}
                      trainingCount={trainings.length}
                      confirmDelete={() => removeList(uuid)}
                    />
                  ),
                })
              }
            >
              <Icon strokeIcon={trashBinIcon} width={16} />
            </button>
          </div>
        )}
      </header>
      <main>
        <SortableList
          axis="xy"
          trainings={trainings.sort((trainigA, trainingB) =>
            trainigA.index <= trainingB.index ? -1 : 1
          )}
          onSortEnd={onSortEnd}
          shouldCancelStart={(e) => {
            if (
              ["a", "svg", "button", "rect", "polyline", "line", "svg"].includes(
                (e.target as any).tagName.toLowerCase()
              )
            ) {
              return true; // Return true to cancel sorting
            }
            return false;
          }}
          helperClass={styles.draggedItem}
        />
        <AddButton
          label={t("trainings.view.portal_configuration.action.add_to_recommended_trainings", {
            defaultValue: "Ajouter une formation recommandée",
          })}
          onClick={showRequalificationModal(addTrainingToList(uuid, trainings, setState), t)}
        />
      </main>
    </section>
  );
};

export default List;
