import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, TextDropdown } from "@skillup/ui";
import qs from "qs";

import useTranslation from "hooks/useTranslation";
import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";
import downloadFileAsUser from "utils/downloadFileAsUser";
import {
  download as downloadIcon,
  trashBin as trashBinIcon,
  cross as crossIcon,
} from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";
import Icon from "components/Icon";
import Loader from "components/Loader";
import { UploadTypes } from "constants/booking";
import { File } from "containers/Supervisor/routes/Projects/Components/SidePanel";

import styles from "./DocumentsModal.module.scss";

export interface Props {
  categories: { [title: string]: File[] } | {};
  refetch: any;
  trainingSessionUuid: string;
}

export default ({ categories, refetch, trainingSessionUuid }: Props) => {
  const [category, setCategory] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState<boolean>(false);
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Object.entries(UploadTypes).map(([value, label]) => ({
        value,
        label: t(`trainings.entity.session.document.type.${value}`, { defaultValue: label }),
      })),
    [t]
  );

  const queryString = qs.stringify({
    timestamp: new Date().valueOf(),
  });

  const onDrop = async (files) => {
    const body = new FormData();
    body.append("file", files[0]);

    setFile(body);
  };

  const onDownloadAll = async () => {
    const filename = "archive.zip";
    const url = `/v1/projects/download-all/${trainingSessionUuid}/${filename}?${queryString}`;
    try {
      setIsDownloadingAll(true);
      await downloadFileAsUser(url, filename, "API", "application/zip");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors du téléchargement de votre archive.",
        type: "error",
      });
    } finally {
      setIsDownloadingAll(false);
    }
  };

  const onDownload = async ({ name, uuid }) => {
    const url = `/v1/projects/download/${trainingSessionUuid}/${uuid}/${name}?${queryString}`;

    try {
      setIsLoading(true);
      await downloadFileAsUser(url, name, "API");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors du téléchargement de votre fichier.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      await DataLayer.request({
        body: file,
        contentType: "multipart/form-data",
        method: "POST",
        url: `/v1/projects/files/${trainingSessionUuid}?type=trainingSession&category=${category}`,
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.session.documents.modal.success", {
          defaultValue: "Fichier envoyé.",
        }),
        type: "success",
      });
      refetch();
      Acta.dispatchEvent("closeModal");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.session.documents.modal.error", {
          defaultValue: "Une erreur est survenue lors de l’envoi de votre fichier.",
        }),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFileFromBooking = async (fileUUID) => {
    try {
      await DataLayer.request({
        method: "DELETE",
        url: `/v1/projects/delete/${trainingSessionUuid}/${fileUUID}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.session.documents.modal.file.deleted", {
          defaultValue: "Fichier supprimé.",
        }),
        type: "success",
      });
      refetch();
      Acta.dispatchEvent("closeModal");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.entity.session.documents.modal.error", {
          defaultValue: "Une erreur est survenue lors de l’envoi de votre fichier.",
        }),
        type: "error",
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });
  const hasFiles = useMemo(() => !!Object.keys(categories)?.length, [categories]);

  return (
    <div className={styles.DocumentsModal}>
      <div className={styles.blockWrapper}>
        <h3>
          {t("trainings.entity.session.documents.modal.title", {
            defaultValue: "Gérer les documents",
          })}
        </h3>
        <div onClick={(_) => Acta.dispatchEvent("closeModal")}>
          <Icon strokeIcon={crossIcon} width={12} />
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <section className={styles.dropZone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
              <p>
                {t("trainings.entity.session.documents.modal.dropzone", {
                  defaultValue: "Glissez / Déposez un fichier ici",
                })}
              </p>
              <p>
                {t("trainings.entity.or", {
                  defaultValue: "ou",
                })}
              </p>
              <p>
                {t("trainings.entity.session.documents.modal.addFile", {
                  defaultValue: "Ajoutez un fichier depuis votre ordinateur",
                })}
              </p>
            </div>
          </div>
        </section>
        {file && (
          <div className={styles.fileSelectedContainer}>
            <p className={styles.fileSelected}>{file.get("file")?.name}</p>
            <TextDropdown
              placeholder={t("trainings.entity.session.documents.modal.placeholder.type", {
                defaultValue: "Type de document",
              })}
              options={options}
              onSelect={({ value }) => setCategory(value)}
              small
            />
            <Button
              kind="danger"
              label={t("trainings.entity.session.documents.modal.delete.document", {
                defaultValue: "Supprimer",
              })}
              style={{ height: 38, marginLeft: 16 }}
              onClick={(_) => setFile(null)}
            />
          </div>
        )}
      </div>
      <div className={styles.blockWrapper}>
        <div>
          <p className={styles.sectionTitle}>
            {t("trainings.entity.session.documents.modal.list", {
              defaultValue: "Liste des documents :",
            })}
          </p>
        </div>
        <div className={styles.categoriesWrapper}>
          {!hasFiles && (
            <p style={{ textAlign: "center" }}>
              {t("trainings.entity.session.documents.modal.noDocument", {
                defaultValue: "Aucun document pour le moment",
              })}
            </p>
          )}
          {hasFiles && (
            <>
              <div className={styles.categoryWrapper}>
                <p>
                  {t("trainings.entity.session.documents.modal.document.type", {
                    defaultValue: "Type",
                  })}
                </p>
                <p>
                  {t("trainings.entity.session.documents.modal.document", {
                    defaultValue: "Document",
                  })}
                </p>
              </div>
              {Object.entries(categories).map(([title, files]) => (
                <div className={styles.categoryWrapper} key={title}>
                  {files.map((file) => (
                    <div key={file.uuid} className={styles.fileWrapper}>
                      <h3>
                        {t(`trainings.entity.session.document.type.${title}`, {
                          defaultValue: UploadTypes[title],
                        })}
                      </h3>
                      <p>{file.name}</p>
                      <div>
                        <button onClick={(_) => onDownload(file)} disabled={isLoading}>
                          {isLoading ? (
                            <Loader style={{ height: 0 }} />
                          ) : (
                            <Icon strokeIcon={downloadIcon} width={15} />
                          )}
                        </button>
                        <button onClick={(_) => deleteFileFromBooking(file.uuid)}>
                          <Icon strokeIcon={trashBinIcon} width={18} stroke={Colors.error} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
        {hasFiles && (
          <button onClick={onDownloadAll} className={styles.downloadAll} disabled={isLoading}>
            {isDownloadingAll
              ? t("trainings.entity.session.documents.modal.downloading", {
                  defaultValue: "En cours de téléchargement...",
                })
              : t("trainings.entity.session.documents.modal.download.all", {
                  defaultValue: "Télécharger tous les fichiers",
                })}
          </button>
        )}
        <div className={styles.submitContainer}>
          <button className={styles.close} onClick={(_) => Acta.dispatchEvent("closeModal")}>
            {t("trainings.entity.session.documents.modal.close", {
              defaultValue: "Fermer",
            })}
          </button>
          <Button
            label={t("trainings.entity.session.documents.modal.submit", {
              defaultValue: "Valider",
            })}
            className={styles.submit}
            onClick={submit}
            disabled={!file || !category || isLoading}
          />
        </div>
      </div>
    </div>
  );
};
