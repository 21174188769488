import useTranslation from "hooks/useTranslation";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";

import { DSModal } from "@skillup/ui";
import { ScheduleOperationsRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

import { Schedule } from "../../PortalSchedules";

import styles from "../../PortalSchedules.module.scss";

type Props = {
  schedule: Schedule;
  onClose: (needRefresh?: boolean) => void;
};

export default function ScheduleDeleteModal({ schedule, onClose }: Props) {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      buildRequest<ScheduleOperationsRoutes.Delete>({
        method: "DELETE",
        path: "/schedule/{scheduleUuid}/operations/remove",
        params: { scheduleUuid: schedule.uuid },
      })(),
  });

  const handleSubmit = () => {
    mutate(null, {
      onSuccess: () => {
        addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
          appearance: "success",
        });
        onClose(true);
      },
      onError: () => {
        addToast(t("app.errors.message"), { appearance: "error" });
      },
    });
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("supervisor.portalschedules.modal.delete.title", {
            name: schedule.name,
            defaultValue: "Supprimer le plan « {{name}} » ?",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <p className={styles.modalContent}>
          {t("supervisor.portalschedules.modal.delete.description", {
            defaultValue:
              "La suppression d’un plan entraîne la suppression de toutes les lignes qu’il contient.",
          })}
          <div className={styles.alert}>
            {t("supervisor.portalschedules.modal.delete.warning", {
              defaultValue: "Cette action est irréversible.",
            })}
          </div>
        </p>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={() => onClose()}
        />
        <DSModal.Footer.PrimaryButton
          label={t("supervisor.portalschedules.modal.delete.submit", {
            defaultValue: "Supprimer",
          })}
          onClick={handleSubmit}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
