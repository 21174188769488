import { GridRowParams } from "@mui/x-data-grid-pro";
import { Flex, Text } from "@skillup/design-system";
import { ListUtils } from "@skillup/shared-utils";
import { DSDataGrid, DSFilters, FilterRef, useFilters } from "@skillup/ui";
import placeholderImg from "assets/collaborator/empty_target.png";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { Collaborator } from "../../../../api";
import { QueryAction, QueryOptions, useTargets, useUserTargetDetailModal } from "../../hooks";
import { CompletionRates } from "./CompletionRates";
import { TargetRow, useTableData } from "./useTableData";
import { GlobalFilters } from "../../UserTargets";
import { UserTargetEmpty } from "../UserTargetEmpty";
import styles from "./EvaluatedUserTargetsDataGrid.module.scss";

interface Props {
  readonly user: Collaborator;
  globalFilters: GlobalFilters;
}

const queryOptionsReducer = (state: QueryOptions, action: QueryAction) => {
  switch (action.type) {
    case "sort":
      return { ...state, sort: action.sort, order: action.order };
    case "pagination":
      return { ...state, limit: action.pageSize, offset: action.page * action.pageSize };
    case "filter":
      return { ...state, filter: JSON.stringify({ ...action.filter }) };
    default:
      return state;
  }
};

const filterConfig = (t: TranslationType) => ({
  name: {
    type: ListUtils.FilterType.TEXT,
    label: t("supervisor.view.targets.evaluated.column.name", {
      defaultValue: "Nom de l'objectif",
    }),
  },
  targetCategoryLabel: {
    type: ListUtils.FilterType.TEXT,
    label: t("supervisor.view.targets.evaluated.column.category", {
      defaultValue: "Catégorie",
    }),
  },
  "evaluationCampaign.titleForHR": {
    type: ListUtils.FilterType.TEXT,
    label: t("supervisor.view.targets.evaluated.column.campaign", {
      defaultValue: "Campagne d'évaluation",
    }),
  },
  manager: {
    type: ListUtils.FilterType.MULTISELECT,
    label: t("supervisor.view.targets.evaluated.column.manager", {
      defaultValue: "Évaluateur",
    }),
  },
});

export const EvaluatedUserTargetsDataGrid = ({ user, globalFilters }: Props) => {
  const { t } = useTranslation();
  const [queryOptions, dispatch] = useReducer(queryOptionsReducer, {
    limit: 10,
    filter: JSON.stringify(globalFilters),
  });
  const filterRef = useRef<FilterRef>();

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      dispatch({ type: "pagination", page, pageSize });
    },
    [dispatch]
  );

  const handleSortModelChange = useCallback(
    (sort: string, order?: "ASC" | "DESC") => {
      dispatch({ type: "sort", sort, order });
    },
    [dispatch]
  );

  const { targetsList, targetsTotal, evaluators, isLoading, handleDeleteTarget } = useTargets({
    userUuid: user.uuid,
    queryOptions,
  });
  const { columns, data } = useTableData(targetsList, t);
  const { userTargetDetailModal, openUserTargetDetailModal } = useUserTargetDetailModal({
    userUuid: user.uuid,
    handleDeleteTarget,
  });

  const handleFilterModelChange = useCallback((col) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  }, []);

  const filtersInput = useMemo(() => {
    return {
      name: {
        label: t("supervisor.view.targets.evaluated.column.name", {
          defautlValue: "Nom de l'objectif",
        }),
        placeholder: t("supervisor.view.targets.evaluated.filter.target.placeholder", {
          defaultValue: "Filtrer par nom",
        }),
      },
      targetCategoryLabel: {
        label: t("supervisor.view.targets.evaluated.column.category", {
          defautlValue: "Catégorie",
        }),
        placeholder: t("supervisor.view.targets.evaluated.filter.category.placeholder", {
          defaultValue: "Filtrer par catégorie",
        }),
      },
      "evaluationCampaign.titleForHR": {
        label: t("supervisor.view.targets.evaluated.column.campaign", {
          defaultValue: "Campagne d'évaluation",
        }),
        placeholder: t("supervisor.view.targets.evaluated.filter.campaign.placeholder", {
          defaultValue: "Filtrer par campagne d'évaluation",
        }),
      },
      manager: {
        label: t("supervisor.view.targets.evaluated.column.manager", {
          defaultValue: "Évaluateur",
        }),
        placeholder: t("supervisor.view.targets.evaluated.filter.manager.placeholder", {
          defaultValue: "Filtrer par nom de l'évaluateur",
        }),
        options: evaluators.map(({ uuid, fullName }) => ({
          value: uuid,
          label: fullName,
        })),
      },
    };
  }, [evaluators, t]);

  const [filters, filterValues, setFilters] = useFilters(filterConfig(t), filtersInput);

  const targetEmptyWithNoFiltersActive = useMemo(() => {
    return targetsTotal === 0 && !Object.values(filterValues).some((filter) => filter.value);
  }, [filterValues, targetsTotal]);

  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
    },
    [setFilters]
  );

  useEffect(() => {
    dispatch({
      type: "filter",
      filter: {
        ...globalFilters,
        name: filterValues?.name?.value ? filterValues?.name?.value : undefined,
        targetCategoryLabel: filterValues?.targetCategoryLabel?.value
          ? filterValues?.targetCategoryLabel?.value
          : undefined,
        "evaluationCampaign.titleForHR": filterValues?.["evaluationCampaign.titleForHR"]?.value
          ? filterValues?.["evaluationCampaign.titleForHR"]?.value
          : undefined,
        manager: filterValues?.manager?.value
          ? (filterValues?.manager?.value as string[])
          : undefined,
      },
    });
  }, [filterValues, globalFilters]);
  const entityName = useMemo(
    () => `${data.length} Objectif${data.length > 1 ? "s" : ""}`,
    [data.length]
  );

  if (targetEmptyWithNoFiltersActive) {
    return <UserTargetEmpty user={user} selectedFilter="evaluated" t={t} />;
  }
  return (
    <Flex flexDirection="column" className={styles.EvaluatedUserTargetsDataGrid}>
      {data ? (
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <CompletionRates
              userUuid={user.uuid}
              queryOptions={{
                filter: queryOptionsReducer(
                  {},
                  {
                    type: "filter",
                    filter: {
                      isEvaluated: globalFilters.isEvaluated,
                      campaigns: globalFilters.campaigns,
                    },
                  }
                ).filter,
              }}
              t={t}
            />
            <DSFilters
              ref={filterRef}
              t={t}
              config={filterConfig(t)}
              filters={filters}
              onChange={handleFilterValuesChange}
            />
            <DSDataGrid
              columns={columns}
              rows={data}
              sorting={{
                property: queryOptions?.sort ?? "name",
                direction: queryOptions?.order ?? "ASC",
              }}
              onSort={handleSortModelChange}
              onFilter={handleFilterModelChange}
              onPageChange={handlePageChange}
              pagination
              rowCount={targetsTotal}
              entityName={entityName}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }],
                },
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              editable
              loading={isLoading}
              slotProps={{
                toolbar: {
                  translationPrefix: "evaluatedTargetsDatagrid",
                  entityName,
                },
              }}
              onRowClick={(params: GridRowParams<TargetRow>) =>
                openUserTargetDetailModal(params.row.id)
              }
            />
            <div className={styles.container__footer}></div>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center" flexGrow={1}>
          <img src={placeholderImg} alt="bientôt disponible" />
          <Text espaceFont="body1Regular">
            {t("supervisor.view.targets.empty", {
              defaultValue: "Aucun objectif n'a encore été évalué pour {{ userFullName }}",
              userFullName: user.fullName,
            })}
          </Text>
        </Flex>
      )}
      {userTargetDetailModal}
    </Flex>
  );
};
