import { Summary } from "@skillup/types";

import Acta from "utils/Acta";
import { type TranslationType } from "hooks/useTranslation";
import history from "utils/history";

import PositioningModal from "./Components/PositioningModal";
import UnmanagedBySkillupModal from "./Modals/UnmanagedBooking";
import { CancelModal } from "./Components/CancelModal";
import CancelOrPostponeModal from "./Components/CancelOrPostponeModal";
import MailReminderModal from "./Components/MailReminderModal";
import ScheduleImportModal from "./Components/ScheduleImportModal";
import PostponeOpsModal from "./Components/PostponeOpsModal";
import NoSessionModal from "./Components/NoSessionModal";

import EditLeadModal from "./Components/EditLeadModal";
import EditBookingModal from "./Components/EditBookingModal";
import DeleteModal from "./Components/DeleteModal";
import AssociateToFileModal from "./Components/AssociateToFileModal";
import RevertToApprovedHrModal from "./Components/RevertToApprovedHrModal";
import MoveToScheduleModal from "./Components/MoveToScheduleModal";
import ConfirmValidationModal from "./Components/ConfirmValidationModal";
import CancelReviewModal from "./Components/CancelReviewModal";

import { SummonForIntra } from "./Modals";
import AddRowModal from "./Modals/AddRow/ToPlan/AddRowToPlan";
import AddRowToCollectionModal from "./Modals/AddRow/ToCollection/AddRowToCollection";
import RequalifyModal from "./Modals/Requalify/Requalify";
import UpdateRowModal from "./Modals/UpdateRow";
import DeleteManualRowsModal from "./Modals/DeleteManualRow";
import { ManualTrainingData } from "./Modals/ManualRowForms/Training";

import addRowToPlan from "./utils/addRowToPlan";
import { IRelationCall } from "./utils/fetchItems";
import { requalify, sendEmails, sendValidationReminderMail } from "./utils";

import RequalificationModal from "components/RequalificationModal";
import UserChoiceModal from "components/UserChoiceModal";
import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";
import RevertToPendingModal from "./Components/RevertToPendingModal";

/**
 * Modals
 */

type CancelParams = {
  rowsUuids: string[];
  reload: () => void;
  hasReceivedNotification: boolean;
  type: "deny" | "cancel";
  rowType: string;
  managedBySkillup: boolean;
  rowStatus: string;
};

const showCancelModal = ({
  reload,
  rowsUuids,
  type,
  managedBySkillup,
  rowStatus,
}: CancelParams) => {
  Acta.setState("modalDisplayed", {
    content: (
      <CancelModal
        rowsUuids={rowsUuids}
        onClose={(needRefresh) => {
          Acta.dispatchEvent("closeModal");
          needRefresh === true && reload();
        }}
        type={type}
        rowsManagedBySkillup={managedBySkillup}
        rowStatus={rowStatus}
      />
    ),
    size: "small",
    title: type === "deny" ? "Refuser la demande" : "Annuler la demande",
  });
};

type CancelOrPostponeParams = {
  rows: string[];
  addedManually: boolean;
  managedBySkillup: boolean;
  type: string;
  scheduleUuid: string;
  hasReceivedNotification: boolean;
  rowStatus: string;
  reload: () => void;
};
const showCancelOrPostponeModal = ({
  addedManually,
  managedBySkillup,
  type,
  reload,
  rows,
  hasReceivedNotification,
  scheduleUuid,
  rowStatus,
}: CancelOrPostponeParams) => {
  Acta.setState("modalDisplayed", {
    content: (
      <CancelOrPostponeModal
        rows={rows}
        rowsAreManuallyAdded={addedManually}
        rowsManagedBySkillup={managedBySkillup}
        type={type}
        scheduleUuid={scheduleUuid}
        hasReceivedNotification={hasReceivedNotification}
        reload={reload}
        rowStatus={rowStatus}
      />
    ),
    noHeader: true,
    size: "medium",
  });
};

const showPostponeModal = (item) => {
  Acta.setState("modalDisplayed", {
    content: <PostponeOpsModal item={item} />,
    size: "small",
    title: "[OPS] Reporter",
  });
};

interface OpsEditionParams {
  rows: string[];
  type: string;
  reload: () => void;
}

const showEditModal = ({ reload, rows, type }: OpsEditionParams) => {
  Acta.setState("modalDisplayed", {
    content:
      type === "booking" ? (
        <EditBookingModal rows={rows} reload={reload} />
      ) : (
        <EditLeadModal rows={rows} reload={reload} />
      ),
    size: "small",
    title: "[OPS] Modifier",
  });
};

const showMoveToScheduleModal = ({
  reload,
  rowsUuids,
  scheduleUuid,
  currentState,
  summary,
}: {
  rowsUuids: string[];
  scheduleUuid: string;
  reload: () => void;
  currentState: IRelationCall["primaryState"];
  summary: Summary;
}) => {
  Acta.setState("modalDisplayed", {
    content: (
      <MoveToScheduleModal
        rowsUuids={rowsUuids}
        scheduleUuid={scheduleUuid}
        currentState={currentState}
        reload={reload}
        summary={summary}
      />
    ),
    size: "small",
    title: "Déplacer vers un autre plan",
    translationKey: "trainings.view.pending_schedule_row.property.move_to_plan_modal.title",
  });
};

const getRevertToPendingModal = ({
  reload,
  onClose,
  rowsUuids,
}: {
  rowsUuids: string[];
  reload: () => void;
  onClose: () => void;
}) => {
  return <RevertToPendingModal rowsUuids={rowsUuids} reload={reload} onClose={onClose} />;
};

const showAssociateToFileModal = (rows) => {
  Acta.setState("modalDisplayed", {
    content: <AssociateToFileModal rows={rows} />,
    size: "small",
    title: "[OPS] Réassocier à un dossier",
  });
};

type ShowRevertToApproveModalParams = {
  rows: string[];
  reload: () => void;
  activeTab: string;
  users: Array<{ noEmail: boolean; fullName: string }>;
  t: TranslationType;
};
const showRevertToApprovedHrModal = ({
  rows,
  reload,
  activeTab,
  users,
  t,
}: ShowRevertToApproveModalParams) => {
  Acta.setState("modalDisplayed", {
    content: (
      <RevertToApprovedHrModal rows={rows} reload={reload} activeTab={activeTab} users={users} />
    ),
    size: "small",
    title: t("trainings.view.schedule_plan.action.put_back_to_subscription", {
      defaultValue: "Remettre dans « À inscrire »",
    }),
  });
};

interface OpsDeletionParams {
  rows: string[];
  reload: () => void;
  activeTab: string;
}

const showDeleteModal = ({ reload, rows, activeTab }: OpsDeletionParams) => {
  Acta.setState("modalDisplayed", {
    content: <DeleteModal rows={rows} reload={reload} activeTab={activeTab} />,
    size: "small",
    title: "Supprimer",
  });
};

const showRequalificationModal = (rows, reload, t: TranslationType) => {
  Acta.setState("modalDisplayed", {
    border: true,
    content: (
      <RequalificationModal
        rows={rows}
        displayedTrainingTypes={["intra", "list", "inter"]}
        submitLabel={t("trainings.view.requalification_modal.requalify.training", {
          defaultValue: "Requalifier",
        })}
        action={async (uuid: string, type: "intra" | "inter", rowsUuids?: Array<string>) => {
          await requalify(rowsUuids, uuid, type, reload, t);
        }}
      />
    ),
    noHeader: true,
    size: "xxLarge",
  });
};

export interface OpenUsersModalProps {
  selection: { uuid: string; type: "inter" | "intra" };
  type: "plan" | "collection";
  scheduleUuid: string;
  training?: { name: string; organization: string };
  onRowsAdded: (rows: string[]) => void;
  t: TranslationType;
}

const openUsersModal = ({
  selection,
  type,
  scheduleUuid,
  training,
  onRowsAdded,
  t,
}: OpenUsersModalProps) => {
  Acta.setState("modalDisplayed", {
    border: true,
    content: (
      <UsersListProvider>
        <UserChoiceModal
          // @ts-ignore
          training={training}
          showManagerEmailInput
          onSubmit={async (users, onError) => {
            const rows = await addRowToPlan({
              target: selection,
              users,
              scheduleUuid,
              type,
              onError,
            });

            onRowsAdded(rows);
          }}
        />
      </UsersListProvider>
    ),
    title: t("trainings.view.schedule.add_trainees_modal.title", {
      defaultValue: "Sélectionner des collaborateurs à ajouter",
    }),
    size: "xLarge",
  });
};

export interface ShowCatalogModalProps {
  type: "plan" | "collection";
  scheduleUuid: string;
  onRowsAdded: (rows: string[]) => void;
  t: TranslationType;
}

const showCatalogModal = ({
  type: baseType,
  scheduleUuid,
  onRowsAdded,
  t,
}: ShowCatalogModalProps) => {
  Acta.setState("modalDisplayed", {
    border: true,
    content: (
      <RequalificationModal
        submitLabel={t("trainings.view.requalification_modal.select.training", {
          defaultValue: "Sélectionner",
        })}
        hideComparison
        displayedTrainingTypes={["intra", "list", "inter"]}
        // tslint:disable-next-line:variable-name
        action={(
          uuid: string,
          type: "intra" | "inter",
          _rows,
          training?: { name: string; organization: string }
        ) => {
          openUsersModal({
            selection: { uuid, type },
            scheduleUuid,
            type: baseType,
            training,
            onRowsAdded,
            t,
          });
        }}
      />
    ),
    noHeader: true,
    size: "xxLarge",
  });
};

const startAddingRowProcess = ({
  addRowsToList,
  showCatalog,
  scheduleUuid,
}: {
  scheduleUuid: string;
  showCatalog: () => void;
  addRowsToList: (createdRows: string[]) => Promise<void>;
}) => {
  Acta.setState("modalDisplayed", {
    size: "xLarge",
    showOverflow: true,
    noHeader: true,
    content: (
      <AddRowModal
        showCatalog={showCatalog}
        addRowsToList={addRowsToList}
        scheduleUuid={scheduleUuid}
      />
    ),
  });
};

const startAddingRowToCollectionProcess = ({
  addRowsToList,
  showCatalog,
  scheduleUuid,
}: {
  scheduleUuid: string;
  showCatalog: () => void;
  addRowsToList: (createdRows: string[]) => Promise<void>;
}) => {
  Acta.setState("modalDisplayed", {
    size: "xLarge",
    showOverflow: true,
    noHeader: true,
    content: (
      <AddRowToCollectionModal
        showCatalog={showCatalog}
        addRowsToList={addRowsToList}
        scheduleUuid={scheduleUuid}
      />
    ),
  });
};

const startRequalificationProcess = ({
  reload,
  rows,
  summary,
  t,
}: {
  rows: string[];
  summary: Summary;
  reload: () => Promise<void>;
  t: TranslationType;
}) => {
  Acta.setState("modalDisplayed", {
    size: "auto",
    showOverflow: true,
    noHeader: true,
    content: (
      <RequalifyModal
        reload={reload}
        rows={rows}
        summary={summary}
        showCatalog={() => showRequalificationModal(rows, reload, t)}
      />
    ),
  });
};

const startManualEditionProcess = ({
  reload,
  rowUuid,
  scheduleUuid,
  trainingToEdit,
}: {
  rowUuid: string;
  scheduleUuid: string;
  reload: (rowUuid: string) => Promise<void>;
  trainingToEdit: ManualTrainingData;
}) => {
  Acta.setState("modalDisplayed", {
    size: "auto",
    showOverflow: true,
    noHeader: true,
    content: (
      <UpdateRowModal
        reload={reload}
        rowUuid={rowUuid}
        scheduleUuid={scheduleUuid}
        trainingToEdit={trainingToEdit}
      />
    ),
  });
};

const showDeleteManualRowsModal = ({
  reload,
  rows,
}: {
  rows: string[];
  reload: (deletedRows: string[]) => Promise<void>;
}) => {
  Acta.setState("modalDisplayed", {
    size: "small",
    title: "Suppression des lignes",
    translationKey: "trainings.view.pending_schedule_row.property.delete_modal.title",
    content: <DeleteManualRowsModal reload={reload} rows={rows} />,
  });
};

const showUnmanagedBySkillupModal = ({
  uuids,
  summary,
  users,
  activeTab,
  noEmail = false,
  noManager = false,
  reload,
  t,
}) => {
  Acta.setState("modalDisplayed", {
    content: (
      <UnmanagedBySkillupModal
        uuids={uuids}
        summary={summary}
        users={users}
        noEmail={noEmail}
        noManager={noManager}
        activeTab={activeTab}
        reload={reload}
      />
    ),
    size: "small",
    title:
      activeTab === "plan-approved"
        ? t("trainings.view.schedule_plan.external_subscribe.title", {
            defaultValue: "Inscrire hors Skillup",
          })
        : t("trainings.view.schedule_plan.manual_complete.title", {
            defaultValue: "Completer",
          }),
  });
};

type ShowPositioningModalProps = {
  rows: string[];
  reload: () => void;
  noEmail: boolean;
  noManager: boolean;
  noEmailManager?: boolean;
  scheduleUuid: string;
};

const showPositioningModal = ({
  noEmail,
  noManager,
  noEmailManager,
  reload,
  rows,
  scheduleUuid,
}: ShowPositioningModalProps) => {
  Acta.setState("modalDisplayed", {
    content: (
      <PositioningModal
        scheduleUuid={scheduleUuid}
        items={rows}
        onClose={(needRefresh) => needRefresh && reload()}
        noEmail={noEmail}
        noManager={noManager}
        noEmailManager={noEmailManager}
      />
    ),
    size: "small",
    title: "Inscrire à une session",
    translationKey: "trainings.view.schedule_plan.session_positioning_modal.title",
  });
};

const showValidationMailReminderModal = (rows, reloadRows: (uuids: string[]) => void) => {
  Acta.setState("modalDisplayed", {
    content: (
      <MailReminderModal
        text="Relancer les validateurs par email."
        rows={rows}
        sendEmails={(data) => sendValidationReminderMail(data, reloadRows)}
      />
    ),
    size: "small",
    title: "Relancer par email",
    translationKey: "trainings.view.pending_schedule_row.property.reminder_modal.title",
  });
};

const showBookingMailReminderModal = (
  rows,
  scheduleUuid: string,
  reloadRows: (uuid: string[]) => void
) => {
  Acta.setState("modalDisplayed", {
    content: (
      <MailReminderModal
        text=""
        rows={rows}
        sendEmails={(data, isReminder) => sendEmails(data, isReminder, scheduleUuid, reloadRows)}
      />
    ),
    size: "small",
    title: "Relancer par email",
  });
};

const showAdminImport = (scheduleUuid: string, reload: () => Promise<void>) => {
  Acta.setState("modalDisplayed", {
    content: <ScheduleImportModal scheduleUuid={scheduleUuid} reload={reload} />,
    size: "large",
    title: "Import",
  });
};

type ShowNoSessionInscriptionModalParams = {
  rows: string[];
  summary: Summary;
  reload: () => void;
  type: "inter" | "intra";
  t: TranslationType;
};

const showNoSessionModal = ({
  reload,
  rows,
  summary,
  type,
  t,
}: ShowNoSessionInscriptionModalParams) => {
  const query = new URLSearchParams();
  if (summary.trainingUuid) {
    query.set("mode", "create");
    query.set("trainingUuid", summary.trainingUuid);
  }

  Acta.setState("modalDisplayed", {
    content: (
      <NoSessionModal
        requalify={() => showRequalificationModal(rows, reload, t)}
        type={type}
        goToProjects={() => {
          Acta.dispatchEvent("closeModal");
          history.push(`/responsable/mes-sessions/en-cours?${query.toString()}`);
        }}
      />
    ),
    size: "small",
    title: "Pas de session disponible",
    translationKey: "trainings.view.no_session_modal.title",
  });
};

const showSummonForIntraModal = ({ uuid, trainingName, formattedTimespan }) => {
  Acta.setState("modalDisplayed", {
    content: (
      <SummonForIntra
        uuid={uuid}
        trainingName={trainingName}
        formattedTimespan={formattedTimespan}
      />
    ),
    size: "small",
    title: "Convoquer la session",
  });
};

const showConfirmValidationModal = (
  selectedRows: Array<string>,
  isBypass: boolean,
  onMoveToPlan
) => {
  Acta.setState("modalDisplayed", {
    content: (
      <ConfirmValidationModal rows={selectedRows} isBypass={isBypass} onMoveToPlan={onMoveToPlan} />
    ),
    size: "small",
    title: "Validation et passage au plan",
    translationKey: "trainings.view.pending_schedule_row.property.validate_modal.title",
  });
};

const showCancelReviewModal = ({ uuid, t }) => {
  Acta.setState("modalDisplayed", {
    content: <CancelReviewModal uuid={uuid} />,
    size: "small",
    title: t("trainings.view.schedule_plan.action.cancel_review", {
      defaultValue: "Annuler l'évaluation",
    }),
  });
};

export {
  showAdminImport,
  showAssociateToFileModal,
  showBookingMailReminderModal,
  showCancelModal,
  showCancelOrPostponeModal,
  showCatalogModal,
  showDeleteModal,
  showEditModal,
  showMoveToScheduleModal,
  showNoSessionModal,
  showPositioningModal,
  showPostponeModal,
  startRequalificationProcess,
  startManualEditionProcess,
  showRequalificationModal,
  showRevertToApprovedHrModal,
  showValidationMailReminderModal,
  startAddingRowProcess,
  startAddingRowToCollectionProcess,
  showUnmanagedBySkillupModal,
  showSummonForIntraModal,
  showConfirmValidationModal,
  showCancelReviewModal,
  showDeleteManualRowsModal,
  getRevertToPendingModal,
};
