import { MdDragIndicator as DragIndicator } from "react-icons/md";
import { MdError as Error } from "react-icons/md";
import { memo, useMemo } from "react";
import cx from "classnames";

import { DSButton, DSTooltip, Flex } from "@skillup/ui";

import { ChildIcon } from "../../../ChildIcon";
import { ItemActions } from "../ItemActions";

import styles from "../../TemplateBuilderStructure.module.scss";
import useTranslation from "hooks/useTranslation";

export const StructureChild = memo(function StructureChild({
  id,
  kind,
  type,
  label,
  errors,
  dragListeners,
  displayMenuOnHover = true,
  highlight = false,
  onClick,
  onFocus,
  onDuplication,
  onDeletion,
  showActionsButtons = false,
}: {
  id?: string;
  kind: string;
  type?: string;
  label?: string;
  errors?: string[];
  dragListeners?: any;
  displayMenuOnHover?: boolean;
  highlight?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onDuplication?: () => void;
  onDeletion?: () => void;
  showActionsButtons?: boolean;
}) {
  const { t } = useTranslation();
  const childKind = useMemo(() => {
    switch (kind) {
      case "question":
        if (type === "radio") {
          return t("interviews.templateBuilder.child.radio", {
            defaultValue: "Choix multiple (une seule réponse possible)",
          });
        }
        if (type === "checkbox") {
          return t("interviews.templateBuilder.child.checkbox", {
            defaultValue: "Choix multiple (plusieurs réponses possibles)",
          });
        }
        if (type === "text") {
          return t("interviews.templateBuilder.child.text", {
            defaultValue: "Question ouverte",
          });
        }
        return "Unknown question type";
      case "targets":
        if (type === "current") {
          return t("interviews.templateBuilder.child.target.current", {
            defaultValue: "Revue des objectifs",
          });
        }
        return t("interviews.templateBuilder.child.target.next", {
          defaultValue: "Fixation d'objectifs",
        });
      case "table":
        return t("interviews.templateBuilder.child.table", {
          defaultValue: "Tableau",
        });
      case "guidelines":
        return t("interviews.templateBuilder.child.guidelines", {
          defaultValue: "Introduction",
        });
      case "personnalisableGuidelines":
        return t("interviews.templateBuilder.child.personnalisableGuidelines", {
          defaultValue: "En-tête de l'entretien",
        });
      case "conclusion":
        return t("interviews.templateBuilder.child.conclusion", {
          defaultValue: "Partage et signature",
        });
      case "html":
        return t("interviews.templateBuilder.child.html", {
          defaultValue: "HTML",
        });
      case "title":
        return t("interviews.templateBuilder.child.title", {
          defaultValue: "Titre",
        });
      case "interviewsHistory":
        return t("interviews.templateBuilder.child.interviewsHistory", {
          defaultValue: "Historique d'entretiens",
        });
      case "trainingsHistory":
        return t("interviews.templateBuilder.child.trainingsHistory", {
          defaultValue: "Historique de formation",
        });
      case "trainingsCollection":
        return t("interviews.templateBuilder.child.trainingsCollection", {
          defaultValue: "Demande de formation",
        });
      case "job":
        return t("interviews.templateBuilder.child.job", {
          defaultValue: "Fiche de poste",
        });
      case "upload":
        return t("interviews.templateBuilder.child.upload", {
          defaultValue: "Téléchargement de fichiers",
        });
      case "globalTargetsCompletion":
        return t("interviews.templateBuilder.child.globalTargetsCompletion", {
          defaultValue: "Taux d’atteinte global",
        });
      case "skills":
        return t("interviews.templateBuilder.child.skills", {
          defaultValue: "Compétences",
        });
      case "userSpecificData":
        return t("interviews.templateBuilder.child.userSpecificData", {
          defaultValue: "Données spécifiques à l'utilisateur",
        });
      default:
        return "Unknown child kind";
    }
  }, [kind, type, t]);
  const emptyLabel = useMemo(() => label?.length === 0, [label]);
  return (
    <Flex
      row
      className={cx(
        styles.child,
        displayMenuOnHover ? styles["displayMenuOnHover"] : "",
        highlight ? styles.highlight : "",
        errors?.length ? styles["error"] : ""
      )}
      id={id}
      tabIndex={0}
      onClick={onClick}
      onFocus={onFocus}
    >
      {showActionsButtons ? (
        <DSButton
          iconOnly
          buttonSize="S"
          icon={<DragIndicator />}
          className={styles["drag-indicator"]}
          {...dragListeners}
        />
      ) : (
        <div className={styles["no-drag-indicator"]} />
      )}
      {errors?.length > 0 ? (
        <DSTooltip label={errors[0]}>
          <Error className={styles["error-icon"]} />
        </DSTooltip>
      ) : (
        <div className={styles["no-error-icon"]}></div>
      )}
      <div className={styles["child-icon"]}>
        <DSTooltip label={childKind}>
          <ChildIcon kind={kind} type={type} />
        </DSTooltip>
      </div>
      <div className={cx(styles["ellipsisBlock"], emptyLabel ? styles["emptyChildLabel"] : "")}>
        {emptyLabel
          ? t("interviews.templateBuilder.noTitle", { defaultValue: "Sans libellé" })
          : label}
        &nbsp;
      </div>
      <Flex grow />
      <ItemActions
        deletionTooltipLabel={t("interviews.templateBuilder.delete", {
          defaultValue: "Supprimer",
        })}
        duplicationTooltipLabel={t("interviews.templateBuilder.duplicate", {
          defaultValue: "Dupliquer",
        })}
        showActionsButtons={showActionsButtons}
        onDuplication={onDuplication}
        onDeletion={onDeletion}
      />
    </Flex>
  );
});
