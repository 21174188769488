import { type FunctionComponent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { type ListUtils } from "@skillup/shared-utils";

import { type CustomComponentProps, type FilterProp, type FilterProps } from "./types";

export type UseFilterProp<Config extends ListUtils.FilterConfigurationMap> = {
  [key in keyof Config]: FilterProps<Config[key]["type"]> & {
    initialValue?: ListUtils.FilterValueType[Config[key]["type"]];
    value?: ListUtils.FilterValueType[Config[key]["type"]];
    operator?: ListUtils.FilterOperators;
    operators?: Array<ListUtils.FilterOperators>;
    darkMode?: boolean;
    component?: FunctionComponent<CustomComponentProps>;
    data?: any;
  };
};

export const useFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  filters: UseFilterProp<Config>
) => {
  const initialFilters = useMemo(() => {
    return Object.keys(config).reduce((acc, key) => {
      const baseKey = key.split("_$")[0];
      return {
        ...acc,
        [key]: {
          type: config[baseKey].type,
          visibilityMode: filters[baseKey]?.visibilityMode,
          defaultValue: filters[baseKey]?.defaultValue,
          options: filters[baseKey]?.options,
          value: filters[baseKey]?.initialValue,
          operator: filters[baseKey]?.operator ?? "contains",
          operators: filters[baseKey]?.operators,
          label: config[baseKey]?.label,
          placeholder: filters[baseKey]?.placeholder,
          sortPosition: filters[baseKey]?.sortPosition,
          component: filters[baseKey]?.component,
        },
      };
    }, {} as FilterProp<Config>);
  }, [config, filters]);
  const [filterValues, setFilterValues] = useState<ListUtils.FilterValues<Config>>(
    Object.keys(config)
      .filter(
        (filterId) =>
          initialFilters[filterId] &&
          initialFilters[filterId].value !== undefined &&
          initialFilters[filterId].value !== null
      )
      .reduce(
        (acc, filterId) => ({
          ...acc,
          [filterId]: {
            type: initialFilters[filterId].type,
            value: initialFilters[filterId].value,
            operator: initialFilters[filterId].operator,
            data: initialFilters[filterId].data,
          },
        }),
        {} as FilterProp<Config>
      )
  );

  return [initialFilters, filterValues, setFilterValues] as [
    typeof initialFilters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};

export const useUrlSyncedFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  initialValues: UseFilterProp<Config>
) => {
  const history = useHistory();
  const location = useLocation();

  const filtersConfig = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return Object.keys(config).reduce<UseFilterProp<Config>>((acc, filterId) => {
      // Handle both base filter IDs and duplicated filter IDs (with underscore)
      const relatedParams = Array.from(searchParams.entries()).filter(
        ([key]) => key === filterId || key.startsWith(`${filterId}_`)
      );

      const result = { ...acc } as UseFilterProp<Config>;

      relatedParams.forEach(([key, value]) => {
        (result as any)[key] = {
          ...acc[filterId],
          initialValue: config[filterId].type === "multiselect" ? searchParams.getAll(key) : value,
        };
      });

      return result;
    }, initialValues);
  }, [config]);

  const [filters, filterValues, setFilterValues] = useFilters(config, filtersConfig);

  /**
   * Update url's searchParams according to filters
   * This function should fire on `values` or `config` change
   */
  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const filterId in filterValues) {
      const baseFilterId = filterId.split("_$")[0];

      if (
        config[baseFilterId].type === "multiselect" &&
        Array.isArray(filterValues[filterId]?.value) &&
        filterValues[filterId].value.length > 0 &&
        filterValues[filterId].value.every(Boolean)
      ) {
        filterValues[filterId].value.forEach((value) => searchParams.append(filterId, value));
      } else if (
        config[baseFilterId].type === "text" &&
        filterValues[filterId]?.value !== undefined &&
        filterValues[filterId]?.value !== null &&
        filterValues[filterId]?.value !== ""
      ) {
        searchParams.set(filterId, filterValues[filterId].value.toString());
      }
    }

    searchParams.sort();
    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.sort();

    if (currentSearchParams.toString() !== searchParams.toString()) {
      history.replace({ search: searchParams.toString() });
    }
  }, [filterValues, config]);

  return [filters, filterValues, setFilterValues] as [
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};
