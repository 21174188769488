import { useMemo, useState } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";

import TrackingForm, { IForm } from "../TrackingForm";

import styles from "./CreateTracking.module.scss";

import { buildSafeRequest } from "utils/buildRequest";
import type { TrackingRoutes } from "types/api";
import { formatError } from "services/errors";

interface Props {
  readonly onClose: () => void;
}

type Payload = TrackingRoutes.Create["payload"];
const createTracking = async (payload: Payload) => {
  const request = await buildSafeRequest<TrackingRoutes.Create>({
    method: "POST",
    path: "/tracking",
    payload,
  });

  return request.run();
};

function buildPayloadFromForm(form: IForm): Payload {
  const { userUuid = "", habilitationUuid = "", validSince } = form;

  return {
    userUuid,
    habilitationUuid,
    validSince,
  };
}

export default ({ onClose }: Props) => {
  const [form, setFormState] = useSetState<IForm>({});
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const isFormStateValid = useMemo(() => !!form.userUuid && !!form.habilitationUuid, [form]);

  const submitRequest = async () => {
    const request = createTracking(buildPayloadFromForm(form));
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de l’ajout du document",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_habilitation) => {
        addToast(
          t("tracking.document.create.success", {
            defaultValue: "Ligne de suivi ajoutée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  return (
    <Modal
      title={t("trainings.view.regulatory_collaborator_tracking.action.add", {
        defaultValue: "Ajouter une ligne de suivi",
      })}
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.createTracking}>
        <TrackingForm form={form} setFormState={setFormState} />
        <div className={styles.buttonsWrapper}>
          <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
          <DSButton
            className={styles.button}
            disabled={!isFormStateValid}
            loading={loading}
            onClick={submitRequest}
            emphasis="High"
            tooltip={
              !isFormStateValid
                ? t("trainings.view.regulatory_collaborator_tracking.tooltip.mandatory_fields", {
                    defaultValue:
                      "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                  })
                : undefined
            }
            label="Confirmer"
          />
        </div>
      </div>
    </Modal>
  );
};
