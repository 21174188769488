import cx from "classnames";

import { Badge, DSTooltip as Tooltip } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import { SESSION_STATES } from "../../helper";
import styles from "./PositionnedTrainees.module.scss";

export default ({ initialData, row, value }) => {
  const { t } = useTranslation();

  const currentRow = initialData[row.id];
  const { minStock, stock } = currentRow.properties;
  const unsummonedTraineesCount = row.original.unsummonedTraineesCount;
  const state = row.values.rawState;

  const grammarTooltip = t(
    "trainings.view.session_list.datatable.positionnedTraineesCount.tooltip",
    {
      defaultValue: "{{ count }} collaborateurs inscrits n'ont pas reçu de convocation",
      count: unsummonedTraineesCount,
    }
  );

  return (
    <div className={styles.PositionnedTrainees}>
      <div className={styles.high}>
        <span>{value}</span>
        {state === SESSION_STATES.summoned && unsummonedTraineesCount > 0 && (
          <Tooltip label={grammarTooltip}>
            <Badge
              value={unsummonedTraineesCount}
              className={styles.badge}
              backgroundColor={"#FCF1E8"}
            />
          </Tooltip>
        )}
      </div>
      {minStock && (
        <p className={cx(styles.default, { [styles.min]: minStock && value < minStock })}>
          {t("trainings.view.session_list.datatable.positionnedTraineesCount.min", {
            defaultValue: "min: {{ min }}",
            min: minStock,
          })}
        </p>
      )}
      {stock && (
        <p className={cx(styles.default, { [styles.max]: stock && value > stock })}>
          {t("trainings.view.session_list.datatable.positionnedTraineesCount.max", {
            defaultValue: "max: {{ max }}",
            max: stock,
          })}
        </p>
      )}
    </div>
  );
};
