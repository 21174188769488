import { buildRequest } from "utils/buildRequest";

import { EmployeesRoutes } from "@skillup/core-hr-bridge";

export type Collaborator =
  EmployeesRoutes.GetEmployeesWithHierarchy["response"]["employeesWithHierarchy"][0];

export async function getEmployees(payload: EmployeesRoutes.GetEmployeesWithHierarchy["payload"]) {
  try {
    const response = await buildRequest<EmployeesRoutes.GetEmployeesWithHierarchy>({
      method: "POST",
      path: "/employees-with-hierarchy",
      payload,
      target: "CORE_HR",
    })();

    return response;
  } catch (err) {
    return undefined;
  }
}
export default getEmployees;
