import { useCallback, useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "@tanstack/react-query";

import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSFormGroupTextInput,
  DSModal,
  DSTextInput,
  Select,
} from "@skillup/ui";

import cx from "classnames";
import styles from "./CancelModal.module.scss";
import { useToggle } from "react-use";
import { buildRequest } from "utils/buildRequest";
import { FieldRoutesType, ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

interface Props {
  rowsUuids: Array<string>;
  onBack?: () => void;
  onClose: (needRefresh?: boolean) => void;
  rowsManagedBySkillup?: boolean;
  rowStatus: string;
  type: string;
}

const getField = async () => {
  try {
    const data = await buildRequest<FieldRoutesType.GetV2>({
      method: "GET",
      path: `/fields-v2`,
      query: {
        bindings: ["deniedOrCancelledReason"],
      },
    })();

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const CancelModal = ({
  rowsUuids,
  onBack,
  onClose,
  rowsManagedBySkillup,
  rowStatus,
  type,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [loading, toggleLoading] = useToggle(false);
  const [message, setMessage] = useState<string>(null);
  const [messageOF, setMessageOF] = useState<string>(null);
  const [cancelReason, setCancelReason] = useState<string>(null);

  const { data: [field] = [] } = useQuery(["denied-or-Cancelled-field"], () => getField());
  const fieldOptions = useMemo(
    () => field?.options.map(({ value, key }) => ({ label: value, value: key })),
    [field]
  );

  const displayCommentOF = useMemo(() => {
    return rowsManagedBySkillup && rowStatus === "plan-processing" && cancelReason !== "12";
  }, [rowsManagedBySkillup, rowStatus, cancelReason]);

  const disabledSubmit = useMemo(() => {
    if (type === "deny") {
      return false;
    }

    if (displayCommentOF && !messageOF) {
      return true;
    }

    if (!cancelReason) {
      return true;
    }

    return false;
  }, [messageOF, cancelReason, type, displayCommentOF]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      const { cancelledRows } = await buildRequest<ScheduleRowRoutes.CancelRow>({
        method: "POST",
        path: "/scheduleRow/cancel",
        payload: {
          rows: rowsUuids,
          message,
          deniedOrCancelledReason: cancelReason,
          cancelledMessageForTrainingOrganization: messageOF,
        },
      })();

      if (cancelledRows.length === rowsUuids.length) {
        addToast(
          t("trainings.schedules.cancel.success", {
            defaultValue: `Demande ${type !== "deny" ? "annulée" : "refusée"} avec succès.`,
          }),
          {
            appearance: "success",
          }
        );

        onClose(true);
      }
    } catch (error) {
      addToast(t("trainings.schedules.cancel.error", { defaultValue: "La demande a échoué." }), {
        appearance: "error",
      });
    } finally {
      toggleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleLoading, onClose, message, messageOF, cancelReason, rowsUuids, addToast, t]);

  return (
    <DSModal isOpen className={styles.CancelModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t(`trainings.schedules.${type}.modal.title`, {
            count: rowsUuids.length,
            defaultValue: type !== "deny" ? "Annuler la demande" : "Refuser la demande",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <p>
          {t(`trainings.schedules.${type}.modal.description`, {
            count: rowsUuids.length,
            defaultValue: `Si vous confirmez cette action, la demande passera dans l'onglet ${type !== "deny" ? "Annulées" : "Refusées"}.`,
          })}
        </p>
        <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
          <ul
            className={cx({
              [styles.warnList]:
                rowsManagedBySkillup && rowStatus === "plan-processing" && cancelReason === "12",
            })}
          >
            <li>
              {t(`trainings.schedules.${type}.modal.alert.info`, {
                defaultValue: `Le collaborateur ne sera pas notifié de ${type !== "deny" ? "l'annulation" : "du refus"}`,
              })}
            </li>
            {rowsManagedBySkillup && rowStatus === "plan-processing" && cancelReason === "12" && (
              <li>
                {t(`trainings.schedules.${type}.modal.alert.info.of`, {
                  defaultValue: `L'organisme ne sera pas notifié de ${type !== "deny" ? "l'annulation" : "du refus"}`,
                })}
              </li>
            )}
          </ul>
        </DSAlert>
        <form onSubmit={(e) => e.preventDefault()}>
          {type !== "deny" && (
            <DSFormGroupTextInput
              label={t(`trainings.schedules.${type}.reason.label`, {
                defaultValue: type !== "deny" ? "Motif d'annulation" : "Motif de refus",
              })}
              required
              placeholder={t("trainings.schedules.cancelOrPostpone.reason.placeholder", {
                defaultValue: "Choisir un motif",
              })}
            >
              <Select onChange={setCancelReason} options={fieldOptions} />
            </DSFormGroupTextInput>
          )}
          {displayCommentOF && (
            <DSFormGroupTextInput
              label={t("trainings.schedules.cancel.modal.commentOF.label", {
                defaultValue: `Commentaire d'annulation destiné à l'organisme`,
              })}
              assistiveText={t("trainings.schedules.cancel.modal.commentOF.assistiveText", {
                defaultValue: "Ce commentaire sera transmis à l'organisme.",
              })}
              required
            >
              <DSTextInput
                placeholder={t("trainings.schedules.cancel.modal.comment.placeholder", {
                  defaultValue: "Laissez un commentaire dans votre activité",
                })}
                onChange={setMessageOF}
                name="cancelledMessageForTrainingOrganization"
              />
            </DSFormGroupTextInput>
          )}
          <DSFormGroupTextInput
            label={t("trainings.schedules.cancel.modal.comment.label", {
              defaultValue: "Commentaire (facultatif)",
            })}
            assistiveText={t("trainings.schedules.cancel.modal.comment.assistiveText", {
              defaultValue: `Le commentaire ci-dessus ne sera pas transmis au collaborateur mais figurera dans l'activité de la ligne`,
            })}
          >
            <DSTextInput
              autoFocus={true}
              placeholder={t("trainings.schedules.cancel.modal.comment.placeholder", {
                defaultValue: "Laissez un commentaire dans votre activité",
              })}
              onChange={setMessage}
              name="message"
            />
          </DSFormGroupTextInput>
        </form>
      </DSModal.Content>
      <DSModal.Footer>
        {!!onBack && (
          <DSModal.Footer.CancelButton
            disabled={loading}
            onClick={onBack}
            label={t("app.modal.action.back", {
              defaultValue: "Retour",
            })}
          />
        )}
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handleSubmit}
          label={t(`trainings.schedules.${type}.modal.confirm`, {
            defaultValue: `Confirmer ${type !== "deny" ? "l'annulation" : "le refus"}`,
          })}
          disabled={disabledSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
