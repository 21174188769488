import React, { useRef, useState, useCallback, useEffect, Fragment } from "react";
import { useSetState, useMount, useToggle } from "react-use";
import { useParams } from "react-router-dom";
import { IoMdInformationCircle } from "react-icons/io";

import { useFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import cx from "classnames";
import { isNil } from "lodash";
import { analytics } from "services";
import moment, { Moment } from "moment";

import { Select as DSSelect, Label } from "@skillup/ui";
import type { IScheduleLead, IOrganization } from "@skillup/types";
import { BookingRoutes } from "@skillup/espace-rh-bridge";
import { EmployeeTrainingSessionState } from "@skillup/training-bridge";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";
import injectSafeHtml from "utils/sanitizer";
import modalitiesConstants from "constants/modalities";

import TrainingScore from "components/TrainingScore";
import InteractiveButton from "components/InteractiveButton";
import DropDown, { DropDownOptions } from "components/DropDown";
import TextInput from "components/TextInput";
import StepRegistration from "./components/StepRegistration";

import { position, phone as phoneIcon } from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";

import styles from "./TrainingInscriptionStyles.module.scss";
import { GetEmployeeTrainingSessionsStateRoute } from "@skillup/espace-rh-bridge/src/routes/training/getEmployeeTrainingSessionsState";
import { DateTime, Duration, ParseDate } from "@skillup/shared-utils";

interface State {
  displayProgram: boolean;
  step: string;
  training?: any;
  trainee?: any;
  company?: any;
  lead?: IScheduleLead;
  currentSessionPlace?: any;
  currentSession?: any;
  organization?: IOrganization;
  success?: boolean;
  properties?: any;
  sessionsState?: EmployeeTrainingSessionState[];
}

const TrainingInscription = () => {
  const { t, i18n } = useTranslation();
  const { rowUuid } = useParams<{ rowUuid?: string }>();

  const sessionDropdown = useRef<DropDown<DropDownOptions<string>>>(null);
  const [submitting, toggleSubmitting] = useToggle(false);

  const [state, setState] = useSetState<State>({
    displayProgram: false,
    step: "home",
  });

  const {
    training,
    organization,
    trainee,
    company,
    currentSessionPlace,
    currentSession,
    displayProgram,
    lead,
    step,
    sessionsState,
  } = state;

  const durationObject = training?.duration
    ? Duration.parseStringToObject(training.duration)
    : undefined;
  const durationAsHours = durationObject?.hours ?? 7;
  const durationAsDays =
    durationObject?.days ?? durationAsHours >= training?.hoursPerDay
      ? durationAsHours / (training.hoursPerDay ?? 7)
      : undefined;

  const [role, setRole] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const { data, loading, error } = useFetch<{ step: string; properties: any }>({
    url: `/v1/scheduleRow/action/${rowUuid}`,
  });

  useMount(() => {
    analytics.sendEvent("Booking", "Open booking page", rowUuid);
  });

  useEffect(() => {
    if (data && !loading) {
      const { properties, step } = data;

      if (step !== "home") {
        return setState({ step, ...properties });
      }

      setRole(properties.trainee.role || "");
      setPhone(properties.trainee.phone || "");

      return setState({
        training: properties.training, // This can be an intra, but it's transparent.
        trainee: properties.trainee,
        company: properties.company,
        lead: properties.lead,
        currentSessionPlace:
          properties.training.places && properties.training.places[0]
            ? properties.training.places[0].value
            : null,
        currentSession: null,
        organization: properties.organization,
        success: false,
      });
    }

    if (error) {
      console.trace(error);
      return setState({ step: "error", properties: {} });
    }
  }, [data, loading, error, setState, setRole, setPhone]);

  useEffect(() => {
    if (!training || !trainee) {
      return;
    }

    const fetchSessionsState = async () => {
      try {
        const sessionsState = await DataLayer.request<GetEmployeeTrainingSessionsStateRoute>({
          method: "GET",
          url: `/v1/training/${training.uuid}/sessions-state/${trainee.uuid}`,
        });

        setState((state) => ({ ...state, sessionsState }));
      } catch (error) {
        console.error("An error occured while fetching session state", error);
      }
    };

    fetchSessionsState();
  }, [training, trainee, setState]);

  const hasOnlineSessions = (sessions) => {
    if (isNil(sessions) || !sessions.length) return false;
    return sessions.filter((session) => session.online).length > 0;
  };

  const onSetPlace = useCallback(
    (city) => {
      setState({
        currentSessionPlace: city,
        currentSession: null,
      });

      if (sessionDropdown.current) {
        sessionDropdown.current.selectOption(null);
      }
    },
    [setState, sessionDropdown]
  );

  const onSetSession = useCallback(
    (session) => {
      setState({ currentSession: session });
    },
    [setState]
  );

  const submit = useCallback(
    async (e): Promise<void> => {
      e.preventDefault();

      const {
        lead: { type },
        currentSession,
        trainee,
      } = state;

      if (!currentSession) {
        return Acta.dispatchEvent("sendAppMessage", {
          message: t("trainings.view.session_subscribe.form.error.missing_session_date", {
            defaultValue: "Vous devez choisir une date de session.",
          }),
          type: "error",
        });
      }

      toggleSubmitting(true);

      try {
        if (type === "inter") {
          const dataWithTrainees = {
            session: currentSession,
            scheduleRow: rowUuid,
            trainee: {
              fullName: trainee.fullName,
              email: trainee.email,
              role,
              noEmail: trainee.noEmail,
              phone,
              invoicingBudgetaryCode: trainee.invoicingBudgetaryCode,
              company: trainee.company,
            },
          };
          await buildRequest<BookingRoutes.CreateInterBooking>({
            method: "POST",
            path: "/booking/booking-inter",
            payload: dataWithTrainees,
          })();
          setState({ step: "success" });
          analytics.sendEvent("Booking", "Success page", rowUuid);
        } else if (type === "intra") {
          await DataLayer.request({
            method: "POST",
            url: "/v1/scheduleLead/position-on-session",
            body: JSON.stringify({
              session: currentSession,
              scheduleRow: rowUuid,
              traineeUuids: [trainee.uuid],
              trainingSessionUuids: training.sessions.map((s) => s.uuid),
            }),
          });
          setState({ step: "success" });
        }
      } catch (error) {
        console.trace(error);
        if (error.statusCode === 429) {
          Acta.dispatchEvent("sendAppMessage", {
            message: t("trainings.view.session_subscribe.form.error.too_many_request", {
              defaultValue:
                "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
            }),
            type: "error",
          });
        } else if (error.statusCode === 410) {
          setState({ step: "full" });
        } else if (error.statusCode === 409) {
          Acta.dispatchEvent("sendAppMessage", {
            message: t("trainings.view.session_subscribe.form.error.conflict", {
              defaultValue:
                "Le collaborateur est déjà inscrit à cette session. Veuillez choisir une autre session.",
            }),
            type: "error",
          });
        } else {
          analytics.sendEvent("Booking", "Error page", rowUuid);
          setState({ step: "error" });
        }

        toggleSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowUuid, state, role, phone, setState, toggleSubmitting]
  );

  const computeSessionDates = useCallback((dates?: string[]): JSX.Element => {
    if (!dates) return undefined;

    const intervals = dates
      .map((d) => d.split("/").filter(Boolean))
      .map((interval) => interval.map((date) => moment(date)));

    const countedDays = new Array<number>();
    const durationAsDays = intervals.reduce((acc, interval: Moment[]) => {
      // We don't want to count the same day twice.
      if (countedDays.includes(interval[0].startOf("day").unix())) return acc;
      countedDays.push(interval[0].startOf("day").unix());

      if (interval.length === 1) return acc + 1;
      return acc + 1 + interval[interval.length - 1].diff(interval[0], "day");
      // +1 is because moment doesn't think the last day is included.
    }, 0);

    const dateRanges = dates.map((date) =>
      date.split("/").map((d) => ParseDate.FromParsableJS(moment(d).toDate()))
    );
    const formattedDates = dateRanges.map((dateRange) => {
      const [start, end] = dateRange;
      const startDate = start.toLocaleString(DateTime.DATE_SHORT, { locale: i18n.language });
      const endDate = end.toLocaleString(DateTime.DATE_SHORT, { locale: i18n.language });

      const isAllDay = start.hour === 0 && end.hour === 0;

      if (isAllDay) {
        return t("trainings.view.session_subscribe.form.session_dates.range_all_day", {
          defaultValue: "Du {{start}} au {{end}} (toute la journée)",
          start: startDate,
          end: endDate,
        });
      }

      return t("trainings.view.session_subscribe.form.session_dates.range_all_day", {
        defaultValue: "Du {{start}} au {{end}} de {{startHour}} à {{endHour}}",
        start: startDate,
        end: endDate,
        startHour: start.toLocaleString(DateTime.TIME_24_SIMPLE, { locale: i18n.language }),
        endHour: end.toLocaleString(DateTime.TIME_24_SIMPLE, { locale: i18n.language }),
      });
    });

    return (
      <div className={styles.sessionDatesPanel}>
        <div>
          <IoMdInformationCircle size={32} color={Colors.altScoreColor} />
        </div>
        <div className={styles.content}>
          <span>
            {t("trainings.view.session_subscribe.form.session_dates", {
              defaultValue: "Formation sur {{count}} jours",
              count: durationAsDays,
            })}
          </span>
          <ul>
            {formattedDates.map((formattedDate) => (
              <li key={formattedDate}>{formattedDate}</li>
            ))}
          </ul>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!training) {
    if (step === "home") return null;

    return (
      <main className={styles.TrainingInscription}>
        <StepRegistration t={t} step={step} trainee={trainee} currentSession={null} />;
      </main>
    );
  }

  const { reviews = [], sessions = [], places = [], sessionsByPlace } = training;

  let possibleDates = [];

  if (currentSessionPlace) {
    possibleDates =
      sessionsByPlace &&
      sessionsByPlace[currentSessionPlace].map((session) => {
        const [start, end] = session.dates?.[0]?.split("/") ?? [];
        const startDate = ParseDate.FromParsableJS(moment(start).toDate());
        const endDate = end && ParseDate.FromParsableJS(moment(end).toDate());

        const days = [startDate.toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })];
        let hours: string[] | undefined = undefined;

        if (endDate) {
          days.push(endDate.toLocaleString(DateTime.DATE_FULL, { locale: i18n.language }));

          if (startDate.hour !== endDate?.hour) {
            hours = [
              startDate.toLocaleString(DateTime.TIME_24_SIMPLE, { locale: i18n.language }),
              endDate.toLocaleString(DateTime.TIME_24_SIMPLE, { locale: i18n.language }),
            ];
          }
        }

        const isDisabled = sessionsState?.some(
          (s) => s.sessionUuid === session.uuid && s.isPositioned
        );

        return {
          value: session.uuid,
          label: `${days[0]}${hours ? ` | ${hours[0]}` : ""} ${
            isDisabled
              ? ` ${t("trainings.view.session_subscribe.form.already_register", {
                  defaultValue: "(le collaborateur est déjà inscrit à cette session)",
                })}`
              : ""
          }`,
          isDisabled,
        };
      });
  }

  const hasSessions = hasOnlineSessions(sessions);
  const isRf = User.isRF();

  const organizationLogoUrl =
    organization && organization.profileLogoOptiSmall
      ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${organization.profileLogoOptiSmall}`
      : null;

  const companyLogoUrl =
    company && company.logo ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${company.logo}` : null;

  const mappedTrainingContent = [
    {
      title: t("trainings.view.session_subscribe.form.property.objectives.label", {
        defaultValue: "Objectifs",
      }),
      content: training.objectives,
    },
    {
      title: t("trainings.view.session_subscribe.form.property.prererquisites.label", {
        defaultValue: "Pré-requis",
      }),
      content: training.prerequisites,
    },
    {
      title: t("trainings.view.session_subscribe.form.property.program.label", {
        defaultValue: "Programme",
      }),
      content: training.program,
    },
  ];

  const selectedSessionData = sessions.find((s) => s.uuid === currentSession);

  const currentSessionDatesPanel =
    lead && lead.type === "intra" ? computeSessionDates((selectedSessionData || {}).dates) : null;

  return (
    <main className={styles.TrainingInscription}>
      {step === "home" ? (
        <div className={cx(styles.home, styles.card)}>
          <header>
            <h1>
              {t("trainings.view.session_subscribe.title", {
                defaultValue: "Inscription à une formation",
              })}
            </h1>

            {trainee && trainee.fullName && (
              <p>
                {t("trainings.view.session_subscribe.subtitle", {
                  defaultValue: "Pour {{trainee}}",
                  trainee: trainee.fullName,
                })}
              </p>
            )}
          </header>
          <div className={styles.training}>
            <div className={styles.summary}>
              <div className={cx(styles.top, { [styles.open]: displayProgram })}>
                {!!organizationLogoUrl && (
                  <div>
                    <div
                      className={styles.orgLogo}
                      style={{ backgroundImage: `url(${organizationLogoUrl})` }}
                    />
                  </div>
                )}
                <div className={styles.title}>
                  <h2>
                    {t("trainings.view.session_subscribe.training_name", {
                      defaultValue: "Formation {{name}}",
                      name: training.name,
                    })}
                  </h2>
                  {!!training.reviewsGlobalScore && !!reviews.length && (
                    <TrainingScore
                      score={training.reviewsGlobalScore}
                      reviewsCount={reviews.length}
                    />
                  )}
                  <div className={styles.organization}>
                    {t("trainings.view.session_subscribe.organization", {
                      defaultValue: "Par {{organization}}",
                      organization: organization.name,
                    })}
                  </div>
                </div>
                <div className={styles.containerTrainingInfos}>
                  {training.mode && (
                    <div>
                      {t("trainings.entity.training.property.mode." + training.mode + ".label", {
                        defaultValue: modalitiesConstants.mapping[training.mode],
                      })}
                    </div>
                  )}
                  {durationObject && (
                    <div>
                      {durationAsDays
                        ? t("trainings.view.session_subscribe.duration_days", {
                            defaultValue: "{{count}} jours",
                            count: durationAsDays,
                          })
                        : t("trainings.view.session_subscribe.duration_hours", {
                            defaultValue: "{{count}} heures",
                            count: durationAsHours,
                          })}
                    </div>
                  )}
                </div>
                {training.program && (
                  <div
                    className={styles.iconMore}
                    onClick={() => setState({ displayProgram: !displayProgram })}
                  >
                    {displayProgram
                      ? t("trainings.view.session_subscribe.action.close_program", {
                          defaultValue: "Fermer le programme",
                        })
                      : t("trainings.view.session_subscribe.action.display_program", {
                          defaultValue: "Afficher le programme",
                        })}
                  </div>
                )}
              </div>
              {displayProgram && (
                <section className={styles.containerContent}>
                  {mappedTrainingContent.map(
                    (item) =>
                      item.content && (
                        <Fragment key={item.title}>
                          <h3 key={item.title}>{item.title}</h3>
                          <blockquote
                            key={`content-${item.title}`}
                            {...injectSafeHtml(item.content)}
                          />
                        </Fragment>
                      )
                  )}
                  <div
                    className={styles.iconMore}
                    onClick={() => setState({ displayProgram: false })}
                  >
                    {t("trainings.view.session_subscribe.action.close_program", {
                      defaultValue: "Fermer le programme",
                    })}
                  </div>
                </section>
              )}
            </div>
          </div>
          <form onSubmit={submit}>
            {hasSessions && (
              <div className={styles.forms}>
                <div className={styles.session}>
                  <Fragment>
                    <div className={styles.dropdown} id="cities">
                      <Label
                        className={styles.Label}
                        label={t("trainings.view.session_subscribe.form.property.city.label", {
                          defaultValue: "Lieu",
                        })}
                      />
                      <DSSelect
                        value={currentSessionPlace}
                        className={styles.DSSelect}
                        options={places}
                        placeholder={t(
                          "trainings.view.session_subscribe.form.property.city.placeholder",
                          {
                            defaultValue: "Sélectionnez un lieu",
                          }
                        )}
                        onChange={(value) => onSetPlace(value)}
                      />
                    </div>
                    <div className={styles.dropdown} id="dates">
                      <Label
                        className={styles.Label}
                        label={t("trainings.view.session_subscribe.form.property.date.label", {
                          defaultValue: "Date",
                        })}
                      />
                      <DSSelect
                        defaultValue={currentSession}
                        className={styles.DSSelect}
                        options={possibleDates}
                        placeholder={t(
                          "trainings.view.session_subscribe.form.property.date.placeholder",
                          {
                            defaultValue: "Sélectionnez une date",
                          }
                        )}
                        onChange={(value) => onSetSession(value)}
                      />
                    </div>
                    {currentSessionDatesPanel}
                  </Fragment>
                </div>
                <div className={styles.divider} />
                <div className={styles.trainee}>
                  <div className={styles.traineeForm}>
                    <TextInput
                      label={t("trainings.view.session_subscribe.form.property.title.label", {
                        defaultValue: "Poste / Titre",
                      })}
                      name="role"
                      small
                      placeholder={t(
                        "trainings.view.session_subscribe.form.property.title.placeholder",
                        {
                          defaultValue: "Poste ou titre du stagiaire",
                        }
                      )}
                      defaultValue={role}
                      onChange={(_, value) => setRole(value)}
                      required
                      strokeIcon={position}
                      style={{ width: 275 }}
                      type="text"
                    />
                    {organization.traineePhoneRequired && (
                      <TextInput
                        label={t("trainings.view.session_subscribe.form.property.phone.label", {
                          defaultValue: "Téléphone",
                        })}
                        name="phone"
                        small
                        placeholder={t(
                          "trainings.view.session_subscribe.form.property.phone.placeholder",
                          {
                            defaultValue: "Téléphone du stagiaire",
                          }
                        )}
                        defaultValue={phone}
                        onChange={(_, value) => setPhone(value)}
                        required
                        strokeIcon={phoneIcon}
                        style={{ width: 275 }}
                        type="tel"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {hasSessions && !state.success && (
              <InteractiveButton
                background={Colors.gradientYellow}
                color="#333"
                size="small"
                label={t("trainings.view.session_subscribe.form.action.submit", {
                  defaultValue: "Valider l'inscription",
                })}
                type="submit"
                style={{
                  padding: "0 32px",
                  fontWeight: 400,
                  fontSize: 14,
                }}
                loading={submitting}
              />
            )}
            {!hasSessions && (
              <div className={styles.error}>
                <p>
                  {t("trainings.view.session_subscribe.form.error.no_session", {
                    defaultValue:
                      "Vous ne pouvez pas vous inscrire à cette formation pour le moment car aucune session ne semble ouverte à l’inscription.",
                  })}
                </p>
                <p>
                  {t("trainings.view.session_subscribe.form.error.no_session_contact_hr", {
                    defaultValue:
                      "Nous vous recommandons de contacter votre responsable formation.",
                  })}
                </p>
              </div>
            )}
          </form>
          {!isRf && (
            <div className={styles.containerMoreQuestions}>
              <p>
                {t("trainings.view.session_subscribe.form.more_questions", {
                  defaultValue:
                    "Pour toute question sur cette inscription, vous pouvez contacter votre gestionnaire de formation.",
                })}
                {/* <b><a href="mailto:contact@skillup.co?subject=Probleme dans la page de booking">contact@skillup.co</a></b> */}
              </p>
            </div>
          )}
        </div>
      ) : (
        <StepRegistration
          t={t}
          step={step}
          trainee={trainee}
          currentSession={
            sessions && {
              ...sessions.find((session) => session.uuid === currentSession),
              place: currentSessionPlace,
            }
          }
        />
      )}
      {!!companyLogoUrl && (
        <div className={styles.companyLogo} style={{ backgroundImage: `url(${companyLogoUrl})` }} />
      )}
    </main>
  );
};

export default TrainingInscription;
