import React, { useMemo, useState, useEffect } from "react";

import { ListUtils } from "@skillup/shared-utils";
import { Select, SelectOperator } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { FilterCard, SelectWrapper, BetweenOperator } from "./CustomComponent.styled";
import { CollaboratorsProvider, useCollaboratorsContext } from "../CollaboratorContext";

interface CustomComponentProps {
  filterValue: any;
  darkMode?: boolean;
  onChange: (value: any) => void;
}

export const CustomComponent: React.FC<CustomComponentProps> = ({
  darkMode = false,
  filterValue,
  onChange,
}) => {
  return (
    <CollaboratorsProvider>
      <Layout darkMode={darkMode} filterValue={filterValue} onChange={onChange} />
    </CollaboratorsProvider>
  );
};

const Layout: React.FC<CustomComponentProps> = ({ darkMode, filterValue, onChange }) => {
  const { t } = useTranslation();

  const { getSkills } = useCollaboratorsContext();
  const { data: skills } = getSkills();

  const operatorList = useMemo(
    () => [
      {
        label: t("design-system.operator.equals", {
          defaultValue: "Égal à",
        }),
        value: ListUtils.FilterOperator.EQUALS,
      },
      {
        label: t("design-system.operator.lessThanOrEqual", {
          defaultValue: "Inférieur ou égal à",
        }),
        value: ListUtils.FilterOperator.LESS_THAN_OR_EQUAL,
      },
      {
        label: t("design-system.operator.greaterThanOrEqual", {
          defaultValue: "Supérieur ou égal à",
        }),
        value: ListUtils.FilterOperator.GREATER_THAN_OR_EQUAL,
      },
      {
        label: t("design-system.operator.between", {
          defaultValue: "Entre",
        }),
        value: ListUtils.FilterOperator.BETWEEN,
      },
    ],
    [t]
  );

  const [firstValue, setFirstValue] = useState<number | undefined>(() => {
    if (Array.isArray(filterValue?.value)) {
      return filterValue.value[0];
    }
    return typeof filterValue?.value === "number" ? filterValue.value : undefined;
  });

  const [secondValue, setSecondValue] = useState<number | undefined>(() => {
    if (Array.isArray(filterValue?.value)) {
      return filterValue.value[1];
    }
    return typeof filterValue?.value === "number" ? filterValue.value : undefined;
  });

  const [operator, setOperator] = useState<string>(filterValue?.operator);
  const [entity, setEntity] = useState<string | undefined>(filterValue?.data?.entity);

  useEffect(() => {
    if (operator === ListUtils.FilterOperator.CONTAINS) {
      setOperator(ListUtils.FilterOperator.BETWEEN);
    }
    // Empty dependency array since this should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operator !== ListUtils.FilterOperator.BETWEEN && secondValue) {
      setSecondValue(undefined);
    }
    // passign secondValue as a dependency triggers an infinite state reload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator]);

  useEffect(() => {
    if (entity && !firstValue && !secondValue) {
      Promise.resolve().then(() => {
        setFirstValue(0);
        setSecondValue(100);
      });
    }
  }, [entity, firstValue, secondValue]);

  useEffect(() => {
    if (!entity || !operator) return;

    const hasValidValue =
      operator === ListUtils.FilterOperator.BETWEEN
        ? firstValue !== undefined && secondValue !== undefined
        : firstValue !== undefined;

    if (!hasValidValue) return;

    const newValue = {
      type: ListUtils.FilterType.SINGLESELECT,
      data: { entity },
      operator,
      value: operator === ListUtils.FilterOperator.BETWEEN ? [firstValue, secondValue] : firstValue,
    };

    // Add value comparison to prevent unnecessary state updates
    if (JSON.stringify(filterValue) === JSON.stringify(newValue)) return;

    onChange(newValue);
  }, [entity, operator, firstValue, secondValue, onChange, filterValue]);

  const selectOptions =
    skills
      ?.find((s) => s.uuid === entity)
      ?.levels.map((lvl, index, arr) => ({
        label: lvl.name,
        value: (index / (arr.length - 1)) * 100,
      })) ?? [];

  return (
    <FilterCard>
      <SelectWrapper isFirstElement={!!entity}>
        <Select
          value={filterValue?.data?.entity}
          placeholder={t("design-system.value.select", {
            defaultValue: "Choisissez une valeur",
          })}
          options={skills?.map((skill) => ({
            label: skill.name,
            value: skill.uuid,
          }))}
          onChange={(newEntity) => {
            setEntity(newEntity);
            setFirstValue(undefined);
            setSecondValue(undefined);
          }}
        />
      </SelectWrapper>
      {entity && (
        <>
          <SelectOperator
            noRadius={true}
            value={operator}
            darkMode={darkMode}
            operatorList={operatorList}
            onChange={setOperator}
            placeholder={t("design-system.value.select", {
              defaultValue: "Choisissez une valeur",
            })}
          />
          <SelectWrapper noRadius={operator === "between"}>
            <Select
              value={firstValue}
              options={selectOptions}
              onChange={setFirstValue}
              placeholder={t("design-system.value.select", {
                defaultValue: "Choisissez une valeur",
              })}
            />
          </SelectWrapper>
          {operator === "between" && (
            <>
              <BetweenOperator darkMode={darkMode}>
                {t("design-system.operator.and", {
                  defaultValue: "Et",
                })}
              </BetweenOperator>
              <SelectWrapper>
                <Select
                  value={secondValue}
                  options={selectOptions}
                  onChange={setSecondValue}
                  placeholder={t("design-system.value.select", {
                    defaultValue: "Choisissez une valeur",
                  })}
                />
              </SelectWrapper>
            </>
          )}
        </>
      )}
    </FilterCard>
  );
};
