import React from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { magnifyingGlass as magnifyingGlassIcon } from "uiAssets/StrokeIcons";

import getTrainings, { ITraining, IFilters, ITrainingAggregations } from "../Actions/getTrainings";
import styles from "./SearchInputStyles.module.scss";
import { TranslationType } from "hooks/useTranslation";

export interface IEntity {
  uuid: string;
  slug: string;
  shape: string;
}

export interface IProps {
  available: boolean;
  inAccount?: boolean;
  filters: IFilters;
  onSearch: (trainings: Array<ITraining>, aggregations: ITrainingAggregations) => void;
  t: TranslationType;
}

export interface IState {
  currentSearch: string;
}

export default class SearchInput extends React.PureComponent<IProps, IState> {
  private input: HTMLInputElement;
  private timeout: number | null;

  public state = {
    currentSearch: "",
  };

  public componentDidUpdate(prevProps: IProps) {
    if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
      this.handleChange(this.props.filters, this.state.currentSearch);
    }
  }

  private onChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const query = event.currentTarget.value.trim();

    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (!query || query === "") {
      return this.setState({ currentSearch: "" });
    }

    if (query && this.state.currentSearch !== query) {
      this.setState({
        currentSearch: query,
      });
      this.timeout = window.setTimeout(
        this.handleChange.bind(this, this.props.filters, query),
        300
      );
    }
  };

  private handleChange = async (filters, search): Promise<void> => {
    if (!search) return;

    const receivedData = await getTrainings(this.props.filters, this.state.currentSearch);

    if (filters !== this.props.filters || search !== this.state.currentSearch) {
      return;
    }

    const { trainings, trainingAggregations } = receivedData;
    if (this.props.onSearch) {
      this.props.onSearch(trainings, trainingAggregations);
    }
  };

  private onKeyPressInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    /* On enter, blur */
    if (event.nativeEvent.keyCode === 13 && this.state.currentSearch) {
      this.input.blur();
    }
  };

  public render(): JSX.Element {
    const { available } = this.props;

    return (
      <div className={cx(styles.SearchInput, { [styles.disabled]: !available })}>
        <input
          id="SearchInputInput"
          ref={(input: HTMLInputElement) => (this.input = input)}
          placeholder={this.props.t("trainings.entity.view.requalification_modal.search", {
            defaultValue: "Rechercher une formation...",
          })}
          autoComplete="off"
          onChange={this.onChange}
          onKeyPress={this.onKeyPressInput}
          style={available ? {} : { display: "none" }}
        />
        <input
          placeholder={this.props.t("trainings.entity.view.requalification_modal.search", {
            defaultValue: "Rechercher une formation...",
          })}
          disabled={true}
          style={!available ? {} : { display: "none" }}
        />
        <button>
          <Icon strokeIcon={magnifyingGlassIcon} width={15} stroke="#fff" />
        </button>
      </div>
    );
  }
}
