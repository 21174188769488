import { createTRPCReact, createTRPCProxyClient, httpBatchLink } from "@trpc/react-query";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

import type { AppRouter } from "@skillup/gepp";

import storage, { allPersistentStorages } from "services/storage";

export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${process.env.REACT_APP_PUBLIC_APP_URL + "gepp-api"}`,
      headers() {
        return {
          "X-Auth-Token": storage.get("X-Auth-Token", allPersistentStorages),
        };
      },
    }),
  ],
  transformer: null,
});
