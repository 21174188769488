import { Flex } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import { Divided } from "../ChildWrappers";
import { Permissions } from "../../Permissions/Permissions";
import { Child } from "../../../../reducer";

import styles from "./FileUploadChild.module.scss";

type FileUpload = Extract<Child, { kind: "upload" }>;

export function FileUploadChild({
  child,
  indexes,
  t,
}: {
  child: FileUpload;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  t: TranslationType;
}) {
  return (
    <Divided>
      <Flex row className={styles.fileUploadChild}>
        {t("interviews.templateBuilder.upload", {
          defaultValue:
            "Le bloc « Téléchargement de fichiers » permet aux participants d’uploader des documents. Ces documents pourront être téléchargés par le collaborateur, le responsable de l’entretien et toutes les personnes habilitées à accéder à l’entretien. Ils seront annexés au PDF de l’entretien.",
        })}
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="upload"
          order={["upload", "comment"]}
          override={{
            comment: t("interviews.templateBuilder.upload.permissions.comment", {
              defaultValue: "Qui peut commenter le bloc ?",
            }),
          }}
          mandatory={{
            upload: t("interviews.templateBuilder.upload.permissions.upload", {
              defaultValue:
                "Vous devez sélectionner au moins un participant qui peut ajouter des fichiers.",
            }),
          }}
          t={t}
        />
      </Flex>
    </Divided>
  );
}
