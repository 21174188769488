import { useEffect, useCallback, useState, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useSetState } from "react-use";
import cx from "classnames";

import { Summary as ISummary } from "@skillup/types";
import { EmployeeTrainingSessionState } from "@skillup/training-bridge";
import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { chevronBottom } from "uiAssets/StrokeIcons";
import Acta from "utils/Acta";

import Icon from "components/Icon";
import InteractiveButton from "components/InteractiveButton";
import Loader from "components/Loader";

import { getStatus } from "../../utils";
import { IRelationCall } from "../../utils/fetchItems";
import fetchPrimary, { IPrimary, IPrimaryCall } from "../../utils/fetchPrimary";

import { ManualTrainingData } from "../../Modals/ManualRowForms/Training";
import DropDownActionMenu from "../DropDownActionMenu";

import ActionFactory from "./ActionFactory";
import styles from "./ActionsStyles.module.scss";

export interface IProps {
  scheduleUuid: string;
  data?: Array<IRelationCall>;
  useCache?: boolean;
  showRequalifyModal: (rows: Array<string>, summary: ISummary, cb: Function) => void;
  showManualEditionProcess: (
    rows: Array<string>,
    training: ManualTrainingData,
    cb: Function
  ) => void;
  showDeleteManualRowsModal: (rows: string[]) => void;
  showPositioningModal: (
    rows: Array<string>,
    noEmail?: boolean,
    noManager?: boolean,
    noEmailManager?: boolean
  ) => void;
  showBookingMailReminderModal: (rows: Array<string>) => void;
  showValidationMailReminderModal: (rows: Array<string>) => void;
  onMoveToPlan: (rows: Array<string>) => Promise<void>;
  summary: ISummary;
  users: Array<{ noEmail: boolean; fullName: string }>;
  sessionsState: Array<EmployeeTrainingSessionState>;
  fetch: Function;
  reloadRows: (rows: string[]) => void;
}

interface IState {
  rows: IPrimary[];
  displayActionMenu: boolean;
  actions: Array<string>;
  noSessions: boolean;
  noEmail?: boolean;
  noManager?: boolean;
  noEmailManager?: boolean;
  isNotManagedBySkillup?: boolean;
  hasReceivedNotification?: boolean;
  tab?: string;
}

let timeout;

const ActionsButton = (props: IProps): JSX.Element => {
  const { reloadRows, scheduleUuid, users, sessionsState } = props;
  const { t } = useTranslation();

  const [
    {
      actions,
      displayActionMenu,
      noSessions,
      noEmail,
      noManager,
      noEmailManager,
      rows,
      isNotManagedBySkillup,
      hasReceivedNotification,
      tab,
    },
    setState,
  ] = useSetState<IState>({
    actions: [],
    displayActionMenu: false,
    noSessions: false,
    rows: [],
    noEmail: (props.data || []).some((row) => row.user.noEmail),
    noManager: (props.data || []).some((row) => row.user.noManager),
    noEmailManager: (props.data || []).some((row) => row.user.noEmailManager),
  });
  const history = useHistory();

  const [currentModal, setCurrentModal] = useState<ReactElement | null>(null);

  const processData = useCallback(
    ({ rows, actions, state, tab }: IPrimaryCall) => {
      if (rows.length !== 0) {
        setState({
          actions,
          noSessions: state.noSessions,
          rows,
          isNotManagedBySkillup: state.isNotManagedBySkillup,
          hasReceivedNotification: state.hasReceivedNotification === true,
          tab,
        });
      }
    },
    [setState]
  );

  const fetch = useCallback(
    async (data: Array<IRelationCall>) => {
      try {
        const response = await fetchPrimary(
          data.map((d) => d.uuid),
          props.useCache
        );
        processData(response);
      } catch (err) {
        console.error(err);
      }
    },
    [processData, props.useCache]
  );

  useEffect(() => {
    setState({
      noEmail: (props.data || []).some((row) => row.user.noEmail),
      noManager: (props.data || []).some((row) => row.user.noManager),
      noEmailManager: (props.data || []).some((row) => row.user.noEmailManager),
    });

    if (props.data) {
      setState({ rows: [], displayActionMenu: false });
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      if (props.data.length) {
        timeout = setTimeout(async () => await fetch(props.data), 300);
      }
    }
  }, [fetch, props.data, setState]);

  const openBookingPage = () => {
    if (props.data && props.data[0]) {
      const row = props.data[0].uuid;
      window.open(`${process.env.REACT_APP_PUBLIC_APP_URL}entreprise/inscription/${row}`);
      Acta.dispatchEvent("closeModal");
    }
  };

  const selectedRows = (props.data ?? []).map((i) => i.uuid);
  const reloadSelection = useCallback(() => {
    reloadRows(selectedRows);
  }, [reloadRows, selectedRows]);

  const actionFactory = ActionFactory(
    props,
    rows,
    history,
    selectedRows,
    tab,
    noEmail,
    noManager,
    noEmailManager,
    reloadSelection,
    noSessions,
    openBookingPage,
    hasReceivedNotification,
    scheduleUuid,
    setCurrentModal,
    t
  );

  const toggleActionMenu = (): void => setState({ displayActionMenu: !displayActionMenu });

  const computeWarning = (data) => {
    const statuses = getStatus({ data });

    if (isNotManagedBySkillup && tab !== "plan-approved") {
      return {
        key: "notManagedBySkillup",
        options: {
          defaultValue: "Cette inscription a été réalisée hors Skillup.",
        },
      };
    }

    return statuses[0];
  };

  const { data } = props;
  const actionData = actions.map(actionFactory).filter(Boolean);
  const primaryAction = actionData[0];
  const actionsForMenu = actionData.slice(1);
  const warning = computeWarning(data);

  const noSessionAvailableWarning =
    primaryAction?.actionKey === "canBook" &&
    primaryAction?.label === "Ajouter une session" &&
    selectedRows.length <= 1;

  return (
    <div className={styles.Actions}>
      {rows?.length === 0 ? (
        <div className={styles.loader}>
          <Loader width={30} />
        </div>
      ) : (
        <>
          {noSessionAvailableWarning && (
            <div className={styles.Alert}>
              <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                {t("trainingSchedule.sidePanel.actions.noSessionAvailableWarning", {
                  defaultValue:
                    "{{ name }} est déjà inscrit à la seule session disponible. Créer une session pour l'inscrire.",
                  name: users[0].fullName,
                  count: sessionsState.length,
                })}
              </DSAlert>
            </div>
          )}
          {!noSessionAvailableWarning && warning && data?.length === 1 && (
            <div className={styles.labelsContainer}>
              <p>
                {t(`trainings.view.schedule_row.sidepanel.infos.${warning.key}`, warning.options)}
              </p>
            </div>
          )}
          <div className={cx(styles.wrapper, styles.actions)}>
            <>
              {actions.length === 0 ? (
                <p>
                  {rows.length > 1
                    ? t(`trainings.view.schedule_row.sidepanel.error.noActionsFound`, {
                        defaultValue: "Ces demandes de formation n'ont aucune action en commun",
                      })
                    : t(`trainings.view.schedule_row.sidepanel.error.noActionsAvailable`, {
                        defaultValue: "Aucune action disponible pour cette demande",
                      })}
                </p>
              ) : (
                <>
                  {primaryAction && (
                    <div className={styles.buttons}>
                      <InteractiveButton
                        background="linear-gradient(90deg, #F9AC00, #FFD65A)"
                        color="#444"
                        label={primaryAction.label}
                        onClick={primaryAction.action}
                        size="small"
                        name={primaryAction.actionKey}
                      />
                    </div>
                  )}
                  {actionsForMenu && actionsForMenu.length > 0 && (
                    <>
                      <div
                        className={cx(styles.showMore, {
                          [styles.clicked]: displayActionMenu,
                        })}
                        onClick={toggleActionMenu}
                      >
                        <span>
                          {t(`trainings.view.schedule_row.sidepanel.actions.placeholder`, {
                            defaultValue: "Autres actions",
                          })}
                        </span>
                        <Icon strokeIcon={chevronBottom} width={12} />
                      </div>

                      {displayActionMenu && (
                        <>
                          <div className={styles.background} onClick={toggleActionMenu} />
                          <DropDownActionMenu actions={actionsForMenu} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
          {currentModal}
        </>
      )}
    </div>
  );
};

export default ActionsButton;
