import React, { useMemo, useRef, useState, type FormEvent } from "react";
import cx from "classnames";
import { useDebouncedCallback } from "use-debounce";
import { MdFilterAlt, MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { type Column, type Table } from "@tanstack/react-table";

import { ColumnType, type Column as ColumnDef, type DataTableRow, type FilterValue, type TableFilters } from "../types";

import { type Themes } from "components/commonProps";
import { Tooltip } from "components/Tooltip/Tooltip";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Form/Checkbox";
import { DSPopover as Popover } from "components/Popover";
import { TextInput } from "../../../Legacy_components/TextInput";
import { NumberInput } from "../../../Legacy_components/NumberInput";
import { SelectFilter } from "../components/SelectFilter";
import { SearchSelectFilter as SearchSelect } from "../components/SearchSelectFilter";
import { useCellWidth } from "../hooks/useCellWidth";
import { useParentOffset } from "../hooks/useParentOffset";

import styles from "./TableHeaderCell.styles.module.scss";

function TableHeaderActions({
  column,
  columnDef,
  theme,
  filters,
  onFilter,
  onCloseFilter,
  largeFilterForm,
  mode,
  isSelectedFilter,
  actionsSide,
}) {
  return (
    <div
      className={cx(styles.actions, {
        [styles.right]: actionsSide === "right",
        [styles.left]: actionsSide === "left",
      })}
    >
      {column.getCanSort() && (
        <div
          aria-label={`sort-${column.id}`}
          className={cx(styles.headerIcon, styles[mode], {
            [styles.selectedIcon]: !!column.getIsSorted(),
          })}
          onClick={column.getToggleSortingHandler()}
        >
          <Button
            buttonSize="S"
            label="Sort"
            theme={theme}
            icon={
              column.getIsSorted() === "desc" ? (
                <MdArrowUpward size={16} />
              ) : (
                <MdArrowDownward size={16} />
              )
            }
            iconOnly
          />
        </div>
      )}
      {columnDef.filterable !== false && (
        <FilterIcon
          className={cx({
            [styles.selectedIcon]: isSelectedFilter,
          })}
          column={column}
          theme={theme}
          filters={filters}
          onSetFilter={(value) => onFilter(columnDef, value)}
          onClose={onCloseFilter}
          large={largeFilterForm}
          mode={mode}
        />
      )}
    </div>
  );
}

export function TableHeaderCell<R extends DataTableRow>({
  column,
  table,
  filters,
  theme,
  onFilter,
  onCloseFilter,
  largeFilterForm,
  mode = "large",
  hasScrolled,
  className,
}: {
  readonly column: Column<R>;
  readonly table: Table<any>;
  readonly filters: TableFilters<R>;
  readonly theme: Themes;
  readonly onFilter: (column: ColumnDef<R>, value: FilterValue) => void;
  readonly onCloseFilter?: () => void;
  readonly largeFilterForm?: boolean;
  readonly isTableOverflow?: boolean;
  readonly hasScrolled?: boolean;
  readonly mode?: "compact" | "large";
  readonly className?: string;
}) {
  const cellWidth = useCellWidth(
    `td[data-key="dt-td-${column.id}"]`,
    table.getState().pagination.pageIndex
  );
  const parentOffset = useParentOffset<R>(column, table, mode);

  const isSelectedFilter = filters[column.id] != null;
  const columnDef = column.columnDef.meta as ColumnDef<R> & { checkboxes: boolean };

  const width = useMemo(() => {
    let result =
      table.getState().columnSizing[column.id] > 1
        ? table.getState().columnSizing[column.id]
        : undefined;
    if (cellWidth && result && cellWidth > result) {
      result = cellWidth;
    }
    return result;
  }, [cellWidth, table.getState().columnSizing]);

  const actionProps = {
    mode,
    theme,
    column,
    filters,
    onFilter,
    onCloseFilter,
    columnDef,
    largeFilterForm,
    isSelectedFilter,
  };

  return (
    <div
      className={cx(
        styles.TableHeaderCell,
        styles[mode],
        columnDef.type && styles[`col-type-${columnDef?.type}`],
        {
          [styles.iconOnly]: columnDef.icon,
          [styles.sticky]: columnDef.sticky,
          [styles.scroll]: hasScrolled,
        },
        className,
        columnDef.className
      )}
      style={
        {
          width,
          minWidth: width,
          maxWidth: width,
          "--parent-offset": `${parentOffset}px`,
        } as React.CSSProperties
      }
      key={column.id}
      data-key={`dt-th-${column.id}`}
    >
      <div
        className={cx(styles.wrapper, {
          [styles.showFilters]: !!column.getIsSorted() || isSelectedFilter,
          [styles.alignRight]: columnDef.headAlign === "right",
          [styles.alignCenter]: columnDef.headAlign === "center",
          [styles.alignLeft]: columnDef.headAlign === "left" || columnDef.headAlign === undefined,
        })}
      >
        <TableHeaderActions actionsSide="left" {...actionProps} />
        <div
          className={cx(styles.headerTitle, {
            [styles.hasIcon]: columnDef.icon,
          })}
        >
          {columnDef.icon && columnDef.title && (
            <Tooltip
              label={columnDef.title}
              className={styles.iconTip}
              theme={theme}
              delayShow={300}
              withPortal
            >
              {columnDef.icon}
            </Tooltip>
          )}
          {columnDef.icon && !columnDef.title && columnDef.icon}
          {!columnDef.icon && columnDef.title && (
            <Tooltip
              label={columnDef.title}
              theme={theme}
              delayShow={300}
              direction="top"
              withPortal
              enabledOnOverflow
            >
              {columnDef.title}
            </Tooltip>
          )}
        </div>
        <TableHeaderActions actionsSide="right" {...actionProps} />
      </div>
    </div>
  );
}

function FilterIcon<R extends DataTableRow>({
  className,
  column,
  theme,
  filters,
  onSetFilter,
  onClose,
  large,
  mode = "large",
}: {
  className: string;
  column: Column<R>;
  theme: Themes;
  onSetFilter: (value: FilterValue) => void;
  onClose?: () => void;
  filters: TableFilters<R>;
  large?: boolean;
  mode?: "compact" | "large";
}) {
  const [closePopover, setClosePopover] = useState<() => void>();
  const [isOpen, setOpen] = useState(false);
  const value = useRef<FilterValue>(filters?.[column.id]);

  function handleOpen() {
    setOpen(true);
  }
  function handleChange(v: FilterValue) {
    onSetFilter(v);
  }
  function handleClose() {
    handleChange(value.current);
    setOpen(false);
    onClose?.();
  }

  return (
    <Popover
      content={() => (
        <FilterForm
          value={value}
          column={column}
          theme={theme}
          onChange={handleChange}
          onSubmit={closePopover}
          large={large}
        />
      )}
      actions={({ close }) => {
        setClosePopover(() => close);
      }}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <div
        className={cx(styles.headerIcon, styles[mode], className, {
          [styles.selectedIcon]: isOpen,
          [styles.active]: isOpen,
        })}
        aria-label={`filter-${column.id}`}
      >
        <div className={cx(styles[mode], styles.badge)} />
        <Button
          buttonSize="S"
          label="Filter"
          theme={theme}
          icon={<MdFilterAlt size={16} />}
          iconOnly
        />
      </div>
    </Popover>
  );
}
function FilterForm<R extends DataTableRow>({
  column,
  value,
  theme,
  onChange,
  onSubmit,
  large,
}: {
  column: Column<R>;
  value: React.MutableRefObject<FilterValue>;
  theme: Themes;
  onChange?: (v: FilterValue) => void;
  onSubmit?: () => void;
  large?: boolean;
}) {
  function handleClear() {
    value.current = undefined;
    onSubmit?.();
  }
  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    onSubmit?.();
  }
  function handleBlur(e: FormEvent) {
    const container = e.currentTarget;
    setTimeout(() => {
      if (!container?.contains(document.activeElement)) onSubmit?.();
    });
  }

  const hasFilterValue = useMemo(() => value.current != null, [value]);

  return (
    <form
      tabIndex={-1}
      className={cx(styles.filterForm, { [styles.largeFilterForm]: large })}
      onBlur={handleBlur}
      onSubmit={handleSubmit}
    >
      <div className={styles.filterHeader}>
        <div className={styles.filterTitle}>Filtrer</div>
        {hasFilterValue && (
          <Button
            type="button"
            theme={theme}
            emphasis="Low"
            buttonSize="S"
            label="Effacer"
            onClick={handleClear}
            className={styles.filterClear}
          />
        )}
      </div>
      <FilterInput<R> value={value} column={column} onChange={onChange} />
    </form>
  );
}

const FilterInput = <R extends DataTableRow>({ value, column, onChange }) => {
  const columnDef = column.columnDef.meta as ColumnDef<R>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentValue, setCurrentValue] = useState<FilterValue>(value.current);

  function handleChange(v: FilterValue) {
    value.current = v;
    setCurrentValue(v);
    onChange?.(v);
  }
  function handleCheckboxChange() {
    return handleChange(!value.current);
  }
  // function handleOptionChange(key: string) {
  //   const v = getDefaultOptionsSet();
  //   if (v.has(key)) {
  //     v.delete(key);
  //   } else {
  //     v.add(key);
  //   }
  //   if (v.size === column.options?.length) handleChange(undefined);
  //   else handleChange(v);
  // }

  function handleSetOptionChange(v?: Set<string>) {
    if (v?.size === columnDef.options?.length) handleChange(undefined);
    else handleChange(v);
  }

  const debouncedChange = useDebouncedCallback(() => {
    onChange?.(currentValue);
  }, 300);
  function handleInputChange(v: FilterValue) {
    value.current = v;
    setCurrentValue(v);
    debouncedChange();
  }
  // function getDefaultOptionsSet() {
  //   if (value.current instanceof Set) return new Set(value.current);
  //   return new Set(column.options?.map((o) => o.value));
  // }

  if (columnDef.renderFilter) {
    return (
      <>
        {columnDef.renderFilter({
          column: columnDef,
          value: currentValue,
          onChange: handleChange,
        })}
      </>
    );
  }
  switch (columnDef.type) {
    case ColumnType.NUMBER:
      return (
        <div className={styles.filterContent}>
          <NumberInput
            className={styles.filterInput}
            placeholder={columnDef.title}
            defaultValue={value.current as number}
            onChange={handleInputChange}
            autoFocus
          />
        </div>
      );
    case ColumnType.DATE:
      return (
        <div className={styles.filterContent}>
          <TextInput
            type="date"
            className={styles.filterInput}
            placeholder={columnDef.title}
            defaultValue={value.current as string}
            onChange={handleInputChange}
            autoFocus
          />
        </div>
      );
    case ColumnType.SELECT:
      // const checkboxValues = getDefaultOptionsSet();
      return (
        <div className={cx(styles.filterContent)}>
          <SelectFilter
            options={columnDef.options?.map((e) => ({ ...e, display: true })) ?? []}
            values={value.current as Set<string>}
            onChange={handleSetOptionChange}
          />
        </div>
      );
    case ColumnType.BOOL:
      const checkboxValue = typeof value.current === "boolean" ? value.current : undefined;
      return (
        <div className={styles.filterContent}>
          <Checkbox
            className={styles.filterInput}
            value={column.id}
            checked={checkboxValue}
            onChange={handleCheckboxChange}
            label={columnDef.title as string}
            name={columnDef.title as string}
          />
        </div>
      );
    case ColumnType.SEARCH_SELECT:
      return (
        <div className={styles.filterContent}>
          <SearchSelect
            options={columnDef.options ?? []}
            values={value.current as Set<string>}
            onChange={handleSetOptionChange}
          />
        </div>
      );

    default:
      return (
        <div className={styles.filterContent}>
          <TextInput
            className={styles.filterInput}
            placeholder={columnDef.title}
            defaultValue={value.current as string}
            onChange={handleInputChange}
            autoFocus
          />
        </div>
      );
  }
};
