import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DSButton,
  DSCard,
  DSFilters,
  useUrlSyncedFilters,
  DSPopover,
  DSAvatar,
  Label as DSLabel,
  Flex,
  Select as DSSelect,
  DSNumberInput,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
} from "@skillup/ui";
import { CampaignEventTrigger } from "@skillup/espace-rh-bridge";
import { useAreas } from "hooks";
import { useFilteredPopulation } from "../../hooks/useFilteredPopulation";
import { filtersConfigInitialValues, getPopulationFiltersConfig } from "../../helper";
import styles from "./Steps.module.scss";

interface IProps {
  readonly duration: number;
  readonly setDuration: (duration: number) => void;
  readonly filters: CampaignEventTrigger;
  readonly setFilters: (filters?: CampaignEventTrigger) => void;
}

const AutomationStep = (props: IProps) => {
  const { t } = useTranslation();
  const [displayTooltip, setDisplayTooltip] = useState(true);
  return (
    <div className={styles.CardWrapper}>
      <FilterOnPopulation t={t} filters={props.filters} setFilters={props.setFilters} />
      <TriggerSetup
        t={t}
        filters={props.filters}
        setFilters={props.setFilters}
        duration={props.duration}
        setDuration={props.setDuration}
      />
      {displayTooltip && (
        <DSAlert
          type={DSAlertType.INFO}
          display={DSAlertDisplay.INLINE}
          onClose={() => setDisplayTooltip(false)}
          closeButton
          className={styles.Info}
        >
          {t("interviews.campaigncreation.automated.description1", {
            defaultValue: `Au lancement de la campagne, puis quotidiennement, des entretiens seront créés pour chaque collaborateur correspondant à ces critères et n’ayant pas encore été concernés par l’automatisation.`,
          })}
          <br />
          {t("interviews.campaigncreation.automated.description2", {
            defaultValue: `Cette automatisation n’est pas rétroactive et ne concernera donc pas les collaborateurs qui auraient correspondu aux critères dans le passé.`,
          })}
        </DSAlert>
      )}
    </div>
  );
};

export default AutomationStep;

const FilterOnPopulation = ({ t, filters: globalFilters, setFilters }) => {
  const { allAreas } = useAreas();

  const config = useMemo(() => getPopulationFiltersConfig(t), [t]);
  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(
    config,
    filtersConfigInitialValues(allAreas, t)
  );

  const { usersUuids, usersData } = useFilteredPopulation(filterValues);

  const setFilterHandler = (p) => {
    setFilterValues(p);
    setFilters({ ...globalFilters, filter: p });
  };

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.AutomationStep} showTitle={false}>
        <span className={styles.title}>
          {t("interviews.campaigncreation.automated.title1", {
            defaultValue: "Population concernée",
          })}
        </span>
        <div className={styles.filters}>
          <DSFilters t={t} config={config} filters={filters} onChange={setFilterHandler} />
        </div>
        <div className={styles.popnow}>
          <p>
            {t("interviews.campaigncreation.automated.users", {
              defaultValue:
                "À titre d’information, {{ count }} collaborateur correspond aujourd’hui à vos critères.",
              defaultValue_other: `À titre d’information, {{ count }} collaborateurs correspondent aujourd’hui à vos critères.`,
              count: usersUuids?.total,
            })}
          </p>
          {usersUuids?.total > 0 && (
            <DSPopover content={() => <PopoverContent usersData={usersData} />}>
              <DSButton
                buttonSize="S"
                emphasis="Low"
                label={t("interviews.campaigncreation.automated.viewusers", {
                  defaultValue: "Voir le collaborateur",
                  defaultValue_other: "Voir les collaborateurs",
                  count: usersUuids?.total,
                })}
              />
            </DSPopover>
          )}
        </div>
      </DSCard>
    </div>
  );
};

const PopoverContent = ({ usersData }) => {
  return (
    <div className={styles.popover}>
      {usersData?.map((userData) => (
        <div className={styles.popoverContent}>
          <DSAvatar initials={userData?.initials} randomColorSeedString={userData?.uuid} />
          <p>{userData?.fullName}</p>
        </div>
      ))}
    </div>
  );
};

const TriggerSetup = ({ t, filters, setFilters, duration, setDuration }) => {
  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.Trigger} showTitle={false}>
        <span className={styles.title}>
          {t("interviews.campaigncreation.automated.title2", {
            defaultValue: "Déclencheur",
          })}
        </span>
        <Flex className={styles.block1}>
          <Flex column>
            <DSLabel
              label={t("interviews.campaigncreation.automated.reftriggerlabel", {
                defaultValue: "Date ou événement de référence",
              })}
            />
            <p className={styles.triggerEvent}>
              {t("interviews.campaigncreation.automated.reftriggervalue", {
                defaultValue: "Date de début de contrat",
              })}
            </p>
          </Flex>
          <Flex column>
            <DSLabel
              label={t("interviews.campaigncreation.automated.triggerlabel", {
                defaultValue: "Déclenchement de l'automatisation",
              })}
              required
            />
            <Flex>
              <DSNumberInput
                value={filters.offset?.toString()}
                placeholder="30"
                min={0}
                onChange={(e) => setFilters({ ...filters, offset: parseInt(e) })}
                className={styles.number}
              />
              <DSSelect
                options={[
                  {
                    value: "days",
                    label: t("interviews.campaigncreation.automated.days", {
                      defaultValue: "jour(s)",
                    }),
                  },
                  {
                    value: "weeks",
                    label: t("interviews.campaigncreation.automated.weeks", {
                      defaultValue: "semaine(s)",
                    }),
                  },
                  {
                    value: "months",
                    label: t("interviews.campaigncreation.automated.month", {
                      defaultValue: "mois",
                    }),
                  },
                  {
                    value: "years",
                    label: t("interviews.campaigncreation.automated.years", {
                      defaultValue: "année(s)",
                    }),
                  },
                ]}
                defaultValue={{
                  value: "days",
                  label: t("interviews.campaigncreation.automated.days", {
                    defaultValue: "jour(s)",
                  }),
                }}
                className={styles.select}
                value={filters.offsetUnit}
                onChange={(e) => setFilters({ ...filters, offsetUnit: e })}
              />
              <DSSelect
                options={[
                  {
                    value: "after",
                    label: t("interviews.campaigncreation.automated.after", {
                      defaultValue: "après",
                    }),
                  },
                ]}
                defaultValue={{
                  value: "after",
                  label: t("interviews.campaigncreation.automated.after", {
                    defaultValue: "après",
                  }),
                }}
                value={filters.offsetDirection}
                onChange={(e) => setFilters({ ...filters, offset_filter: e })}
                className={styles.selectDirection}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex className={styles.block2}>
          <Flex column>
            <DSLabel
              label={t("interviews.campaigncreation.automated.duration", {
                defaultValue: "Délai de signature",
              })}
              required
            />
            <Flex>
              <DSNumberInput
                placeholder={"30"}
                className={styles.number}
                min={0}
                value={duration?.toString()}
                onChange={(e) => setDuration(parseInt(e))}
              />
              <p>
                {t("interviews.campaigncreation.automated.days", {
                  defaultValue: "jour(s)",
                })}
              </p>
            </Flex>
          </Flex>
        </Flex>
        <p className={styles.caption}>
          {t("interviews.campaigncreation.automated.durationcaption", {
            defaultValue: "Durée au bout de laquelle l'entretien devra avoir été conduit et signé.",
          })}
        </p>
      </DSCard>
    </div>
  );
};
