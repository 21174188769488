import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";

import HabilitationForm, { IForm, ValidateForm, BuildPayloadFromForm } from "../HabilitationForm";

import {
  HabilitationValidity,
  matchingFields,
  Payload,
} from "../../components/HabilitationForm/config";
import styles from "./CreateHabilitation.module.scss";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import { useFormState } from "../../state/formState";

interface Props {
  readonly onClose: () => void;
}

const createHabilitation = async (payload: Payload) => {
  const request = await buildSafeRequest<HabilitationsRoutes.Create>({
    method: "POST",
    path: "/habilitations",
    payload,
  });

  return request.run();
};

export default ({ onClose }: Props) => {
  const { t } = useTranslation();
  const durationTypeDefaultValue = matchingFields.find(
    (el) => el.field === "duration"
  ).defaultValue;
  const deadlineAnticipationTypeDefaultValue = matchingFields.find(
    (el) => el.field === "deadlineAnticipation"
  ).defaultValue;

  const [form, setFormState] = useSetState<IForm>({
    validity: {
      value: HabilitationValidity.LIMITED,
    },
    durationType: {
      value: durationTypeDefaultValue,
    },
    deadlineAnticipationType: {
      value: deadlineAnticipationTypeDefaultValue,
    },
    deadlineAnticipation: undefined,
    duration: undefined,
  });
  const { addToast } = useToasts();

  const submitRequest = async () => {
    const request = createHabilitation(BuildPayloadFromForm(form));

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la création de l'habilitation.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_habilitation) => {
        addToast(
          t("habilitation.creation.success", { defaultValue: "Habilitation créée avec succès" }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );
  };

  const handleMainAction = () => {
    const { form: newForm, hasError } = ValidateForm(form);

    if (hasError) {
      setFormState(newForm);
    }
    if (!hasError) {
      submitRequest();
    }
  };

  const { disabled, tooltip } = useFormState(form);

  return (
    <Modal
      title={t("trainings.view.regulatory.form.add_habilitation.title", {
        defaultValue: "Ajouter une habilitation",
      })}
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.createHabilitation}>
        <HabilitationForm form={form} setFormState={setFormState} />
        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label={t("common.action.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSButton
            className={styles.button}
            disabled={disabled}
            emphasis="High"
            tooltip={tooltip}
            label={t("common.action.confirm", { defaultValue: "Confirmer" })}
            onClick={handleMainAction}
          />
        </div>
      </div>
    </Modal>
  );
};
