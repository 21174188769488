import { Flex, Text } from "@skillup/design-system";
import styles from "./ReadOnlyHistoricalDataConfiguration.module.scss";
import { DSButton } from "@skillup/ui";
import { HistoricalDataConfiguration } from "../../../services";
import useTranslation from "hooks/useTranslation";

type ReadOnlyHistoricalDataConfigurationProps = {
  configuration: HistoricalDataConfiguration;
  enableEdit: () => void;
  historicalDataLabelMap: Record<keyof HistoricalDataConfiguration, string>;
};

export const ReadOnlyHistoricalDataConfiguration = ({
  configuration,
  enableEdit,
  historicalDataLabelMap,
}: ReadOnlyHistoricalDataConfigurationProps) => {
  const { t } = useTranslation();

  const labels = Object.keys(configuration)
    .filter((key) => configuration[key] === true)
    .map((key) => historicalDataLabelMap[key]);

  return (
    <Flex gap="s" flexDirection="column">
      <Flex gap="xxs">
        <Text espaceFont="h5">
          {t("employeeProfile.historicalData.title", {
            defaultValue: "Accès aux données d’historique",
          })}
        </Text>
      </Flex>
      <Flex gap="xxs" flexDirection="column">
        <Flex>
          <Text espaceFont="captionBold">
            {t("employeeProfile.historicalData.readOnly.subTitle", {
              defaultValue: "Données accessibles",
            })}
          </Text>
        </Flex>
        <Flex
          className={styles.historicalDataList}
          gap="xxs"
          padding="s"
          flexDirection="column"
          backgroundColor="surface-light"
        >
          {labels.length === 0 && (
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {t("employeeProfile.historicalData.readOnly.noDataSelected", {
                defaultValue: "Aucun historique accessible",
              })}
            </Text>
          )}
          {labels.map((label) => (
            <Text espaceFont="body1Regular" color="plainText-onLight-default">
              {label}
            </Text>
          ))}
        </Flex>
      </Flex>
      <Flex>
        <DSButton
          label={t("employeeProfile.historicalData.readOnly.btn.edit", {
            defaultValue: "Modifier",
          })}
          buttonSize="S"
          emphasis="Mid"
          onClick={enableEdit}
        />
      </Flex>
    </Flex>
  );
};
