import React, { useEffect } from "react";
import { useSetState } from "react-use";
import { DSButton } from "@skillup/ui";

import Acta from "utils/Acta";
import { useTypedFetch } from "hooks";

import Review from "./Review";

import styles from "./CancelReviewModal.module.scss";
import { buildRequest } from "utils/buildRequest";

import type { ReviewsRoutes } from "@skillup/espace-rh-bridge";
import useTranslation from "hooks/useTranslation";

export default function CancelReviewModal({ uuid }) {
  const { data: review } = useTypedFetch<ReviewsRoutes.GetReviewsForRowRequest>({
    method: "GET",
    path: "/review/get-review/{rowUuid}",
    query: {
      rowUuid: uuid,
    },
  });

  const [state, setState] = useSetState<{ hot: boolean; cold: boolean }>({
    hot: false,
    cold: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setState({
      ...(review?.hot && {
        hot: !["abandoned", "cancelled", "completed"].includes(review?.hot?.state),
      }),
      ...(review?.cold && {
        cold: !["abandoned", "cancelled", "completed"].includes(review?.cold?.state),
      }),
    });
  }, [setState, review]);

  const cancelReview = async () => {
    try {
      const reviewsUuid = [];

      if (state.hot && review?.hot?.uuid) {
        reviewsUuid.push(review?.hot?.uuid);
      }

      if (state.cold && review?.cold?.uuid) {
        reviewsUuid.push(review?.cold?.uuid);
      }

      await buildRequest<ReviewsRoutes.CancelReviewRequest>({
        method: "POST",
        path: `/review/cancel-review`,
        payload: {
          reviewsUuid,
        },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.cancel_review_from_row.success", {
          defaultValue:
            "Le collaborateur ne recevra plus d’email pour l’évaluation de cette formation",
        }),
        type: "success",
      });
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.cancel_review_from_row.error", {
          defaultValue: "Une erreur s'est produite lors de l'annulation de l'évaluation.",
        }),
        type: "error",
      });
    } finally {
      Acta.dispatchEvent("closeModal");
    }
  };

  if (!review?.hot) {
    return (
      <div className={styles.noReview}>
        {t("trainings.view.cancel_review_from_row.no_reviews", {
          defaultValue: "Aucune évaluation n'est associée avec cette ligne.",
        })}
      </div>
    );
  }

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.info}>
        {t("trainings.view.cancel_review_from_row.description", {
          defaultValue:
            "Cette action bloquera l’envoi de futurs emails à ce collaborateur pour cette formation.",
        })}
      </div>
      <Review
        review={review?.hot}
        setState={setState}
        checked={state[review?.hot?.scope]}
        scope="hot"
      />
      <Review
        review={review?.cold}
        setState={setState}
        checked={state[review?.cold?.scope]}
        scope="cold"
      />
      <DSButton
        className={styles.cancelButton}
        disabled={!state.cold && !state.hot}
        onClick={cancelReview}
        label={t("trainings.view.cancel_review_from_row.action.confirm", {
          defaultValue: "Annuler l'évaluation",
          count: state.cold && state.hot ? 2 : 1,
        })}
      />
    </div>
  );
}
