import { useRef, Suspense } from "react";
import { useHistory } from "react-router-dom";

import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";

import { Flex, Text, Loader } from "@skillup/design-system";
import { FilterRef, DSFilters, DSDataGrid, usePersistColumnSettings } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import useJobsTableData from "../utils/useJobsTableData";
import { useJobsGridFilters } from "../utils/useJobsGridFilters";
import { AnalyticsProvider, useAnalyticsContext } from "../OverviewContext";


const JobsOverview = () => {
  return (
    <AnalyticsProvider>
      <Flex width="100%" height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </AnalyticsProvider>
  );
};

const Layout = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const filterRef = useRef<FilterRef>();
  const { getJobAnalytics } = useAnalyticsContext();

  const [conf, filters, filterValues, setFilterValues] = useJobsGridFilters(t);

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
      setFilterValues(filterValues);
    }
  };

  const apiRef = useGridApiRef();
  const { gridInitialState } = usePersistColumnSettings({
    apiRef,
    persistenceId: `grid-skills-jobs-overview.v3`,
  });
  const initialState = {
    ...gridInitialState,
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 20,
      },
    },
  };
  const { data: jobs, status } = getJobAnalytics(filterValues);
  const { columns, rows: tableData } = useJobsTableData({
    jobs,
    t,
  });

  const rows =
    tableData ||
    []
      ?.filter((row) =>
        filterValues?.name?.value
          ? row.name.toLowerCase().includes(filterValues?.name?.value.toLowerCase())
          : true
      )
      .filter((row) =>
        filterValues?.category?.value
          ? row.category.toLowerCase().includes(filterValues?.category?.value.toLowerCase())
          : true
      );

  if (status === "loading") return <Loader fillSpace />;

  const handleRowClick = (row) => {
    const searchParams = new URLSearchParams(location.search);

    history.push(`/responsable/analyses/fiche-de-poste/${row.id}?${searchParams.toString()}`);
  };

  return (
    <Flex paddingTop="s" paddingHorizontal="s" flexDirection="column">
      <Text
        marginTop="xxs"
        marginBottom="s"
        espaceFont="captionBold"
        color="plainText-onLight-lighter"
      >
        {t("skills.list.job.overviewInfo", {
          defaultValue:
            "La liste des fiches de postes ci-dessous inclut uniquement celles qui ont fait l'objet d'une évaluation.",
        })}
      </Text>
      <DSFilters t={t} config={conf} ref={filterRef} filters={filters} onChange={setFilterValues} />

      <DSDataGrid
        editable
        pagination
        rows={rows}
        apiRef={apiRef}
        columns={columns}
        rowCount={rows.length}
        disableRowSelectionOnClick
        initialState={initialState}
        onRowClick={handleRowClick}
        onFilter={handleTableColumnFilterClick}
        emptyOverlay={{
          text: t("skills.list.job.emptyOverlayText", {
            defaultValue: "Aucune fiche de poste ne correspond à votre recherche.",
          }),
        }}
        entityName={
          rows.length > 1
            ? `${rows.length} ${t("skills.list.job.entityName", {
                defaultValue: "fiches de poste",
              })}`
            : `${rows.length} ${t("skills.list.job.singleEntityName", {
                defaultValue: "fiche de poste",
              })}`
        }
        errorOverlay={{
          text: [
            t("skills.list.job.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des fiches de poste.`,
            }),
            t("skills.list.collaborator.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("skills.list.collaborator.errorOverlayText.thirdSentence", {
              defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />
    </Flex>
  );
};

export default JobsOverview;
