import { MdAccountBalanceWallet, MdGroup, MdInfo, MdTimelapse, MdWebStories } from "react-icons/md";

import { DSTooltip, Flex, Loader } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import {
  formatValue,
  formatValueForKeyIndicator,
  formatValueWithUnit,
  getUnitFromDataType,
} from "../Charts/utils/formatter";
import { CardProps } from "./Card";

import styles from "./KeyIndicator.module.scss";

const icons = {
  default: MdInfo,
  total_number_of_concerned_employees: MdGroup,
  total_sum_of_additional_expenses: MdAccountBalanceWallet,
  total_sum_of_financed_amount: MdAccountBalanceWallet,
  total_sum_of_price: MdAccountBalanceWallet,
  total_sum_of_price_with_tax: MdAccountBalanceWallet,
  total_sum_of_real_cost: MdAccountBalanceWallet,
  total_sum_of_real_cost_with_tax: MdAccountBalanceWallet,
  total_sum_of_realized_duration: MdTimelapse,
  total_training_number_of_line: MdWebStories,
};

export const KeyIndicator = ({ data, card, loading }: CardProps) => {
  const { t, i18n } = useTranslation();

  const type = card.question_meta.axes[0].data_type;
  const {
    settings: { currency },
  } = useSettings();

  const row = data?.data[0];
  const [value] = row ?? [];
  const unit = getUnitFromDataType(type, currency);

  const questionLabel = data
    ? t(`training.dashboard.key_indicator.label.${card.question_meta.id}`, {
        defaultValue: card.short_name,
      })
    : "";
  let shortName = data && unit
    ? questionLabel +
      " " +
      t(`tranings.view.dashboard.key_indicator_title_unit.${type}`, {
        defaultValue: "(en {{ unit }})",
        unit,
      })
    : questionLabel;

  const Icon = icons[card.question_meta.id] || icons.default;
  const formatedValue = formatValue(value, type);
  const formatedValueWithUnit = formatValueWithUnit(formatedValue, type, currency, i18n.language);

  return (
    <DSTooltip label={formatedValueWithUnit}>
      <Flex column className={styles.keyIndicator}>
        <Flex className={styles.header}>
          <Icon className={styles.icon} />
          {loading && <Loader />}
          {!loading && (
            <p className={styles.value}>
              {formatValueForKeyIndicator(value, type, i18n.language, {
                billion: t("tranings.view.dashboard.number_unit.billion", {
                  defaultValue: "Mrd",
                }),
                million: t("tranings.view.dashboard.number_unit.million", {
                  defaultValue: "M",
                }),
              })}
            </p>
          )}
        </Flex>
        <p>{shortName}</p>
      </Flex>
    </DSTooltip>
  );
};
