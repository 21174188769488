import React, { useCallback } from "react";
import { useToggle } from "react-use";

import useTranslation from "hooks/useTranslation";
import InteractiveButton from "components/InteractiveButton";

import styles from "./PositioningModalStyles.module.scss";

export interface IProps {
  text: string;
  rows: Array<string>;
  sendEmails: (rows: Array<string>, reminder: true) => Promise<void>;
}

const MailReminderModal = ({ text, rows, sendEmails }: IProps) => {
  const { t } = useTranslation();
  const [sending, toggleSending] = useToggle(false);

  const send = useCallback(async (): Promise<void> => {
    toggleSending(true);
    await sendEmails(rows, true);
    toggleSending(false);
  }, [rows, sendEmails, toggleSending]);

  return (
    <div className={styles.positioningModal}>
      <p style={{ marginBottom: 20 }}>
        {t("trainings.view.pending_schedule_row.property.reminder_modal.description", {
          defaultValue: text ?? "Relancer par email",
        })}
      </p>

      <InteractiveButton
        loading={sending}
        label={t("common.action.send", {
          defaultValue: "Envoyer",
        })}
        size="small"
        onClick={send}
        style={{ fontWeight: 500 }}
      />
    </div>
  );
};

export default MailReminderModal;
