import { FlatButton } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import Acta from "utils/Acta";
import styles from "./ChooseCampaignModale.module.scss";

const ChooseCampaignModale = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const pickOption = (route: string) => () => {
    Acta.dispatchEvent("closeModal");
    history.push(route);
  };

  return (
    <div className={styles.chooseCampaignModale}>
      <FlatButton
        className={styles.option}
        title={t("interviews.legacyCampagin", {
          defaultValue: "Campagne d'entretiens",
        })}
        description={t("interviews.legacyCampaginDescription", {
          defaultValue:
            "Créer une campagne d'entretiens avec la date du lancement et la date limite de signature.",
        })}
        onClick={pickOption("/responsable/campagne/creation-campagne-entretiens")}
      />
      <FlatButton
        className={styles.option}
        title={t("interviews.ongoingCampagin", {
          defaultValue: "Entretiens automatisés",
        })}
        description={t("interviews.ongoingCampaginDescription", {
          defaultValue:
            "Paramétrer des entretiens qui se génèrent automatiquement à une date donnée.",
        })}
        onClick={pickOption("/responsable/campagne/creation-entretiens-automatises")}
      />
      <FlatButton
        className={styles.option}
        title={t("interviews.adHocCampagin", {
          defaultValue: "Entretiens ponctuels",
        })}
        description={t("interviews.adHocCampaginDescription", {
          defaultValue: "Créer des entretiens manuellement pour répondre à des besoins ponctuels.",
        })}
        onClick={pickOption("/responsable/campagne/creation-entretiens-ponctuels")}
      />
    </div>
  );
};

export default ChooseCampaignModale;
