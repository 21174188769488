import { useSortable } from "@dnd-kit/sortable";
import { PropsWithChildren } from "react";
import { CSS } from "@dnd-kit/utilities";
import { Flex } from "@skillup/design-system";
import { DSButton } from "@skillup/ui";
import DragIndicator from "@mui/icons-material/DragIndicator";

export const SortableItem = ({ id, children }: PropsWithChildren<{ id: string }>) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Flex gap="xs">
        <div {...attributes} {...listeners}>
          <DSButton iconOnly icon={<DragIndicator color="action" />} buttonSize="S" />
        </div>
        {children}
      </Flex>
    </div>
  );
};
