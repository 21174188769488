import React, { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";

import { DSSortable, DSButton, Label as DSLabel, DSTextInput } from "@skillup/ui";

import type { Template } from "services/interviews";

import styles from "./TargetSettingsModal.module.scss";
import { Flex } from "@skillup/design-system";
import useTranslation from "hooks/useTranslation";

export const ChoicesSettings = ({
  choices,
  addChoice,
  removeChoice,
  handleChangeChoices,
  handleReorderChoices,
}: {
  choices: {
    id: string;
    title: string;
  }[];
  addChoice: () => void;
  removeChoice: (id: string) => void;
  handleChangeChoices: (value: string, index: number) => void;
  handleReorderChoices: (
    items: {
      id: string;
      title: React.ReactNode;
    }[],
    startIndex: number,
    endIndex: number
  ) => {
    id: string;
    title: string;
  }[];
}) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column">
      <DSLabel
        className={styles.label}
        label={t("interviews.choicesSettings.targetScale", {
          defaultValue: "Échelle d'évaluation pour les objectifs qualitatifs",
        })}
      />
      {choices.length ? (
        <DSSortable
          handle
          removable
          removeAlwaysVisible
          onRemove={removeChoice}
          items={choices.map((choice, index) => ({
            id: choice.id,
            title: (
              <DSTextInput
                name={`${choice.id}-choice-item`}
                onChange={(e) => handleChangeChoices(e, index)}
                placeholder={t("interviews.choicesSettings.placeholder", {
                  defaultValue: "Saisissez un libellé",
                })}
                value={choice.title}
                autoFocus
              />
            ),
          }))}
          // @ts-expect-error the types in the DS are wrong, they should be generic
          reorderItems={handleReorderChoices}
        />
      ) : (
        <p className={styles.noItem}>
          {t("interviews.choicesSettings.noItem", {
            defaultValue: "Aucun niveau d'évaluation défini",
          })}
        </p>
      )}
      <DSButton
        label={t("interviews.choicesSettings.addChoice", {
          defaultValue: "Ajouter un niveau d'évaluation",
        })}
        className={styles.addButton}
        onClick={() => addChoice()}
        buttonSize="S"
        emphasis="Low"
      />
    </Flex>
  );
};

export function useChoices(template: Template) {
  const [choices, setChoices] = useState<
    {
      id: string;
      title: string;
    }[]
  >(
    template.choicesForOrdinalTargets
      ? template.choicesForOrdinalTargets.map((choice) => ({
          id: uuid(),
          title: choice,
        }))
      : []
  );

  const addChoice = useCallback(() => {
    const newChoices = [...choices];
    newChoices.push({
      id: uuid(),
      title: "",
    });
    setChoices(newChoices);
  }, [choices, setChoices]);

  const handleChangeChoices = useCallback(
    (value: string, index: number) => {
      const newChoices = [...choices];
      newChoices[index] = { id: choices[index].id, title: value };
      setChoices(newChoices);
    },
    [choices, setChoices]
  );

  const handleReorderChoices = useCallback(
    (
      items: {
        id: string;
        title: React.ReactNode;
      }[],
      startIndex: number,
      endIndex: number
    ) => {
      const reorderedChoices = [...items];
      const [replaced] = reorderedChoices.splice(startIndex, 1);
      reorderedChoices.splice(endIndex, 0, replaced);

      const newChoices = reorderedChoices.map((choice) => choices.find((c) => c.id === choice.id));
      setChoices(newChoices);
      return newChoices;
    },
    [choices, setChoices]
  );

  const removeChoice = useCallback(
    (id: string) => {
      const newChoices = [...choices];
      const index = newChoices.findIndex((choice) => choice.id === id);
      newChoices.splice(index, 1);

      setChoices(newChoices);
    },
    [choices, setChoices]
  );

  return {
    choices,
    addChoice,
    handleChangeChoices,
    handleReorderChoices,
    removeChoice,
  };
}
