import { DSModal } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import styles from "./CancelModal.module.scss";

export const CancelModal = ({ openModal, setOpenModal, onClose, onCancel }) => {
  const { t } = useTranslation();

  return (
    <DSModal className={styles.modal} isOpen={openModal}>
      <DSModal.Header onClose={() => setOpenModal(false)}>
        <DSModal.Header.Title
          title={t("training.dashboard.config.modal.title", {
            defaultValue: "Abandonner les modifications ?",
          })}
        />
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        <p className={styles.firstText}>
          {t("training.dashboard.config.modal.content.first", {
            defaultValue: `La configuration contient des modifications qui n’ont pas été enregistrées.`,
          })}
        </p>
        <p>
          {t("training.dashboard.config.modal.content.second", {
            defaultValue:
              "Si vous quittez cette page sans avoir enregistré vos modifications, elles seront définitivement perdues.",
          })}
        </p>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("common.action.cancel", { defaultValue: "Annuler" })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={onCancel}
          label={t("common.action.cancel_edition", {
            defaultValue: "Abandonner les modifications",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
