import React from "react";
import SuccessImage from "assets/stepRegistration/success.png";
import ErrorImage from "assets/stepRegistration/error.png";
import ExpiredImage from "assets/stepRegistration/expired.png";
import styles from "./StepRegistrationStyles.module.scss";

import { getFullName } from "utils/User";
import { Button } from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";
interface IProps {
  step: string;
  t: TranslationType;
  trainee: any;
  currentSession: {
    name: string;
    formattedTimespan: string;
    city: string;
    place: string;
  };
}

export default class StepRegistration extends React.PureComponent<IProps, {}> {
  private renderContent = (): React.ReactNode => {
    const { currentSession, step, trainee, t } = this.props;

    switch (step) {
      case "success":
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: t("trainings.view.session_subscribe.form.steps.success.summary", {
                  defaultValue:
                    "{{fullName}} est inscrit(e) à la formation <b>{{sessionName}}</b> {{timespan}} à {{place}}",
                  fullName: getFullName(trainee),
                  sessionName: currentSession.name,
                  timespan: currentSession.formattedTimespan.toLowerCase(),
                  place: currentSession.place,
                }),
              }}
            />
            <br /> <br />
            {t("trainings.view.session_subscribe.form.steps.success.summon_disclaimer", {
              defaultValue: "La convocation sera envoyée quelques semaines avant la formation.",
            })}
          </>
        );
      case "invalid":
        return (
          <>
            {t("trainings.view.session_subscribe.form.steps.invalid.invalid_link", {
              defaultValue: "Le lien que vous avez suivi n’est plus valide.",
            })}
            <br />
            {t("trainings.view.session_subscribe.form.steps.invalid.contact_admin", {
              defaultValue: "Cela peut être dû à plusieurs raisons :",
            })}
            <br />
            {t("trainings.view.session_subscribe.form.steps.invalid.reason_already_registered", {
              defaultValue: "- vous êtes déjà inscrit",
            })}
            <br />
            {t("trainings.view.session_subscribe.form.steps.invalid.reason_session_cancelled", {
              defaultValue: "- la session a été annulée",
            })}
            <br />
            {t("trainings.view.session_subscribe.form.steps.invalid.reason_session_modified", {
              defaultValue:
                "- la session est en cours de modification par votre responsable formation",
            })}
          </>
        );
      case "cancelled":
        return t("trainings.view.session_subscribe.form.steps.cancelled.text", {
          defaultValue: "Cette demande a été annulée par votre responsable formation.",
        });
      case "error":
        return t("trainings.view.session_subscribe.form.steps.error.text", {
          defaultValue:
            "L’inscription n’a pu aboutir en raison d’un problème technique. Veuillez ré-essayer plus tard",
        });
      case "full":
        return t("trainings.view.session_subscribe.form.steps.full.text", {
          defaultValue:
            "L’inscription n’a pu aboutir car la date sélectionnée n’est plus disponible",
        });
      default:
        return "";
    }
  };

  public render(): JSX.Element {
    const { step, t } = this.props;

    const steps = {
      cancelled: {
        title: t("trainings.view.session_subscribe.form.steps.cancelled.title", {
          defaultValue: "Demande annulée",
        }),
      },
      success: {
        title: t("trainings.view.session_subscribe.form.steps.success.title", {
          defaultValue: "L'inscription est terminée",
        }),
      },
      error: {
        title: t("trainings.view.session_subscribe.form.steps.error.title", {
          defaultValue: "Erreur",
        }),
      },
      invalid: {
        title: t("trainings.view.session_subscribe.form.steps.invalid.title", {
          defaultValue: "Lien expiré",
        }),
      },
      full: {
        title: t("trainings.view.session_subscribe.form.steps.full.title", {
          defaultValue: "Session complète",
        }),
      },
    };

    return (
      <div className={styles.StepRegistration}>
        <header>
          <h1>{steps[step].title}</h1>
        </header>
        <div className={styles.training}>
          <div>
            {step === "success" && <img src={SuccessImage} alt="success" />}
            {(step === "error" || step === "cancelled" || step === "full") && (
              <img src={ErrorImage} alt="error" />
            )}
            {step === "invalid" && <img src={ExpiredImage} alt="invalid" />}
          </div>
          <div className={styles.content}>{this.renderContent()}</div>
          {step === "full" && (
            <div className={styles.buttonsContainer}>
              <Button
                kind="primary"
                label={t("trainings.view.session_subscribe.form.action.back", {
                  defaultValue: "Revenir au formulaire",
                })}
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
