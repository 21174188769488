import React, { ReactNode, useEffect } from "react";
import cx from "classnames";
import { useSetState } from "react-use";

import useTranslation from "hooks/useTranslation";

import Icon from "components/Icon";
import { chevronBottom, chevronTop } from "uiAssets/Icons";

import styles from "./MassEditDropDownStyles.module.scss";

type DropdownElement = { label: string; value: "keep" | "replace" };

const dropdownValues: Array<DropdownElement> = [
  {
    label: "Garder les valeurs existantes",
    value: "keep",
  },
  {
    label: "Remplacer les valeurs existantes",
    value: "replace",
  },
];

interface Props {
  children: ReactNode;
  className?: string;
  numberOfRows: number;
}

interface State {
  isDropDownOpen: boolean;
  value: DropdownElement;
  isFieldOpenToModification: boolean;
}

export default function MassEditDropDown(props: Props) {
  const { t } = useTranslation();

  const [state, setState] = useSetState<State>({
    isDropDownOpen: false,
    value: dropdownValues[0],
    isFieldOpenToModification: false,
  });

  const { isDropDownOpen, value, isFieldOpenToModification } = state;
  const { children, className, numberOfRows } = props;

  useEffect(() => {
    setState({
      isDropDownOpen: false,
      value: dropdownValues[0],
      isFieldOpenToModification: false,
    }); // reset changed when row changes
  }, [numberOfRows, setState]);

  const handleChoice = (selectedOption: DropdownElement) => {
    if (selectedOption.value === "replace") {
      setState({ value: selectedOption, isDropDownOpen: false, isFieldOpenToModification: true });
    } else {
      setState({ value: selectedOption, isDropDownOpen: false, isFieldOpenToModification: false });
    }
  };

  return (
    <div className={cx(styles.massEditDropDown, className)}>
      {isFieldOpenToModification ? (
        children
      ) : (
        <div className={styles.dropDown}>
          <div
            className={styles.mainSelector}
            onClick={() => {
              setState({ isDropDownOpen: !isDropDownOpen });
            }}
          >
            <p className={styles.selectedOption}>
              {t(
                `trainings.view.schedule_row.sidepanel.edit_input.mass_edit_dropdown.${value.value}`,
                {
                  defaultValue: value.label,
                }
              )}
            </p>
            <span className={styles.iconWrapper}>
              <Icon width={15} icon={isDropDownOpen ? chevronTop : chevronBottom} />
            </span>
          </div>
          {isDropDownOpen && (
            <div className={styles.options}>
              {dropdownValues.map((o, i) => (
                <div className={styles.option} key={i} onClick={() => handleChoice(o)}>
                  {t(
                    `trainings.view.schedule_row.sidepanel.edit_input.mass_edit_dropdown.${o.value}`,
                    {
                      defaultValue: o.label,
                    }
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
