import { MdReplay as Replay } from "react-icons/md";
import React from "react";

import { IScheduleLog } from "@skillup/types";

import DataLayer from "utils/DataLayer";
import {
  arobase as mailIcon,
  calendar as calendarIcon,
  check as checkIcon,
  cross as crossIcon,
  plus as plusIcon,
  letter,
  edit as editIcon,
} from "uiAssets/StrokeIcons";
import colors from "uiAssets/Colors";

const logsStaticData = {
  "added-to-intra": {
    getLabel: (): string => `<span>{{ author }}</span> rattaché au programme intra.`,
    icon: plusIcon,
  },
  "added-to-plan": {
    getLabel: (): string => `Demande de formation ajoutée au plan <span>par {{ author }}</span>`,
    icon: plusIcon,
  },
  approved_by_manager: {
    color: colors.success,
    getLabel: () => `Demande approuvée <span>par {{ author }}</span>`,
    icon: checkIcon,
    iconStroke: "#fff",
    style: {
      background: colors.successL,
    },
  },
  "automatic-reminder-email": {
    getLabel: (): string => `Relance de l'email de choix de session <span>par {{ author }}</span>`,
    icon: mailIcon,
  },
  "booking-created": {
    getLabel: (): string => `Inscription réalisée <span>par {{ author }}</span>`,
    icon: calendarIcon,
  },
  "booking-created-outside-skillup": {
    getLabel: (): string => `Inscription réalisée hors Skillup <span>par {{ author }}</span>`,
    icon: calendarIcon,
  },
  "booking-postponed": {
    getLabel: (): string => `Inscription reportée <span>par {{ author }}</span>`,
    icon: calendarIcon,
  },
  cancelled: {
    color: colors.error,
    getLabel: (): string => `Demande annulée <span>par {{ author }}</span>.`,
    icon: crossIcon,
  },
  "cancelled-booking": {
    color: colors.error,
    getLabel: (): string => `Session annulée <span>par {{ author }}</span>.`,
    icon: crossIcon,
  },
  "cancelled-project": {
    color: colors.error,
    getLabel: (): string =>
      // Fix formatting
      `Session du {{ oldSessionDate }} {{ oldSessionCity }} annulée <span>par {{ author }}</span>. En attente d'une nouvelle inscription.`,
    icon: crossIcon,
  },
  "cancelling-booking": {
    color: colors.warning,
    getLabel: (): string => `Demande d’annulation émise <span>par {{ author }}</span>.`,
    icon: crossIcon,
  },
  collected: {
    getLabel: (): string => `Demande de formation émise <span>par {{ author }}</span>`,
    icon: plusIcon,
  },
  "convocation-by-skillup": {
    getLabel: (_argument: IScheduleLog): string => "Convocation envoyée <span>avec Skillup</span>",
    icon: letter,
  },
  "convocation-by-rf": {
    getLabel: (): string => `Convocation envoyée <span>hors Skillup par {{ author }}</span>`,
    icon: letter,
  },
  denied: {
    color: colors.error,
    getLabel: (): string => `Demande refusée <span>par {{ author }}</span>.`,
    icon: crossIcon,
  },
  denied_by_manager: {
    color: colors.error,
    getLabel: () => `Demande refusée <span>par {{ author }}</span>`,
    icon: crossIcon,
    iconStroke: "#fff",
    iconWidth: 12,
    style: {
      background: colors.errorL,
    },
  },
  imported: {
    getLabel: (): string => `Ligne importée <span>par {{ author }}</span>`,
    icon: plusIcon,
  },
  "intra-created": {
    getLabel: (): string => `Création du programme Intra <span>par {{ author }}</span>.`,
    icon: plusIcon,
  },
  "moved-to-plan": {
    color: colors.success,
    getLabel: (): string => `Demande validée <span>par {{ author }}</span>.`,
    icon: checkIcon,
  },
  positioned: {
    getLabel: (): string => `Inscription réalisée <span>par {{ author }}</span>`,
    icon: calendarIcon,
  },
  "positioning-email": {
    getLabel: (): string => `Email de choix de session envoyé <span>par {{ author }}</span>.`,
    icon: mailIcon,
  },
  "positioning-email-by-manager": {
    getLabel: (): string =>
      // @ts-ignore
      `Email de choix de session envoyé au manager {{ manager }} <span>par {{ author }}</span>.`,
    icon: mailIcon,
  },
  postponed: {
    getLabel: (): string =>
      `Inscription à la session du {{ oldSessionDate }} {{ oldSessionCity }} annulée, en attente d'une nouvelle inscription <span>par {{ author }}</span>`,
    icon: calendarIcon,
  },
  "project-postponed": {
    getLabel: (): string =>
      `Session reportée
      du
        <strong><em>{{ oldSessionDate }}</em></strong>
          <strong>{{ oldSessionCity }}</strong>
        au <strong><em>{{ newSessionDate }}</em></strong>
          à <strong>{{ newSessionCity }}</strong>
          par {{ author }}</span>`,
    icon: calendarIcon,
  },
  "proposed-by-manager": {
    getLabel: (): string => `Formation proposée par <strong>{{ manager }}</strong>`,
    icon: plusIcon,
  },
  "reminder-email": {
    getLabel: (): string => `Relance de l'email de choix de session <span>par {{ author }}</span>.`,
    icon: mailIcon,
  },
  requalified: {
    getLabel: (): string =>
      `Demande requalifiée <span>
            par {{ author }} depuis
            <strong>"<em>{{ origin }} </em>"</strong> par <strong>{{ originOrg }}</strong>
        vers <strong>"<em>{{ target }}</em>"</strong> par <strong>{{ targetOrg }}</strong>.
      </span>`,
    icon: plusIcon,
  },
  "validation-reminder": {
    getLabel: (): string => `Relance de l'email de validation <span>par {{ author }}</span>.`,
    icon: mailIcon,
  },
  "moved-from": {
    getLabel: (): string =>
      `Demande déplacée depuis <span><em>{{ originPlan }}</em></span> par {{ author }}.`,
    icon: plusIcon,
  },
  "manual-edition": {
    getLabel: (): string => `Demande éditée manuellement <span>par {{ author }}</span>`,
    icon: editIcon,
  },
  "added-to-collection": {
    getLabel: (): string => `Demande de formation ajoutée au recueil <span>par {{ author }}</span>`,
    icon: plusIcon,
  },
  "reverted-to-approved": {
    getLabel: (): string => `Demande renvoyée au statut "À inscrire" <span>par {{ author }}</span>`,
    icon: <Replay color={colors.blue} size="18px" />,
  },
  "reverted-to-pending": {
    getLabel: (): string =>
      `Demande renvoyée au statut "À valider RH" <span>par {{ author }}</span>`,
    icon: <Replay color={colors.blue} size="18px" />,
  },
};

export interface IActivity extends IScheduleLog {
  color?: string;
  getLabel: () => string;
  icon: string | React.ReactNode;
  iconStroke?: "#fff";
  iconWidth?: 12;
  style?: {
    [key: string]: string;
  };
  properties: IScheduleLog["properties"];
}

export default async (rowUuid) => {
  try {
    const logs = (await DataLayer.request({
      method: "GET",
      target: "API",
      url: `/v1/scheduleRow/logs/${rowUuid}`,
    })) as Array<IScheduleLog>;

    const activities: Array<IActivity> = [...(logs || [])].map((logItem) => {
      return {
        ...logItem,
        ...logsStaticData[logItem.properties.event],
      };
    });

    const activitiesByDates = activities.reduce((accByDates, act) => {
      const d = act.properties.createdAt;

      accByDates[d] = [...(accByDates[d] || []), act];
      return accByDates;
    }, {});

    return activitiesByDates;
  } catch (err) {
    return undefined;
  }
};
