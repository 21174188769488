import React from "react";
import cx from "classnames";

import styles from "./SkillLevel.module.scss";

import { Tooltip } from "components/Tooltip";

interface SkillLevelProps {
  readonly level?: number;
  readonly totalLevel: Array<{ level: number; name: string }>;
}

const SkillLevel = ({ level, totalLevel }: SkillLevelProps) => {
  return (
    <div className={cx(styles.SkillLevel)}>
      {totalLevel
        .sort((a, b) => a.level - b.level)
        .map((e) => (
          <Tooltip key={e.level} label={e.name} direction="top" flex>
            <span
              className={cx(styles.level, {
                [styles.reached]: level !== undefined && level >= e.level,
              })}
            ></span>
          </Tooltip>
        ))}
    </div>
  );
};

export { SkillLevel };
