import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { colors, Icon } from "@skillup/ui";
import { ISimpleSelectionUser, ISimpleManager, USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import Acta from "utils/Acta";
import Loader from "components/Loader";
import styles from "../AddTraineesContent.module.scss";
import { baseStyle, acceptStyle, rejectStyle } from "./dropFileStyle";
import { searchUsersByFile } from "fetchers/users-fetcher";
import { head, isArray, last } from "lodash";
import useTranslation from "hooks/useTranslation";

interface IProps {
  onSubmit: (
    users: ISimpleSelectionUser | { employee: ISimpleSelectionUser; manager: ISimpleManager }
  ) => void;
  canUploadManager: boolean;
  scope: USER_FILE_VALIDATION_TYPE;
}

const ImportFile = (props: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const NO_UPLOAD_MANAGER_ERROR = t(
    "trainings.view.add_trainees_modal.import.no_upload_manager_error ",
    {
      defaultValue:
        "Vous ne pouvez pas lier les collaborateurs avec un manager dans cette fonctionnalité",
    }
  );

  const UPLOAD_MANAGER_ERROR = t("trainings.view.add_trainees_modal.import.upload_manager_error", {
    defaultValue:
      "Vous ne pouvez pas sélectionner des collaborateurs sans lien avec un manager dans cette fonctionnalité",
  });

  const onDrop = async (files: File[]) => {
    if (isLoading) {
      return;
    }

    submitFile(files[0]);
  };
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
    noClick: isLoading,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept && !isLoading ? acceptStyle : {}),
      ...(isDragReject && isLoading ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept, isLoading]
  );

  const submitUsers = (users) => {
    users.forEach((user) => {
      if (!user) {
        return;
      }
      props.onSubmit({
        email: user.email,
        fullName: user.fullName,
        uuid: user.uuid,
        organizationsByUuid: user.organizationsByUuid,
        joinDate: user.joinDate,
        errors: user.errors,
      });
    });
  };
  const submitPair = (users: { employee: ISimpleSelectionUser; manager: ISimpleManager }[]) => {
    users.forEach((user) => {
      if (!user.employee) {
        return;
      }
      const { employee, manager } = user;
      props.onSubmit({
        employee: {
          email: employee.email,
          fullName: employee.fullName,
          uuid: employee.uuid,
          organizationsByUuid: employee.organizationsByUuid,
          joinDate: employee.joinDate,
          errors: employee.errors,
        },
        manager: {
          email: manager.email,
          fullName: manager.fullName,
          linkedTo: manager.linkedTo,
          uuid: manager.uuid,
          errors: manager.errors,
        },
      });
    });
  };

  const submitFile = async (file: File) => {
    try {
      setIsLoading(true);
      const response = await searchUsersByFile(file, props.scope);
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.add_trainees_modal.import.success", {
          defaultValue: "Collaborateurs ajoutés à la sélection avec succès",
        }),
        type: "success",
      });
      // Uploaded without manager
      if (!isArray(response[0])) {
        if (props.canUploadManager) {
          throw new Error(UPLOAD_MANAGER_ERROR);
        }
        submitUsers(response);

        return;
      }
      if (!props.canUploadManager) {
        throw new Error(NO_UPLOAD_MANAGER_ERROR);
      }
      // Upload with manager
      response.forEach((users) => {
        const user = head(users) as ISimpleSelectionUser;
        const manager = last(users) as ISimpleManager;

        if (!manager) {
          // this will change when we handle error, its for avoiding error toast because manager is undefined when not found.
          return;
        }
        manager.linkedTo = user?.email;
        submitPair([{ employee: user, manager }]);
      });
    } catch (e) {
      if (e.message === UPLOAD_MANAGER_ERROR) {
        Acta.dispatchEvent("sendAppMessage", {
          message: e.message,
          type: "error",
        });

        return;
      }
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.add_trainees_modal.import.error", {
          defaultValue: "Une erreur est survenue lors de l'ajout des collaborateurs",
        }),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className={styles.importSection}>
        <div {...getRootProps({ style })} aria-label="drop-input">
          <input {...getInputProps()} />
          <div style={{ width: "100%", display: "flex" }}>
            {isLoading ? (
              <Loader style={{ height: 0, margin: "15px 0" }} />
            ) : (
              <>
                <Icon.Upload
                  color={colors.primaryBlue}
                  style={{ marginLeft: "10px" }}
                  size="1.5rem"
                />
                <p>
                  {t("trainings.view.add_trainees_modal.import.from_file.label", {
                    defaultValue: "Importer depuis un fichier",
                  })}
                </p>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportFile;
