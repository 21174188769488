import { useState } from "react";
import { DSModal, DSRadio, DSRadioGroup } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";
import styles from "./CancelOrPostponeModalStyles.module.scss";

interface Props {
  rows: string[];
  onOptionChange: (option: string) => void;
  onClose: (needRefresh?: boolean) => void;
}

export default function CancelOrPostponeChooseModal({ rows, onOptionChange, onClose }: Props) {
  const [radioValue, setRadioValue] = useState<"postpone" | "cancel" | null>(null);
  const { t } = useTranslation();

  return (
    <DSModal isOpen className={styles.CancelOrPostponeModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={
            rows.length > 1 ? "Reporter ou annuler les demandes" : "Reporter ou annuler la demande"
          }
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <DSRadioGroup name="CancelOrPostpone">
          <DSRadio
            label={t("common.postpone", { defaultValue: "Reporter" })}
            helperText={t("training.schedules.cancelOrPostpone.modal.helperText.postpone", {
              defaultValue: `Cette action permet d'annuler la réservation auprès de l'organisme puis de choisir
                une autre date de formation ou un autre programme. Attention ! Une fois l'action
                confirmée, la demande repassera dans l'onglet « À inscrire ».`,
            })}
            checked={radioValue === "postpone"}
            onChange={() => setRadioValue("postpone")}
          />
          <DSRadio
            label={t("common.cancel", { defaultValue: "Annuler" })}
            helperText={t("training.schedules.cancelOrPostpone.modal.helperText.cancel", {
              defaultValue: `Cette action permet d'annuler définitivement la réservation auprès de l'organisme.
                Attention ! Une fois l'action confirmée, vous ne pourrez plus reporter l'inscription
                à une date ultérieure.`,
            })}
            checked={radioValue === "cancel"}
            onChange={() => setRadioValue("cancel")}
          />
        </DSRadioGroup>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.cancel", { defaultValue: "Annuler" })}
          onClick={() => onClose()}
        />
        <DSModal.Footer.PrimaryButton
          label={t("common.confirm", { defaultValue: "Confirmer" })}
          disabled={!radioValue}
          onClick={() => onOptionChange(radioValue)}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
