import { useEffect, useMemo } from "react";
import { useToggle } from "react-use";
import { isEmpty } from "lodash";
import Acta from "utils/Acta";
import useTypedFetch from "hooks/useTypedFetch";
import { TextInput, NumberInput, Select, Switch } from "@skillup/ui";
import styles from "./HabilitationForm.module.scss";

import {
  matchingFields,
  FieldElement,
  HabilitationValidity,
  Payload,
  FormFieldsAdvitam,
  FormFieldsLimited,
} from "./config";
import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";
import CreateLabel from "components/CreateLabel";
import { Habilitation } from "../../types";
import useTranslation from "hooks/useTranslation";

type FormFieldValue =
  | {
      value?: any;
      error?: boolean;
    }
  | undefined;

export type IForm = Partial<
  Record<keyof FormFieldsLimited, FormFieldValue> | Record<keyof FormFieldsAdvitam, FormFieldValue>
>;

export enum DATE_TYPE {
  Y = 1,
  M = 2,
  D = 3,
}

export const LoadHabilitationToForm = (habilitation?: Habilitation) => {
  const commonField = {
    name: { value: habilitation.name },
    categoryUuid: { value: habilitation.category.uuid },
  };

  if (habilitation.validity === "limited") {
    const durationType = DATE_TYPE[habilitation.duration.slice(-1)];
    const deadlineAnticipationType = DATE_TYPE[habilitation.deadlineAnticipation.slice(-1)];
    return {
      ...commonField,
      validity: { value: true },
      duration: { value: habilitation.duration.slice(1, -1) },
      durationType: { value: durationType.toString() },
      deadlineAnticipation: { value: habilitation.deadlineAnticipation.slice(1, -1) },
      deadlineAnticipationType: { value: deadlineAnticipationType.toString() },
    };
  }

  return {
    ...commonField,
    validity: { value: false },
  };
};

export const ValidateForm = (form: IForm): { form: IForm; hasError: boolean } => {
  let validity = form?.validity?.value;
  if (!validity) {
    validity = HabilitationValidity.ADVITAM;
  }
  return matchingFields.reduce<{ form: IForm; hasError: boolean }>(
    (acc, matchingField) => {
      const value = form[matchingField.field]?.value?.toString();
      if (
        (matchingField.required &&
          validity === HabilitationValidity.ADVITAM &&
          matchingField.scope !== HabilitationValidity.LIMITED &&
          isEmpty(value)) ||
        (matchingField.required && validity === HabilitationValidity.LIMITED && isEmpty(value))
      ) {
        return {
          form: {
            ...acc.form,
            [matchingField.field]: {
              value,
              error: true,
            },
          },
          hasError: true,
        };
      }

      return {
        ...acc,
        form: {
          ...acc.form,
          [matchingField.field]: { value },
        },
      };
    },
    { form: {}, hasError: false }
  );
};

export const BuildPayloadFromForm = (form: IForm) => {
  let validity = form.validity?.value;
  const dateType = ["Y", "M", "D"] as const;

  if (!validity) {
    validity = HabilitationValidity.ADVITAM;
  } else {
    validity = HabilitationValidity.LIMITED;
  }

  const payload = matchingFields.reduce(
    (acc, curr) => {
      let value = form[curr.field]?.value;

      if (curr.field === "categoryUuid") {
        acc["categoryUuid"] = value;
      }

      if (curr.field === "validity" && value === false) {
        value = HabilitationValidity.ADVITAM;
      } else if (curr.field === "validity" && value === true) {
        value = HabilitationValidity.LIMITED;
      }

      if ("deadlineAnticipationType" in form) {
        if (curr.field === "duration" && validity === HabilitationValidity.LIMITED) {
          value = `P${value}${dateType[form.durationType.value - 1]}`;
        } else if (
          curr.field === "deadlineAnticipation" &&
          validity === HabilitationValidity.LIMITED
        ) {
          value = `P${value}${dateType[form.deadlineAnticipationType.value - 1]}`;
        }
      }

      return {
        ...acc,
        data: {
          ...acc.data,
          [curr.field]: value,
        },
      };
    },
    {
      data: {},
    } as Payload
  );
  return payload;
};

export interface Props {
  readonly form: IForm;
  readonly setFormState: (patch: Partial<IForm> | ((prevState: IForm) => Partial<IForm>)) => void;
}

const HabilitationForm = ({ form, setFormState }: Props) => {
  const { t } = useTranslation();
  const {
    activeCompany: { uuid: companyUuid },
  } = Acta.getState("userData");

  // get all categories to feed the select component
  const { data } = useTypedFetch<HabilitationsRoutes.Category.GetCategory>({
    method: "GET",
    path: "/habilitations/categories",
    params: { companyUuid },
  });

  const categoryOptions = useMemo(
    () =>
      data?.categories.map((option) => {
        return {
          label: option.name,
          value: option.uuid,
        };
      }),
    [data]
  );

  const onChangeInput =
    (field: keyof FormFieldsAdvitam | keyof FormFieldsLimited) => (value: string | number) => {
      setFormState({
        [field]: { value },
      });
    };

  const handleToggle = (status?: boolean): void => {
    const durationTypeDefaultValue = matchingFields.find(
      (el) => el.field === "duration"
    ).defaultValue;
    const deadlineAnticipationTypeDefaultValue = matchingFields.find(
      (el) => el.field === "deadlineAnticipation"
    ).defaultValue;

    setFormState({
      validity: {
        value: status ? HabilitationValidity.ADVITAM : HabilitationValidity.LIMITED,
      },
      durationType: {
        value: durationTypeDefaultValue,
      },
      deadlineAnticipationType: {
        value: deadlineAnticipationTypeDefaultValue,
      },
      deadlineAnticipation: undefined,
      duration: undefined,
    });
  };

  const [active, toggleSwitch] = useToggle(form?.validity?.value);

  useEffect(() => {
    toggleSwitch(form?.validity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.validity?.value]);

  const RenderInput = (line: FieldElement) => {
    switch (line.type) {
      case "TextInput":
        return (
          <TextInput
            autoFocus
            className={styles.input}
            value={form?.[line.field]?.value ?? ""}
            placeholder={t(
              `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
              { defaultValue: line.placeholder ?? line.name }
            )}
            onChange={onChangeInput(line.field)}
            errored={!!form?.[line.field]?.error}
            controlled
            aria-label={line.name}
          />
        );
      case "Select":
        return (
          <Select
            className={styles.select}
            options={categoryOptions}
            onChange={(item) => onChangeInput(line.field)(item)}
            placeholder={t(
              `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
              { defaultValue: line.placeholder ?? line.name }
            )}
            value={form?.["categoryUuid"]?.value}
            error={!!form?.[line.field]?.error}
          />
        );
      case "Switch":
        return (
          <div>
            <Switch
              aria-label={t(`trainings.entity.habilitation.property.${line.translationKey}`, {
                defaultValue: line.name,
              })}
              className={styles.switch}
              label={t(`trainings.entity.habilitation.property.${line.translationKey}`, {
                defaultValue: line.name,
              })}
              active={active}
              onToggle={handleToggle}
            />
          </div>
        );
      case "Duration":
        switch (line.field) {
          case "duration":
            return (
              <div>
                {!active && (
                  <div className={styles.duration}>
                    <NumberInput
                      aria-label={line.name + " valeur"}
                      className={styles.input}
                      value={form?.[line.field]?.value ?? ""}
                      placeholder={t(
                        `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
                        { defaultValue: line.placeholder ?? line.name }
                      )}
                      onChange={onChangeInput(line.field)}
                      errored={!!form?.[line.field]?.error}
                    />
                    <Select
                      className={styles.select}
                      options={line.options}
                      onChange={(item) => onChangeInput("durationType")(item)}
                      placeholder={t(
                        `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
                        { defaultValue: line.placeholder ?? line.name }
                      )}
                      value={form?.["durationType"]?.value}
                    />
                  </div>
                )}
              </div>
            );
          case "deadlineAnticipation":
            return (
              <div>
                {!active && (
                  <div className={styles.duration}>
                    <NumberInput
                      aria-label={line.name + " valeur"}
                      className={styles.input}
                      value={form?.[line.field]?.value ?? ""}
                      placeholder={t(
                        `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
                        { defaultValue: line.placeholder ?? line.name }
                      )}
                      onChange={onChangeInput(line.field)}
                      errored={!!form?.[line.field]?.error}
                    />
                    <Select
                      className={styles.select}
                      options={line.options}
                      onChange={(item) => onChangeInput("deadlineAnticipationType")(item)}
                      placeholder={t(
                        `trainings.entity.habilitation.property.${line.translationKey}.placeholder`,
                        { defaultValue: line.placeholder ?? line.name }
                      )}
                      value={form?.["deadlineAnticipationType"]?.value}
                    />
                  </div>
                )}
              </div>
            );
        }
    }
  };

  return (
    <>
      {matchingFields.map((line) => (
        <div className={styles.inputLine} key={line.name}>
          {line.type !== "Switch" &&
            ((line.type === "Duration" && !active) || line.type !== "Duration") && (
              <CreateLabel
                className={styles.label}
                label={t("trainings.entity.habilitation.property." + line.translationKey, {
                  defaultValue: line.name,
                })}
                required={line.required}
              />
            )}
          {RenderInput(line)}
        </div>
      ))}
    </>
  );
};

export default HabilitationForm;
