import { useKeyCombo } from "@rwh/react-keystrokes";

import useTranslation from "hooks/useTranslation";
import { useEffect } from "react";
import { buildRequest } from "utils/buildRequest";
import { TargetType } from "utils/DataLayer";

async function generateLocalesLink(
  keys: { key: string; defaultValue: string; countable: boolean }[],
  target: TargetType
) {
  const { link } = await buildRequest<{
    payload: { keys: Array<{ key: string; defaultValue: string; countable: boolean }> };
    method: "POST";
    path: "/keys/custom-link";
    response: { link: string };
  }>({
    target,
    path: "/keys/custom-link",
    method: "POST",
    payload: { keys },
  })();

  window.open(link);
}

const useTranslationShortcut = () => {
  const { activeKeys, translationAPITarget } = useTranslation();
  const isPressed = useKeyCombo("@ControlLeft + @AltLeft + t");

  useEffect(() => {
    if (isPressed) {
      generateLocalesLink(
        Array.from(activeKeys.entries()).map(([key, { defaultValue, countable }]) => ({
          key,
          defaultValue,
          countable,
        })),
        translationAPITarget
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed]);

  return isPressed;
};

export default useTranslationShortcut;
