import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { MdClose as Close } from "react-icons/md";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { Popover, DSButton, UserPopover, DSAvatarGroup, DSUserPreview } from "@skillup/ui";

import { SuggestionsJob } from "types/skills";
import useTranslation from "hooks/useTranslation";

import { useSuggestionsContext } from "../SuggestionsContext";
import {
  TopShadow,
  DeclineButton,
  CardContainer,
  RoleContainer,
  PopoverContent,
} from "./SuggestionCard.styled";

interface CardProps extends SuggestionsJob {
  isDeclined?: boolean;
  openModal: (role: string, employees: SuggestionsJob["employees"]) => void;
}

const SuggestionCard = ({
  employees,
  isDeclined,
  mostRelevantJobLabel,
  openModal,
  role,
}: CardProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [closePopover, setClosePopover] = useState<() => void>();
  const history = useHistory();

  const suggestionsJobs = useSuggestionsContext();
  const { refetch: refetchPending } = suggestionsJobs.getJobSuggestions("pending");
  const { refetch: refetchCancelled } = suggestionsJobs.getJobSuggestions("cancelled");

  return (
    <CardContainer>
      <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
        {employees.length > 1
          ? t("skills.suggestions.employees", {
              count: employees.length,
              defaultValue: "{{count}} collaborateurs",
            })
          : t("skills.suggestions.employee", {
              defaultValue: "1 collaborateur",
            })}
      </Text>
      <Flex gap="s" height="100%" marginTop="xs" flexDirection="column">
        <Flex alignItems="center">
          <DSAvatarGroup
            size="S"
            maxItems={5}
            firstElementForeground
            totalItems={employees.length}
            users={employees.map((employee) => ({
              uuid: employee.employeeID,
              fullName: employee.fullName,
              initials: getInitials(employee),
            }))}
          />
          {employees.length === 1 && (
            <Text marginLeft="xxs" espaceFont="body2Regular" color="plainText-onLight-default">
              {employees[0].fullName}
            </Text>
          )}
          {employees.length > 5 && (
            <Popover
              actions={({ close }) => {
                setClosePopover(() => close);
              }}
              children={
                <DSButton
                  buttonSize="S"
                  emphasis="Low"
                  label={t("skills.suggestions.seeList", {
                    defaultValue: "Voir la liste",
                  })}
                />
              }
              content={() => (
                <PopoverContent>
                  <Flex marginBottom="xs" justifyContent="space-between">
                    <Text
                      marginBottom="xxs"
                      espaceFont="captionBold"
                      color="plainText-onLight-default"
                    >
                      {employees.length > 1
                        ? t("skills.suggestions.associatedEmployees", {
                            count: employees.length,
                            defaultValue: "{{count}} collaborateurs associés",
                          })
                        : t("skills.suggestions.associatedEmployee", {
                            defaultValue: "1 collaborateur associé",
                          })}
                    </Text>
                    <DSButton iconOnly buttonSize="S" icon={<Close />} onClick={closePopover} />
                  </Flex>
                  <Flex gap="xs" overflow="auto" flexDirection="column">
                    {employees.map((employee) => {
                      const { employeeID, fullName } = employee;
                      return (
                        <Flex key={employeeID}>
                          <UserPopover
                            position="down-right"
                            user={{
                              uuid: employeeID,
                              fullName,
                              initials: getInitials({ fullName }),
                            }}
                            profileBtn={{
                              label: t("skills.dashboard.sidePanel.seeProfile", {
                                defaultValue: "Voir le profil",
                              }),
                              onClick: () => {
                                history.push(
                                  `/responsable/collaborateurs/${employeeID}/competences`
                                );
                              },
                            }}
                          >
                            <DSUserPreview
                              size="S"
                              fullName={fullName}
                              style={{ alignItems: "center" }}
                              randomColorSeedString={employeeID}
                              initials={getInitials({ fullName })}
                            />
                          </UserPopover>
                        </Flex>
                      );
                    })}
                  </Flex>
                  <TopShadow />
                </PopoverContent>
              )}
            />
          )}
        </Flex>
        <RoleContainer marginBottom="s">
          <Flex flex={1} flexDirection="column" justifyContent="center">
            <Text espaceFont="captionRegular">
              {t("skills.suggestions.role", {
                defaultValue: "Rôle",
              })}
            </Text>
            <Text espaceFont="body1Bold" color="plainText-onLight-default">
              {role}
            </Text>
          </Flex>
          <Flex alignItems="center" marginHorizontal="s">
            <ArrowForwardIcon />
          </Flex>
          <Flex flex={1} flexDirection="column" justifyContent="center">
            <Text espaceFont="captionRegular">
              {t("skills.suggestions.jobSuggested", {
                defaultValue: "Fiche de poste suggérée",
              })}
            </Text>
            <Text espaceFont="body1Bold" color="plainText-onLight-default">
              {mostRelevantJobLabel}
            </Text>
          </Flex>
        </RoleContainer>
      </Flex>
      <Flex justifyContent="flex-end">
        {!isDeclined && (
          <DeclineButton
            buttonSize="S"
            emphasis="Mid"
            label={t("skills.suggestions.button.decline", {
              defaultValue: "Refuser la suggestion",
            })}
            onClick={async () => {
              try {
                await suggestionsJobs.cancelJobSuggestions(
                  employees.reduce<string[]>((acc, { employeeSuggestions }) => {
                    acc.push(...employeeSuggestions.map(({ suggestionID }) => suggestionID));
                    return acc;
                  }, [])
                );

                refetchPending();
                refetchCancelled();

                addToast(
                  t("skills.suggestions.cancel.success", {
                    defaultValue:
                      "Les suggestions ont bien été refusées, elles ne vous seront plus proposées",
                  }),
                  { appearance: "success" }
                );
              } catch (e) {
                addToast(
                  t("skills.suggestions.cancel.error", {
                    defaultValue: "Une erreur est survenue",
                  }),
                  { appearance: "error" }
                );
              }
            }}
          />
        )}
        <DSButton
          buttonSize="S"
          emphasis="High"
          onClick={() => openModal(role, employees)}
          label={t("skills.suggestions.button.approve", {
            defaultValue: "Traiter la suggestion",
          })}
        />
      </Flex>
    </CardContainer>
  );
};

export default SuggestionCard;
