import { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";

import { DSModal, DSRadio, DSRadioGroup } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import styles from "./SelectTrainingTypeModal.module.scss";

const SelectTrainingTypeModal = ({
  modalState,
  setModalState,
}: {
  modalState: { isOpen: boolean };
  setModalState: Dispatch<SetStateAction<{ isOpen: boolean }>>;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useState<"create-intra" | "reference-new-inter">("create-intra");

  function closeModal() {
    setModalState({ isOpen: false });
  }

  function handleSubmit() {
    if (state === "create-intra") {
      history.push("/responsable/programmes/gestion-intras/creation");
    } else {
      history.push("/responsable/programmes/gestion-intras/reference-new-inter");
    }
  }

  return (
    <DSModal isOpen={modalState.isOpen} className={styles.SelectTrainingTypeModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("trainings.view.training_list.add_inter_modal.property.title", {
            defaultValue: "Ajouter un programme",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <DSRadioGroup name="addInterChoices">
          <DSRadio
            label={t("trainings.view.training_list.add_inter_modal.create_intra", {
              defaultValue: "Créer un programme intra",
            })}
            helperText={t("trainings.view.training_list.add_inter_modal.create_intra", {
              defaultValue:
                "Vous souhaitez proposer à vos collaborateurs une formation interne ou intra-entreprise",
            })}
            checked={state === "create-intra"}
            onChange={() => setState("create-intra")}
          />
          <DSRadio
            label={t("trainings.view.training_list.add_inter_modal.reference_new_inter", {
              defaultValue: "Référencer un nouveau programme inter",
            })}
            helperText={t(
              "trainings.view.training_list.add_inter_modal.reference_new_inter_helper_text",
              {
                defaultValue:
                  "Vous souhaitez inscrire vos collaborateurs à une formation inter-entreprise, proposée par un organisme mais non référencée dans le catalogue Skillup",
              }
            )}
            checked={state === "reference-new-inter"}
            onChange={() => setState("reference-new-inter")}
          />
        </DSRadioGroup>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={closeModal}
        />
        <DSModal.Footer.PrimaryButton
          label={t("common.action.continue", {
            defaultValue: "Continuer",
          })}
          onClick={handleSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default SelectTrainingTypeModal;
