import { MdMoreVert as MoreVert } from "react-icons/md";
import { useMemo } from "react";

import {
  HorizontalDivider,
  DSButton,
  DSFormGroupTextInput,
  Label,
  DSDropdown,
  DSDropdownItem,
  DSAvatar,
} from "@skillup/ui";
import { format, FormatDate, formatToLocaleDate, getInitials } from "@skillup/shared-utils";

import useTranslation, { TranslationType } from "hooks/useTranslation";
import SidePanel from "components/SidePanel";

import DocumentList from "../../Trackings/components/DocumentList/DocumentList";
import TrackingStatus from "../../Trackings/components/TrackingStatus";
import { translateState } from "../../Trackings/components/TrackingState";
import { TrackingAction } from "../../Trackings/hooks/useTrackingActions";

import { useHabilitationByUuid } from "../../state/habilitations";
import { Tracking } from "../../types";

import TrackingDuration from "../TrackingDuration/TrackingDuration";

import styles from "./TrackingSidePanel.module.scss";

const displayDate = (date: string, t: TranslationType): string =>
  date
    ? format(new Date(date), FormatDate.getDayMonthYearFormat())
    : t("trainings.view.regulatory_collaborator_tracking.side_panel.no_date", {
        defaultValue: "Aucune date renseignée",
      });

interface Props {
  readonly tracking: Tracking;
  readonly onClose: () => void;
  readonly refetchTrackings: () => void;
  readonly openActionModal: (trackingUuid: string, action: TrackingAction) => void;
  readonly getActionLabel: (action: TrackingAction, t: TranslationType) => string;
}

export default ({
  tracking,
  onClose,
  refetchTrackings,
  openActionModal,
  getActionLabel,
}: Props) => {
  const { t } = useTranslation();
  const habilitation = useHabilitationByUuid(tracking.habilitation.uuid);

  const isLimited = useMemo(
    () => habilitation.isRight() && habilitation.right().validity === "limited",
    [habilitation]
  );

  const validityValue = useMemo(() => {
    if (!isLimited) {
      return (
        <p className={styles.sectionValue}>
          {t("trainings.view.regulatory_collaborator_tracking.side_panel.validitiy_unlimited", {
            defaultValue: "Validité illimitée",
          })}
        </p>
      );
    } else if (tracking.validUntil) {
      return (
        <div className={styles.sectionValue}>
          <TrackingDuration validUntil={tracking.validUntil} />
        </div>
      );
    }
    return (
      <p className={styles.sectionValue}>
        {t("trainings.view.regulatory_collaborator_tracking.side_panel.validitiy_limited", {
          defaultValue: "Aucune date de limite",
        })}
      </p>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimited, tracking]);

  return (
    <div>
      {tracking && (
        <SidePanel
          className={styles.trackingSidePanel}
          headerTopTools={
            <div className={styles.headerStatus}>
              <TrackingStatus status={tracking.status} t={t} darkMode />
            </div>
          }
          title={tracking.user.fullName}
          subTitle={tracking.habilitation.name}
          headerBottomTools={
            <div className={styles.headerDropDown}>
              <DSDropdown
                button={<DSButton label="dropdown" darkMode icon={<MoreVert />} iconOnly />}
              >
                {tracking.actions.length > 0 &&
                  tracking.actions.map((action) => (
                    <DSDropdownItem
                      label={getActionLabel(action, t)}
                      disabled={action.disabled}
                      onClick={() => {
                        openActionModal(tracking.uuid, action);
                      }}
                      tooltipLabel={action.tooltip}
                      tooltipDirection="top"
                    />
                  ))}
              </DSDropdown>
            </div>
          }
          onClose={onClose}
        >
          <div>
            {tracking.associatedRowState && (
              <div>
                <div className={styles.trackingContainer}>
                  <section className={styles.trackingRowSection}>
                    <Label
                      label={t("trainings.view.regulatory_collaborator_tracking.side_panel.state", {
                        defaultValue: "État",
                      })}
                    />
                    <p className={styles.sectionValue}>
                      {translateState(tracking.associatedRowState)}
                    </p>
                  </section>
                </div>
                <div className={styles.trackingSidePaneldivider}>
                  <HorizontalDivider top="xs" bottom="xs" />
                </div>
              </div>
            )}
            <div className={styles.trackingContainer}>
              {tracking.status !== "archived" && (
                <section className={styles.trackingSection}>
                  <DSFormGroupTextInput
                    label={t(
                      "trainings.view.regulatory_collaborator_tracking.side_panel.validity_duration_left",
                      {
                        defaultValue: "Durée restante de validité",
                      }
                    )}
                  >
                    {validityValue}
                  </DSFormGroupTextInput>
                </section>
              )}
              <div className={styles.trackingRow}>
                <section className={styles.trackingRowSection}>
                  <Label
                    label={t(
                      "trainings.view.regulatory_collaborator_tracking.side_panel.validity_start_date",
                      {
                        defaultValue: "Date de début de validité",
                      }
                    )}
                  />
                </section>
                {(isLimited || tracking.status === "archived") && (
                  <section className={styles.trackingRowSection}>
                    <Label
                      label={t(
                        "trainings.view.regulatory_collaborator_tracking.side_panel.validity_end_date",
                        {
                          defaultValue: "Date de fin de validité",
                        }
                      )}
                    />
                  </section>
                )}
              </div>
              <div className={styles.trackingRow}>
                <section className={styles.trackingRowSection}>
                  <p className={styles.sectionValue}>{displayDate(tracking.validSince, t)}</p>
                </section>
                {(isLimited || tracking.status === "archived") && (
                  <section className={styles.trackingRowSection}>
                    <p className={styles.sectionValue}>{displayDate(tracking.validUntil, t)}</p>
                  </section>
                )}
              </div>
            </div>
            <div className={styles.trackingSidePaneldivider}>
              <HorizontalDivider top="xs" bottom="xs" />
            </div>
            <div className={styles.trackingContainer}>
              <DocumentList tracking={tracking} refetchTrackings={refetchTrackings} />
            </div>
            <div className={styles.trackingContainer}>
              <Label
                label={t("trainings.view.regulatory_collaborator_tracking.side_panel.note", {
                  defaultValue: "Note",
                })}
              />
              {tracking.note ? (
                <div className={styles.trackingNote}>
                  <DSAvatar initials={getInitials({ fullName: tracking.note.createdBy })} />
                  <div>
                    <p>{tracking.note.noteText}</p>
                    <small>
                      {tracking.note.updatedAt &&
                        t(
                          "trainings.view.regulatory_collaborator_tracking.side_panel.last_modified",
                          {
                            defaultValue: "Dernière modification le {{date}} par {{author}}.",
                            date: formatToLocaleDate(tracking.note.updatedAt),
                            author: tracking.note.createdBy,
                          }
                        )}
                    </small>
                  </div>
                </div>
              ) : (
                <span className={styles.noTrackingNote}>
                  {t("trainings.view.regulatory_collaborator_tracking.side_panel.no_notes", {
                    defaultValue: "Aucune note pour cette ligne de suivi",
                  })}
                </span>
              )}
            </div>
          </div>
        </SidePanel>
      )}
    </div>
  );
};
