import React, { useMemo, useState } from "react";
import moment from "moment";
import cx from "classnames";
import { isEmpty, isNil } from "lodash";
import { useSetState } from "react-use";

import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import useTranslation from "hooks/useTranslation";

import type { ManualTrainingData } from "./Training";
import { modes } from "./Training";
import Dates from "./Dates";

import styles from "./Session.module.scss";
import { switchOnError } from "../AddRow/helper";

export type AvailableState = "pending" | "approved_by_hr" | "won";
type StateOption = { label: string; value: AvailableState };
const states: StateOption[] = [
  { label: "À inscrire", value: "approved_by_hr" },
  { label: "Inscrit/Réalisé", value: "won" },
];

export type ManualSessionData = {
  sessionStartDate?: string;
  sessionEndDate?: string;
  sessionCity?: string;
};

export type User = { fullName: string; uuid: string };

interface ManualSessionProps {
  trainingData: ManualTrainingData;
  initialSessionData?: ManualSessionData;
  desiredState?: AvailableState;
  isLoading?: boolean;
  target: "collection" | "plan";
  onSuccess: (data: {
    sessionData?: Required<ManualSessionData>;
    desiredState: AvailableState;
  }) => void;
}

const defaultState: Record<"collection" | "plan", AvailableState> = {
  collection: "pending",
  plan: "approved_by_hr",
};

const ManualImportSession = ({
  target,
  onSuccess,
  trainingData,
  initialSessionData,
  desiredState,
}: ManualSessionProps) => {
  const [sessionData, setSessionData] = useSetState<ManualSessionData>(initialSessionData);
  const [selectedState, selectState] = useState<StateOption["value"]>(
    desiredState ?? defaultState[target]
  );
  const { t } = useTranslation();

  const sessionType = useMemo(
    () =>
      t(`trainings.entity.session.property.type.${trainingData.sessionType.toLowerCase()}.label`, {
        defaultValue: trainingData.sessionType,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trainingData.sessionType]
  );

  const sessionMode = useMemo(
    () =>
      t(`trainings.entity.training.property.mode.${trainingData.sessionMode}.label`, {
        defaultValue: modes[trainingData.sessionMode],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trainingData.sessionMode]
  );

  const setSessionDataKey = (key, value) => {
    const parsedValue = isEmpty(value) ? undefined : value;
    setSessionData({ ...sessionData, [key]: parsedValue });
  };

  const today = useMemo(() => moment(), []);
  const datesExplanation = useMemo(() => {
    if (isNil(sessionData.sessionEndDate)) return undefined;
    if (moment(sessionData.sessionEndDate).isAfter(today)) {
      return (
        <p className={styles.datesExplanation}>
          {t("trainings.view.add_trainees_modal.manual_import.dates_explanation.future", {
            defaultValue:
              "La date de fin de la session est dans le futur : les demandes seront placées dans l'onglet 'Inscrit'",
          })}
        </p>
      );
    } else {
      return (
        <p className={styles.datesExplanation}>
          {t("trainings.view.add_trainees_modal.manual_import.dates_explanation.past", {
            defaultValue:
              "La date de fin de la session est dans le passé : les demandes seront placées dans l'onglet 'Réalisé'",
          })}
        </p>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData.sessionEndDate, today]);

  const submit = async () => {
    try {
      const requireInscription = selectedState === "won";
      if (requireInscription) {
        if (!sessionData.sessionCity) {
          throw new Error("address");
        }

        if (isNil(sessionData.sessionStartDate) || isNil(sessionData.sessionEndDate)) {
          throw new Error("dates");
        }

        if (moment(sessionData.sessionStartDate).isAfter(sessionData.sessionEndDate)) {
          throw new Error("invalidEndDate");
        }

        onSuccess({
          sessionData: {
            sessionEndDate: sessionData.sessionEndDate,
            sessionStartDate: sessionData.sessionStartDate,
            sessionCity: sessionData.sessionCity,
          },
          desiredState: selectedState,
        });
      } else {
        onSuccess({
          desiredState: selectedState,
        });
      }
    } catch (err) {
      switchOnError(err, t);
    }
  };

  return (
    <div className={styles.manualImportSessionModal}>
      <div className={styles.preview} aria-label="preview-formation">
        <p className={styles.recall}>
          {t("trainings.view.add_trainees_modal.manual_import.title.label", {
            defaultValue: "Programme créé hors Skillup",
          })}
        </p>
        <p>
          <strong>{trainingData.trainingName}</strong> / {sessionType} - {sessionMode} /{" "}
          {trainingData.trainingOrganizationName} -{" "}
          {`${trainingData.total * 0.01} € - ${trainingData.sessionDuration} ${t(
            "training.common.hours.label",
            {
              defaultValue: "heures",
            }
          )}`}
        </p>
      </div>

      {target === "plan" && (
        <>
          <p style={{ marginLeft: "20px" }}>
            {t("trainings.view.add_trainees_modal.manual_import.add_to_tab.title", {
              defaultValue: "Ajouter dans l'onglet",
            })}
          </p>
          <div className={styles.pickState} aria-label="sélection-onglet">
            {states.map((option) => (
              <RadioButton
                key={option.value}
                option={{
                  label: t(`trainings.entity.session.property.state.${option.value}.label`, {
                    defaultValue: option.label,
                  }),
                  value: option.value,
                }}
                select={() => {
                  selectState(option.value);
                  setSessionData({ sessionEndDate: undefined, sessionStartDate: undefined });
                }}
                isSelected={selectedState === option.value}
              />
            ))}
          </div>

          {selectedState === "won" && (
            <>
              <Dates sessionData={sessionData} setSessionData={setSessionData} />
              {datesExplanation}
              <TextInput
                label={t("training.common.place.label", {
                  defaultValue: "Lieu",
                })}
                alwaysOpen
                required
                showRequired
                defaultValue={sessionData.sessionCity}
                name="sessionCity"
                onChange={(e) => setSessionDataKey("sessionCity", e.currentTarget.value)}
                autoComplete="off"
                style={{ marginLeft: "20px" }}
              />
            </>
          )}
        </>
      )}
      <div className={styles.nextStep}>
        <InteractiveButton
          label={t("training.common.next.label", {
            defaultValue: "Suivant",
          })}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const RadioButton = ({
  isSelected,
  option,
  select,
}: {
  isSelected: boolean;
  option: { label: string; value: string };
  select: (string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.radioButton}
      aria-label={t("trainings.view.add_trainees_modal.manual_import.radio.tabs.label", {
        defaultValue: "onglet-{{tab}}",
        tab: option.label,
      })}
    >
      <div
        className={cx(styles.option, { [styles.selected]: isSelected })}
        onClick={() => select(option.value)}
      >
        {isSelected && <div className={styles.middleCircle} />}
      </div>
      <p>{option.label}</p>
    </div>
  );
};

export default ManualImportSession;
