import { useMemo } from "react";
import { isNil } from "lodash";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import type { UserTarget } from "@skillup/espace-rh-bridge";
import { DateTime } from "@skillup/shared-utils";
import { Flex } from "@skillup/design-system";
import { DSAvatar, DSProgressBar, TypeProgressBar } from "@skillup/ui";
import { getUserInitials } from "utils/User";
import { TranslationType } from "hooks/useTranslation";
import styles from "./EvaluatedUserTargetsDataGrid.module.scss";

export type TargetRow = ReturnType<typeof parseTableRows>;

export const useTableData = (
  targets: UserTarget[] | undefined,
  t: TranslationType
): {
  data: TargetRow[];
  columns: Array<GridColDef>;
} => {
  const columns = useMemo(() => generateColumns(t), [t]);
  const data = useMemo(() => generateTableRows(targets ?? []), [targets]);

  return { columns, data };
};

function generateTableRows(targets: Array<UserTarget>) {
  return targets.map(parseTableRows);
}

const parseTableRows = (target: UserTarget) => ({
  id: target.uuid,
  data: target,
});

function generateColumns(t: TranslationType) {
  const columns = [
    {
      field: "name",
      headerName: t("supervisor.view.targets.evaluated.column.name", {
        defaultValue: "Nom de l'objectif",
      }),
      filterable: true,
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.name,
    },
    {
      field: "evaluation.manager.completion",
      headerName: t("supervisor.view.targets.evaluated.column.evaluation", {
        defaultValue: "Évaluation",
      }),
      filterable: false,
      sortable: false,
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.evaluation?.manager?.completion,
      renderCell: (params: GridValueGetterParams) => {
        const evaluation = params.row?.data?.evaluation?.manager;
        if (params.row?.data?.scale === "ordinal") {
          const choices = params.row?.data?.choices;
          return <span>{choices?.[evaluation?.completion - 1]}</span>;
        }
        const goal = params.row?.data?.goal;
        return (
          <DSProgressBar
            className={styles["datagrid__evaluation--progressbar"]}
            layout="list"
            type={TypeProgressBar.PERCENTAGE}
            thin={true}
            valueFormatter={(_, value) => <span>{value} %</span>}
            data={[
              {
                label: "",
                value: goal
                  ? Math.round((evaluation.completion * 10000) / goal)
                  : evaluation.completion,
                color: "yellow",
              },
            ]}
          />
        );
      },
    },
    {
      field: "targetCategoryLabel",
      headerName: t("supervisor.view.targets.evaluated.column.category", {
        defaultValue: "Catégorie",
      }),
      filterable: true,
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.targetCategoryLabel,
    },
    {
      field: "weight",
      headerName: t("supervisor.view.targets.evaluated.column.weight", {
        defaultValue: "Pondération",
      }),
      filterable: false,
      sortable: false,
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.weight,
      renderCell: (params: GridValueGetterParams) => {
        const weight = params.row?.data?.weight;
        if (isNil(weight)) {
          return <span>—</span>;
        } else {
          return <span>{Number(weight.toFixed(2))} %</span>;
        }
      },
    },
    {
      field: "evaluationCampaign.titleForHR",
      headerName: t("supervisor.view.targets.evaluated.column.campaign", {
        defaultValue: "Campagne d'évaluation",
      }),
      filterable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.evaluationCampaign?.titleForHR,
    },
    {
      field: "manager",
      headerName: t("supervisor.view.targets.evaluated.column.manager", {
        defaultValue: "Évaluateur",
      }),
      filterable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => row.data.manager.fullName,
      renderCell: (params: GridValueGetterParams) => {
        const manager = params.row?.data?.manager;
        return (
          <Flex flexDirection="row" alignItems="center" gap="xs">
            <DSAvatar
              size="S"
              type="Initials"
              imageUrl={undefined}
              initials={getUserInitials(manager)}
              randomColorSeedString={manager?.uuid}
              tooltip={manager?.fullName}
            />
            <span>{manager.fullName}</span>
          </Flex>
        );
      },
    },
    {
      field: "evaluationDate",
      headerName: t("supervisor.view.targets.evaluated.column.evaluationDate", {
        defaultValue: "Date d'évaluation",
      }),
      filterable: false,
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }: { row: TargetRow }) => {
        if (row.data.evaluationDate) {
          return row.data.evaluationDate;
        }
        // if (row.data.evaluation?.manager?.updatedAt) {
        //   return row.data.evaluation.manager.updatedAt;
        // }
        // if (row.data.evaluation?.manager?.createdAt) {
        //   return row.data.evaluation.manager.createdAt;
        // }
        return null;
      },
      renderCell: ({ value }: GridValueGetterParams) => {
        return (
          <span>
            {value
              ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT, { locale: "fr" })
              : ""}
          </span>
        );
      },
    },
  ];
  return columns;
}
