import { useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";

import { Radar, Switch } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import { RadarContainer } from "./RadarList.styled";
import { computeRadarData } from "@skillup/shared-utils";

type RadarListProps = {
  categories: Array<{
    name: string;
    skills: Array<{
      name: string;
      level: number;
      levelName: string;
      levelDescription?: string;
      totalLevel: Array<{ level: number }>;
      expectedLevel?: number;
      expectedLevelName?: string;
      expectedLevelDescription?: string;
      evaluatedBy: string;
      evaluatedAt: string;
      levelRange: { min: number; max: number };
    }>;
  }>;
};

export const RadarList = ({ categories }: RadarListProps) => {
  const { t } = useTranslation();
  const [isSingleRadar, setSingleRadar] = useState(false);

  const concatenatedCategories = useMemo(() => {
    if (isSingleRadar) {
      const newCategories = categories.reduce(
        (acc, category) => {
          return {
            name: t("collaborator.skills.allCategories", {
              defaultValue: "Toutes les catégories",
            }),
            skills: [...acc.skills, ...category.skills],
          };
        },
        {
          name: "",
          skills: [],
        }
      );

      return [newCategories];
    } else {
      return categories;
    }
  }, [isSingleRadar, categories, t]);

  const radar = useMemo(() => {
    return concatenatedCategories?.map((category) => {
      const { skills } = category;

      const { enhancedSkills, gridLevels, keys } = computeRadarData(skills);

      let margin = { top: 70, right: 0, bottom: 130, left: 0 };

      if (skills.length > 12) {
        margin = { top: 30, right: 0, bottom: 60, left: 0 };
      }

      return (
        <RadarContainer key={category.name}>
          <Text espaceFont="h6" color="plainText-onLight-lighter">
            {category.name}
          </Text>
          <Radar margin={margin} keys={keys} data={enhancedSkills} gridLevels={gridLevels} />
        </RadarContainer>
      );
    });
  }, [concatenatedCategories]);

  return (
    <>
      {categories.length > 1 && (
        <Flex marginBottom="s">
          <Switch
            label={t("collaborator.skills.groupAllSkills", {
              defaultValue: "Afficher toutes les compétences sur un seul radar",
            })}
            active={isSingleRadar}
            onToggle={setSingleRadar}
          />
        </Flex>
      )}
      <Flex flexDirection="column" alignItems="center">
        {radar}
      </Flex>
    </>
  );
};
