import { type TranslationObject } from "../../../types";

export const HOT_SURVEY_TRANSLATIONS: TranslationObject = {
  fr: {
    question_0: "La formation était adaptée à mes objectifs",
    "question_0.answer_0": "Tout à fait d'accord",
    "question_0.answer_1": "Plutôt d'accord",
    "question_0.answer_2": "Neutre",
    "question_0.answer_3": "Plutôt pas d'accord",
    "question_0.answer_4": "Pas du tout d'accord",
    question_1:
      "Le formateur maitrise parfaitement son sujet, les supports pédagogiques sont adaptés",
    "question_1.answer_0": "Tout à fait d'accord",
    "question_1.answer_1": "Plutôt d'accord",
    "question_1.answer_2": "Neutre",
    "question_1.answer_3": "Plutôt pas d'accord",
    "question_1.answer_4": "Pas du tout d'accord",
    question_2: "Mon intérêt a été maintenu tout au long de la formation",
    "question_2.answer_0": "Tout à fait d'accord",
    "question_2.answer_1": "Plutôt d'accord",
    "question_2.answer_2": "Neutre",
    "question_2.answer_3": "Plutôt pas d'accord",
    "question_2.answer_4": "Pas du tout d'accord",
    question_3: "Les objectifs décrits en début de formation ont été atteints",
    "question_3.answer_0": "Tout à fait d'accord",
    "question_3.answer_1": "Plutôt d'accord",
    "question_3.answer_2": "Neutre",
    "question_3.answer_3": "Plutôt pas d'accord",
    "question_3.answer_4": "Pas du tout d'accord",
    question_4: "L'équilibre entre apport théorique et exercices pratiques était bon",
    "question_4.answer_0": "Tout à fait d'accord",
    "question_4.answer_1": "Plutôt d'accord",
    "question_4.answer_2": "Neutre",
    "question_4.answer_3": "Plutôt pas d'accord",
    "question_4.answer_4": "Pas du tout d'accord",
    question_5:
      "L'organisation pratique de la formation était bonne (accueil, locaux et/ou simplicité de connexion, logiciel...)",
    "question_5.answer_0": "Tout à fait d'accord",
    "question_5.answer_1": "Plutôt d'accord",
    "question_5.answer_2": "Neutre",
    "question_5.answer_3": "Plutôt pas d'accord",
    "question_5.answer_4": "Pas du tout d'accord",
    question_6: "Je recommanderais cette formation à un collègue",
    "question_6.answer_0": "Tout à fait d'accord",
    "question_6.answer_1": "Plutôt d'accord",
    "question_6.answer_2": "Neutre",
    "question_6.answer_3": "Plutôt pas d'accord",
    "question_6.answer_4": "Pas du tout d'accord",
    question_7: "Quels étaient les points forts et les points d'amélioration de cette formation ?",
  },
  en: {
    question_0: "The training was suited to my objectives",
    "question_0.answer_0": "Strongly agree",
    "question_0.answer_1": "Somewhat agree",
    "question_0.answer_2": "Neutral",
    "question_0.answer_3": "Somewhat disagree",
    "question_0.answer_4": "Strongly disagree",
    question_1:
      "The trainer perfectly masters the subject, and the teaching materials are appropriate",
    "question_1.answer_0": "Strongly agree",
    "question_1.answer_1": "Somewhat agree",
    "question_1.answer_2": "Neutral",
    "question_1.answer_3": "Somewhat disagree",
    "question_1.answer_4": "Strongly disagree",
    question_2: "My interest was maintained throughout the training",
    "question_2.answer_0": "Strongly agree",
    "question_2.answer_1": "Somewhat agree",
    "question_2.answer_2": "Neutral",
    "question_2.answer_3": "Somewhat disagree",
    "question_2.answer_4": "Strongly disagree",
    question_3: "The objectives described at the beginning of the training were achieved",
    "question_3.answer_0": "Strongly agree",
    "question_3.answer_1": "Somewhat agree",
    "question_3.answer_2": "Neutral",
    "question_3.answer_3": "Somewhat disagree",
    "question_3.answer_4": "Strongly disagree",
    question_4: "The balance between theoretical input and practical exercises was good",
    "question_4.answer_0": "Strongly agree",
    "question_4.answer_1": "Somewhat agree",
    "question_4.answer_2": "Neutral",
    "question_4.answer_3": "Somewhat disagree",
    "question_4.answer_4": "Strongly disagree",
    question_5:
      "The practical organization of the training was good (reception, premises and/or ease of connection, software, etc.)",
    "question_5.answer_0": "Strongly agree",
    "question_5.answer_1": "Somewhat agree",
    "question_5.answer_2": "Neutral",
    "question_5.answer_3": "Somewhat disagree",
    "question_5.answer_4": "Strongly disagree",
    question_6: "I would recommend this training to a colleague",
    "question_6.answer_0": "Strongly agree",
    "question_6.answer_1": "Somewhat agree",
    "question_6.answer_2": "Neutral",
    "question_6.answer_3": "Somewhat disagree",
    "question_6.answer_4": "Strongly disagree",
    question_7: "What were the strengths and weaknesses of this training?",
  },
  de: {
    question_0: "Die Schulung war auf meine Ziele abgestimmt",
    "question_0.answer_0": "Ganz einverstanden",
    "question_0.answer_1": "Eher einverstanden",
    "question_0.answer_2": "Neutral",
    "question_0.answer_3": "Eher nicht einverstanden",
    "question_0.answer_4": "Überhaupt nicht einverstanden",
    question_1: "Der Trainer beherrscht das Thema perfekt, die Lehrmaterialien sind angemessen",
    "question_1.answer_0": "Ganz einverstanden",
    "question_1.answer_1": "Eher einverstanden",
    "question_1.answer_2": "Neutral",
    "question_1.answer_3": "Eher nicht einverstanden",
    "question_1.answer_4": "Überhaupt nicht einverstanden",
    question_2: "Mein Interesse wurde während der gesamten Schulung aufrechterhalten",
    "question_2.answer_0": "Ganz einverstanden",
    "question_2.answer_1": "Eher einverstanden",
    "question_2.answer_2": "Neutral",
    "question_2.answer_3": "Eher nicht einverstanden",
    "question_2.answer_4": "Überhaupt nicht einverstanden",
    question_3: "Die zu Beginn der Schulung beschriebenen Ziele wurden erreicht",
    "question_3.answer_0": "Ganz einverstanden",
    "question_3.answer_1": "Eher einverstanden",
    "question_3.answer_2": "Neutral",
    "question_3.answer_3": "Eher nicht einverstanden",
    "question_3.answer_4": "Überhaupt nicht einverstanden",
    question_4: "Das Gleichgewicht zwischen theoretischem Input und praktischen Übungen war gut",
    "question_4.answer_0": "Ganz einverstanden",
    "question_4.answer_1": "Eher einverstanden",
    "question_4.answer_2": "Neutral",
    "question_4.answer_3": "Eher nicht einverstanden",
    "question_4.answer_4": "Überhaupt nicht einverstanden",
    question_5:
      "Die praktische Organisation der Schulung war gut (Empfang, Räumlichkeiten und/oder einfache Verbindung, Software...)",
    "question_5.answer_0": "Ganz einverstanden",
    "question_5.answer_1": "Eher einverstanden",
    "question_5.answer_2": "Neutral",
    "question_5.answer_3": "Eher nicht einverstanden",
    "question_5.answer_4": "Überhaupt nicht einverstanden",
    question_6: "Ich würde diese Schulung einem Kollegen empfehlen",
    "question_6.answer_0": "Ganz einverstanden",
    "question_6.answer_1": "Eher einverstanden",
    "question_6.answer_2": "Neutral",
    "question_6.answer_3": "Eher nicht einverstanden",
    "question_6.answer_4": "Überhaupt nicht einverstanden",
    question_7: "Welche waren die Stärken und Verbesserungspunkte dieser Schulung?",
  },
  it: {
    question_0: "La formazione era adatta ai miei obiettivi",
    "question_0.answer_0": "Completamente d'accordo",
    "question_0.answer_1": "Piuttosto d'accordo",
    "question_0.answer_2": "Neutrale",
    "question_0.answer_3": "Piuttosto in disaccordo",
    "question_0.answer_4": "Per niente d'accordo",
    question_1:
      "Il formatore padroneggia perfettamente l'argomento, i materiali didattici sono adeguati",
    "question_1.answer_0": "Completamente d'accordo",
    "question_1.answer_1": "Piuttosto d'accordo",
    "question_1.answer_2": "Neutrale",
    "question_1.answer_3": "Piuttosto in disaccordo",
    "question_1.answer_4": "Per niente d'accordo",
    question_2: "Il mio interesse è stato mantenuto durante tutta la formazione",
    "question_2.answer_0": "Completamente d'accordo",
    "question_2.answer_1": "Piuttosto d'accordo",
    "question_2.answer_2": "Neutrale",
    "question_2.answer_3": "Piuttosto in disaccordo",
    "question_2.answer_4": "Per niente d'accordo",
    question_3: "Gli obiettivi descritti all'inizio della formazione sono stati raggiunti",
    "question_3.answer_0": "Completamente d'accordo",
    "question_3.answer_1": "Piuttosto d'accordo",
    "question_3.answer_2": "Neutrale",
    "question_3.answer_3": "Piuttosto in disaccordo",
    "question_3.answer_4": "Per niente d'accordo",
    question_4: "L'equilibrio tra input teorico ed esercizi pratici era buono",
    "question_4.answer_0": "Completamente d'accordo",
    "question_4.answer_1": "Piuttosto d'accordo",
    "question_4.answer_2": "Neutrale",
    "question_4.answer_3": "Piuttosto in disaccordo",
    "question_4.answer_4": "Per niente d'accordo",
    question_5:
      "L'organizzazione pratica della formazione è stata buona (accoglienza, locali e/o semplicità di connessione, software...)",
    "question_5.answer_0": "Completamente d'accordo",
    "question_5.answer_1": "Piuttosto d'accordo",
    "question_5.answer_2": "Neutrale",
    "question_5.answer_3": "Piuttosto in disaccordo",
    "question_5.answer_4": "Per niente d'accordo",
    question_6: "Consiglierei questa formazione a un collega",
    "question_6.answer_0": "Completamente d'accordo",
    "question_6.answer_1": "Piuttosto d'accordo",
    "question_6.answer_2": "Neutrale",
    "question_6.answer_3": "Piuttosto in disaccordo",
    "question_6.answer_4": "Per niente d'accordo",
    question_7: "Quali erano i punti di forza e i punti di miglioramento di questa formazione?",
  },
  es: {
    question_0: "La formación se ajustó a mis objetivos",
    "question_0.answer_0": "Totalmente de acuerdo",
    "question_0.answer_1": "Más o menos de acuerdo",
    "question_0.answer_2": "Neutral",
    "question_0.answer_3": "Más bien en desacuerdo",
    "question_0.answer_4": "Totalmente en desacuerdo",
    question_1:
      "El formador domina perfectamente el tema, y los materiales pedagógicos son adecuados",
    "question_1.answer_0": "Totalmente de acuerdo",
    "question_1.answer_1": "Más o menos de acuerdo",
    "question_1.answer_2": "Neutral",
    "question_1.answer_3": "Más bien en desacuerdo",
    "question_1.answer_4": "Totalmente en desacuerdo",
    question_2: "Mi interés se mantuvo durante toda la formación",
    "question_2.answer_0": "Totalmente de acuerdo",
    "question_2.answer_1": "Más o menos de acuerdo",
    "question_2.answer_2": "Neutral",
    "question_2.answer_3": "Más bien en desacuerdo",
    "question_2.answer_4": "Totalmente en desacuerdo",
    question_3: "Los objetivos descritos al comienzo de la formación se lograron",
    "question_3.answer_0": "Totalmente de acuerdo",
    "question_3.answer_1": "Más o menos de acuerdo",
    "question_3.answer_2": "Neutral",
    "question_3.answer_3": "Más bien en desacuerdo",
    "question_3.answer_4": "Totalmente en desacuerdo",
    question_4: "El equilibrio entre los aportes teóricos y los ejercicios prácticos fue bueno",
    "question_4.answer_0": "Totalmente de acuerdo",
    "question_4.answer_1": "Más o menos de acuerdo",
    "question_4.answer_2": "Neutral",
    "question_4.answer_3": "Más bien en desacuerdo",
    "question_4.answer_4": "Totalmente en desacuerdo",
    question_5:
      "La organización práctica de la formación fue buena (recepción, instalaciones y/o facilidad de conexión, software...)",
    "question_5.answer_0": "Totalmente de acuerdo",
    "question_5.answer_1": "Más o menos de acuerdo",
    "question_5.answer_2": "Neutral",
    "question_5.answer_3": "Más bien en desacuerdo",
    "question_5.answer_4": "Totalmente en desacuerdo",
    question_6: "Recomendaría esta formación a un colega",
    "question_6.answer_0": "Totalmente de acuerdo",
    "question_6.answer_1": "Más o menos de acuerdo",
    "question_6.answer_2": "Neutral",
    "question_6.answer_3": "Más bien en desacuerdo",
    "question_6.answer_4": "Totalmente en desacuerdo",
    question_7: "¿Cuáles fueron los puntos fuertes y los puntos de mejora de esta formación?",
  },
};

export const COLD_SURVEY_TRANSLATIONS: TranslationObject = {
  fr: {
    question_0: "Considérez-vous que cette formation vous a permis de prendre confiance en vous ?",
    "question_0.answer_0": "Oui",
    "question_0.answer_1": "Non",
    question_1: "Considérez-vous que cette formation vous a permis de faciliter votre quotidien ?",
    "question_1.answer_0": "Oui",
    "question_1.answer_1": "Non",
    question_2:
      "Considérez-vous que cette formation vous a permis d’améliorer la qualité ou l’efficacité de votre travail ?",
    "question_2.answer_0": "Oui",
    "question_2.answer_1": "Non",
    question_3:
      "Considérez-vous que cette formation vous a permis de vous perfectionner dans un domaine que vous connaissiez déjà ?",
    "question_3.answer_0": "Oui",
    "question_3.answer_1": "Non",
    question_4:
      "Considérez-vous que cette formation vous a permis de développer de nouvelles compétences ?",
    "question_4.answer_0": "Oui",
    "question_4.answer_1": "Non",
    question_5: "Autres (à préciser)",
    question_6: "La formation visait-elle la préparation d’un diplôme ou d’une certification ?",
    "question_6.answer_0": "Oui",
    "question_6.answer_1": "Non",
    question_7: "Si oui, avez-vous obtenu le diplôme ou la certification visé(e) ?",
    "question_7.answer_0": "Oui",
    "question_7.answer_1": "Non",
    question_8: "Si non, pourquoi :",
    question_9: "La formation a-t-elle répondu à vos attentes initiales ?",
    "question_9.answer_0": "Oui, tout à fait",
    "question_9.answer_1": "Oui, en partie",
    "question_9.answer_2": "Non, pas vraiment",
    "question_9.answer_3": "Non, pas du tout",
    question_10:
      "Estimez-vous que la formation était en adéquation avec le métier ou les réalités du secteur ?",
    "question_10.answer_0": "Oui, tout à fait",
    "question_10.answer_1": "Oui, en partie",
    "question_10.answer_2": "Non, pas vraiment",
    "question_10.answer_3": "Non, pas du tout",
    question_11: "Recommanderiez-vous ce stage à une personne exerçant le même métier que vous ?",
    "question_11.answer_0": "Oui, tout à fait",
    "question_11.answer_1": "Oui, en partie",
    "question_11.answer_2": "Non, pas vraiment",
    "question_11.answer_3": "Non, pas du tout",
    question_12: "Utilisez-vous les connaissances acquises lors de la formation ?",
    "question_12.answer_0": "Oui, tout à fait",
    "question_12.answer_1": "Oui, en partie",
    "question_12.answer_2": "Non, pas vraiment",
    "question_12.answer_3": "Non, pas du tout",
    question_13:
      "Quels étaient selon vous les principaux points forts et les principaux axes d’amélioration de cette formation ?",
  },
  en: {
    question_0: "Do you consider that this training helped you gain self-confidence?",
    "question_0.answer_0": "Yes",
    "question_0.answer_1": "No",
    question_1: "Do you consider that this training helped facilitate your daily work?",
    "question_1.answer_0": "Yes",
    "question_1.answer_1": "No",
    question_2:
      "Do you consider that this training improved the quality or efficiency of your work?",
    "question_2.answer_0": "Yes",
    "question_2.answer_1": "No",
    question_3:
      "Do you consider that this training helped you improve in a field you were already familiar with?",
    "question_3.answer_0": "Yes",
    "question_3.answer_1": "No",
    question_4: "Do you consider that this training allowed you to develop new skills?",
    "question_4.answer_0": "Yes",
    "question_4.answer_1": "No",
    question_5: "Other (please specify)",
    question_6: "Was the training aimed at preparing for a diploma or certification?",
    "question_6.answer_0": "Yes",
    "question_6.answer_1": "No",
    question_7: "If yes, did you obtain the targeted diploma or certification?",
    "question_7.answer_0": "Yes",
    "question_7.answer_1": "No",
    question_8: "If not, why?",
    question_9: "Did the training meet your initial expectations?",
    "question_9.answer_0": "Yes, completely",
    "question_9.answer_1": "Yes, partially",
    "question_9.answer_2": "No, not really",
    "question_9.answer_3": "No, not at all",
    question_10:
      "Do you think the training was aligned with the job or the realities of the sector?",
    "question_10.answer_0": "Yes, completely",
    "question_10.answer_1": "Yes, partially",
    "question_10.answer_2": "No, not really",
    "question_10.answer_3": "No, not at all",
    question_11: "Would you recommend this training to someone in the same profession as you?",
    "question_11.answer_0": "Yes, completely",
    "question_11.answer_1": "Yes, partially",
    "question_11.answer_2": "No, not really",
    "question_11.answer_3": "No, not at all",
    question_12: "Are you using the knowledge acquired during the training?",
    "question_12.answer_0": "Yes, completely",
    "question_12.answer_1": "Yes, partially",
    "question_12.answer_2": "No, not really",
    "question_12.answer_3": "No, not at all",
    question_13: "What, in your opinion, were the main strengths and weaknesses of this training?",
  },
  de: {
    question_0: "Haben Sie das Gefühl, dass diese Schulung Ihr Selbstvertrauen gestärkt hat ?",
    "question_0.answer_0": "Ja",
    "question_0.answer_1": "Nein",
    question_1: "Haben Sie das Gefühl, dass diese Schulung Ihren Alltag erleichtert hat ?",
    "question_1.answer_0": "Ja",
    "question_1.answer_1": "Nein",
    question_2:
      "Haben Sie das Gefühl, dass diese Schulung die Qualität oder Effizienz Ihrer Arbeit verbessert hat ?",
    "question_2.answer_0": "Ja",
    "question_2.answer_1": "Nein",
    question_3:
      "Haben Sie das Gefühl, dass diese Schulung Sie in einem Bereich, den Sie bereits kannten, verbessert hat ?",
    "question_3.answer_0": "Ja",
    "question_3.answer_1": "Nein",
    question_4:
      "Haben Sie das Gefühl, dass diese Schulung Ihnen geholfen hat, neue Fähigkeiten zu entwickeln ?",
    "question_4.answer_0": "Ja",
    "question_4.answer_1": "Nein",
    question_5: "Andere (bitte angeben)",
    question_6:
      "War die Schulung auf die Vorbereitung eines Diploms oder einer Zertifizierung ausgerichtet ?",
    "question_6.answer_0": "Ja",
    "question_6.answer_1": "Nein",
    question_7: "Wenn ja, haben Sie das angestrebte Diplom oder die Zertifizierung erhalten ?",
    "question_7.answer_0": "Ja",
    "question_7.answer_1": "Nein",
    question_8: "Wenn nein, warum :",
    question_9: "Hat die Schulung Ihre anfänglichen Erwartungen erfüllt ?",
    "question_9.answer_0": "Ja, vollständig",
    "question_9.answer_1": "Ja, teilweise",
    "question_9.answer_2": "Nicht wirklich",
    "question_9.answer_3": "Überhaupt nicht",
    question_10:
      "Halten Sie die Schulung für angemessen für den Beruf oder die Realität des Sektors ?",
    "question_10.answer_0": "Ja, vollständig",
    "question_10.answer_1": "Ja, teilweise",
    "question_10.answer_2": "Nicht wirklich",
    "question_10.answer_3": "Überhaupt nicht",
    question_11: "Würden Sie dieses Praktikum einer Person mit dem gleichen Beruf empfehlen ?",
    "question_11.answer_0": "Ja, vollständig",
    "question_11.answer_1": "Ja, teilweise",
    "question_11.answer_2": "Nicht wirklich",
    "question_11.answer_3": "Überhaupt nicht",
    question_12: "Verwenden Sie die während der Schulung erworbenen Kenntnisse ?",
    "question_12.answer_0": "Ja, vollständig",
    "question_12.answer_1": "Ja, teilweise",
    "question_12.answer_2": "Nicht wirklich",
    "question_12.answer_3": "Überhaupt nicht",
    question_13:
      "Was waren Ihrer Meinung nach die Hauptstärken und die wichtigsten Verbesserungsbereiche dieser Schulung ?",
  },
  it: {
    question_0:
      "Ritiene che questa formazione le abbia permesso di acquisire fiducia in sé stesso?",
    "question_0.answer_0": "Si",
    "question_0.answer_1": "No",
    question_1:
      "Ritiene che questa formazione le abbia permesso di semplificare la sua vita quotidiana?",
    "question_1.answer_0": "Si",
    "question_1.answer_1": "No",
    question_2:
      "Ritiene che questa formazione le abbia permesso di migliorare la qualità o l'efficienza del suo lavoro?",
    "question_2.answer_0": "Si",
    "question_2.answer_1": "No",
    question_3:
      "Ritiene che questa formazione le abbia permesso di perfezionarsi in un settore che già conosceva?",
    "question_3.answer_0": "Si",
    "question_3.answer_1": "No",
    question_4: "Ritiene che questa formazione le abbia permesso di sviluppare nuove competenze?",
    "question_4.answer_0": "Si",
    "question_4.answer_1": "No",
    question_5: "Altro (specificare)",
    question_6:
      "La formazione era finalizzata alla preparazione di un diploma o di una certificazione?",
    "question_6.answer_0": "Si",
    "question_6.answer_1": "No",
    question_7: "In caso affermativo, ha ottenuto il diploma o la certificazione desiderata?",
    "question_7.answer_0": "Si",
    "question_7.answer_1": "No",
    question_8: "Se no, perché :",
    question_9: "La formazione ha soddisfatto le sue aspettative iniziali ?",
    "question_9.answer_0": "Sì, completamente",
    "question_9.answer_1": "Sì, in parte",
    "question_9.answer_2": "Non proprio",
    "question_9.answer_3": "Per niente",
    question_10: "Ritiene che la formazione fosse adeguata al mestiere o alle realtà del settore ?",
    "question_10.answer_0": "Sì, completamente",
    "question_10.answer_1": "Sì, in parte",
    "question_10.answer_2": "Non proprio",
    "question_10.answer_3": "Per niente",
    question_11: "Consiglierebbe questo stage a una persona che svolge lo stesso lavoro ?",
    "question_11.answer_0": "Sì, completamente",
    "question_11.answer_1": "Sì, in parte",
    "question_11.answer_2": "Non proprio",
    "question_11.answer_3": "Per niente",
    question_12: "Sta utilizzando le conoscenze acquisite durante la formazione ?",
    "question_12.answer_0": "Sì, completamente",
    "question_12.answer_1": "Sì, in parte",
    "question_12.answer_2": "Non proprio",
    "question_12.answer_3": "Per niente",
    question_13:
      "Quali sono stati secondo lei i principali punti di forza e le principali aree di miglioramento di questa formazione ?",
  },
  es: {
    question_0: "¿Considera que esta formación le permitió ganar confianza en sí mismo?",
    "question_0.answer_0": "Si",
    "question_0.answer_1": "No",
    question_1: "¿Considera que esta formación le permitió facilitar su día a día?",
    "question_1.answer_0": "Si",
    "question_1.answer_1": "No",
    question_2:
      "¿Considera que esta formación le permitió mejorar la calidad o la eficiencia de su trabajo?",
    "question_2.answer_0": "Si",
    "question_2.answer_1": "No",
    question_3:
      "¿Considera que esta formación le permitió perfeccionarse en un área que ya conocía?",
    "question_3.answer_0": "Si",
    "question_3.answer_1": "No",
    question_4: "¿Considera que esta formación le permitió desarrollar nuevas competencias?",
    "question_4.answer_0": "Si",
    "question_4.answer_1": "No",
    question_5: "Otros (especificar)",
    question_6: "¿La formación estaba orientada a la preparación de un diploma o certificación?",
    "question_6.answer_0": "Si",
    "question_6.answer_1": "No",
    question_7: "Si es así, ¿obtuvo el diploma o la certificación deseada?",
    "question_7.answer_0": "Si",
    "question_7.answer_1": "No",
    question_8: "Si no, ¿por qué :",
    question_9: "¿La formación cumplió con sus expectativas iniciales ?",
    "question_9.answer_0": "Sí, completamente",
    "question_9.answer_1": "Sí, parcialmente",
    "question_9.answer_2": "No, no realmente",
    "question_9.answer_3": "No, en absoluto",
    question_10:
      "¿Considera que la formación estaba alineada con el trabajo o las realidades del sector ?",
    "question_10.answer_0": "Sí, completamente",
    "question_10.answer_1": "Sí, parcialmente",
    "question_10.answer_2": "No, no realmente",
    "question_10.answer_3": "No, en absoluto",
    question_11: "¿Recomendaría esta práctica a alguien que ejerza la misma profesión que usted ?",
    "question_11.answer_0": "Sí, completamente",
    "question_11.answer_1": "Sí, parcialmente",
    "question_11.answer_2": "No, no realmente",
    "question_11.answer_3": "No, en absoluto",
    question_12: "¿Está utilizando los conocimientos adquiridos durante la formación ?",
    "question_12.answer_0": "Sí, completamente",
    "question_12.answer_1": "Sí, parcialmente",
    "question_12.answer_2": "No, no realmente",
    "question_12.answer_3": "No, en absoluto",
    question_13:
      "¿Cuáles fueron, según su opinión, los principales puntos fuertes y áreas de mejora de esta formación ?",
  },
};
