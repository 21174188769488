import React from "react";
import cx from "classnames";

import { type Themes } from "components/commonProps";

import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { FormGroupInput as FormGroupTextInput } from "components/FormGroup/FormGroupTextInput";
import { Dropdown } from "components/Dropdown/Dropdown";

import { More } from "components/MaterialIcons";

import styles from "./Filter.module.scss";
import { FilterComponent } from "./FilterComponent";
import { type FilterProps } from "../types";
import { type ListUtils } from "@skillup/shared-utils";

interface Props {
  readonly theme: Themes;
  readonly filter?: FilterProps<any>;
  readonly config: ListUtils.FilterConfiguration;
  readonly value: ListUtils.FilterValue;
  readonly id: string;
  readonly darkMode?: boolean;
  readonly onChange: (filterId: string, value: any) => void;
  readonly onReset: (filterId: string) => void;
  readonly onDelete: (filterId: string) => void;
  readonly t: (key: string, params?: { defaultValue: string } & any) => string;
  readonly translationPrefix?: string;
}

const Filter = ({
  t,
  translationPrefix = "filters",
  theme,
  id,
  filter,
  config,
  value,
  darkMode = false,
  onChange,
  onReset,
  onDelete,
}: Props): JSX.Element | null => {
  const { label, visibilityMode, defaultValue } = filter ?? {};
  const baseId = id.split("_$")[0];

  return (
    <FormGroupTextInput
      role="filter-container"
      key={id}
      className={cx(styles.Filter)}
      label={
        <Flex className={styles.GroupLabel}>
          {label ?? t(`filter.label.${baseId}`)}
          <Dropdown
            theme={theme}
            button={
              <Button
                darkMode={darkMode}
                role="more-filter-button"
                buttonSize="S"
                iconOnly
                icon={<More />}
              />
            }
          >
            <Dropdown.Item
              label={t(`${translationPrefix}-reset`, {
                defaultValue: "Réinitialiser le filtre",
              })}
              onClick={() => onReset(id)}
            />
            {visibilityMode !== "always" && defaultValue === undefined && (
              <Dropdown.Item
                label={t(`${translationPrefix}-delete`, {
                  defaultValue: "Supprimer le filtre",
                })}
                onClick={() => onDelete(id)}
              />
            )}
          </Dropdown>
        </Flex>
      }
    >
      <FilterComponent
        t={t}
        id={id}
        config={config}
        filter={filter}
        value={value}
        onChange={onChange}
        darkMode={darkMode}
      />
    </FormGroupTextInput>
  );
};

export { Filter };
