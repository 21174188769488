import { Either } from "@skillup/monads";
import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

export type PartialCancelParams = {
  rowsUuid: string[];
  message?: string;
  deniedOrCancelledReason?: string;
  cancelledMessageForTrainingOrganization?: string;
};
export async function partialCancel({
  rowsUuid,
  message,
  deniedOrCancelledReason,
  cancelledMessageForTrainingOrganization,
}: PartialCancelParams) {
  try {
    await buildRequest<ScheduleRowRoutes.CancelRow>({
      method: "POST",
      path: "/scheduleRow/cancel",
      payload: {
        rows: rowsUuid,
        message,
        deniedOrCancelledReason,
        cancelledMessageForTrainingOrganization,
      },
    })();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(undefined);
  }
}
