import React, { useRef } from "react";
import { isEmpty, isNil } from "lodash";

import { TextInput, TextArea, RadioBox, TextDropdown, AssistiveArea, DSTooltip } from "@skillup/ui";

import { matchingFields, FieldElement, Organization } from "./config";
import CreateLabel from "components/CreateLabel";

import styles from "./CompanyForm.module.scss";
import FindUser from "components/FindUser";
import Colors from "uiAssets/Colors";
import { UpdateChildPayload, UpdatePayload } from "hooks/useCompany";
import User from "utils/User";
import useTranslation from "hooks/useTranslation";

export interface IForm {
  [fieldBinding: string]:
    | {
        value?: any;
        error?: boolean;
        errorMessage?: string;
      }
    | undefined;
}

export const LoadOrganizationToForm = (organization?: Organization) => {
  return matchingFields.reduce((acc, curr) => {
    if (curr.field === "bookingManager") {
      return {
        ...acc,
        [curr.field]: organization?.bookingManager
          ? {
              value: {
                email: organization?.bookingManagerEmail,
                fullName: organization?.bookingManager,
                organizations: [],
                organizationsByUuid: [],
              },
            }
          : undefined,
      };
    }

    const value = organization[curr.field];

    return {
      ...acc,
      [curr.field]: { value },
    };
  }, {});
};

export const ValidateForm = (form: IForm): { form: IForm; hasError: boolean } => {
  return matchingFields.reduce<{ form: IForm; hasError: boolean }>(
    (acc, matchingField) => {
      const value = form[matchingField.field]?.value;

      if (matchingField.required && isEmpty(value)) {
        return {
          form: {
            ...acc.form,
            [matchingField.field]: {
              value,
              error: true,
            },
          },
          hasError: true,
        };
      }

      if (
        matchingField.field === "bookingManagerPhone" &&
        !isEmpty(value) &&
        (!value.match(/^\+?\d+$/) || value.length < 10 || value.length > 20)
      ) {
        return {
          form: {
            ...acc.form,
            [matchingField.field]: {
              value,
              error: true,
            },
          },
          hasError: true,
        };
      }

      if (
        matchingField.field === "legalRegistrationSIREN" &&
        !isEmpty(value) &&
        !value.match(/^[0-9]{9}$/)
      ) {
        return {
          form: {
            ...acc.form,
            [matchingField.field]: {
              value,
              error: true,
              errorMessage: "Le SIREN doit contenir 9 chiffres",
            },
          },
          hasError: true,
        };
      }

      if (
        matchingField.field === "legalRegistrationSIRET" &&
        !isEmpty(value) &&
        !value.match(/^[0-9]{14}$/)
      ) {
        return {
          form: {
            ...acc.form,
            [matchingField.field]: {
              value,
              error: true,
              errorMessage: "Le SIRET doit contenir 14 chiffres",
            },
          },
          hasError: true,
        };
      }

      return {
        ...acc,
        form: {
          ...acc.form,
          [matchingField.field]: { value },
        },
      };
    },
    { form: {}, hasError: false }
  );
};

export const BuildPayloadFromForm = (form: IForm) => {
  return matchingFields.reduce((acc, curr) => {
    if (curr.field === "bookingManager") {
      const value = form[curr.field]?.value;

      return {
        ...acc,
        ...(value && { bookingManager: value.email }),
      };
    }

    const value = form[curr.field]?.value;

    return {
      ...acc,
      [curr.field]: value,
    };
  }, {} as UpdatePayload | UpdateChildPayload);
};

export interface Props {
  readonly form: IForm;
  readonly setFormState: (patch: Partial<IForm> | ((prevState: IForm) => Partial<IForm>)) => void;
  readonly isChild: boolean;
}

const CompanyForm = ({ form, setFormState, isChild }: Props) => {
  const { t } = useTranslation();
  const originalCurrency = useRef(form.currency?.value ?? "EUR");
  const onChangeInput = (field: keyof Organization) => (value: any) => {
    setFormState({
      [field]: { value },
    });
  };

  const RenderInput = (line: FieldElement) => {
    switch (line.type) {
      case "TextInput":
        return (
          <>
            <TextInput
              className={styles.input}
              value={form?.[line.field]?.value ?? ""}
              placeholder={line.placeholder ?? line.name}
              onChange={onChangeInput(line.field)}
              errored={!!form?.[line.field]?.error}
              controlled
            />
            {!!form?.[line.field]?.errorMessage && (
              <AssistiveArea mode="error" text={form?.[line.field]?.errorMessage} />
            )}
          </>
        );

      case "TextArea":
        return (
          <TextArea
            className={styles.input}
            value={form?.[line.field]?.value ?? ""}
            placeholder={line.placeholder ?? line.name}
            onChange={onChangeInput(line.field)}
            errored={!!form?.[line.field]?.error}
          />
        );

      case "Radio":
        return (
          <div className={styles.radioBox}>
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.[line.field]?.value === true}
              label="Oui"
              value={true}
              onClick={(v) =>
                isNil(v) ? onChangeInput(line.field)(undefined) : onChangeInput(line.field)(v)
              }
              canUncheck
            />
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.[line.field]?.value === false}
              label="Non"
              value={false}
              onClick={(v) =>
                isNil(v) ? onChangeInput(line.field)(undefined) : onChangeInput(line.field)(v)
              }
              canUncheck
            />
          </div>
        );

      case "TextDropdown":
        const index = line.options.findIndex(
          (e) => e.value.toUpperCase() === form?.[line.field]?.value?.toUpperCase()
        );

        let warningText: string = null;
        if (line.field === "currency") {
          const isDisabled = !User.isSkillupDeveloper() && !User.isSkillupAdmin();

          if (isDisabled) {
            return (
              <DSTooltip
                label={t("currency.disabled.tooltip", {
                  defaultValue:
                    "Pour changer la devise veuillez vous rapprocher de votre interlocuteur opérationnel Skillup",
                })}
              >
                {t(`${line.field}.${form?.[line.field]?.value}`)}
              </DSTooltip>
            );
          }

          if (originalCurrency.current !== form.currency?.value) {
            warningText = t("currency.updated.warning", {
              defaultValue:
                "Le changement de devise ne modifiera pas les montants actuels, aucune conversion ne sera appliquée",
            });
          }
        }

        return (
          <>
            <TextDropdown
              className={styles.input}
              options={line.options}
              selectedIndex={index !== -1 ? index : undefined}
              onClear={() => onChangeInput(line.field)(undefined)}
              onSelect={(item) => onChangeInput(line.field)(item.value)}
              placeholder={line.placeholder ?? line.name}
            />
            {warningText !== null && <AssistiveArea mode="warning" text={warningText} />}
          </>
        );

      case "UserSearch":
        return (
          <FindUser
            className={styles.findUser}
            onEdit={() => onChangeInput(line.field)(undefined)}
            showRightIcon
            user={form?.[line.field]?.value}
            autoFocus={false}
            onSelect={onChangeInput(line.field)}
            underlineColor={form?.[line.field]?.error ? Colors.error : undefined}
            t={t}
          />
        );
    }
  };
  return (
    <>
      {matchingFields.map((line) => {
        if (isChild && line.field === "currency") return null;

        return (
          <div className={styles.inputLine} key={line.name}>
            <CreateLabel className={styles.label} label={line.name} required={line.required} />
            {RenderInput(line)}
          </div>
        );
      })}
    </>
  );
};

export default CompanyForm;
