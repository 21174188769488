import { useToggle, useSetState } from "react-use";
import React, { useState, Suspense, useEffect } from "react";
import { useParams, useHistory, generatePath } from "react-router-dom";

import { useTypedFetch } from "hooks";
import { useRecoilCallback } from "recoil";

import { DSTextArea, DSFormGroupTextInput } from "@skillup/ui";

import Acta from "utils/Acta";
import DSLayout from "components/DSLayout";
import type { JobsRoutes } from "types/api";
import useTranslation from "hooks/useTranslation";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import Details from "../components/Details";
import { useJobState } from "../../state/jobs";
import { JobDetails } from "../components/Details/Details";
import SkillSearch, { SkillWithExpectation } from "../components/SkillSearch";

import styles from "./EditJob.module.scss";

type GetJobRoute = JobsRoutes["Jobs.GetOne"];
const getJobMethod: GetJobRoute["method"] = "GET";

const EditJob = () => {
  const history = useHistory();

  const [details, setJobDetails] = useSetState<JobDetails>();
  const [description, setDescription] = useState<string>();
  const [relatedSkills, setRelatedSkills] = useState<SkillWithExpectation[]>([]);

  const { jobUuid } = useParams<{ jobUuid: string }>();

  const { data: jobDataResponse } = useTypedFetch<GetJobRoute>({
    method: getJobMethod,
    params: { jobUuid },
    path: "/competences/jobs/{jobUuid}",
  });

  const { editJob } = useJobState(jobUuid);

  const [loading, toggleLoading] = useToggle(false);

  const handleSubmit = useRecoilCallback(() => async () => {
    toggleLoading(true);
    try {
      await editJob({
        data: {
          description,
          label: details?.title,
        },
        fields: details?.fieldsWithData.map((f) => ({
          uuid: f.uuid,
          value: f.value,
        })),
        requiredSkills: relatedSkills.map((skill) => ({
          uuid: skill.skill.uuid,
          expectedLevel: skill.expectedLevel,
        })),
      });

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Fiche de poste modifiée avec succès.",
      });

      history.push("/responsable/competences/fiches-de-poste");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Echec lors de l'ajout de la fiche de poste.",
      });
    }
    toggleLoading(false);
  });

  useEffect(() => {
    setDescription(jobDataResponse?.job?.description);
  }, [jobDataResponse]);

  const job = jobDataResponse?.job;

  const { t } = useTranslation();

  return (
    <DSLayout
      title={jobDataResponse?.job?.label}
      parent={{
        title: t("jobs.title.jobSheets", {
          defaultValue: "Fiches de poste",
        }),
        titleUrl: generatePath("/responsable/referentiels/fiches-de-poste"),
      }}
      layouts={
        [
          {
            primaryButton: (
              <DSNewHeaderButton
                loading={loading}
                label="Enregistrer les modifications"
                onClick={handleSubmit}
              />
            ),
          },
        ] as const
      }
    >
      <div className={styles.editJob}>
        <div className={styles.content}>
          {jobDataResponse && (
            <Suspense fallback={<div />}>
              <Details
                key={job?.uuid}
                onChange={setJobDetails}
                initialData={{
                  fieldsMap: jobDataResponse?.job?.fields,
                  title: jobDataResponse?.job?.label,
                }}
              />
            </Suspense>
          )}
          <div className={styles.section}>
            <DSFormGroupTextInput label="Description">
              <DSTextArea
                value={description}
                id="description-rich-editor"
                placeholder="Éditez ici la fiche de poste ou copier-collez depuis un fichier"
                onChange={setDescription}
              />
            </DSFormGroupTextInput>
          </div>
          {jobDataResponse && (
            <div className={styles.section}>
              <Suspense fallback={<div>Chargement...</div>}>
                <SkillSearch
                  label="Compétences liées"
                  initialData={jobDataResponse?.job?.requiredSkills}
                  onSkillChange={(newSkills) => setRelatedSkills(newSkills)}
                />
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </DSLayout>
  );
};

export default EditJob;
