import isEmpty from "lodash/isEmpty";

import { MdSearch as Search } from "react-icons/md";
import { DSModal, DSTextInput, HorizontalDivider, Flex } from "@skillup/ui";
import { DateTime } from "@skillup/shared-utils";

import styles from "./ObsoleteTargets.module.scss";
import { useTargets } from "../../../hooks";
import { UserTarget } from "@skillup/espace-rh-bridge";
import { TranslationType } from "hooks/useTranslation";

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  userUuid: string;
  t: TranslationType;
}

export const ObsoleteTargetsModal = ({ isOpen, closeModal, userUuid, t }: IProps) => {
  const { obsoleteTargets, searchOnObsolete, filteredObsoleteTargets } = useTargets({
    userUuid,
    queryOptions: {
      order: "ASC",
      filter: JSON.stringify({
        isObsolete: true,
      }),
    },
  });

  return (
    <DSModal isOpen={isOpen} className={styles.ObsoleteTargetsModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("supervisor.view.targets.archived.header", {
            defaultValue: "{{ count }} objectif obsolète",
            defaultValue_other: "{{ count }} objectifs obsolètes",
            count: obsoleteTargets.length,
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.list}>
          <div className={styles.search}>
            <DSTextInput
              placeholder={t("supervisor.view.targets.archived.search.placeholder", {
                defaultValue: "Recherche parmi les objectifs obsolètes",
              })}
              name="filter-targets"
              actionButton={<Search />}
              onChange={async (e) => await searchOnObsolete(e)}
            />
          </div>
          <ObsoleteTargetsList
            targets={obsoleteTargets}
            filteredTargets={filteredObsoleteTargets}
            t={t}
          >
            {filteredObsoleteTargets.map((target, index) => (
              <ObsoleteTargetItem
                isLastItem={index === filteredObsoleteTargets.length - 1}
                key={target.uuid}
                target={target}
                t={t}
              />
            ))}
          </ObsoleteTargetsList>
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};

const ObsoleteTargetsList = ({
  targets,
  filteredTargets,
  children,
  t,
}: {
  targets: UserTarget[];
  filteredTargets: UserTarget[];
  children: React.ReactNode;
  t: TranslationType;
}) => {
  if (isEmpty(targets)) {
    return (
      <section className={styles["list__item--empty"]}>
        {t("supervisor.view.targets.archived.empty", {
          defaultValue: "Aucun objectif obsolète",
        })}
      </section>
    );
  }
  if (isEmpty(filteredTargets)) {
    return (
      <section className={styles["list__item--empty"]}>
        {t("supervisor.view.targets.archived.search.empty", {
          defaultValue: "Aucun objectif obsolète ne correspond à votre recherche.",
        })}
      </section>
    );
  }
  return <>{children}</>;
};

const ObsoleteTargetItem = ({
  target,
  isLastItem,
  t,
}: {
  target: UserTarget;
  isLastItem: boolean;
  t: TranslationType;
}) => {
  const obsoletors = target.activities
    ?.map((activity) => activity.logs.find((log) => log.type === "obsolete"))
    .filter(Boolean);
  return (
    <>
      <Flex row className={styles.list__item}>
        <div className={styles.list__item__content}>
          <div className={styles.list__item__content__title}>{target.name}</div>
          {obsoletors.length ? (
            <div className={styles.list__item__content__subtitle}>
              {t("supervisor.view.targets.archived.item", {
                defaultValue: "Objectif marqué comme obsolète le {{ date }} par {{ author }}",
                date: DateTime.fromISO(obsoletors[0].createdAt).toFormat("dd/MM/yyyy"),
                author: obsoletors[0].author ? obsoletors[0].author.fullName : "",
              })}
            </div>
          ) : null}
        </div>
      </Flex>
      {isLastItem ? null : <HorizontalDivider top="none" bottom="none" />}
    </>
  );
};
