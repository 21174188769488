import React from "react";

import cx from "classnames";

import styles from "./UserPopover.module.scss";
import { Avatar } from "components/Avatar/Avatar";
import { DSPopover as Popover } from "components/Popover";
import { type PopoverProps } from "components/Popover/Popover";
import { type Themes } from "components/commonProps";
import { Button } from "components/Button/Button";
import { type Link } from "react-router-dom";

export interface Props {
  readonly backDropclassName?: string;
  readonly className?: string;
  readonly contentClassName?: string;
  readonly user: {
    readonly uuid?: string;
    readonly initials: string;
    readonly fullName: string;
  };
  readonly properties?: string[];
  readonly position?: PopoverProps["position"];
  readonly profileBtn?: {
    readonly label: string;
    readonly onClick?: () => void;
    readonly className?: string;
    readonly redirectOnClick?: {
      readonly to: string;
      readonly linkComponent: typeof Link;
    };
  };
  readonly theme: Themes;
  readonly children: React.ReactElement;
}

function UserPopover({
  className,
  contentClassName,
  user,
  properties,
  profileBtn,
  position,
  theme,
  children,
  backDropclassName,
}: Props) {
  return (
    <Popover
      className={cx(styles.UserPopover, className)}
      position={position}
      triggerAction="hover"
      triggerDelay={500}
      backDropClassName={backDropclassName}
      content={() => (
        <div className={cx(styles.UserPopoverContent, contentClassName)}>
          <div className={styles.body}>
            <Avatar
              theme={theme}
              className={styles.avatar}
              initials={user.initials}
              size="L"
              randomColorSeedString={user.uuid}
            />
            <div className={cx(styles.props, styles[theme])}>
              <h2>{user.fullName}</h2>
              <div className={styles.subProps}>
                {properties && properties?.length >= 1 ? (
                  <>
                    {properties.map((property, i) => (
                      <div key={i} className={styles.prop}>
                        {property}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={styles.prop}>&mdash;</div>
                )}
              </div>
            </div>
          </div>
          {profileBtn && (
            <div className={styles.footer}>
              {profileBtn.redirectOnClick ? (
                <profileBtn.redirectOnClick.linkComponent to={profileBtn.redirectOnClick.to}>
                  <Button
                    theme={theme}
                    emphasis="Low"
                    buttonSize="S"
                    className={styles.showProfile}
                    onClick={profileBtn.onClick}
                    label={profileBtn.label}
                    href={profileBtn.redirectOnClick.to}
                  />
                </profileBtn.redirectOnClick.linkComponent>
              ) : (
                <Button
                  theme={theme}
                  emphasis="Low"
                  buttonSize="S"
                  className={styles.showProfile}
                  onClick={profileBtn.onClick}
                  label={profileBtn.label}
                />
              )}
            </div>
          )}
        </div>
      )}
    >
      <div className={styles.toggler}>{children}</div>
    </Popover>
  );
}

export { UserPopover };
