import React, {
  type KeyboardEvent,
  type ReactElement,
  type ReactNode,
  useContext,
  useMemo,
  useEffect,
  useState,
  type CSSProperties,
} from "react";
import cx from "classnames";

import styles from "./Dropdown.module.scss";
import { MoreVert } from "components/MaterialIcons";
import { Button as DSButton } from "components/Button";
import { type ButtonProps } from "components/Button/Button";
import { DropdownContext, type PopoverActions } from "./DropdownContext";
import { DropdownItem } from "./DropdownItem";
import { DropdownAsyncItem } from "./DropdownAsyncItem";
import { type Themes } from "../commonProps";
import { DSPopover as Popover } from "components/Popover";

export interface DropdownProps {
  readonly button?: ReactNode;
  readonly position?: "auto" | "down-left" | "down-right" | "up-left" | "up-right";
  readonly placementShift?: number | [number, number]; // placementShift in pixel from the popover anchor[x, y]
  readonly disabled?: boolean;
  readonly opened?: boolean;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly className?: string;
  readonly overflow?: CSSProperties["overflow"];
  readonly buttonSize?: ButtonProps["buttonSize"];
  readonly buttonClassName?: string;
  readonly children: ReactNode | ReactNode[];
  readonly onToggleDropdown?: (opened: boolean) => void;
}

const PureDropdown = (props: DropdownProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [popoverActions, setPopoverActions] = useState<PopoverActions>();

  const children = useMemo(() => {
    let itemIndex = 0;
    const results: ReactElement[] = [];
    for (const child of React.Children.toArray(props.children)) {
      // @ts-expect-error child.props is defined
      if (isKindOfDropdownItem(child) && !child.props.disabled) {
        results.push(React.cloneElement(child as ReactElement, { index: itemIndex++ }));
      } else {
        results.push(child as ReactElement);
      }
    }
    return results;
  }, [props.children]);

  return (
    <DropdownContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        isLoading,
        setIsLoading,
        popoverActions,
        setPopoverActions,
        theme: props.theme,
        darkMode: !!props.darkMode,
      }}
    >
      <DropdownWrapper {...props}>{children}</DropdownWrapper>
    </DropdownContext.Provider>
  );
};

function DropdownWrapper({
  button,
  disabled,
  position = "auto",
  overflow,
  placementShift = 8,
  className,
  buttonClassName,
  buttonSize = "M",
  children,
  onToggleDropdown,
  opened,
}: DropdownProps & { children: ReactNode[] }) {
  const [showDropdown, toggleDropdown] = useState(!!opened);
  const {
    activeIndex,
    setActiveIndex,
    setIsLoading,
    popoverActions,
    setPopoverActions,
    theme,
    darkMode,
  } = useContext(DropdownContext);

  useEffect(() => {
    if (opened && popoverActions) popoverActions.open();
    return () => {
      if (popoverActions) popoverActions.close();
    };
  }, [opened, popoverActions]);

  const items = useMemo(() => {
    return React.Children.toArray(children).filter((c) => isKindOfDropdownItem(c));
  }, [children]);

  function closeDropDown() {
    popoverActions?.close();
  }
  function handleToggle(opened: boolean) {
    toggleDropdown(opened);
    onToggleDropdown?.(opened);
  }
  function handleOpen() {
    handleToggle(true);
  }
  function handleClose() {
    handleToggle(false);
  }
  function handlePopoverActions(actions: PopoverActions) {
    setPopoverActions(actions);
  }

  async function selectCurrentItem() {
    // @ts-expect-error props is defined
    const child = children.find((c) => c.props.index === activeIndex);

    // @ts-expect-error type is defined
    if (child && child.type.className === "DropdownAsyncItem") {
      setIsLoading(true);
      // @ts-expect-error child.props is defined
      await child?.props?.asyncAction?.();
      setIsLoading(false);
      // @ts-expect-error type is defined
    } else if (child && child.type.className === "DropdownItem") {
      // @ts-expect-error child.props is defined
      child?.props?.onClick?.();
    }

    closeDropDown();
  }

  const handleKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (showDropdown) {
      let newIndex = activeIndex;
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (activeIndex < items.length - 1) {
          newIndex = activeIndex + 1;
        } else {
          newIndex = 0;
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (activeIndex <= 0) {
          newIndex = items.length - 1;
        } else {
          newIndex = activeIndex - 1;
        }
      } else if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        selectCurrentItem();
      } else if (e.key === "Tab") {
        closeDropDown();
      }
      setActiveIndex(newIndex);
    } else {
      if (e.key === "Enter") {
        e.preventDefault();
        closeDropDown();
      }
    }
  };

  const ToggleButton = React.cloneElement(
    (button || (
      <DropdownButton
        iconOnly
        icon={<MoreVert />}
        buttonSize={buttonSize}
        className={buttonClassName}
        theme={theme}
        darkMode={darkMode}
        type="button"
      />
    )) as ReactElement,
    {
      disabled,
    }
  );

  return (
    <Popover
      className={cx(styles.Dropdown, styles[theme], className, {
        [styles.disabled]: disabled,
        [styles.darkMode]: darkMode,
      })}
      overflow={overflow}
      position={position}
      placementShift={placementShift}
      content={() => children}
      contentClassName={cx(styles.DropdownMenu, styles[theme])}
      onKeyDown={handleKey}
      onOpen={handleOpen}
      onClose={handleClose}
      actions={handlePopoverActions}
      data-testid="dropdown-container"
    >
      {ToggleButton}
    </Popover>
  );
}

export function DropdownButton(props: ButtonProps) {
  return (
    <DSButton
      {...props}
      className={cx(styles.DropdownButton, props.className)}
      onClick={props.onClick}
    />
  );
}

export interface DropdownTitleProps {
  readonly label: string;
}

export function DropdownTitle({ label }: DropdownTitleProps) {
  return <label className={cx(styles.DropdownTitle)}>{label}</label>;
}

export function DropdownDivider() {
  return <hr className={styles.DropdownDivider} />;
}

export const Dropdown = Object.assign(PureDropdown, {
  Button: DropdownButton,
  Title: DropdownTitle,
  Item: DropdownItem,
  AsyncItem: DropdownAsyncItem,
  Divider: DropdownDivider,
});

const isKindOfDropdownItem = (
  child: React.ReactChild | React.ReactFragment | React.ReactPortal
) => {
  // @ts-expect-error child.type is defined
  return child.type.className === "DropdownItem" || child.type.className === "DropdownAsyncItem";
};
