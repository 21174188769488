import styled, { css } from "styled-components";

import { Popper, type PopperProps } from "@mui/material";

import { DSColor, EspaceFont, SkuiSpacing } from "components/DesignSystemContext";
import { Themes } from "components/commonProps";

const getEspaceFont = (theme: Themes, selector?: keyof typeof EspaceFont.rh) => {
  if (selector) {
    return css(EspaceFont[theme === Themes.ESPACE_RH ? "rh" : "collab"][selector]);
  }
  return css(EspaceFont[theme === Themes.ESPACE_RH ? "rh" : "collab"]);
};

const StyledPopper = styled(Popper)<PopperProps & { theme: Themes }>`
  z-index: 300;
  .MuiPickersPopper-root {
    z-index: 301;
  }

  .MuiPaper-root {
    border-radius: ${SkuiSpacing.xs};
    ${({ theme }) => getEspaceFont(theme)};

    .MuiButtonBase-root,
    .MuiTypography-root {
      color: ${DSColor["plainText-onLight-default"]};
      ${({ theme }) => getEspaceFont(theme, "body1Regular")};
    }

    .MuiPickersCalendarHeader-label,
    .MuiPickersArrowSwitcher-root > span {
      text-transform: capitalize;
      color: ${DSColor["plainText-onLight-default"]};
      ${({ theme }) => getEspaceFont(theme, "h6")};
    }

    .MuiButtonBase-root.Mui-selected {
      background-color: ${DSColor["Action-onLight-default"]};
      color: ${DSColor["plainText-onDark-default"]};
    }

    .MuiPickersDay-dayOutsideMonth {
      color: ${DSColor["placeholder-onLight"]};
    }
  }
`;

export { StyledPopper };
