import { useHistory } from "react-router-dom";
import { useEffect, useCallback } from "react";

import { Loader } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { CampaignCreationState } from "types/peopleReview";
import { useCampaigns } from "services/peopleReview/useCampaigns";

interface ValidateProps {
  payload: Pick<
    CampaignCreationState,
    | "observers"
    | "titleForHR"
    | "templateID"
    | "hasManagers"
    | "coordinators"
    | "titleForManagers"
    | "managersHelpText"
    | "collaboratorManagerPairs"
    | "managersPreparationEndDate"
    | "prefilledEvaluationsByScaleLabelAndUserID"
  >;
}

export function Validate({ payload }: ValidateProps) {
  const history = useHistory();
  const { create } = useCampaigns();
  const { t } = useTranslation();

  const onSubmit = useCallback(async () => {
    create({
      coordinators: payload.coordinators.map((c) => c.id),
      hasManagers: payload.hasManagers,
      managersHelpText: payload.managersHelpText,
      managersPreparationEndDate: payload.managersPreparationEndDate,
      observers: payload.observers.map((o) => o.id),
      prefilledEvaluationsByScaleLabelAndUserID: payload.prefilledEvaluationsByScaleLabelAndUserID,
      reviewedEmployees: payload.collaboratorManagerPairs.map(({ collaborator, manager }) => ({
        employeeID: collaborator.id,
        managerID: manager?.id,
      })),
      templateID: payload.templateID,
      titleForHR: payload.titleForHR,
      titleForManagers: payload.titleForManagers,
    }).then((id) => history.push(`/responsable/people-review/campaigns/${id}`));
  }, [create, history, payload]);

  useEffect(() => {
    onSubmit();
  }, [create, history, onSubmit]);

  return (
    <Flex gap="l" width="100%" alignItems="center" flexDirection="column" justifyContent="center">
      <Text>
        {t("peopleReview.campaignCreationSteps.creating.loading-message", {
          defaultValue: "Création de la campagne en cours...",
        })}
      </Text>
      <Loader />
    </Flex>
  );
}
