import { useHistory } from "react-router-dom";

import { DSModal, DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import { type Template } from "services/interviews";
import useTranslation from "hooks/useTranslation";

import styles from "./EditTemplateModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
  unarchivedRelatedCampaignsNumber: number;
}

const EditTemplateModal = ({
  template,
  isOpen,
  closeModal,
  unarchivedRelatedCampaignsNumber,
}: IProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const alertContent = (
    <p>
      {t("interviews.editTemplateModal.alert", {
        defaultValue: "Cette trame a été utilisée pour lancer {{count}} campagne.",
        defaultValue_other: "Cette trame a été utilisée pour lancer {{count}} campagnes.",
        count: unarchivedRelatedCampaignsNumber,
      })}
      <br />
      <strong>
        {t("interviews.editTemplateModal.alertText", {
          defaultValue: "Cette campagne ne sera pas modifiée.",
          defaultValue_other: "Ces campagnes ne seront pas modifiées.",
          count: unarchivedRelatedCampaignsNumber,
        })}
      </strong>
    </p>
  );

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.editTemplateModal.title", {
            defaultValue: "Modifier la trame",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.contentContainer}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {alertContent}
          </DSAlert>
          <p
            dangerouslySetInnerHTML={{
              __html: t("interviews.editTemplateModal.text", {
                defaultValue: `Les modifications que vous apporterez <strong>ne seront répercutées que sur les futures campagnes</strong> utilisant cette trame.`,
              }),
            }}
          />
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.editTemplateModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          label={t("interviews.editTemplateModal.continue", {
            defaultValue: "Continuer",
          })}
          onClick={() => push(`/responsable/template/${template.uuid}`)}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export { EditTemplateModal };
