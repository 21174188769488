import { useState } from "react";

import { DSModal, HorizontalDivider } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { toUpperCaseFirstChar } from "helpers/functions";
import { ProcessedSkill, SkillWithExpectedLevel } from "types/skills";

import SkillLevelContent from "./SkillLevelContent";
import { Modal, Skillname } from "../JobForm.styled";

interface Props {
  onBack: () => void;
  onClose: () => void;
  skills: Array<ProcessedSkill>;
  onConfirm: (e: Array<SkillWithExpectedLevel>) => void;
}

const AddExpectedLevelsModal = ({ onBack, onClose, onConfirm, skills }: Props) => {
  const { t } = useTranslation();
  const [skillsWithExpectedLevel, setSkillsWithExpectedLevel] = useState<
    Array<SkillWithExpectedLevel>
  >(
    skills.map((skill) => ({
      evaluationScale: skill.evaluationScale,
      levelNumber: undefined,
      levelUuid: undefined,
      skillName: skill.name,
      skillUuid: skill.uuid,
      totalLevel: skill.evaluationScale.levels.length,
    }))
  );

  const onChange = (skillUuid: string) => {
    return (levelUuid?: string) => {
      setSkillsWithExpectedLevel(
        skillsWithExpectedLevel.map((e) => {
          if (e.skillUuid === skillUuid) {
            return {
              ...e,
              levelNumber: e.evaluationScale.levels.find((level) => level.uuid === levelUuid)
                ?.level,
              levelUuid,
            };
          }
          return e;
        })
      );
    };
  };

  return (
    <>
      <Modal isOpen>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("jobs.addSkillsModal.titleLevel", {
              count: skills.length,
              defaultValue: "Définir le niveau de maîtrise attendu pour {{count}} compétences",
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          {skillsWithExpectedLevel.map((skill, index) => {
            return (
              <div key={index}>
                <Skillname
                  marginBottom="xs"
                  espaceFont="body2Bold"
                  color="plainText-onLight-default"
                >
                  {toUpperCaseFirstChar(skill.skillName)}
                </Skillname>
                <SkillLevelContent
                  skillUuid={skill.skillUuid}
                  levelUuid={skill.levelUuid}
                  evaluationScale={skill.evaluationScale}
                  onChange={onChange(skill.skillUuid)}
                />
                {index !== skills.length - 1 && <HorizontalDivider top="s" bottom="s" />}
              </div>
            );
          })}
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={onBack}
            label={t("skills.archivedModal.buttonCancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            onClick={() => onConfirm(skillsWithExpectedLevel)}
            label={t("jobs.addSkillsModal.buttonConfirm", {
              defaultValue: "Valider la sélection",
            })}
          />
        </DSModal.Footer>
      </Modal>
    </>
  );
};

export default AddExpectedLevelsModal;
