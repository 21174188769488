import { useEffect } from "react";
import { TableOfContent } from "@skillup/ui";

import User, { getGroups } from "utils/User";

import PortalLists from "../../PortalLists";

import ContentToggles from "./ContentToggles";
import PortalContentCategories from "./PortalContentCategories";
import styles from "./PortalContent.module.scss";
import { Group } from "../../../types";
import { useSetState } from "react-use";
import LMSBanners from "./LMSBanners";
import useTranslation from "hooks/useTranslation";

export interface State {
  active?: string;
  groups?: Group[];
}

export default function PortalContent() {
  const [{ active, groups }, setState] = useSetState<State>({
    active: "",
    groups: undefined,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setState({ groups: getGroups() });
  }, [setState]);

  const userIsAdmin = User.isSkillupAdmin();

  return (
    <div className={styles.PortalContent}>
      <div className={styles.content}>
        {userIsAdmin && groups && <ContentToggles hasGroups={groups.length > 1} />}
        {groups && <PortalLists location={location} groups={groups} />}
        {userIsAdmin && <LMSBanners />}
        {userIsAdmin && <PortalContentCategories />}
      </div>
      <div className={styles.tableOfContent}>
        <TableOfContent
          activeItem={active}
          onChangeItem={(itemId: string) => setState({ active: itemId })}
          className={styles.TableOfContent}
        >
          {userIsAdmin && (
            <>
              <TableOfContent.Item
                label={t("trainings.view.portal_configuration.displays.title", {
                  defaultValue: "Informations affichées",
                })}
                id="section-content-toggles"
              />
              <TableOfContent.Item
                label={t("trainings.view.portal_configuration.recommended_trainings.title", {
                  defaultValue: "Formations recommandées",
                })}
                id="section-formations"
              />
              <TableOfContent.Item
                label={t("trainings.view.lms_banner.title", {
                  defaultValue: "Bannières LMS",
                })}
                id="section-lms-banners"
              />
              <TableOfContent.Item
                label={t("trainings.view.portal_configuration.categories.title", {
                  defaultValue: "Catégories",
                })}
                id="section-categories"
              />
            </>
          )}
        </TableOfContent>
      </div>
    </div>
  );
}
