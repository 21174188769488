import { Either } from "@skillup/monads";

import type { TrackingRoutes } from "types/api";
import { buildSafeRequest } from "utils/buildRequest";

const updateTrackingNote = async (
  trackingUuid: string,
  note: string,
  userUuid: string
): Promise<Either<TrackingRoutes.UpdateNote["errors"], TrackingRoutes.UpdateNote["response"]>> => {
  const request = buildSafeRequest<TrackingRoutes.UpdateNote>({
    method: "POST",
    path: "/tracking/{trackingUuid}/update-note",
    params: {
      trackingUuid,
    },
    payload: {
      note,
      userUuid,
    },
  });
  return request.run();
};

export default updateTrackingNote;
