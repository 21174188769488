import React, { useEffect, useCallback } from "react";
import { useSetState } from "react-use";
import { debounce, isEmpty, noop } from "lodash";
import Fuse from "fuse.js";

import { TranslationType } from "hooks/useTranslation";

import Acta from "utils/Acta";
import Icon from "components/Icon";
import useTranslation from "hooks/useTranslation";
import { cross as crossIcon, magnifyingGlass } from "uiAssets/StrokeIcons";
import getTrainings, { ITraining, IFilters, ITrainingAggregations } from "./Actions/getTrainings";

import CompareBlock from "./Components/CompareBlock";
import Sidebar from "./Components/Sidebar";
import Training from "./Components/Training";
import SearchInput from "./Components/SearchInput";
import InteractiveButton from "components/InteractiveButton";
import EmptySVGBox from "uiAssets/SVGImages/emptyBox";
import Colors from "uiAssets/Colors";

import styles from "./RequalificationModalStyles.module.scss";

export interface IProps {
  rows?: Array<string>;
  action?: (
    selectionUuid: string,
    type: "inter" | "intra",
    rows?: Array<string>,
    training?: { name: string; organization: string },
    t?: TranslationType
  ) => void;
  hideComparison?: true;
  submitLabel?: string;
  displayedTrainingTypes?: Array<"inter" | "intra" | "list">;
  renderFooter?: any;
}

const getLandingType = (displayedTrainingTypes: IProps["displayedTrainingTypes"]) => {
  if (!displayedTrainingTypes || displayedTrainingTypes.length === 0) {
    return "intra";
  }

  return displayedTrainingTypes[0];
};

const setSearchResults =
  (setState) => (trainings: Array<ITraining> | null, aggregations: ITrainingAggregations) => {
    setState({
      trainings,
      displayedTrainings: trainings,
      aggregations,
      selection: undefined,
    });
  };

interface IState {
  trainings?: ITraining[];
  displayedTrainings: ITraining[];
  aggregations?: ITrainingAggregations;
  filters: IFilters;
  selection?: ITraining;
  isLoading: boolean;
  localSearchEngine?: Fuse<ITraining>;
}

function Footer({
  action,
  hideComparison,
  selection,
  rows,
  submitLabel,
  isLoading,
  setState,
  filters,
}) {
  return (
    <div className={styles.actions}>
      {!hideComparison && <CompareBlock selection={selection} rows={rows} />}
      {
        // @ts-ignore
        hideComparison && <CompareBlock selection={selection} addOnly />
      }
      <div className={styles.spacer} />
      <InteractiveButton
        name="submit"
        label={submitLabel}
        background={selection ? Colors.gradientYellow : "#ccc"}
        color="#000"
        onClick={
          selection && !isLoading
            ? async () => {
                setState({ isLoading: true });
                await action(selection.uuid, filters.type, rows, selection);
                setState({ isLoading: false });
              }
            : noop
        }
        loading={isLoading}
      />
    </div>
  );
}

export default ({
  rows = [],
  action,
  hideComparison,
  submitLabel,
  displayedTrainingTypes = ["inter", "intra", "list"], // default, important don't remove,
  renderFooter = Footer,
}: IProps) => {
  const [
    {
      localSearchEngine,
      displayedTrainings,
      trainings,
      aggregations,
      filters,
      selection,
      isLoading,
    },
    setState,
  ] = useSetState<IState>({
    filters: {
      type: getLandingType(displayedTrainingTypes),
      withSessions: false,
    },
    isLoading: false,
    displayedTrainings: [],
  });

  const { t } = useTranslation();

  const fetchTrainings = useCallback(async () => {
    const { trainings: fetchedTrainings, trainingAggregations } = await getTrainings(filters);
    const newLocalSearchEngine = ["intra", "list"].includes(filters.type)
      ? new Fuse(fetchedTrainings || [], {
          keys: ["name", "organization", "reference"],
          threshold: 0.3,
        })
      : undefined;

    setState({
      trainings: fetchedTrainings,
      displayedTrainings: fetchedTrainings,
      aggregations: trainingAggregations,
      localSearchEngine: newLocalSearchEngine,
    });
  }, [filters, setState]);

  useEffect(() => {
    setState({
      trainings: undefined,
      displayedTrainings: [],
      aggregations: undefined,
      selection: undefined,
    });
    if (filters.type !== "inter") {
      fetchTrainings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.type, setState]);

  const onFilterChange = (event) => {
    const type = event.target.value;
    setState({ filters: { ...filters, type } });
  };

  const onLocalSearch = (query) => {
    const displayedTrainings = isEmpty(query)
      ? trainings
      : localSearchEngine?.search(query).map((r) => r.item) || [];

    setState({ displayedTrainings });
  };

  const debouncedLocalSearch = debounce(onLocalSearch, 300);

  return (
    <div className={styles.RequalificationModal}>
      <div className={styles.contentHeader}>
        <div className={styles.searchGroup}>
          <select onChange={onFilterChange}>
            {(displayedTrainingTypes || ["intra", "list", "inter"]).map(
              (type) =>
                ({
                  inter: (
                    <option key="inter" value="inter">
                      {t("trainings.entity.view.requalification_modal.inter", {
                        defaultValue: "Catalogue Skillup",
                      })}
                    </option>
                  ),
                  intra: (
                    <option key="intra" value="intra">
                      {t("trainings.entity.view.requalification_modal.intra", {
                        defaultValue: "Mes programmes",
                      })}
                    </option>
                  ),
                  list: (
                    <option key="list" value="list">
                      {t("trainings.entity.view.requalification_modal.list", {
                        defaultValue: "Formations recommandées",
                      })}
                    </option>
                  ),
                })[type]
            )}
          </select>
          {filters.type === "inter" && (
            <SearchInput onSearch={setSearchResults(setState)} filters={filters} available t={t} />
          )}
          {filters.type !== "inter" && (
            <div className={styles.localSearch}>
              <input
                placeholder={t("trainings.entity.view.requalification_modal.search", {
                  defaultValue: "Rechercher une formation...",
                })}
                onChange={(e) => debouncedLocalSearch(e.currentTarget.value)}
              />
              <button>
                <Icon strokeIcon={magnifyingGlass} width={15} stroke="#fff" />
              </button>
            </div>
          )}
        </div>
        <button onClick={() => Acta.dispatchEvent("closeModal")}>
          <Icon strokeIcon={crossIcon} width={12} />
        </button>
      </div>
      <div className={styles.content}>
        {
          // @ts-ignore
          <Sidebar
            aggregations={aggregations}
            filters={filters}
            setFilters={(items: IFilters) => setState({ filters: items })}
          />
        }
        <div className={styles.right}>
          {displayedTrainings?.map((t) => (
            <Training
              key={t.uuid}
              data={t}
              select={(item: ITraining) => setState({ selection: item })}
              isSelected={selection ? selection.uuid === t.uuid : false}
            />
          ))}
          {displayedTrainings?.length === 0 && (
            <div className={styles.emptySign}>
              <EmptySVGBox />
            </div>
          )}
        </div>
      </div>
      {renderFooter({
        action,
        hideComparison,
        selection,
        rows,
        submitLabel,
        isLoading,
        setState,
        filters,
      })}
    </div>
  );
};
