import { Route, Switch, Redirect } from "react-router-dom";
import User from "utils/User";

import { ReviewCriteria } from "./ReviewCriteria/ReviewCriteria";
import { ReviewCriteriaEdit } from "./ReviewCriteriaEdit/ReviewCriteriaEdit";
import { ReviewCriteriaList } from "./ReviewCriteriaList/ReviewCriteriaList";

export const ReviewCriteriaRouter = () => {
  return (
    <Switch>
      <Route exact component={ReviewCriteria} path={ReviewCriteriaRoutes.reviewCriteria} />
      <Route exact component={ReviewCriteria} path={ReviewCriteriaRoutes.reviewCriteriaHistory} />
      {User.isSkillupDeveloper() && (
        <Route
          exact
          component={ReviewCriteriaEdit}
          path={ReviewCriteriaRoutes.reviewCriteriaEdit}
        />
      )}
      <Route exact component={ReviewCriteriaList} path={ReviewCriteriaRoutes.reviewCriteriaList} />
      <Redirect to={ReviewCriteriaRoutes.reviewCriteriaList} />
    </Switch>
  );
};

const BaseUrl = "/responsable/review-criteria" as const;
export const ReviewCriteriaRoutes = {
  BaseUrl,
  reviewCriteria: `${BaseUrl}/:id` as const,
  reviewCriteriaEdit: `${BaseUrl}/:id/edit` as const,
  reviewCriteriaHistory: `${BaseUrl}/:id/history/:selectedVersion?` as const,
  reviewCriteriaList: `${BaseUrl}` as const,
};
