import React, { Fragment } from "react";
import cx from "classnames";

import { FormatDate, DateTime } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";

import Icon from "components/Icon";
import { IActivity } from "containers/Supervisor/routes/TrainingSchedule/utils/fetchLogs";

import Loader from "../Loader";
import styles from "./ActivityStyles.module.scss";

function translateDate(date: string, locale: string) {
  return FormatDate.ToStringLiteralFormat(
    DateTime.fromJSDate(new Date(date)),
    "cccc d MMMM yyyy",
    locale
  );
}

export interface IProps {
  activitiesByDates?: { [key: string]: Array<IActivity> };
}

export default (props: IProps) => {
  const { activitiesByDates } = props;
  const { t, i18n } = useTranslation();

  const addLineBreaks = (message) =>
    message.split("\n").map((text, index) => (
      <Fragment key={index}>
        {text}
        <br />
      </Fragment>
    ));

  return (
    <div className={styles.Activity}>
      {!activitiesByDates && <Loader width={30} />}
      {activitiesByDates && Object.keys(activitiesByDates).length === 0 && (
        <div className={styles.logs}>
          <div className={styles.noActivityPlaceholder}>
            {t("trainings.view.schedule_row.sidepanel.activity.no_activity_available", {
              defaultValue: "Aucune activité disponible pour cette demande",
            })}
          </div>
        </div>
      )}
      {activitiesByDates && Object.keys(activitiesByDates).length > 0 && (
        <div className={styles.logs}>
          {Object.keys(activitiesByDates).map((activityDate) => (
            <React.Fragment key={activityDate}>
              <li className={styles.activityDate}>
                <header>
                  <div />
                  <div>{translateDate(activityDate, i18n.language)}</div>
                </header>
                <div />
              </li>
              {activitiesByDates[activityDate].map((logItem) => (
                <li key={logItem.properties.uuid} data-event={logItem.properties.event}>
                  <header>
                    <div>
                      {typeof logItem.icon === "string" ? (
                        <Icon strokeIcon={logItem.icon} width={14} stroke={logItem.color} />
                      ) : (
                        <>{logItem.icon}</>
                      )}
                    </div>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `trainings.view.schedule_row.sidepanel.activity.logs.${logItem.properties.event}.label`,
                            {
                              defaultValue: logItem.getLabel(),
                              author: logItem.user.fullName || logItem.user.email || "",
                              manager: logItem.properties?.managerID || "",
                              oldSessionDate: new Date(
                                logItem.properties.oldSessionDate
                              ).toLocaleDateString("fr-FR"),
                              oldSessionCity: logItem.properties.oldSessionCity
                                ? `à ${logItem.properties.oldSessionCity}`
                                : "",
                              newSessionCity: logItem.properties.newSessionCity,
                              newSessionDate: new Date(
                                logItem.properties.newSessionDate
                              ).toLocaleDateString("fr-FR"),
                              originPlan: logItem.properties.originPlan,
                              origin: logItem.properties.origin,
                              originOrg: logItem.properties.originOrg,
                              target: logItem.properties.target,
                              targetOrg: logItem.properties.targetOrg,
                            }
                          ),
                        }}
                      />
                    </div>
                  </header>
                  <div className={cx({ [styles.hasMessage]: logItem.properties.message })}>
                    {logItem.properties.message && (
                      <div>
                        <p>{addLineBreaks(logItem.properties.message)}</p>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
