import { useMemo } from "react";

import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";

import type { UserTarget } from "@skillup/espace-rh-bridge";
import { Flex, Text } from "@skillup/design-system";
import { TranslationType } from "hooks/useTranslation";

export type TargetRow = ReturnType<typeof parseTableRows>;

export const useTableData = (
  targets: UserTarget[] | undefined,
  t: TranslationType
): {
  data: TargetRow[];
  columns: Array<GridColDef>;
} => {
  const columns = useMemo(() => generateColumns(t), [t]);
  const data = useMemo(() => generateTableRows(targets ?? []), [targets]);

  return { columns, data };
};

function generateTableRows(targets: Array<UserTarget>) {
  return targets.map(parseTableRows);
}

const parseTableRows = (target: UserTarget) => ({
  id: target.uuid,
  data: target,
});

function generateColumns(t: TranslationType) {
  const columns = [
    {
      field: "name",
      headerName: t("supervisor.view.targets.inProgress.column.name", {
        defaultValue: "Nom de l'objectif",
      }),
      filterable: true,
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.data.name,
      renderCell: (params: GridValueGetterParams) => {
        const imported = params.row?.data?.imported;
        return (
          <Flex flexDirection="column" paddingVertical="xs">
            <span>{params.value}</span>
            {imported && (
              <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                {t("supervisor.view.targets.inProgress.column.name.caption", {
                  defaultValue: "Objectif importé",
                })}
              </Text>
            )}
          </Flex>
        );
      },
    },
    {
      field: "targetCategoryLabel",
      headerName: t("supervisor.view.targets.inProgress.column.category", {
        defaultValue: "Catégorie",
      }),
      filterable: true,
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.data.targetCategoryLabel,
    },
    {
      field: "creationCampaign.titleForHR",
      headerName: t("supervisor.view.targets.inProgress.column.campaign", {
        defaultValue: "Campagne de fixation de l'objectif",
      }),
      filterable: true,
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.data.creationCampaign?.titleForHR,
    },
  ];
  return columns;
}
