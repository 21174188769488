import React from "react";

import { SelectWrapper, StyledSelect } from "./SelectOperator.styled";

interface SelectOperatorProps {
  onChange: (value: any) => void;
  operatorList: Array<{ label: string; value: string }>;
  value: string;
  darkMode?: boolean;
  noRadius?: boolean;
  placeholder?: string;
}

const SelectOperator: React.FC<SelectOperatorProps> = ({
  onChange,
  operatorList,
  value,
  darkMode = false,
  noRadius = false,
  placeholder,
}) => {
  return (
    <SelectWrapper darkMode={darkMode}>
      <StyledSelect
        value={value}
        onChange={onChange}
        options={operatorList}
        isSearchable={false}
        maxShownValues={1}
        placeholder={placeholder}
        noRadius={noRadius}
      />
    </SelectWrapper>
  );
};

export { SelectOperator };
