import { useCallback, useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { format, parseISO } from "@skillup/shared-utils";
import { DSButton, DSAvatar } from "@skillup/ui";
import { getInitials } from "@skillup/shared-utils";

import { useTemplates, type Template, useTemplate } from "services/interviews";
import SidePanel from "components/SidePanel/SidePanel";
import useTranslation from "hooks/useTranslation";

import { TemplateRelatedCampaigns } from "../../components";
import { State, useTemplatesActions, useTemplatesModals, ModalStates } from "../../hooks";
import { getInterviewTypeLabel } from "utils/interviews";

import { TemplateSidePanelActions } from "./TemplateSidePanelActions";
import styles from "./TemplatesSidePanel.module.scss";

type TemplatesSidePanelProps = {
  templateUuid: string;
  onClose: () => void;
  openActionModal: (state: State) => void;
};

const TemplatesSidePanel = ({
  templateUuid,
  onClose,
  openActionModal,
}: TemplatesSidePanelProps) => {
  const {
    actions: { canDo },
    getByUuid,
  } = useTemplates({ refetchOnMount: false, refetchOnWindowFocus: false });
  const { t } = useTranslation();

  const [selectedTemplate, setSelectedTemplate] = useState<Template>(getByUuid(templateUuid));

  useEffect(() => {
    setSelectedTemplate(getByUuid(templateUuid));
  }, [templateUuid, getByUuid, setSelectedTemplate]);
  const [{ state: actions }, setActionsState] = useTemplatesActions();
  const modalToDisplay = useTemplatesModals(actions, setActionsState);

  const lastContributorWithDefault = useMemo(() => {
    const { lastContributor } = selectedTemplate.formattedLogs;
    if (!lastContributor)
      return {
        date: selectedTemplate.updatedAt ?? selectedTemplate.createdAt,
        contributor: { fullName: "Skillup", uuid: "" },
        event: "create" as const,
      };
    return lastContributor;
  }, [selectedTemplate]);

  if (!selectedTemplate) return null;

  return (
    <SidePanel
      className={styles.sidePanel}
      onClose={onClose}
      title={selectedTemplate.title}
      subTitle={getInterviewTypeLabel(selectedTemplate.type, t)}
      headerBottomTools={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "20px",
            padding: "5px 0",
          }}
        >
          <MainAction template={selectedTemplate} setActionsState={setActionsState} />
          <TemplateSidePanelActions
            template={selectedTemplate}
            mainAction={canDo("update") ? "update" : "downloadPDF"}
            setActionsState={openActionModal}
            onClose={onClose}
          />
        </div>
      }
    >
      <div className={styles.container}>
        <div style={{ flex: 1 }}>
          <TemplateRelatedCampaigns campaigns={selectedTemplate.relatedCampaigns} />
        </div>
        <SidePanelFooter lastContributor={lastContributorWithDefault} />
      </div>
      {modalToDisplay}
    </SidePanel>
  );
};

type SidePanelFooterProps = {
  lastContributor: NonNullable<Template["formattedLogs"]["lastContributor"]>;
};
const SidePanelFooter = ({ lastContributor }: SidePanelFooterProps) => {
  const { t } = useTranslation();
  const lastUpdateDate = useMemo(() => {
    if (lastContributor.date) {
      return format(parseISO(lastContributor.date), "dd/MM/yyyy");
    } else {
      return "-";
    }
  }, [lastContributor]);

  return (
    <div className={styles.footerContainer}>
      <div className={styles.lastContributorAvatar}>
        <DSAvatar
          size="S"
          initials={getInitials({ fullName: lastContributor.contributor.fullName })}
          randomColorSeedString={lastContributor.contributor.uuid}
        />
      </div>
      <div>
        <p>
          {t("interviews.templates.sidePanel.lastEdit", {
            defaultValue: "Dernière modification le ",
          })}
          {`${lastUpdateDate}`}
        </p>
        <p>
          {t("interviews.templates.by", {
            defaultValue: " par ",
          })}
          {`${lastContributor.contributor.fullName}`}
        </p>
      </div>
    </div>
  );
};

function MainAction({
  template,
  setActionsState,
}: {
  template: Template;
  setActionsState: (state: State) => void;
}) {
  const { t } = useTranslation();
  const {
    actions: { canDo },
  } = useTemplates({ refetchOnMount: false, refetchOnWindowFocus: false });
  const { addToast } = useToasts();
  const [loadingState, setLoadingState] = useState<"loading" | "idle">("idle");

  const { downloadPDF } = useTemplate(template.uuid);
  const history = useHistory();

  const handleDownloadPDF = useCallback(async () => {
    try {
      setLoadingState("loading");
      await downloadPDF();
      addToast(
        t("interviews.templates.sidePanel.download.success", {
          defaultValue: "Le PDF de la trame a bien été téléchargé",
        }),
        { appearance: "success" }
      );
    } catch (err) {
      addToast(
        t("interviews.templates.sidePanel.download.error", {
          defaultValue: "Une erreur est survenue lors du téléchargement du PDF",
        }),
        { appearance: "error" }
      );
    } finally {
      setLoadingState("idle");
    }
  }, [downloadPDF, addToast, t]);

  const handleEditTemplate = useCallback(() => {
    if (template.relatedCampaigns?.length <= 0)
      return history.push(`/responsable/template/${template.uuid}`);
    return setActionsState({
      state: ModalStates.Edit,
      template,
    });
  }, [history, setActionsState, template]);

  if (canDo("update")) {
    return (
      <DSButton
        label={t("interviews.templates.action.edit", { defaultValue: "Modifier la trame" })}
        darkMode
        style={{ marginRight: "10px" }}
        onClick={handleEditTemplate}
      />
    );
  }

  if (canDo("downloadPDF")) {
    return (
      <DSButton
        label={t("interviews.templates.action.download", {
          defaultValue: "Télécharger le PDF de la trame",
        })}
        darkMode
        style={{ marginRight: "10px" }}
        onClick={handleDownloadPDF}
        loading={loadingState === "loading"}
      />
    );
  }

  return <></>;
}

export { TemplatesSidePanel };
