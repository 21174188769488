import { DSModal } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import { plural } from "utils/locale";
import { ProcessedSkill } from "types/skills";
import useTranslation from "hooks/useTranslation";

import styles from "../../ListSkills.module.scss";

interface ArchiveConfirmModalProps {
  open: boolean;
  close: () => void;
  selectedSkills: Array<ProcessedSkill>;
  confirm: (uuids: Array<string>) => void;
}

const ArchiveConfirmModal = ({
  close,
  confirm,
  open,
  selectedSkills,
}: ArchiveConfirmModalProps) => {
  const { t } = useTranslation();
  const skillsJobs = selectedSkills
    .map((skill) => Object.values(skill?.jobs))
    .flatMap((job) => {
      return job.map((job) => job.name);
    });

  const uniqueSkillsJobs = [...new Set(skillsJobs)];

  return (
    <DSModal isOpen={open} portalTarget={document.body} className={styles.skillConfirmArchiveModal}>
      <DSModal.Header onClose={close}>
        <DSModal.Header.Title
          title={
            selectedSkills?.length > 1
              ? t("skills.archivedModal.buttonArchiveMultiple", {
                  count: selectedSkills?.length,
                  defaultValue: "Archiver les {{count}} compétences",
                })
              : t("skills.archivedModal.buttonArchive", {
                  defaultValue: "Archiver la compétence «{{skillTitle}}»",
                  skillTitle: selectedSkills[0]?.name,
                })
          }
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex height="100%" paddingVertical="s" flexDirection="column">
          {uniqueSkillsJobs.length > 0 && (
            <>
              <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
                {selectedSkills?.length > 1
                  ? t("skills.archivedModal.numberOfJobsLinkedMultiple", {
                      defaultValue: "Ces compétences sont liées à {{jobsNumber}} {{label}}:",
                      jobsNumber: uniqueSkillsJobs.length,
                      label: plural(uniqueSkillsJobs.length, "fiche de poste", {
                        pluralText: "fiches de postes",
                      }),
                    })
                  : t("skills.archivedModal.numberOfJobsLinked", {
                      defaultValue: "Cette compétence est liée à {{jobsNumber}} {{label}}",
                      jobsNumber: uniqueSkillsJobs.length,
                      label: plural(uniqueSkillsJobs.length, "fiche de poste", {
                        pluralText: "fiches de postes",
                      }),
                    })}
              </Text>
              <Text
                paddingLeft="s"
                fontSize="1rem"
                marginBottom="s"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                <ul>
                  {uniqueSkillsJobs.map((job) => (
                    <li key={job}>{job}</li>
                  ))}
                </ul>
              </Text>
              <Text
                fontSize="1rem"
                marginBottom="s"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {selectedSkills?.length > 1
                  ? t("skills.archivedModal.warnlinkToJobMultiple", {
                      defaultValue:
                        "L’archivage de ces compétences entraînera la rupture du lien d'association avec ces fiches de postes.",
                    })
                  : t("skills.archivedModal.warnlinkJobCollab", {
                      defaultValue:
                        "L’archivage de cette compétence entraînera la rupture du lien d'association avec ces fiches de postes.",
                    })}
              </Text>
            </>
          )}
          <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
            {selectedSkills?.length > 1
              ? t("skills.archivedModal.finalWarnArchiveMultiple", {
                  defaultValue: "Vous ne pourrez plus les lier à des fiches de poste",
                })
              : t("skills.archivedModal.finalWarnArchive", {
                  defaultValue: "Vous ne pourrez plus la lier à des fiches de poste",
                })}
          </Text>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={close}
          label={t("skills.archivedModal.buttonCancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={() => confirm(selectedSkills.map((skill) => skill.uuid))}
          label={t("skills.archivedModal.buttonConfirm", {
            defaultValue: "Confirmer l'archivage",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default ArchiveConfirmModal;
