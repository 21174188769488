import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DSAlert, DSAlertDisplay, DSAlertType, DSCheckbox, Flex } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import { Divided } from "../ChildWrappers";
import { Permissions } from "../../Permissions/Permissions";

import { type Child, selectTemplateSections } from "../../../../reducer";

import styles from "./GlobalTargetsCompletionChild.module.scss";

type GlobalTargetsCompletion = Extract<Child, { kind: "globalTargetsCompletion" }>;

type Props = {
  child: GlobalTargetsCompletion;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  updateChild(data: Partial<GlobalTargetsCompletion>): void;
  t: TranslationType;
};

export function GlobalTargetsCompletionChild({ child, indexes, updateChild, t }: Props) {
  const sections = useSelector(selectTemplateSections);
  const warningNoCurrentTargetsChild = useMemo(() => {
    return !sections.some((section) =>
      section.pages?.some((page) =>
        page.children?.some((child) => child.kind === "targets" && child.type === "current")
      )
    );
  }, [sections]);
  return (
    <Divided>
      <Flex column className={styles.globalTargetsCompletionChild}>
        <p
          dangerouslySetInnerHTML={{
            __html: t("interviews.templateBuilder.globalTargetsCompletion.title", {
              defaultValue:
                "Le tableau de taux d’atteinte global agrège l'ensemble des objectifs <strong>évalués dans l’entretien.</strong>",
            }),
          }}
        />
        {warningNoCurrentTargetsChild && (
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE} closeButton={false}>
            <p>
              {t("interviews.templateBuilder.globalTargetsCompletion.alert", {
                defaultValue:
                  "Cette trame n'inclut pas de bloc d'évaluation d'objectifs, le tableau de taux d'atteinte global ne pourra donc pas être affiché.",
              })}
            </p>
          </DSAlert>
        )}
        <p>
          {t("interviews.templateBuilder.globalTargetsCompletion.description.title", {
            defaultValue:
              "Afin de permettre le calcul du taux d’atteinte global, le responsable de l’entretien sera invité à :",
          })}
          <ul
            dangerouslySetInnerHTML={{
              __html: t("interviews.templateBuilder.globalTargetsCompletion.description", {
                defaultValue:
                  "<li>Vérifier la pondération des objectifs et la modifier si nécessaire</li><li>Saisir un équivalent en pourcentage pour l’évaluation des objectifs qualitatifs.</li>",
              }),
            }}
          />
        </p>
      </Flex>
      <Flex column>
        <Flex column className={styles.settings}>
          <DSCheckbox
            className={styles.settings__actionChoice}
            label={t("interviews.templateBuilder.globalTargetsCompletion.required", {
              defaultValue: "Remplissage obligatoire",
            })}
            checked={child.required}
            onChange={(isRequired) => updateChild({ required: isRequired })}
          />
          <DSCheckbox
            className={styles.settings__actionChoice}
            label={t("interviews.templateBuilder.globalTargetsCompletion.forcePercentWeight", {
              defaultValue: "Bloquer la pondération globale à 100",
            })}
            checked={child.forcePercentWeight}
            onChange={(forcePercentWeight) => updateChild({ forcePercentWeight })}
          />
          <p>
            {t("interviews.templateBuilder.globalTargetsCompletion.assistiveText", {
              defaultValue:
                "Seul le responsable de l'entretien peut compléter le tableau de taux d'atteinte global.",
            })}
          </p>
        </Flex>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          order={["reply", "comment"]}
          kind="globalTargetsCompletion"
          t={t}
        />
      </Flex>
    </Divided>
  );
}
