import { type TranslationType } from "hooks/useTranslation";

export const eventOffsetUnitKeyToValue = (
  key: string,
  count: number,
  t: TranslationType
): string => {
  switch (key) {
    case "days":
      return t("interviews.campaign.event.offsetUnit.days", {
        defaultValue: "jour",
        defaultValue_other: "jours",
        count: count,
      });
    case "weeks":
      return t("interviews.campaign.event.offsetUnit.weeks", {
        defaultValue: "semaine",
        defaultValue_other: "semaines",
        count: count,
      });
    case "months":
      return t("interviews.campaign.event.offsetUnit.months", {
        defaultValue: "mois",
        defaultValue_other: "mois",
        count: count,
      });
    case "years":
      return t("interviews.campaign.event.offsetUnit.years", {
        defaultValue: "année",
        defaultValue_other: "années",
        count: count,
      });
    default:
      return "";
  }
};

export const eventOffsetDirectionKeyToValue = (key: string, t: TranslationType): string => {
  switch (key) {
    case "after":
      return t("interviews.campaign.event.offsetDirection.after", { defaultValue: "après" });
    default:
      return "";
  }
};
