import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";

import { Flex, Text } from "@skillup/design-system";
import { DSModal, FileDrop, useModal } from "@skillup/ui";

import { trpc } from "utils/trpc";
import useTranslation from "hooks/useTranslation";
import { toBase64, cleanBase64 } from "helpers/functions";

import styles from "./UploadSkillEntityModal.module.scss";

interface UploadSkillEntityModalProps {
  open: boolean;
  close: () => void;
  entityName: string;
  mutationRoute: string;
  mutationEntity: string;
}

const UploadSkillEntityModal = ({
  close,
  entityName,
  mutationEntity,
  mutationRoute,
  open,
}: UploadSkillEntityModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast } = useToasts();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState<string[]>([]);
  const infoModal = useModal();

  const onDrop = async (files: File[]) => {
    if (isLoading) return;
    setUploadedFile(files[0]);
    setIsLoading(false);
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize: 52428800,
    multiple: false,
    onDrop,
  });

  const importSkillsMutation = trpc[mutationEntity][mutationRoute].useMutation({
    onError: (e) => {
      setIsLoading(false);
      setError(e.message.split("\n"));
      infoModal.show();
      addToast(
        t("skills.importModal.errors", { defaultValue: `L'import des ${entityName} a échoué` }),
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      setIsLoading(false);
      addToast(
        t("skills.importModal.importSuces", {
          defaultValue: `Les ${entityName} ont été importées avec succès`,
        }),
        {
          appearance: "success",
        }
      );
      close();
      setUploadedFile(null);
    },
  });

  const handleImportSkills = () => {
    if (!uploadedFile) return;
    toBase64(uploadedFile).then((base64) => {
      setIsLoading(true);
      importSkillsMutation.mutate({ uploadedFileContent: cleanBase64(base64 as string) });
    });
  };

  const handleCancel = () => {
    close();
    setUploadedFile(null);
  };

  const closeErrorModal = () => {
    infoModal.hide();
    setUploadedFile(null);
  };

  return (
    <>
      <DSModal
        isOpen={infoModal.isOpen}
        portalTarget={document.body}
        className={styles.skillImportErrorModal}
      >
        <DSModal.Header onClose={closeErrorModal}>
          <DSModal.Header.Title
            title={t("skills.importModal.errorModal.title", {
              defaultValue: `Erreurs survenues lors de l'import des ${entityName}`,
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <Flex width="100%" flexDirection="column">
            {error.map((e, index) => (
              <Text
                key={index}
                fontSize="1rem"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {e}
              </Text>
            ))}
          </Flex>
        </DSModal.Content>
        <DSModal.FooterInfo>
          <DSModal.Footer.CancelButton label="Fermer" onClick={closeErrorModal} />
        </DSModal.FooterInfo>
      </DSModal>
      <DSModal isOpen={open} portalTarget={document.body} className={styles.skillEntityImportModal}>
        <DSModal.Header onClose={close}>
          <DSModal.Header.Title
            title={t("skills.importModal.titles", {
              defaultValue: `Import des ${entityName}`,
              entityName,
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <FileDrop
            value={uploadedFile?.name}
            rootProps={getRootProps()}
            isDragActive={isDragActive}
            inputProps={getInputProps()}
            btnLabel={t("interview.upload.drop.button", {
              defaultValue: "Choisir un fichier",
            })}
            dropLabel={t("interview.upload.drop.message", {
              defaultValue: "ou glisser déposer un fichier ici",
            })}
          />
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={handleCancel}
            label={t("skills.archivedModal.buttonCancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            label="Confirmer"
            loading={isLoading}
            onClick={handleImportSkills}
          />
        </DSModal.Footer>
      </DSModal>
    </>
  );
};

export default UploadSkillEntityModal;
