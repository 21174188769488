import React, { type HTMLAttributes, type ReactNode, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import { usePopperTooltip } from "react-popper-tooltip";

import { type Themes } from "../commonProps";
import { useIsOverflow } from "../../hooks/useIsOverflow";

import styles from "./Tooltip.module.scss";

export interface TooltipProps extends HTMLAttributes<HTMLSpanElement> {
  readonly className?: string;
  readonly tooltipClassName?: string;
  readonly label?: string;
  readonly direction?: "top" | "bottom" | "right" | "left";
  readonly displayArrow?: boolean;
  readonly opened?: boolean; // force tooltip to be visible
  readonly withPortal?: boolean;
  readonly delayShow?: number;
  readonly delayHide?: number;
  readonly children: ReactNode | ReactNode[];
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly disabled?: boolean;
  readonly lightColor?: boolean;
  readonly enabledOnOverflow?: boolean;
  readonly overflowDirection?: "horizontal" | "vertical";
  readonly overflowClassName?: string;
  readonly cropLongLabel?: boolean;
  readonly flex?: boolean;
}

const Tooltip = ({
  label,
  children,
  theme,
  className,
  direction,
  opened,
  darkMode = false,
  disabled,
  enabledOnOverflow,
  overflowDirection,
  overflowClassName,
  delayShow,
  tooltipClassName,
  delayHide,
  withPortal,
  lightColor = false,
  cropLongLabel = true,
  displayArrow = true,
  flex = false,
  ...props
}: TooltipProps) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, triggerRef, visible } =
    usePopperTooltip({ placement: direction, visible: opened, delayShow, delayHide });

  const isOverflow = useIsOverflow(enabledOnOverflow ? triggerRef : null, overflowDirection);

  const enabled = useMemo(() => {
    if (disabled) return false;
    if (enabledOnOverflow) return isOverflow;
    return true;
  }, [isOverflow, disabled]);

  const renderTooltip = () => {
    return (
      <div
        ref={setTooltipRef}
        {...getTooltipProps({ className: "tooltip-container" })}
        className={cx(
          styles.tooltip,
          styles[theme],
          {
            [styles.darkMode]: darkMode || lightColor,
          },
          tooltipClassName
        )}
      >
        {displayArrow !== false && <div {...getArrowProps()} className={styles.tooltipArrow} />}
        <TooltipLabel label={label} cropLongLabel={cropLongLabel} />
      </div>
    );
  };

  return (
    <>
      <span
        className={cx(
          flex ? styles.tooltipTriggerFlex : styles.tooltipTrigger,
          className,
          styles[theme],
          overflowClassName && {
            [overflowClassName]: isOverflow,
          }
        )}
        aria-label={`tooltip-${label}`}
        role="presentation"
        ref={setTriggerRef}
        {...props}
      >
        {children}
      </span>
      {visible && enabled && label && !withPortal && renderTooltip()}
      {visible &&
        enabled &&
        label &&
        withPortal &&
        ReactDOM.createPortal(renderTooltip(), document.body)}
    </>
  );
};

type TooltipLabelProps = {
  label: string | undefined;
  cropLongLabel: boolean;
};

function TooltipLabel({ label, cropLongLabel }: TooltipLabelProps) {
  const labelInnerLength = useRef<HTMLDivElement | null>(null);
  const isLongLabel = useMemo(() => {
    if (labelInnerLength.current) {
      // Check if the tooltip content exceeds 2 lines
      const lineHeight = parseInt(window.getComputedStyle(labelInnerLength.current).lineHeight, 10);
      const height = labelInnerLength.current.offsetHeight;
      return height > lineHeight * 2;
    }
    return false;
  }, [labelInnerLength.current]);

  if (cropLongLabel) {
    return (
      <div
        className={cx(styles.label, { [styles.isLongLabel]: isLongLabel })}
        ref={labelInnerLength}
      >
        {label}
      </div>
    );
  }

  return (
    <div ref={labelInnerLength} className={cx(styles.label, { [styles.isLongLabel]: isLongLabel })}>
      {label}
    </div>
  );
}

export { Tooltip };
