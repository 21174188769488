import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { TranslationType } from "hooks/useTranslation";
import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Divided } from "../ChildWrappers";
import { BuilderFormInput } from "../../BuilderFormInput";

import styles from "./UserSpecificData.module.scss";

type UserSpecificDataChild = Extract<Child, { kind: "userSpecificData" }>;

type UserSpecificDataProps = {
  child: UserSpecificDataChild;
  updateChild?: (newChild: Partial<UserSpecificDataChild>) => void;
  readOnly?: boolean;
  t: TranslationType;
};

export const UserSpecificData = ({ child, updateChild, readOnly, t }: UserSpecificDataProps) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: <> </>,
      error: t("interviews.templateBuilder.emptyField.error", {
        defaultValue: "Ce champ doit être rempli.",
      }),
    };
  }, [t]);

  const handleChange = useCallback(
    (value: string) => {
      updateChild({ userSpecificDataKey: value });
    },
    [updateChild]
  );

  return (
    <Divided>
      <div className={styles.container}>
        {readOnly && (
          <p>
            {t("interviews.templateBuilder.userSpecificData.header", {
              defaultValue:
                "Ce bloc permet d’ajouter de la données personnalisée provenant de votre base utilisateur. Il ne peut être configuré que par votre interlocuteur dédié Skillup.",
            })}
          </p>
        )}
        <div>
          <BuilderFormInput
            type="text"
            label="User data key"
            placeholder={t("interviews.templateBuilder.userSpecificData.key.placeholder", {
              defaultValue: "Saisissez quelque chose",
            })}
            name={`${child.uuid}-specific-data`}
            value={child.userSpecificDataKey}
            onChange={readOnly ? undefined : handleChange}
            debounceValue={50}
            required
            disabled={readOnly}
            multipleAssistiveTexts={multipleAssistiveTexts}
            autoFocus={highlightUuid === child.uuid}
          />
        </div>
      </div>
      <div className={styles.permissions}>
        <p>
          {t("interviews.templateBuilder.userSpecificData.permissions", {
            defaultValue: "Aucune action n’est possible pour ce bloc.",
          })}
        </p>
      </div>
    </Divided>
  );
};
