import React, { useMemo, useCallback, useRef, forwardRef, MutableRefObject } from "react";
import type { FormEvent, InputHTMLAttributes, CSSProperties, RefObject } from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import { Icon as UiIcon } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import Icon from "components/Icon";
import colors from "uiAssets/Colors";

import styles from "./TextInput.module.scss";

type TextInputElement = HTMLInputElement | HTMLTextAreaElement;
type TextInputEvent = FormEvent<TextInputElement>;
type TextInputAttributes = InputHTMLAttributes<TextInputElement>;

interface Props extends TextInputAttributes {
  alwaysOpen?: boolean;
  autoFocus?: boolean;
  background?: string;
  borderColor?: string;
  disabled?: boolean;
  icon?: string;
  iconStyle?: CSSProperties;
  rightIcon?: boolean;
  label?: string;
  translationPrefix?: string;
  description?: string;
  min?: string | number;
  name?: string;
  onBlur?: (event: TextInputEvent) => void;
  onChange?: (event: TextInputEvent, value?: string) => void;
  onFocus?: (event: TextInputEvent) => void;
  strokeIcon?: string;
  step?: string;
  underlineColor?: string;
  small?: boolean;
  showRequired?: boolean;
  inputRef?: MutableRefObject<TextInputElement>;
  error?: boolean;
  errorMessage?: string;
}

const TextInput = forwardRef<HTMLLabelElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const {
    className,
    alwaysOpen,
    borderColor,
    icon,
    rightIcon,
    style,
    iconStyle,
    label,
    translationPrefix,
    description,
    min,
    step,
    showRequired,
    strokeIcon,
    type,
    underlineColor,
    small,
    error,
    name,
    errorMessage,
  } = props;

  const translationKey = translationPrefix
    ? `${translationPrefix}.${name ? name + "." : ""}`
    : undefined;

  const translatedErrorMessage = translationKey
    ? t(`${translationKey}error`, {
        defaultValue: errorMessage ?? "Ce champ doit être rempli",
      })
    : errorMessage ?? "Ce champ doit être rempli";

  const translatedLabel = translationKey
    ? t(`${translationKey}label`, { defaultValue: label })
    : label;

  const defaultValue = useMemo(() => {
    if (props.type === "date" && props.defaultValue) {
      const d = new Date(props.defaultValue as string);
      return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(
        -2
      )}`;
    }

    return props.defaultValue;
  }, [props]);

  const [opened, toggleOpen] = useToggle(alwaysOpen || !!defaultValue);
  const hasIcon = useMemo(() => !!icon || !!strokeIcon, [icon, strokeIcon]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = props.inputRef ?? useRef<TextInputElement>(null);
  const textAreaDuplicate = useRef<HTMLDivElement>(null);

  const onFocus = useCallback(
    (event: TextInputEvent): void => {
      if (props.onFocus && typeof props.onFocus === "function") {
        props.onFocus(event);
      }
      if (!props.alwaysOpen) toggleOpen(true);
    },
    [props, toggleOpen]
  );

  const onBlur = useCallback(
    (event: TextInputEvent): void => {
      if (props.onBlur && typeof props.onBlur === "function") {
        props.onBlur(event);
      }
      if (!props.alwaysOpen && !inputRef.current.value) {
        toggleOpen(false);
      }
    },
    [props, toggleOpen, inputRef]
  );

  const onChange = useCallback(
    (event: FormEvent<TextInputElement>): void => {
      if (props.onChange && typeof props.onChange === "function") {
        props.onChange(event, event.currentTarget.value);
      }
    },
    [props]
  );

  const onTextAreaChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>): void => {
      textAreaDuplicate.current.innerText = event.currentTarget.value;
      inputRef.current.style.height = `${Math.min(
        Math.max(48, textAreaDuplicate.current.offsetHeight),
        250
      )}px`;
      onChange(event);
    },
    [textAreaDuplicate, inputRef, onChange]
  );

  const inputProps = useMemo(
    () => ({
      "aria-label": props["aria-label"] || translatedLabel,
      autoComplete: props.autoComplete || "",
      autoFocus: props.autoFocus || false,
      defaultValue,
      disabled: props.disabled || false,
      id: props.id || undefined,
      name: props.name || "noNameDefined",
      onBlur: onBlur,
      onFocus: onFocus,
      placeholder: props.placeholder,
      required: props.required || false,
      pattern: props.pattern,
      style: {
        boxShadow: `0 0 0 1000px ${
          props.error ? colors.errorL : props.background || "#f6f6f6"
        } inset`,
        color: props.error ? colors.error : undefined,
      },
      type: props.type || "text",
    }),
    [translatedLabel, props, defaultValue, onBlur, onFocus]
  );

  return (
    <label
      className={cx(styles.TextInput, {
        [styles.opened]: opened,
        [styles.hasIcon]: hasIcon,
        [styles.small]: small,
        [styles.right]: hasIcon && rightIcon,
        [className]: !!className,
      })}
      style={style}
      ref={ref}
    >
      <span className={styles.label}>
        {translatedLabel} {showRequired ? <span>*</span> : ""}
      </span>
      {description && <p className={styles.description}>{description}</p>}

      <div style={{ borderBottom: `2px solid ${borderColor || "#eee"}` }}>
        {hasIcon && (
          <div className={cx(styles.iconContainer, { [styles.right]: rightIcon })}>
            <Icon
              className={styles.icon}
              icon={icon || ""}
              strokeIcon={strokeIcon || ""}
              style={iconStyle}
            />
          </div>
        )}

        {type === "textarea" && (
          <textarea
            {...inputProps}
            onChange={onTextAreaChange}
            ref={inputRef as RefObject<HTMLTextAreaElement>}
            rows={1}
          />
        )}

        {type === "textarea" && (
          <div ref={textAreaDuplicate} className={styles.textAreaDuplicate} />
        )}

        {type !== "textarea" && (
          <input
            {...inputProps}
            onChange={onChange}
            ref={inputRef as RefObject<HTMLInputElement>}
            step={type === "number" ? step || "1" : undefined}
            min={min}
          />
        )}
        <div
          className={styles.underline}
          style={{ background: error ? colors.error : underlineColor || colors.gradientMain }}
        />
      </div>
      {error && (
        <div className={styles.error}>
          <UiIcon.SignError style={{ margin: "auto 10px auto 0" }} />
          <span>{translatedErrorMessage}</span>
        </div>
      )}
    </label>
  );
});

export default TextInput;
