import { DSModal, Flex } from "@skillup/ui";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTemplate, type Template } from "services/interviews";
import useTranslation, { TranslationType } from "hooks/useTranslation";
import { TemplateRelatedCampaigns } from "../../components";
import styles from "./ArchiveTemplateModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeSidePanel?: () => void;
  closeModal: () => void;
}

type ModalState =
  | { step: "idle" }
  | { step: "archiving" }
  | { step: "success" }
  | { step: "error"; error: string };

export const ArchiveTemplateModal = ({ template, isOpen, closeSidePanel, closeModal }: IProps) => {
  const { t } = useTranslation();
  const templateActions = useTemplate(template.uuid);
  const [modalState, setModalState] = useState<ModalState>({ step: "idle" });
  useStateEffects({ modalState, closeModal, t });

  const send = async (): Promise<void> => {
    if (modalState.step === "archiving") return;
    setModalState({ step: "archiving" });

    try {
      const response = await templateActions.archive();
      if (response.success) {
        setModalState({ step: "success" });
        if (closeSidePanel) closeSidePanel();
      } else {
        setModalState({
          step: "error",
          error: t("interviews.archiveTemplateModal.error", {
            defaultValue: "Echec lors de l'archivage de la trame",
          }),
        });
      }
    } catch (err) {
      setModalState({
        step: "error",
        error: t("interviews.archiveTemplateModal.error", {
          defaultValue: "Echec lors de l'archivage de la trame",
        }),
      });
    }
  };
  return (
    <DSModal isOpen={isOpen} className={styles.ArchiveTemplateModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.archiveTemplateModal.title", {
            defaultValue: "Archiver la trame « {{templateTitle}} »",
            templateTitle: template.title,
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.content}>
          <TemplateRelatedCampaigns campaigns={template.relatedCampaigns} />
          <p>
            {t("interviews.archiveTemplateModal.description", {
              defaultValue:
                "Si vous l’archivez, vous ne pourrez plus lancer de campagne à partir de celle-ci. Vous pourrez néanmoins continuer à ajouter des entretiens dans les campagnes utilisant cette trame.",
            })}
          </p>
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.archiveTemplateModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          label={t("interviews.archiveTemplateModal.confirm", {
            defaultValue: "Confirmer l'archivage",
          })}
          onClick={send}
          disabled={modalState.step === "archiving"}
          loading={modalState.step === "archiving"}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

function useStateEffects({
  modalState,
  closeModal,
  t,
}: {
  modalState: ModalState;
  closeModal: () => void;
  t: TranslationType;
}) {
  const { addToast } = useToasts();

  useEffect(() => {
    switch (modalState.step) {
      case "error":
        addToast(modalState.error, {
          appearance: "error",
        });
        break;
      case "success":
        addToast(
          t("interviews.archiveTemplateModal.success", {
            defaultValue: "Trame archivée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        closeModal();
        break;
      case "idle":
      case "archiving":
      default:
        return;
    }
  }, [modalState, closeModal, addToast, t]);
}
