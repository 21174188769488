import { CampaignListItem } from "@skillup/espace-rh-api/src/controllers/campaign";
import { CampaignsRoutes } from "@skillup/espace-rh-bridge";
import { format } from "@skillup/shared-utils";
import { IRoutes } from "@skillup/types";
import { colors, DSDropdown, Icon, ProgressBar, RectangleTags, useModal } from "@skillup/ui";
import moment from "moment";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import User from "utils/User";
import CampaignActions from "../../Campaign/components/CampaignActions";
import { CloseCampaignModal } from "../../Campaign/components/modals";
import { EditionContext } from "../../Campaign/components/modals/utils";
import { eventOffsetDirectionKeyToValue, eventOffsetUnitKeyToValue } from "utils/campaigns";
import styles from "./CampaignCard.module.scss";

type Campaign = CampaignsRoutes.GetList["response"][0];

const numberOfDaysbeforePrintingTag = 14;

interface PropsNumberOfDays {
  type: IRoutes["/campaigns"]["GET"]["/"][0]["type"] | "oldOngoing";
  endDate?: string;
}

interface PropsCampaignIsOver {
  type: IRoutes["/campaigns"]["GET"]["/"][0]["type"] | "oldOngoing";
  endDate?: string;
  total: number;
  current: number;
}

export interface Props {
  readonly campaign: Campaign;
  readonly onDelete: () => void;
  readonly resync: () => Promise<void>;
  readonly setActionsState: (stateChange: EditionContext) => void;
  readonly setCampaignState: Dispatch<SetStateAction<CampaignListItem>>;
}

const CampaignCard = ({ campaign, onDelete, resync, setActionsState, setCampaignState }: Props) => {
  const { t } = useTranslation();
  const {
    uuid,
    titleForHR,
    type,
    endDate,
    signedInterviews,
    totalInterviews,
    managerOnly,
    hideFromEmployeeUntil,
    hideFromManagerUntil,
    eventType,
  } = campaign;

  const campaignType = useMemo(() => {
    if (type === "legacy") return "legacy";
    if (type === "ongoing") return "oldOngoing";
    if (type === "adHoc" && eventType !== null) return "ongoing";
    if (type === "adHoc" && eventType === null) return "adHoc";
    return "legacy";
  }, [type, eventType]);

  const {
    isOpen: isCampaignClosingOpen,
    show: showCampaignClosingModal,
    hide: hideCloseCampaignModal,
  } = useModal();

  const history = useHistory();

  const renderAdHocCard = (_: Campaign) => (
    <>
      <li className={styles.meta}>
        {t("interviews.manualTrigger", {
          defaultValue: "Déclenchement manuel",
        })}
      </li>
    </>
  );

  const tags = {
    legacy: <RectangleTags.LegacyCampaign key="legacy-campaign" t={t} />,
    oldOngoing: <RectangleTags.OngoingCampaign key="ongoing-campaign" t={t} />,
    ongoing: <RectangleTags.OngoingCampaign key="ongoing-campaign" t={t} />,
    adHoc: <RectangleTags.AdHocCampaign key="adhoc-campaign" t={t} />,
  };

  const CampaignIsOver = ({ type, endDate, total, current }: PropsCampaignIsOver) => {
    if (type === "legacy") {
      const diff = moment(endDate).diff(moment(), "days");

      if (diff < 0) {
        if (total === current) return <RectangleTags.CampaignIsOver t={t} />;
        else return <RectangleTags.CampaignOutOfTime t={t} />;
      }
    }
    return <></>;
  };

  const NumberOfDays = ({ type, endDate }: PropsNumberOfDays) => {
    if (type === "legacy") {
      const diff = moment(endDate).diff(moment(), "days");

      if (diff < numberOfDaysbeforePrintingTag && diff >= 0) {
        return <RectangleTags.DaysRemaining numberOfDays={diff} daysForRed={7} t={t} />;
      }
    }
    return <></>;
  };

  const renderLegacyCard = ({ startDate, endDate }: Campaign) => (
    <>
      <li className={styles.meta}>
        {t("interviews.legacyCampaignStartDate", {
          defaultValue: "Date de début de campagne : {{date}}",
          date: format(new Date(startDate), "dd/MM/yyyy"),
        })}
      </li>
      <li className={styles.meta}>
        {t("interviews.legacyCampaignEndDate", {
          defaultValue: "Date limite de signature : {{date}}",
          date: format(new Date(endDate), "dd/MM/yyyy"),
        })}
      </li>
    </>
  );

  const renderOldOngoingCard = ({ frequency, duration }: Campaign) => (
    <>
      <li className={styles.meta}>
        {t("interviews.ongoingCampaignTrigger", {
          defaultValue: "Déclenchement automatique: {{count}} jour après l'embauche",
          defaultValue_other: "Déclenchement automatique: {{count}} jours après l'embauche",
          count: frequency,
        })}
      </li>
      <li className={styles.meta}>
        {t("interviews.ongoingCampaignSignatureDelay", {
          defaultValue: "Délai de signature: {{count}} jour",
          defaultValue_other: "Délai de signature: {{count}} jours",
          count: duration,
        })}
      </li>
    </>
  );

  const renderOngoingCard = ({
    eventOffset,
    eventOffsetUnit,
    eventOffsetDirection,
    duration,
  }: Campaign) => (
    <>
      <li className={styles.meta}>
        {t("interviews.campaign.ongoing.trigger", { defaultValue: "Déclenchement automatique:" })}{" "}
        {eventOffset} {eventOffsetUnitKeyToValue(eventOffsetUnit, eventOffset, t)}{" "}
        {eventOffsetDirectionKeyToValue(eventOffsetDirection, t)}{" "}
        {t("interviews.campaign.detail.joinDate", { defaultValue: "l'embauche" })}
      </li>
      <li className={styles.meta}>
        {t("interviews.ongoingCampaignSignatureDelay", {
          defaultValue: "Délai de signature: {{count}} jour",
          defaultValue_other: "Délai de signature: {{count}} jours",
          count: duration,
        })}
      </li>
    </>
  );

  const progress =
    totalInterviews === 0 ? 0 : Math.round((signedInterviews / totalInterviews) * 100);

  const diff = endDate && moment(endDate).diff(moment(), "days");

  const tagsToDisplay = [
    tags[campaignType],
    ...(User.isSkillupAdmin()
      ? [
          ...(managerOnly
            ? [<RectangleTags.IsManagerOnlyCampaign key="manager-only-campaign" t={t} />]
            : []),
          ...(hideFromEmployeeUntil
            ? [
                <RectangleTags.HideFromEmployeeUntil
                  status={hideFromEmployeeUntil.managerState}
                  key="hide-for-employee-until"
                  t={t}
                />,
              ]
            : []),
          ...(hideFromManagerUntil
            ? [
                <RectangleTags.HideFromManagerUntil
                  status={hideFromManagerUntil.employeeState}
                  key="hide-from-manager-until"
                  t={t}
                />,
              ]
            : []),
        ]
      : []),
  ];

  const menuItems = useMemo(() => {
    return CampaignActions({
      campaign,
      onDelete,
      resync,
      setActionsState,
      onClick: showCampaignClosingModal,
      t,
    });
  }, [campaign, onDelete, resync, setActionsState, showCampaignClosingModal, t]);

  const [dropdownIsOpened, setDropdownIsOpened] = useState(false);

  const onClick = (value: boolean) => {
    setDropdownIsOpened(value);
    setCampaignState(campaign);
  };
  return (
    <>
      {isCampaignClosingOpen && (
        <CloseCampaignModal campaign={campaign} onClose={hideCloseCampaignModal} resync={resync} />
      )}
      <section
        className={styles.CampaignCard}
        onClick={() => {
          if (!dropdownIsOpened) history.push(`/responsable/campagne/${uuid}`);
        }}
      >
        <div className={styles.tags}>
          <div className={styles.tagsLeft}>{tagsToDisplay}</div>
          <div className={styles.tagsRight}>
            <NumberOfDays type={campaignType} endDate={endDate} />
            <CampaignIsOver
              type={campaignType}
              total={totalInterviews}
              current={signedInterviews}
              endDate={endDate}
            />
            {menuItems.length > 0 && (
              <DSDropdown onToggleDropdown={onClick} className={styles.dropdowContainer}>
                {menuItems}
              </DSDropdown>
            )}
          </div>
        </div>

        <div className={styles.infos}>
          <h4 className={styles.name}>{titleForHR}</h4>
          <ul className={styles.props}>
            {campaignType === "legacy" && renderLegacyCard(campaign)}
            {campaignType === "oldOngoing" && renderOldOngoingCard(campaign)}
            {campaignType === "ongoing" && renderOngoingCard(campaign)}
            {campaignType === "adHoc" && renderAdHocCard(campaign)}
          </ul>
        </div>

        <div className={styles.progress}>
          <div className={styles.progressBarInfos}>
            {diff &&
            diff < numberOfDaysbeforePrintingTag &&
            signedInterviews !== totalInterviews ? (
              <Icon.SignError color="#535d6d" />
            ) : undefined}
            <div className={styles.textWrapper}>
              <h5 className={styles.percentage}>{progress}%</h5>
              <span className={styles.details}>
                {t("interviews.signedInterviewsCount", {
                  defaultValue: "({{count}}/{{total}} entretien signé)",
                  defaultValue_other: "({{count}}/{{total}} entretiens signés)",
                  count: signedInterviews,
                  total: totalInterviews,
                })}
              </span>
            </div>
          </div>
          <ProgressBar
            max={totalInterviews}
            value={signedInterviews}
            color={colors.lightblue}
            side="none"
          />
        </div>
      </section>
    </>
  );
};

export default CampaignCard;
