import { MdDone as Done } from "react-icons/md";
import { MdContentCopy as ContentCopy } from "react-icons/md";
import { useState } from "react";

import { DSButton } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

export function CopyText({ text }) {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
  }

  const handleCopyClick = (e) => {
    e.stopPropagation();
    copyTextToClipboard(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <DSButton
      iconOnly
      buttonSize="S"
      tooltip={
        isCopied
          ? t("training.view.session_list.action.copy_to_clipboard.tootlip_copied", {
              defaultValue: "ID copié",
            })
          : t("training.view.session_list.action.copy_to_clipboard.tootlip_copy", {
              defaultValue: "Copier l'ID de la session",
            })
      }
      onClick={handleCopyClick}
      icon={isCopied ? <Done /> : <ContentCopy />}
    />
  );
}
