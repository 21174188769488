import React, { useCallback } from "react";
import { useSetState, useAsync } from "react-use";
import cx from "classnames";

import {
  IUser,
  TPrimaryState,
  IScheduleLog,
  Summary as ISummary,
  FieldSection,
} from "@skillup/types";
import { EmployeeTrainingSessionState } from "@skillup/training-bridge";

import Activity from "components/Activity";
import Summary from "components/Summary";
import { Details } from "components/Details";
import Icon from "components/Icon";
import { ITraining } from "components/RequalificationModal/Actions/getTrainings";

import useTranslation from "hooks/useTranslation";
import { cross as crossIcon } from "uiAssets/StrokeIcons";
import { FieldRoutesType } from "types/api";

import fetchItems, { IRelationCall } from "../utils/fetchItems";
import fetchDetails from "../utils/fetchDetails";
import fetchLogs, { IActivity } from "../utils/fetchLogs";
import fetchSessionsState from "../utils/fetchSessionsState";

import { ManualTrainingData } from "../Modals/ManualRowForms/Training";
import ActionsButton from "./Actions/ActionsButton";

import FileUpload from "./FileUpload";
import Fields from "./Fields";

import styles from "./SidePanelStyles.module.scss";

export interface IPartialUser extends Partial<IUser> {
  manager?: IUser;
  pendingDemands?: number;
  validatedDemands?: number;
}

export interface IItem {
  booking?: {
    sessionCity: string;
    sessionDtes: Array<Date>;
    sessionDuration: string;
    sessionGuaranteed?: boolean;
    sessionPrice: number;
    sessionType: "inter" | "intra";
    state: TPrimaryState;
    trainingName: string;
    trainingOrganization: string;
    uuid: string;
  };
  leadIntra?: {
    name: string;
    createdAt: Date;
    rows: Array<string>;
    uuid: string;
  };
  training?: {
    name: string;
    organization: {
      name: string;
      uuid: string;
      slug: string;
    };
    seoSlug: string;
    sessionsCount: number;
    types: Array<"inter" | "intra">;
    uuid: string;
  };
  logs: Array<IScheduleLog>;
  primaryState: TPrimaryState;
  row: string;
  rowCreatedAt: Date;
  type: "inter" | "intra";
  user: IPartialUser;
}

export interface IProps {
  readonly className?: string;
  deselectRows: (rows: Array<string>) => void;
  showRequalifyModal: (rows: Array<string>, summary: ISummary, cb: Function) => void;
  showManualEditionProcess: (rows: string[], training: ManualTrainingData, cb: Function) => void;
  showDeleteManualRowsModal: (rows: string[]) => void;
  showPositioningModal: (rows: Array<string>, noEmail?: boolean, noManager?: boolean) => void;
  showBookingMailReminderModal: (rows: Array<string>) => void;
  showValidationMailReminderModal: (rows: Array<string>) => void;
  selectedRows: Array<string>;
  onMoveToPlan: (rows: Array<string>) => Promise<void>;
  type: "plan" | "collection";
  onClose: () => void;
  reloadRows: (rowsUuids: string[]) => Promise<void>;
  scheduleUuid: string;
  useCache?: boolean;
  fields: FieldRoutesType.GetV2["response"];
}

export interface IState {
  activePanel: "activity" | "fileUpload" | "financial";
  data?: IRelationCall[];
  details: Details | {};
  summary: ISummary | {};
  fields: FieldSection[];
  training?: ITraining;
  users?: { noEmail: boolean; fullName: string }[];
  activitiesByDates?: { [key: string]: Array<IActivity> };
  sessionsState?: EmployeeTrainingSessionState[];
}

const SidePanel = (props: IProps) => {
  const {
    className,
    selectedRows,
    reloadRows,
    scheduleUuid,
    useCache,
    fields: companyFields,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    activePanel: "financial",
    details: {},
    summary: {},
    fields: [],
    sessionsState: [],
  });

  const fetch = useCallback(async () => {
    if (selectedRows.length >= 1) {
      try {
        const data = await fetchItems(selectedRows, useCache);
        const details = await fetchDetails(data, useCache);

        if (data.length === 1) {
          const userUuid = details.details.user
            .find((user) => user.label === "Id")
            .value.toString();

          const { trainingUuid } = details.summary;

          let sessionsStatePromise = Promise.resolve(undefined);

          const logPromise = fetchLogs(data[0].uuid);
          if (userUuid && trainingUuid) {
            sessionsStatePromise = fetchSessionsState(trainingUuid, userUuid);
          }

          const [activitiesByDates, sessionsState] = await Promise.all([
            logPromise,
            sessionsStatePromise,
          ]);

          setState({ data, ...details, activitiesByDates, sessionsState });
        } else {
          setState({ data, ...details });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [selectedRows, setState, useCache]);

  useAsync(fetch, [selectedRows]);

  const { activePanel, data, summary, fields, users, sessionsState } = state;

  const tooMuchRowsWording = t("trainings.view.schedule_row.sidepanel.warning.too_much_rows", {
    defaultValue:
      "Votre sélection contient trop de lignes pour donner un résumé. Veuillez préciser votre sélection",
  });

  return (
    <div className={cx(styles.SidePanel, className)}>
      <div className={styles.gradientBackground}>
        <header>
          <button onClick={() => props.onClose()}>
            <Icon strokeIcon={crossIcon} width={15} stroke="#666" />
          </button>
        </header>

        <div className={styles.actionAndSummary}>
          <Summary selection={(selectedRows || []).join("")} summary={summary} />
          <ActionsButton
            scheduleUuid={scheduleUuid}
            reloadRows={reloadRows}
            data={state.data}
            useCache={useCache}
            showBookingMailReminderModal={props.showBookingMailReminderModal}
            showValidationMailReminderModal={props.showValidationMailReminderModal}
            showPositioningModal={props.showPositioningModal}
            showRequalifyModal={props.showRequalifyModal}
            showManualEditionProcess={props.showManualEditionProcess}
            showDeleteManualRowsModal={props.showDeleteManualRowsModal}
            onMoveToPlan={props.onMoveToPlan}
            summary={summary as ISummary}
            sessionsState={sessionsState}
            users={users}
            fetch={fetch.bind(this)}
          />
        </div>
      </div>

      <nav>
        <button
          className={cx({ [styles.active]: activePanel === "financial" })}
          onClick={() => setState({ activePanel: "financial" })}
        >
          {t("trainings.view.schedule_row.sidepanel.tab.follow", {
            defaultValue: "Suivi",
          })}
        </button>
        <button
          className={cx({ [styles.active]: activePanel === "activity" })}
          onClick={() => setState({ activePanel: "activity" })}
        >
          {t("trainings.view.schedule_row.sidepanel.tab.activity", {
            defaultValue: "Activité",
          })}
        </button>
        {data && data.length === 1 && (
          <button
            className={cx({ [styles.active]: activePanel === "fileUpload" })}
            onClick={() => setState({ activePanel: "fileUpload" })}
          >
            {t("trainings.view.schedule_row.sidepanel.tab.documents", {
              defaultValue: "Documents",
            })}
          </button>
        )}
      </nav>

      <div
        className={styles.activity}
        style={activePanel !== "activity" ? { display: "none" } : {}}
      >
        {props.selectedRows.length === 1 ? (
          <Activity activitiesByDates={state.activitiesByDates} />
        ) : (
          <p className={styles.warning}>{tooMuchRowsWording}</p>
        )}
      </div>

      <div
        className={styles.fileUpload}
        style={activePanel !== "fileUpload" ? { display: "none" } : {}}
      >
        {props.selectedRows.length === 1 ? (
          <FileUpload row={props.selectedRows[0]} type={props.type} />
        ) : (
          <p className={styles.warning}>{tooMuchRowsWording}</p>
        )}
      </div>

      <div
        className={styles.financial}
        style={activePanel !== "financial" ? { display: "none" } : {}}
      >
        {state.data ? (
          <Fields
            companyFields={companyFields}
            rowsUuid={props.selectedRows}
            data={fields}
            fetch={fetch}
            reloadRows={props.reloadRows}
          />
        ) : (
          <p className={styles.warning}>{tooMuchRowsWording}</p>
        )}
      </div>
    </div>
  );
};

export default SidePanel;
