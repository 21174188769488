import React, { ReactNode, ComponentClass } from "react";
import { Route, Switch, Link, RouteComponentProps } from "react-router-dom";

import DSLayout from "components/DSLayout";
import { Home, OpenRows, Interviews, Targets } from "./components";

import styles from "./OpsAdmin.module.scss";
import { ImportExportDatasets } from "./components/ImportExportDatasets/ImportExportDatasets";

export interface IRouteInterface {
  path: string;
  label: string;
  component: ReactNode;
}

const routes: Array<IRouteInterface> = [
  {
    component: Home,
    label: "Home",
    path: "/responsable/ops-administration",
  },
  {
    component: OpenRows,
    label: "Import de plan historique",
    path: "/responsable/ops-administration/import",
  },
  {
    component: Interviews,
    label: "Gestion entretiens",
    path: "/responsable/ops-administration/interviews",
  },
  {
    component: Targets,
    label: "Gestion objectifs",
    path: "/responsable/ops-administration/targets",
  },
  {
    component: ImportExportDatasets,
    label: "Historiques Core RH",
    path: "/responsable/ops-administration/import-export-datasets",
  },
];

interface Props {
  location: {
    pathname: string;
  };
}

const OpsAdmin = ({ location }: Props) => {
  const currentRoute: IRouteInterface | undefined = routes.find(
    (route: IRouteInterface) => route.path === location.pathname
  );

  return (
    <DSLayout title={`[DATA] Administration : ${currentRoute ? currentRoute.label : ""}`}>
      <div className={styles.OpsAdmin}>
        <nav>
          {routes.map((route: IRouteInterface) => (
            <Link
              to={route.path}
              className={route.path === location.pathname ? "active" : "notActive"}
              title={route.label}
              key={route.path}
            >
              {route.label}
            </Link>
          ))}
        </nav>

        <Switch>
          {routes.map(({ path, component }: IRouteInterface) => (
            <Route
              exact
              key={path}
              path={path}
              component={component as ComponentClass<RouteComponentProps, any>}
            />
          ))}
        </Switch>
      </div>
    </DSLayout>
  );
};
export default OpsAdmin;
