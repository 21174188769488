import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { editCampaignFeedbackByCampaignID } from "./editCampaignFeedbackByCampaignID";
import { getCampaignFeedbackByCampaignID } from "./getCampaignFeedbackByCampaignID";
import { Text } from "@skillup/design-system";
import useTranslation from "hooks/useTranslation";

export interface CampaignFeedbackFormData {
  isCommentSharedToManager: boolean;
  sharedToManagerActionIds: string[];
  sharedToManagerCoreHRScaleIDs: string[];
}

const useCampaignFeedbackForm = ({
  campaignID,
  onMutationSuccess,
}: {
  campaignID: string;
  onMutationSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [sharedToManagerActionIds, setSharedToManagerActionIds] = useState<string[]>([]);
  const [sharedToManagerCoreHRScaleIDs, setSharedToManagerCoreHRScaleIDs] = useState<string[]>([]);
  const [isCommentSharedToManager, setIsCommentSharedToManager] = useState(true);

  useQuery(
    ["people-reviews-campaign-feedback", campaignID],
    () => getCampaignFeedbackByCampaignID(campaignID),
    {
      enabled: Boolean(campaignID),
      onSuccess: (data) => {
        setSharedToManagerActionIds(data.properties.sharedToManagerActionIds);
        setSharedToManagerCoreHRScaleIDs(data.properties.sharedToManagerCoreHRScaleIDs);
        setIsCommentSharedToManager(data.properties.isCommentSharedToManager);
      },
    }
  );

  const queryClient = useQueryClient();
  const { isLoading: isMutationLoading, mutate } = useMutation(editCampaignFeedbackByCampaignID, {
    onError: () => {
      addToast(
        <>
          <Text espaceFont="body1Regular" color="plainText-onDark-default">
            {t("peopleReview.campaign.feedbackSettingsModal.toasts.an-error-occurred", {
              defaultValue: "Une erreur est survenue.",
            })}
          </Text>
          <Text espaceFont="body1Regular" color="plainText-onDark-default">
            {t("peopleReview.campaign.feedbackSettingsModal.toasts.please-try-again-later", {
              defaultValue: "Veuillez réessayer ultérieurement.",
            })}
          </Text>
        </>,
        {
          appearance: "error",
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["people-reviews-campaign-feedback", campaignID]);
      onMutationSuccess();
    },
  });
  const handleSubmit = (data: CampaignFeedbackFormData) => {
    mutate({
      campaignID,
      isCommentSharedToManager: data.isCommentSharedToManager,
      sharedToManagerActionIds: data.sharedToManagerActionIds,
      sharedToManagerCoreHRScaleIDs: data.sharedToManagerCoreHRScaleIDs,
    });
  };

  const handleChange = (data: CampaignFeedbackFormData) => {
    setSharedToManagerActionIds(data.sharedToManagerActionIds);
    setSharedToManagerCoreHRScaleIDs(data.sharedToManagerCoreHRScaleIDs);
    setIsCommentSharedToManager(data.isCommentSharedToManager);
  };

  return {
    handleChange,
    handleSubmit,
    isMutationLoading,
    isCommentSharedToManager,
    sharedToManagerActionIds,
    sharedToManagerCoreHRScaleIDs,
    setIsCommentSharedToManager,
    setSharedToManagerActionIds,
    setSharedToManagerCoreHRScaleIDs,
  };
};

export default useCampaignFeedbackForm;
