import { useQuery } from "@tanstack/react-query";
import { TargetCategoriesRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";
import { useMemo } from "react";

async function getTargetCategories() {
  try {
    const result = await buildRequest<TargetCategoriesRoutes.GetCategories>({
      method: "GET",
      path: "/targetCategories",
    })();

    return result;
  } catch (err) {
    return [];
  }
}

export function useTargetCategories() {
  const queryForTargetCategories = useQuery(["targetCategories"], getTargetCategories, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const targetCategories = useMemo(() => {
    if (queryForTargetCategories.isLoading || queryForTargetCategories.isError) return [];
    return (
      queryForTargetCategories.data
        .filter((category) => category.label !== "default")
        //TODO: gérer les catégories archivées : on veut ne pas les déselectionner, mais ne pas permettre de les choisir si elles ne sont pas déjà sélectionnées
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((category) => ({
          uuid: category.uuid,
          label: category.label,
        }))
    );
  }, [queryForTargetCategories]);

  return {
    targetCategories,
  };
}
