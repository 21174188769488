/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IFilterManager } from "./IFilterManager";
import {
  type FilterValue,
  FilterOperator,
  type FilterConfiguration,
  type FilterConfigurationMap,
  type FilterOperators,
} from "./types";

export class Neo4JFilterBuilder<Config extends FilterConfigurationMap> extends IFilterManager<
  [string, object],
  Config
> {
  constructor(
    configuration: Config,
    private propertyMap: { [id: string]: string }
  ) {
    super(configuration);
  }

  override buildMultiSelectFilter(
    id: string,
    _filter: FilterConfiguration<"multiselect">,
    filterValue: FilterValue<"multiselect">
  ): [string, object] {
    switch (filterValue.operator) {
      case FilterOperator.CONTAINS:
        return [`${this.propertyMap[id]} IN $${id}`, { [id]: filterValue.value }];
      case FilterOperator.EQUALS:
        return [`${this.propertyMap[id]} = $${id}`, { [id]: filterValue.value }];
      default:
        throw new Error(
          `${filterValue.operator} operator is not supported for multi select filters`
        );
    }
  }

  override buildTextFilter(
    id: string,
    _filter: FilterConfiguration<"text">,
    filterValue: FilterValue<"text">
  ): [string, object] {
    switch (filterValue.operator) {
      case FilterOperator.CONTAINS:
        return [
          `toLower(${this.propertyMap[id]}) CONTAINS $${id}`,
          { [id]: filterValue.value!.toString().toLowerCase() },
        ];
      case FilterOperator.EQUALS:
        return [
          `toLower(${this.propertyMap[id]}) = $${id}`,
          { [id]: filterValue.value!.toString().toLowerCase() },
        ];
      default:
        throw new Error(`${filterValue.operator} operator is not supported for text filters`);
    }
  }

  override buildSingleSelectFilter(
    id: string,
    _filter: FilterConfiguration<"select">,
    filterValue: FilterValue<"select">
  ): [string, object] {
    switch (filterValue.operator) {
      case FilterOperator.EQUALS:
        return [`${this.propertyMap[id]} = $${id}`, { [id]: filterValue.value }];
      default:
        throw new Error(
          `${filterValue.operator} operator is not supported for single select filters`
        );
    }
  }

  override buildNumberFilter(
    id: string,
    _filter: FilterConfiguration<"number">,
    filterValue: FilterValue<"number">
  ): [string, object] {
    if (Array.isArray(filterValue.value)) {
      return this.buildArrayNumberFilter(
        this.propertyMap[id],
        filterValue.operator,
        filterValue.value
      );
    }

    switch (filterValue.operator) {
      case FilterOperator.EQUALS:
        return [`${this.propertyMap[id]} = $${id}`, { [id]: filterValue.value }];
      case FilterOperator.GREATER_THAN:
        return [`${this.propertyMap[id]} > $${id}`, { [id]: filterValue.value }];
      case FilterOperator.LESS_THAN:
        return [`${this.propertyMap[id]} < $${id}`, { [id]: filterValue.value }];
      case FilterOperator.GREATER_THAN_OR_EQUAL:
        return [`${this.propertyMap[id]} >= $${id}`, { [id]: filterValue.value }];
      case FilterOperator.LESS_THAN_OR_EQUAL:
        return [`${this.propertyMap[id]} <= $${id}`, { [id]: filterValue.value }];
      default:
        throw new Error(`${filterValue.operator} operator is not supported for number filters`);
    }
  }

  buildArrayNumberFilter(
    property: string,
    operator: FilterOperators,
    value: [number, number]
  ): [string, object] {
    if (operator === FilterOperator.BETWEEN) {
      return [`$lower <= ${property} <= $higher`, { lower: value[0], higher: value[1] }];
    }

    throw new Error(`${operator} operator is not yet implemented for array number filters`);
  }

  override buildDateFilter(
    id: string,
    _filter: FilterConfiguration<"date">,
    filterValue: FilterValue<"date">
  ): [string, object] {
    if (filterValue.value === undefined)
      throw new Error(`Undefined is not a correct value for Date filters`);

    if (Array.isArray(filterValue.value)) {
      return this.buildArrayDateFilter(
        this.propertyMap[id],
        filterValue.operator,
        filterValue.value
      );
    }

    switch (filterValue.operator) {
      case FilterOperator.EQUALS:
        return [`${this.propertyMap[id]} = $${id}`, { [id]: filterValue.value.getTime() }];
      case FilterOperator.AFTER:
        return [`${this.propertyMap[id]} >= $${id}`, { [id]: filterValue.value.getTime() }];
      case FilterOperator.BEFORE:
        return [`${this.propertyMap[id]} <= $${id}`, { [id]: filterValue.value.getTime() }];
      default:
        throw new Error(`${filterValue.operator} operator is not yet implemented for date filters`);
    }
  }

  buildArrayDateFilter(
    property: string,
    operator: FilterOperators,
    value: [Date, Date]
  ): [string, object] {
    if (operator === FilterOperator.BETWEEN) {
      return [
        `$lower <= ${property} <= $higher`,
        { lower: value[0].getTime(), higher: value[1].getTime() },
      ];
    }

    throw new Error(`${operator} operator is not yet implemented for array date filters`);
  }

  public buildNeo4JFilter(filterId: string, filterValue: FilterValue<any>) {
    return this.buildFilter(filterId, filterValue);
  }
}
