import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";

import { TranslationType } from "hooks/useTranslation";

export default async (
  rows: Array<string> | undefined,
  target: string,
  type: "inter" | "intra",
  reload,
  t: TranslationType
) => {
  try {
    await DataLayer.request({
      body: JSON.stringify({ target, rows }),
      method: "POST",
      url: `/v1/requalification/${type}`,
    });
    Acta.dispatchEvent("closeModal");

    Acta.dispatchEvent("sendAppMessage", {
      message: t("trainings.view.pending_schedule_row.property.requalify_modal.selection_success", {
        defaultValue: "Sélection reliée avec succès au programme sélectionné",
      }),
      type: "success",
    });

    reload();
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: t("trainings.view.pending_schedule_row.property.requalify_modal.selection_error", {
        defaultValue: "Erreur lors de l'ajout du stagiaire.",
      }),
      type: "error",
    });
  }
};
