import React, { useState } from "react";
import cx from "classnames";

import useTranslation from "hooks/useTranslation";

import { link as linkIcon } from "uiAssets/StrokeIcons";
import { mapPin as mapPinIcon } from "uiAssets/Icons";

import Icon from "components/Icon";
import modalitiesConstants from "constants/modalities";
import TrainingScore from "components/TrainingScore";

import { ITraining } from "../Actions/getTrainings";
import styles from "./TrainingStyles.module.scss";

export interface IProps {
  isSelected: boolean;
  data: ITraining;
  select: (training: ITraining) => void;
  lightMode?: true;
}

function Training(props: IProps): JSX.Element {
  const { t, i18n } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const { lightMode, isSelected, data } = props;

  const {
    certification,
    description,
    duration,
    isCPF,
    isNew,
    mode,
    name,
    organization,
    logo,
    objectives,
    price,
    reviewsCount,
    reviewsGlobalScore,
    seoSlug,
    sessions,
    sessionThreshold,
    subtitle,
    uuid,
    reference,
  } = data;

  const select = () => {
    props.select(data);
  };

  const nextSessions = (sessions || [])
    .map((session) => ({
      ...session,
      date: new Date(session.date),
    }))
    .filter(
      (session) => session.date.getTime() > (sessionThreshold ? sessionThreshold.getTime() : 0)
    )
    .slice(0, 3);

  const descriptionText = description || objectives || subtitle || null;

  return (
    <div
      className={cx({
        [styles.Training]: true,
        [styles.lightMode]: lightMode,
        [styles.classic]: !lightMode,
        [styles.selected]: isSelected,
      })}
      onClick={select}
    >
      <header>
        <div
          className={styles.logo}
          style={{
            backgroundImage: `url("${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${logo}")`,
          }}
        />

        <div className={styles.trainingLink}>
          <h3 className={styles.title}>{name}</h3>
          {reviewsCount && reviewsCount > 0 && reviewsGlobalScore ? (
            <TrainingScore
              score={Math.round(reviewsGlobalScore * 10) / 10}
              reviewsCount={reviewsCount}
            />
          ) : (
            ""
          )}
          {reference && <div className={styles.reference}>{reference}</div>}

          <div className={styles.tags}>
            {certification && (
              <span className={styles.certification}>
                {t("trainings.view.requalification_modal.training.certifying", {
                  defaultValue: "Certifiante",
                })}
              </span>
            )}
            {isCPF && (
              <span className={styles.cpf}>
                {t("trainings.view.requalification_modal.training.cpf", {
                  defaultValue: "CPF",
                })}
              </span>
            )}
            {isNew && (
              <span className={styles.cpf}>
                {t("trainings.view.requalification_modal.training.new", {
                  defaultValue: "Nouvelle",
                })}
              </span>
            )}
          </div>
        </div>

        <div className={styles.actions}>
          {!lightMode && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? "-" : "+"}
            </button>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (seoSlug) {
                window.open(`/responsable/programmes/catalogue/${seoSlug}`);
              } else {
                window.open(`/responsable/programmes/gestion-intras/${uuid}`);
              }
            }}
          >
            <Icon strokeIcon={linkIcon} fill="#666" width={20} style={{ marginRight: 4 }} />
          </button>
        </div>
      </header>

      <div className={styles.summary}>
        <div>
          {mode &&
            t(`trainings.entity.training.property.mode.${mode}.label`, {
              defaultValue: modalitiesConstants.mapping[mode],
            }) + " -- "}
          {duration}
        </div>
        <div>{price}</div>
        <div>
          {t("trainings.view.requalification_modal.training.by", {
            organization: organization,
            defaultValue: `Par {{ organization }}`,
          })}
        </div>
        {
          // @ts-ignore
          !data.hasSessions && (
            <div>
              {t("trainings.view.requalification_modal.training.no_session", {
                defaultValue: "Pas de session disponible",
              })}
            </div>
          )
        }
        {(nextSessions || [])[0] && (
          <div>
            <Icon icon={mapPinIcon} width={10} fill="#666" />
            {`${
              nextSessions[0].type !== "distancial"
                ? nextSessions[0].city
                : t("trainings.view.requalification_modal.training.distance", {
                    defaultValue: "À distance",
                  })
            } - ${new Date(nextSessions[0].date).toLocaleString(i18n.language, {
              day: "numeric",
              month: "long",
            })}`}
            {nextSessions.length > 1 && (
              <span>
                {`+ ${nextSessions.length - 1} `}
                <Icon icon={mapPinIcon} width={8} fill="#fff" />
              </span>
            )}
          </div>
        )}
      </div>

      <div className={styles.data} style={{ display: isExpanded ? "block" : "none" }}>
        {descriptionText && (
          <h3>
            {t("trainings.view.requalification_modal.training.about", {
              defaultValue: "À propos",
            })}
          </h3>
        )}
        {descriptionText && <p>{descriptionText}</p>}
        {(nextSessions || []).length > 0 && (
          <h3>
            {t("trainings.view.requalification_modal.training.next_sessions", {
              count: nextSessions.length,
              defaultValue: nextSessions.length <= 1 ? `Prochaine session` : `Prochaines sessions`,
            })}
          </h3>
        )}
        {nextSessions && nextSessions.length > 0 && (
          <div className={styles.nextSessionLinks}>
            {(nextSessions || []).map((session) => (
              <div key={session.uuid} className={styles.nextSessionLink}>
                <Icon icon={mapPinIcon} width={12} />
                {` ${
                  session.type !== "distancial"
                    ? session.city
                    : t("trainings.view.requalification_modal.training.distance", {
                        defaultValue: "À distance",
                      })
                } - ${new Date(session.date).toLocaleString(i18n.language, {
                  day: "numeric",
                  month: "long",
                })}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Training;
