import { useParams, useHistory } from "react-router-dom";

import { t } from "i18next";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Text, Flex } from "@skillup/design-system";
import { DSAvatar, UserPopover } from "@skillup/ui";

import { getUserInitials } from "utils/User";

import { views, Review } from "../../../types";

type UserPopoverCellProps = {
  params: GridRenderCellParams<Review>;
};

const UserPopoverCell = ({ params }: UserPopoverCellProps) => {
  const history = useHistory();
  const { campaignID } = useParams<{ campaignID: string }>();

  const role = params.row.role;
  const fullName = params.row.fullName;
  const uuid = params.row.userID;
  const initials = getUserInitials({ fullName });

  return (
    <UserPopover
      position="down-right"
      properties={[role].filter(Boolean)}
      user={{ uuid, fullName, initials }}
      profileBtn={{
        label: "Voir le profil",
        onClick: () =>
          history.push(
            `/responsable/collaborateurs/${params.row.userID}/career?previousPageURI=/responsable/people-review/campaigns/${campaignID}/${views.grid}&previousPageTitle=People%20Review`
          ),
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <DSAvatar
          darkMode
          size="S"
          type="Initials"
          initials={initials}
          imageUrl={undefined}
          randomColorSeedString={uuid}
        />
        <Text marginLeft="xs" color="plainText-onLight-default">
          {fullName}
        </Text>
      </Flex>
    </UserPopover>
  );
};

export const userPopoverColDef: GridColDef<Review> = {
  field: "fullName" satisfies keyof Review,
  headerName: t("people-review-campaign.talentgrid.column.fullname", {
    defaultValue: "Collaborateur",
  }),
  hideable: false,
  renderCell: (params) => <UserPopoverCell params={params} />,
  width: 150,
};
