import React from "react";
import { useDropzone } from "react-dropzone";

import type { UserImportsRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildFileRequest } from "utils/buildRequest";

import styles from "./Dropzone.module.scss";
import InteractiveButton from "components/InteractiveButton";
import { FcFolder } from "react-icons/fc";
import { useState } from "react";
import useTranslation, { TranslationType } from "hooks/useTranslation";

interface DropzoneProps {
  refetch: () => void;
  canDropFile: boolean;
}

enum DropZoneState {
  IDLE = "idle",
  LOADING = "loading",
}

const DropZone = ({ refetch, canDropFile }: DropzoneProps) => {
  const [status, setStatus] = useState<DropZoneState>(DropZoneState.IDLE);
  const { t } = useTranslation();

  const onDrop = async (files: File[]) => {
    if (status === DropZoneState.LOADING) return;
    setStatus(DropZoneState.LOADING);

    try {
      const uploadRequest = buildFileRequest<UserImportsRoutes.UploadImportData>({
        method: "POST",
        path: "/userImports/upload",
        file: files[0],
      });

      await uploadRequest();

      Acta.dispatchEvent("sendAppMessage", {
        message: t("dropzone.import.fileSent", {
          defaultValue: "Fichier envoyé.",
        }),
        type: "success",
      });

      refetch();
      Acta.dispatchEvent("closeModal");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("dropzone.import.error", {
          defaultValue: "Une erreur est survenue lors de l’envoi de votre fichier.",
        }),
        type: "error",
      });
    } finally {
      // setIsLoading(false);
      setStatus(DropZoneState.IDLE);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  return (
    <div className={styles.dropZone}>
      <div {...getRootProps({ disabled: !canDropFile })}>
        <input aria-label="drop-fichier" {...getInputProps({ disabled: !canDropFile })} />
        {canDropFile || status === DropZoneState.LOADING ? (
          <DropFilePanel t={t} />
        ) : (
          <CannotDropFilePanel t={t} />
        )}
      </div>
    </div>
  );
};

const DropFilePanel = ({ t }: { t: TranslationType }) => (
  <>
    <div className={styles.icon}>
      <FcFolder size={50} />
    </div>
    <div aria-label="Téléchargement" className={styles.description}>
      <p>
        {t("dropzone.import.dragAndDropHere", {
          defaultValue: "Glissez / Déposez un fichier ici",
        })}
      </p>
      <p>
        {t("dropzone.import.or", {
          defaultValue: "ou",
        })}
      </p>
    </div>

    <InteractiveButton
      className={styles.addFileButton}
      label={t("dropzone.import.browse", {
        defaultValue: "Parcourir",
      })}
      aria-label={t("dropzone.import.browse", {
        defaultValue: "Parcourir",
      })}
      size="small"
    />
  </>
);

const CannotDropFilePanel = ({ t }: { t: TranslationType }) => (
  <>
    <div
      aria-label={t("dropzone.import.cannotDropFileLabel", {
        defaultValue: "Envoi impossible",
      })}
      className={styles.cannotDropFile}
    >
      {t("dropzone.import.cannotDropFile", {
        defaultValue:
          "Une base est en attente de validation. Veuillez l’annuler pour en importer une nouvelle.",
      })}
    </div>
  </>
);

export default DropZone;
