import { MdEvent as Event } from "react-icons/md";
import { MdExpandMore as ExpandMore } from "react-icons/md";
import { MdExpandLess as ExpandLess } from "react-icons/md";
import { MdEventBusy as EventBusy } from "react-icons/md";
import { MdHistory as History } from "react-icons/md";
import { MdAttachFile as AttachFile } from "react-icons/md";
import { MdOutlineFileDownload as FileDownload } from "react-icons/md";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { last } from "lodash";
import cx from "classnames";
import { FormatDate, DateTime, Locales, DateTimeFormatOptions } from "@skillup/shared-utils";

import {
  DSButton,
  DSDropdown,
  DSDropdownItem,
  DSModal,
  DSTooltip,
  Flex,
  Label as DSLabel,
  DSAlertType,
  DSAlertDisplay,
  DSAlert,
} from "@skillup/ui";

import downloadFileAsUser, { type MimeType } from "utils/downloadFileAsUser";

import { Project } from "../../Actions/getProjects";

import styles from "./UserSummonedModal.module.scss";
import _ from "lodash";
import useTranslation from "hooks/useTranslation";

export interface Props {
  summon: Project["summon"];
  training: Project["training"];
  onClose: () => void;
  isOpen: boolean;
  sessionId: string;
}

type SummonEvent = Project["summon"]["summonEvents"][number];
type UrlAttachments = SummonEvent["mailData"]["urlAttachments"];

export default ({ summon, training, onClose, isOpen, sessionId }: Props) => {
  const { t } = useTranslation();
  const [selectedSummonEvent, setSelectedSummonEvent] = useState<SummonEvent>(
    summon.summonEvents[0]
  );

  useEffect(() => {
    setSelectedSummonEvent(summon.summonEvents[0]);
  }, [summon]);

  const [downloadingSummonEvent, setDownloadingSummonEvent] = useState<number>(null);

  return (
    <DSModal isOpen={isOpen} className={styles.modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.view.session_summon_history.title", {
            defaultValue: "Formation {{trainingName}} par {{trainingOrganization}}",
            trainingName: training.name,
            trainingOrganization: training.organization,
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex className={styles.modalContent}>
          {summon.summonEvents.length === 0 ? (
            <DSLabel
              label={t("trainings.view.session_summon_history.no_summons", {
                defaultValue: "Aucune convocation envoyée",
              })}
              className={styles.mildLabel}
            />
          ) : (
            <>
              <Flex className={styles.leftPanel} column>
                {!!summon && (
                  <>
                    <DSLabel
                      label={t("trainings.view.session_summon_history.sent_summons", {
                        defaultValue: "Convocations envoyées",
                      })}
                      className={styles.label}
                    />
                    <SummonEventsList
                      summonEvents={summon.summonEvents}
                      selectedSummonEvent={selectedSummonEvent}
                      setSelectedSummonEvent={setSelectedSummonEvent}
                    />
                  </>
                )}
              </Flex>
              {selectedSummonEvent && (
                <Flex className={styles.rightPanel} column>
                  <div>
                    <SummonEventMetadata summonEvent={selectedSummonEvent} />
                    <Email
                      email={selectedSummonEvent.mailData?.content}
                      title={selectedSummonEvent.mailData?.subject}
                    />
                  </div>
                  {(selectedSummonEvent.mailOptions?.withCalendarInvitations ||
                    selectedSummonEvent.mailData?.urlAttachments?.length > 0) && (
                    <Flex className={styles.emailAttachments}>
                      <CalendarEvents summonEvent={selectedSummonEvent} />
                      <Files files={selectedSummonEvent.mailData?.urlAttachments} />
                    </Flex>
                  )}
                </Flex>
              )}
            </>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.action.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          label={t("trainings.view.session_summon_history.action.download_summon", {
            defaultValue: "Télécharger la convocation",
          })}
          disabled={!selectedSummonEvent}
          onClick={() =>
            downloadSummons({
              downloadingSummonEvent,
              setDownloadingSummonEvent,
              summonId: summon.id,
              selectedSummonEvent,
              sessionId,
            })
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
};

enum TooltipLabel {
  SUMMON = "Cet e-mail contient une invitation agenda",
  CANCEL = "Invation agenda annulée",
  PARTIAL_CANCEL = "Invitation agenda partiellement annulée",
  RESUMMON = "Invitation agenda mise à jour",
}

enum SummonEventType {
  SUMMON = "Convocation",
  CANCEL = "Mail d'annulation",
  RESUMMON = "Reconvocation",
  PARTIAL_CANCEL = "Annulation partielle",
}

const SummonEventCard = ({ summonEvent }: { summonEvent: SummonEvent }) => {
  const { t, i18n } = useTranslation();

  const { label, icon, tooltip } = useMemo(() => {
    switch (summonEvent.type) {
      case "create":
      case "partial-add":
        return {
          tooltip: t(`trainings.view.session_summon_history.event.summon.tooltip`, {
            defaultValue: TooltipLabel.SUMMON,
          }),
          icon: <Event size="1.25rem" />,
          label: t(`trainings.view.session_summon_history.event.summon.label`, {
            defaultValue: SummonEventType.SUMMON,
          }),
        };
      case "cancel":
        return {
          tooltip: t(`trainings.view.session_summon_history.event.cancel.tooltip`, {
            defaultValue: TooltipLabel.CANCEL,
          }),
          icon: <EventBusy size="1.25rem" />,
          label: t(`trainings.view.session_summon_history.event.cancel.label`, {
            defaultValue: SummonEventType.CANCEL,
          }),
        };
      case "re-summon":
        return {
          tooltp: t(`trainings.view.session_summon_history.event.resummon.tooltip`, {
            defaultValue: TooltipLabel.RESUMMON,
          }),
          icon: <History size="1.25rem" />,
          label: t(`trainings.view.session_summon_history.event.resummon.label`, {
            defaultValue: SummonEventType.RESUMMON,
          }),
        };
      case "partial-cancel":
        return {
          tooltip: t(`trainings.view.session_summon_history.event.partial_cancel.tooltip`, {
            defaultValue: TooltipLabel.PARTIAL_CANCEL,
          }),
          icon: <EventBusy size="1.25rem" />,
          label: t(`trainings.view.session_summon_history.event.partial_cancel.label`, {
            defaultValue: SummonEventType.PARTIAL_CANCEL,
          }),
        };
      default:
        return {
          tooltip: t("trainings.view.session_summon_history.event.invalid.tooltip", {
            defaultValue: "Donnée invalide",
          }),
          icon: <></>,
          label: t("trainings.view.session_summon_history.event.invalid.label", {
            defaultValue: "Unknown-type",
          }),
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summonEvent.type]);

  const parsedDates = formatDate(summonEvent.createdAt, DateTime.DATE_FULL, i18n.language);
  const cardLabel = useMemo(() => {
    if (!summonEvent.mailData) {
      return {
        topLabel: parsedDates,
        middleLabel: label,
        bottomLabel: t("trainings.view.session_summon_history.mailed_attendees_number", {
          defaultValue: "{{count}} stagiaires",
          count: summonEvent.attendeesNotMailed.length ?? 0,
        }),
      };
    }

    return {
      topLabel: parsedDates,
      middleLabel: label,
      bottomLabel: t("trainings.view.session_summon_history.mailed_attendees_number", {
        defaultValue: "{{count}} stagiaires",
        count: summonEvent.sessionData.trainees.length ?? 0,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summonEvent, label, parsedDates]);

  return (
    <Flex>
      <DSTooltip
        label={tooltip}
        direction="top"
        className={styles.icon}
        disabled={!summonEvent.mailOptions?.withCalendarInvitations}
      >
        {icon}
      </DSTooltip>
      <div>
        <DSLabel label={cardLabel.topLabel} className={styles.mildLabel} />
        <DSLabel label={cardLabel.middleLabel} className={styles.endOfLine} />
        <DSLabel label={cardLabel.bottomLabel} className={styles.endOfLine} />
      </div>
    </Flex>
  );
};

type SummonsListProps = {
  summonEvents: SummonEvent[];
  selectedSummonEvent: SummonEvent;
  setSelectedSummonEvent: Dispatch<SetStateAction<SummonEvent>>;
};

const SummonEventsList = ({
  summonEvents,
  selectedSummonEvent,
  setSelectedSummonEvent,
}: SummonsListProps) => {
  return (
    <div className={styles.summonEventsList}>
      {summonEvents.map((summonEvent) => {
        return (
          <Flex
            className={cx(styles.filterCard, {
              [styles.filterCardAtive]: summonEvent === selectedSummonEvent,
            })}
            key={summonEvent.id}
            onClick={() => setSelectedSummonEvent(summonEvent)}
          >
            <SummonEventCard summonEvent={summonEvent} />
          </Flex>
        );
      })}
    </div>
  );
};

type UsersProps = {
  labels: string[];
  users: string[];
  maxStringLength: number;
  className?: string;
};

const Users = ({ labels, users, maxStringLength, className }: UsersProps) => {
  const [isFullListDisplayed, setIsFullListDisplayed] = useState(false);

  useEffect(() => {
    setIsFullListDisplayed(false);
  }, [users]);

  const allUsersString = users.map((trainee, index) => (index > 0 ? " " : "") + trainee).toString();

  const usersStringToDisplay = allUsersString.slice(0, maxStringLength);

  let numberOfUsersDisplayed = 0;
  for (const letter of usersStringToDisplay) {
    if (letter === ",") numberOfUsersDisplayed++;
  }

  return (
    <div
      className={cx(className, {
        [styles.fullUsersList]: isFullListDisplayed,
      })}
    >
      <span className={styles.startOfLine}>{labels[0]}</span>
      <span className={styles.endOfLine}>
        {usersStringToDisplay}
        {usersStringToDisplay.length === maxStringLength && (
          <MoreUsers
            hiddenUsers={users.length - numberOfUsersDisplayed}
            remainingUsersString={allUsersString.slice(maxStringLength)}
            isFullListDisplayed={isFullListDisplayed}
            setIsFullListDisplayed={setIsFullListDisplayed}
          />
        )}
        {labels[1] !== undefined && <span className={styles.precision}>{labels[1]}</span>}
      </span>
    </div>
  );
};

type MoreUsersProps = {
  hiddenUsers: number;
  remainingUsersString: string;
  isFullListDisplayed: boolean;
  setIsFullListDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
};

const MoreUsers = ({
  hiddenUsers,
  remainingUsersString,
  isFullListDisplayed,
  setIsFullListDisplayed,
}: MoreUsersProps) => {
  const { t } = useTranslation();
  const preLabel = isFullListDisplayed ? `${remainingUsersString} ` : "... ";
  const label = isFullListDisplayed
    ? t("common.action.hide", { defaultValue: "Masquer" })
    : t("trainings.view.session_summon_history.action.more_users", {
        defaultValue: `et {{count}} autres`,
        count: hiddenUsers,
      });

  return (
    <span>
      {preLabel}
      <u
        className={styles.moreUsersLink}
        role="button"
        onClick={() => setIsFullListDisplayed(!isFullListDisplayed)}
      >
        {label}
      </u>
    </span>
  );
};

type SummonEventMetadataParams = {
  summonEvent: SummonEvent;
};

const SummonEventMetadata = ({ summonEvent }: SummonEventMetadataParams) => {
  const { t, i18n } = useTranslation();
  const summonEventUsersSummonedBySkillup = useMemo(() => {
    const attendeesCancelled = summonEvent?.sessionData.trainees.filter(
      (user) => !summonEvent.attendeesNotMailed.some((a) => a.email === user.email)
    );
    return attendeesCancelled.map((user) => user.fullName || user.email) ?? [];
  }, [summonEvent]);

  const summonEventUsersWithoutSkillup = useMemo(() => {
    return summonEvent.attendeesNotMailed.map((user) => user.fullName || user.email) ?? [];
  }, [summonEvent]);

  const summonEventTrainers = useMemo(
    () => summonEvent?.sessionData.trainers.map((trainer) => trainer.fullName) ?? [],
    [summonEvent]
  );

  const label = useMemo(() => {
    const parsedDate = formatDate(summonEvent.createdAt, DateTime.DATE_FULL, i18n.language);
    const parsedTime = formatDate(summonEvent.createdAt, DateTime.TIME_24_SIMPLE, i18n.language);
    const commonEndSentence = t(
      "trainings.view.session_summon_history.metadata.common_end_sentence",
      {
        defaultValue: `le {{date}} à {{time}}`,
        date: parsedDate,
        time: parsedTime,
      }
    );

    switch (true) {
      case ["cancel", "partial-cancel"].includes(summonEvent.type):
        return (
          t("trainings.view.session_summon_history.metadata.status.cancel.label", {
            defaultValue: "Email d'annulation envoyé",
          }) +
          " " +
          commonEndSentence
        );
      case !summonEvent.mailData:
        return (
          t("trainings.view.session_summon_history.metadata.status.without_skillup.label", {
            defaultValue: "Convoqué hors skillup",
          }) +
          " " +
          commonEndSentence
        );
      default:
        return (
          t("trainings.view.session_summon_history.metadata.status.sent.label", {
            defaultValue: "Convocation envoyée",
          }) +
          " " +
          commonEndSentence
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summonEvent]);

  return (
    <div className={styles.metadata}>
      <DSLabel label={label} className={styles.label} />
      <div className={styles.users}>
        {(summonEventUsersSummonedBySkillup.length > 0 ||
          summonEventUsersWithoutSkillup.length > 0) && (
          <Users
            labels={[
              t("trainings.view.session_summon_history.sent_to.label", { defaultValue: "À : " }),
            ]}
            users={
              summonEventUsersSummonedBySkillup.length
                ? summonEventUsersSummonedBySkillup
                : summonEventUsersWithoutSkillup
            }
            maxStringLength={60}
            className={styles.customTextarea}
          />
        )}
        {!!summonEventTrainers?.length && (
          <Users
            labels={[
              t("trainings.view.session_summon_history.sent_to.label.prefix", {
                defaultValue: "Et : ",
              }),
              t("trainings.view.session_summon_history.sent_to.label", {
                defaultValue: " (formateurs)",
                count: summonEventTrainers.length ?? 0,
              }),
            ]}
            users={summonEventTrainers}
            maxStringLength={80}
            className={styles.customTextarea}
          />
        )}
        <NotNotifiedAlert summonEvent={summonEvent} />
      </div>
      {summonEvent.mailOptions?.sendSummaryToManagers && (
        <DSLabel
          label={t("trainings.view.session_summon_history.sent_to_managers.label", {
            defaultValue: "Les managers des stagiaires ont été informés par e-mail",
          })}
          className={styles.comment}
        />
      )}
    </div>
  );
};

type EmailParams = {
  email: string;
  title: string;
};

const Email = ({ email, title }: EmailParams) => {
  const { t } = useTranslation();

  if (!email) {
    return (
      <em>
        {t("trainings.view.session_summon_history.no_email_sent", {
          defaultValue: "Aucun email envoyé pour cette convocation hors Skillup",
        })}
      </em>
    );
  }

  email = email.replace(/\n/g, "").replace(/<br \/>/g, "\n");

  return (
    <div className={styles.email}>
      <DSLabel label={parsedTitle(title)} className={styles.label} />
      <div className={styles.body} dangerouslySetInnerHTML={{ __html: email }} />
    </div>
  );
};

const CalendarEvents = ({ summonEvent }: { summonEvent: SummonEvent }) => {
  const { t } = useTranslation();
  const { sessionData, dateIntervals } = summonEvent;
  const [isExpanded, setExpand] = useState(false);
  const parsedDates = useCalendarDates({ dates: dateIntervals });

  if (!dateIntervals.length) return null;

  if (isExpanded) {
    return (
      <Flex className={styles.calendarEventsExpanded} column>
        <Flex className={styles.upperPart}>
          <Flex>
            <Event size="1.5rem" />
            <DSLabel
              label={t("trainings.view.session_summon_history.schedule_invitation.label", {
                defaultValue: "Invitation agenda",
              })}
              className={styles.title}
            />
          </Flex>
          <Flex>
            <ExpandLess size="1.25rem" onClick={() => setExpand(false)} />
          </Flex>
        </Flex>
        <Flex className={styles.lowerPart} column>
          <InfoWithArray
            startOfLine={t("trainings.view.session_summon_history.dates.label", {
              defaultValue: "Dates : ",
              count: parsedDates.length,
            })}
            endOfLine={parsedDates}
          />
          <InfoWithString
            startOfLine={t("trainings.view.session_summon_history.city.label", {
              defaultValue: "Lieu : ",
            })}
            endOfLine={sessionData.city}
          />
          <InfoWithString
            startOfLine={t("trainings.view.session_summon_history.room.label", {
              defaultValue: "Salle : ",
            })}
            endOfLine={sessionData.room}
          />
          <InfoWithLink
            startOfLine={t("trainings.view.session_summon_history.link.label", {
              defaultValue: "Lien de connexion : ",
            })}
            endOfLine={sessionData.link}
          />
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex className={styles.calendarEvents} column>
      <Flex className={styles.upperPart}>
        <Flex>
          <Event size="1.5rem" />
          <DSLabel
            label={t("trainings.view.session_summon_history.schedule_invitation.label", {
              defaultValue: "Invitation agenda",
            })}
            className={styles.title}
          />
        </Flex>
        <Flex>
          <ExpandMore size="1.25rem" onClick={() => setExpand(true)} />
        </Flex>
      </Flex>
      <Flex className={styles.lowerPart}>
        <DSLabel
          className={styles.label}
          label={`${parsedDates[0].trim()} à ${sessionData.city}`
            .slice(0, 37)
            .concat(parsedDates[0].length > 38 ? "..." : "")}
        />
      </Flex>
    </Flex>
  );
};

function NotNotifiedAlert({ summonEvent }: { summonEvent: SummonEvent }) {
  const { t } = useTranslation();
  const attendeesNotMailed = summonEvent.attendeesNotMailed;

  if (!summonEvent.isSummonedBySkillup) {
    return (
      <DSAlert className={styles.alert} type={DSAlertType.INFO} display={DSAlertDisplay.INLINE}>
        <p>
          {t("trainings.view.session_summon_history.not_notified", {
            defaultValue:
              "Les personnes suivantes n'ont pas reçu de mail car elles ont été convoquées hors Skillup",
            count: attendeesNotMailed.length ?? 0,
          })}{" "}
          : <b>{attendeesNotMailed.map((t) => t.fullName ?? t.email).join(", ")}</b>
        </p>
      </DSAlert>
    );
  }

  const noEmailAttendees = attendeesNotMailed.filter((attendee) => attendee.reason === "no-email");
  const attendeesSummonedOutsideSkillup = attendeesNotMailed.filter(
    (attendee) => attendee.reason === "not-summoned-by-skillup"
  );
  const companyDisabledEmailsAttendees = attendeesNotMailed.filter(
    (attendee) => attendee.reason === "company-disabled-emails"
  );

  const rest = attendeesNotMailed.filter(
    (attendee) => !attendee.reason || attendee.reason === "unexpected-reason"
  );

  if (
    !noEmailAttendees.length &&
    !attendeesSummonedOutsideSkillup.length &&
    !rest.length &&
    !companyDisabledEmailsAttendees.length
  ) {
    return <></>;
  }

  return (
    <>
      <DSAlert className={styles.alert} type={DSAlertType.INFO} display={DSAlertDisplay.INLINE}>
        {noEmailAttendees.length > 0 && (
          <p>
            {t("trainings.view.session_summon_history.not_notified_no_email", {
              defaultValue:
                "Les personnes suivantes n'ont pas reçu d'email car elles n'ont pas d'adresses email rattachées",
              count: noEmailAttendees.length ?? 0,
            })}{" "}
            : <b>{noEmailAttendees.map((t) => t.fullName ?? t.email).join(", ")}</b>
          </p>
        )}
        {companyDisabledEmailsAttendees.length > 0 && (
          <p>
            {noEmailAttendees.length > 0 && <br />}
            {t("trainings.view.session_summon_history.not_notified_company_disabled_email", {
              defaultValue:
                "Les personnes suivantes n'ont pas reçu d'email car votre paramétrage d'entreprise a désactivé leur envoi",
              count: companyDisabledEmailsAttendees.length,
            })}{" "}
            : <b>{companyDisabledEmailsAttendees.map((t) => t.fullName ?? t.email).join(", ")}</b>
          </p>
        )}
        {attendeesSummonedOutsideSkillup.length > 0 && (
          <p>
            {(noEmailAttendees.length > 0 || companyDisabledEmailsAttendees.length > 0) && <br />}
            {t("trainings.view.session_summon_history.not_notified_summoned_outside_skillup", {
              defaultValue:
                "Les personnes suivantes n'ont pas reçu d'email car elles ont été convoquées hors Skillup",
              count: attendeesSummonedOutsideSkillup.length,
            })}{" "}
            : <b>{attendeesSummonedOutsideSkillup.map((t) => t.fullName ?? t.email).join(", ")}</b>
          </p>
        )}
        {rest.length > 0 && (
          <p>
            {(noEmailAttendees.length > 0 ||
              companyDisabledEmailsAttendees.length > 0 ||
              attendeesSummonedOutsideSkillup.length > 0) && <br />}
            {t("trainings.view.session_summon_history.not_notified_unexpected_reason", {
              defaultValue: "Les personnes suivantes n'ont pas reçu d'email",
              count: rest.length,
            })}{" "}
            : <b>{rest.map((t) => t.fullName ?? t.email).join(", ")}</b>
          </p>
        )}
      </DSAlert>
    </>
  );
}

function formatDate(date: string, format: DateTimeFormatOptions, locale: Locales) {
  return `${FormatDate.toLocaleString(
    DateTime.fromISO(date, {
      locale: "fr",
      zone: "utc",
    }),
    format,
    locale
  )}`;
}

const useCalendarDates = ({ dates }: { dates: Array<string> }) => {
  const { i18n } = useTranslation();

  return dates
    .map((date) => date.split("/"))
    .flatMap((date) => {
      const startDate = formatDate(date[0], DateTime.DATE_FULL, i18n.language);
      const startHour = formatDate(date[0], DateTime.TIME_24_SIMPLE, i18n.language);
      if (!date[1]) return startDate;

      const endDate = formatDate(date[1], DateTime.DATE_FULL, i18n.language);
      const endHour = formatDate(date[1], DateTime.TIME_24_SIMPLE, i18n.language);

      const formattedDates = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
      const formattedHours = startHour === "00:00" ? "" : `${startHour} - ${endHour}`;
      return `${formattedDates} ${formattedHours}`;
    });
};

const Files = ({ files }: { files: UrlAttachments }) => {
  const { t } = useTranslation();
  const [downloadingAttachments, setDownloadingAttachments] = useState<Record<string, boolean>>({});
  const downloadParams = (attachment: UrlAttachments[number], openFileInNewTab: boolean) => ({
    downloadingAttachments,
    setDownloadingAttachments,
    attachment,
    openFileInNewTab,
  });

  return (
    <>
      {files?.map((attachment, index) => (
        <Flex className={styles.files} key={index}>
          <Flex>
            <AttachFile size="1.5rem" />
            <DSTooltip label={attachment.filename} direction="top">
              <DSLabel label={attachment.filename} className={styles.title} />
            </DSTooltip>
          </Flex>
          {documentExtMapRecord(last(attachment.filename.split(".")) as DocumentExt) ? (
            <DSDropdown className={styles.dropdown}>
              <DSDropdownItem
                label={t("trainings.view.session_summon_history.attachment.action.display", {
                  defaultValue: "Aperçu",
                })}
                onClick={() => downloadAttachment(downloadParams(attachment, true))}
              />
              <DSDropdownItem
                label={t("trainings.view.session_summon_history.attachment.action.download", {
                  defaultValue: "Télécharger",
                })}
                onClick={() => downloadAttachment(downloadParams(attachment, false))}
              />
            </DSDropdown>
          ) : (
            <DSButton
              label="Télécharger"
              emphasis="Low"
              icon={<FileDownload />}
              iconOnly
              onClick={() => downloadAttachment(downloadParams(attachment, false))}
            />
          )}
        </Flex>
      ))}
    </>
  );
};

const Info = ({ startOfLine, children }: { startOfLine: string; children: ReactNode }) => {
  return (
    <Flex>
      <DSLabel label={startOfLine} className={styles.startOfLine} />
      &nbsp;
      {children}
    </Flex>
  );
};

const InfoWithString = ({ startOfLine, endOfLine }: { startOfLine: string; endOfLine: string }) => {
  if (!endOfLine) return null;
  return (
    <Info startOfLine={startOfLine}>
      <DSLabel label={endOfLine} className={styles.endOfLine} />
    </Info>
  );
};

type InfoWithArrayParams = {
  startOfLine: string;
  endOfLine?: string[];
};

const InfoWithArray = ({ startOfLine, endOfLine }: InfoWithArrayParams) => {
  if (!endOfLine) return null;
  return (
    <Info startOfLine={startOfLine}>
      <ol>
        {endOfLine.map((elem, index) => (
          <li key={index}>{elem.slice(0, 35).concat(elem.length > 36 ? "..." : "")}</li>
        ))}
      </ol>
    </Info>
  );
};

const InfoWithLink = ({ startOfLine, endOfLine }: { startOfLine: string; endOfLine: string }) => {
  if (!endOfLine) return null;
  return (
    <Info startOfLine={startOfLine}>
      <a href={endOfLine} rel="noopener noreferrer" target="_blank" className={styles.endOfLine}>
        {endOfLine}
      </a>
    </Info>
  );
};

type DownloadSummonsParams = {
  summonId: number;
  selectedSummonEvent: SummonEvent;
  downloadingSummonEvent: number;
  sessionId: string;
  setDownloadingSummonEvent: Dispatch<SetStateAction<number>>;
};

async function downloadSummons({
  summonId,
  selectedSummonEvent,
  downloadingSummonEvent,
  sessionId,
  setDownloadingSummonEvent,
}: DownloadSummonsParams) {
  if (downloadingSummonEvent === selectedSummonEvent.id) return;

  setDownloadingSummonEvent(selectedSummonEvent.id);
  const url = `/v1/summons/${summonId}/${selectedSummonEvent.id}/${sessionId}/downloadV2`;

  const firstWord = selectedSummonEvent.mailData.subject.split(" ")[0];
  const date = FormatDate.ToISOShort(DateTime.fromISO(selectedSummonEvent.createdAt));

  await downloadFileAsUser(url, `${firstWord}-${sessionId}__${date}.zip`, "API")
    .catch(console.error)
    .finally(() => setDownloadingSummonEvent(null));
}

type DownloadAttachmentParams = {
  downloadingAttachments: Record<string, boolean>;
  setDownloadingAttachments: Dispatch<SetStateAction<Record<string, boolean>>>;
  attachment: UrlAttachments[0];
  openFileInNewTab: boolean;
};

async function downloadAttachment({
  downloadingAttachments,
  setDownloadingAttachments,
  attachment,
  openFileInNewTab,
}: DownloadAttachmentParams) {
  if (downloadingAttachments[attachment.uuid]) {
    return;
  }
  const mimeType = documentExtMapRecord(last(attachment.filename.split(".")));

  setDownloadingAttachments({
    ...downloadingAttachments,
    [attachment.uuid]: true,
  });
  const url = `/v1/files/${attachment.uuid}/download`;
  await downloadFileAsUser(
    url,
    attachment.filename || "Untitled",
    "API",
    mimeType,
    openFileInNewTab
  )
    .catch(console.error)
    .finally(() =>
      setDownloadingAttachments({
        ...downloadingAttachments,
        [attachment.uuid]: false,
      })
    );
}

const documentExtMapRecord = (value: string): MimeType | undefined => {
  const ExtMapToMimes: Record<DocumentExt, string> = {
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    pdf: "application/pdf",
  };

  const result: MimeType | undefined = ExtMapToMimes[value];
  return result;
};
type DocumentExt = "pdf" | "png" | "jpeg" | "jpg";

function parsedTitle(title: string) {
  return title.replace(/\b[A-Z]+\b/g, (match) => _.startCase(_.toLower(match)));
}
