import React, { useState } from "react";
import useTranslation from "hooks/useTranslation";

import { DateTime } from "@skillup/shared-utils";
import { DSAlert, DSAlertDisplay, DSAlertType, DSModal, DSRadio, DSRadioGroup } from "@skillup/ui";
import { type Project } from "services/sessions";

import styles from "./ChooseSummonTypeModal.module.scss";

enum SummonType {
  WITHSKILLUP = "withSkillup",
  WITHOUTSKILLUP = "withoutSkillup",
}

export function ChooseSummonTypeModal({
  session,
  onClose,
  summonViaSkillup,
  selfSummon,
}: {
  session: Project;
  onClose: () => void;
  summonViaSkillup: () => void;
  selfSummon: () => void;
}) {
  const { t } = useTranslation();

  const [summonType, selectSummonType] = useState<SummonType>();
  const endDatePlusOneDay = DateTime.fromISO(session.properties.endDate).startOf("day");
  const today = DateTime.local().startOf("day");

  const hasEndDateExpired = endDatePlusOneDay < today;

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.sessions.summontype.title", {
            defaultValue: "Convocation de stagiaire(s)",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.ChooseSummonTypeModal}>
          {hasEndDateExpired && (
            <DSAlert
              type={DSAlertType.WARNING}
              display={DSAlertDisplay.INLINE}
              className={styles.warning}
            >
              {t("trainings.sessions.summontype.warning", {
                defaultValue:
                  "La session est terminée.\nIl n’est donc plus possible de convoquer via Skillup.",
              })}
            </DSAlert>
          )}
          <div className={styles.radios}>
            <DSRadioGroup name="summonType">
              <DSRadio
                disabled={hasEndDateExpired}
                label={t("trainings.sessions.summontype.withskillup.label", {
                  defaultValue: "Convoquer la session via Skillup",
                })}
                checked={summonType === SummonType.WITHSKILLUP}
                helperText={t("trainings.sessions.summontype.withskillup.helptext", {
                  defaultValue: "Envoyer un e-mail aux stagiaires via Skillup.",
                })}
                onChange={() => selectSummonType(SummonType.WITHSKILLUP)}
              />
              <DSRadio
                label={t("trainings.sessions.summontype.withoutskillup.label", {
                  defaultValue: "Convoquer la session hors Skillup",
                })}
                checked={summonType === SummonType.WITHOUTSKILLUP}
                helperText={t("trainings.sessions.summontype.withoutskillup.helptext", {
                  defaultValue:
                    'La session passera au statut "Convoquée" mais aucune notification ne sera envoyée aux stagiaires par Skillup.',
                })}
                onChange={() => selectSummonType(SummonType.WITHOUTSKILLUP)}
              />
            </DSRadioGroup>
          </div>
        </div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          label={t("app.modal.action.continue", {
            defaultValue: "Continuer",
          })}
          disabled={!summonType}
          onClick={() =>
            summonType === SummonType.WITHSKILLUP ? summonViaSkillup() : selfSummon()
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
}
