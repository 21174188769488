import { MdClose as Close } from "react-icons/md";
import { MdSearch as Search } from "react-icons/md";
import { MdContentCopy as ContentCopy } from "react-icons/md";
import { useState, useMemo } from "react";
import cx from "classnames";
import { useToasts } from "react-toast-notifications";

import {
  Checkbox,
  DSButton,
  DSModal,
  DSTextInput,
  DSUserPreview,
  Flex,
  HorizontalDivider,
  DSTooltip,
} from "@skillup/ui";
import { TrainingUtils } from "@skillup/training-bridge";

import { getUserInitials } from "utils/User";
import useTranslation from "hooks/useTranslation";

import { TraineesList, Status, Project, Trainee } from "./types";

import styles from "./ParticipantsModal.module.scss";

type Props = {
  project: Project;
  onSubmit: (participants: Trainee[]) => void;
  onClose: () => void;
};

const ParticipantsModal = ({ project, onSubmit, onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();

  const { city: projectCity, dates } = project.properties;
  const { name: projectName } = project.training;
  const { positionnedTrainees, summonedTrainees } = project;

  const dateIntervals = useMemo(
    () =>
      TrainingUtils.ScheduleRow.formatIntervalsForDisplay(dates, {
        t,
        language: i18n.language,
      }),
    [dates, t, i18n.language]
  );

  const positionnedTraineesUuids = useMemo(
    () => positionnedTrainees.map((trainee) => trainee.uuid),
    [positionnedTrainees]
  );
  const summonedTraineesUuids = useMemo(
    () => summonedTrainees.map((trainee) => trainee.uuid),
    [summonedTrainees]
  );

  const [searchedValue, setSearchedValue] = useState<string>();
  const [traineesList, setTraineesList] = useState<TraineesList>({
    trainees: {
      positionned: positionnedTraineesUuids,
      summoned: summonedTraineesUuids,
    },
    checkedTrainees: [],
    activeColumn: "both",
    columnChecked: false,
  });
  const numberOfSummonedTrainees = summonedTrainees.length;
  const numberOfPositionnedTrainees = positionnedTrainees.length;
  const isTraineeChecked = (uuid: string, status: Status): boolean | "intermediate" => {
    if (traineesList.activeColumn === "both") return false;
    if (traineesList.activeColumn !== status) return false;
    if (traineesList.checkedTrainees.includes(uuid)) return true;

    return false;
  };
  const handleTraineeCheckbox = (status: Status, uuid: string) => {
    if (status === Status.POSTITIONNED && traineesList.activeColumn === "summoned") return;
    if (status === Status.SUMMONED && traineesList.activeColumn === "positionned") return;
    setTraineesList((prevTraineesList) => {
      let actualCheckedTrainees: string[] = prevTraineesList.checkedTrainees;
      let checkedTrainees: string[] = prevTraineesList.checkedTrainees;
      const traineeIndex = actualCheckedTrainees.indexOf(uuid);
      if (traineeIndex > -1) {
        checkedTrainees.splice(traineeIndex, 1);
      } else {
        checkedTrainees.push(uuid);
      }
      const numberOfCheckedTrainees = checkedTrainees?.length ?? 0;
      if (numberOfCheckedTrainees === 0) {
        return {
          ...prevTraineesList,
          activeColumn: "both",
          columnChecked: false,
          checkedTrainees: [],
        };
      }
      if (numberOfCheckedTrainees === prevTraineesList.trainees[status].length) {
        return {
          ...prevTraineesList,
          activeColumn: status,
          columnChecked: true,
          checkedTrainees,
        };
      }
      if (numberOfCheckedTrainees < prevTraineesList.trainees[status].length) {
        return {
          ...prevTraineesList,
          activeColumn: status,
          columnChecked: "intermediate",
          checkedTrainees,
        };
      }
      throw new Error("Unable to update trainees list");
    });
  };

  const handleColumnCheckbox = (status: Status) => () => {
    const filteredTrainees =
      status === "summoned" ? filteredSummonedTrainees : filteredPositionnedTrainees;
    if (traineesList.activeColumn !== status && traineesList.activeColumn !== "both") return;
    if (status === "summoned" && numberOfSummonedTrainees === 0) return;
    if (status === "positionned" && numberOfPositionnedTrainees === 0) return;
    if (filteredTrainees) {
      setTraineesList((prevTraineesList) => {
        const updatedTraineesList = { ...prevTraineesList };

        // set columnChecked property
        if (prevTraineesList.columnChecked === "intermediate") {
          updatedTraineesList.columnChecked = false;
        } else if (filteredPositionnedTrainees.length < positionnedTrainees.length) {
          updatedTraineesList.columnChecked = "intermediate";
        } else {
          updatedTraineesList.columnChecked = !prevTraineesList.columnChecked;
        }

        // set activeColumn property
        updatedTraineesList.activeColumn = prevTraineesList.columnChecked ? "both" : status;

        // set checkedTrainees property
        if (prevTraineesList.columnChecked) {
          updatedTraineesList.checkedTrainees = [];
        } else if (!filteredTrainees) {
          updatedTraineesList.checkedTrainees = prevTraineesList.trainees[status];
        } else {
          updatedTraineesList.checkedTrainees = filteredTrainees.map((trainee) => trainee.uuid);
        }

        return updatedTraineesList;
      });
    }
  };

  const handleCopyParticipants = (status: Status) => () => {
    try {
      const trainees =
        status === "summoned" ? filteredSummonedTrainees : filteredPositionnedTrainees;
      const traineesData = trainees
        .map((trainee) => `${trainee.fullName ?? ""} ${trainee.email}`)
        .join("\n");

      navigator.clipboard.writeText(traineesData);

      addToast(
        t("trainings.manage.users.participants.copy.success", {
          defaultValue: "La liste des participants a été copiée.",
        }),
        { appearance: "success" }
      );
    } catch (e) {
      addToast(
        t("trainings.manage.users.participants.copy.error", {
          defaultValue: "La liste des participants n'a pas pu être copiée",
        }),
        { appearance: "error" }
      );
    }
  };

  const handleSubmitClick = () => {
    const uuids = traineesList.checkedTrainees;
    const status = traineesList.activeColumn;

    if (status === "both") return;
    if (uuids.length === 0) return;

    const trainees = uuids.map((uuid) => {
      const source = status === "summoned" ? project.summonedTrainees : project.positionnedTrainees;

      return source.find((trainee) => trainee.uuid === uuid);
    });

    onSubmit(trainees);
  };

  const filteredSummonedTrainees = useMemo(() => {
    if (!searchedValue) {
      return summonedTrainees;
    }

    return summonedTrainees.filter((trainee) =>
      trainee.fullName?.toLowerCase().includes(searchedValue?.toLowerCase())
    );
  }, [searchedValue, summonedTrainees]);
  const filteredPositionnedTrainees = useMemo(() => {
    if (!searchedValue) {
      return positionnedTrainees;
    }

    return positionnedTrainees.filter((trainee) =>
      trainee.fullName?.toLowerCase().includes(searchedValue?.toLowerCase())
    );
  }, [searchedValue, positionnedTrainees]);
  const tooltipColumnLabel = t("trainings.manage.users.participants.tooltip", {
    defaultValue:
      "Vous ne pouvez pas sélectionner à la fois des participants non convoqués et des participants convoqués.",
  });
  const tooltipSubmitLabel = t("trainings.manage.users.participants.tooltip.submit", {
    defaultValue: "Veuillez sélectionner au moins un collaborateur à désinscrire",
  });
  return (
    <DSModal isOpen className={styles.Modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.manage.users.participants.title", { defaultValue: "Participants" })}
        />
        <DSModal.Header.SubHeader>
          <Flex column>
            <p className={styles.SubHeader}>
              {[
                projectName,
                t("trainings.manage.users.participants.subtitle.city", {
                  defaultValue: "à {{projectCity}}",
                  projectName,
                  projectCity,
                }),
                ...dateIntervals,
              ].join(" - ")}
            </p>
          </Flex>
        </DSModal.Header.SubHeader>
      </DSModal.Header>
      <DSModal.Content className={styles.ContentModal}>
        {(numberOfPositionnedTrainees > 10 || numberOfSummonedTrainees > 10) && (
          <DSTextInput
            name="searchTrainees"
            value={searchedValue ?? ""}
            className={styles.searchInput}
            placeholder={t("trainings.manage.users.participants.search", {
              defaultValue: "Chercher un collaborateur",
            })}
            iconRight={
              <>
                {searchedValue && (
                  <DSButton iconOnly icon={<Close />} onClick={() => setSearchedValue(undefined)} />
                )}
                <Search />
              </>
            }
            onChange={(value) => setSearchedValue(value)}
          />
        )}
        <Flex column>
          <Flex row className={styles.contentColumns}>
            <Flex column className={styles.column}>
              <Flex
                className={cx(styles.columnTitle, {
                  [styles.disabled]: traineesList.activeColumn === "summoned",
                })}
              >
                <Checkbox
                  value="all-positionned"
                  checked={
                    traineesList.activeColumn === "positionned" ? traineesList.columnChecked : false
                  }
                  onClick={handleColumnCheckbox(Status.POSTITIONNED)}
                />
                <span>
                  {searchedValue
                    ? t("trainings.manage.list.title.searched.positionned", {
                        defaultValue: "Inscrits non convoqués ({{count}} sur {{total}})",
                        count: filteredPositionnedTrainees.length,
                        total: positionnedTrainees.length,
                      })
                    : t("trainings.manage.list.title.positionned", {
                        defaultValue: "Inscrits non convoqués ({{count}})",
                        count: positionnedTrainees.length,
                      })}
                </span>
                <DSButton
                  className={styles.copyButton}
                  onClick={handleCopyParticipants(Status.POSTITIONNED)}
                  iconOnly
                  icon={<ContentCopy />}
                  buttonSize="S"
                  emphasis="Low"
                  tooltip={t("trainings.manage.users.positionned.copy", {
                    defaultValue: "Copier la liste des inscrits non convoqués",
                  })}
                />
              </Flex>
              <HorizontalDivider top="xs" bottom="s" />
              <Flex column>
                {filteredPositionnedTrainees.map((trainee) => (
                  <DSTooltip
                    label={tooltipColumnLabel}
                    disabled={traineesList.activeColumn !== "summoned"}
                  >
                    <Flex
                      key={trainee.uuid}
                      className={cx(styles.columnItem, {
                        [styles.disabledItem]: traineesList.activeColumn === "summoned",
                        [styles.checkedItem]: isTraineeChecked(trainee.uuid, Status.POSTITIONNED),
                      })}
                      onClick={() => handleTraineeCheckbox(Status.POSTITIONNED, trainee.uuid)}
                    >
                      <Checkbox
                        value={trainee.uuid}
                        checked={isTraineeChecked(trainee.uuid, Status.POSTITIONNED)}
                      />
                      <DSUserPreview
                        size="M"
                        fullName={trainee.fullName}
                        propertyLabel={trainee.email ?? ""}
                        initials={getUserInitials(trainee)}
                        randomColorSeedString={trainee.fullName ?? ""}
                      />
                    </Flex>
                  </DSTooltip>
                ))}
              </Flex>
            </Flex>
            <Flex column className={styles.column}>
              <Flex
                className={cx(styles.columnTitle, {
                  [styles.disabled]: traineesList.activeColumn === "positionned",
                })}
              >
                <Checkbox
                  value="all-summoned"
                  checked={
                    traineesList.activeColumn === "summoned" ? traineesList.columnChecked : false
                  }
                  onClick={handleColumnCheckbox(Status.SUMMONED)}
                />
                <span>
                  {searchedValue
                    ? t("trainings.manage.list.title.searched.summoned", {
                        defaultValue: "Convoqués ({{count}} sur {{total}})",
                        count: filteredSummonedTrainees.length,
                        total: summonedTrainees.length,
                      })
                    : t("trainings.manage.list.title.summoned", {
                        defaultValue: "Convoqués ({{count}})",
                        count: summonedTrainees.length,
                      })}
                </span>
                <DSButton
                  className={styles.copyButton}
                  onClick={handleCopyParticipants(Status.SUMMONED)}
                  iconOnly
                  icon={<ContentCopy />}
                  buttonSize="S"
                  emphasis="Low"
                  tooltip={t("trainings.manage.users.summoned.copy", {
                    defaultValue: "Copier la liste des convoqués",
                  })}
                />
              </Flex>
              <HorizontalDivider top="xs" bottom="s" />
              <Flex column>
                {filteredSummonedTrainees.map((trainee) => (
                  <DSTooltip
                    label={tooltipColumnLabel}
                    disabled={traineesList.activeColumn !== "positionned"}
                  >
                    <Flex
                      key={trainee.uuid}
                      className={cx(styles.columnItem, {
                        [styles.disabledItem]: traineesList.activeColumn === "positionned",
                        [styles.checkedItem]: isTraineeChecked(trainee.uuid, Status.SUMMONED),
                      })}
                      onClick={() => handleTraineeCheckbox(Status.SUMMONED, trainee.uuid)}
                    >
                      <Checkbox
                        value={trainee.uuid}
                        checked={isTraineeChecked(trainee.uuid, Status.SUMMONED)}
                      />
                      <DSUserPreview
                        size="M"
                        fullName={trainee.fullName}
                        propertyLabel={trainee.email ?? ""}
                        initials={getUserInitials(trainee)}
                        randomColorSeedString={trainee.fullName ?? ""}
                      />
                    </Flex>
                  </DSTooltip>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("trainings.manage.users.form.cancel", { defaultValue: "Annuler" })}
        />
        <DSTooltip label={tooltipSubmitLabel} disabled={traineesList.activeColumn !== "both"}>
          <DSModal.Footer.PrimaryButton
            onClick={handleSubmitClick}
            disabled={traineesList.activeColumn === "both"}
            label={
              traineesList.checkedTrainees.length > 0
                ? t("trainings.manage.users.form.unsubscribe", {
                    count: traineesList.checkedTrainees.length,
                    defaultValue: "Désinscrire {{count}} stagiaire",
                  })
                : t("trainings.manage.users.form.unsubscribe_disable", {
                    defaultValue: "Désinscrire",
                  })
            }
          />
        </DSTooltip>
      </DSModal.Footer>
    </DSModal>
  );
};

export default ParticipantsModal;
