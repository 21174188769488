import { isNil } from "lodash";
import type { InterviewState } from "@skillup/espace-rh-bridge";
import { DSAlert, DSAlertDisplay, DSAlertType, DSCard, Switch } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import styles from "./Steps.module.scss";

interface IProps {
  readonly hideFromEmployeeUntil?: { managerState: InterviewState };
  readonly setHideFromEmployeeUntil: (hideFromEmployeeUntil?: {
    managerState: InterviewState;
  }) => void;
  readonly managerOnly: boolean;
  readonly setManagerOnly: (managerOnly: boolean) => void;
  readonly confidential: boolean;
  readonly setConfidentiality: (confidential: boolean) => void;
}

const OptionsStep = (props: IProps) => {
  const {
    hideFromEmployeeUntil,
    setHideFromEmployeeUntil,
    managerOnly,
    setManagerOnly,
    confidential,
    setConfidentiality,
  } = props;

  const { t } = useTranslation();
  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.OptionsStep} showTitle={false}>
        <div className={styles.optionsGroup}>
          <div className={styles.groupTitle}>
            <span>
              {t("interviews.campaigncreation.options", {
                defaultValue: "Options de workflow",
              })}
            </span>
          </div>
          <div className={styles.option}>
            <Switch
              active={!!managerOnly}
              onToggle={(checked) => {
                setManagerOnly(checked);
                setHideFromEmployeeUntil(undefined);
              }}
              label={t("interviews.campaigncreation.options.managerOnly", {
                defaultValue: "Entretiens gérés unilatéralement par les responsables",
              })}
              className={styles.switch}
              labelClassName={styles.labelSwitch}
            />
            <p className={styles.description}>
              {t("interviews.campaigncreation.options.managerOnly.description", {
                defaultValue:
                  "En activant cette option, seuls les responsables de l’entretien pourront répondre aux questions de l’entretien. Cette option est intéressante dans le cadre de campagnes d’entretien réalisées avec des collaborateurs sans accès à Skillup et ne nécessitant pas d’auto-évaluation.",
              })}
            </p>
          </div>
          <div className={styles.option}>
            <Switch
              active={!isNil(hideFromEmployeeUntil)}
              onToggle={(checked) =>
                setHideFromEmployeeUntil(checked ? { managerState: "shared" } : undefined)
              }
              label={t("interviews.campaigncreation.options.hideFromEmployeeUntil", {
                defaultValue: "Collaborateur impliqué après le partage des responsables",
              })}
              className={styles.switch}
              labelClassName={styles.labelSwitch}
              disabled={managerOnly}
            />
            <p className={styles.description}>
              {t("interviews.campaigncreation.options.hideFromEmployeeUntil.description", {
                defaultValue:
                  "Les collaborateurs auront accès à l’entretien après le partage du responsable.",
              })}
            </p>
          </div>
        </div>
        <div className={styles.confidentialGroup}>
          <div className={styles.groupTitle}>
            {t("interviews.campaigncreation.options.confidential", {
              defaultValue: "Confidentialité de la campagne",
            })}
            <span>Confidentialité de la campagne</span>
          </div>
          <div className={styles.option}>
            <Switch
              active={confidential}
              onToggle={(checked) => setConfidentiality(checked)}
              label={t("interviews.campaigncreation.options.confidential.label", {
                defaultValue: "Cette campagne est confidentielle",
              })}
              className={styles.switch}
              labelClassName={styles.labelSwitch}
            />
            <p className={styles.description}>
              {t("interviews.campaigncreation.options.confidential.description", {
                defaultValue:
                  "Les entretiens de cette campagne ne pourront être consultés que par les collaborateurs et leurs responsables d’entretien. Les futurs managers et la hiérarchie du collaborateur ne pourront pas les consulter.",
              })}
            </p>
          </div>
        </div>
        {confidential && (
          <div className={styles.OptionsStepWarning}>
            <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
              {t("interviews.campaigncreation.options.confidential.warning", {
                defaultValue:
                  "Attention : une fois la campagne lancée, ce paramètre ne pourra plus être modifié par la suite.",
              })}
            </DSAlert>
          </div>
        )}
      </DSCard>
    </div>
  );
};

export default OptionsStep;
