import { DSModal } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import useCampaignFeedbackForm from "services/peopleReview/campaign/useCampaignFeedbackForm";

import { Campaign } from "../../types";
import { CampaignFeedbackForm } from "../CampaignFeedbackForm/CampaignFeedbackForm";

interface CampaignFeedbackModalProps {
  campaign: Campaign;
  onClose: () => void;
}

export function CampaignFeedbackModal({ campaign, onClose }: CampaignFeedbackModalProps) {
  const { t } = useTranslation();

  const {
    handleChange,
    handleSubmit,
    isCommentSharedToManager,
    isMutationLoading,
    sharedToManagerActionIds,
    sharedToManagerCoreHRScaleIDs,
  } = useCampaignFeedbackForm({
    campaignID: campaign.id,
    onMutationSuccess: onClose,
  });

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.campaign.feedbackSettingsModal.title", {
            defaultValue: "Configurer les éléments partagés lors de la clôture",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex paddingBottom="s" flexDirection="column">
          <CampaignFeedbackForm
            campaign={campaign}
            onChange={handleChange}
            data={{
              isCommentSharedToManager,
              sharedToManagerActionIds,
              sharedToManagerCoreHRScaleIDs,
            }}
          />
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          loading={isMutationLoading}
          label={t("peopleReview.campaign.feedbackSettingsModal.save", {
            defaultValue: "Enregistrer les modifications",
          })}
          onClick={() =>
            handleSubmit({
              isCommentSharedToManager,
              sharedToManagerActionIds,
              sharedToManagerCoreHRScaleIDs,
            })
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
}
