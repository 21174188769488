import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { useMemo, useState, useEffect, useCallback } from "react";

import cx from "classnames";

import { useQueryClient } from "@tanstack/react-query";
import {
  GridRowId,
  useGridApiRef,
  GridRowParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import { ReviewRoutes } from "@skillup/people-review-bridge";
import { PeopleReviewRoutes } from "@skillup/espace-rh-bridge";
import {
  Link,
  Label,
  Select,
  DSAlert,
  DSModal,
  FileDrop,
  DSDataGrid,
  DSAlertType,
  DSAlertDisplay,
  HorizontalDivider,
} from "@skillup/ui";

import { useUsers } from "services/users";
import useTranslation from "hooks/useTranslation";
import { buildRequest, buildFileRequest } from "utils/buildRequest";

import { Campaign } from "../../types";
import { CollaboratorOwnManagerWarningAlert } from "../CollaboratorOwnManagerWarningAlert";

import styles from "./AddEmployeeToCampaignModal.module.scss";

// eslint-disable-next-line perfectionist/sort-union-types
type Step = "bulk-or-single" | "single-select-manager" | "bulk-select";

type EmployeeAndTheirManager = {
  userID: string;
  managerID?: string;
  managerEmail?: string;
  employeeEmail?: string;
  managerFullName?: string;
  employeeFullName: string;
  isCollaboratorOwnManager: boolean;
};

// MARK: AddEmployeeToCampaignModal
type AddEmployeeToCampaignModalProps = {
  campaign: Campaign;
  onClose: () => void;
};

export function AddEmployeeToCampaignModal({ campaign, onClose }: AddEmployeeToCampaignModalProps) {
  const { t } = useTranslation();
  const [employeesAndTheirManager, setEmployeesAndTheirManager] = useState<
    EmployeeAndTheirManager[]
  >([]);
  const [step, setStep] = useState<Step>("bulk-or-single");
  const [error, setError] = useState<{
    hasError: boolean;
    duplicates?: EmployeeAndTheirManager[];
  }>({
    hasError: false,
  });
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  async function handleSubmit() {
    try {
      const { duplicates, success } = await buildRequest<ReviewRoutes.AddReviewsToCampaign>({
        method: "POST",
        path: "/review/add-reviews-to-campaign",
        payload: {
          campaignID: campaign.id,
          reviews: employeesAndTheirManager.map(({ managerID, userID }) => ({ managerID, userID })),
        },
        target: "PEOPLE_REVIEW",
      })();

      if (!success) {
        const duplicateUserIds = duplicates.map(({ userID }) => userID);
        return setError({
          duplicates: employeesAndTheirManager.filter(({ userID }) =>
            duplicateUserIds.includes(userID)
          ),
          hasError: true,
        });
      }

      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      addToast(
        t("peopleReview.AddEmployeeToCampaignModal.submit.success", {
          defaultValue: "Collaborateur(s) ajouté(s) à la campagne",
        }),
        { appearance: "success" }
      );
      setError({ hasError: false });
    } catch (err) {
      setError({ hasError: true });
    }
  }

  return (
    <DSModal isOpen={true} className={styles.modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.AddEmployeeToCampaignModal.title", {
            defaultValue: "Ajouter des collaborateurs",
          })}
        />
      </DSModal.Header>

      <DSModal.Content className={styles.modal}>
        {error.hasError && (
          <Flex marginBottom="s">
            {error.duplicates?.length ? (
              <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
                {t("peopleReview.AddEmployeeToCampaignModal.submit.hasError.duplicates", {
                  defaultValue: "Les collaborateurs suivants sont déjà présents dans la campagne :",
                })}
                <ul>
                  {error.duplicates.map(({ employeeFullName, userID }) => (
                    <li key={userID}>{employeeFullName}</li>
                  ))}
                </ul>
              </DSAlert>
            ) : (
              <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
                {t("peopleReview.AddEmployeeToCampaignModal.submit.hasError", {
                  defaultValue: "Une erreur est survenue lors de l'ajout de collaborateur.",
                })}
              </DSAlert>
            )}
          </Flex>
        )}
        {step === "bulk-or-single" ? (
          <>
            <BulkUpload
              setStep={setStep}
              hasManagers={campaign.hasManagerPreparation}
              onUpload={setEmployeesAndTheirManager}
            />

            <HorizontalDivider top="s" bottom="s" />

            <Flex flexDirection="column">
              <Label
                label={t("peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.label", {
                  defaultValue: "Ou choisir le collaborateur à ajouter :",
                })}
              />
              <SelectEmployee
                onSelect={({ id, fullName }) =>
                  setEmployeesAndTheirManager([
                    { employeeFullName: fullName, isCollaboratorOwnManager: false, userID: id },
                  ])
                }
              />
            </Flex>
          </>
        ) : step === "single-select-manager" ? (
          <>
            <Flex flexDirection="row">
              <Flex flexGrow={1} flexDirection="column">
                <Label
                  label={t("peopleReview.AddEmployeeToCampaignModal.stepTwo.employeeSelect.label", {
                    defaultValue: "Collaborateur",
                  })}
                />
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {employeesAndTheirManager[0].employeeFullName}
                </Text>
              </Flex>
              <Flex flexGrow={1} flexDirection="column">
                <Label
                  required
                  label={t("peopleReview.AddEmployeeToCampaignModal.stepTwo.managerSelect.label", {
                    defaultValue: "Manager",
                  })}
                />
                <SelectEmployee
                  excludedUserUuids={[employeesAndTheirManager[0].userID]}
                  onSelect={({ id, fullName }) =>
                    setEmployeesAndTheirManager([
                      {
                        ...employeesAndTheirManager[0],
                        isCollaboratorOwnManager: employeesAndTheirManager[0].userID === id,
                        managerFullName: fullName,
                        managerID: id,
                      },
                    ])
                  }
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <BulkSelect
            hasManagers={campaign.hasManagerPreparation}
            employeesAndTheirManager={employeesAndTheirManager}
            onSelect={setEmployeesAndTheirManager}
          />
        )}
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.AddEmployeeToCampaignModal.cancel", {
            defaultValue: "Annuler",
          })}
        />

        {step === "bulk-or-single" ? (
          <DSModal.Footer.PrimaryButton
            disabled={!employeesAndTheirManager.length}
            label={t("peopleReview.AddEmployeeToCampaignModal.next", {
              defaultValue: "Suivant",
            })}
            onClick={() =>
              campaign.hasManagerPreparation ? setStep("single-select-manager") : handleSubmit()
            }
          />
        ) : step === "single-select-manager" ? (
          <DSModal.Footer.PrimaryButton
            disabled={!employeesAndTheirManager[0].managerID}
            onClick={handleSubmit}
            label={t("peopleReview.AddEmployeeToCampaignModal.single.submit", {
              defaultValue: "Ajouter le collaborateur",
            })}
          />
        ) : (
          <DSModal.Footer.PrimaryButton
            onClick={handleSubmit}
            label={t("peopleReview.AddEmployeeToCampaignModal.bulk.submit", {
              count: employeesAndTheirManager.length,
              defaultValue:
                employeesAndTheirManager.length <= 1
                  ? "Ajouter {{count}} collaborateur"
                  : "Ajouter {{count}} collaborateurs",
            })}
          />
        )}
      </DSModal.Footer>
    </DSModal>
  );
}

// MARK: SelectEmployee
type SelectEmployeeProps = {
  excludedUserUuids?: string[];
  onSelect: (employee: { id: string; fullName: string }) => void;
};

function SelectEmployee({ excludedUserUuids = [], onSelect }: SelectEmployeeProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const { data, error, isLoading } = useUsers({ excludedUserUuids, query });

  function getNoOptionMessage() {
    if (isLoading) {
      return t(
        "peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.placeholder.isLoading",
        {
          defaultValue: "Recherche en cours",
        }
      );
    }

    if (error) {
      return t("peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.placeholder.error", {
        defaultValue: "Une erreur s'est produite",
      });
    }

    // Backend route seems to trigger only after 2/3 chars
    if (query?.length < 3) {
      return t(
        "peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.placeholder.waitingUserInput",
        {
          defaultValue: "Saisissez au moins 3 caractères",
        }
      );
    }

    return t("peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.placeholder.empty", {
      defaultValue: "Aucun résultat trouvé",
    });
  }

  return (
    <Select
      isSearchable
      noOptionsMessage={getNoOptionMessage()}
      onInputChange={(query) => setQuery(query)}
      options={data.map((employee) => ({
        label: employee.fullName,
        value: employee.uuid,
      }))}
      placeholder={t("peopleReview.AddEmployeeToCampaignModal.stepOne.employeeSelect.placeholder", {
        defaultValue: "Sélectionnez un collaborateur",
      })}
      onChange={(employeeId) => {
        const employee = data.find(({ uuid }) => uuid === employeeId);
        if (employee) {
          onSelect({ id: employee.uuid, fullName: employee.fullName });
        }
      }}
    />
  );
}

// MARK: BulkUpload
type BulkUploadProps = {
  hasManagers: boolean;
  setStep: (step: Step) => void;
  onUpload: (rows: EmployeeAndTheirManager[]) => void;
};

function BulkUpload({ hasManagers, onUpload, setStep }: BulkUploadProps) {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);

  const onDrop = useCallback(
    async (files: File[]) => {
      try {
        const attendees = await buildFileRequest<PeopleReviewRoutes.UploadReviewedEmployees>({
          file: files[0],
          method: "POST",
          path: "/upload-reviewed-employees",
        })();

        if (attendees.employees.length === 0) {
          throw new Error("no attendees found");
        }

        onUpload(
          attendees.employees
            .filter(({ collaborator }) => Boolean(collaborator))
            .map(({ collaborator, manager }) => {
              return {
                employeeEmail: collaborator.email,
                employeeFullName: collaborator.fullName,
                isCollaboratorOwnManager: collaborator.uuid === manager?.uuid,
                userID: collaborator.uuid,
                ...(hasManagers && {
                  managerEmail: manager?.email,
                  managerFullName: manager?.fullName,
                  managerID: manager?.uuid,
                }),
              };
            })
        );
        setHasError(false);
        setStep("bulk-select");
      } catch (err) {
        setHasError(true);
      }
    },
    [hasManagers, onUpload, setStep]
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize: 52428800,
    multiple: false,
    onDrop,
  });

  return (
    <Flex gap="l" flexDirection="column">
      {hasError ? (
        <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
          {t("peopleReview.AddEmployeeToCampaignModal.upload.hasError", {
            defaultValue:
              "Une erreur est survenue lors de l'import du fichier. Merci de vérifier que votre fichier correspond au template.",
          })}
          <br />
          <Link
            label="Recommencer"
            className={styles.retryLink}
            onClick={() => {
              setHasError(false);
            }}
          />
        </DSAlert>
      ) : (
        <>
          <Flex gap="s" flexDirection="column">
            <FileDrop
              rootProps={getRootProps()}
              isDragActive={isDragActive}
              className={styles.fileDrop}
              inputProps={getInputProps()}
              btnLabel={t("peoplereview.upload.drop.button", {
                defaultValue: "Choisir un fichier",
              })}
              dropLabel={t("peoplereview.upload.drop.message", {
                defaultValue: "ou glisser déposer un fichier ici",
              })}
            />
          </Flex>
          <Flex flexDirection="column">
            {hasManagers ? (
              <Text espaceFont="body1Regular" color="plainText-onLight-default">
                {t("peoplereview.upload.template.explanationText", {
                  defaultValue:
                    "Votre fichier doit comporter 1 ligne par collaborateur et 2 colonnes",
                })}
              </Text>
            ) : (
              <Text espaceFont="body1Regular" color="plainText-onLight-default">
                {t("peoplereview.upload.template.explanationText", {
                  defaultValue:
                    "Votre fichier doit comporter 1 ligne par collaborateur et 1 colonne",
                })}
              </Text>
            )}

            <ul className={styles.columnsText}>
              <li>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {t("peoplereview.upload.template.explanationText.columnOne", {
                    defaultValue: "Colonne 1 : email du collaborateur",
                  })}
                </Text>
              </li>
              {hasManagers && (
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peoplereview.upload.template.explanationText.columnTwo", {
                      defaultValue:
                        "Colonne 2 : email du manager en charge de la People Review du collaborateur",
                    })}
                  </Text>
                </li>
              )}
            </ul>
          </Flex>
          <CSVLink
            filename="template.csv"
            className={styles.getTemplateLink}
            data={[["collab_email", hasManagers && "manager_email"].filter(Boolean)]}
          >
            {t("peoplereview.upload.template.download.text", {
              defaultValue: "Télécharger un template de fichier",
            })}
          </CSVLink>
        </>
      )}
    </Flex>
  );
}

// MARK: BulkSelect
type DataGridRow = {
  id: GridRowId;
} & EmployeeAndTheirManager;

type BulkSelectProps = {
  hasManagers: boolean;
  employeesAndTheirManager: EmployeeAndTheirManager[];
  onSelect: (selectedRows: EmployeeAndTheirManager[]) => void;
};

function BulkSelect({ employeesAndTheirManager, hasManagers, onSelect }: BulkSelectProps) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const [rows] = useState<DataGridRow[]>(
    employeesAndTheirManager.map((employeeAndTheirManager) => ({
      ...employeeAndTheirManager,
      id: employeeAndTheirManager.userID,
    }))
  );

  useEffect(() => {
    if (apiRef?.current?.selectRows) {
      apiRef.current.selectRows(rows.map(({ id }) => id));
    }
  }, [rows, apiRef]);

  const collaboratorsOwnManagerEmails: string[] = useMemo(
    () =>
      rows.filter((row) => row.isCollaboratorOwnManager).map(({ employeeEmail }) => employeeEmail),
    [rows]
  );

  return (
    <Flex marginBottom="xxs" flexDirection="column">
      {hasManagers && employeesAndTheirManager.length > 0 && (
        <CollaboratorOwnManagerWarningAlert emails={collaboratorsOwnManagerEmails} />
      )}
      <DSDataGrid
        rows={rows}
        apiRef={apiRef}
        hideFooter={true}
        checkboxSelection
        isRowSelectable={(params: GridRowParams<EmployeeAndTheirManager>) =>
          !params.row.isCollaboratorOwnManager
        }
        onRowSelectionModelChange={(selection) => {
          onSelect(employeesAndTheirManager.filter(({ userID }) => selection.includes(userID)));
        }}
        entityName={t("peoplereview.creationStep.attendees.datagrid.entityName", {
          count: rows.length,
          defaultValue: rows.length <= 1 ? "{{count}} collaborateur" : "{{count}} collaborateurs",
        })}
        emptyOverlay={{
          text: [
            t("peopleReview.creationStep.attendees.emptyOverlayText.firstSentence", {
              defaultValue: "Aucun collaborateur chargé pour le moment.",
            }),
            t("peopleReview.creationStep.attendees.emptyOverlayText.secondSentence", {
              defaultValue: "Déposez un fichier correspondant au template pour commencer.",
            }),
          ],
        }}
        columns={[
          {
            field: "employeeFullName",
            flex: 1,
            headerName: "Collaborateur",
            renderCell: (data: GridRenderCellParams<EmployeeAndTheirManager, string>) => {
              return (
                <p
                  className={cx({
                    [styles.invalidCollabManagerPair]: data.row.isCollaboratorOwnManager,
                  })}
                >
                  {data.value}
                </p>
              );
            },
            resizable: false,
          },
          ...(hasManagers
            ? [
                {
                  field: "managerFullName",
                  flex: 1,
                  headerName: "Manager",
                  renderCell: (data: GridRenderCellParams<EmployeeAndTheirManager, string>) => {
                    return (
                      <p
                        className={cx({
                          [styles.invalidCollabManagerPair]: data.row.isCollaboratorOwnManager,
                        })}
                      >
                        {data.value}
                      </p>
                    );
                  },
                  resizable: false,
                },
              ]
            : []),
        ]}
      />
    </Flex>
  );
}
