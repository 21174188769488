import { getInitials, ListUtils } from "@skillup/shared-utils";
import { useQuery } from "@tanstack/react-query";
import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";
import { EmployeesRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

async function searchEmployees(filters: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>) {
  return buildRequest<EmployeesRoutes.SearchEmployees>({
    method: "POST",
    path: "/employees/search",
    payload: { filters, includeDisabledEmployees: false },
    target: "CORE_HR",
  })();
}

async function getUsersByUuids(uuids: string[]) {
  if (uuids.length === 0) return [];
  return buildRequest<CollaboratorsRoutes.GetByUuids>({
    method: "POST",
    path: "/collaborators/filtered/by-uuids",
    payload: { uuids },
  })();
}

export const useFilteredPopulation = (
  filters: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>
) => {
  const { data: usersUuids } = useQuery(
    ["automation-population", filters],
    () => searchEmployees(filters),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: usersData } = useQuery(
    ["automation-population-details", usersUuids?.items ?? []],
    () => getUsersByUuids(usersUuids.items),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    usersUuids,
    usersData: usersData?.map((user) => ({
      uuid: user.uuid,
      initials: getInitials({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
      }),
      fullName: user.fullName,
    })),
  };
};
