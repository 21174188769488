import cx from "classnames";

import { ParseDate, FormatDate, fr } from "@skillup/shared-utils";
import { Autocomplete, DatePicker, DSFormGroupTextInput } from "@skillup/ui";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import { useHabilitations } from "../../state/habilitations";
import { searchUsersByQuery } from "fetchers/users-fetcher";

import styles from "./TrackingForm.module.scss";
import useTranslation from "hooks/useTranslation";

export type IForm = {
  userUuid?: string;
  habilitationUuid?: string;
  validSince?: string;
};

export interface Props {
  readonly form: IForm;
  readonly setFormState: (patch: Partial<IForm> | ((prevState: IForm) => Partial<IForm>)) => void;
}

const TrackingForm = ({ form, setFormState }: Props) => {
  const { t } = useTranslation();
  const { data: habilitations } = useHabilitations();

  async function filterHabilitations(search: string) {
    return (
      habilitations?.habilitations
        .filter((habilitation) =>
          habilitation.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
        .map((habilitation) => ({
          label: habilitation.name,
          value: habilitation.uuid,
        })) ?? []
    );
  }

  async function filterCollaborators(search: string) {
    if (!search) return [];
    const users = await searchUsersByQuery(search, USER_FILE_VALIDATION_TYPE.NONE);
    return users.map((user) => ({
      label: user.fullName,
      value: user.uuid,
    }));
  }

  function handleInputChange(key: keyof IForm) {
    return (value: IForm[typeof key]) => {
      let newValue = value;
      if (key === "validSince" && value)
        newValue = FormatDate.ToStringFormat(ParseDate.FromISO(value), "yyyy-MM-dd");

      setFormState({
        [key]: newValue,
      });
    };
  }

  return (
    <>
      <div className={styles.inputLine}>
        <DSFormGroupTextInput
          className={styles.label}
          label={t("trainings.view.regulatory.link_collaborator_form.select_collaborator.label", {
            defaultValue: "Sélectionner un collaborateur",
          })}
          required
        >
          <Autocomplete
            autoFocus
            className={styles.input}
            placeholder={t(
              "trainings.view.regulatory.link_collaborator_form.select_collaborator.placeholder",
              { defaultValue: "Cherchez un collaborateur" }
            )}
            aria-label={t(
              "trainings.view.regulatory.link_collaborator_form.select_collaborator.placeholder",
              { defaultValue: "Cherchez un collaborateur" }
            )}
            query={filterCollaborators}
            value={form.userUuid}
            onChange={handleInputChange("userUuid")}
            debounce={300}
            noOptionsMessage=" "
          />
        </DSFormGroupTextInput>
      </div>
      <div className={styles.inputLine}>
        <DSFormGroupTextInput
          className={styles.label}
          label={t("trainings.view.regulatory.link_collaborator_form.select_habilitation.label", {
            defaultValue: "Sélectionner une habilitation",
          })}
          required
        >
          <Autocomplete
            key={habilitations ? 1 : 0} // Avoid glitches once habilitations are loaded, TODO remove once we have a true Autocmplee component
            className={styles.input}
            placeholder={t(
              "trainings.view.regulatory.link_collaborator_form.select_habilitation.placeholder",
              { defaultValue: "Cherchez une habilitation" }
            )}
            aria-label={t(
              "trainings.view.regulatory.link_collaborator_form.select_habilitation.placeholder",
              { defaultValue: "Sélectionner une habilitation" }
            )}
            value={form.habilitationUuid}
            onChange={handleInputChange("habilitationUuid")}
            query={filterHabilitations}
          />
        </DSFormGroupTextInput>
      </div>
      <div className={cx(styles.inputLine, styles.inline)}>
        <DSFormGroupTextInput
          className={styles.label}
          label={t("trainings.view.regulatory.link_collaborator_form.validity.label", {
            defaultValue: "Début de validité de la nouvelle habilitation",
          })}
        >
          <DatePicker
            placeholder={t(
              "trainings.view.regulatory.link_collaborator_form.validity.placeholder",
              { defaultValue: "Choisissez une date" }
            )}
            value={form.validSince && new Date(form.validSince)}
            onChange={(value) => {
              handleInputChange("validSince")(value?.toISOString());
            }}
            autoFormat
            locale={fr}
            className={cx(styles.input, styles.inputDate)}
          />
        </DSFormGroupTextInput>
      </div>
    </>
  );
};

export default TrackingForm;
