import { TranslationType } from "hooks/useTranslation";

export default function durationGroups(t: TranslationType) {
  const oneDay = t("trainings.view.requalification_modal.duration_group.1_day", {
    defaultValue: "1 jour",
  });
  const twoDays = t("trainings.view.requalification_modal.duration_group.2_days", {
    defaultValue: "2 jours",
  });
  const threeDays = t("trainings.view.requalification_modal.duration_group.3_days", {
    defaultValue: "3 jours",
  });
  const moreThan3Days = t("trainings.view.requalification_modal.duration_group.more_than_3_days", {
    defaultValue: "Plus de 3 jours",
  });

  const mapping = {
    0: oneDay,
    1: twoDays,
    2: threeDays,
    3: moreThan3Days,
  };

  const values = [oneDay, twoDays, threeDays, moreThan3Days];

  return { mapping, values };
}
