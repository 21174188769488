import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Summary as ISummary } from "@skillup/types";

import User from "utils/User";
import { time } from "uiAssets/Icons";
import useTranslation from "hooks/useTranslation";

import Icon from "components/Icon";
import Loader from "components/Loader";

import styles from "./SummaryStyles.module.scss";

export interface IProps {
  summary: ISummary | {};
  selection: string;
}

const Summary = (props: IProps) => {
  const { t } = useTranslation();

  const summary = (props.summary || {}) as ISummary;
  const {
    selectionLength,
    price,
    priceWithTax,
    durationAsHours,
    trainingName,
    trainingOrganization,
    trainingUrl,
    types,
    modality,
    modalityLabel,
    total,
    totalWithTax,
    totalDuration,
  } = summary;

  const multipleLines = selectionLength > 1;
  const singleLines = selectionLength === 1;
  const isLoading = props.selection && props.selection !== summary.selection;

  const durationText = useMemo(() => {
    const hours = Math.floor(totalDuration);
    const minutes = Math.round((totalDuration - hours) * 60);

    const hoursText = t("common.duration.hours", {
      defaultValue: "{{count}} heures",
      count: hours,
    });

    if (minutes > 0) {
      const minutesText = t("common.duration.minutes", {
        defaultValue: "{{count}} minutes",
        count: minutes,
      });

      return `${hoursText} ${minutesText}`;
    }

    return hoursText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDuration]);

  return (
    <div className={styles.Summary}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader width={30} />
        </div>
      )}

      {!isLoading && multipleLines && (
        <div className={styles.multipleLines}>
          <div>
            <strong>{selectionLength} </strong>
            {t("trainings.view.schedule_row.sidepanel.actionsSelected", {
              defaultValue: "actions de formation selectionnées",
              count: selectionLength,
            })}
          </div>
          <div>
            Prix total : <strong>{User.isCompanyWithTax() ? totalWithTax ?? total : total}</strong>
          </div>
          {totalDuration && (
            <div>
              Durée totale : <strong>{durationText}</strong>
            </div>
          )}
        </div>
      )}

      {!isLoading && singleLines && (
        <div className={styles.singleLine}>
          <div>
            <Link to={trainingUrl || ""}>{trainingName}</Link>
          </div>
          <div>
            <span>
              <div>
                {!!trainingOrganization && (
                  <p>
                    {t("trainings.view.schedule_row.sidepanel.byOrganization", {
                      defaultValue: "Par {{ organization }}",
                      organization: trainingOrganization,
                    })}
                  </p>
                )}
              </div>
              <div>
                {!!durationAsHours && (
                  <>
                    <Icon icon={time} fill="#666" width={16} style={{ marginRight: 5 }} />
                    <span className={styles.duration}>
                      {t("trainings.view.schedule_row.sidepanel.durationAsHours", {
                        defaultValue: "{{ hour }} heures",
                        hour: durationAsHours,
                      })}
                    </span>
                  </>
                )}
                {!!modality && (
                  <span className={styles.modality}>
                    {t(`trainings.entity.training.property.mode.${modality}.label`, {
                      defaultValue: modalityLabel,
                    })}
                  </span>
                )}
                {!!types && types === "Intra" && (
                  <span className={styles.types}>
                    {t(`trainings.entity.session.property.type.${types}.label`, {
                      defaultValue: types,
                    })}
                  </span>
                )}
              </div>
            </span>
            {(!!price || price === "Sur devis") && (
              <span className={styles.price}>
                {User.isCompanyWithTax() ? priceWithTax ?? price : price}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
