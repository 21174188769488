import { Flex, Text } from "@skillup/design-system";
import styles from "./EditHistoricalDataConfiguration.module.scss";
import { DSButton, DSCheckbox, DSModal } from "@skillup/ui";
import { HistoricalDataConfiguration } from "../../../services";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type EditHistoricalDataConfigurationProps = {
  configuration: HistoricalDataConfiguration;
  saveHistoricalDataConfiguration: (config: HistoricalDataConfiguration) => void;
  disabledEdit: () => void;
  historicalDataLabelMap: Record<keyof HistoricalDataConfiguration, string>;
};

export const EditHistoricalDataConfiguration = ({
  saveHistoricalDataConfiguration,
  configuration,
  disabledEdit,
  historicalDataLabelMap,
}: EditHistoricalDataConfigurationProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tempHistoricalDataConfiguration, setTempHistoricalDataConfiguration] =
    useState<HistoricalDataConfiguration>(configuration);

  const handleChangeConfiguration = (key: keyof HistoricalDataConfiguration) => {
    setTempHistoricalDataConfiguration({
      ...tempHistoricalDataConfiguration,
      [key]: !tempHistoricalDataConfiguration[key],
    });
  };

  const handleConfirmSave = () => {
    saveHistoricalDataConfiguration(tempHistoricalDataConfiguration);
    disabledEdit();
  };

  const activatedKeysToWarn = useMemo(() => {
    return Object.keys(tempHistoricalDataConfiguration).filter(
      (key) => tempHistoricalDataConfiguration[key] === true && configuration[key] === false
    );
  }, [configuration, tempHistoricalDataConfiguration]);

  const handleSave = () => {
    if (activatedKeysToWarn.length > 0) {
      setShowModal(true);
      return;
    }

    handleConfirmSave();
  };

  return (
    <Flex
      gap="s"
      padding="s"
      flexDirection="column"
      className={styles.body}
      backgroundColor="surface-light"
      height={"max-content"}
    >
      <Flex gap="xxs" flexDirection="column">
        <Text espaceFont="h5">
          {t("employeeProfile.historicalData.title", {
            defaultValue: "Accès aux données d’historique",
          })}
        </Text>
        <Text espaceFont="body1Regular">
          {t("employeeProfile.historicalData.edit.subTitle", {
            defaultValue:
              "Choisissez quelles données sont accessibles pour leurs supérieurs hiérarchiques.",
          })}
        </Text>
      </Flex>
      <Flex gap="xxs" flexDirection="column">
        <DSCheckbox
          label={historicalDataLabelMap["salaryAndJob"]}
          checked={tempHistoricalDataConfiguration.salaryAndJob}
          onChange={() => handleChangeConfiguration("salaryAndJob")}
        />
        <DSCheckbox
          label={historicalDataLabelMap["primesBonus"]}
          checked={tempHistoricalDataConfiguration.primesBonus}
          onChange={() => handleChangeConfiguration("primesBonus")}
        />
      </Flex>
      <Flex gap="xs" justifyContent="flex-end">
        <DSButton
          label={t("employeeProfile.historicalData.edit.btn.cancel", {
            defaultValue: "Annuler",
          })}
          buttonSize="S"
          emphasis="Mid"
          onClick={disabledEdit}
        />
        <DSButton
          label={t("employeeProfile.historicalData.edit.btn.confirm", {
            defaultValue: "Enregistrer les modifications",
          })}
          buttonSize="S"
          emphasis="High"
          onClick={handleSave}
        />
      </Flex>
      <DSModal isOpen={showModal}>
        <DSModal.Header onClose={() => setShowModal(false)}>
          <DSModal.Header.Title
            title={t("employeeProfile.historicalData.edit.modal.title", {
              defaultValue: "Avertissement sur les données sensibles",
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <Flex flexDirection="column" gap="s">
            <Text>
              {t("employeeProfile.historicalData.edit.modal.warningMessagePart1", {
                defaultValue: "Vous avez choisi de rendre accessibles les données de:",
              })}
              <ul className={styles.activatedKeyToWarn}>
                {activatedKeysToWarn.map((key) => (
                  <li key={key}>{historicalDataLabelMap[key]}</li>
                ))}
              </ul>
            </Text>
            <Text>
              {t("employeeProfile.historicalData.edit.modal.warningMessagePart2", {
                defaultValue:
                  "Ces données seront immédiatement visibles pour les collaborateurs et leurs supérieurs hiérarchiques.",
              })}
            </Text>
          </Flex>
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={() => setShowModal(false)}
            label={t("employeeProfile.historicalData.edit.modal.btn.cancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            onClick={handleConfirmSave}
            label={t("employeeProfile.historicalData.edit.modal.btn.confirm", {
              defaultValue: "Confirmer",
            })}
          />
        </DSModal.Footer>
      </DSModal>
    </Flex>
  );
};
