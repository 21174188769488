import { InterviewState } from "@skillup/espace-rh-bridge";
import { StatusType } from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";
interface IColorKeyValue {
  color: string;
  key: string;
  value: string;
  shortValue: string;
}

export const getStatusValueToStatusType = (statusValue: string): StatusType => {
  switch (statusValue) {
    case "signed":
      return StatusType.SUCCESS;
    case "unsigned":
      return StatusType.ERROR;
    case "shared":
      return StatusType.PROGRESS;
    case "pending":
      return StatusType.DONE;
    default:
      return StatusType.ERROR;
  }
};

export const interviewStatusKeyToValue = (
  t: TranslationType,
  key?: InterviewState
): IColorKeyValue => {
  switch (key) {
    case "signed":
      return {
        color: "green",
        key: "signed",
        value: t("interviews.status.value.signed", { defaultValue: "Entretien signé" }),
        shortValue: t("interviews.status.shortValue.signed", { defaultValue: "Signé" }),
      };
    case "shared":
      return {
        color: "blue",
        key: "shared",
        value: t("interviews.status.value.shared", { defaultValue: "Préparation partagée" }),
        shortValue: t("interviews.status.shortValue.shared", { defaultValue: "Partagé" }),
      };
    case "pending":
      return {
        color: "grey",
        key: "pending",
        value: t("interviews.status.value.pending", { defaultValue: "Préparation non partagée" }),
        shortValue: t("interviews.status.shortValue.pending", { defaultValue: "À préparer" }),
      };
    case "unsigned":
      return {
        color: "red",
        key: "unsigned",
        value: t("interviews.status.value.unsigned", { defaultValue: "Entretien non signé" }),
        shortValue: t("interviews.status.shortValue.unsigned", { defaultValue: "Non signé" }),
      };
    default:
      return {
        color: "red",
        key: "404",
        value: "Not found status",
        shortValue: "Not found",
      };
  }
};
