import { Label as DSLabel, DSTextArea } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import type { Child } from "../../../../reducer";

import styles from "./Guidelines.module.scss";

type Guidelines = Extract<Child, { kind: "guidelines" }>;

type GuidelinesProps = {
  child: Guidelines;
  updateTemplate(data: Guidelines, choicesForOrdinalTargets?: string[]): void;
  t: TranslationType;
};

const Guidelines = (props: GuidelinesProps) => {
  const handleChange = (value: string) => {
    props.updateTemplate({ ...props.child, description: value });
  };

  return (
    <div className={styles.container}>
      <div>
        <DSLabel
          label={props.t("interviews.templateBuilder.guidelines.label", {
            defaultValue: "Texte d’introduction",
          })}
          required
        />
        <DSTextArea
          value={props.child.description}
          onChange={handleChange}
          placeholder={props.t("interviews.templateBuilder.guidelines.placeholder", {
            defaultValue: "Introduction",
          })}
          debounceValue={50}
        />
      </div>
    </div>
  );
};

export { Guidelines };
