import { useToggle } from "react-use";
import Colors from "uiAssets/Colors";

import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";

import InteractiveButton from "components/InteractiveButton";

import styles from "./RevertToApprovedModalStyles.module.scss";
import useTranslation from "hooks/useTranslation";

export interface Props {
  rows: Array<string>;
  reload: () => void;
  activeTab: string;
  users: Array<{ noEmail: boolean; fullName: string }>;
}

export default ({ rows, reload, activeTab, users }: Props) => {
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();

  const handleSubmit = async (): Promise<void> => {
    toggleLoading(true);

    try {
      await buildRequest<ScheduleRowRoutes.RevertToApprovedHr>({
        path: "/scheduleRow/revert-to-approved-hr",
        method: "POST",
        payload: { rows },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.put_back_to_subscription.action.success", {
          defaultValue: "Ligne renvoyée avec succès.",
          count: rows.length,
        }),
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      reload();
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.put_back_to_subscription.action.error", {
          defaultValue: "La demande a échoué.",
        }),
        type: "error",
      });
    } finally {
      toggleLoading(false);
    }
  };

  const text: Array<string> = [
    t("trainings.view.put_back_to_subscription.sent_mail_description", {
      defaultValue:
        "{{ fullName }} a reçu un mail contenant un lien l’invitant à choisir une session.",
      count: rows.length,
      fullName: users[0].fullName,
    }),
    t("trainings.view.put_back_to_subscription.sent_mail_description_2", {
      defaultValue:
        "Ce lien ne sera plus valide dès le renvoi de ces lignes au statut « À inscrire ».",
    }),
  ];

  const canceledText: Array<string> = [
    t("trainings.view.put_back_to_subscription.no_mail_description", {
      defaultValue: "{{ fullName }} n’a pas été notifié de l’annulation.",
      count: rows.length,
      fullName: users[0].fullName,
    }),
    t("trainings.view.put_back_to_subscription.no_mail_description_2", {
      defaultValue: `Pensez à les informer une fois ces lignes renvoyées au statut « À inscrire ».`,
    }),
  ];

  return (
    <div className={styles.RevertToApprovedHrModal}>
      <>
        {activeTab === "plan-denied"
          ? canceledText.map((para, index) => <p key={index}>{para}</p>)
          : text.map((para, index) => <p key={index}>{para}</p>)}
      </>
      <div className={styles.buttonsContainer}>
        <InteractiveButton
          label={t("common.acction.cancel", { defaultValue: "Annuler" })}
          onClick={() => {
            Acta.dispatchEvent("closeModal");
          }}
          background="#fff"
          color={Colors.blue}
          style={{ fontWeight: 500 }}
          size="small"
          loading={loading}
        />
        <InteractiveButton
          label={t("trainings.view.put_back_to_subscription.action.confirm", {
            defaultValue: "Confirmer le renvoi",
          })}
          onClick={handleSubmit}
          background={Colors.gradientBlue}
          style={{ fontWeight: 500 }}
          size="small"
          loading={loading}
        />
      </div>
    </div>
  );
};
