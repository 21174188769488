import { useCallback } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal, DSColor } from "@skillup/ui";
import { Text, Flex, Loader } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { Action, useAction } from "services/peopleReview";

interface ArchiveActionModalProps {
  action: Action;
  isOpen: boolean;
  closeModal: () => void;
}

export const ArchiveActionModal = ({ action, closeModal, isOpen }: ArchiveActionModalProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { mutations } = useAction(action.uuid);

  const archiveAction = useCallback(async () => {
    try {
      await mutations.archive.mutateAsync();
      closeModal();
      addToast("Action archivée avec succès", { appearance: "success" });
    } catch (err) {
      addToast("Une erreur est survenue", { appearance: "error" });
      closeModal();
    }
  }, [addToast, closeModal, mutations.archive]);

  const { isLoading } = mutations.archive;

  return (
    <DSModal isOpen={isOpen} portalTarget={document.body}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("peoplereviewsConfiguration.actions.modals.archive.title", {
            defaultValue: "Archiver l'action",
          })}
        />
      </DSModal.Header>
      <DSModal.Content
        borderColor={DSColor["border-onLight"]}
        backgroundColor={DSColor["surface-light-darker"]}
      >
        <Flex paddingVertical="s" flexDirection="column">
          {isLoading && <Loader fill />}
          {!isLoading && (
            <Text espaceFont="body2Regular">
              {t("peoplereviewsConfiguration.actions.modals.archive.message", {
                defaultValue: "Êtes vous certain de vouloir archiver cette action ?",
              })}
            </Text>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("peoplereviewsConfiguration.actions.modals.archive.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={archiveAction}
          label={t("peoplereviewsConfiguration.actions.modals.archive.confirm", {
            defaultValue: "Archiver l'action",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
