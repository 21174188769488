import React, { useState } from "react";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";
import { isUndefined } from "lodash";
import type { InterviewUser } from "@skillup/types";
import { CampaignEventTrigger, CampaignMailingSettings } from "@skillup/espace-rh-bridge";

import { QuestionsStep, FinalStep, AutomationStep, MailingSettings } from "./components/Steps";
import { InterviewPairs } from "./models/InterviewPair";
import { Step } from "./models/Step";
import { createNewCampaign as createNewCampaignRequest } from "./requests/CreateCampain";

import CreateFormInterviewWrapper from "./CreateInterviewFormWrapper";
import useTranslation from "hooks/useTranslation";
import OptionsStep from "./components/Steps/OptionsStep";

interface IState {
  titleForHR: string;
  titleForAttendees: string;
  interviews: InterviewPairs[];
  template?: { title: string; uuid: string };
  managerOnly?: boolean;
  start?: number;
  ongoingStartDate?: number;
  hideFromEmployeeUntil?: { managerState: InterviewUser["state"] };
  confidential: boolean;
  duration: number;
  // [CAA-DEF-DATA-EXCHG-001]
  filters?: CampaignEventTrigger;
  mailingSettings: CampaignMailingSettings;
}

const CreateNewAutomatedInterviews = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    titleForHR: "",
    titleForAttendees: "",
    duration: undefined,
    interviews: [],
    // [CAA-DEF-DATA-EXCHG-001]
    filters: {
      type: "joinDate",
      offset: undefined,
      offsetUnit: "days",
      offsetDirection: "after",
      filter: {},
    },
    mailingSettings: {
      notificationCreated: true,
      notificationManagerChange: true,
      notificationShared: true,
      notificationSigned: true,
      notificationReopen: true,
      reminderMinus14: true,
      reminderMinus7: true,
      reminderMinus1: true,
      reminderPlus1: true,
    },
    confidential: false,
  });

  const {
    titleForHR,
    titleForAttendees,
    template,
    interviews,
    managerOnly,
    duration,
    hideFromEmployeeUntil,
    confidential,
    filters,
    mailingSettings,
  } = state;

  const [canCreateCampaign, setCanCreateCampaign] = useState(false);

  const steps: Step[] = [
    {
      label: t("interviews.campaigncreation.step.modelandname", {
        defaultValue: "Trame et nom",
      }),
      id: "modelAndName",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <QuestionsStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          setQuestionsTemplate={(template) => setState({ template })}
          setCampaignTitle={(campaignName, scope: "HR" | "attendees") => {
            if (scope === "HR") {
              setState({ titleForHR: campaignName });
            } else if (scope === "attendees") {
              setState({ titleForAttendees: campaignName });
            }
          }}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.automation", {
        defaultValue: "Automatisation",
      }),
      id: "automation",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(filters.type) &&
        filters.offset &&
        !isUndefined(filters.offsetUnit) &&
        !isUndefined(filters.offsetDirection) &&
        duration
      ),
      component: (
        <AutomationStep
          duration={duration}
          setDuration={(duration: number) => setState({ duration })}
          filters={filters}
          setFilters={(filters: CampaignEventTrigger) => setState({ filters })}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.workflowandconfidentiality", {
        defaultValue: "Workflow et confidentialité",
      }),
      id: "workflowAndConfidentiality",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(filters.type) &&
        filters.offset &&
        !isUndefined(filters.offsetUnit) &&
        !isUndefined(filters.offsetDirection) &&
        duration
      ),
      component: (
        <OptionsStep
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          setHideFromEmployeeUntil={(hideFromEmployeeUntil) => setState({ hideFromEmployeeUntil })}
          managerOnly={managerOnly}
          setManagerOnly={(managerOnly) => setState({ managerOnly })}
          confidential={confidential}
          setConfidentiality={(confidential) => setState({ confidential })}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.notifications", {
        defaultValue: "Notifications",
      }),
      id: "notifications",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(filters.type) &&
        filters.offset &&
        !isUndefined(filters.offsetUnit) &&
        !isUndefined(filters.offsetDirection) &&
        duration
      ),
      component: (
        <MailingSettings
          mailingSettings={mailingSettings}
          updateOne={(key, enabled) =>
            setState({ mailingSettings: { ...mailingSettings, [key]: enabled } })
          }
          updateAllNotif={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("notification"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          updateAllRemind={(enabled) => {
            let newMailingSettings = Object.assign({}, mailingSettings);
            Object.keys(newMailingSettings)
              .filter((k) => k.includes("remind"))
              .forEach(function (key) {
                newMailingSettings[key] = enabled;
              });
            setState({ mailingSettings: newMailingSettings });
          }}
          warning={false}
        />
      ),
    },
    {
      label: t("interviews.campaigncreation.step.summary", {
        defaultValue: "Synthèse",
      }),
      id: "summary",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(filters.type) &&
        filters.offset &&
        !isUndefined(filters.offsetUnit) &&
        !isUndefined(filters.offsetDirection) &&
        duration
      ),
      component: (
        <FinalStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          interviews={interviews}
          type="ongoing"
          start={filters.offset} // update when there are other types than joinDate
          duration={duration}
          setCanCreateCampaign={setCanCreateCampaign}
          managerOnly={managerOnly}
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          confidential={confidential}
        />
      ),
    },
  ];

  const createNewCampaign = async (toggleLoading: (nextValue?: boolean) => void) => {
    const campaignUuid = await createNewCampaignRequest({
      interviews,
      toggleLoading,
      t,
      confidential,
      data: {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "adHoc",
        duration,
        hideFromEmployeeUntil,
        trigger: filters,
        mailingSettings,
      },
    });

    if (campaignUuid) history.push(`/responsable/campagne/${campaignUuid}`);
  };

  return (
    <CreateFormInterviewWrapper
      steps={steps}
      title={t("interviews.campaigncreation.type.ongoing", {
        defaultValue: "Entretiens en continu",
      })}
      sendButtonLabel={t("interviews.campaigncreation.type.ongoing.create", {
        defaultValue: "Créer les entretiens",
      })}
      createNewCampaign={createNewCampaign}
      canCreateCampaign={canCreateCampaign}
    />
  );
};

export default CreateNewAutomatedInterviews;
