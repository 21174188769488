import { useMemo } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { DSModal } from "@skillup/ui";
import { DSUserPreview } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";
import { restoreManagerPreparation } from "services/peopleReview/reviews/restoreManagerPreparation";

import { Review, Campaign } from "../../types";

import styles from "./ManagerPreparationRestoreModal.module.scss";

interface ManagerPreparationRestoreModalProps {
  review: Review;
  campaign: Campaign;
  onClose: () => void;
}

export const ManagerPreparationRestoreModal = ({
  campaign,
  onClose,
  review,
}: ManagerPreparationRestoreModalProps) => {
  const { t } = useTranslation();

  const managerFields = useMemo(() => {
    return Object.entries(review.managerFields).map(([scaleID, value]) => {
      const scale = campaign.scales.find((scale) => scale.id === scaleID);
      const scaleLabel = scale.label;
      const scaleValue = scale.options.find((option) => option.value.toString() === value)?.label;

      return {
        label: scaleLabel,
        value: scaleValue,
      };
    });
  }, [review.managerFields, campaign.scales]);

  const coordinatorFields = useMemo(() => {
    return Object.entries(review.fields).map(([scaleID, value]) => {
      const scale = campaign.scales.find((scale) => scale.id === scaleID);
      const scaleLabel = scale.label;
      const scaleValue = scale.options.find((option) => option.value.toString() === value)?.label;

      return {
        label: scaleLabel,
        value: scaleValue,
      };
    });
  }, [review.fields, campaign.scales]);

  const queryClient = useQueryClient();

  const { mutate: restore } = useMutation({
    mutationFn: () => {
      return restoreManagerPreparation({ campaignID: campaign.id, reviewID: review.id });
    },
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      onClose();
    },
  });

  return (
    <DSModal isOpen={true}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.button.managerPreparationReloadModal.title", {
            defaultValue: "Préparation manager",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex gap="s" minWidth="726px" flexDirection="column">
          <Flex gap="l" flexDirection="row" alignItems="center">
            <DSUserPreview
              size="M"
              fullName={review.manager}
              style={{ alignItems: "center" }}
              randomColorSeedString={review.manager}
              initials={getInitials({ fullName: review.manager })}
              propertyLabel={t("peopleReview.button.managerPreparationReloadModal.manager", {
                defaultValue: "Manager",
              })}
            />
            <DSUserPreview
              size="M"
              fullName={review.fullName}
              style={{ alignItems: "center" }}
              randomColorSeedString={review.fullName}
              propertyLabel={t("peopleReview.button.managerPreparationReloadModal.collaborator", {
                defaultValue: "Collaborateur",
              })}
              initials={getInitials({
                firstName: review.firstName,
                fullName: review.fullName,
                lastName: review.lastName,
              })}
            />
          </Flex>

          <Flex
            gap="s"
            width="100%"
            paddingBottom="s"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Flex
              gap="m"
              flex={1}
              flexDirection="column"
              padding="s s 2.25rem s"
              className={styles.preparationManagerCard}
            >
              <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                {t("peopleReview.button.managerPreparationReloadModal.managerEvaluation", {
                  defaultValue: "Évaluations du manager",
                })}
              </Text>
              <Flex gap="s" flexDirection="column">
                {managerFields.map(({ label, value }) => (
                  <Flex gap="xxs" key={label} flexDirection="column">
                    <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                      {label}
                    </Text>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {value ?? "-"}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>

            <Flex
              gap="m"
              flex={1}
              flexDirection="column"
              padding="s s 2.25rem s"
              className={styles.coordinatorCard}
            >
              <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                {t("peopleReview.button.managerPreparationReloadModal.abitrationInProgress", {
                  defaultValue: "Arbitrage en cours",
                })}
              </Text>
              <Flex gap="s" flexDirection="column">
                {coordinatorFields.map(({ label, value }) => (
                  <Flex gap="xxs" key={label} flexDirection="column">
                    <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                      {label}
                    </Text>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {value ?? "-"}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.button.managerPreparationReloadModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={() => restore()}
          label={t("peopleReview.button.managerPreparationReloadModal.restore", {
            defaultValue: "Rétablir la préparation manager",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
