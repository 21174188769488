import { useState } from "react";

import { Select } from "@skillup/ui";
import { Flex, Text, Loader } from "@skillup/design-system";
import { DatasetKey, datasetKeys, TimelineType } from "@skillup/core-hr-bridge";

import { useCoreHRRecords } from "services/coreHR";
import { buildURL } from "utils/buildRequest";
import dataLayer from "utils/DataLayer";

import { Collaborator } from "../../api";

import styles from "./UserCareerDevMonitoring.module.scss";
import useTranslation from "hooks/useTranslation";
import { FieldsTimelines } from "./FieldsTimelines";
import { FieldsAtDate } from "./FieldsAtDate";

type Record = TimelineType["data"][number];

const sortByDate = (a: Record, b: Record) =>
  new Date(b.date).getTime() - new Date(a.date).getTime();

interface UserCareerDevMonitoringProps {
  readonly user: Collaborator;
}

const fieldsDictionnary = {
  job: {
    annualSalary: "Salaire annuel",
    monthlySalary: "Salaire mensuel",
    hourlyRate: "Taux horaire",
    jobTitle: "Intitulé du poste",
    currency: "Monnaie",
  },
  review: {
    performance: "Performance",
    engagement: "Engagement",
    flightRisk: "Risque de départ",
    potential: "Potentiel",
    impact: "Impact",
  },
};

type UserCareerDevMonitoringCardProps = {
  readonly record: Record;
  readonly datasetKey: DatasetKey;
};

const UserCareerDevMonitoringCard = ({ record, datasetKey }: UserCareerDevMonitoringCardProps) => {
  const { uuid } = record;

  const recordInformations = Object.entries(record)
    .filter(([key]) => key !== "date" && key !== "uuid" && key !== "employeeID")
    .map(([key, value]) => {
      if (key === "annualSalary" || key === "monthlySalary") {
        return [key, Math.round(Number(value) / 100)]; // salary data is stored in cents
      }
      return [key, value];
    });

  return (
    <Flex padding="s" key={uuid} flexDirection="column" className={styles.careerCard}>
      <div>
        {recordInformations.map(([key, value]) => {
          return (
            <div key={key}>
              {fieldsDictionnary[datasetKey][key]}: {value}
            </div>
          );
        })}
      </div>

      <Flex justifyContent="flex-end">
        <Text>Mis à jour le: {new Date(record["date"]).toLocaleString("fr-FR")}</Text>
      </Flex>
    </Flex>
  );
};

const UserCareerDevMonitoring = ({ user }: UserCareerDevMonitoringProps) => {
  const { t } = useTranslation();
  const [datasetKey, setDatasetKey] = useState<DatasetKey>("job");

  const { timelineRecords, refreshRecords } = useCoreHRRecords({
    userUuid: user.uuid,
    key: datasetKey,
  });

  return (
    <Flex flexDirection="column" padding="s">
      <Flex padding="s">
        <Flex gap="s" flexDirection="column">
          <Text espaceFont="h4">
            {t("peoplereview.export.title", {
              defaultValue: "Exporter",
            })}
          </Text>
          <a
            target="_blank"
            rel="noreferrer"
            href={dataLayer.buildRootURL(
              "CORE_HR",
              buildURL({
                params: { datasetKey, employeeID: user.uuid },
                path: "/datasets/{datasetKey}/employees/{employeeID}/export",
                target: "CORE_HR",
              })
            )}
          >
            Export as CSV
          </a>
        </Flex>
        <Flex flexDirection="column" maxWidth="40rem" width="100%" margin="0 auto">
          <Select
            placeholder="Aucun Dataset séléctionné"
            options={datasetKeys.map((option) => ({
              label: option.toString(),
              value: option,
            }))}
            value={datasetKey}
            clearable={false}
            isSearchable={false}
            onChange={(option) => {
              if (!option) return;
              setDatasetKey(option);
              refreshRecords();
            }}
          />
          <Flex padding="s" flexDirection="column">
            {timelineRecords
              ?.sort(sortByDate)
              .map((record) => (
                <UserCareerDevMonitoringCard datasetKey={datasetKey} record={record} />
              )) ?? <Loader fillSpace />}
          </Flex>
        </Flex>
      </Flex>
      <hr />
      <FieldsTimelines user={user} />
      <hr />
      <FieldsAtDate user={user} />
    </Flex>
  );
};

export default UserCareerDevMonitoring;
