import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import useTranslation from "hooks/useTranslation";

import { DSModal, RadioForm, Label as DSLabel, DSTooltip } from "@skillup/ui";

import type { Template } from "services/interviews";

import styles from "./ChooseCampaignTypeModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

const ChooseCampaignTypeModal = ({ template, isOpen, closeModal }: IProps) => {
  const [active, setActive] = useState<any>();
  const history = useHistory();

  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: any) => {
      setActive(value);
    },
    [setActive]
  );

  const handleSubmit = useCallback(() => {
    if (active === "legacy")
      history.push(`/responsable/campagne/creation-campagne-entretiens?template=${template.uuid}`);
    if (active === "ongoing")
      history.push(
        `/responsable/campagne/creation-entretiens-automatises?template=${template.uuid}`
      );
    if (active === "adHoc")
      history.push(`/responsable/campagne/creation-entretiens-ponctuels?template=${template.uuid}`);
  }, [active, history, template]);

  return (
    <DSModal isOpen={isOpen} className={styles.ChooseCampaignType}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("interviews.newCampain", {
            defaultValue: "Nouvelle campagne",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <DSLabel
          label={t("interviews.chooseCampaignTypeModal.selectType", {
            defaultValue: "Sélectionnez le type de campagne",
          })}
          required
          className={styles.label}
        />
        <RadioForm
          choices={getCampaignOptions(t)}
          active={active}
          onChange={handleChange}
          className={styles.radioform}
        />
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("interviews.chooseCampaignTypeModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSTooltip
          label={t("interviews.chooseCampaignTypeModal.tooltip", {
            defaultValue:
              "Avant de poursuivre, vous devez choisir le type de campagne que vous souhaitez créer.",
          })}
          disabled={!!active}
        >
          <DSModal.Footer.PrimaryButton
            label={t("interviews.chooseCampaignTypeModal.next", {
              defaultValue: "Suivant",
            })}
            onClick={handleSubmit}
            disabled={!active}
          />
        </DSTooltip>
      </DSModal.Footer>
    </DSModal>
  );
};

export { ChooseCampaignTypeModal };

const getCampaignOptions = (t) => {
  return [
    {
      label: t("interviews.chooseCampaignTypeModal.type.legacy", {
        defaultValue: "Campagne d'entretiens",
      }),
      value: "legacy",
      helpText: t("interviews.chooseCampaignTypeModal.type.legacy.helpText", {
        defaultValue:
          "Créer une campagne d’entretiens définie par une date de lancement et une date limite de signature",
      }),
    },
    {
      label: t("interviews.chooseCampaignTypeModal.type.ongoing", {
        defaultValue: "Entretiens automatisés",
      }),
      value: "ongoing",
      helpText: t("interviews.chooseCampaignTypeModal.type.ongoing.helpText", {
        defaultValue: "Paramétrer des entretiens qui se génèrent automatiquement à une date donnée",
      }),
    },
    {
      label: t("interviews.chooseCampaignTypeModal.type.oneOff", {
        defaultValue: "Entretiens ponctuels",
      }),
      value: "adHoc",
      helpText: t("interviews.chooseCampaignTypeModal.type.oneOff.helpText", {
        defaultValue: "Créer des entretiens manuellement pour répondre à des besoins ponctuels",
      }),
    },
  ];
};
