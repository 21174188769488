import React from "react";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import FuzzyCheckString from "utils/FuzzyCheckString";
import TextInput from "components/TextInput";
import Loader from "components/Loader";

import Icon from "components/Icon";
import {
  chevronBottom as chevronBottomIcon,
  chevronTop as chevronTopIcon,
  cross as crossIcon,
  trashBin as trashBinIcon,
} from "uiAssets/StrokeIcons";
import colors from "uiAssets/Colors";

import LoadingError from "./LoadingError";
import PortalCategoriesLazyRenderedList from "./PortalCategoriesLazyRenderedList";

import styles from "./PortalCategoriesStyles.module.scss";
import { TranslationType } from "hooks/useTranslation";

export interface ICategory {
  background: string;
  children?: Array<ICategory>;
  index: number;
  name: string;
  trainingCount?: number;
  uuid: string;
  lowercaseName: string;
  fuzzyScore: number;
}

export interface IState {
  allCategories?: Array<ICategory>;
  filteredAllCategories?: Array<ICategory>;
  isLoading: boolean;
  isRemovingCategory?: ICategory | null;
  loadingError: boolean;
  selectedCategories: Array<ICategory>;
}

export default class PortalCategories extends React.PureComponent<
  {
    t: TranslationType;
  },
  IState
> {
  public state: IState = {
    isLoading: true,
    loadingError: false,
    selectedCategories: [],
  };

  public async componentDidMount(): Promise<void> {
    try {
      const selectedCategories = (await DataLayer.request({
        url: "/v1/portal/tag",
      })) as any;

      let { tags: allCategories } = (await DataLayer.request({
        url: "/v1/data/catalog-by-tag",
      })) as any;

      allCategories = allCategories
        .sort((categoryA: ICategory, categoryB: ICategory) =>
          categoryA.name < categoryB.name ? -1 : 1
        )
        .map((category: ICategory) => ({
          ...category,
          fuzzyScore: 0,
          lowercaseName: (category.name || "").toLocaleLowerCase(),
        }));

      this.setState({
        allCategories,
        filteredAllCategories: allCategories,
        isLoading: false,
        selectedCategories,
      });
    } catch (error) {
      this.setState({
        loadingError: true,
      });
    }
  }

  private addCategory = async (category: ICategory): Promise<void> => {
    if ((this.state.selectedCategories || []).some((c) => c.uuid === category.uuid)) {
      return Acta.dispatchEvent("sendAppMessage", {
        message: "La catégorie est déjà sélectionnée.",
        type: "error",
      });
    }

    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/portal/tag/${category.uuid}`,
      });

      this.setState({
        selectedCategories: [...(this.state.selectedCategories || []), category].sort(
          (categoryA: ICategory, categoryB: ICategory) => (categoryA.name > categoryB.name ? 1 : -1)
        ),
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t(
          "trainings.view.portal_configuration.categories.toast.category_added_success",
          { defaultValue: "Catégorie ajoutée." }
        ),
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t(
          "trainings.view.portal_configuration.categories.toast.category_added_error",
          { defaultValue: "La catégorie n‘a pa pu être ajoutée." }
        ),
        type: "error",
      });
    }
  };

  private removeCategory = async (targetCategory: ICategory): Promise<void> => {
    try {
      await DataLayer.request({
        method: "DELETE",
        url: `/v1/portal/tag/${targetCategory.uuid}`,
      });
      this.setState({
        isRemovingCategory: null,
        selectedCategories: (this.state.selectedCategories || []).filter(
          (category: ICategory) => category.uuid !== targetCategory.uuid
        ),
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t(
          "trainings.view.portal_configuration.categories.toast.category_removed_success",
          { defaultValue: "Catégorie retirée." }
        ),
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t(
          "trainings.view.portal_configuration.categories.toast.category_removed_error",
          { defaultValue: "La catégorie n‘a pa pu être retirée." }
        ),
        type: "error",
      });
    }
  };

  private reorderCategory = async (
    targetCategory: ICategory,
    way: "up" | "down"
  ): Promise<void> => {
    const index = targetCategory.index;
    const updatedCategories = this.state.selectedCategories.map((category) => {
      let targetIndex: number = category.index;
      if (targetIndex === index) {
        targetIndex += way === "down" ? 1 : -1;
      } else if (way === "up" && targetIndex === index - 1) {
        targetIndex = index;
      } else if (way === "down" && targetIndex === index + 1) {
        targetIndex = index;
      }
      return {
        ...category,
        index: targetIndex,
      };
    });

    this.setState({ selectedCategories: updatedCategories });

    try {
      await DataLayer.request({
        body: JSON.stringify(
          updatedCategories
            .sort((categoryA: ICategory, categoryB: ICategory) =>
              categoryA.index > categoryB.index ? 1 : -1
            )
            .map((category, index) => ({
              uuid: category.uuid,
              index,
            }))
        ),
        method: "POST",
        url: "/v1/portal/tag/sort",
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t(
          "trainings.view.portal_configuration.categories.toast.update_success",
          { defaultValue: "Modifications enregistrées." }
        ),
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: this.props.t("trainings.view.portal_configuration.categories.toast.update_error", {
          defaultValue: "Echec d’enregistrement.",
        }),
        type: "error",
      });
    }
  };

  private onChangeFilter = (event: React.FormEvent<HTMLInputElement>): void => {
    const testString: string = event.currentTarget.value.toLowerCase();
    let allCategories: Array<ICategory> = this.state.allCategories || [];
    if (!testString || testString === "") {
      return this.setState({
        filteredAllCategories: this.state.allCategories,
      });
    }
    const categoriesCount: number = allCategories.length;
    let index = 0;
    for (index; index < categoriesCount; index++) {
      const res = FuzzyCheckString(
        // @ts-ignore
        (allCategories[index] || "").lowercaseName,
        testString
      );
      (allCategories[index] as ICategory).fuzzyScore = res.score;
    }
    allCategories = allCategories.sort((categoryA: ICategory, categoryB: ICategory) =>
      categoryA.fuzzyScore > categoryB.fuzzyScore ? -1 : 1
    );
    this.setState({
      filteredAllCategories: [...allCategories],
    });
  };

  public render(): JSX.Element {
    const { t } = this.props;
    const {
      filteredAllCategories,
      isLoading,
      isRemovingCategory,
      loadingError,
      selectedCategories,
    } = this.state;

    const selectedCategoriesUUIDs: Array<string> = (selectedCategories || []).map(
      (category: ICategory): string => category.uuid
    );

    if (loadingError) return <LoadingError />;

    if (isLoading) return <Loader />;

    return (
      <div className={styles.PortalCategories}>
        <h2>
          {t("trainings.view.portal_configuration.categories.selected_categories.title", {
            defaultValue: "Catégories sélectionnées",
          })}
        </h2>
        {(selectedCategories || [])
          .sort((categoryA: ICategory, categoryB: ICategory) =>
            categoryA.index > categoryB.index ? 1 : -1
          )
          .map((category: ICategory, index: number) => (
            <div key={category.uuid}>
              <span>{`${index} - ${category.name}`}</span>
              <div>
                {index > 0 && (
                  <button onClick={() => this.reorderCategory(category, "up")}>
                    <Icon strokeIcon={chevronTopIcon} width={12} />
                  </button>
                )}
                {index < (selectedCategories || []).length - 1 && (
                  <button onClick={() => this.reorderCategory(category, "down")}>
                    <Icon strokeIcon={chevronBottomIcon} width={12} />
                  </button>
                )}
                {isRemovingCategory && isRemovingCategory === category ? (
                  <React.Fragment>
                    <button onClick={() => this.removeCategory(category)}>
                      <span style={{ color: colors.error }}>
                        {t(
                          "trainings.view.portal_configuration.categories.selected_categories.action.remove_confirm",
                          { defaultValue: "confirmer" }
                        )}
                      </span>
                      <Icon strokeIcon={trashBinIcon} width={12} stroke={colors.error} />
                    </button>
                    <button onClick={() => this.setState({ isRemovingCategory: null })}>
                      <span style={{ color: colors.blue }}>
                        {t(
                          "trainings.view.portal_configuration.categories.selected_categories.action.remove_cancel",
                          { defaultValue: "annuler" }
                        )}
                      </span>
                      <Icon strokeIcon={crossIcon} width={8} />
                    </button>
                  </React.Fragment>
                ) : (
                  <button onClick={() => this.setState({ isRemovingCategory: category })}>
                    <Icon strokeIcon={trashBinIcon} width={12} stroke={colors.error} />
                  </button>
                )}
              </div>
            </div>
          ))}
        <section>
          <TextInput
            label={t(
              "trainings.view.portal_configuration.categories.selected_categories.action.search_category.label",
              { defaultValue: "Chercher une catégorie" }
            )}
            placeholder={t(
              "trainings.view.portal_configuration.categories.selected_categories.action.search_category.placeholder",
              { defaultValue: "nom approximatif" }
            )}
            alwaysOpen
            background="#fff"
            onChange={this.onChangeFilter}
          />
          <PortalCategoriesLazyRenderedList
            t={this.props.t}
            allCategories={(filteredAllCategories || []).filter(
              (category: ICategory) => !selectedCategoriesUUIDs.includes(category.uuid)
            )}
            addCategory={this.addCategory}
          />
        </section>
      </div>
    );
  }
}
