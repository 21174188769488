import { FormatDate, ParseDate } from "@skillup/shared-utils";
import {
  DSProgressBar,
  dataInPercent,
  DSFormGroupTextInput,
  DSTextInput,
  TypeProgressBar,
  Loader,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
  COLORS,
} from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";
import { useDashboardData } from "../../context";
import styles from "./Summary.module.scss";

function Summary({ t }: { t: TranslationType }) {
  const { dashboardData, activeCampaign, isLoading } = useDashboardData();
  const campaignData = dashboardData?.[activeCampaign];

  if (isLoading || !campaignData) {
    return (
      <div className={styles.Summary}>
        <Loader />
      </div>
    );
  }

  const startDate = ParseDate.FromISO(campaignData.campaign.startDate);
  const endDate = ParseDate.FromISO(campaignData.campaign.endDate);

  return (
    <>
      <div id="summary" />
      <div className={styles.Summary}>
        {isLoading && <Loader />}
        <div className={styles.header}>
          <label className={styles.title}>
            {t("dashboard.interviews.summary.title", { defaultValue: "Synthèse" })}
          </label>
          {startDate.isValid && (
            <DSFormGroupTextInput
              label={t("dashboard.interviews.summary.startDate", {
                defaultValue: "Date de lancement de la campagne",
              })}
              darkMode
            >
              <DSTextInput
                name="date1"
                readOnly
                className={styles.dateInput}
                value={FormatDate.ToStringFormat(startDate, "dd/MM/yyyy")}
                darkMode
              />
            </DSFormGroupTextInput>
          )}
          {endDate.isValid && (
            <DSFormGroupTextInput
              label={t("dashboard.interviews.summary.endDate", {
                defaultValue: "Date limite de signature",
              })}
              darkMode
            >
              <DSTextInput
                name="date2"
                readOnly
                className={styles.dateInput}
                value={FormatDate.ToStringFormat(endDate, "dd/MM/yyyy")}
                darkMode
              />
            </DSFormGroupTextInput>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.title}>
            <label>
              {t("dashboard.interviews.summary.employee", {
                defaultValue: "{{ count }} collaborateur",
                defaultValue_other: "{{ count }} collaborateurs",
                count: campaignData.interview.collaborators,
              })}
            </label>
            <p>
              {t("dashboard.interviews.summary.manager", {
                defaultValue: "{{ count }} responsable d'entretien",
                defaultValue_other: "{{ count }} responsables d'entretien",
                count: campaignData.interview.managers,
              })}
            </p>
          </div>
          <DSProgressBar
            className={styles.progressBar}
            darkMode
            layout="segmented"
            legendsLayout="column"
            type={TypeProgressBar.PERCENTAGE}
            data={dataInPercent([
              {
                label: t("dashboard.interviews.summary.notShared", {
                  defaultValue: "Préparations non partagées",
                }),
                value: campaignData.interview.notShared,
                color: "grey" as COLORS,
              },
              {
                label: t("dashboard.interviews.summary.shared", {
                  defaultValue: "Préparation partagée par au moins une des deux parties",
                }),
                value: campaignData.interview.shared,
                color: "blue" as COLORS,
              },
              {
                label: t("dashboard.interviews.summary.signed", {
                  defaultValue: "Entretien signé par au moins une des deux parties",
                }),
                value: campaignData.interview.signed,
                color: "green" as COLORS,
              },
            ])}
          />
        </div>
      </div>
      {endDate.diffNow("days").days > 0 && (
        <DSAlert type={DSAlertType.INFO} display={DSAlertDisplay.INLINE} className={styles.Alert}>
          {t("dashboard.interviews.summary.alert", {
            defaultValue:
              "Seules les réponses des préparations partagées sont consignées ci-dessous. Elles sont susceptibles d’évoluer jusqu’à ce que la campagne soit clôturée (la date limite de signature est fixée au {{ endDate }}).",
            endDate: FormatDate.ToStringFormat(endDate, "dd/MM/yyyy"),
          })}
        </DSAlert>
      )}
    </>
  );
}

export default Summary;
