export enum FeatureFlags {
  // modules
  INTERVIEWS = "interviews",
  TRAININGS = "trainings",
  SKILLS = "competences",
  REGULATORY = "reglementaire",

  // new access list v2 feature flags
  ACCESS_LIST_V2 = "access-list-v2",

  TRAINING_DASHBOARD = "training-dashboard",
  INTERVIEW_DASHBOARD = "interview-dashboard",

  TRAINING_SCHEDULE_PLAN = "trainings-schedule-plan",
  TRAINING_SCHEDULE_COLLECTION = "trainings-schedule-collection",

  TRAINING_REGULATORY_HABILITATIONS = "trainings-regulatory-habilitations",
  TRAINING_REGULATORY_TRACKINGS = "trainings-regulatory-trackings",

  TRACKING_INTRAS_ORGANIZATION = "trainings-intras-organization",
  TRAINING_INTRAS_SKILLUP = "trainings-intras-skillup",

  TRAINING_SESSIONS = "trainings-sessions",

  TRAINING_HOT_EVAL = "trainings-hot-evaluations",
  TRAINING_COLD_EVAL = "trainings-cold-evaluations",

  INTERVIEW_TRACKINGS = "interviews-trackings",
  INTERVIEW_TEMPLATES = "interviews-templates",

  SKILLS_JOBS = "skills-jobs",
  SKILLS_REQUIRED = "skills-required",
  SKILLS_EMPLOYEES = "skills-employees",

  EMPLOYEES = "employees",

  CONFIGURATION_ADMIN_PLATFORM = "configuration-admin-platform",
  CONFIGURATION_PLANS = "configuration-plans",
  CONFIGURATION_PORTAIL = "configuration-portail",
  CONFIGURATION_IMPORTS = "configuration-imports",

  PEOPLE_REVIEW = "people-review",

  CAREER_DATA = "career-data",
  SALARY_DATA = "salary-data",
  REVIEW_CRITERIA = "review-criteria",

  DISABLE_CUSTOM_TRAINING_FIELDS = "custom-training-fields",

  // admin feature flags
  SKILLS_TAB_V2 = "skills-tab-v2",
  DEMO = "demo",
  CAMPAIGN_CREATION = "campaign-creation",
  SKILLS_COLLABORATOR_V2 = "skills-collaborator-v2",
  JOBS_V2 = "jobs-tab-v2",
  MANAGER_SKILLS = "manager-skills",

  // old design
  MY_ACCOUNT = "my-account",
  USERS = "users",

  ADMIN_TRAINER = "admin-trainer",
  TRAINER = "trainer",
  ADMIN_WITHOUT_INTRAS = "admin-without-intras",
  TRAINER_WITHOUT_INTRAS = "trainer-without-intras",
  TRAINER_QHSE = "trainer-qhse",
  CUSTOM = "custom",
}

export interface Settings {
  portalLogo?: string;
  portalBackground?: string;
  portalIntroMessage?: string;
  portalIntroMessageColor?: string;
  portalIntroButtonColor?: string;
  portalIntroButtonBackgroundColor?: string;
  portalHRSpecificTrainingEnabled?: boolean;
  portalHRUploadUserDBEnabled?: boolean;
  features: FeatureFlags[];
}
