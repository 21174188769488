import { PermissionRoles } from "../types";
import { DSRadio } from "@skillup/ui";
import { DSRadioGroup } from "@skillup/ui";
import { TranslationType } from "hooks/useTranslation";

export const TableChildColumnPermissionsRadioFormRoles = ({
  childId,
  columnUuid,
  role,
  onGrantForRole,
  t,
}: {
  childId: string;
  columnUuid: string;
  role: PermissionRoles;
  onGrantForRole: (selectedRole: PermissionRoles) => void;
  t: TranslationType;
}) => {
  return (
    <DSRadioGroup name={"table-child-role-permissions" + childId + columnUuid}>
      <DSRadio
        label={t("interviews.templateBuilder.choices.employee", {
          defaultValue: "Le collaborateur",
        })}
        checked={role === "employee"}
        onChange={() => onGrantForRole("employee")}
      />
      <DSRadio
        label={t("interviews.templateBuilder.choices.manager", {
          defaultValue: "Le responsable d'entretien",
        })}
        checked={role === "manager"}
        onChange={() => onGrantForRole("manager")}
      />
    </DSRadioGroup>
  );
};
