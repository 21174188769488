import { useToasts } from "react-toast-notifications";
import { useMemo, Suspense, useState, useCallback } from "react";

import { Select, DSModal } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { Assignee, DatatableJob, SuggestionsJob } from "types/skills";

import { AssignModal } from "./Assignments.styled";
import { useAssignmentsContext } from "./AssignmentsContext";
import { JobSelectionDropdown } from "./components/JobSelectionDropdown";
import { useSuggestionsContext } from "../Suggestions/SuggestionsContext";
import { associateJob } from "../Suggestions/components/handler/SuggestionAcceptHandler";

interface AssignEmployeeToJobModalV4Props {
  onClose: () => void;
  jobs: Array<DatatableJob>;
  employees: Array<Assignee>;
  suggestions: Array<SuggestionsJob>;
}

const sortByName = (a: DatatableJob, b: DatatableJob) => a.name.localeCompare(b.name);

export const AssignmentsModal = ({
  employees,
  jobs,
  onClose,
  suggestions,
}: AssignEmployeeToJobModalV4Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const currentJobUuid = employees.length === 1 ? employees[0].jobID : undefined;

  const suggestionsForSelectedEmployees = useMemo(
    () =>
      suggestions.filter((suggestion) =>
        suggestion.employees.some((employee) =>
          employees.some(({ uuid }) => uuid === employee.employeeID)
        )
      ),
    [employees, suggestions]
  );

  const remapEmployeesBySuggestionEmployee = useMemo(() => {
    return suggestions.flatMap((suggestion) =>
      suggestion.employees.filter((suggestionEmployee) =>
        employees.some((employee) => employee.uuid === suggestionEmployee.employeeID)
      )
    );
  }, [suggestions, employees]);

  const [selectedJobUuid, setSelectedJobUuid] = useState<null | string>(currentJobUuid);

  const sortedJobs = useMemo(() => jobs.sort(sortByName), [jobs]);

  const {
    assignEmployeeToJob: { isLoading, mutator, refetch },
  } = useAssignmentsContext();
  const suggestionsJobs = useSuggestionsContext();
  const { refetch: refetchPending } = suggestionsJobs.getJobSuggestions("pending");
  const { refetch: refetchCancelled } = suggestionsJobs.getJobSuggestions("cancelled");

  const handleSubmit = useCallback(async () => {
    const targetJob = jobs.find(({ uuid }) => uuid === selectedJobUuid);

    const employeesArray = employees.map(({ uuid, companyID, version }) => ({
      companyUuid: companyID,
      employeeUuid: uuid,
      version: version ? version + 1 : 1,
    }));

    const payload = associateJob(remapEmployeesBySuggestionEmployee, selectedJobUuid);

    const selectedJobNotInSuggestionsForAnEmployee = remapEmployeesBySuggestionEmployee
      .filter(({ employeeSuggestions }) =>
        employeeSuggestions.every(({ jobId }) => jobId !== selectedJobUuid)
      )
      .map(({ employeeID }) => employeeID);

    if (remapEmployeesBySuggestionEmployee.length === 0) {
      await mutator(employeesArray, {
        uuid: targetJob?.uuid,
        name: targetJob?.name,
      });
      onClose();
    } else {
      try {
        if (selectedJobNotInSuggestionsForAnEmployee.length !== 0) {
          const matchedEmployees = employees
            .filter(({ uuid }) => selectedJobNotInSuggestionsForAnEmployee.includes(uuid))
            .map(({ uuid, companyID, version }) => ({
              companyUuid: companyID,
              employeeUuid: uuid,
              version: version ? version + 1 : 1,
            }));

          await mutator(matchedEmployees, {
            uuid: targetJob?.uuid,
            name: targetJob?.name,
          });
        }
        await suggestionsJobs.acceptSuggestions(payload);
        refetch();
        refetchPending();
        refetchCancelled();
        if (selectedJobNotInSuggestionsForAnEmployee.length === 0) {
          addToast(
            t("portal.config.employee.assignEmployeeToJob.success", {
              defaultValue: "La fiche de poste a bien été assignée",
            }),
            { appearance: "success" }
          );
        }
      } catch (e) {
        addToast(
          t("skills.suggestions.accept.error", {
            defaultValue: "Une erreur est survenue",
          }),
          { appearance: "error" }
        );
      } finally {
        onClose();
      }
    }
  }, [
    jobs,
    employees,
    selectedJobUuid,
    remapEmployeesBySuggestionEmployee,
    mutator,
    onClose,
    suggestionsJobs,
    refetch,
    refetchPending,
    refetchCancelled,
    addToast,
    t,
  ]);

  return (
    <AssignModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("skills.list.collaborator.assign", {
            defaultValue: "Associer une fiche de poste",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Text display="flex" espaceFont="captionBold">
          {employees.length > 1 ? (
            <span>
              {t("portal.config.employee.assignEmployeeToJob.multipleAssignLabel", {
                defaultValue: "Fiche de poste à associer à ces collaborateurs",
              })}
            </span>
          ) : (
            <span>
              {t("portal.config.employee.assignEmployeeToJob.simpleAssignLabel", {
                defaultValue: "Fiche de poste à associer à {{fullName}}",
                fullName: employees[0].fullName,
              })}
            </span>
          )}
          <Text marginLeft="xxs" color="decoration-red-base">
            *
          </Text>
        </Text>

        <Flex marginBottom="xs">
          <Suspense
            fallback={
              <Select
                options={[]}
                placeholder={t("skills.list.collaborator.loading", {
                  defaultValue: "Récupération de la liste des fiches de poste...",
                })}
              />
            }
          >
            <JobSelectionDropdown
              t={t}
              jobs={sortedJobs}
              selectedJobUuid={selectedJobUuid}
              setSelectedJobUuid={setSelectedJobUuid}
              suggestions={suggestionsForSelectedEmployees}
            />
          </Suspense>
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("skills.list.collaborator.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={isLoading}
          disabled={currentJobUuid === selectedJobUuid}
          onClick={handleSubmit}
          label={t("skills.list.collaborator.assignJob", {
            defaultValue: "Associer la fiche de poste",
          })}
        />
      </DSModal.Footer>
    </AssignModal>
  );
};
