import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";
import { Divided } from "../ChildWrappers";

import { BuilderFormInput } from "../../BuilderFormInput";

import styles from "./JobChild.module.scss";

type JobChildType = Extract<Child, { kind: "job" }>;

type JobChildProps = {
  child: JobChildType;
  updateChild: (newChild: Partial<JobChildType>) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  t: TranslationType;
};

export function JobChild({ child, indexes, updateChild, t }: JobChildProps) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: (
        <>
          {t("interviews.templateBuilder.job.assistiveText.default", {
            defaultValue:
              "Le bloc « Fiche de poste » affiche la description de la fiche de poste associée au collaborateur.",
          })}
        </>
      ),
    };
  }, [t]);
  const changeTitle = useCallback(
    (value: string) => {
      updateChild({ title: value });
    },
    [updateChild]
  );

  return (
    <Divided>
      <Flex column className={styles.jobChild}>
        <BuilderFormInput
          type="text"
          value={child.title}
          onChange={changeTitle}
          debounceValue={300}
          label={t("interviews.templateBuilder.job.label", { defaultValue: "Libellé du bloc" })}
          name={`${child.uuid}-job-title`}
          placeholder={t("interviews.templateBuilder.job.placeholder", {
            defaultValue: "Saisissez un libellé",
          })}
          multipleAssistiveTexts={multipleAssistiveTexts}
          autoFocus={highlightUuid === child.uuid}
        />
      </Flex>
      <Flex column>
        <Permissions
          uuid={child.uuid}
          indexes={indexes}
          kind="job"
          restrictions={child.disallow}
          override={{
            comment: t("interviews.templateBuilder.job.permissions.comment", {
              defaultValue: "Qui peut commenter la fiche de poste ?",
            }),
          }}
          t={t}
        />
      </Flex>
    </Divided>
  );
}
