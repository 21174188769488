import { isUndefined } from "lodash";
import { Status as DSStatus, StatusType } from "@skillup/ui";
import { Fragment } from "react";

import Loader from "components/Loader";
import DateUtils from "utils/dates";
import { TranslationType } from "hooks/useTranslation";

import { Campaign } from "./modals/useCampaignModals";
import { eventOffsetDirectionKeyToValue, eventOffsetUnitKeyToValue } from "utils/campaigns";
import styles from "./CampaignDetailsStyles.module.scss";

interface IProps {
  readonly isLoading: boolean;
  readonly campaign?: Campaign;
  readonly t: TranslationType;
}


const Meta = ({ campaign, t }: { campaign: Campaign; t: TranslationType }): JSX.Element | null => {
  switch (campaign.type) {
    case "legacy": {
      const [startDate, endDate] = DateUtils.formatDates([campaign.startDate, campaign.endDate]);

      return (
        <Fragment>
          <div className={styles.dateBlock}>
            <label>
              {t("campaign.campaignStartDate", {
                defaultValue: "Date de début de campagne",
              })}
            </label>
            <span>{startDate}</span>
          </div>
          <div className={styles.dateBlock}>
            <label>
              {t("campaign.campaignSignDate", {
                defaultValue: "Date limite de signature",
              })}
            </label>
            <span>{endDate}</span>
          </div>
        </Fragment>
      );
    }
    case "ongoing": {
      const { duration, frequency } = campaign;

      return (
        <Fragment>
          <div className={styles.automated}>
            <div className={styles.dateBlock}>
              <label>
                {t("campaign.startDate", {
                  defaultValue: "Date de début :",
                })}
              </label>
              <span>
                {t("campaign.daysAfterStart", {
                  defaultValue: "{{count}} jour après l'embauche",
                  defaultValue_other: "{{count}} jours après l'embauche",
                  count: frequency,
                })}
              </span>
            </div>
            <div className={styles.dateBlock}>
              <label>
                {t("campaign.signLimit", {
                  defaultValue: "Délai de signature :",
                })}
              </label>
              <span>
                {t("campaign.duration", {
                  defaultValue: "{{count}} jour",
                  defaultValue_other: "{{count}} jours",
                  count: duration,
                })}
              </span>
            </div>
          </div>
        </Fragment>
      );
    }
    case "adHoc": {
      switch (campaign.eventType) {
        case "joinDate": {
          const { eventOffset, eventOffsetUnit, eventOffsetDirection, duration } = campaign;
          return (
            <div className={styles.dateBlock}>
              {/* <DSTooltip label=""> TODO: handle the display of the types of population filtered*/}
              <DSStatus
                type={StatusType.INFO}
                label={t("interviews.campaign.detail.ongoing.trigger", {
                  defaultValue: "Entretiens automatisés",
                })}
                withIcon
              />
              {/* </DSTooltip> */}
              <label>Déclencheur</label>
              <span>
                {eventOffset} {eventOffsetUnitKeyToValue(eventOffsetUnit, eventOffset, t)}{" "}
                {eventOffsetDirectionKeyToValue(eventOffsetDirection, t)}{" "}
                {t("interviews.campaign.detail.joinDate", { defaultValue: "l'embauche" })}
              </span>
              <label>
                {t("campaign.signLimit", {
                  defaultValue: "Délai de signature :",
                })}
              </label>
              <span>
                {t("campaign.duration", {
                  defaultValue: "{{count}} jour",
                  defaultValue_other: "{{count}} jours",
                  count: duration,
                })}
              </span>
            </div>
          );
        }
        default:
          return (
            <div className={styles.dateBlock}>
              <label>
                {t("campaign.manualTrigger", {
                  defaultValue: "Déclenchement manuel",
                })}
              </label>
            </div>
          );
      }
    }
    default:
      return <></>;
  }
};

const CampaignDetails = ({ isLoading, campaign, t }: IProps): JSX.Element => {
  if (isLoading || isUndefined(campaign)) {
    return <Loader />;
  }

  return (
    <aside className={styles.CampaignDetails}>
      <div className={styles.meta}>
        <Meta campaign={campaign} t={t} />
      </div>
    </aside>
  );
};

export default CampaignDetails;
