import { DateTime, Interval, Settings, DateTimeFormatOptions } from "luxon";
import {
  Locale,
  parse,
  format,
  isBefore,
  isValid,
  getTime,
  formatISO,
  eachMonthOfInterval,
  parseISO,
  differenceInCalendarMonths,
  differenceInCalendarDays,
  lightFormat,
  isToday,
  getYear,
  intervalToDuration,
} from "date-fns";
import { fr, enGB } from "date-fns/locale";

import { convertToTimeZone } from "date-fns-timezone";

const convertToParisTimeZone = (date: Date) =>
  convertToTimeZone(date, { timeZone: "Europe/Paris" });

import Timespan from "./timespan";
import Duration from "./duration";
import compactObject from "./compactObject";
import NumberUtil from "./number";
import { ArrayUtils } from "./array";
import { isSameDay } from "./isSameDay/isSameDay";
import { shortString, resolveString } from "./shortener";
export * as ParseDate from "./parse";
export * as FormatDate from "./format";
export * as LocaleDate from "./locale";
export * as DateUtils from "./date";
export * as ListUtils from "./list";

export * as PromiseUtil from "./promise";

export { compactObject, ArrayUtils, Duration, Timespan, NumberUtil, shortString, resolveString };

export default {
  compactObject,
  Duration,
  Timespan,
  NumberUtil,
};
export {
  Locale,
  fr,
  enGB,
  parse,
  format,
  isBefore,
  isValid,
  getTime,
  formatISO,
  convertToParisTimeZone,
  eachMonthOfInterval,
  parseISO,
  differenceInCalendarMonths,
  differenceInCalendarDays,
  lightFormat,
  isToday,
  getYear,
  intervalToDuration,
  DateTime,
  DateTimeFormatOptions,
  Interval,
  Settings,
  isSameDay,
};

export type { Compute } from "./types";

export type { ISODurationString } from "./duration";

export { UC, hexa } from "./hexa";
export { Errors, ErrorsTypes, ResponseError } from "./errors";
export { renderCellDate } from "./locale/renderDataTableCellDate";
export { formatToLocaleDate } from "./locale/formatDate";

export {
  formatMonetary,
  getMonetarySymbol,
  DefaultCurrency,
  SupportedCurrencies,
  type Currency,
  type FormatMonetaryOptions,
} from "./currency";

export { Locales } from "./locale";

export { getInitials } from "./user";

export * from "./skills";
