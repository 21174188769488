import { useCallback, useState } from "react";
import { useSetState } from "react-use";

import { isUndefined } from "lodash";
import { DropDownCheckbox, Select } from "@skillup/ui";

import useSettings from "hooks/useSettings";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import type { OrganizationRoutesType } from "types/api";

import { IUserIdentity } from "containers/Supervisor/routes/Projects/Components/UserSearchInput";
import Button from "components/Button";
import FindUser from "components/FindUser";

import { selectOptionsEnabled, useAdminData } from "../../pages/ManageAdmins";
import styles from "./AddAdminModal.module.scss";
import useTranslation from "hooks/useTranslation";

interface IProps {
  readonly refresh: () => void;
}

interface State {
  readonly user?: IUserIdentity;
  readonly userAreas?: Array<{ label: string; value: string; isSelected: boolean }>;
  readonly userScope?: string;
}

const AddAdminModal = ({ refresh }: IProps) => {
  const {
    activeCompany: { areas: possibleUserAreas, uuid: companyUuid },
  } = Acta.getState("userData");

  const [state, setState] = useSetState<State>({
    user: undefined,
    userAreas: possibleUserAreas.map((e) => ({
      label: e.name,
      value: e.uuid,
      isSelected: false,
    })),
    userScope: "Admin",
  });

  const [isLoading, setIsLoading] = useState(false);

  const onChange = useCallback(
    (field) => (e) => {
      setState({ [field]: e });
    },
    [setState]
  );
  const { onChangeScopeAccess } = useAdminData();
  const createAdmin = useCallback(
    async (companyUuid: string, userUuid: string, areas: string[], scope: string) => {
      try {
        setIsLoading(true);
        await buildRequest<OrganizationRoutesType.CreateAdmin>({
          method: "POST",
          path: "/organization/{companyUuid}/admin",
          params: { companyUuid },
          payload: { userUuid, areas },
        })();

        await onChangeScopeAccess(userUuid)(scope);

        Acta.dispatchEvent("sendAppMessage", {
          message: `Administrateur ajouté avec succès`,
          type: "success",
        });
        Acta.dispatchEvent("closeModal");
        refresh();
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: `Une erreur est survenue lors de l'ajout de l'administrateur`,
          type: "error",
        });
      } finally {
        setIsLoading(false);
        window.location.reload();
      }
    },
    [onChangeScopeAccess, refresh]
  );

  const { user, userAreas, userScope } = state;
  const { settings } = useSettings();
  const { adminOptions, hasAdminOptions } = selectOptionsEnabled(settings);
  const { t } = useTranslation();

  return (
    <div className={styles.NewUserModal}>
      <span className={styles.label}>Collaborateur</span>
      <FindUser user={user} onSelect={onChange("user")} showRightIcon t={t} />
      {user && (
        <div>
          <div className={styles.perimetersBlock}>
            <span className={styles.label}>Périmètre(s)</span>
            <DropDownCheckbox
              items={userAreas}
              onChange={onChange("userAreas")}
              labels={{
                itemSingular: "périmètre",
                itemPlural: "périmètres",
                allItemsSelected: "Tous mes périmètres",
                noItemSelected: "Aucun périmètre",
              }}
              className={styles.userAreaDropdown}
            />
          </div>
          {hasAdminOptions && (
            <div className={styles.roleBlock}>
              <span className={styles.label}>Niveau d'accès</span>
              <Select
                className={styles.select}
                value={state.userScope}
                options={adminOptions}
                onChange={onChange("userScope")}
              />
            </div>
          )}
        </div>
      )}
      <footer className={styles.actions} data-target="add-admin">
        {isUndefined(user) && (
          <Button onClick={() => Acta.dispatchEvent("closeModal")} className={styles.backButton}>
            Annuler
          </Button>
        )}

        {user && (
          <Button
            onClick={() => setState({ user: undefined })}
            disabled={isUndefined(user) || isLoading}
            className={styles.backButton}
          >
            Retour
          </Button>
        )}

        <Button
          id="add-admin"
          disabled={isUndefined(user) || isLoading}
          onClick={() => {
            const selectedAreas = userAreas.filter((e) => e.isSelected).map((e) => e.value);
            if (selectedAreas.length === 0) {
              Acta.dispatchEvent("sendAppMessage", {
                message: `Vous devez sélectionner au moins un périmètre.`,
                type: "error",
              });
            } else {
              createAdmin(companyUuid, user.uuid, selectedAreas, userScope);
            }
          }}
        >
          Ajouter l'administrateur
        </Button>
      </footer>
    </div>
  );
};

export default AddAdminModal;
