import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { DSDropdownAsyncItem, DSDropdownItem } from "@skillup/ui";

import { type Template, useTemplate } from "services/interviews";
import { State, ModalStates } from "../hooks";
import { useCallback } from "react";
import { TranslationType } from "hooks/useTranslation";

type OpenModalActionsProps = {
  template: Template;
  setActionsState: (state: State) => void;
  t?: TranslationType;
};

type PreviewActionsProps = {
  as: "manager" | "collab" | "hr";
  asyncAction: () => Promise<void>;
  label?: string;
  t: TranslationType;
};

const Edit = ({
  template,
  setActionsState,
  relatedCampaigns,
  t,
}: {
  template: Template;
  setActionsState: (state: State) => void;
  relatedCampaigns?: { uuid: string; title: string; archivedAt?: string }[];
  t: TranslationType;
}) => {
  const { push } = useHistory();
  const unarchivedRelatedCampaigns = relatedCampaigns?.filter(
    (relatedCampaign) => !relatedCampaign?.archivedAt
  );

  const onClickAction = useCallback(() => {
    if (unarchivedRelatedCampaigns && unarchivedRelatedCampaigns?.length <= 0)
      return push(`/responsable/template/${template.uuid}`);
    return setActionsState({ state: ModalStates.Edit, template });
  }, [push, setActionsState, template, unarchivedRelatedCampaigns]);

  return (
    <DSDropdownItem
      key="edit"
      label={t("interviews.templates.action.edit", { defaultValue: "Modifier la trame" })}
      onClick={onClickAction}
    />
  );
};

const EditParams = ({ template, setActionsState, t }: OpenModalActionsProps) => {
  const openEditParamsModal = () => setActionsState({ state: ModalStates.EditParams, template });
  return (
    <DSDropdownItem
      key="editParams"
      label={t("interviews.templates.action.editParams", {
        defaultValue: "Paramètres généraux de la trame",
      })}
      onClick={openEditParamsModal}
    />
  );
};

const EditTargetSettings = ({ template, setActionsState, t }: OpenModalActionsProps) => {
  const openEditTargetSettingsModal = () =>
    setActionsState({ state: ModalStates.EditTargetSettings, template });
  return (
    <DSDropdownItem
      key="editTargetSettings"
      label={t("interviews.templates.action.editTargetSettings", {
        defaultValue: "Paramètres liés aux objectifs",
      })}
      onClick={openEditTargetSettingsModal}
    />
  );
};

const Rename = ({ template, setActionsState, t }: OpenModalActionsProps) => {
  const openRenameModal = () => setActionsState({ state: ModalStates.Rename, template });
  return (
    <DSDropdownItem
      label={t("interviews.templates.action.rename", { defaultValue: "Renommer la trame" })}
      onClick={openRenameModal}
      key="rename"
    />
  );
};
const Archive = ({ template, setActionsState, t }: OpenModalActionsProps) => {
  const openArchiveModal = () => setActionsState({ state: ModalStates.Archive, template });
  return (
    <DSDropdownItem
      label={t("interviews.templates.action.archive", { defaultValue: "Archiver la trame" })}
      onClick={openArchiveModal}
      key="archive"
    />
  );
};

const TransferCopy = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openTransferCopyModal = () =>
    setActionsState({ state: ModalStates.TransferCopy, template });
  return (
    <DSDropdownItem
      key="transfer-copy"
      label="[OPS] Transférer une copie de la trame vers une autre entreprise"
      onClick={openTransferCopyModal}
    />
  );
};

const DownloadPDF = ({ templateUuid, t }: { templateUuid: string; t: TranslationType }) => {
  const { addToast } = useToasts();
  const templateActions = useTemplate(templateUuid);

  return (
    <DSDropdownAsyncItem
      label={t("interviews.templates.action.download", {
        defaultValue: "Télécharger le PDF de la trame",
      })}
      asyncAction={async () => {
        try {
          await templateActions.downloadPDF();
          addToast(
            t("interviews.templates.download.success", { defaultValue: "Téléchargement réussi" }),
            { appearance: "success" }
          );
        } catch (e) {
          addToast(
            t("interviews.templates.download.error", {
              defaultValue: "Échec lors du téléchargement de la trame",
            }),
            {
              appearance: "error",
            }
          );
        }
      }}
    />
  );
};

const Preview = ({ as, asyncAction, label, t }: PreviewActionsProps) => {
  switch (as) {
    case "collab":
      return (
        <DSDropdownAsyncItem
          key="previewCollab"
          label={
            label ??
            t("interviews.templates.action.preview.collab", {
              defaultValue: "Prévisualiser en tant que collaborateur",
            })
          }
          asyncAction={asyncAction}
        />
      );
    case "manager":
      return (
        <DSDropdownAsyncItem
          key="previewManager"
          label={
            label ??
            t("interviews.templates.action.preview.manager", {
              defaultValue: "Prévisualiser en tant que responsable",
            })
          }
          asyncAction={asyncAction}
        />
      );
    case "hr":
      return (
        <DSDropdownAsyncItem
          key="previewHR"
          label="[DEV] Prévisualiser en tant que RH"
          asyncAction={asyncAction}
        />
      );
  }
};

const CreateCampaign = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openChooseCampaignTypeModal = () =>
    setActionsState({ state: ModalStates.ChooseCampaignType, template });
  return (
    <DSDropdownItem
      key="createCampaign"
      label="[OPS] Créer une campagne en utilisant cette trame"
      onClick={openChooseCampaignTypeModal}
    />
  );
};

const Duplicate = ({
  template,
  onClose,
  t,
}: {
  template: Template;
  onClose?: () => void;
  t: TranslationType;
}) => {
  const templateActions = useTemplate(template.uuid);

  return (
    <DSDropdownItem
      key="duplicateTemplate"
      label={t("interviews.templates.action.duplicate", { defaultValue: "Dupliquer la trame" })}
      onClick={async () => {
        await templateActions.duplicate(template);
        if (onClose) onClose();
      }}
    />
  );
};

export const TemplateActions = {
  Edit,
  EditParams,
  EditTargetSettings,
  Rename,
  Archive,
  TransferCopy,
  DownloadPDF,
  Preview,
  CreateCampaign,
  Duplicate,
};
