import React, { useEffect, useCallback, useRef, useMemo } from "react";
import cx from "classnames";
import { useSetState } from "react-use";
import { useDropzone } from "react-dropzone";
import qs from "qs";

import useTranslation from "hooks/useTranslation";
import Acta from "utils/Acta";
import downloadFileAsUser from "utils/downloadFileAsUser";

import { UploadTypes } from "constants/booking";

import { download as downloadIcon, trashBin as trashBinIcon } from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";

import InteractiveButton from "components/InteractiveButton";
import Icon from "components/Icon";
import DropDown from "components/DropDown";

import { DeleteFileModal } from "../Components/DeleteFileModal";

import Actions from "../Actions";
import fetchFiles, { IFiles } from "../Actions/fetchFiles";

import styles from "./FileUploadStyles.module.scss";

interface IProps {
  row: string;
  type: "plan" | "collection";
}

interface IState {
  details?: IFiles;
  isLoading: boolean;
}

const FileUpload = (props: IProps) => {
  const { t } = useTranslation();

  const currentFileType = useRef<DropDown<typeof options>>(null);
  const [state, setState] = useSetState<IState>({ isLoading: false });

  const options = useMemo(() => {
    return Object.entries(UploadTypes).map(([value, label]) => ({
      value,
      label: t(`trainings.entity.schedule_row.property.upload_type.${value}`, {
        defaultValue: label,
      }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = useCallback(async (): Promise<void> => {
    if (!props.row) return;

    try {
      const details = await fetchFiles(props.row);
      setState({ details });
    } catch (err) {
      console.error(err);
    }
  }, [props.row, setState]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const queryString = qs.stringify({
    timestamp: new Date().valueOf(),
  });

  const onDrop = useCallback(
    async (files) => {
      const { row } = props;

      const body = new FormData();
      body.append("file", files[0]);

      await Actions.uploadFile({
        body,
        row,
        type: props.type,
        category: currentFileType.current.getValue(),
      });
      fetch();
    },
    [fetch, props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  const deleteFileFromBooking = async (fileUUID) => {
    const { row } = props;
    await Actions.deleteFile(row, fileUUID);
    fetch();
  };

  const onDownloadAll = async () => {
    const { details } = state;

    if (!details) return;

    const filename = "archive.zip";
    const url = `/v1/booking/downloadall/${details.uuid}/${filename}?${queryString}`;

    try {
      setState({ isLoading: true });
      await downloadFileAsUser(url, filename, "API", "application/zip");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.schedule_row.sidepanel.documents.error_archive", {
          defaultValue: "Une erreur est survenue lors du téléchargement de votre archive.",
        }),
        type: "error",
      });
    } finally {
      setState({ isLoading: false });
    }
  };

  const onDownload = async (fileUUID: string, fileName: string) => {
    const { details } = state;

    if (!details) return;

    const filename = fileName;
    const url = `/v1/booking/download/${details.uuid}/${fileUUID}/${fileName}?${queryString}`;

    try {
      setState({ isLoading: true });
      await downloadFileAsUser(url, filename, "API");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: t("trainings.view.schedule_row.sidepanel.documents.error_file", {
          defaultValue: "Une erreur est survenue lors du téléchargement de votre fichier.",
        }),
        type: "error",
      });
    } finally {
      setState({ isLoading: false });
    }
  };

  const orderFilesByCategory = (files) => {
    return files.reduce((acc, file) => {
      const { category } = file;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(file);

      return acc;
    }, {});
  };

  const { details, isLoading } = state;

  if (!details) return null;

  const ordonateFilesByCategory = orderFilesByCategory(details.files);

  return (
    <div className={styles.FileUpload}>
      <div className={styles.filesContainer}>
        {details.files && details.files.length > 0 && (
          <h2>
            <InteractiveButton
              label={t("trainings.view.schedule_row.sidepanel.documents.download_files", {
                defaultValue: "Télécharger tous les fichiers",
              })}
              strokeIconLeft={downloadIcon}
              size="small"
              onClick={isLoading ? () => null : onDownloadAll}
            />
          </h2>
        )}

        {(!details.files || details.files.length === 0) && (
          <p>
            {t("trainings.view.schedule_row.sidepanel.documents.no_file", {
              defaultValue: "Aucun fichier pour cette réservation.",
            })}
          </p>
        )}

        {ordonateFilesByCategory &&
          Object.keys(ordonateFilesByCategory).map((category) => (
            <>
              <h3 className={styles.titleCategory} key={category}>
                {t(`trainings.view.schedule_row.sidepanel.documents.upload_type.${category}`, {
                  defaultValue: UploadTypes[category] || category,
                })}
              </h3>
              {ordonateFilesByCategory[category].map((file) => (
                <div key={file.uuid} className={styles.file}>
                  <div>
                    <p>{`${file.name}`}</p>
                  </div>
                  <div>
                    <button
                      className={styles.button}
                      disabled={isLoading}
                      onClick={(_) => onDownload(file.uuid, file.name)}
                    >
                      <Icon strokeIcon={downloadIcon} width={15} />
                    </button>
                    <button
                      className={cx(styles.deleteFile, {
                        [styles.disabled]: file.type === "trainingSession",
                      })}
                      disabled={file.type === "trainingSession"}
                      onClick={() =>
                        Acta.setState("modalDisplayed", {
                          content: (
                            <DeleteFileModal onDelete={() => deleteFileFromBooking(file.uuid)} />
                          ),
                          size: "small",
                          title: `Supprimer "{{ file }}"`,
                          translationKey: "trainings.view.delete_file_modal.title",
                          translationOptions: {
                            file: file.name,
                          },
                        })
                      }
                    >
                      <Icon
                        strokeIcon={trashBinIcon}
                        width={18}
                        stroke={file.type === "trainingSession" ? Colors.textColor : Colors.error}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ))}
      </div>
      <div className={styles.separator} />
      <div className={styles.filesContainer}>
        <h3>
          {t("trainings.view.schedule_row.sidepanel.documents.add_file", {
            defaultValue: "Ajouter un fichier",
          })}
        </h3>
        <DropDown
          label={t("trainings.view.schedule_row.sidepanel.documents.dropdown.label", {
            defaultValue: "Type de fichier",
          })}
          options={options}
          defaultValue={options[0].value}
          background="#f3f3f3"
          ref={currentFileType}
        />
        <section className={styles.dropZone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <p>
                {t("trainings.view.schedule_row.sidepanel.documents.drop_file", {
                  defaultValue: "Déposez un fichier ici ou cliquez ici pour choisir un fichier",
                })}
              </p>
            }
          </div>
        </section>
      </div>
    </div>
  );
};

export default FileUpload;
