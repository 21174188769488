import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import { ExtractRouteParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory, generatePath } from "react-router-dom";

import { Form, getIn, Formik, FieldArray } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { Flex, Text, Loader } from "@skillup/design-system";
import {
  DSButton,
  DSCheckbox,
  DSTextInput,
  DSSimpleTextArea,
  DSFormGroupTextInput,
} from "@skillup/ui";

import DSLayout from "components/DSLayout";
import { editReviewCriteria } from "services/coreHR/fields/editReviewCriteria";
import {
  getReviewCriteriaHistory,
  getReviewCriteriaHistoryCacheKey,
  editReviewCriteriaPayloadValidation,
} from "services/coreHR/fields/getReviewCriteriaHistory";

import { ReviewCriteriaRoutes } from "../review-criteria.router";

import styles from "./ReviewCriteriaEdit.module.scss";

export function ReviewCriteriaEdit() {
  const { addToast } = useToasts();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } =
    useParams<ExtractRouteParams<typeof ReviewCriteriaRoutes.reviewCriteria, string>>();
  const { data } = useQuery(getReviewCriteriaHistoryCacheKey(id), getReviewCriteriaHistory(id), {
    enabled: Boolean(id),
  });
  const { mutate } = useMutation(editReviewCriteria(id), {
    onError: () => {
      addToast(
        t("core-hr.review-criteria-edit.mutation.error", {
          defaultValue: "Une erreur est survenue",
        }),
        { appearance: "error" }
      );
      queryClient.invalidateQueries(getReviewCriteriaHistoryCacheKey(id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getReviewCriteriaHistoryCacheKey(id));
      history.push(generatePath(ReviewCriteriaRoutes.reviewCriteria, { id }));
    },
  });
  const [lockForceNewVersionCheckbox, setLockForceNewVersionCheckbox] = useState(false);

  return (
    <DSLayout
      className={styles.container}
      title={data?.currentVersion.label}
      parent={{
        title: t("core-hr.review-criteria-edit.title", {
          defaultValue: "Configuration des critères de positionnement",
        }),
        titleUrl: generatePath(ReviewCriteriaRoutes.reviewCriteriaList),
      }}
    >
      {data ? (
        <Formik
          validateOnBlur
          validationSchema={toFormikValidationSchema(editReviewCriteriaPayloadValidation)}
          onSubmit={(values) => {
            mutate(values);
          }}
          initialValues={{
            id: data.currentVersion.id,
            description: data.currentVersion.description,
            forceNewVersion: false,
            label: data.currentVersion.label,
            options: data.currentVersion.options,
          }}
        >
          {({ errors, handleSubmit, setFieldValue, values }) => (
            <Form className={styles.form} onSubmit={handleSubmit}>
              <Flex gap="l" width="100%" className={styles.content}>
                <Flex gap="l" width="100%" maxWidth="24rem" flexDirection="column">
                  <Text espaceFont="h5" color="plainText-onLight-lighter">
                    {t("core-hr.review-criteria-edit.definition", { defaultValue: "Définition" })}
                  </Text>

                  <DSFormGroupTextInput
                    required
                    label={t("core-hr.review-criteria-edit.form.title.label", {
                      defaultValue: "Intitulé du critère",
                    })}
                    assistiveText={
                      getIn(errors, "label" satisfies keyof typeof values)
                        ? getIn(errors, "label" satisfies keyof typeof values)
                        : t("core-hr.review-criteria-edit.form.title.assistive-text", {
                            defaultValue: "Clé : {{key}}",
                            key: data.fieldKey,
                          })
                    }
                  >
                    <DSTextInput
                      value={values.label}
                      name={"label" satisfies keyof typeof values}
                      error={getIn(errors, "label" satisfies keyof typeof values)}
                      onChange={(value) =>
                        setFieldValue("label" satisfies keyof typeof values, value)
                      }
                    />
                  </DSFormGroupTextInput>

                  <DSFormGroupTextInput
                    assistiveText={getIn(errors, "description" satisfies keyof typeof values)}
                    label={t("core-hr.review-criteria-edit.form.description.label", {
                      defaultValue: "Description",
                    })}
                  >
                    <DSSimpleTextArea
                      rows={10}
                      value={values.description}
                      name={"description" satisfies keyof typeof values}
                      error={getIn(errors, "description" satisfies keyof typeof values)}
                      onChange={(value) =>
                        setFieldValue("description" satisfies keyof typeof values, value)
                      }
                    />
                  </DSFormGroupTextInput>
                </Flex>

                <Flex gap="l" width="100%" height="fit-content" flexDirection="column">
                  <Text espaceFont="h5" color="plainText-onLight-lighter">
                    {t("core-hr.review-criteria-edit.scales", {
                      defaultValue: "Échelle d'évaluation",
                    })}
                  </Text>

                  <FieldArray
                    name={"options" satisfies keyof typeof values}
                    render={({ pop, push, replace }) => (
                      <Flex gap="l" flexDirection="column">
                        {values.options.map((option, index) => (
                          <Flex gap="s" key={option.value}>
                            <DSFormGroupTextInput
                              required
                              className={styles.optionLabelInput}
                              assistiveText={getIn(errors, `options[${index}].label`)}
                              label={t("core-hr.review-criteria-edit.form.options.name.label", {
                                defaultValue: "Intitulé du niveau {{value}}",
                                value: index + 1,
                              })}
                            >
                              <DSTextInput
                                value={option.label}
                                error={getIn(errors, `options[${index}].label`)}
                                name={"label" satisfies keyof (typeof values.options)[number]}
                                onChange={(value) => replace(index, { ...option, label: value })}
                              />
                            </DSFormGroupTextInput>

                            <DSFormGroupTextInput
                              className={styles.optionDescriptionInput}
                              assistiveText={getIn(errors, `options[${index}].description`)}
                              label={t(
                                "core-hr.review-criteria-edit.form.options.description.label",
                                {
                                  defaultValue: "Description du niveau {{level}}",
                                  level: index + 1,
                                }
                              )}
                            >
                              <DSSimpleTextArea
                                rows={3}
                                value={option.description}
                                error={getIn(errors, `options[${index}].description`)}
                                name={"description" satisfies keyof (typeof values.options)[number]}
                                onChange={(value) =>
                                  replace(index, { ...option, description: value })
                                }
                              />
                            </DSFormGroupTextInput>
                          </Flex>
                        ))}

                        <Flex justifyContent="space-between">
                          <DSButton
                            type="button"
                            buttonSize="S"
                            emphasis="High"
                            label={t("core-hr.review-criteria-edit.form.options.button-add", {
                              defaultValue: "Ajouter un niveau",
                            })}
                            onClick={() => {
                              setLockForceNewVersionCheckbox(true);
                              setFieldValue("forceNewVersion" satisfies keyof typeof values, true);
                              push({
                                description: "",
                                label: "",
                                value: values.options.length + 1,
                              });
                            }}
                          />

                          <DSButton
                            type="button"
                            emphasis="Low"
                            buttonSize="S"
                            onClick={() => {
                              setLockForceNewVersionCheckbox(true);
                              setFieldValue("forceNewVersion" satisfies keyof typeof values, true);
                              pop();
                            }}
                            label={t(
                              "core-hr.review-criteria-edit.form.options.button-remove-last",
                              {
                                defaultValue: "Supprimer le dernier niveau",
                              }
                            )}
                          />
                        </Flex>
                      </Flex>
                    )}
                  />
                </Flex>

                <Flex
                  gap="xs"
                  padding="s"
                  alignItems="end"
                  flexDirection="column"
                  className={styles.stickyFooter}
                >
                  <DSCheckbox
                    checked={values.forceNewVersion}
                    disabled={lockForceNewVersionCheckbox}
                    name={"forceNewVersion" satisfies keyof typeof values}
                    onChange={(checked) =>
                      setFieldValue("forceNewVersion" satisfies keyof typeof values, checked)
                    }
                    label={t("core-hr.review-criteria-edit.form.incr-version.label", {
                      defaultValue: "Incrémenter le numéro de version",
                    })}
                  />

                  <Flex gap="xs">
                    <DSButton
                      buttonSize="M"
                      emphasis="Low"
                      href={generatePath(ReviewCriteriaRoutes.reviewCriteria, { id })}
                      label={t("core-hr.review-criteria-edit.form.button.cancel", {
                        defaultValue: "Annuler",
                      })}
                    />
                    <DSButton
                      type="submit"
                      buttonSize="M"
                      emphasis="High"
                      label={t("core-hr.review-criteria-edit.form.button.submit", {
                        defaultValue: "Enregistrer les modifications",
                      })}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </DSLayout>
  );
}
