import { useMemo, useState } from "react";
import useTranslation from "hooks/useTranslation";

import { useMachine } from "@xstate/react";
import { useQuery } from "@tanstack/react-query";

import {
  DSAvatarGroup,
  DSModal,
  DSFormGroupTextInput,
  DSTextInput,
  Select,
  DSTooltip,
} from "@skillup/ui";
import { FieldRoutesType, SessionUser } from "@skillup/espace-rh-bridge";

import { confirmMachine } from "stateMachine/confirm";
import { PartialPostponeParams } from "services/sessions/partialPostpone";
import { getUserInitials } from "utils/User";
import { buildRequest } from "utils/buildRequest";

import AttendeeNotificationAlert from "./AttendeeNotificationAlert";
import { Project } from "./types";
import styles from "./PostponeModal.module.scss";

type Props = {
  trainees: Array<SessionUser>;
  project: Project;
  onBack: () => void;
  onClose: () => void;
  partialPostpone: (params: PartialPostponeParams) => Promise<void>;
};

const getField = async () => {
  try {
    const data = await buildRequest<FieldRoutesType.GetV2>({
      method: "GET",
      path: `/fields-v2`,
      query: {
        bindings: ["deniedOrCancelledReason"],
      },
    })();

    return data;
  } catch (err) {
    return [];
  }
};

export const PostponeModal = ({ trainees, onBack, onClose, partialPostpone, project }: Props) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>(null);
  const [deniedOrCancelledReason, setCancelReason] = useState<string>(null);

  const { data: [field] = [] } = useQuery(["denied-or-Cancelled-field"], () => getField());
  const fieldOptions = useMemo(
    () => field?.options.map(({ value, key }) => ({ label: value, value: key })),
    [field]
  );


  const [current, send] = useMachine(confirmMachine, {
    actions: {
      onBack: () => onBack(),
      onClose: () => onClose(),
    },
    services: {
      onConfirm: () => {
        return partialPostpone({
          rowsUuid: trainees.map((trainee) => trainee.row),
          message,
          deniedOrCancelledReason,
        }).then(() => {
          onClose();
        });
      },
    },
  });

  return (
    <>
      <DSModal isOpen className={styles.PostponeModal}>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("trainings.manage.users.postpone.title", {
              count: trainees.length,
              defaultValue: "Reporter la demande pour {{ count }} stagiaire",
            })}
          />
          <DSModal.Header.SubHeader>
            <DSAvatarGroup
              size="S"
              tooltipDirection="bottom"
              maxItems={5}
              totalItems={trainees.length}
              users={trainees.map((trainee) => {
                return {
                  uuid: trainee.uuid,
                  fullName: trainee.fullName,
                  initials: getUserInitials(trainee),
                };
              })}
            />
          </DSModal.Header.SubHeader>
        </DSModal.Header>

        <DSModal.Content className={styles.content}>
          <p className={styles.description}>
            {t("trainings.manage.users.postpone.description", {
              count: trainees.length,
              defaultValue:
                "Si vous confirmez cette action, les stagiaires pourront être inscrits à d'autres sessions.",
            })}
          </p>
          <AttendeeNotificationAlert
            className={styles.attendeeNotificationAlert}
            type="postpone"
            project={project}
            trainees={trainees}
          />
          <DSFormGroupTextInput
            label={t("trainings.schedules.postpone.reason.label", {
              defaultValue: "Motif du report",
            })}
            required
            placeholder={t("trainings.schedules.cancelOrPostpone.reason.placeholder", {
              defaultValue: "Choisir un motif",
            })}
          >
            <Select onChange={setCancelReason} options={fieldOptions} />
          </DSFormGroupTextInput>
          <form onSubmit={(e) => e.preventDefault()}>
            <DSFormGroupTextInput
              label={t("trainings.manage.users.postpone.comment.label", {
                defaultValue: "Commentaire interne (facultatif)",
              })}
              assistiveText={t("trainings.manage.users.postpone.comment.assistiveText", {
                count: trainees.length,
                defaultValue:
                  "Le commentaire sera visible pour le manager et les responsables ayant accès au plan, mais ne sera pas transmis au collaborateur.",
              })}
            >
              <DSTextInput
                autoFocus={true}
                placeholder={t("trainings.manage.users.postpone.comment.placeholder", {
                  defaultValue: "Saisissez votre commentaire",
                })}
                onChange={(value) => setMessage(value)}
                name="message"
              />
            </DSFormGroupTextInput>
          </form>
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            disabled={current.context.loading}
            label={t("trainings.manage.users.form.goback", { defaultValue: "Retour" })}
            onClick={() => send("BACK")}
          />
          <DSTooltip
            label={t("trainings.manage.users.form.cancelOrPostpone.tooltip.require", {
              defaultValue:
                "Tous les champs sont obligatoires (signalés par une astérisque rouge) doivent être renseignés",
            })}
            disabled={!!deniedOrCancelledReason}
          >
            <DSModal.Footer.PrimaryButton
              disabled={current.context.loading || !deniedOrCancelledReason}
              label={t("trainings.manage.users.form.postpone.next", {
                defaultValue: "Confirmer le report",
              })}
              onClick={() => send("CONFIRM")}
            />
          </DSTooltip>
        </DSModal.Footer>
      </DSModal>
    </>
  );
};
