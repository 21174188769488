import { Chip } from "@skillup/design-system";

import { TranslationType } from "hooks/useTranslation";

import type { Tracking } from "../../state/tracking";

export function translateState(state: string) {
  switch (state) {
    case "pending":
      return "À inscrire";
    case "won":
      return "Inscrit(e)";
    case "realized":
      return "Réalisé";
    default:
      return "";
  }
}

type Props = {
  state: Tracking["associatedRowState"];
  t: TranslationType;
  asChip?: boolean;
};

function TrackingState({ state, t, asChip }: Props) {
  const translatedState = t(`trainings.entity.tracking.state.${state}`, {
    defaultValue: translateState(state),
  });

  if (asChip) {
    return <Chip label={translatedState} darkMode />;
  }
  
  return <>{translatedState}</>;
}

export default TrackingState;
