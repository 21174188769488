import React from "react";
import { Repeat } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const OngoingCampaign = ({ t }: { t: (l: string, p: object) => string }) => (
  <RectangleTag
    label={t("interviews.ongoingCampagin", {
      defaultValue: "Entretiens automatisés",
    })}
    icon={<Repeat />}
    backgroundColor="#f5f5f5"
    textColor="#92929d"
  />
);

export { OngoingCampaign };
