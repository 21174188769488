import { MdExpandMore as ExpandMore } from "react-icons/md";
import { MdViewAgenda as ViewAgenda } from "react-icons/md";
import { MdInsertDriveFile as InsertDriveFile } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Child, add } from "../../reducer";
import {
  DSButton,
  DSDropdown,
  DSDropdownItem,
  DSDropdownTitle,
  DSDropdownDivider,
} from "@skillup/ui";
import { canCreateChildBlock } from "../../utils/canCreateChildBlock";
import { AddChildAction } from "../../reducer/actions/addBlock/addChild/addChild";
import { PositionItem } from "../../hooks/useBuilderModals";
import { TranslationType } from "hooks/useTranslation";
import { useBuilderContext } from "../../BuilderContext";
import { ChildIcon } from "../../ChildIcon";

type ButtonAddContentItemProps = {
  item: PositionItem;
  t: TranslationType;
};

type AddCommonChild = { blockType: "child" };

type AddQuestionChild = AddCommonChild & {
  kind: "question";
  type: Extract<Child, { kind: "question" }>["type"];
  t: TranslationType;
};
type AddTargetsChild = AddCommonChild & {
  kind: "targets";
  type: Extract<Child, { kind: "targets" }>["type"];
  t: TranslationType;
};

type AddOtherChild = AddCommonChild & {
  kind: Exclude<
    Child,
    { kind: "question" | "targets" | "guidelines" | "personnalisableGuidelines" }
  >["kind"];
  t: TranslationType;
};

type AddChildBlock = AddQuestionChild | AddTargetsChild | AddOtherChild;

type AddBlock = AddChildBlock | { blockType: "page" | "section"; t: TranslationType };

export const ButtonAddContentItem = ({ item, t }: ButtonAddContentItemProps) => {
  const dispatch = useDispatch();
  const { updatedItemIdRef } = useBuilderContext();

  const indexes = useMemo(() => {
    const indexes = {
      sectionIndex: undefined,
      pageIndex: undefined,
      childIndex: undefined,
    };

    if (item.type === "section" || item.type === "page" || item.type === "child") {
      indexes.sectionIndex = item.sectionIndex;
    }

    if (item.type === "page" || item.type === "child") {
      indexes.pageIndex = item.pageIndex;
    }

    if (item.type === "child") {
      indexes.childIndex = item.childIndex;
    }

    return indexes;
  }, [item]);

  const addBlock = useCallback(
    (params: AddBlock) => {
      const newChildUuid = uuid();
      updatedItemIdRef.current = { uuid: newChildUuid, fromAction: "addBlock" };
      if (params.blockType === "child") {
        let addParams: AddChildAction;
        if (params.kind === "question") {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
            type: params.type,
            t: params.t
          };
        } else if (params.kind === "targets") {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
            type: params.type,
            t: params.t
          };
        } else {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
            t: params.t
          };
        }
        dispatch(add(addParams));
      } else {
        dispatch(
          add({
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            t: params.t
          })
        );
      }
    },
    [indexes, dispatch, updatedItemIdRef]
  );

  return (
    <DSDropdown
      placementShift={[0, 3]}
      // placementShift: x, y shift in pixel from the popover anchor(default value is [ 8, 8 ]),
      // I put [ 0, 3 ] here to align with the button, hide the button shadow and part of the border radii to best fit the spec without reworking the DS
      button={
        <DSButton
          label={t("interviews.templateBuilder.addBlock", {
            defaultValue: "Ajouter un bloc",
          })}
          darkMode
          buttonSize="S"
          emphasis="High"
          actionIcon={<ExpandMore />}
        />
      }
    >
      <DSDropdownTitle
        label={t("interviews.templateBuilder.section.elements", {
          defaultValue: "Éléments structurels",
        })}
      />
      <DSDropdownItem
        label={t("interviews.templateBuilder.section", {
          defaultValue: "Section",
        })}
        icon={<ViewAgenda />}
        onClick={() => {
          addBlock({ blockType: "section", t: t });
        }}
      />
      <DSDropdownItem
        label={t("interviews.templateBuilder.page", {
          defaultValue: "Sous-section",
        })}
        icon={<InsertDriveFile />}
        onClick={() => {
          addBlock({ blockType: "page", t: t });
        }}
      />
      <DSDropdownDivider />

      <DSDropdownTitle
        label={t("interviews.templateBuilder.child.info", {
          defaultValue: "Texte informatif",
        })}
      />
      {canCreateChildBlock("title") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.title", {
            defaultValue: "Titre",
          })}
          icon={<ChildIcon kind="title" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "title", t: t });
          }}
        />
      )}
      {canCreateChildBlock("html") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.html.long", {
            defaultValue: "Texte d'information",
          })}
          icon={<ChildIcon kind="html" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "html", t: t });
          }}
        />
      )}
      <DSDropdownDivider />

      <DSDropdownTitle
        label={t("interviews.templateBuilder.child.question", {
          defaultValue: "Questions d'entretien",
        })}
      />
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.text", {
            defaultValue: "Question ouverte",
          })}
          icon={<ChildIcon kind="question" type="text" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "text", t: t });
          }}
        />
      )}
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.checkbox", {
            defaultValue: "Choix multiple (plusieurs réponses possibles)",
          })}
          icon={<ChildIcon kind="question" type="checkbox" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "checkbox", t: t });
          }}
        />
      )}
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.radio", {
            defaultValue: "Choix multiple (une seule réponse possible)",
          })}
          icon={<ChildIcon kind="question" type="radio" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "radio", t: t });
          }}
        />
      )}
      {canCreateChildBlock("table") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.table.long", {
            defaultValue: "Tableau à remplir",
          })}
          icon={<ChildIcon kind="table" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "table", t: t });
          }}
        />
      )}
      {canCreateChildBlock("targets") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.target.next", {
            defaultValue: "Fixation d'objectifs",
          })}
          icon={<ChildIcon kind="targets" type="next" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "targets", type: "next", t: t });
          }}
        />
      )}
      {canCreateChildBlock("trainingsCollection") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.trainingsCollection", {
            defaultValue: "Demande de formation",
          })}
          icon={<ChildIcon kind="trainingsCollection" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "trainingsCollection", t: t });
          }}
        />
      )}
      {canCreateChildBlock("upload") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.upload.content", {
            defaultValue: "Partage de fichier",
          })}
          icon={<ChildIcon kind="upload" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "upload", t: t });
          }}
        />
      )}
      <DSDropdownDivider />

      <DSDropdownTitle
        label={t("interviews.templateBuilder.child.dynamic", {
          defaultValue: "Contenus dynamiques",
        })}
      />
      {canCreateChildBlock("targets") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.target.current", {
            defaultValue: "Revue des objectifs",
          })}
          icon={<ChildIcon kind="targets" type="current" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "targets", type: "current", t: t });
          }}
        />
      )}
      {canCreateChildBlock("globalTargetsCompletion") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.globalTargetsCompletion", {
            defaultValue: "Taux d’atteinte global",
          })}
          icon={<ChildIcon kind="globalTargetsCompletion" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "globalTargetsCompletion", t: t });
          }}
        />
      )}
      {canCreateChildBlock("job") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.job", {
            defaultValue: "Fiche de poste",
          })}
          icon={<ChildIcon kind="job" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "job", t: t });
          }}
        />
      )}
      {canCreateChildBlock("skills") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.skills.long", {
            defaultValue: "Compétences à évaluer",
          })}
          icon={<ChildIcon kind="skills" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "skills", t: t });
          }}
        />
      )}
      {canCreateChildBlock("trainingsHistory") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.trainingsHistory", {
            defaultValue: "Historique de formation",
          })}
          icon={<ChildIcon kind="trainingsHistory" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "trainingsHistory", t: t });
          }}
        />
      )}
      {canCreateChildBlock("interviewsHistory") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.interviewsHistory", {
            defaultValue: "Historique d'entretiens",
          })}
          icon={<ChildIcon kind="interviewsHistory" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "interviewsHistory", t: t });
          }}
        />
      )}
      {canCreateChildBlock("userSpecificData") && (
        <DSDropdownItem
          label={t("interviews.templateBuilder.child.userSpecificData", {
            defaultValue: "Données spécifiques à l'utilisateur",
          })}
          icon={<ChildIcon kind="userSpecificData" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "userSpecificData", t: t });
          }}
        />
      )}
    </DSDropdown>
  );
};
