import React from "react";

import InteractiveButton from "components/InteractiveButton";
import useTranslation from "hooks/useTranslation";

import styles from "./NoSessionModalStyles.module.scss";

export interface IProps {
  requalify: () => void;
  goToProjects: () => void;
  type?: "inter" | "intra";
}

const NoSessionModal = ({ requalify, goToProjects, type }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.noSessionModal}>
      <p>
        {t("trainings.view.no_session_modal.description", {
          defaultValue: "Il semblerait que cette formation ne propose pas de date de session.",
        })}
      </p>
      {type !== "intra" && (
        <p>
          {t("trainings.view.no_session_modal.description.inter", {
            defaultValue:
              "Souhaitez-vous requalifier la demande afin de l'associer à un programme équivalent avec des sessions ?",
          })}
        </p>
      )}
      {type === "intra" && (
        <p>
          {t("trainings.view.no_session_modal.description.intra", {
            defaultValue:
              'Pour ajouter une session ou modifier une session existante, allez dans la section "Mes sessions".',
          })}
        </p>
      )}
      <div className={styles.buttons}>
        <InteractiveButton
          label={
            type !== "intra"
              ? t("trainings.view.no_session_modal.submit.inter", {
                  defaultValue: "Requalifer",
                })
              : t("trainings.view.no_session_modal.submit.intra", {
                  defaultValue: "Ajouter / Modifier une session",
                })
          }
          onClick={type !== "intra" ? requalify : goToProjects}
          size="small"
        />
      </div>
    </div>
  );
};

export default NoSessionModal;
