import { buildRequest } from "utils/buildRequest";

import { ScheduleRowTypes, type TrainingDomainRoutes } from "@skillup/training-bridge";

import type { GetQuestionsRouteType } from "../types";

export const fetchAnalyticsConfig = async () => {
  try {
    const config = await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardGetConfigRoute>({
      method: "GET",
      path: "/schedule-rows/dashboard/config",
      target: "TRAINING_API",
    })();

    if (!config) {
      return null;
    }

    return config;
  } catch (error) {
    return null;
  }
};

export const saveAnalyticsConfig = async (config: ScheduleRowTypes.DashboardConfig) => {
  try {
    return await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardSaveConfigRoute>({
      method: "POST",
      path: "/schedule-rows/dashboard/save",
      target: "TRAINING_API",
      payload: config,
    })();
  } catch (error) {
    throw new Error("Failed to save config");
  }
};

export const saveDefaultDashboardConfiguration = async () => {
  try {
    return await buildRequest<TrainingDomainRoutes.SCHEDULE_ROWS.DashboardSaveDefaultConfigRoute>({
      method: "POST",
      path: "/schedule-rows/dashboard/default-config/save",
      target: "TRAINING_API",
    })();
  } catch (error) {
    throw new Error("Failed to check for default configuration");
  }
};

export const getQuestions = async () => {
  const questionsResult = await buildRequest<GetQuestionsRouteType>({
    method: "GET",
    path: `/refine/questions`,
    query: {
      "fields[questions]": [
        "name",
        "fields",
        "recommended_short_name",
        "recommended_x_label",
        "recommended_y_label",
        "available_charts",
        "available_filters",
      ].join(","),
    },
    target: "ANALYTICS_CONFIG_SERVICE",
  })();

  return questionsResult.data.map((item) => item.attributes);
};
