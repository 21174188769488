import * as z from "zod";

export const JobSchema = z.object({
  jobName: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .min(1, { message: "Ce champ ne doit pas être vide" }),
  fields: z.array(
    z.object({
      uuid: z.string().uuid(),
      value: z.string().trim().optional(),
      name: z.string(),
      index: z.number(),
    })
  ),
  sections: z.array(
    z.object({
      uuid: z.string().uuid(),
      value: z.string().trim().optional(),
      name: z.string(),
      index: z.number(),
    })
  ),
  skills: z.array(
    z.object({
      skillUuid: z.string().uuid(),
      expectedLevelUuid: z.string().uuid().nullable().optional(),
    })
  ),
});
