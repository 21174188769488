/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { useEffect } from "react";
import { MdMoreVert as MoreVert } from "react-icons/md";

import { DSButton, DSFormGroupTextInput, DSDropdown, DSDropdownItem } from "@skillup/ui";
import { Duration } from "@skillup/shared-utils";

import SidePanel from "components/SidePanel";
import useTranslation, { type TranslationType } from "hooks/useTranslation";

import { Habilitation } from "../../types";
import { useHabilitationByUuid } from "../../state/habilitations";

import styles from "./HabilitationSidePanel.module.scss";

type HabilitationAction = {
  label?: string;
  disabled?: boolean;
  tooltip?: string;
};
interface Props {
  readonly habilitationUuid: string;
  readonly onDeleteHabilitation: () => void;
  readonly onModifyHabilitation: () => void;
  readonly onClose: () => void;
  readonly openActionModal: (habilitation: Habilitation, action: HabilitationAction) => void;
  readonly getLabelFromAction: (action: HabilitationAction, t: TranslationType) => string;
}

export default ({
  habilitationUuid,
  onDeleteHabilitation,
  onModifyHabilitation,
  onClose,
  openActionModal,
  getLabelFromAction,
}: Props) => {
  const { t } = useTranslation();
  const habilitation = useHabilitationByUuid(habilitationUuid);
  const habilitationNotFound = useMemo(() => habilitation.isLeft(), [habilitation]);

  useEffect(() => {
    if (habilitationNotFound) {
      onClose();
    }
  }, [habilitationNotFound, onClose]);

  const data = useMemo(
    () => (habilitation.isRight() ? habilitation.right() : null),
    [habilitation]
  );

  const trackingsCountText = useMemo(() => {
    return data
      ? t("trainings.entity.habilitation.property.trackingsCount.value", {
          defaultValue: "{{count}} collaborateurs associés",
          count: data!.trackingsCount,
        })
      : "";
  }, [data]);

  const canAssociateCollabs = useMemo(() => {
    if (data.actions.find((a) => a.type === "addTrackingsInBulk")) return true;
    return false;
  }, [data]);

  return (
    <div>
      {data && (
        <SidePanel
          className={styles.habilitationSidePanel}
          title={data.name}
          subTitle={data.category.name}
          headerDetails={trackingsCountText}
          headerBottomTools={
            <div className={styles.headerBottomTools}>
              {canAssociateCollabs === true && (
                <DSButton
                  label={t("trainings.view.regulatory.form.link_collaborator.title", {
                    defaultValue: "Associer des collaborateurs",
                  })}
                  emphasis={"Mid"}
                  buttonSize={"S"}
                  fontWeight="normal"
                  darkMode
                  onClick={() =>
                    openActionModal(data, { type: "addTrackingsInBulk" } as HabilitationAction)
                  }
                />
              )}
              <div className={styles.headerDropDown}>
                <DSDropdown
                  button={<DSButton label="dropdown" darkMode icon={<MoreVert />} iconOnly />}
                >
                  {data.actions.length > 0 &&
                    data.actions.map((action) => (
                      <DSDropdownItem
                        label={getLabelFromAction(action, t)}
                        disabled={action.disabled}
                        onClick={() => {
                          openActionModal(data, action);
                        }}
                        tooltipLabel={t(
                          "trainings.view.regulatory.action." + action.type + "." + action.tooltip,
                          {
                            defaultValue:
                              "Cette habilitation est associée à {{count}} collaborateurs et ne peut donc pas être supprimée.",
                            count: data.trackingsCount,
                          }
                        )}
                        tooltipDirection="top"
                      />
                    ))}
                </DSDropdown>
              </div>
            </div>
          }
          onClose={onClose}
        >
          <div className={styles.habilitationContainer}>
            <div className={styles.habilitationContent}>
              <section className={styles.habilitationContentSection}>
                <DSFormGroupTextInput
                  label={t("trainings.entity.habilitation.property.category", {
                    defaultValue: "Catégorie",
                  })}
                >
                  <p className={styles.habilitationContentValue}>{data.category.name}</p>
                </DSFormGroupTextInput>
              </section>
              {data.validity === "limited" && (
                <section>
                  <div className={styles.habilitationContentSection}>
                    <DSFormGroupTextInput
                      label={t("trainings.entity.habilitation.property.validity", {
                        defaultValue: "Durée de validité",
                      })}
                    >
                      <p className={styles.habilitationContentValue}>
                        {Duration.fromIsoToHumain(data.duration)}
                      </p>
                    </DSFormGroupTextInput>
                  </div>
                  <div className={styles.habilitationContentSection}>
                    <DSFormGroupTextInput
                      label={t("trainings.entity.habilitation.property.deadlineAnticipation", {
                        defaultValue: "Anticipation de l'échéance",
                      })}
                    >
                      <p className={styles.habilitationContentValue}>
                        {Duration.fromIsoToHumain(data.deadlineAnticipation)}
                      </p>
                    </DSFormGroupTextInput>
                  </div>
                </section>
              )}
            </div>

            <div className={styles.buttonsWrapper}>
              <DSButton
                emphasis="Low"
                buttonSize="S"
                fontWeight="normal"
                disabled={data.trackingsCount !== 0}
                tooltip={
                  data.trackingsCount !== 0
                    ? t(
                        "trainings.view.regulatory.action.delete.habilitation_associated_to_collaborators",
                        {
                          defaultValue:
                            "Cette habilitation est associée à {{count}} collaborateurs et ne peut donc pas être supprimée.",
                          count: data.trackingsCount,
                        }
                      )
                    : undefined
                }
                label={t("common.action.delete", { defaultValue: "Supprimer" })}
                onClick={onDeleteHabilitation}
              />
              <DSButton
                emphasis="Mid"
                buttonSize="S"
                fontWeight="normal"
                label={t("trainings.view.regulatory.action.update", {
                  defaultValue: "Modifier l'habilitation",
                })}
                onClick={onModifyHabilitation}
              />
            </div>
          </div>
        </SidePanel>
      )}
    </div>
  );
};
