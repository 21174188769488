import { useMemo } from "react";
import InteractiveButton from "components/InteractiveButton";

import type { ManualTrainingData } from "./Training";
import { modes } from "./Training";

import styles from "./Session.module.scss";
import AddTraineesContent from "components/AddTraineesModal/AddTraineesContent";
import { useUsersList } from "components/AddTraineesModal/add-trainees-context";
import { ISimpleSelectionUser, USER_FILE_VALIDATION_TYPE } from "@skillup/types";
import { switchOnError } from "../AddRow/helper";
import Acta from "utils/Acta";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";
import useTranslation from "hooks/useTranslation";

export type User = ISimpleSelectionUser;

interface ManualUserSelectionProps {
  trainingData: ManualTrainingData;
  isLoading?: boolean;
  target: "collection" | "plan";
  onSuccess: (data: { users: User[] }) => void;
}

const ManualImportUser = ({
  isLoading = false,
  onSuccess,
  trainingData,
}: ManualUserSelectionProps) => {
  const {
    state: { selectedUsers },
  } = useUsersList();

  const { t } = useTranslation();

  const sessionType = useMemo(
    () =>
      t(`trainings.entity.session.property.type.${trainingData.sessionType.toLowerCase()}.label`, {
        defaultValue: trainingData.sessionType,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trainingData.sessionType]
  );

  const sessionMode = useMemo(
    () =>
      t(`trainings.entity.training.property.mode.${trainingData.sessionMode}.label`, {
        defaultValue: modes[trainingData.sessionMode],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trainingData.sessionMode]
  );

  const submit = async () => {
    try {
      if (selectedUsers.length === 0) {
        throw new Error("users");
      }
      if (selectedUsers.some((user) => !!user.errors?.length)) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Certains collaborateurs sélectionnés contiennent des erreurs",
          type: "error",
        });
        return;
      }
      onSuccess({
        users: selectedUsers,
      });
    } catch (err) {
      switchOnError(err, t);
    }
  };

  const groups = useAreaUserGroups(t);

  return (
    <div className={styles.manualImportSessionModal}>
      <div className={styles.preview} aria-label="preview-formation">
        <p className={styles.recall}>
          {t("trainings.view.add_trainees_modal.manual_import.title.label", {
            defaultValue: "Programme créé hors Skillup",
          })}
        </p>
        <p>
          <strong>{trainingData.trainingName}</strong> - {sessionType} - {sessionMode} -{" "}
          {trainingData.trainingOrganizationName} -{" "}
          {`${trainingData.total * 0.01} € - ${trainingData.sessionDuration} ${t(
            "training.common.hours.label",
            {
              defaultValue: "heures",
            }
          )}`}
        </p>
      </div>
      <AddTraineesContent
        canUploadManager={false}
        scope={USER_FILE_VALIDATION_TYPE.FREE_ROW}
        customUsersLists={
          groups && [
            {
              label: t("trainings.entity.training.add_trainees_modal.select_by_area.label", {
                defaultValue: "Sélectionner par périmètre",
              }),
              groups,
            },
          ]
        }
      />
      <div className={styles.nextStep}>
        <InteractiveButton
          loading={isLoading}
          label={t("trainings.common.button.add.label", {
            defaultValue: "Ajouter",
          })}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default ManualImportUser;
