import { useMemo, useState } from "react";

import { useModal2, DataTable, DSDropdownItem } from "@skillup/ui";

import { useFilteredHabilitations } from "../state/habilitations";
import { Habilitation } from "../types";

import styles from "./Habilitations.module.scss";

import HabilitationSidePanel from "../components/HabilitationSidePanel/HabilitationSidePanel";
import Reglementaire from "../Reglementaire";
import UserAreaSelect from "../components/UserAreaSelect/UserAreaSelect";

import useHabilitationsActions, { ModalStates } from "./useHabilitationsActions";
import useHabilitationsModals from "./useHabilitationsModals";
import useTableData from "./useTableData";

import NoHabilitationsSVG from "./assets/NoHabilitations";
import { atom, useRecoilState } from "recoil";
import useTranslation from "hooks/useTranslation";

const Habilitations = () => {
  const { t } = useTranslation();
  const [sidePanelHabilitation, setSidePanelHabilitation] = useState<Habilitation>();

  const [
    { state: actions, getLabelFromAction, getNewModalStateFromAction, getTooltipFromAction },
    setActionsState,
  ] = useHabilitationsActions();
  const modalToDisplay = useHabilitationsModals(actions, setActionsState);

  const { data, isSuccess } = useFilteredHabilitations();
  const { columns, data: tableData } = useTableData(data?.habilitations ?? [], t);

  const noHabilitationsAfterLoad = useMemo(
    () => tableData.length === 0 && isSuccess,
    [tableData, isSuccess]
  );

  const { isOpen: isSidePanelOpen, show: showSidePanel, hide: hideSidePanel } = useModal2();
  const openSidePanel = (): void => {
    if (!isSidePanelOpen) {
      showSidePanel();
    }
  };

  const toggleSidePannel = (habilitationUuid: string): void => {
    const habilitation = data.habilitations.find(
      (habilitation) => habilitation.uuid === habilitationUuid
    );

    if (habilitation.uuid === sidePanelHabilitation?.uuid) {
      setSidePanelHabilitation(undefined);
    } else if (habilitation) {
      setSidePanelHabilitation(habilitation);
    }

    openSidePanel();
  };

  const openActionModal = (habilitation, action): void => {
    setActionsState(getNewModalStateFromAction({ action, habilitation }));
  };

  const paginationHook = useRecoilState(habilitationsPagination);

  return (
    <Reglementaire
      actions={{
        label: t("trainings.view.regulatory.action.add_habilitation", {
          defaultValue: "Ajouter une habilitation",
        }),
        onClick: () => setActionsState({ state: ModalStates.Creating }),
      }}
    >
      <div className={styles.habilitations}>
        <div className={styles.tracking}>
          <div className={styles.globalFilters}>
            <UserAreaSelect />
          </div>
        </div>

        {noHabilitationsAfterLoad && (
          <div className={styles.noHabilitations}>
            <NoHabilitationsSVG />
            <p>
              {t("trainings.view.regulatory.action.add_habilitation", {
                defaultValue:
                  "Commencez à lister les habilitations de vos collaborateurs en cliquant sur le bouton<br/>“Ajouter une habilitation”",
              })}
            </p>
          </div>
        )}

        {tableData?.length > 0 && (
          <DataTable<(typeof tableData)[0]>
            className={styles.dataTable}
            header={{
              totalRowsLabel: (total) => {
                if (total < data?.habilitations.length) {
                  return t("trainings.view.regulatory.table.filtered_habilitation_count", {
                    defaultValue: "{{count}} habilitations filtrées sur {{total}}",
                    count: total,
                    total: data?.habilitations.length ?? 0,
                  });
                }

                return t("trainings.view.regulatory.table.habilitation_count", {
                  defaultValue: "{{count}} habilitations",
                  count: total,
                });
              },
            }}
            checkboxes={false}
            onClickRow={(row) => toggleSidePannel(row.id)}
            columns={columns}
            rows={tableData}
            actions={(row) => {
              const habilitation = data.habilitations.find((h) => h.uuid === row.id);

              return row.data.actions.map((action) => (
                <DSDropdownItem
                  key={action.type}
                  label={getLabelFromAction(action, t)}
                  disabled={!!action.disabled}
                  onClick={() => {
                    openActionModal(habilitation, action);
                  }}
                  tooltipLabel={getTooltipFromAction(habilitation, action, t)}
                  tooltipDirection="top"
                />
              ));
            }}
            pagination={{
              rowsPerPageLabel: t("trainings.view.regulatory.table.row_per_page", {
                defaultValue: "Lignes par page",
              }),
              itemsCountLabel: t("trainings.view.regulatory.table.item_count", {
                defaultValue: "Habilitations %range% sur %count%",
              }),
              pageLabel: t("common.view.table.page", {
                defaultValue: "Page",
              }),
              stateHook: paginationHook,
            }}
            mode="compact"
          />
        )}

        {modalToDisplay}

        {isSidePanelOpen && sidePanelHabilitation && (
          <HabilitationSidePanel
            habilitationUuid={sidePanelHabilitation.uuid}
            onDeleteHabilitation={() =>
              setActionsState({
                state: ModalStates.Deleting,
                habilitation: sidePanelHabilitation,
              })
            }
            onModifyHabilitation={() =>
              setActionsState({
                state: ModalStates.Modifying,
                habilitation: sidePanelHabilitation,
              })
            }
            onClose={hideSidePanel}
            openActionModal={openActionModal}
            getLabelFromAction={getLabelFromAction}
          />
        )}
      </div>
    </Reglementaire>
  );
};
const habilitationsPagination = atom<number>({
  key: "habilitations.pagination",
  default: 10,
});

export default Habilitations;
