import { MdOpenInNew as OpenInNew } from "react-icons/md";
import React from "react";

import { isNil } from "lodash";

import { DataTable, DSDropdownItem } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";
import useTranslation from "hooks/useTranslation";

import { TrainingResults } from "../types";
import styles from "../UserTraining.module.scss";
import useTableData from "./useTableData";

interface Props {
  readonly trainings: TrainingResults;
}

export default ({ trainings }: Props) => {
  const { baseUrl } = useBaseUrl();
  const { t } = useTranslation();

  const getBodyUrlByState = (state: string) => {
    switch (state) {
      case "pending_for_HR":
      case "pending_for_additionnal_validation":
      case "denied":
        return "recueil-des-besoins/collection-all";
      default:
        return "plan-de-formation/plan-all";
    }
  };

  const { columns, data: tableData } = useTableData({ trainings: trainings ?? [] });

  return (
    <DataTable
      className={styles.table}
      header={{
        totalRowsLabel: (total) => {
          let label =
            t("supervisor.view.collaborators.training_tab.property.table.total_rows", {
              defaultValue: "{{ count }} demandes de formations",
              count: total,
            }) + " ";

          if (total < trainings.length) {
            label += t("supervisor.view.collaborators.training_tab.property.table.filtred_row", {
              defaultValue: "filtrées sur {{ trainingLength }}",
              count: total,
              trainingLength: trainings.length,
            });
          }

          return label;
        },
      }}
      columns={columns}
      rows={tableData}
      mode="compact"
      actions={(row) => [
        <DSDropdownItem
          key="goto-training"
          label={
            <>
              {t("supervisor.view.collaborators.training_tab.action.table.goto.property.training", {
                defaultValue: "Accéder au programme",
              }) + " "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          disabled={isNil(row.data.seoSlug)}
          tooltipLabel={
            isNil(row.data.seoSlug)
              ? t("supervisor.view.collaborators.training_tab.action.table.goto.property.training.tooltip", {
                  defaultValue: "Aucun programme n’est lié à cette formation dans Skillup",
                })
              : ""
          }
          onClick={() => {
            const win = window.open(
              `${baseUrl}/programmes/${row.data.seoSlug ? "catalogue" : "gestion-intras"}/${
                row.data.seoSlug
              }`,
              "_blank"
            );
            win.focus();
          }}
        />,
        <DSDropdownItem
          key="show-scheduleRow"
          label={
            <>
              {t("supervisor.view.collaborators.training_tab.action.table.property.show_schedule_row", {
                defaultValue: "Consulter la ligne associée dans le plan",
              }) + " "}
              <OpenInNew style={{ fontSize: "1rem", verticalAlign: "middle" }} />
            </>
          }
          onClick={() => {
            const win = window.open(
              `${baseUrl}/${row.data.schedule?.uuid}/${getBodyUrlByState(row.data.state)}/${
                row.id
              }`,
              "_blank"
            );
            win.focus();
          }}
        />,
      ]}
      pagination={{
        rowsPerPageLabel: t(
          "supervisorUserDetail.user_training.table.pagination.training_per_page",
          { defaultValue: "Formations par page" }
        ),
        itemsCountLabel: t("supervisor.view.collaborators.training_tab.property.table.pagination.training_range", {
          defaultValue: "Formations %range% sur %count%",
        }),
        pageLabel: t("supervisor.view.collaborators.training_tab.property.table.pagination.page", {
          defaultValue: "Page",
        }),
      }}
    />
  );
};
