import { DSColor } from "components/DesignSystemContext";
import { Select } from "components/Select";
import styled, { css } from "styled-components";

const darkModeStyles = css`
  background-color: ${DSColor["surface-dark-darker"]};
  color: ${DSColor["plainText-onDark-darker"]};
  &:hover {
    border-color: ${DSColor["surface-dark-darker"]};
    color: ${DSColor["plainText-onDark-darker"]};
  }
`;

const lightModeStyles = css`
  background-color: ${DSColor["surface-light-darker"]};
  color: ${DSColor["plainText-onLight-lighter"]};
`;

export const SelectWrapper = styled.div<{ darkMode: boolean }>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  div > div {
    cursor: pointer;
    border-right: 0px;
    text-align: center;
    ${({ darkMode }) => (darkMode ? lightModeStyles : darkModeStyles)};
  }
`;

export const StyledSelect = styled(Select)<{ noRadius: boolean }>`
  width: fit-content;
  min-width: 100px;
  font-weight: 700 !important;
  font-size: 0.625rem !important;

  > div {
    ${({ noRadius }) => (noRadius ? "border-radius: 0px;" : "border-radius: 4px 0px 0px 4px;")};
  }
`;
