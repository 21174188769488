import { CampaignsRoutes } from "@skillup/espace-rh-bridge";
import { format } from "@skillup/shared-utils";
import { FloatingTabs, SearchInput, useModal } from "@skillup/ui";
import cx from "classnames";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import Loader from "components/Loader";
import { useQueryString, useTypedFetch } from "hooks";
import useSettings from "hooks/useSettings";
import useTranslation from "hooks/useTranslation";
import createUserAccessChecker from "hooks/userAccessChecker";
import isEmpty from "lodash/isEmpty";
import { stringify } from "qs";
import { useCallback, useMemo, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useSetState } from "react-use";
import Acta from "utils/Acta";
import User from "utils/User";
import useCampaignModals from "../Campaign/components/modals/useCampaignModals";
import { EditionContext } from "../Campaign/components/modals/utils";
import CampaignCard from "./CampaignCard";
import styles from "./CampaignsList.module.scss";
import ChooseCampaignModale from "./ChooseCampaignModale";

interface State {
  searchSymbol?: string;
}

type Campaign = CampaignsRoutes.GetByUuid["response"];

const CampaignsList = () => {
  const [state, setState] = useSetState<State>({});
  const { status = "active" } = useQueryString<{ status?: "active" | "archived" }>();
  const { t } = useTranslation();

  const { searchSymbol } = state;

  const archived = useMemo(() => {
    return status === "archived";
  }, [status]);

  const {
    data: fetchedCampaigns = [],
    loading,
    refetch,
  } = useTypedFetch<CampaignsRoutes.GetList>({
    method: "GET",
    path: "/campaigns",
    query: { archived },
  });

  const archivedCampaigns = useMemo(() => {
    return fetchedCampaigns.filter((e) => e.archivedAt);
  }, [fetchedCampaigns]);

  const campaigns = useMemo(() => {
    return archived ? archivedCampaigns : fetchedCampaigns;
  }, [archived, archivedCampaigns, fetchedCampaigns]);

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((e) =>
      !isEmpty(searchSymbol)
        ? e.titleForHR.toLowerCase().indexOf(searchSymbol) !== -1 ||
          (e.startDate &&
            format(new Date(e.startDate), "dd/MM/yyyy").indexOf(searchSymbol) !== -1) ||
          (e.endDate && format(new Date(e.endDate), "dd/MM/yyyy").indexOf(searchSymbol) !== -1)
        : true
    );
  }, [searchSymbol, campaigns]);

  const tabs = useMemo(() => {
    return [
      {
        active: status === "active",
        link: `/responsable/campagnes${stringify({ status: "active" }, { addQueryPrefix: true })}`,
        label: t("interviews.openedCampaigns", {
          defaultValue: "Campagnes en cours",
        }),
      },
      {
        active: status === "archived",
        link: `/responsable/campagnes${stringify(
          { status: "archived" },
          { addQueryPrefix: true }
        )}`,
        label: t("interviews.closedCampaigns", {
          defaultValue: "Campagnes clôturées",
        }),
      },
    ];
  }, [status, t]);

  const [campaignState, setCampaignState] = useState<Campaign>(filteredCampaigns[0]);

  const { isOpen, show, hide } = useModal();
  const [modalState, setModalState] = useState<EditionContext>(EditionContext.IDLE);
  const modalToDisplay = useCampaignModals({
    resync: async () => {
      await refetch();
    },
    campaign: campaignState,
    context: modalState,
    onClose: () => {
      setActionsState(EditionContext.IDLE);
    },
  });
  const setActionsState = (stateChange: EditionContext) => {
    switch (stateChange) {
      case EditionContext.IDLE: {
        hide();
        break;
      }
      case EditionContext.MODIFY:
      case EditionContext.PARAMETER:
      case EditionContext.NOTIFICATION:
      case EditionContext.UPDATE_STRUCTURE:
        show();
        break;
    }
    setModalState(stateChange);
  };
  const renderCampaigns = (): JSX.Element | JSX.Element[] => {
    if (isEmpty(campaigns)) {
      return (
        <section className={cx(styles.Item, styles.noRunningCampaign)}>
          {archived
            ? t("interviews.noClosedCampaign", {
                defaultValue: "Pas de campagne cloturée.",
              })
            : t("interviews.noOpenedCampaign", {
                defaultValue: "Pas de campagne en cours.",
              })}
        </section>
      );
    }

    if (isEmpty(filteredCampaigns)) {
      return (
        <section className={cx(styles.Item, styles.noRunningCampaign)}>
          {t("interviews.search.empty", {
            defaultValue: "Aucun résultat ne correspond à votre recherche.",
          })}
        </section>
      );
    }

    return filteredCampaigns.map((campaign) => (
      <CampaignCard
        key={campaign.uuid}
        campaign={campaign}
        onDelete={refetch}
        resync={async () => {
          await refetch();
        }}
        setActionsState={setActionsState}
        setCampaignState={setCampaignState}
      />
    ));
  };

  const chooseCampaignModale = () => {
    Acta.setState("modalDisplayed", {
      content: <ChooseCampaignModale />,
      size: "small",
      title: t("interviews.chooseCampaignTitle", {
        defaultValue: "Nouveau",
      }),
      showOverflow: true,
    });
  };

  const handleSearch = useCallback(
    (searchSymbol: string) => {
      setState({ searchSymbol: searchSymbol.toLowerCase() });
    },
    [setState]
  );
  const { settings, userAccessModules } = useSettings();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const canCreateCampaign =
    User.isDemoUser() ||
    User.canCreateInterviewCampaignForDemo() ||
    UserAccessChecker.toCampaignCreation();

  return (
    <>
      <DSLayout
        title={t("interviews.interviewFollowups", {
          defaultValue: "Suivi des entretiens",
        })}
        layouts={
          [
            {
              primaryButton: canCreateCampaign ? (
                <DSNewHeaderButton
                  label={t("interviews.newCampain", {
                    defaultValue: "Nouvelle campagne",
                  })}
                  onClick={chooseCampaignModale}
                />
              ) : undefined,
            },
          ] as const
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.CampaignsList}>
            <main className={styles.container}>
              <header className={styles.actions} data-target="header">
                <FloatingTabs
                  className={styles.tabs}
                  tabs={tabs}
                  navLink={(p: NavLinkProps) => <NavLink {...p} />}
                />
                <SearchInput
                  className={styles.searchInput}
                  placeholder={t("interviews.search", {
                    defaultValue: "Rechercher...",
                  })}
                  onChange={handleSearch}
                  value={state.searchSymbol}
                />
              </header>
              {renderCampaigns()}
            </main>
          </div>
        )}
      </DSLayout>
      {isOpen && modalToDisplay}
    </>
  );
};

export default CampaignsList;
