import React from "react";
import { IUser } from "@skillup/types";
import { isUndefined } from "lodash";
import cx from "classnames";
import { IoIosCheckmarkCircle } from "react-icons/io";

import Icon from "components/Icon";
import { user as userIcon } from "uiAssets/StrokeIcons";
import UserSearchInput, {
  IUserIdentity,
} from "containers/Supervisor/routes/Projects/Components/UserSearchInput";

import styles from "./FindUser.module.scss";
import { TranslationType } from "hooks/useTranslation";

interface IProps {
  user?: IUser | IUserIdentity;
  onSelect?: (user: IUserIdentity) => any;
  label?: string;
  className?: string;
  userAreas?: Array<string>;
  onEdit?: () => any;
  error?: string;
  filter?: (u: IUserIdentity) => boolean;
  showRightIcon?: boolean;
  underlineColor?: string;
  autoFocus?: boolean;
  t: TranslationType;
}

const FindUser = ({
  autoFocus = true,
  user,
  onSelect,
  label,
  className,
  userAreas,
  onEdit,
  error,
  filter,
  showRightIcon,
  underlineColor,
  t,
}: IProps): JSX.Element => (
  <div className={cx(styles.FindUser, className)}>
    {!isUndefined(label) && <span className={styles.label}>{label}</span>}
    {isUndefined(user) ? (
      <UserSearchInput
        autoFocus={autoFocus}
        className={styles.searchInput}
        onSelect={onSelect}
        filter={filter}
        userAreas={userAreas}
        aria-label={label}
        undelineColor={underlineColor}
        t={t}
      />
    ) : (
      <div className={cx(styles.content, { [styles.hasError]: !isUndefined(error) })}>
        <div className={styles.left}>
          <Icon icon={userIcon} width={20} className={styles.icon} />
          <div className={styles.meta} aria-label="user-info">
            <span className={styles.name}>{user.fullName}</span>
            <span className={styles.email}>{user.email}</span>
          </div>
        </div>
        <div className={styles.right}>
          {!isUndefined(onEdit) && (
            <span className={styles.edit} onClick={onEdit}>
              {t("collaborators.findUser.edit", {
                defaultValue: "Modifier",
              })}
            </span>
          )}
          {showRightIcon && user && (
            <IoIosCheckmarkCircle color="#C6DFAD" size={30} style={{ marginLeft: 5 }} />
          )}
        </div>
      </div>
    )}
  </div>
);

export default FindUser;
