import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import { v4 as uuid } from "uuid";
import { Form, Formik, FieldArray } from "formik";

import { getQueryKey } from "@trpc/react-query";
import { useQueryClient } from "@tanstack/react-query";

import { Flex, Text } from "@skillup/design-system";
import { Loader, DSColor, DSModal, DSButton, useModal2, Label as DSLabel } from "@skillup/ui";

import { plural } from "utils/locale";
import { FieldEntity } from "types/skills";
import { trpc, RouterOutputs } from "utils/trpc";
import useTranslation from "hooks/useTranslation";

import Sortable from "../Sortable/Sortable";
import ConfirmationModal from "./ConfirmationModal";

import styles from "./ManageSkillsCustomFieldsModal.module.scss";

const TITLE_TEXT = "Compétences - Champs personnalisés";
const CONFIRM_MODAL_TITLE_TEXT = "Confirmer la suppression ?";
const DESCRIPTION_TEXT =
  "Vous pouvez créer autant de champs personnalisés que nécessaire. L'ordre que vous définirez ici sera repris dans les formulaires de création et de visualisation des compétences.";
const NAME_LABEL = "Intitulé du champ";
const ADD_FIELD_LABEL = "Ajouter un champ personnalisé";
const CANCEL_LABEL = "Annuler";
const SAVE_LABEL = "Enregistrer";
const CONFIRM_MODAL_SAVE_LABEL = "Continuer";
const SAVE_TOAST_TEXT_SUCCESS = "Champs personnalisés enregistrés avec succès";
const SAVE_TOAST_TEXT_ERROR =
  "Une erreur s'est produite lors de l'enregistrement des champs personnalisés";
const REMOVE_TOAST_TEXT_ERROR = "Une erreur est survenue dans la suppression du champ";

interface SkillField {
  ref: string;
  name: string;
  index: number;
  uuid?: string;
  version: number;
  skillsLinked?: number;
}

interface FieldToRemove {
  uuid: string;
  version: number;
}

type FieldType = Exclude<RouterOutputs["fields"]["setFields"], void>["entity"];

const setNewIndexes = <T extends { index: number; version?: number }>(field: T, index: number) => ({
  ...field,
  index: index + 1,
  version: field.version ? field.version + 1 : 1,
});

interface ManageSkillsCustomFieldsProps {
  isOpen: boolean;
  closeModal: () => void;
}

function ManageSkillsCustomFieldsModal({ closeModal, isOpen }: ManageSkillsCustomFieldsProps) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const confirmationModal = useModal2();
  const queryClient = useQueryClient();
  const getAllSkillsKey = getQueryKey(trpc.skills.getAllSkills);

  const [fields, setFields] = useState<Array<SkillField>>([]);
  const [fieldsToRemove, setFieldsToRemove] = useState<Array<FieldToRemove>>([]);
  const [currentField, setCurrentField] = useState<SkillField>();

  const { data, error, refetch, status } = trpc.skills.getFields.useQuery(undefined, {
    enabled: isOpen,
  });

  const setFieldsMutation = trpc.fields.setFields.useMutation({
    onError: () => {
      addToast(
        t("portal.skills.jobsList.customFields.success", {
          defaultValue: SAVE_TOAST_TEXT_ERROR,
        }),
        {
          appearance: "error",
        }
      );
      setFieldsToRemove([]);
    },
    onSuccess: () => {
      addToast(
        t("portal.skills.jobsList.customFields.success", {
          defaultValue: SAVE_TOAST_TEXT_SUCCESS,
        }),
        {
          appearance: "success",
        }
      );
      closeModal();
      refetch();
      queryClient.invalidateQueries(getAllSkillsKey);
      setFieldsToRemove([]);
    },
  });

  const removeFieldsMutation = trpc.fields.removeField.useMutation({
    onError: () =>
      addToast(
        t("portal.skills.jobsList.customFields.success", {
          defaultValue: REMOVE_TOAST_TEXT_ERROR,
        }),
        {
          appearance: "error",
        }
      ),
  });

  useEffect(() => {
    if (data) {
      setFields(
        data.map(
          (field) =>
            ({
              ...field,
              ref: uuid(),
            }) as SkillField
        )
      );
    }
  }, [data, isOpen]);

  const handleSubmit = ({ skillsCustomFields }: { skillsCustomFields: Array<SkillField> }) => {
    if (fieldsToRemove.length > 0) {
      removeFieldsMutation.mutate(fieldsToRemove);
    }

    setFieldsMutation.mutate({
      entity: FieldEntity.SKILL as unknown as FieldType,
      fields: skillsCustomFields.map(setNewIndexes).filter((f) => f.name !== ""),
    });
  };

  const confirmRemove = () => {
    if (currentField.uuid) {
      setFieldsToRemove([
        ...fieldsToRemove,
        { uuid: currentField.uuid, version: currentField.version + 1 },
      ]);
      setFields(fields.filter((field) => field.uuid !== currentField.uuid));
      refetch();
    }
    confirmationModal.hide();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ skillsCustomFields: fields }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, resetForm, values }) => (
        <Form>
          <ConfirmationModal
            confirm={() => confirmRemove()}
            isOpen={confirmationModal.isOpen}
            closeModal={confirmationModal.hide}
            title={t("portal.skills.skillsList.modalCustomFields.delete", {
              defaultValue: CONFIRM_MODAL_TITLE_TEXT,
            })}
            saveLabel={t("portal.skills.skillsList.modalCustomFields.saveLabel", {
              defaultValue: CONFIRM_MODAL_SAVE_LABEL,
            })}
            content={
              <Text
                marginBottom="xs"
                fontSize="fontSizeS"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {t("portal.skills.skillsList.modalCustomFields.confirmationText", {
                  defaultValue: `Ce champ est rempli pour {{entity}}. En supprimant ce champ, vous supprimerez également les informations contenues dans celle-ci dans chacune des compétences. Cette action est irréversible.`,
                  entity: t("portal.skills.skillsList.modalCustomFields.confirmationSkill", {
                    defaultValue: plural(currentField?.skillsLinked ?? 0, "%n compétence%s"),
                  }),
                })}
              </Text>
            }
          />

          <DSModal
            isOpen={isOpen}
            portalTarget={document.body}
            className={styles.skillsCustomFieldsModal}
          >
            <DSModal.Header
              onClose={() => {
                closeModal();
                resetForm();
              }}
            >
              <DSModal.Header.Title
                title={t("portal.skills.skillsList.modalCustomFields.title", {
                  defaultValue: TITLE_TEXT,
                })}
              />
            </DSModal.Header>

            <DSModal.Content
              borderColor={DSColor["border-onLight"]}
              backgroundColor={DSColor["surface-light-darker"]}
            >
              {status === "loading" && <Loader />}
              {status === "error" && <div>{error.message}</div>}
              {status === "success" && (
                <Flex paddingVertical="s" flexDirection="column">
                  <FieldArray
                    name="skillsCustomFields"
                    render={({ move, push, remove }) => (
                      <React.Fragment>
                        <Flex marginBottom="s">
                          <Text espaceFont="captionRegular">
                            {t("portal.skills.skillsList.modalCustomFields.description", {
                              defaultValue: DESCRIPTION_TEXT,
                            })}
                          </Text>
                        </Flex>

                        <Flex marginBottom="xxs" marginRight="jumbo">
                          <DSLabel
                            required
                            label={t("portal.skills.skillsList.modalCustomFields.nameLabel", {
                              defaultValue: NAME_LABEL,
                            })}
                          />
                        </Flex>

                        <Sortable
                          inputName="skillsCustomFields"
                          arrayHelpers={{ move, remove }}
                          setCurrentField={setCurrentField}
                          fields={values.skillsCustomFields}
                          confirmationModal={confirmationModal}
                        />

                        <DSButton
                          buttonSize="S"
                          label={t("portal.skills.skillsList.modalCustomFields.addFieldLabel", {
                            defaultValue: ADD_FIELD_LABEL,
                          })}
                          onClick={() => {
                            push({
                              index: values.skillsCustomFields.length + 1,
                              name: "",
                              ref: uuid(),
                            });
                          }}
                        />
                      </React.Fragment>
                    )}
                  />
                </Flex>
              )}
            </DSModal.Content>
            <DSModal.Footer>
              <DSModal.Footer.CancelButton
                onClick={() => {
                  closeModal();
                  resetForm();
                }}
                label={t("portal.skills.skillsList.modalCustomFields.cancelLabel", {
                  defaultValue: CANCEL_LABEL,
                })}
              />
              <DSModal.Footer.PrimaryButton
                onClick={() => handleSubmit()}
                label={t("portal.skills.skillsList.modalCustomFields.saveLabel", {
                  defaultValue: SAVE_LABEL,
                })}
              />
            </DSModal.Footer>
          </DSModal>
        </Form>
      )}
    </Formik>
  );
}

export default ManageSkillsCustomFieldsModal;
