import { useState, useMemo, useCallback, useEffect } from "react";
import { debounce } from "lodash";

import { DSFormGroupTextInput, DSTextInput, Loader, DSButton } from "@skillup/ui";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "../AdminPortalToggle/AdminPortalToggle";

import useAdminProperties, { saveChanges } from "../state/useAdminProperties";
import EditBannerModal from "../LmsBanners/EditBannerModal/EditBannerModal";
import BannersList from "../LmsBanners/BannersList";

import styles from "./PortalContent.module.scss";
import { useToggle } from "react-use";
import useTranslation from "hooks/useTranslation";

// interface LMSBannersProps {}

export default function LMSBanners() {
  const { data, refetch, loading } = useAdminProperties();
  const { t } = useTranslation();

  const adminProperties = useMemo(() => {
    return data?.properties ?? null;
  }, [data]);

  const [title, setTitle] = useState(adminProperties?.["portalLmsTitle"] ?? "");
  const isEnabled = useMemo(() => {
    return adminProperties?.["portalLmsEnabled"] ?? false;
  }, [adminProperties]);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [currentBanner, setCurrentBanner] = useState(null);

  useEffect(() => {
    setTitle(adminProperties?.["portalLmsTitle"] ?? "");
  }, [adminProperties]);

  const handleOnChange = async (body: CompanyAdminPayload) => {
    await saveChanges({
      portalLmsTitle: title,
      portalLmsEnabled: body.portalLmsEnabled,
    });
    await refetch();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedTitleUpdate = useCallback(
    debounce(async (portalLmsTitle) => {
      const body = {
        portalLmsTitle: portalLmsTitle,
        portalLmsEnabled: true,
      };

      try {
        await DataLayer.request({
          body: JSON.stringify(body),
          method: "POST",
          url: "/v1/portal/admin",
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: t("trainings.view.lms_banner.toast.save_succes", {
            defaultValue: "Titre sauvegardé.",
          }),
          type: "success",
        });
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: t("trainings.view.lms_banner.toast.save_error", {
            defaultValue: "Échec d’ennregistrement.",
          }),
          type: "error",
        });
      }
    }, 2000),
    []
  );

  const handleTitleChange = async (portalLmsTitle: string) => {
    setTitle(portalLmsTitle);
    delayedTitleUpdate(portalLmsTitle);
  };

  const handleCloseModal = (banner) => {
    if (banner) {
      // TODO: refetch banners / mutate query
      // console..log("modal closed", banner);
    }
    toggleModal();
    setCurrentBanner(null);
  };

  return (
    <div className={styles.lmsBanners}>
      <div>
        <h3 id="section-lms-banners" className={styles.title}>
          {t("trainings.view.lms_banner.title", {
            defaultValue: "Bannières LMS",
          })}
        </h3>
        {loading && <Loader />}
        {!loading && (
          <div className={styles.content}>
            <AdminPortalToggle
              propertiesKey="portalLmsEnabled"
              label={t("trainings.view.lms_banner.action.enable", {
                defaultValue: "Activer l'accès à un ou des LMS",
              })}
              type={PortalToggleType.ENABLED}
              isSelected={isEnabled}
              onChange={handleOnChange}
            />
            {isEnabled && (
              <>
                <DSFormGroupTextInput
                  label={t("trainings.view.lms_banner.form.title.label", {
                    defaultValue: "Titre affiché au dessus des bannières",
                  })}
                  className={styles.titleForm}
                >
                  <DSTextInput name="title" value={title} onChange={handleTitleChange} />
                </DSFormGroupTextInput>
                <BannersList
                  onEditBanner={(banner) => {
                    setCurrentBanner(banner);
                    toggleModal();
                  }}
                />
                <DSButton
                  buttonSize="S"
                  emphasis="Mid"
                  label={t("trainings.view.lms_banner.action.add_lms_access", {
                    defaultValue: "Ajouter un accès LMS",
                  })}
                  onClick={toggleModal}
                />
                <EditBannerModal
                  isOpen={isModalOpen}
                  banner={currentBanner}
                  onClose={handleCloseModal}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
