import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useRef, useMemo, useState, useCallback } from "react";

import cx from "classnames";
import { MdPersonAdd as PersonAdd } from "react-icons/md";
import { MdExpandMore as ExpandMore, MdExpandLess as ExpandLess } from "react-icons/md";

import { GridColDef, useGridApiRef, GridInitialState } from "@mui/x-data-grid-pro";

import { Flex, Loader } from "@skillup/design-system";
import { UserRoutes } from "@skillup/espace-rh-bridge";
import {
  DSButton,
  useModal,
  useModal2,
  FilterRef,
  DSFilters,
  DSDataGrid,
  DSDropdownItem,
  usePersistColumnSettings,
} from "@skillup/ui";

import User from "utils/User";
import { plural } from "utils/locale";
import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import useSettings, { useAccessCheckFor } from "hooks/useSettings";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import AddUserModal from "containers/Supervisor/routes/User/components/AddUserModal";
import EditUserModal from "containers/Supervisor/routes/User/components/EditUserModal/EditUserModal";
import addUserApi, {
  CreateCollaboratorPayload,
} from "containers/Supervisor/routes/User/api/addUser";
import patchUser, {
  UpdateCollaboratorPayload,
} from "containers/Supervisor/routes/User/api/patchUser";

import useTableData from "./utils/useTableData";
import { Collaborator } from "../../api/getUsers";
import { CollaboratorRow } from "./utils/parseCollaboratorIntoRow";
import { CollaboratorsProvider, useCollaboratorsContext } from "./CollaboratorContext";

import styles from "./CollaboratorTable.module.scss";

const CollaboratorTable = () => {
  return (
    <CollaboratorsProvider>
      <Layout />
    </CollaboratorsProvider>
  );
};

const Layout = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const hasAccessToTrainings = useAccessCheckFor("trainings");
  const { settings } = useSettings();
  const { addToast } = useToasts();
  const filterRef = useRef<FilterRef>();
  const {
    hide: hideCreationModal,
    isOpen: isCreationModalOpen,
    show: showCreationModal,
  } = useModal();
  const {
    hide: closeModal,
    isOpen: isEditCollabModalOpen,
    show: openEditCollabModal,
  } = useModal2();
  const [isExpanded, setExpand] = useState(true);

  const {
    filtersHook,
    paginationHook: { actualPaginationModel, setPaginationModel, total: totalRows },
    refetch,
    sortHook: { setSortModel, sortModel },
    users,
  } = useCollaboratorsContext();
  const { conf, filterCategories, filters, setFilterValues } = filtersHook;

  const [selectedCollaborator, setSelectedCollaborator] = useState<Collaborator>();
  const filtersWithValues = Object.values(filters).filter(({ value }) => value !== undefined);
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>(filtersWithValues);

  const apiRef = useGridApiRef();
  const { gridInitialState } = usePersistColumnSettings({
    apiRef,
    persistenceId: `grid-collaborators`,
  });

  const initialState: GridInitialState = useMemo(() => {
    return {
      pagination: { paginationModel: { page: 0, pageSize: 20 } },
      pinnedColumns: {
        left: ["lastName", "firstName"],
        right: ["actions"],
      },
      ...gridInitialState,
    };
  }, [gridInitialState]);

  const editRowCollaborator = (row: CollaboratorRow) => {
    const collaborator = users.collabs.find((c) => c.uuid === row.uuid);
    setSelectedCollaborator(collaborator);
    openEditCollabModal();
  };

  const { columns, rows } = useTableData({
    actions: { editRowCollaborator },
    collaborators: users.collabs,
    maxLevel: users.maxLevel,
    t,
  });

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };

  const handleRowClick = (row) => {
    history.push(
      `/responsable/collaborateurs/${row.id}${hasAccessToTrainings ? "/formations" : ""}`
    );
  };

  const openExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetUserExportRouteData>(
      {
        method: "GET",
        params: { fileName: "Utilisateurs.xlsx" },
        path: "/user/export/{fileName}",
      },
      {
        filename: "Utilisateurs.xlsx",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        target: "API",
      }
    );
  }, []);

  const openAllUsersExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetAllUsersExportRouteData>(
      {
        method: "GET",
        params: { fileName: "UtilisateursFull.xlsx" },
        path: "/user/export-all/{fileName}",
      },
      {
        filename: "UtilisateursFull.xlsx",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        target: "API",
      }
    );
  }, []);

  const createUser = useCallback(
    async (payload: CreateCollaboratorPayload) => {
      try {
        const collaborator = await addUserApi(payload);
        hideCreationModal();
        if (collaborator) {
          refetch();
        }
      } catch (err) {
        addToast(
          t("collaborators.toast.error.createUser", {
            defaultValue: `Impossible de créer un collaborateur: {{message}}`,
            message: err?.message,
          }),
          { appearance: "error" }
        );
      }
    },
    [addToast, hideCreationModal, refetch, t]
  );

  const updateUser = useCallback(
    async (uuid: string, payload: UpdateCollaboratorPayload) => {
      try {
        await patchUser(uuid, payload);
        refetch();
        addToast(
          t("collaborators.toast.success.updateUser", {
            defaultValue: `Les données du collaborateur ont été mises à jour.`,
          }),
          { appearance: "success" }
        );
      } catch (err) {
        addToast(
          t("collaborators.toast.error.updateUser", {
            defaultValue: `Erreur lors de la mise à jour du collaborateur.`,
          }),
          { appearance: "error" }
        );
      }
    },
    [addToast, refetch, t]
  );

  const collaboratorTradLabel = t("collaborators.layout.title", {
    defaultValue: `Collaborateurs`,
  });

  return (
    <DSLayout
      title={totalRows > 0 ? `${collaboratorTradLabel} (${totalRows})` : `${collaboratorTradLabel}`}
      header={
        <>
          <div className={cx(styles.layoutHeader, { [styles.isExpanded]: isExpanded })}>
            <DSFilters
              t={t}
              config={conf}
              darkMode={true}
              ref={filterRef}
              filters={filters}
              className={styles.filters}
              isFilterableFilterList={true}
              duplicateFilters={["skills"]}
              filterCategories={filterCategories}
              translationPrefix="collaborators.dataGrid"
              onChange={(values) => {
                setCurrentFilters(values);
                setFilterValues(values);
              }}
            />
            <p>
              {t("collaborators.list.n_filters_applied", {
                count: Object.keys(currentFilters).filter((key) => currentFilters[key].value)
                  .length,
                defaultValue: "{{ count }} filtre appliqué",
              })}
            </p>

            <DSButton
              darkMode
              iconOnly
              className={styles.expandButton}
              icon={isExpanded ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setExpand(!isExpanded)}
            />
          </div>
        </>
      }
      layouts={[
        {
          dropdownContent: [
            settings?.portalHRUploadUserDBEnabled ? (
              <DSDropdownItem
                key="base-import"
                onClick={() => history.push("/responsable/collaborateurs/imports")}
                label={t("collaborators.layout.action.importUserBase", {
                  defaultValue: `Importer une base`,
                })}
              />
            ) : undefined,
            <DSDropdownItem
              key="base-export"
              onClick={openExportFile}
              label={t("collaborators.layout.action.exportUserBase", {
                defaultValue: `Exporter la base utilisateur`,
              })}
            />,
            User.isSkillupAdmin() ? (
              <DSDropdownItem
                key="export-all-users"
                onClick={openAllUsersExportFile}
                label={t("collaborators.layout.action.exportAllUser", {
                  defaultValue: `[OPS] Exporter la base avec les utilisateurs inactifs`,
                })}
              />
            ) : undefined,
          ].filter(Boolean),
          primaryButton: (
            <DSNewHeaderButton
              icon={<PersonAdd />}
              onClick={showCreationModal}
              label={t("collaborators.layout.action.addUser", {
                defaultValue: `Ajouter un collaborateur`,
              })}
            />
          ),
        },
      ]}
    >
      <Flex width="100%" paddingTop="m" paddingHorizontal="l" flexDirection="column">
        {users.isLoading ? (
          <Loader fillSpace />
        ) : (
          <DSDataGrid
            pagination
            rows={rows ?? []}
            columns={columns}
            rowCount={totalRows}
            sortModel={sortModel}
            loading={users.isLoading}
            disableRowSelectionOnClick
            initialState={initialState}
            paginationModel={actualPaginationModel}
            onRowClick={handleRowClick}
            onSortModelChange={setSortModel}
            onFilter={handleTableColumnFilterClick}
            onPageChange={(page, pageSize) => {
              setPaginationModel({ page, pageSize });
            }}
            emptyOverlay={{
              text: t("collaborators.list.emptyOverlayText", {
                defaultValue: "Aucun collaborateur ne correspond à votre recherche.",
              }),
            }}
            entityName={plural(
              totalRows || 0,
              `%n ${t("collaborators.list.collaborator.entityName", {
                defaultValue: "collaborateur",
              })}%s`
            )}
            errorOverlay={{
              text: [
                t("collaborators.list.errorOverlayText.firstSentence", {
                  defaultValue: `Une erreur est survenue lors du chargement des collaborateurs.`,
                }),
                t("collaborators.list.errorOverlayText.secondSentence", {
                  defaultValue: `Veuillez réessayer ultérieurement.`,
                }),
                t("collaborators.list.errorOverlayText.thirdSentence", {
                  defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
                }),
              ],
            }}
          />
        )}
        {isCreationModalOpen && (
          <AddUserModal
            t={t}
            loading={false}
            submitRequest={createUser}
            onClose={hideCreationModal}
          />
        )}

        {selectedCollaborator && (
          <EditUserModal
            t={t}
            updateRequest={updateUser}
            isOpen={isEditCollabModalOpen}
            userData={selectedCollaborator}
            userUuid={selectedCollaborator.uuid}
            closeModal={() => {
              closeModal();
            }}
          />
        )}
      </Flex>
    </DSLayout>
  );
};

export default CollaboratorTable;
