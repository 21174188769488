import storage, { allPersistentStorages } from "services/storage";
import * as Sentry from "utils/Sentry";
import checkAuthentication from "utils/checkAuthentication";
import { BasicRouteType } from "types/route";
import { forEach, isArray, isDate, isObject } from "lodash";

export type MethodsType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
export type TargetType =
  | "API"
  | "PORTAL"
  | "AUTH"
  | "CORE_HR"
  | "PEOPLE_REVIEW"
  | "INSIGHTS_SERVICE"
  | "INSIGHTS_SERVICE_V2"
  | "ANALYTICS_CONFIG_SERVICE"
  | "TRAINING_API"
  | "PRODUCTION_LOCALES_API_PROXY"
  | "CURRENT_ENVIRONMENT_LOCALES_SERVICE"
  | undefined;
export enum ResponseValue {
  // Specify what data is returned by the DataLayer function
  JSON = "json", // (default) parse API response body as JSON and return JSON object
  RAW_BODY = "rawBody", // return raw API response body
  RAW_RESPONSE = "rawResponse", // return raw API request object with body, headers, status code and so on
}
export interface IOptions {
  authentificationOrganization?: string;
  authentificationToken?: string;
  body?: string | FormData | null;
  contentType?: string; // Specify for autocompletion
  method?: MethodsType;
  responseType?: "arraybuffer" | "blob" | "document" | "json" | "text";
  responseValue?: ResponseValue;
  target?: TargetType;
  url: string;
}

export interface IDefaultOptions extends IOptions {
  body: null;
  method: "GET";
  target: "API";
  url: "";
}

const defaultOptions: IDefaultOptions = {
  body: null,
  method: "GET",
  target: "API",
  url: "",
};

const {
  REACT_APP_PUBLIC_API_ROOT,
  REACT_APP_PORTAL_API_ROOT,
  REACT_APP_AUTH_API_ROOT,
  REACT_APP_CORE_HR_API_ROOT,
  REACT_APP_PEOPLE_REVIEW_API_ROOT,
  REACT_APP_INSIGHTS_SERVICE_API_ROOT,
  REACT_APP_ANALYTICS_CONFIG_ROOT,
  REACT_APP_TRAINING_API_ROOT,
  REACT_APP_LOCALES_API_ROOT,
  REACT_APP_CURRENT_ENVIRONMENT_LOCALES_API,
} = process.env;

const buildRootURL = (target: TargetType, url: string) => {
  switch (target) {
    case "API":
      return `${REACT_APP_PUBLIC_API_ROOT}${url}`;
    case "PORTAL":
      return `${REACT_APP_PORTAL_API_ROOT}/v1${url}`;
    case "AUTH":
      return `${REACT_APP_AUTH_API_ROOT}/v1${url}`;
    case "CORE_HR":
      return `${REACT_APP_CORE_HR_API_ROOT}${url}`;
    case "PEOPLE_REVIEW":
      return `${REACT_APP_PEOPLE_REVIEW_API_ROOT}${url}`;
    case "INSIGHTS_SERVICE":
      return `${REACT_APP_INSIGHTS_SERVICE_API_ROOT}/v1${url}`;
    case "INSIGHTS_SERVICE_V2":
      return `${REACT_APP_INSIGHTS_SERVICE_API_ROOT}/v2${url}`;
    case "ANALYTICS_CONFIG_SERVICE":
      return `${REACT_APP_ANALYTICS_CONFIG_ROOT}${url}`;
    case "TRAINING_API":
      return `${REACT_APP_TRAINING_API_ROOT}${url}`;
    case "PRODUCTION_LOCALES_API_PROXY":
      return `${REACT_APP_LOCALES_API_ROOT}${url}`;
    case "CURRENT_ENVIRONMENT_LOCALES_SERVICE":
      return `${REACT_APP_CURRENT_ENVIRONMENT_LOCALES_API}${url}`;
    default:
      return url;
  }
};

export default {
  buildRootURL,
  init: {},

  request<T extends BasicRouteType>(options: IOptions = defaultOptions): Promise<T["response"]> {
    return new Promise((resolve, reject) => {
      const optionsWithDefault: IOptions = {
        ...defaultOptions,
        ...(options || {}),
      };
      const {
        authentificationOrganization,
        authentificationToken,
        body,
        contentType,
        method,
        responseType,
        responseValue,
        target,
        url,
      } = optionsWithDefault;

      const requestUrl = buildRootURL(target, url);

      const request = new XMLHttpRequest();
      request.open(method || "GET", requestUrl, true);

      if (responseType) {
        request.responseType = responseType;
      }

      if (process.env.REACT_APP_BASIC_AUTH_HEADER) {
        request.setRequestHeader("Authorization", process.env.REACT_APP_BASIC_AUTH_HEADER);
      }

      const authToken = authentificationToken || storage.get("X-Auth-Token", allPersistentStorages);

      if (authToken) {
        request.setRequestHeader("X-Auth-Token", authToken);
      }

      const authAsOrg = authentificationOrganization || storage.get("X-Auth-As-Organization");
      if (authAsOrg) {
        request.setRequestHeader("X-Auth-As-Organization", authAsOrg);
      }

      const authAsUser = storage.get("X-Auth-As-User");

      if (authAsUser) {
        request.setRequestHeader("X-Auth-As-User", authAsUser);
      }

      if (contentType === undefined) {
        request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      } else if (contentType !== "multipart/form-data") {
        // Do not set content type for multipart/form-data, browser will set the content type with boundary
        request.setRequestHeader("Content-Type", contentType);
      }

      request.onload = () => {
        let result = request.response;
        try {
          result = JSON.parse(request.response);
        } catch (e) {
          console.error(e);
        }

        if (request.status >= 200 && request.status < 400) {
          switch (responseValue) {
            case ResponseValue.RAW_BODY:
              result = request.response;
              break;
            case ResponseValue.RAW_RESPONSE:
              result = request;
              break;
          }
          resolve(result);
        } else {
          console.error(result);

          const { statusCode, message } = result;
          const { response, responseURL, status } = request;

          if (status === 401) {
            checkAuthentication();
          }

          const error = new Error(`DataLayer request status code ${request.status}`);
          const payload = {
            message,
            response,
            responseURL,
            statusCode,
            ...optionsWithDefault,
          };

          Sentry.logError({
            error,
            payload,
          });

          reject(result);
        }
      };

      request.onerror = (error) => {
        console.error(error);
        Sentry.logError({
          error: new Error("DataLayer request onerror"),
          payload: {
            response: request.response,
            responseURL: request.responseURL,
            ...optionsWithDefault,
          },
        });

        return reject(Error("Network Error"));
      };

      return request.send(body);
    });
  },
};

/*
 * convert an {} to a form data
 */
export const hashToFormData = (hash: any, path: string) => {
  const formData = new FormData();

  forEach(hash, (value, key) => {
    if (isArray(value)) {
      forEach(value, (arrayValue) => {
        formData.append(
          `${path}[${key}][]`,
          isObject(arrayValue) ? JSON.stringify(arrayValue) : arrayValue
        );
      });
    } else if (isDate(value)) {
      formData.append(`${path}[${key}]`, value.toISOString());
    } else if (value instanceof Blob || value instanceof File) {
      formData.append(`${path}[${key}]`, value, "file");
    } else if (isObject(value)) {
      forEach(value, (hashValue, hashKey) => {
        if (isArray(hashValue)) {
          forEach(hashValue, (arrayValue) => {
            formData.append(
              `${path}[${key}][${hashKey}][]`,
              // @ts-ignore
              isObject(arrayValue) ? JSON.stringify(arrayValue) : arrayValue
            );
          });
        } else {
          formData.append(`${path}[${key}][${hashKey}]`, hashValue);
        }
      });
    } else if (value !== undefined) {
      formData.append(`${path}[${key}]`, value);
    }
  });
  return formData;
};
