import { PayloadAction } from "@reduxjs/toolkit";
import { BuilderTemplate, PermissionAction } from "../types";
import { getChildErrors } from "../utils";

export function changePermission(state: BuilderTemplate, action: PayloadAction<PermissionAction>) {
  const { indexes } = action.payload;
  const child = state.sections[indexes.section].pages[indexes.page].children[indexes.child];

  if (!("disallow" in child)) {
    return state;
  }

  child.disallow[action.payload.role][action.payload.action] =
    action.payload.type === "revokePermission"; // it's a restriction object

  if (child.kind === "targets" && action.payload.action === "creation") {
    if (!(child.type === "next" && action.payload.role === "manager")) {
      child.disallow[action.payload.role].edition = action.payload.type === "revokePermission";
      child.disallow[action.payload.role].deletion = action.payload.type === "revokePermission";
    }
  }

  child.errors = getChildErrors(child, action.payload.t, action.payload.choicesForOrdinalTargets);
  state.sections[indexes.section].pages[indexes.page].children[indexes.child] = child;
  return state;
}
