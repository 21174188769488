import { EmployeeProfileConfigurationRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

export type EmployeeField =
  EmployeeProfileConfigurationRoutes.GetConfiguration["response"]["fields"][number];
export type FieldConfiguration =
  EmployeeProfileConfigurationRoutes.GetConfiguration["response"]["fieldConfiguration"][number];
export type HistoricalDataConfiguration =
  EmployeeProfileConfigurationRoutes.GetConfiguration["response"]["historicalDataConfiguration"];

export const getEmployeeProfileConfigurationQueryKey = "employee-profile-configuration";

export async function getEmployeeProfileConfiguration(): Promise<{
  employeeFields: EmployeeField[];
  fieldConfiguration: FieldConfiguration[];
  historicalDataConfiguration: HistoricalDataConfiguration;
}> {
  const configuration = await buildRequest<EmployeeProfileConfigurationRoutes.GetConfiguration>({
    method: "GET",
    path: `/employee-profile-configuration`,
    target: "CORE_HR",
  })();

  return {
    employeeFields: configuration.fields,
    fieldConfiguration: configuration.fieldConfiguration,
    historicalDataConfiguration: configuration.historicalDataConfiguration,
  };
}
