import { MdWarning as Warning } from "react-icons/md";
import { Fragment } from "react";
import { DSButton, DSCard, Switch } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import styles from "./Steps.module.scss";
import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";

interface IProps {
  readonly mailingSettings: CampaignMailingSettings;
  readonly updateOne: (key: string, enabled: boolean) => void;
  readonly updateAllNotif: (enabled: boolean) => void;
  readonly updateAllRemind: (enabled: boolean) => void;
  readonly warning: boolean;
}

const notificationsMapping = (t) => ({
  notificationStart: t("interviews.campaigncreation.notifications.campaignstart", {
    defaultValue: "Lancement de campagne",
  }),
  notificationCreated: t("interviews.campaigncreation.notifications.interviewcreation", {
    defaultValue: "Création d'un entretien",
  }),
  notificationManagerChange: t("interviews.campaigncreation.notifications.managerchange", {
    defaultValue: "Changement de manager",
  }),
  notificationShared: t("interviews.campaigncreation.notifications.interviewshared", {
    defaultValue: "Partage de la préparation",
  }),
  notificationSigned: t("interviews.campaigncreation.notifications.interviewreadytosign", {
    defaultValue: "Entretien prêt à être signé",
  }),
  notificationReopen: t("interviews.campaigncreation.notifications.interviewreopen", {
    defaultValue: "Réouverture d’un entretien",
  }),
});

const remindersMapping = (t) => ({
  reminderMinus14: t("interviews.campaigncreation.notifications.reminderminus14", {
    defaultValue: "14 jours avant la date de fin",
  }),
  reminderMinus7: t("interviews.campaigncreation.notifications.reminderminus7", {
    defaultValue: "7 jours avant la date de fin",
  }),
  reminderMinus1: t("interviews.campaigncreation.notifications.reminderminus1", {
    defaultValue: "La veille de la date de fin",
  }),
  reminderPlus1: t("interviews.campaigncreation.notifications.reminderplus1", {
    defaultValue: "Le lendemain de la date de fin",
  }),
});

const MailingSettings = ({
  mailingSettings,
  updateOne,
  updateAllNotif,
  updateAllRemind,
  warning,
}: IProps) => {
  const { t } = useTranslation();
  const everythingNotifDisabled = Object.keys(mailingSettings)
    .filter((s) => notificationsMapping(t)[s])
    .every((k) => mailingSettings[k] === false);
  const everythingRemindDisabled = Object.keys(mailingSettings)
    .filter((s) => remindersMapping(t)[s])
    .every((k) => mailingSettings[k] === false);

  // TODO: Once new design system components are implemented, make sure accessibility works properly on all controls

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.MailingSettingsCard} showTitle={false}>
        <div className={styles.MailingSettingsWrapper}>
          <div className={styles.notificationsWrapper}>
            <label>
              {t("interviews.campaigncreation.notifications.title", {
                defaultValue: "Notifications par mail par événement :",
              })}
            </label>
            {Object.keys(notificationsMapping(t)).map((key) => {
              if (mailingSettings[key] === undefined) {
                return undefined;
              }
              const label = notificationsMapping(t)[key];
              return (
                <Fragment key={`toggle-${key}`}>
                  <div className={styles.notificationsToggleWrapper}>
                    <Switch
                      active={mailingSettings[key]}
                      onToggle={(checked) => updateOne(key, checked)}
                      className={styles.notificationsToggle}
                      label={label}
                    />
                  </div>
                </Fragment>
              );
            })}
            <DSButton
              label={
                everythingNotifDisabled
                  ? t("interviews.campaigncreation.notifications.enableAll", {
                      defaultValue: "Tout activer",
                    })
                  : t("interviews.campaigncreation.notifications.disableAll", {
                      defaultValue: "Tout désactiver",
                    })
              }
              className={styles.toggleAllButton}
              onClick={() => {
                updateAllNotif(everythingNotifDisabled);
              }}
              emphasis="Low"
              buttonSize="S"
            />
          </div>

          <div className={styles.remindersWrapper}>
            <label>
              {t("interviews.campaigncreation.notifications.reminder.title", {
                defaultValue: "Relances en cas de non-signature : ",
              })}
            </label>
            {Object.keys(remindersMapping(t)).map((key) => {
              const label = remindersMapping(t)[key];
              return (
                <div key={`toggle-${key}`} className={styles.remindersToggleWrapper}>
                  <Switch
                    active={mailingSettings[key]}
                    onToggle={(checked) => updateOne(key, checked)}
                    className={styles.remindersToggle}
                    label={label}
                  />
                </div>
              );
            })}
            <DSButton
              label={
                everythingRemindDisabled
                  ? t("interviews.campaigncreation.notifications.enableAll", {
                      defaultValue: "Tout activer",
                    })
                  : t("interviews.campaigncreation.notifications.disableAll", {
                      defaultValue: "Tout désactiver",
                    })
              }
              className={styles.toggleAllButton}
              onClick={() => {
                updateAllRemind(everythingRemindDisabled);
              }}
              emphasis="Low"
              buttonSize="S"
            />
          </div>
        </div>

        {warning && (
          <div className={styles.warningContainer}>
            <>
              <Warning color="#FE9D58" />
            </>
            <div className={styles.warningText}>
              {t("interviews.campaigncreation.notifications.warning", {
                defaultValue:
                  "Les modifications apportées ne sont pas rétroactives : il est possible que certaines notifications aient déjà été envoyées.",
              })}
            </div>
          </div>
        )}
      </DSCard>
    </div>
  );
};

export default MailingSettings;
