import React from "react";

import { Currency, LocaleDate, formatMonetary } from "@skillup/shared-utils";

import User from "utils/User";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import styles from "./TotalPrice.module.scss";

export default ({ initialData, row }) => {
  const { t, i18n } = useTranslation();
  const settings = useSettings();

  const currency = settings.settings.currency;
  const language = i18n.language;

  const currentRow = initialData[row.id];

  const total = User.isCompanyWithTax()
    ? currentRow.training?.totalWithTax ?? currentRow.training?.total
    : currentRow.training?.total;
  const formattedTotalPrice = formatPrice(total, currency, language);

  const pricePerTrainee = User.isCompanyWithTax()
    ? currentRow.training?.pricePerTraineeWithTax ?? currentRow.training?.pricePerTrainee
    : currentRow.training?.pricePerTrainee;
  const formattedPricePerTrainee = formatPrice(pricePerTrainee, currency, language).replace(
    new RegExp(/(,[0]{2})/),
    ""
  );

  return (
    <div className={styles.TotalPrice}>
      <div>{formattedTotalPrice}</div>
      {!!pricePerTrainee && (
        <span>
          {t("trainings.view.session_list.datatable.formattedPricePerTrainee", {
            defaultValue: "par collab: {{ price }}",
            price: formattedPricePerTrainee,
          })}
        </span>
      )}
    </div>
  );
};

const formatPrice = (price: number, currency: Currency, language: LocaleDate.Locales) => {
  return formatMonetary(price ?? 0, {
    currency,
    locale: language,
    decimals: 2,
  });
};
