import { useMemo } from "react";
import { i18n } from "i18next";
import {
  useTranslation as useI18NextTranslationHook,
  UseTranslationResponse,
  Namespace,
} from "react-i18next";
import { LocaleDate } from "@skillup/shared-utils";
import User from "utils/User";
import { useLocation } from "react-router-dom";
import { useTranslationAPITarget } from "i18n";
import { TargetType } from "utils/DataLayer";

export interface CustomI18n extends i18n {
  language: LocaleDate.Locales;
}

interface UseTranslationReturnType<K extends Namespace<string>, TPrefix>
  extends Omit<UseTranslationResponse<K, TPrefix>, "t" | "i18n"> {
  t: TranslationType;
  activeKeys: Map<Key, { defaultValue: DefaultValue; countable: boolean }>;
  i18n: CustomI18n;
  translationAPITarget: TargetType;
  toggleTranslationAPITarget: () => void;
}

export type TFunction = UseTranslationReturnType<Namespace<string>, undefined>["t"];
type Key = string;
type DefaultValue = string;

const activeKeys = new Map<Key, { defaultValue: DefaultValue; countable: boolean }>();
let currentView = "";

const useTranslation = (): UseTranslationReturnType<Namespace<string>, undefined> => {
  const user = User.getUserData();
  const { target, toggleTarget } = useTranslationAPITarget();
  const localesNs: string = useMemo(() => user?.activeCompany?.slug ?? "default", [user]);

  const location = useLocation();

  const { t, i18n, ready } = useI18NextTranslationHook(localesNs, { useSuspense: false });

  const updateActiveKeys = (key: string, defaultValue: string, options: { count?: number }) => {
    if (activeKeys.has(key)) return;
    activeKeys.set(key, { defaultValue, countable: options.count != null });
  };

  // Effect to reset active keys when the view changes
  // useMemo instead of useEffect to run before rendering
  useMemo(() => {
    const pathname = location?.pathname?.split("responsable/")?.[1] ?? "";
    const newView = pathname.split("/")[0];

    if (currentView !== newView) {
      currentView = newView;
      activeKeys.clear();
    }
  }, [location]);

  const tOut = useMemo(
    () =>
      (
        key: string,
        options: {
          [key: string]: any;
          defaultValue?: string;
        } = {}
      ) => {
        const { defaultValue, count, ...rest } = options;
        updateActiveKeys(key, defaultValue ?? "", { count });
        const value = t(`${localesNs}:${key}`, { defaultValue, count, ...rest });
        return value ?? defaultValue;
      },
    [t, localesNs]
  );

  return {
    t: tOut,
    i18n,
    ready,
    activeKeys,
    translationAPITarget: target,
    toggleTranslationAPITarget: toggleTarget,
  } as UseTranslationReturnType<Namespace<string>, undefined>;
};

export type TranslationType = (
  key: string,
  options?: { [key: string]: any; defaultValue?: string | undefined }
) => string;

export default useTranslation;
