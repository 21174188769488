import React from "react";
import cx from "classnames";

import { file as fileIcon } from "uiAssets/Icons";
import Acta from "utils/Acta";
import { UploadTypes } from "constants/booking";
import Icon from "components/Icon";
import { File } from "containers/Supervisor/routes/Projects/Components/SidePanel";

import { DocumentsModal } from "./components";

import styles from "./Files.module.scss";
import { DSButton } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

interface Props {
  className?: string;
  categories: { [title: string]: File[] } | {};
  refetch: Function;
  trainingSessionUuid: string;
}

export default ({ className, categories, refetch, trainingSessionUuid }: Props) => {
  const { t } = useTranslation();

  const openModal = () =>
    Acta.setState("modalDisplayed", {
      content: (
        <DocumentsModal
          categories={categories}
          refetch={refetch}
          trainingSessionUuid={trainingSessionUuid}
        />
      ),
      size: "large",
      noHeader: true,
    });

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        {/* <h2>Documents</h2> */}
        <h2>
          {t("trainings.sessions.sidepanel.section.documents.title", {
            defaultValue: "Documents",
          })}
        </h2>
        <DSButton
          name="show-documents"
          label={t("trainings.sessions.sidepanel.documents", {
            defaultValue: "Gérer les documents",
          })}
          buttonSize="S"
          emphasis="Mid"
          onClick={openModal}
        />
      </div>
      {Object.entries(categories).map(([title, files]) => (
        <div className={styles.categoryWrapper} key={title}>
          <h3>
            <Icon strokeIcon={fileIcon} width={16} />
            {t(`trainings.entity.session.document.type.${title}`, {
              defaultValue: UploadTypes[title],
            })}
          </h3>
          {files.map((file) => (
            <div key={file.uuid}>{file.name}</div>
          ))}
        </div>
      ))}
    </div>
  );
};
