import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import styles from "../TemplateBuilderStructure.module.scss";

export const ItemActions = ({
  showActionsButtons,
  onDuplication,
  onDeletion,
  deletionTooltipLabel,
  duplicationTooltipLabel,
}: {
  showActionsButtons: boolean;
  onDuplication: () => void;
  onDeletion: () => void;
  deletionTooltipLabel: string;
  duplicationTooltipLabel: string;
}) => {
  if (!showActionsButtons) return null;
  return (
    <DSDropdown
      buttonSize="S"
      className={styles["menu-icons"]}
      buttonClassName={styles["dropdown-button"]}
    >
      <DSDropdownItem label={duplicationTooltipLabel} onClick={onDuplication} />
      <DSDropdownItem label={deletionTooltipLabel} onClick={onDeletion} />
    </DSDropdown>
  );
};
