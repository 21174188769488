import { HTMLAttributes } from "react";

import { Status, StatusType } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
interface CampaignStateProps extends HTMLAttributes<HTMLDivElement> {
  isDark: boolean;
}

export function NotStartedStatus({ className, isDark }: CampaignStateProps) {
  const { t } = useTranslation();
  return (
    <Status
      darkMode={isDark}
      className={className}
      type={StatusType.DONE}
      label={t("peoplereview.campaign.state.notStarted", { defaultValue: "Non démarrée" })}
    />
  );
}
