import React, { useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

import { ListUtils } from "@skillup/shared-utils";
import { DateFilter } from "components/Filters/components/OperatorsFilters";

import { Flex } from "components/Flex";
import { Select } from "components/Select";
import { TextInput } from "components/Form/TextInput";

import { type FilterProps } from "../types";
import { SelectOperator } from "./SelectOperator/SelectOperator";
import { NumberFilter } from "./OperatorsFilters/NumberFilter";

import styles from "./Filter.module.scss";
import { StyledSelect } from "../commons.styled";

interface Props {
  readonly id: string;
  readonly config: ListUtils.FilterConfiguration;
  readonly filter?: FilterProps<any>;
  readonly value: ListUtils.FilterValue;
  readonly onChange: (filterId: string, value: ListUtils.FilterValue) => void;
  readonly translationPrefix?: string;
  readonly darkMode?: boolean;
  readonly t: (key: string, params?: { defaultValue: string } & any) => string;
}

const FilterComponent = ({
  id,
  config: { type },
  filter: {
    options,
    placeholder,
    lang,
    component: CustomComponent,
    operators = [ListUtils.FilterOperator.CONTAINS],
  } = {},
  value: filterValue,
  onChange,
  darkMode = false,
  t,
}: Props): JSX.Element | null => {
  const [stateValue, setStateValue] = useState<ListUtils.FilterValue["value"]>(filterValue?.value);
  const debouncedOnChange = useCallback(debounce(onChange, 300), []);
  const debouncedOnChangeDate = useCallback(debounce(onChange, 800), []);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
      debouncedOnChangeDate.cancel();
    };
  }, [debouncedOnChange, debouncedOnChangeDate]);

  // Necessary to update the value when the filter is reset
  useEffect(() => {
    setStateValue(filterValue?.value);
  }, [filterValue]);

  const handleOnFilterValueChange = (value: any) => {
    setStateValue(value);

    if (type === ListUtils.FilterType.TEXT || type === ListUtils.FilterType.NUMBER) {
      debouncedOnChange(id, {
        type,
        operator: filterValue?.operator ?? ListUtils.FilterOperator.CONTAINS,
        value,
      });

      return;
    }

    if (type === ListUtils.FilterType.MULTISELECT) {
      const valueArray = value.filter((v: any) => !!v);
      onChange(id, {
        type: ListUtils.FilterType.MULTISELECT,
        operator: filterValue?.operator || ListUtils.FilterOperator.CONTAINS,
        value: isEmpty(valueArray) ? null : valueArray,
      });

      return;
    }

    if (type === ListUtils.FilterType.SINGLESELECT) {
      onChange(id, {
        type: ListUtils.FilterType.SINGLESELECT,
        operator: filterValue?.operator || ListUtils.FilterOperator.CONTAINS,
        value,
      });

      return;
    }

    if (type === ListUtils.FilterType.DATE) {
      debouncedOnChangeDate(id, {
        type: ListUtils.FilterType.DATE,
        operator: filterValue?.operator || ListUtils.FilterOperator.CONTAINS,
        value: value,
      });

      return;
    }

    onChange(id, value);
  };

  const handleOperatorChange = (operator: ListUtils.FilterOperators) => {
    onChange(id, {
      type,
      operator,
      value: stateValue,
    });
  };

  const placeholderText = placeholder ?? t(`filter.placeholder.${id}`);
  if (CustomComponent) {
    return (
      <CustomComponent
        onChange={(filterValue: ListUtils.FilterValue) => onChange(id, filterValue)}
        filterValue={filterValue}
        darkMode={darkMode}
      />
    );
  }

  const renderOperator = (child: any) => (
    <Flex row>
      {operators.length > 1 ? (
        <>
          <SelectOperator
            operatorList={operators.map((operator) => ({
              value: operator,
              label: t(`filter.operator.${operator}`),
            }))}
            darkMode={darkMode}
            value={filterValue?.operator}
            onChange={handleOperatorChange}
          />
          <StyledSelect darkMode={darkMode}>{child}</StyledSelect>
        </>
      ) : (
        child
      )}
    </Flex>
  );

  switch (type) {
    case ListUtils.FilterType.TEXT:
      return renderOperator(
        <TextInput
          className={styles.Input}
          autoFocus
          name={id}
          value={stateValue?.toString() ?? ""}
          placeholder={placeholderText}
          onChange={handleOnFilterValueChange}
        />
      );
    case ListUtils.FilterType.NUMBER:
      return renderOperator(
        <NumberFilter
          darkMode={darkMode}
          className={styles.Input}
          onChange={handleOnFilterValueChange}
          value={filterValue as any}
        />
      );
    case ListUtils.FilterType.MULTISELECT:
    case ListUtils.FilterType.SINGLESELECT:
      return renderOperator(
        <Select
          className={styles.Select}
          multi={type === ListUtils.FilterType.MULTISELECT}
          isSearchable
          canSelectAll
          autoFocus
          clearable={false}
          maxShownValues={1}
          darkMode={type === ListUtils.FilterType.MULTISELECT && darkMode}
          value={(stateValue as any[]) ?? []}
          selectPlaceholder={placeholderText}
          onChange={handleOnFilterValueChange}
          options={options ?? []}
          extraValuesLabel={(count) =>
            t("filter.multiselect.extra_value", {
              defaultValue: "+{{count}} autres",
              count,
            })
          }
          allValuesLabel={t(`select.all-values.${id}`, {
            defaultValue: "Tous les éléments",
          })}
        />
      );
    case ListUtils.FilterType.DATE:
      return renderOperator(
        <DateFilter
          lang={lang}
          darkMode={darkMode}
          onChange={handleOnFilterValueChange}
          value={filterValue as any}
        />
      );
  }
};

export { FilterComponent };
