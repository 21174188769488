import { MdHelpOutline as HelpOutline, MdLanguage as Language } from "react-icons/md";
import React, { ReactElement, useCallback, useMemo, MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { AuthRoutes } from "services";

import {
  NavigationHeader,
  DSAvatar,
  DSDropdown,
  DSDropdownItem,
  DSDropdownDivider,
  NavigationTabs,
  NavigationTab,
} from "@skillup/ui";

import authActions from "actions/auth";
import {
  getUserData,
  getUserInitials,
  isManager,
  isInterviewManager,
  isImpostor,
  isSkillupDeveloper,
  isSkillupAdmin,
} from "utils/User";

import styles from "./DSNewHeaderStyles.module.scss";
import { useIsPentestCompany } from "hooks/useCheckCompanyScope";
import useTranslation from "hooks/useTranslation";
import { LanguageSelector } from "./LanguageSelector";
import { buildRequest } from "utils/buildRequest";
import { TargetType } from "utils/DataLayer";

interface IParent {
  readonly title: string;
  readonly titleUrl: string;
  readonly onClick?: () => void;
}

export interface Layout {
  readonly tab?: NavigationTab;
  readonly customHeaderItems?: JSX.Element;
  readonly primaryButton?: JSX.Element;
  readonly dropdownContent?: Array<JSX.Element>;
}

const { REACT_APP_HELP_CENTER_URL } = process.env;
const helpCenterUrl = `${REACT_APP_HELP_CENTER_URL}`;

type Defined<T> = T extends undefined ? never : T;

type ExtractActiveLayoutId<T extends Array<Layout>> = Defined<T[number]["tab"]>["id"];

export interface DSNewHeaderProps<T extends Layout> {
  readonly title: string | JSX.Element;
  readonly subtitle?: JSX.Element;
  readonly parent?: IParent;
  readonly leftItem?: JSX.Element;
  readonly className?: string;
  readonly layouts?: ReadonlyArray<T>;
  readonly activeLayout?: ExtractActiveLayoutId<Array<T>>;
  readonly onChangeLayout?: (tab: string) => void;
  readonly header?: JSX.Element;
  readonly onClickMenu?: (e?: MouseEvent) => void;
  readonly showMenu?: boolean;
  readonly isHiddenOnMobile?: boolean;
}

function DSNewHeader<T extends Layout>({
  title,
  subtitle,
  parent,
  leftItem,
  className,
  header,
  layouts = new Array<T>(),
  activeLayout,
  onChangeLayout,
  onClickMenu,
  showMenu,
  isHiddenOnMobile,
}: DSNewHeaderProps<T>) {
  const userIsManager = isManager() || isInterviewManager();
  const userData = getUserData();
  const history = useHistory();
  const isPentestCompany = useIsPentestCompany();
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { t, activeKeys, translationAPITarget } = useTranslation();
  const isPasswordUser = userData?.authStrategy === "password";

  const initials = getUserInitials(getUserData());

  const changeView = (status: "collab" | "manager") => {
    switch (status) {
      case "collab":
        location.href = AuthRoutes.computeCompanyUserRoute();
        break;
      case "manager":
        location.href = AuthRoutes.computeManagerRoute();
        break;
    }
  };

  const signout = useCallback(async () => {
    const organization = userData?.activeCompany?.reference;
    await authActions.signout();

    const withQueryArgs = stringify(
      {
        organization,
        reason: "user-logout",
      },
      { addQueryPrefix: true }
    );
    window.location.href = `${process.env.REACT_APP_PUBLIC_APP_URL}connexion/${withQueryArgs}`;
  }, [userData]);

  const MenuCustomItems = useMemo(() => {
    return !activeLayout
      ? layouts[0]?.customHeaderItems
      : layouts.find((e) => e.tab?.id === activeLayout)?.customHeaderItems;
  }, [activeLayout, layouts]);

  const MenuButton = useMemo(() => {
    return !activeLayout
      ? layouts[0]?.primaryButton
      : layouts.find((e) => e.tab?.id === activeLayout)?.primaryButton;
  }, [activeLayout, layouts]);

  const MenuDropdown = useMemo(() => {
    const selectedLayout = layouts.find((e) => e.tab?.id === activeLayout);

    return selectedLayout?.dropdownContent && selectedLayout?.dropdownContent?.length >= 1 ? (
      <DSDropdown className={styles.dropdownContainer} darkMode>
        {selectedLayout.dropdownContent}
      </DSDropdown>
    ) : undefined;
  }, [activeLayout, layouts]);

  const UserMenuDropdown = useMemo(() => {
    const Avatar: ReactElement = (
      <DSAvatar
        type={"Initials"}
        imageUrl={undefined}
        initials={initials}
        darkMode
        randomColorSeedString={userData?.uuid}
      />
    );

    return (
      <DSDropdown button={Avatar}>
        <DSDropdownItem
          label={t("header.avatar.action.go_to.hr_view", {
            defaultValue: "Espace responsable",
          })}
          selectable
          selected
        />
        {userIsManager && (
          <DSDropdownItem
            label={t("header.avatar.action.go_to.manager_view", {
              defaultValue: "Espace manager",
            })}
            selectable
            onClick={() => changeView("manager")}
          />
        )}
        <DSDropdownItem
          label={t("header.avatar.action.go_to.collaborator_view", {
            defaultValue: "Espace collaborateur",
          })}
          selectable
          onClick={() => changeView("collab")}
        />

        {isPentestCompany && isPasswordUser && (
          <>
            <DSDropdownDivider />
            <DSDropdownItem
              label={t("header.avatar.action.change_password", {
                defaultValue: "Changer de mot de passe",
              })}
              onClick={() =>
                (window.location.href = `/connexion/modification-mot-de-passe/${btoa(
                  userData.email
                )}`)
              }
            />
          </>
        )}

        <DSDropdownDivider />

        <DSDropdownItem
          label={t("common.view.header.action.select_language", {
            defaultValue: "Changer la langue",
          })}
          onClick={() => setLanguageModalOpen(true)}
          icon={<Language />}
        />

        <DSDropdownDivider />

        <DSDropdownItem
          label={t("header.avatar.action.help_center", {
            defaultValue: "Centre d'aide",
          })}
          onClick={() => {
            window.open(helpCenterUrl, "_blank", "noreferrer");
          }}
          icon={<HelpOutline />}
        />
        <DSDropdownDivider />

        {(isSkillupDeveloper() || isImpostor() || isSkillupAdmin()) && (
          <DSDropdownItem
            label={t("header.avatar.action.change_user", {
              defaultValue: "Changer d'utilisateur",
            })}
            onClick={() => {
              const withQueryArgs = stringify(
                {
                  redirect: window.location.href,
                },
                { addQueryPrefix: true }
              );

              history.push(`/connexion-en-tant-que${withQueryArgs}`);
            }}
          />
        )}
        {isSkillupAdmin() && (
          <DSDropdownItem
            label={t("header.avatar.action.change_company", {
              defaultValue: "Changer d'entreprise",
            })}
            onClick={() => history.push("/responsable/changer-d-entreprise")}
          />
        )}
        {(isSkillupAdmin() || isSkillupDeveloper()) && (
          <DSDropdownItem
            label={t("header.avatar.action.translate_page", {
              defaultValue: "Traduire cette page",
            })}
            onClick={async () => {
              await generateLocalesLink(
                Array.from(activeKeys.entries()).map(([key, { defaultValue, countable }]) => ({
                  key,
                  defaultValue,
                  countable,
                })),
                translationAPITarget
              );
            }}
          />
        )}
        {(isSkillupDeveloper() || isImpostor() || isSkillupAdmin()) && <DSDropdownDivider />}
        <DSDropdownItem
          label={t("header.avatar.action.logout", {
            defaultValue: "Déconnexion",
          })}
          onClick={signout}
        />
      </DSDropdown>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    languageModalOpen,
    initials,
    userIsManager,
    signout,
    history,
    isPentestCompany,
    isPasswordUser,
    userData.email,
    userData.uuid,
    activeKeys,
    translationAPITarget,
  ]);

  const tabMenu = useMemo(() => {
    return layouts.map((e) => e.tab).filter((e): e is NavigationTab => e !== undefined);
  }, [layouts]);

  return (
    <React.Fragment>
      <NavigationHeader
        className={className}
        title={title}
        subtitle={subtitle}
        showMenu={showMenu}
        onClickMenu={onClickMenu}
        parent={parent}
        leftItem={leftItem}
        isHiddenOnMobile={isHiddenOnMobile}
      >
        {MenuCustomItems}
        {MenuButton}
        {MenuDropdown}
        {UserMenuDropdown}
      </NavigationHeader>
      {header}
      {layouts && activeLayout && (
        <NavigationTabs
          spacingLeft={showMenu}
          tabs={tabMenu}
          activeTab={activeLayout}
          className={className}
          onChangeTab={onChangeLayout}
        />
      )}
      <LanguageSelector isOpen={languageModalOpen} onClose={() => setLanguageModalOpen(false)} />
    </React.Fragment>
  );
}

export default DSNewHeader;

async function generateLocalesLink(
  keys: { key: string; defaultValue: string; countable: boolean }[],
  target: TargetType
) {
  const { link } = await buildRequest<{
    payload: { keys: Array<{ key: string; defaultValue: string; countable: boolean }> };
    method: "POST";
    path: "/keys/custom-link";
    response: { link: string };
  }>({
    target,
    path: "/keys/custom-link",
    method: "POST",
    payload: { keys },
  })();

  window.open(link);
}
