import { useMemo } from "react";
import { parse, format } from "date-fns";

import { Column, ColumnType, DSTooltip } from "@skillup/ui";
import { InterviewResults } from "../types";

import { sortBy, uniqBy } from "lodash";
import { interviewStatusKeyToValue } from "utils/InterviewStatusKeyToValue";
import { TranslationType } from "hooks/useTranslation";
import styles from "../UserInterview.module.scss";
import { getInterviewTypeLabel } from "utils/interviews";
import { InterviewStatus } from "services/interviews/components";

type Props = {
  interviews: InterviewResults;
  t: TranslationType;
};

function useTableData({ interviews = [], t }: Props): {
  data: InterviewRow[];
  columns: Column<InterviewRow>[];
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns(interviews, t),
      data: generateTableRows(interviews, t),
    }),
    [interviews, t]
  );

  return tableData;
}

export default useTableData;

const generateColumns = (interviews: InterviewResults, t: TranslationType) => {
  const columns: Array<Column<InterviewRow>> = [
    {
      key: "title",
      title: t("supervisor.view.collaborators.interviews.column.title", {
        defaultValue: "Intitulé et type d’entretien",
      }),
      filterable: false,
      minWidth: 20,
      sticky: true,
      renderCell: (interview) => (
        <div className={styles.cell}>
          <div className={styles.title}>
            <DSTooltip
              label={interview.title}
              enabledOnOverflow
              tooltipClassName={styles.tooltip}
              withPortal
            >
              {interview.title}
            </DSTooltip>
          </div>
          <div className={styles.subtitle}>
            {getInterviewTypeLabel(interview.type, t)}
            {interview.source === "imported"
              ? t("supervisor.view.collaborators.interviews.subTitle.imported", {
                  defaultValue: " (Entretien importé)",
                })
              : ""}
          </div>
        </div>
      ),
    },
    {
      key: "startDate",
      title: t("supervisor.view.collaborators.interviews.column.startDate", {
        defaultValue: "Période de l’entretien",
      }),
      filterable: false,
      sortable: true,
      renderCell: (interview) =>
        interview.startDate ? `${interview.startDate} - ${interview.endDate}` : interview.endDate,
      sortFn: sortingRow,
    },
    {
      key: "managerFullName",
      title: t("supervisor.view.collaborators.interviews.column.managerFullName", {
        defaultValue: "Responsable de l'entretien",
      }),
    },
    {
      key: "employeeState",
      title: t("supervisor.view.collaborators.interviews.column.employeeState", {
        defaultValue: "Statut collaborateur",
      }),
      type: ColumnType.SEARCH_SELECT,
      options: uniqBy(interviews, (i) => interviewStatusKeyToValue(t, i.employee?.state).shortValue)
        .map((e) => {
          return {
            value: e.employee?.state,
            label: interviewStatusKeyToValue(t, e.employee?.state).shortValue,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
      renderCell: ({ employeeState, source }) => {
        return source !== "imported" ? (
          <InterviewStatus userInterviewStatus={employeeState} />
        ) : null;
      },
    },
    {
      key: "managerState",
      title: t("supervisor.view.collaborators.interviews.column.managerState", {
        defaultValue: "Statut responsable",
      }),
      type: ColumnType.SEARCH_SELECT,
      options: uniqBy(interviews, (i) => interviewStatusKeyToValue(t, i.manager.state).shortValue)
        .map((e) => {
          return {
            value: e.employee?.state,
            label: interviewStatusKeyToValue(t, e.employee?.state).shortValue,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
      renderCell: ({ managerState, source }) => {
        return source !== "imported" ? (
          <InterviewStatus userInterviewStatus={managerState} />
        ) : null;
      },
    },
  ];

  return columns;
};

function generateTableRows(interviews: InterviewResults, t: TranslationType): Array<InterviewRow> {
  const rows = interviews?.map((interview) => parseInterviewIntoRow(interview, t));
  return sortBy(rows, sortingRow);
}

function parseInterviewIntoRow(interview: InterviewResults[0], t: TranslationType) {
  return {
    id: interview.uuid,
    data: {
      ...interview,
      managerFullName: interview.manager.fullName,
      managerState: interview.manager?.state,
      employeeState: interview.employee?.state,
      startDate: interview.startDate
        ? format(new Date(interview.startDate), "dd/MM/yyyy").toLocaleString()
        : "",
      endDate: interview.endDate
        ? format(new Date(interview.endDate), "dd/MM/yyyy").toLocaleString()
        : "",
    },
  };
}

export type InterviewRow = ReturnType<typeof parseInterviewIntoRow>;

const sortingRow = (row) => {
  if (row.data.endDate) return -parse(row.data.endDate, "dd/MM/yyyyy", new Date()).getTime();
  else if (row.data.startDate)
    return -parse(row.data.startDate, "dd/MM/yyyyy", new Date()).getTime();
  else return 0;
};
