import { useState, useMemo } from "react";
import { useToggle, useMount, useUnmount } from "react-use";
import { Link, useHistory, useLocation } from "react-router-dom";
import cx from "classnames";

import useTranslation from "hooks/useTranslation";
import { check, cross } from "uiAssets/Icons";
import Acta from "utils/Acta";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";
import Icon from "components/Icon";
import PlaceFilter from "./PlaceFilter";
import getPossibleFilters from "./getPossibleFilters";
import { IUser } from "@skillup/types";

import styles from "./FiltersStyles.module.scss";

interface Props {
  filters: any;
  aggregations: object;
  availableFilters: object;
}
const Filters = (props: Props) => {
  const { filters = {}, aggregations = {}, availableFilters } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [seeMoreDates, toggleMoreDates] = useToggle(false);
  const [seeMoreEntities, toggleMoreEntities] = useToggle(false);
  const [seeMoreOrganization, toggleMoreOrganization] = useToggle(false);
  const [showFilters, toggleFilters] = useToggle(false);
  const [userData, setUserData] = useState<IUser>();

  const possibleFilters = getPossibleFilters(t);

  useMount(() => {
    Acta.subscribeStateWithoutContext(
      "userData",
      (userData: IUser) => setUserData(userData),
      "Filters"
    );
  });

  useUnmount(() => {
    Acta.unsubscribeStateWithoutContext("userData", "Filters");
  });

  const currentFilters = useMemo(() => {
    let currentFilters = [];

    for (const filterkey of Object.keys(filters)) {
      const filterOption = possibleFilters[filterkey];
      if (filterOption) {
        currentFilters = [
          ...currentFilters,
          <h3 key={`removeFilterLabel-${filterOption.label()}`}>{filterOption.label()}</h3>,
          <div
            key={`removeFilterLabel-${filterOption.label()}-filters`}
            className={styles.selectedFiltersContainer}
          >
            {possibleFilters[filterkey]
              .buildRemovalLinks(pathname, filters, filters[filterkey])
              .map((link) => (
                <InteractiveButton
                  key={`removeFilterLabel-${filterOption.label()}-${link.url}`}
                  iconRight={cross}
                  onClick={() => history.push(link.url)}
                  label={link.label}
                  size="small"
                />
              ))}
          </div>,
        ];
      }
    }

    return currentFilters;
  }, [history, filters, pathname, possibleFilters]);

  const usableFilters = useMemo(() => {
    let usableFilters = [];

    for (const availableFilterKey of Object.keys(possibleFilters)) {
      if (
        availableFilters[availableFilterKey] &&
        possibleFilters[availableFilterKey] &&
        possibleFilters[availableFilterKey].buildAdditionLinks &&
        (!possibleFilters[availableFilterKey].optional || aggregations[availableFilterKey])
      ) {
        const moreDates = availableFilterKey === "sessionMonth" && !seeMoreDates;
        const moreOrganizations = availableFilterKey === "organization" && !seeMoreOrganization;
        const moreEntities = availableFilterKey === "entities" && !seeMoreEntities;

        let slice = 1000;
        if (moreDates) slice = 3;
        if (moreOrganizations) slice = 5;
        if (moreEntities) slice = 5;

        usableFilters = [
          ...usableFilters,
          <h3 key={`addFilter-${possibleFilters[availableFilterKey].label()}`}>
            {possibleFilters[availableFilterKey].label()}
          </h3>,
          ...possibleFilters[availableFilterKey]
            .buildAdditionLinks(pathname, filters, aggregations, userData)
            .slice(0, slice)
            .map((link) => (
              <Link
                key={`addFilter-${possibleFilters[availableFilterKey].label()}-${link.label}`}
                to={link.url}
                className={styles.addFilterLink}
              >
                <div>
                  <div>
                    {link.isSelected && <Icon icon={check} fill={Colors.blue} width={10} />}
                  </div>
                  {link.label}
                </div>
                <span>{link.count}</span>
              </Link>
            )),
          moreDates && (
            <button key="seeMoreDates" onClick={toggleMoreDates}>
              {"...toutes les dates +"}
            </button>
          ),
          moreOrganizations && (
            <button key="seeMoreOrganization" onClick={toggleMoreOrganization}>
              {"...tous les organismes +"}
            </button>
          ),
          moreEntities && (
            <button key="seeMoreEntities" onClick={toggleMoreEntities}>
              ...toutes les entités +
            </button>
          ),
        ];
      }
    }

    return usableFilters;
  }, [
    pathname,
    userData,
    aggregations,
    filters,
    availableFilters,
    seeMoreDates,
    seeMoreEntities,
    seeMoreOrganization,
    toggleMoreDates,
    toggleMoreEntities,
    toggleMoreOrganization,
    possibleFilters,
  ]);

  return (
    <aside className={styles.Filters}>
      <div className={styles.showHideFilters}>
        <button onClick={toggleFilters}>
          {showFilters ? "Masquer les filtres" : "Afficher les filtres"}
        </button>
      </div>
      <div className={cx(styles.filters, { [styles.show]: showFilters })}>
        {currentFilters.length > 0 && (
          <div className={styles.currentFiltersContainer}>
            <h2>Filtres actifs</h2>
            {currentFilters}
          </div>
        )}
        {
          // @ts-ignore
          availableFilters.place && <PlaceFilter {...props} />
        }
        {usableFilters}
      </div>
      {showFilters && (
        <div className={styles.showHideFilters}>
          <button onClick={toggleFilters}>Masquer les filtres</button>
        </div>
      )}
    </aside>
  );
};

export default Filters;
