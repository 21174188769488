import { Dispatch, useMemo } from "react";

import Acta from "utils/Acta";

import type { Tracking } from "../../state/tracking";
import RenewTracking from "../../components/RenewTracking/RenewTracking";
import CreateTracking from "../../components/CreateTracking/CreateTracking";
import CreateOrManageTrackingNote from "../../components/CreateOrManageTrackingNote/CreateOrManageTrackingNote";
import CreateOrManageTrackingDocument from "../../components/CreateOrManageTrackingDocument/CreateOrManageTrackingDocument";

import UpdateStateModal from "../modals/UpdateStateModal";
import UpdateValidityModal from "../modals/UpdateValidityModal";
import ArchiveTrackingModal from "../modals/ArchiveTrackingModal";
import UnarchiveTrackingModal from "../modals/UnarchiveTrackingModal";

import { State, ModalStates } from "./useTrackingActions";

function useTrackingsModal(
  currentAction: State,
  setCurrentAction: Dispatch<State>,
  data: Array<Tracking>,
  refetchTrackings: () => void
) {
  const currentModal = useMemo(() => {
    const userUuid = Acta.getState("userData").uuid;
    switch (currentAction.state) {
      case ModalStates.Creating:
        return <CreateTracking onClose={() => setCurrentAction({ state: ModalStates.Idle })} />;
      case ModalStates.CreatingNote:
      case ModalStates.ManagingNote: {
        const tracking = data.find((t) => t.uuid === currentAction.trackingUuid);

        return (
          <CreateOrManageTrackingNote
            trackingUuid={currentAction.trackingUuid}
            note={tracking.note?.noteText}
            userUuid={userUuid}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      }
      case ModalStates.CreatingDoc:
      case ModalStates.ManagingDocs: {
        const tracking = data.find((t) => t.uuid === currentAction.trackingUuid);

        return (
          <CreateOrManageTrackingDocument
            trackingUuid={currentAction.trackingUuid}
            documents={tracking.documents}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
            refetchTrackings={refetchTrackings}
          />
        );
      }
      case ModalStates.Renewing:
        return (
          <RenewTracking
            trackingUuid={currentAction.trackingUuid}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.UpdateValidity: {
        const tracking = data.find((t) => t.uuid === currentAction.trackingUuid);

        return (
          <UpdateValidityModal
            tracking={tracking}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      }
      case ModalStates.UpdateState: {
        const tracking = data.find((t) => t.uuid === currentAction.trackingUuid);

        return (
          <UpdateStateModal
            tracking={tracking}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      }
      case ModalStates.Archiving:
        return (
          <ArchiveTrackingModal
            trackingUuid={currentAction.trackingUuid}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.Unarchive: {
        const tracking = data.find((t) => t.uuid === currentAction.trackingUuid);

        return (
          <UnarchiveTrackingModal
            tracking={tracking}
            onClose={() => setCurrentAction({ state: ModalStates.Idle })}
          />
        );
      }
      case ModalStates.Idle:
      default:
        return <></>;
    }
  }, [currentAction, setCurrentAction, refetchTrackings, data]);

  return currentModal;
}

export default useTrackingsModal;
