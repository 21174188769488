import React from "react";

import {
  DatePicker as MUIDatePicker,
  type DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider, type PickerValidDate } from "@mui/x-date-pickers-pro";
import { frFR as frFRText } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { type Locales } from "@skillup/shared-utils";
import esES from "date-fns/locale/es";
import frFR from "date-fns/locale/fr";
import itIT from "date-fns/locale/it";
import enBG from "date-fns/locale/en-GB";

import { StyledPopper } from "./Popper";
import { useThemes } from "hooks/useThemes";

export type DatePickerProps = Omit<
  MUIDatePickerProps<PickerValidDate>,
  "theme" | "disableHighlightToday" | "slots" | "onChange"
> & {
  lang: Locales;
  onChange?: (date: any) => void;
  slotProps?: any;
};

const DatePicker = (props: DatePickerProps) => {
  const theme = useThemes();
  const { lang, onChange, ...rest } = props;

  const handleDateChange = (date: PickerValidDate | null) => {
    if (onChange) {
      const localDate = date ? new Date(date.setUTCHours(0, 0, 0, 0)) : null;
      onChange(localDate);
    }
  };

  const dateLocales: { [language in Locales]: Locale } = {
    cimode: enBG,
    de: enBG,
    en: enBG,
    es: esES,
    fr: {
      ...frFR,
      options: {
        ...frFR.options,
        weekStartsOn: 1, // Sunday = 0, Monday = 1.
      },
    },
    it: itIT,
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={frFRText.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale={dateLocales[lang]}
    >
      <MUIDatePicker
        showDaysOutsideCurrentMonth
        {...rest}
        onChange={handleDateChange}
        disableHighlightToday
        slots={{
          popper: StyledPopper,
        }}
        slotProps={{
          ...props.slotProps,
          popper: {
            ...props.slotProps?.popper,
            theme: theme,
          } as any,
          textField: {
            ...props.slotProps?.textField,
          } as any,
        }}
      />
    </LocalizationProvider>
  );
};

export { DatePicker };
