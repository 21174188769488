import { Either } from "@skillup/monads";
import { buildRequest } from "utils/buildRequest";
import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

export type PartialPostponeParams = {
  rowsUuid: string[];
  message?: string;
  deniedOrCancelledReason?: string;
  cancelledMessageForTrainingOrganization?: string;
};
export async function partialPostpone({
  rowsUuid,
  message,
  cancelledMessageForTrainingOrganization,
  deniedOrCancelledReason,
}: PartialPostponeParams) {
  try {
    await buildRequest<ScheduleRowRoutes.PostponeRow>({
      method: "POST",
      path: "/scheduleRow/postpone",
      payload: {
        rows: rowsUuid,
        message,
        cancelledMessageForTrainingOrganization,
        deniedOrCancelledReason,
      },
    })();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(undefined);
  }
}
