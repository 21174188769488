import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
} from "react";

import { useTranslation } from "context";
import { MdSearch as Search } from "react-icons/md";

import { Popper } from "@mui/material";
import Tune from "@mui/icons-material/Tune";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { DSButton, DSTooltip, DSTextInput, HorizontalDivider } from "@skillup/ui";

import { Text, Flex, Title } from "../../styled";

import styles from "./ColumnsVisibilityModal.module.scss";

type ColumnsVisibilityPopperProps = ColumnsVisibilityPopperContentProps;

export function ColumnsVisibilityPopper({
  columnsConfig,
  columnVisibilityModel,
  setColumnVisibilityModel,
}: ColumnsVisibilityPopperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        anchorEl &&
        !anchorEl.contains(event.target as Node) &&
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    }

    if (anchorEl) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }

    return;
  }, [anchorEl]);

  const handlePopperClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  return (
    <>
      <DSTooltip label="Gérer les colonnes du tableau">
        <DSButton iconOnly icon={<Tune />} onClick={handlePopperClick} />
      </DSTooltip>
      <div ref={popperRef}>
        <ColumnsVisibilityPopperWrapper anchor={anchorEl} isOpen={Boolean(anchorEl)}>
          <ColumnsVisibilityPopperContent
            columnsConfig={columnsConfig}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        </ColumnsVisibilityPopperWrapper>
      </div>
    </>
  );
}

type ColumnsVisibilityPopperWrapperProps = PropsWithChildren<{
  isOpen: boolean;
  anchor: null | HTMLElement;
}>;

export function ColumnsVisibilityPopperWrapper({
  anchor,
  children,
  isOpen,
}: ColumnsVisibilityPopperWrapperProps) {
  return (
    <Popper
      open={isOpen}
      disablePortal
      anchorEl={anchor}
      style={{ zIndex: 999 }}
      popperOptions={{
        placement: "bottom-end",
      }}
      modifiers={[
        {
          enabled: true,
          name: "preventOverflow",
          options: {
            altAxis: true,
            altBoundary: true,
            padding: 8,
            rootBoundary: "viewport",
            tether: true,
          },
        },
      ]}
    >
      {children}
    </Popper>
  );
}

type ColumnsVisibilityPopperContentProps = {
  columnVisibilityModel: { [key: string]: boolean };
  columnsConfig: Array<{
    key: string;
    headerName: string;
  }>;
  setColumnVisibilityModel: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
};

export function ColumnsVisibilityPopperContent({
  columnsConfig,
  columnVisibilityModel,
  setColumnVisibilityModel,
}: ColumnsVisibilityPopperContentProps) {
  const [query, setQuery] = useState<string>();

  const filteredColumns = useMemo(() => {
    if (!query || query === "") return columnsConfig;
    return columnsConfig.filter((column) =>
      column.headerName.toLowerCase().includes(query?.toLowerCase() ?? "")
    );
  }, [query, columnsConfig]);

  const t = useTranslation();

  const visibleColumns = filteredColumns.filter((column) => columnVisibilityModel[column.key]);
  const invisibleColumns = filteredColumns.filter((column) => !columnVisibilityModel[column.key]);

  return (
    <Flex
      alignItems="start"
      paddingVertical="xs"
      flexDirection="column"
      paddingHorizontal="xs"
      className={styles.columnsVisibilityModal}
    >
      <Title h5>
        {t("design-system.columns.columnManagement", {
          defaultValue: "Gestion des colonnes",
        })}
      </Title>
      <DSTextInput
        value={query}
        iconRight={<Search />}
        name="property-search"
        style={{ marginTop: "1rem" }}
        onChange={setQuery}
        aria-label={t("design-system.columns.findProperty", {
          defaultValue: "Chercher une propriété",
        })}
        placeholder={t("design-system.columns.findProperty", {
          defaultValue: "Chercher une propriété",
        })}
      />

      <Flex flexDirection="column" className={styles.content}>
        <Text paddingVertical="s" color="plainText-onLight-lighter">
          {t("design-system.columns.displayedColumns", {
            defaultValue: "Colonnes affichées",
          })}
        </Text>
        <Flex flexDirection="column" className={styles.columnsContainer}>
          {visibleColumns.map((column) => (
            <Flex
              key={column.key}
              flexWrap="nowrap"
              alignItems="center"
              paddingHorizontal="xs"
              className={styles.columnRow}
              justifyContent="space-between"
            >
              <Text
                hasEllipsis
                whiteSpace="nowrap"
                espaceFont="captionRegular"
                color="plainText-onLight-default"
              >
                {column.headerName}
              </Text>
              <Visibility
                color="action"
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    [column.key]: false,
                  });
                }}
              />
            </Flex>
          ))}
        </Flex>

        <HorizontalDivider top="xs" bottom="xs" />

        <Text paddingBottom="s" color="plainText-onLight-lighter">
          {t("design-system.columns.hiddenColumns", {
            defaultValue: "Colonnes masquées",
          })}
        </Text>
        <Flex flexDirection="column" className={styles.columnsContainer}>
          {invisibleColumns.map((column) => (
            <Flex
              flexGrow={1}
              key={column.key}
              alignItems="center"
              paddingHorizontal="xs"
              className={styles.columnRow}
              justifyContent="space-between"
            >
              <Text espaceFont="captionRegular" color="plainText-onLight-default">
                {column.headerName}
              </Text>
              <VisibilityOff
                fontSize="small"
                color="disabled"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    [column.key]: true,
                  });
                }}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
