import { useState } from "react";

import { Flex } from "@skillup/design-system";
import { UserRoutes } from "@skillup/espace-rh-bridge";
import { Locales } from "@skillup/shared-utils";
import { DSButton, DSFormGroupTextInput, DSModal, Select } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { buildRequest } from "utils/buildRequest";
import { getUserData, isSkillupDeveloper } from "utils/User";

import styles from "./LanguageSelector.module.scss";
import { useToasts } from "react-toast-notifications";

const LanguageLabels = {
  fr: "Français",
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  es: "Español",
  cimode: "Trad labels",
};

export const LanguageSelector = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();
  const user = getUserData();
  const [updating, setUpdating] = useState(false);
  const [language, setLanguage] = useState<Locales>(user.currentLanguage ?? user.defaultLanguage);
  const { translationAPITarget, toggleTranslationAPITarget } = useTranslation();

  const languageOptions = Locales.reduce((acc, locale) => {
    if (locale === "cimode" && !isSkillupDeveloper()) {
      return acc;
    }

    acc.push({
      value: locale,
      label: t(`common.view.language.${locale}`, { defaultValue: LanguageLabels[locale] }),
    });

    return acc;
  }, []);

  const handleSubmitClick = async () => {
    setUpdating(true);

    try {
      await buildRequest<UserRoutes.PatchUserLanguageRouteData>({
        target: "API",
        method: "POST",
        path: `/user/language`,
        payload: { currentLanguage: language },
      })();

      i18n.changeLanguage(language);

      // Sync language in local storage
      const userData = JSON.parse(localStorage.getItem("_skillup_userData") ?? "");
      if (userData) {
        localStorage.setItem(
          "_skillup_userData",
          JSON.stringify({ ...userData, currentLanguage: language })
        );
      }

      window.location.reload();
    } catch (error) {
      addToast(t("common.error.generic", { defaultValue: "Une erreur est survenue" }), {
        appearance: "error",
      });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <DSModal isOpen={isOpen} className={styles.languageModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("common.view.set_language.title", { defaultValue: "Changer la langue" })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex flexDirection="column" className={styles.content}>
          <DSFormGroupTextInput
            label={t("common.view.set_language.description", {
              defaultValue: "Choisir la langue utilisée dans l'application",
            })}
          >
            <Select
              value={language}
              options={languageOptions}
              onChange={(language) => setLanguage(language)}
            />
          </DSFormGroupTextInput>

          {isSkillupDeveloper() ? (
            <DSButton
              emphasis="Low"
              buttonSize="S"
              onClick={toggleTranslationAPITarget}
              label={`Source des trads: ${
                translationAPITarget === "CURRENT_ENVIRONMENT_LOCALES_SERVICE"
                  ? "environnement"
                  : "production"
              }`}
            />
          ) : undefined}
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.action.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          label={t("common.action.update", { defaultValue: "Modifier" })}
          onClick={handleSubmitClick}
          disabled={updating}
          loading={updating}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
