import { useState, useEffect } from "react";

import { useModal2 } from "@skillup/ui";

import { useFilteredTrackings, Tracking as TrackingType } from "../../state/tracking";

const useSidepanel = () => {
  const { hide, show, isOpen } = useModal2();
  const { data, dataUpdatedAt } = useFilteredTrackings();
  const [tracking, setTracking] = useState<TrackingType>();

  useEffect(() => {
    if (data) {
      const newTracking = data.trackings.find((t) => t.uuid === tracking?.uuid);
      if (!newTracking && isOpen) hide();
      setTracking(newTracking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdatedAt, data, tracking, setTracking]);

  function openSidepanelForTracking(newSelection: TrackingType) {
    setTracking(newSelection);
    show();
  }

  function hideSidepanel() {
    hide();
    setTracking(undefined);
  }

  return {
    state: { tracking },
    toggle: ({ newSelection }: { newSelection: TrackingType }) => {
      if (newSelection == null) {
        return hideSidepanel();
      }

      if (newSelection.uuid === tracking?.uuid) {
        hideSidepanel();
      } else {
        openSidepanelForTracking(newSelection);
      }
    },
    isOpen,
  };
};

export default useSidepanel;
