import React, { useState } from "react";

import Acta from "utils/Acta";
import useTranslation from "hooks/useTranslation";
import InteractiveButton from "components/InteractiveButton";

export default ({ rows, isBypass, onMoveToPlan }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await onMoveToPlan(rows);
      Acta.dispatchEvent("closeModal");
    } catch {
      setLoading(false);
    }
  };

  return (
    <div style={{ lineHeight: "1.6em", padding: "20px" }}>
      <p style={{ marginBottom: "1rem" }}>
        {isBypass &&
          t("trainings.view.pending_schedule_row.property.validate_modal.warning", {
            defaultValue:
              "Attention, ces demandes n’ont pas encore été validées par le(s) manager(s).",
            count: rows.length,
          })}

        {t("trainings.view.pending_schedule_row.property.validate_modal.content", {
          defaultValue:
            "Êtes vous sûr(e) de vouloir valider ces {{ count }} demandes et de les passer au plan (les collaborateurs ne seront pas notifiés de ces actions) ?",
          count: rows.length,
        })}
      </p>
      <InteractiveButton
        label={t("trainings.view.pending_schedule_row.property.validate_modal.action.validate", {
          defaultValue: "Valider",
        })}
        loading={loading}
        onClick={onSubmit}
      />
    </div>
  );
};
