import React, { useEffect, useMemo, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { useQuery } from "@tanstack/react-query";
import faker from "faker";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";

import { trpc } from "utils/trpc";

import { Flex, Text } from "@skillup/design-system";
import { PortalRoutes } from "@skillup/espace-rh-bridge";
import { DSButton, Loader, DSSortable } from "@skillup/ui";

import { JobLayout } from "./JobTemplateFields.styled";
import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "containers/Supervisor/routes/PortalManagement/components/AdminPortal/AdminPortalToggle/AdminPortalToggle";
import { buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";

function JobTemplateFields(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    activeCompany: { uuid: companyUuid },
  } = Acta.getState("userData");

  const {
    data: geppSettings,
    isLoading,
    refetch,
  } = useQuery<{
    properties: {
      jobsExploratorEnabled: boolean;
      jobsExploratorProximityJobEnabled: boolean;
    };
  }>(["geppSettings"], async () => {
    const data = await buildRequest<PortalRoutes.GetGeppSettingsForCompany>({
      method: "GET",
      path: `/portal/settings/{companyUuid}/gepp`,
      params: { companyUuid },
    })();
    return data;
  });

  const { addToast } = useToasts();

  const handleOnChange = async (body: CompanyAdminPayload) => {
    try {
        await buildRequest<PortalRoutes.PatchGeppDataForCompany>({
          method: "POST",
          path: `/portal/admin/gepp`,
          payload: body,
        })();
        refetch();
      } catch (err) {
        addToast(
          t("portal.config.skills.jobTemplateFields.error", {
            defaultValue: "Une erreur est survenue.",
          }),
          {
            appearance: "error",
          }
        );
      }

    await refetch();
  };

  const geppProperties = useMemo(() => {
    return geppSettings?.properties ?? null;
  }, [geppSettings]);

  const [items, setItems] = useState([]);
  const { status, data, error } = trpc.jobTemplates.getAll.useQuery();

  useEffect(() => {
    if (data) {
      setItems(
        data.map(({ name: title }) => ({
          title,
          id: faker.datatype.uuid(),
          disabled: true,
        }))
      );
    }
  }, [data]);

  return (
    <JobLayout>
      <Flex>
        <Flex flexDirection="column" marginTop="m">
          <Text espaceFont="body2Bold" color="plainText-onLight-default">
            {t("portal.config.skills.jobTemplateFields.list.title", {
              defaultValue: "Structure des fiches de poste",
            })}
          </Text>
          <Flex flexDirection="column" marginTop="xs">
            {status === "loading" && <Loader />}
            {status === "error" && <div>{error.message}</div>}
            {status === "success" && (
              <Flex flexDirection="column">
                <DSSortable items={items} locked={true} />
              </Flex>
            )}
          </Flex>
          <Flex width="fit-content" marginTop="m">
            <DSButton
              type="button"
              label={t("portal.config.skills.jobTemplateFields.set", {
                defaultValue: "Modifier la structure des fiches de poste",
              })}
              onClick={() => history.push("/responsable/structure-de-fiches-de-poste/set")}
            />
          </Flex>

          <Flex flexDirection="column" marginTop="xxl">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Text espaceFont="body2Bold" color="plainText-onLight-default">
                  {t("portal.config.skills.jobTemplateFields.feature.exploratorTitle", {
                    defaultValue: "Page métiers dans l’espace collaborateur",
                  })}
                </Text>
                <Flex marginTop="xs" flexDirection="column" gap="xs">
                  <AdminPortalToggle
                    propertiesKey="jobsExploratorEnabled"
                    label={t("portal.config.skills.jobTemplateFields.feature.exploratorContent", {
                      defaultValue: "Montrer les collaborateurs associés aux fiches de poste",
                    })}
                    type={PortalToggleType.ENABLED}
                    isSelected={geppProperties ? geppProperties["jobsExploratorEnabled"] : false}
                    onChange={handleOnChange}
                  />
                  <AdminPortalToggle
                    propertiesKey="jobsExploratorProximityJobEnabled"
                    label={t(
                      "portal.config.skills.jobTemplateFields.feature.exploratorProximityJobs",
                      {
                        defaultValue: "Afficher la section des postes en adéquation",
                      }
                    )}
                    type={PortalToggleType.ENABLED}
                    isSelected={
                      geppProperties ? geppProperties["jobsExploratorProximityJobEnabled"] : false
                    }
                    onChange={handleOnChange}
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </JobLayout>
  );
}

export default JobTemplateFields;
