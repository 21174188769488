import { useMutation, useQuery } from "@tanstack/react-query";
import useTranslation from "hooks/useTranslation";
import { useToasts } from "react-toast-notifications";

import { DSModal, DSRadio, DSRadioGroup } from "@skillup/ui";

import User from "utils/User";

import styles from "../../PortalSchedules.module.scss";

import { buildRequest } from "utils/buildRequest";
import { OrganizationRoutes } from "@skillup/espace-rh-bridge";

import { useEffect, useState } from "react";

type Props = {
  onClose: (needRefresh?: boolean) => void;
};

export default function ImportedPlansModal({ onClose }: Props) {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const {
    activeCompany: { uuid: organizationUuid },
  } = User.getUserData();

  const [value, setValue] = useState<Boolean>();

  const { isLoading: organizationIsLoading, data: organization } = useQuery({
    queryKey: ["organization_details", organizationUuid],
    queryFn: () =>
      buildRequest<OrganizationRoutes.GetOrganizationDetails>({
        method: "GET",
        path: "/organization/details",
      })(),
  });

  useEffect(() => {
    if (organization) {
      setValue(organization?.filterImportedScheduleFields === true);
    }
  }, [organization]);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (value: boolean) =>
      buildRequest<OrganizationRoutes.UpdateFilterImportedScheduleFields>({
        method: "POST",
        path: "/organization/{organizationUuid}/update-filter-imported-schedule-fields",
        params: { organizationUuid },
        payload: {
          value,
        },
      })(),
  });

  const handleSubmit = () => {
    mutate(value === true, {
      onSuccess: () => {
        addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
          appearance: "success",
        });
        onClose(true);
      },
      onError: () => {
        addToast(t("app.errors.message"), { appearance: "error" });
      },
    });
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("app.schedule.imported_schedule.setting.modal.title", {
            defaultValue: "Plans importés - Configuration par défaut",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.modalContent}>
          <p>
            {t("app.schedule.imported_schedule.setting.modal.description", {
              defaultValue: `Vous pouvez décider de considérer comme « plan importé » n'importe lequel de vos plans, si vous savez qu'il est principalement constitué de lignes importées.

Certaines données associées aux lignes importées peuvent être incomplètes ou inexactes : prix, dates, lieu, durée et modalité.`,
            })}
          </p>
          <p className={styles.label}>
            {t("app.schedule.imported_schedule.setting.modal.label", {
              defaultValue:
                "Choisissez ce que vous voulez faire de ces données non fiables. Ces réglages s'appliqueront à l'ensemble des lignes (importées ou non) des plans considérés comme « plans importés » :",
            })}
          </p>
          <DSRadioGroup name="filterImportedScheduleFields">
            <DSRadio
              label={t("app.schedule.imported_schedule.setting.radio.positive", {
                defaultValue: "Masquer les données non fiables",
              })}
              helperText={t("app.schedule.imported_schedule.setting.radio.positive.description", {
                defaultValue:
                  "Les données non fiables seront masquées pour toutes les lignes (importées ou non) dans l'historique de formation du profil collaborateur, ainsi que dans l'espace formation de l'espace manager.",
              })}
              checked={value === true}
              onChange={() => setValue(true)}
            />
            <DSRadio
              label={t("app.schedule.imported_schedule.setting.radio.negative", {
                defaultValue: "Afficher les données non fiables",
              })}
              helperText={t("app.schedule.imported_schedule.setting.radio.negative.description", {
                defaultValue:
                  "Les données non fiables seront affichées pour toutes les lignes (importées ou non) dans l'historique de formation du profil collaborateur, ainsi que dans l'espace formation de l'espace manager.",
              })}
              checked={value === false}
              onChange={() => setValue(false)}
            />
          </DSRadioGroup>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={() => onClose()}
        />
        <DSModal.Footer.PrimaryButton
          label={t("app.modal.action.save", {
            defaultValue: "Enregistrer",
          })}
          disabled={organizationIsLoading}
          onClick={handleSubmit}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
