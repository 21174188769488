import { t } from "i18next";

import { ListUtils } from "@skillup/shared-utils";
import { EmployeeFields, EmployeeFilters } from "@skillup/core-hr-bridge";

import { type getReviewsData } from "services/peopleReview/reviews/getReviewsData";
import { type getCampaignDetails } from "services/peopleReview/useCampaignDetails/getCampaignDetails";

export type Campaign = Awaited<ReturnType<typeof getCampaignDetails>>;
export type Review = Awaited<ReturnType<typeof getReviewsData>>["reviews"][number];
export type Modal =
  | "changeManager"
  | "editingComment"
  | "assigningActionsV2"
  | "managerPreparationRestore";
export type ActiveModal = null | { modal: Modal; reviews: Review[] };
export type OpenModal = (modal: Modal, reviews: Review[]) => void;
export const views = {
  detailed: "detailed" as const,
  grid: "grid" as const,
  matrix: "matrix" as const,
};
export type View = keyof typeof views; 

export function getFiltersConfig(
  campaign: Campaign,
  managersConfig?: { key: string; label: string }[]
) {
  return {
    coordinatorStatus: {
      type: ListUtils.FilterType.MULTISELECT,
      label: t("people-review-campaign.filters.coordinatorStatus", {
        defaultValue: "Statut",
      }),
    },
    [EmployeeFields.branch.key]: {
      type: EmployeeFilters.branch.type,
      label: t(EmployeeFields.branch.traductionKey, {
        defaultValue: EmployeeFields.branch.traductionDefaultValue,
      }),
    },
    [EmployeeFields.division.key]: {
      type: EmployeeFilters.division.type,
      label: t(EmployeeFields.division.traductionKey, {
        defaultValue: EmployeeFields.division.traductionDefaultValue,
      }),
    },
    [EmployeeFields.email.key]: {
      type: EmployeeFilters.email.type,
      label: t(EmployeeFields.email.traductionKey, {
        defaultValue: EmployeeFields.email.traductionDefaultValue,
      }),
    },
    [EmployeeFields.firstName.key]: {
      type: EmployeeFilters.firstName.type,
      label: t(EmployeeFields.firstName.traductionKey, {
        defaultValue: EmployeeFields.firstName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.fullName.key]: {
      type: EmployeeFilters.fullName.type,
      label: t(EmployeeFields.fullName.traductionKey, {
        defaultValue: EmployeeFields.fullName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.lastName.key]: {
      type: EmployeeFilters.lastName.type,
      label: t(EmployeeFields.lastName.traductionKey, {
        defaultValue: EmployeeFields.lastName.traductionDefaultValue,
      }),
    },
    [EmployeeFields.perimeters.key]: {
      type: EmployeeFilters.perimeters.type,
      label: t(EmployeeFields.perimeters.traductionKey, {
        defaultValue: EmployeeFields.perimeters.traductionDefaultValue,
      }),
    },
    [EmployeeFields.registrationNumber.key]: {
      type: EmployeeFilters.registrationNumber.type,
      label: t(EmployeeFields.registrationNumber.traductionKey, {
        defaultValue: EmployeeFields.registrationNumber.traductionDefaultValue,
      }),
    },
    [EmployeeFields.role.key]: {
      type: EmployeeFilters.role.type,
      label: t(EmployeeFields.role.traductionKey, {
        defaultValue: EmployeeFields.role.traductionDefaultValue,
      }),
    },
    [EmployeeFields.service.key]: {
      type: EmployeeFilters.service.type,
      label: t(EmployeeFields.service.traductionKey, {
        defaultValue: EmployeeFields.service.traductionDefaultValue,
      }),
    },
    [EmployeeFields.site.key]: {
      type: EmployeeFilters.site.type,
      label: t(EmployeeFields.site.traductionKey, {
        defaultValue: EmployeeFields.site.traductionDefaultValue,
      }),
    },
    ...(campaign.hasManagerPreparation && {
      manager: {
        type: ListUtils.FilterType.MULTISELECT,
        label: t("people-review-campaign.filter.managers", {
          defaultValue: "Manager",
        }),
      },
    }),
    ...campaign.scales.reduce((acc, { id, label }) => {
      acc[id] = {
        type: ListUtils.FilterType.MULTISELECT,
        label,
      };
      return acc;
    }, {}),
    ...campaign.customFields.reduce((acc, { binding, label }) => {
      acc[binding] = {
        /**
         * @todo we could change the type according to the customUserField.type
         */
        type: ListUtils.FilterType.TEXT,
        label,
      };
      return acc;
    }, {}),
    ...(managersConfig ? managersConfig.reduce((acc, { key, label }) => {
      acc[key] = {
        type: ListUtils.FilterType.MULTISELECT,
        label,
      }
      return acc;
    }, {}) : {}), 
  } satisfies ListUtils.FilterConfigurationMap;
}

export type FiltersConfig = ReturnType<typeof getFiltersConfig>;
