import { useCallback } from "react";
import useTranslation from "hooks/useTranslation";

import { Flex, Text, Loader } from "@skillup/design-system";
import { DSFormGroupTextInput, DSTextInput, DSTooltip, VerticalDivider, Icon } from "@skillup/ui";

import { useAccessCheckFor } from "hooks/useSettings";
import { useTimelineForFields } from "services/coreHR";

import { Collaborator } from "../../api";
import getLocalizedSalary from "../localizedSalary";
import { EmptyLayout } from "./EmptyLayout";

const sortByStartDate = (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime();

type UserCareerProps = {
  readonly user: Collaborator;
};

const UserCareer = ({ user }: UserCareerProps) => {
  const { t } = useTranslation();

  const canAccessSalaryData = useAccessCheckFor("salary-data");

  const { timelineRecords, isLoading } = useTimelineForFields({
    employeeID: user?.uuid,
    fieldsKeys: [
      "job.monthlySalary",
      "job.currency",
      "job.jobTitle",
      "job.annualSalary",
      "salary-bonus.amount",
      "salary-bonus.currency",
      "salary-bonus.type",
      "salary-bonus.comment",
      "salary-bonus.source",
    ],
  });

  const convertToLocalizedSalary = useCallback(
    ({ startDate, data }) =>
      getLocalizedSalary({
        startDate,
        rawData: data,
        records: timelineRecords?.["job.currency"],
        locale: user.currentLanguage,
      }),
    [timelineRecords, user]
  );

  const annualSalaryData =
    timelineRecords?.["job.annualSalary"].filter((record) => record.data) || [];
  const monthlySalaryData =
    timelineRecords?.["job.monthlySalary"].filter((record) => record.data) || [];

  const sortedAnnualSalaryData = annualSalaryData.sort(sortByStartDate);
  const sortedMonthlySalaryData = monthlySalaryData.sort(sortByStartDate);

  const sortedSalaryData =
    annualSalaryData.length >= monthlySalaryData.length
      ? sortedAnnualSalaryData
      : sortedMonthlySalaryData;
  const hasSalaryData = sortedSalaryData.length > 0;

  const jobData = timelineRecords?.["job.jobTitle"] || [];
  const sortedJobTitleData = jobData.sort(sortByStartDate);
  const hasJobTitleData = jobData.length > 0;

  const convertToLocalizedSalaryBonusCurrency = useCallback(
    ({ startDate, data }) =>
      getLocalizedSalary({
        startDate,
        rawData: data,
        records: timelineRecords?.["salary-bonus.currency"],
        locale: user.currentLanguage,
      }),
    [timelineRecords, user]
  );

  const salaryBonusAmountData = timelineRecords?.["salary-bonus.amount"] || [];
  const sortedSalaryBonusAmountData = salaryBonusAmountData.sort(sortByStartDate);
  const hasSalaryBonusData = salaryBonusAmountData.length > 0;  

  const salaryBonusTypeData = timelineRecords?.["salary-bonus.type"] || [];
  const sortedSalaryBonusTypeData = salaryBonusTypeData.sort(sortByStartDate);

  const salaryBonusCommentData = timelineRecords?.["salary-bonus.comment"] || [];
  const sortedSalaryBonusCommentData = salaryBonusCommentData.sort(sortByStartDate);

  if (!isLoading && !hasSalaryData && !hasJobTitleData && !hasSalaryBonusData)
    return <EmptyLayout fullName={user.fullName} />;

  return (
    <Flex padding="l" gap="s" justifyContent="center">
      {isLoading ? (
        <Loader fillSpace />
      ) : (
        <Flex
          maxWidth="42rem"
          flexGrow={1}
          gap="s"
          paddingBottom="m"
          flexDirection="row"
          justifyContent="center"
        >
          {/* TODO: Also add feature flag condition in core-hr (backend). Data can be find in request response */}
          {canAccessSalaryData && (
            <Flex width="100%">
              <Flex width="100%">
                <Flex gap="s" flexGrow={1} flexDirection="column" justifyContent="flex-start">
                  <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                    {t("manager.peopleReview.timelineModal.job.monthlySalary.title", {
                      defaultValue: "Historique de salaire",
                    })}
                  </Text>
                  {!hasSalaryData && (
                    <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
                      {t("manager.peopleReview.timelineModal.job.salary.noData", {
                        defaultValue: "Pas de donnée disponible",
                      })}
                    </Text>
                  )}
                  {hasSalaryData &&
                    sortedSalaryData.map(({ data, startDate }, index) => (
                      <DSFormGroupTextInput
                        key={index}
                        label={new Date(startDate).toLocaleDateString()}
                      >
                        <Flex paddingVertical="xs" flexDirection="column">
                          <DSTextInput
                            name="annualSalary"
                            value={convertToLocalizedSalary({ data, startDate })}
                            readOnly
                          />
                        </Flex>
                      </DSFormGroupTextInput>
                    ))}
                </Flex>
              </Flex>
              <VerticalDivider left="s" right="s" />
            </Flex>
          )}

          <Flex width="100%">
            <Flex
              gap="s"
              flexGrow={1}
              paddingBottom="s"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                {t("manager.peopleReview.timelineModal.job.jobTitle.title", {
                  defaultValue: "Historique de postes occupés",
                })}
              </Text>
              {!hasJobTitleData && (
                <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
                  {t("manager.peopleReview.timelineModal.job.jobTitle.noData", {
                    defaultValue: "Pas de donnée disponible",
                  })}
                </Text>
              )}
              {hasJobTitleData &&
                sortedJobTitleData.map(({ data, startDate }, index) => (
                  <DSFormGroupTextInput
                    key={index}
                    label={new Date(startDate).toLocaleDateString()}
                  >
                    <Flex paddingVertical="xs" flexDirection="column">
                      <DSTextInput name="jobTitle" value={data} readOnly />
                    </Flex>
                  </DSFormGroupTextInput>
                ))}
            </Flex>
          </Flex>
          <VerticalDivider left="s" right="s" />
          <Flex width="100%">
            <Flex
              gap="s"
              flexGrow={1}
              paddingBottom="s"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                {t("manager.peopleReview.timelineModal.salary-bonus.title", {
                  defaultValue: "Historique de bonus et primes",
                })}
              </Text>
              {!hasSalaryBonusData && (
                <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
                  {t("manager.peopleReview.timelineModal.salary-bonus.noData", {
                    defaultValue: "Pas de donnée disponible",
                  })}
                </Text>
              )}
              {hasSalaryBonusData &&
                sortedSalaryBonusAmountData.map(({ data, startDate }, index) => (
                  <DSFormGroupTextInput
                    key={index}
                    label={new Date(startDate).toLocaleDateString()}
                  >
                    <Flex paddingVertical="xs" flexDirection="column">
                      <DSTextInput
                        name="salary-bonus.amount"
                        value={convertToLocalizedSalaryBonusCurrency({ data, startDate })}
                        readOnly
                      />

                      <Flex flexDirection="row" gap="xxs" alignItems="center">
                        <Text color="plainText-onLight-lighter" espaceFont="captionRegular">
                          {sortedSalaryBonusTypeData[index].data}
                        </Text>
                        <DSTooltip
                          label={sortedSalaryBonusCommentData[index].data}
                          style={{ height: "1rem" }}
                        >
                          <Icon.Info size="1rem" color="#69798c" />
                        </DSTooltip>
                      </Flex>
                    </Flex>
                  </DSFormGroupTextInput>
                ))}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default UserCareer;
