import DashboardChildCard, {
  Props as DashboardChildCardProps,
} from "../DashboardChildCard/DashboardChildCard";
import { DashboardChildType } from "@skillup/espace-rh-bridge";
import { TranslationType } from "hooks/useTranslation";
import { useMemo } from "react";

type QuestionType = DashboardChildType.CLOSED_QUESTION | DashboardChildType.OPEN_QUESTION;

export type Props<T extends QuestionType> = Pick<
  DashboardChildCardProps<T>,
  "child" | "className" | "icon" | "typeName" | "bodyRef" | "children"
> & { t: TranslationType };
function Question<T extends QuestionType>(props: Props<T>) {
  const { child, t } = props;

  const replyRestrictions = useMemo(() => {
    if (child.disallow?.employee?.reply) {
      // Not sure this case exists, actually
      if (child.disallow?.manager?.reply)
        return t("dashboard.interviews.child.question.disallow.all", {
          defaultValue: "Question sans réponse possible",
        });
      else
        return t("dashboard.interviews.child.question.allow.manager", {
          defaultValue: "Seuls les responsables d’entretien peuvent répondre à cette question",
        });
    } else {
      if (child.disallow?.manager?.reply)
        return t("dashboard.interviews.child.question.allow.employee", {
          defaultValue: "Seuls les collaborateurs peuvent répondre à cette question",
        });
      else
        return t("dashboard.interviews.child.question.allow.all", {
          defaultValue:
            "Les collaborateurs et les responsables d’entretien peuvent répondre à cette question",
        });
    }
  }, [child, t]);
  const noReplyRestrictions = useMemo(
    () => !child.disallow?.employee?.reply && !child.disallow?.manager?.reply,
    [child]
  );

  return (
    <DashboardChildCard
      title={child.label ?? ""}
      subTitle={replyRestrictions}
      withRoleFilter={noReplyRestrictions}
      t={t}
      {...props}
    />
  );
}

export default Question;
